



















import { Component, Vue } from "vue-property-decorator";
import DataCompare from "./components/DataCompare.vue";
import ClaimTax from "./components/ClaimTax.vue";

@Component({
  name: "InfoAuthorization",
  components: { DataCompare, ClaimTax }
})
export default class extends Vue {
  private StatusEnums: any = {
    NOT_ACTIVE: 0, // 未开通
    AUDITING: 1, // 审核中
    REFUSED: 2, // 审核拒绝
    DEACTIVE: 3, // 停用
    ACTIVED: 4
  };

  status: Number = this.StatusEnums.ACTIVED;
  activeIndex: String = "1";

  refuseReason: String = "";

  async getList() {
    try {
      // const res = await getServiceManageListApi();
      const { data } = { data: 0 };

      this.status = data;
    } catch (e) {
      //
    }
  }

  handleApplyActive() {
    // 申请开通
  }

  handleRefreshResult() {
    // 审核中刷新结果
  }

  handleSelect(e) {
    this.activeIndex = e;
  }
}
