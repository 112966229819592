

































































import { Component, Vue } from 'vue-property-decorator'
// import { ITaxRateConifg, IUpdateTaxRateParams } from '@/api/types'
import {
  getIdentifyTypeList,
  modifyIdentifyType
} from '@/api/system'
import BsmTable from '@/views/components/BsmTable.vue'
import { ElForm } from 'element-ui/types/form'

@Component({
  name: 'IdentifyTypeList',
  components: {
    BsmTable
  }
})
export default class extends Vue {
  list: any[] = []
  listLoading = true
  submitLoading = false
  showEdit = false
  invoiceActive = true
  temp = {
    code: '',
    name: ''
  }

  id: any = ''
  rules = {
    code: [{
      trigger: 'blur',
      required: true,
      message: '请输入服务商代码'
    }, {
      trigger: 'blur',
      pattern: /^[0-9]+$/,
      message: '请输入数字'
    }, {
      trigger: 'blur',
      validator: this.validateCode
    }],
    name: [{
      required: true,
      type: 'string',
      message: '请输入服务商名称',
      trigger: 'blur'
    }]
  }

  resetTemp() {
    this.temp = {
      code: '',
      name: ''
    }
  }

  validateCode(rule, value, callback) {
    let isDuplicate = false
    this.list.forEach(i => {
      if (i.code === this.temp.code) {
        isDuplicate = true
      }
    })
    if (isDuplicate) {
      callback(new Error('服务商代码重复，请输入其他数字'))
    } else {
      callback()
    }
  }

  tableListOptions = [
    /* {
       field: '',
       type: 'actionSetting',
       label: '操作',
       align: 'center',
       width: 50,
       attrs: {
         actionOptions: [{
           type: 'edit',
           label: '编辑'
         }]
       },
       fixed: true
     }, */
    {
      field: 'code',
      label: '代码',
      align: 'center'
    }, {
      field: 'name',
      label: '认证方式名称'
    }
  ]

  created() {
    this.getList()
  }

  async getList() {
    this.listLoading = true
    const { data } = await getIdentifyTypeList()
    const content = data.content ? JSON.parse(data.content) : []
    this.id = data.id
    this.list = content
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }


  async handleSubmit() {
    this.submitLoading = true
    const valid = await (this.$refs.form as ElForm).validate()
    try {
      if (valid) {
        // '[{\"code\":\"01\",\"name\":\"腾讯\"},{\"code\":\"02\",\"name\":\"数据宝\"},{\"code\":\"03\",\"name\":\"旷世科技\"},{\"code\":\"04\",\"name\":\"商汤科技\"},{\"code\":\"05\",\"name\":\"科大讯飞\"},{\"code\":\"06\",\"name\":\"阿里云\"},{\"code\":\"07\",\"name\":\"e签宝\"},{\"code\":\"08\",\"name\":\"上上签\"},{\"code\":\"09\",\"name\":\"安心签\"},{\"code\":\"10\",\"name\":\"云片\"},{\"code\":\"11\",\"name\":\"创蓝\"}]'
        this.list.push(this.temp)
        await modifyIdentifyType({
          id: this.id,
          content: JSON.stringify(this.list)
        })
        this.getList()
        this.showEdit = false
        this.$message.success('编辑成功')
      } else {
        this.$message.error('请输入正确参数')
      }
    } finally {
      this.submitLoading = false
    }
  }

  handleClose() {
    // this.resetTemp()
    (this.$refs.form as ElForm).resetFields()
    this.submitLoading = false
  }
}

