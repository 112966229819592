import request from '@/utils/request'

const path = ''

export const getConsignorDetail = (params: { subject_id: string }) =>
  request({
    url: `${path}/consignor/detail`,
    method: 'post',
    data: params
  })

export const getAgentDetail = (params: { subject_id: string }) =>
  request({
    url: `${path}/agent/detail`,
    method: 'post',
    data: params
  })

export const getCarrierDetail = (params: { subject_id: string }) =>
  request({
    url: `${path}/carrier/detail`,
    method: 'post',
    data: params
  })

export const getDriverDetail = (params: { subject_id: string }) =>
  request({
    url: `${path}/driver/detail`,
    method: 'post',
    data: params
  })


export const getVehicleDetail = (params: { subject_id: string }) =>
  request({
    url: `${path}/vehicle/detail`,
    method: 'post',
    data: params
  })

