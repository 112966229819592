// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.role-authority-container {\n  position: relative;\n  padding: 20px;\n  overflow: auto;\n}\n.role-authority-container .item-group {\n    padding: 6px;\n}\n.role-authority-container .item-group .tree-item-1:first-child {\n      border-top: none;\n}\n.role-authority-container .authority-tree-container {\n    margin-top: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
