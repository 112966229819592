var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "waybill-goods-container" }, [
    _c(
      "div",
      {
        staticClass: "content-title",
        staticStyle: { "font-weight": "500", color: "#4d4d4d" }
      },
      [_vm._v(" 货物信息 ")]
    ),
    _c("div", { staticClass: "content-header" }, [
      _c("span", [
        _vm._v("货物总件数：" + _vm._s(_vm.waybillGoodsInfo.totalQuantity))
      ]),
      _c("span", [
        _vm._v(
          "总体积：" +
            _vm._s(_vm._f("volume")(_vm.waybillGoodsInfo.totalVolume)) +
            "方"
        )
      ]),
      _c("span", [
        _vm._v(
          "总质量：" +
            _vm._s(_vm._f("weight")(_vm.waybillGoodsInfo.totalWeight)) +
            "千克"
        )
      ]),
      _c("span", [
        _vm._v(
          "总货值：" +
            _vm._s(
              _vm._f("amountFormatFilter")(_vm.waybillGoodsInfo.totalWorth)
            ) +
            "元"
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("table-list", {
          attrs: {
            data: _vm.waybillGoodsInfo.goodsDtoList,
            options: _vm.goodsDtoListOptions,
            "max-height": "300",
            "show-setting": false
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }