




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FilledMap from '../../components/Charts/FilledMap.vue'

function rgbToHex(r, g, b) {
  var hex = ((r << 16) | (g << 8) | b).toString(16)
  return '#' + new Array(Math.abs(hex.length - 7)).join('0') + hex
}

// hex to rgb
function hexToRgb(hex) {
  var rgb = []
  for (var i = 1; i < 7; i += 2) {
    rgb.push(parseInt('0x' + hex.slice(i, i + 2)))
  }
  return rgb
}
// 计算渐变过渡色
function gradient(startColor, endColor, step) {
  // 将 hex 转换为rgb
  var sColor = hexToRgb(startColor)
  var eColor = hexToRgb(endColor)

  // 计算R\G\B每一步的差值
  var rStep = (eColor[0] - sColor[0]) / step
  var gStep = (eColor[1] - sColor[1]) / step
  var bStep = (eColor[2] - sColor[2]) / step

  var gradientColorArr = []
  for (var i = 0; i < step; i++) {
    // 计算每一步的hex值
    gradientColorArr.push(rgbToHex(parseInt(rStep * i + sColor[0]), parseInt(gStep * i + sColor[1]), parseInt(bStep * i + sColor[2])))
  }
  return gradientColorArr
}

const colorType = {
  dispatch: {
    color: '#17BB89'
  },
  receive: {
    color: '#4F7DF9'
  }
}
// #4F7DF9

@Component({
  name: 'ChinaMap',
  components: {
    FilledMap
  }
})

export default class ChinaMap extends Vue {
  @Prop() deliveryDistributionData: any[]
  @Prop() receiverDistributionData: any[]
  @Prop({ default: 'normal' }) private size!: 'normal' | 'small'

  private step = 8
  private data: any[] = []
  private isReceive = false
  private defaultColorType = 'dispatch'
  private color: string = colorType[this.defaultColorType].color
  private type: string = this.defaultColorType
  private gradient: string[] = gradient('#ffffff', this.color, this.step)
  private max = 100
  private tipLineBackground: string

  private updateGradient() {
    this.gradient = gradient('#ffffff', this.color, this.step)
  }

  private handleToggleColorType(type: string) {
    this.color = colorType[type].color
    this.type = type
    if (type === 'dispatch') {
      this.data = this.deliveryDistributionData
    } else if (type === 'receive') {
      this.data = this.receiverDistributionData
    }
    this.setMax()
    this.updateGradient()
  }

  private setMax() {
    let max = 0
    if (this.data && this.data.length) {
      for (let i = 0, len = this.data.length; i < len; i++) {
        const d = this.data[i]
        if (d.value > max) {
          max = d.value
        }
      }
    }
    this.max = max
  }

  private setTipLineBackground() {
    this.tipLineBackground = `linear-gradient(left,#ffffff 0%,${this.color} 100%)`
  }

  @Watch('deliveryDistributionData')
  onDeliveryDistributionDataChange(data: any[]) {
    if (this.type === 'dispatch') {
      this.data = data
      this.setMax()
      this.setTipLineBackground()
    }
  }

  @Watch('receiverDistributionData')
  onReceiverDistributionDataChange(data: any[]) {
    if (this.type === 'receive') {
      this.data = data
      this.setMax()
      this.setTipLineBackground()
    }
  }
}

