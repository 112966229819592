var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-container" },
    [
      _c(
        "div",
        { staticClass: "home-editor-container" },
        [
          _c(
            "el-row",
            { staticClass: "gutter", attrs: { gutter: 16 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "home-card" },
                  [
                    _c("div", { staticClass: "title-box" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(" 本期预警 ")
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["risk_alarm"],
                              expression: "['risk_alarm']"
                            }
                          ],
                          staticClass: "more",
                          on: {
                            click: function($event) {
                              return _vm.jumpTo("/regulation/risk_alarm")
                            }
                          }
                        },
                        [
                          _vm._v(" 查看更多 "),
                          _c("i", { staticClass: "el-icon-arrow-right" })
                        ]
                      )
                    ]),
                    _c("alarm-list", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingData,
                          expression: "loadingData"
                        },
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["risk_alarm"],
                          expression: "['risk_alarm']"
                        }
                      ],
                      ref: "alarmList",
                      attrs: {
                        data: _vm.data,
                        type: "recently",
                        "no-title": true
                      },
                      on: { showPop: _vm.showPop }
                    }),
                    !_vm.roles.includes("risk_alarm")
                      ? _c("p", { staticClass: "no-auth-p" }, [
                          _vm._v(" 暂无权限 ")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "gutter", attrs: { gutter: 16 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "home-card" }, [
                  _c("div", { staticClass: "title-box" }, [
                    _c("div", { staticClass: "title" }, [_vm._v(" 最新公告 ")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-con" },
                    [
                      _vm.roles.includes("newest_notice")
                        ? [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.noticeNewestLoading,
                                    expression: "noticeNewestLoading"
                                  }
                                ]
                              },
                              [
                                _c("p", { staticClass: "notice-title" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.noticeNewest.title) + " "
                                  )
                                ]),
                                _c("div", {
                                  staticClass: "notice-con",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.noticeNewest.content)
                                  }
                                })
                              ]
                            )
                          ]
                        : _vm._e(),
                      !_vm.roles.includes("newest_notice")
                        ? _c("p", { staticClass: "no-auth-p" }, [
                            _vm._v(" 暂无权限 ")
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "home-card" }, [
                  _c("div", { staticClass: "title-box" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" 通知与公告 ")
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["notice_list"],
                            expression: "['notice_list']"
                          }
                        ],
                        staticClass: "more",
                        on: {
                          click: function($event) {
                            return _vm.jumpTo("/notice/manage")
                          }
                        }
                      },
                      [
                        _vm._v(" 查看更多 "),
                        _c("i", { staticClass: "el-icon-arrow-right" })
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "card-con" }, [
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.noticeLoading,
                            expression: "noticeLoading"
                          },
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["notice_list"],
                            expression: "['notice_list']"
                          }
                        ],
                        staticClass: "news-list"
                      },
                      _vm._l(_vm.newsList, function(item, index) {
                        return _c("li", { key: index }, [
                          _c(
                            "p",
                            {
                              staticClass: "t",
                              on: {
                                click: function($event) {
                                  return _vm.jumpTo("/notice/detail/" + item.id)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          ),
                          _c("span", { staticClass: "date" }, [
                            _vm._v(
                              _vm._s(_vm.momentTime(item.actual_send_time))
                            )
                          ])
                        ])
                      }),
                      0
                    ),
                    !_vm.roles.includes("notice_list")
                      ? _c("p", { staticClass: "no-auth-p" }, [
                          _vm._v(" 暂无权限 ")
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "gutter", attrs: { gutter: 16 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "home-card" }, [
                  _c("div", { staticClass: "title-box" }, [
                    _c("div", { staticClass: "title" }, [_vm._v(" 协同 ")]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["coordination"],
                            expression: "['coordination']"
                          }
                        ],
                        staticClass: "more",
                        on: {
                          click: function($event) {
                            return _vm.jumpTo("/regulation/coordination")
                          }
                        }
                      },
                      [
                        _vm._v(" 查看更多 "),
                        _c("i", { staticClass: "el-icon-arrow-right" })
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "card-con table-list-container",
                      staticStyle: { "min-height": "370px" }
                    },
                    [
                      _vm.roles.includes("coordination")
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "tab-box",
                                staticStyle: { "padding-bottom": "16px" }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type:
                                        _vm.coordinationType === 1
                                          ? "primary"
                                          : "",
                                      round: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.viewCoordinationList(1)
                                      }
                                    }
                                  },
                                  [_vm._v(" 待处理的协同 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type:
                                        _vm.coordinationType === 3
                                          ? "primary"
                                          : "",
                                      round: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.viewCoordinationList(3)
                                      }
                                    }
                                  },
                                  [_vm._v(" 发出的协同 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type:
                                        _vm.coordinationType === 2
                                          ? "primary"
                                          : "",
                                      round: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.viewCoordinationList(2)
                                      }
                                    }
                                  },
                                  [_vm._v(" 收到的协同 ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.coordinationLoading,
                                    expression: "coordinationLoading"
                                  }
                                ],
                                staticStyle: { width: "100%" },
                                attrs: { data: _vm.coordinationList }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { prop: "create_time", label: "时间" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.momentTime(
                                                    scope.row.create_time
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2598916599
                                  )
                                }),
                                _c("el-table-column", {
                                  attrs: { prop: "receiver", label: "接收人" }
                                }),
                                _c("el-table-column", {
                                  attrs: { prop: "status", label: "反馈状态" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row.status
                                                    ? "已回执"
                                                    : "待回执"
                                                ) +
                                                " "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    403303035
                                  )
                                }),
                                _c("el-table-column", {
                                  attrs: { prop: "title", label: "标题" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "el-link",
                                              {
                                                attrs: {
                                                  underline: false,
                                                  type: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.jumpTo(
                                                      "/regulation/coordination_detail/" +
                                                        scope.row.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(scope.row.title) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1596265853
                                  )
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      !_vm.roles.includes("coordination")
                        ? _c("p", { staticClass: "no-auth-p" }, [
                            _vm._v(" 暂无权限 ")
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "gutter", attrs: { gutter: 16 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "home-card" }, [
                  _c("div", { staticClass: "title-box" }, [
                    _c("div", { staticClass: "title" }, [_vm._v(" 区县排名 ")])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "card-con table-list-container",
                      staticStyle: { height: "314px" }
                    },
                    [
                      _vm.roles.includes("agent_score_rank")
                        ? _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.agentLoading,
                                  expression: "agentLoading"
                                }
                              ],
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.agentList }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "排名",
                                  type: "index",
                                  width: "80"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "district_name",
                                  label: "区县名称"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "agent_count", label: "企业数" }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "risk_score",
                                  label: "得分",
                                  width: "80"
                                }
                              })
                            ],
                            1
                          )
                        : _c("p", { staticClass: "no-auth-p" }, [
                            _vm._v(" 暂无权限 ")
                          ])
                    ],
                    1
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 16 } }, [
                _c("div", { staticClass: "home-card" }, [
                  _c("div", { staticClass: "title-box" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" 网货企业排名（近30天） ")
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["agent_sort"],
                            expression: "['agent_sort']"
                          }
                        ],
                        staticClass: "more",
                        on: {
                          click: function($event) {
                            return _vm.jumpTo("/dataquery/agent_rank")
                          }
                        }
                      },
                      [
                        _vm._v(" 查看更多 "),
                        _c("i", { staticClass: "el-icon-arrow-right" })
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "card-con table-list-container",
                      staticStyle: { "min-height": "314px" }
                    },
                    [
                      _vm.roles.includes("agent_sort")
                        ? _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.rankLoading,
                                  expression: "rankLoading"
                                }
                              ],
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.agentRankList }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "排名",
                                  type: "index",
                                  width: "80"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "agent_name",
                                  label: "企业名称",
                                  width: "220"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "risk_score",
                                  label: "得分",
                                  width: "80"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "drivers",
                                  label: "司机数",
                                  width: "90"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "vehicles",
                                  label: "车辆数",
                                  width: "90"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "waybill_count",
                                  label: "运单量",
                                  width: "90"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "income",
                                  label: "运费金额(元)"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.income
                                                  ? (
                                                      Number(scope.row.income) /
                                                      1000
                                                    ).toFixed(2)
                                                  : 0
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3985574837
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "weight",
                                  label: "运输重量(吨)"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.weight
                                                  ? (
                                                      Number(scope.row.weight) /
                                                      1000
                                                    ).toFixed(2)
                                                  : 0
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1039173845
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.roles.includes("agent_sort")
                        ? _c("p", { staticClass: "no-auth-p" }, [
                            _vm._v(" 暂无权限 ")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "gutter", attrs: { gutter: 16 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "home-card" }, [
                  _c("div", { staticClass: "title-box" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" 监管对象（累计） ")
                    ])
                  ]),
                  _c("div", { staticClass: "card-con" }, [
                    _vm.roles.includes("industrial_subject_count")
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.countLoading,
                                expression: "countLoading"
                              }
                            ]
                          },
                          [
                            _c("div", { staticClass: "industrial-item-box" }, [
                              _c("div", { staticClass: "item-con" }, [
                                _c("img", {
                                  attrs: {
                                    width: "48",
                                    src: "/img/icons/whqy.png",
                                    alt: ""
                                  }
                                }),
                                _c("div", { staticClass: "con-r" }, [
                                  _c("p", { staticClass: "t" }, [
                                    _vm._v(" 网货企业 ")
                                  ]),
                                  _c("p", { staticClass: "num" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.industrialCountData.agent_count
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "item-con" }, [
                                _c("img", {
                                  attrs: {
                                    width: "48",
                                    src: "/img/icons/ydsl.png",
                                    alt: ""
                                  }
                                }),
                                _c("div", { staticClass: "con-r" }, [
                                  _c("p", { staticClass: "t" }, [
                                    _vm._v(" 运单数量 ")
                                  ]),
                                  _c("p", { staticClass: "num" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.industrialCountData.waybill_count
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "industrial-item-box" }, [
                              _c("div", { staticClass: "item-con" }, [
                                _c("img", {
                                  attrs: {
                                    width: "48",
                                    src: "/img/icons/sjsl.png",
                                    alt: ""
                                  }
                                }),
                                _c("div", { staticClass: "con-r" }, [
                                  _c("p", { staticClass: "t" }, [
                                    _vm._v(" 司机数量 ")
                                  ]),
                                  _c("p", { staticClass: "num" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.industrialCountData.driver_count
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "item-con" }, [
                                _c("img", {
                                  attrs: {
                                    width: "48",
                                    src: "/img/icons/clsl.png",
                                    alt: ""
                                  }
                                }),
                                _c("div", { staticClass: "con-r" }, [
                                  _c("p", { staticClass: "t" }, [
                                    _vm._v(" 车辆数量 ")
                                  ]),
                                  _c("p", { staticClass: "num" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.industrialCountData.vehicle_count
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              ])
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "industrial-item-box",
                                staticStyle: { "padding-bottom": "0" }
                              },
                              [
                                _c("div", { staticClass: "item-con" }, [
                                  _c("img", {
                                    attrs: {
                                      width: "48",
                                      src: "/img/icons/tyrsl.png",
                                      alt: ""
                                    }
                                  }),
                                  _c("div", { staticClass: "con-r" }, [
                                    _c("p", { staticClass: "t" }, [
                                      _vm._v(" 托运人数量 ")
                                    ]),
                                    _c("p", { staticClass: "num" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.industrialCountData
                                              .consignor_count
                                          ) +
                                          " "
                                      )
                                    ])
                                  ])
                                ]),
                                _c("div", { staticClass: "item-con" }, [
                                  _c("img", {
                                    attrs: {
                                      width: "48",
                                      src: "/img/icons/cyrsl.png",
                                      alt: ""
                                    }
                                  }),
                                  _c("div", { staticClass: "con-r" }, [
                                    _c("p", { staticClass: "t" }, [
                                      _vm._v(" 承运人数量 ")
                                    ]),
                                    _c("p", { staticClass: "num" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.industrialCountData
                                              .carrier_count
                                          ) +
                                          " "
                                      )
                                    ])
                                  ])
                                ])
                              ]
                            )
                          ]
                        )
                      : _c("p", { staticClass: "no-auth-p" }, [
                          _vm._v(" 暂无权限 ")
                        ])
                  ])
                ])
              ]),
              _c("el-col", { attrs: { span: 16 } }, [
                _c("div", { staticClass: "home-card" }, [
                  _c("div", { staticClass: "title-box" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" 业务趋势（近30天） ")
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["overview"],
                            expression: "['overview']"
                          }
                        ],
                        staticClass: "more",
                        on: {
                          click: function($event) {
                            return _vm.jumpTo("/dataquery/overview")
                          }
                        }
                      },
                      [
                        _vm._v(" 查看更多 "),
                        _c("i", { staticClass: "el-icon-arrow-right" })
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "card-con",
                      staticStyle: { padding: "0", "min-height": "264px" }
                    },
                    [
                      _vm.roles.includes("overview")
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.waybillLoading,
                                  expression: "waybillLoading"
                                }
                              ]
                            },
                            [
                              _c(
                                "chart-container",
                                {
                                  staticStyle: { height: "264px" },
                                  attrs: {
                                    title: "运单笔数(笔)",
                                    amount: _vm.waybill_count
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "line-chart-container" },
                                    [
                                      _c("line-chart", {
                                        attrs: {
                                          data: _vm.waybillLineData,
                                          "series-field": "type",
                                          "x-field": "timestamp",
                                          "y-field": "bill_count"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _c("p", { staticClass: "no-auth-p" }, [
                            _vm._v(" 暂无权限 ")
                          ])
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("alarm-pop", {
        ref: "alarmDialog",
        attrs: {
          "current-id": _vm.currentAlarmId,
          "current-name": _vm.currentName
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }