



















































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import TypeMap from '../TypeMap/index.vue'
import { cloneDeep } from 'lodash'
interface IFilterOption {
  field: string | string [] // 主字段
  label: string // 字段显示名称
  main ? : boolean // 是否默认显示
  type ? : string // 字段显示的组件类型, 默认input
  attrs ? : any // 需要向组件中添加属性的对象，该对象上的所有属性都会加到组件上。
  component ? : any // 对应的组件，该字段会覆盖掉type类型和componeName
  componentName ? : string // 组件名称，已经注册过的组件，或者全局组件的名称。该字段会覆盖掉type的效果
  config ? : any // 组件的配置文件，通常用于自定义组件的传递的配置文件
  hidden ? : boolean // 是否显示该字段
}

@Component({
  components: {
    TypeMap
  }
})
export default class SearchFilter extends Vue {
  @Prop({
    default: function() {
      return {}
    },
    required: true
  }) value!: any

  @Prop({
    default: function() {
      return []
    },
    required: true
  }) options!: IFilterOption[]

  @Prop({
    default: function() {
      return false
    }
  }) showClear: boolean

  @Prop({ default: 'input' }) typeDefault: string

  @Prop({ }) labelWidth!: number

  isShow = false

  get autoLabelWidth() {
    let max = 0
    this.options.forEach(option => {
      if (option.label.length > max) {
        max = option.label.length
      }
    })
    return max * 14
  }

  get isShowClear() {
    return this.showClear
  }

  get renderLabelWidth() {
    return this.labelWidth || this.autoLabelWidth
  }

  get filter() {
    return this.value
  }

  set filter(val) {
    this.$emit('input', val)
  }

  get mainField() {
    return this.options.filter(option => !!option.main)
  }

  get hideField() {
    return this.options.filter(option => !option.main)
  }

  get renderMainField() {
    const mainField = cloneDeep(this.mainField).filter(item => !item.hidden)
    const list = []
    while (mainField.length) {
      if (mainField.length > 3) {
        list.push(mainField.splice(0, 3))
      } else {
        list.push(mainField.splice(0, mainField.length))
      }
    }
    return list
  }

  get renderHidefield() {
    const hideField = cloneDeep(this.hideField).filter(item => !item.hidden)
    const list = []
    while (hideField.length) {
      if (hideField.length > 3) {
        list.push(hideField.splice(0, 3))
      } else {
        list.push(hideField.splice(0, hideField.length))
      }
    }
    return list
  }

  get defaultAttrs() {
    return {
      placeholder: '请输入内容'
    }
  }

  created() {
    if (this.initShowStatus()) {
      this.handleShow()
    }
  }

  initShowStatus() {
    let isShow = false
    const query = this.$route.query

    for (let i = 0; i < this.options.length; i++) {
      const option = this.options[i]
      if (option.field instanceof Array) {
        option.field.forEach(f => {
          if (query[f]) {
            isShow = true
          }
        })
      } else if (query[option.field]) {
        isShow = true
        break
      }
    }
    return isShow
  }

  handleShow() {
    if (this.isShow) {
      this.hideField.forEach(option => {
        if (option.field instanceof Array) {
          option.field.forEach(f => {
            if (this.filter[f] instanceof Array) {
              this.filter[f] = []
            } else if (this.filter[f] || this.filter[f] === 0) {
              this.filter[f] = ''
            }
          })
        } else {
          if (this.filter[option.field] instanceof Array) {
            this.filter[option.field] = []
          } else if (this.filter[option.field] || this.filter[option.field] === 0) {
            this.filter[option.field] = ''
          }
        }
      })
      this.mainField.forEach(option => {
        if (option.field instanceof Array) {
          option.field.forEach(f => {
            if (this.filter[f] instanceof Array) {
              this.filter[f] = []
            } else if (this.filter[f] || this.filter[f] === 0) {
              this.filter[f] = ''
            }
          })
        } else {
          if (this.filter[option.field] instanceof Array) {
            this.filter[option.field] = []
          } else if (this.filter[option.field] || this.filter[option.field] === 0) {
            this.filter[option.field] = ''
          }
        }
      })
    }
    this.isShow = !this.isShow

    // 触发resize事件  改变table高度
    const myEvent = new Event('resize')
    window.dispatchEvent(myEvent)
  }

  handleFilter() {
    this.$emit('search', this.filter)
  }

  handleClear() {
    this.filter = {
      page: {
        index: 1,
        limit: 10
      }
    }
    this.$emit('clear', this.filter)
    this.$emit('search', this.filter)
  }

  makeAttrsFromFields(fields) {
    return fields.reduce((pre, cur, index) => {
      pre[`fields${index}`] = this.filter[cur]
      return pre
    }, {})
  }

  makeListenersFromFields(fields, listeners = {}) {
    return fields.reduce((pre, cur, index) => {
      pre[`update:fields${index}`] = (val) => {
        console.log('filter', this.filter, val)
        // this.filter[cur] = val
        this.$set(this.filter, cur, val)
      }
      return pre
    }, listeners)
  }

  isArray(arr) {
    return arr instanceof Array
  }

  mounted() {
    // console.log(this.$listeners)
  }
}

