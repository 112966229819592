







import { Component, Prop, InjectReactive, Watch, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { Column } from '@antv/g2plot'
import ResizeMixin from './mixins/resize1'
import moment from 'moment'
import { merge } from 'lodash'

@Component({
  name: 'ColumnChart'
})
export default class extends mixins(ResizeMixin) {
  @Prop({ default: 'column-chart' }) private className!: string;
  @Prop({ default: '100%' }) private width: string;
  @Prop({ default: '100%' }) private height: string;
  // @Prop({ default: '' }) private xField!: string;
  // @Prop({ default: '' }) private yField!: string;
  @Prop({ default: '' }) private colorField: string;
  // @Prop({ default: '' }) private title!: string;
  @Prop({ default: '' }) private seriesField: string;
  @Prop({ default() { return {} } }) private config!: any
  @Prop({ default: 0 }) private min!: number
  @Prop() private max!: number

  // @Prop({
  //   default() {
  //     return []
  //   }
  // }) private data!: any[]

  // private chart:any = ''

  private get options() {
    return merge({
      data: this.data,
      xField: this.xField,
      yField: this.yField,
      columnWidthRatio: 0.2,
      color: ['#2174E9', '#00B77F', '#FF8700', '#C4C4C4'],
      // colorField: this.colorField,
      seriesField: this.seriesField,
      yAxis: {
        min: this.min,
        max: this.max
      }
    }, this.config)
  }

  private init() {
    this.chart = new Column(this.$refs.chart as HTMLElement, this.options)
    this.chart.render()
  }

  private update() {
    this.chart.update(this.options)
  }

  mounted() {
    this.init()
  }
}
