var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse }
    },
    [
      !_vm.isGreenSkin
        ? [
            _c(
              "transition",
              { attrs: { name: "sidebarLogoFade" } },
              [
                _vm.collapse
                  ? _c(
                      "router-link",
                      {
                        key: "collapse",
                        staticClass: "sidebar-logo-link",
                        attrs: { to: "/" }
                      },
                      [
                        !_vm.isHaoyunbao
                          ? _c("img", {
                              staticClass: "sidebar-logo",
                              staticStyle: { width: "36px" },
                              attrs: {
                                src: require("../../../../public/img/icons/logo@2x.png")
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        key: "expand",
                        staticClass: "sidebar-logo-link",
                        attrs: { to: "/" }
                      },
                      [
                        _vm.isHaoyunbao
                          ? _c("img", {
                              staticClass: "sidebar-logo",
                              attrs: {
                                src: require("../../../../public/img/icons/logo-haoyunbao.png")
                              }
                            })
                          : _c("img", {
                              staticClass: "sidebar-logo",
                              attrs: {
                                src: require("../../../../public/img/icons/zsj-logo.png")
                              }
                            })
                      ]
                    )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }