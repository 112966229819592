var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadding,
          expression: "loadding"
        }
      ],
      staticClass: "setting-container"
    },
    [
      _c(
        "div",
        { attrs: { classs: "avatar-container" } },
        [
          _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: "",
                "auto-upload": false,
                "show-file-list": false,
                "on-change": _vm.handleFileChange
              }
            },
            [
              _vm.userForm.avatar
                ? _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.userForm.avatar }
                  })
                : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.userForm,
                "label-position": "left",
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号", prop: "username" } },
                [_c("span", [_vm._v(_vm._s(_vm.userForm.username))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账号名", prop: "nickname" } },
                [_c("span", [_vm._v(_vm._s(_vm.userForm.nickname))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色", prop: "rolename" } },
                [_c("span", [_vm._v(_vm._s(_vm.userForm.rolename))])]
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.update } },
                [_vm._v(" 更新 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }