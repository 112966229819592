var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("search-filter", {
        attrs: { options: _vm.queryFilterOptions, "type-default": "input" },
        on: { search: _vm.handleFilter },
        model: {
          value: _vm.listQuery,
          callback: function($$v) {
            _vm.listQuery = $$v
          },
          expression: "listQuery"
        }
      }),
      _c(
        "section",
        {},
        [
          _c("table-list", {
            attrs: {
              data: _vm.list,
              options: _vm.tableListOptions,
              "list-loading": _vm.listLoading,
              showSetting: false
            },
            on: {
              actionCommand: _vm.handleActionCommand,
              "button-action": _vm.handleActionCommand
            }
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page.index,
          limit: _vm.listQuery.page.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery.page, "index", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery.page, "limit", $event)
          },
          pagination: _vm.getList
        },
        scopedSlots: _vm._u([
          {
            key: "end",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.handleSetting }
                  },
                  [_vm._v(" 申报设置 ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.settingDialogVisible,
            title: "申报设置",
            "destroy-on-close": true,
            width: "400px",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.settingDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "flex", staticStyle: { "align-items": "center" } },
              [
                _c(
                  "div",
                  { staticStyle: { width: "150px", "text-align": "right" } },
                  [_vm._v("申报时间(每月)：")]
                ),
                _c("el-input", {
                  staticStyle: { width: "100px", "margin-right": "8px" },
                  model: {
                    value: _vm.applySettingForm.apply_day,
                    callback: function($$v) {
                      _vm.$set(_vm.applySettingForm, "apply_day", $$v)
                    },
                    expression: "applySettingForm.apply_day"
                  }
                }),
                _vm._v("号 ")
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flex mt-16",
                staticStyle: { "align-items": "center" }
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "150px", "text-align": "right" } },
                  [_vm._v("提醒频率(每)：")]
                ),
                _c("el-input", {
                  staticStyle: { width: "100px", "margin-right": "8px" },
                  model: {
                    value: _vm.applySettingForm.sms_frequency,
                    callback: function($$v) {
                      _vm.$set(_vm.applySettingForm, "sms_frequency", $$v)
                    },
                    expression: "applySettingForm.sms_frequency"
                  }
                }),
                _vm._v("天 ")
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flex mt-16",
                staticStyle: { "align-items": "center" }
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "150px", "text-align": "right" } },
                  [_vm._v("每期申报月数：")]
                ),
                _c("el-input", {
                  staticStyle: { width: "100px" },
                  model: {
                    value: _vm.applySettingForm.months_per_period,
                    callback: function($$v) {
                      _vm.$set(_vm.applySettingForm, "months_per_period", $$v)
                    },
                    expression: "applySettingForm.months_per_period"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "flex text-warning mt-16",
                staticStyle: { "padding-left": "40px" }
              },
              [
                _c("div", [_vm._v("说明：")]),
                _c("div", [_vm._v("每期申报月数即每隔几个月申报一次")])
              ]
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSaveSetting }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ]
      ),
      _c("DeclearComfirm", {
        ref: "declearComfirmRef",
        staticStyle: {
          position: "absolute",
          top: "0",
          left: "0",
          "z-index": "9999"
        },
        on: { success: _vm.handleFilter }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }