var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "multi-line-chart-container" },
    [
      _c("line-chart", {
        attrs: {
          data: _vm.lineChartData,
          "x-field": this.xField,
          "series-field": "_type",
          "y-field": this.yField
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }