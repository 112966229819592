







































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import WaybillRightPanel from './WaybillRightPanel.vue'
// import {
//   IWaybillPaymentData
// } from '../index.d'
import { logContent } from '../../directives/log-content'
import { logKeyName, timeFilterShowSecond } from '../../filters'

// const win = window as any

const waybillLogInfoDefault: any = []

@Component({
  components: {
    WaybillRightPanel
  },
  directives: {
    logContent
  },
  filters: {
    logKeyName
  }
})
export default class extends Vue {
  @Prop({ default: () => waybillLogInfoDefault }) waybillLogInfo
  centerDialogVisible = false
  content: any = []

  closePop() {
    this.centerDialogVisible = false
  }

  handleViewContent(content: string) {
    const arry = []
    const temp = content.split('&')
    temp.forEach(t => {
      const key = t.split('=')[0]
      const item = {
        key: key,
        value: t.replace(key + '=', '')
      }

      if (key.indexOf('time') !== -1 && item.value.length >= 13) {
        item.value = timeFilterShowSecond(item.value)
      }

      arry.push(item)
    })

    this.content = arry
    this.centerDialogVisible = true
  }
}

