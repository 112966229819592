var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "anti-fraud-container" },
    [
      _c("div", { staticClass: "anti-fraud-result-container" }, [
        _c("div", { staticClass: "title" }, [_vm._v(" 运单评分 ")]),
        _c("div", { staticClass: "main" }, [
          _c(
            "div",
            { staticClass: "gauge-container pt-2" },
            [
              _c("gauge-chart", {
                attrs: {
                  "content-title": _vm.getRiskScore,
                  value: _vm.data.antiFraudWaybillDto.riskScore,
                  "content-val": function(ref) {
                    var percent = ref.percent

                    if (percent < 0) {
                      return "-"
                    } else {
                      return "" + Math.round(percent * 1000)
                    }
                  },
                  config: {
                    innerRadius: 0.85,
                    statistic: {
                      title: {
                        offsetY: 25
                      },
                      content: {
                        offsetY: 10
                      }
                    }
                  }
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "anti-fraud-detail-container pl-6" }, [
        _c("div", { staticClass: "title" }, [_vm._v(" 评分详情 ")]),
        _c("div", { staticClass: "main mt-6 flex x-center" }, [
          _c(
            "div",
            { staticClass: "pl-14" },
            _vm._l(_vm.data.antiFraudResultDtoList, function(result) {
              return _c(
                "div",
                { key: result.type, staticClass: "verify-detail-row" },
                [
                  _c("img-icons", {
                    attrs: {
                      name: _vm.riskRuleIcons[result.type],
                      width: "20px"
                    }
                  }),
                  _c("span", [_vm._v(_vm._s(result.type) + "规则")]),
                  _c("span", [_vm._v(_vm._s(result.resultDesc))]),
                  _c("span", [
                    _vm._v(
                      _vm._s(result.passedCount) +
                        "/" +
                        _vm._s(result.totalCount)
                    )
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "text-primary",
                      on: {
                        click: function($event) {
                          return _vm.handleClick(result.antiFraudRuleDtoList)
                        }
                      }
                    },
                    [_vm._v("查看详情")]
                  )
                ],
                1
              )
            }),
            0
          )
        ])
      ]),
      _c("div", { staticClass: "anti-fraud-status-container" }, [
        _c(
          "div",
          {
            staticClass: "title flex",
            staticStyle: {
              "align-items": "center",
              "justify-content": "space-between"
            }
          },
          [
            _c("div", [
              _vm._v(" 风险评估状态 "),
              _c("span", { staticClass: "ml-6" }, [
                _vm._v(
                  _vm._s(
                    _vm.proofStatusMap[_vm.data.antiFraudWaybillDto.proofStatus]
                  )
                )
              ])
            ])
          ]
        ),
        _c("div", { staticClass: "flex x-center pa-2" }, [
          _vm.data.antiFraudWaybillDto.riskGrade === 0
            ? _c("img", {
                staticStyle: { width: "144px" },
                attrs: {
                  src:
                    "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yujingxitong-kuaihuoyun/%E6%9C%AA%E8%AF%84%E4%BC%B0.png"
                }
              })
            : _vm._e(),
          _vm.data.antiFraudWaybillDto.riskGrade === 2
            ? _c("img", {
                staticStyle: { width: "144px" },
                attrs: {
                  src:
                    "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yujingxitong-kuaihuoyun/%E8%A6%81%E7%B4%A0%E7%BC%BA%E5%A4%B1%EF%BC%88%E6%96%B0%EF%BC%89.png"
                }
              })
            : _vm._e(),
          _vm.data.antiFraudWaybillDto.riskGrade === 1 ||
          _vm.data.antiFraudWaybillDto.riskGrade === 4
            ? _c("img", {
                staticStyle: { width: "144px" },
                attrs: {
                  src:
                    "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yujingxitong-kuaihuoyun/%E8%A6%81%E7%B4%A0%E5%AE%8C%E6%95%B4.png"
                }
              })
            : _vm._e(),
          _vm.data.antiFraudWaybillDto.riskGrade === 3
            ? _c("img", {
                staticStyle: { width: "144px" },
                attrs: {
                  src:
                    "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yujingxitong-kuaihuoyun/%E8%A6%81%E7%B4%A0%E5%BC%82%E5%B8%B8%EF%BC%88%E6%96%B0%EF%BC%89.png"
                }
              })
            : _vm._e()
        ])
      ]),
      _vm._t("default"),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-container",
          attrs: {
            title: "申诉运单",
            visible: _vm.appealVisible,
            width: "1000px"
          },
          on: {
            "update:visible": function($event) {
              _vm.appealVisible = $event
            },
            close: _vm.handleCloseConfig
          }
        },
        [
          _c("div", { staticClass: "box" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("div", { staticClass: "title" }, [_vm._v(" 申诉清单： ")]),
                _c("table-list", {
                  attrs: {
                    data: _vm.waybillAppealItem,
                    options: _vm.appealItemListOptions,
                    "max-height": "300",
                    "show-setting": false
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.formData,
                "label-width": "130px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申诉材料" } },
                [
                  _c("multi-upload-file", {
                    attrs: { "file-list": _vm.initFileList, limit: 10 },
                    on: { input: _vm.handleFileListChange }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "explain", label: "申诉说明" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.formData.explain,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "explain", $$v)
                      },
                      expression: "formData.explain"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.subLoading },
                  on: { click: _vm.handleCloseConfig }
                },
                [_vm._v(" 关闭 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.subLoading },
                  on: {
                    click: function($event) {
                      return _vm.handleSubmit()
                    }
                  }
                },
                [_vm._v(" 提交 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.showDialog,
                title: "未通过校验规则",
                width: "700"
              },
              on: {
                "update:visible": function($event) {
                  _vm.showDialog = $event
                }
              }
            },
            [
              _c("table-list", {
                attrs: {
                  name: "anti-fraud",
                  data: _vm.unPasslist,
                  options: _vm.ruleOptions,
                  "show-setting": false
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.data.antiFraudWaybillLogDto &&
              _vm.data.antiFraudWaybillLogDto.operateTypeAlias,
            visible: _vm.dialogVisible,
            width: "30%",
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                display: "block",
                "margin-top": "8px",
                "margin-bottom": "8px"
              }
            },
            [
              _vm._v("该运单由 "),
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(_vm._s(_vm.data.antiFraudWaybillLogDto.operator) + " ")
              ]),
              _vm._v("于 "),
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(
                  _vm._s(
                    _vm._f("timeFilterShowSecond")(
                      _vm.data.antiFraudWaybillLogDto.createTime
                    )
                  )
                )
              ]),
              _vm._v(
                " 操作" +
                  _vm._s(
                    _vm.data.antiFraudWaybillLogDto &&
                      _vm.data.antiFraudWaybillLogDto.operateTypeAlias
                  )
              )
            ]
          ),
          _c("h4", [_vm._v("通过理由")]),
          _c("div", [_vm._v(_vm._s(_vm.data.antiFraudWaybillLogDto.reason))]),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            _vm._l(_vm.data.antiFraudWaybillLogDto.photos, function(uri) {
              return _c("contain-show-img", {
                key: uri,
                staticStyle: {
                  width: "80px",
                  height: "80px",
                  "margin-right": "10px"
                },
                attrs: { src: uri }
              })
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }