var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "box-card chart-container",
      attrs: { shadow: "hover", "body-style": { padding: 0 } }
    },
    [
      _c(
        "div",
        { staticClass: "chart-summary" },
        [
          _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _vm.amount !== undefined
            ? _c("span", { staticClass: "amount" }, [
                _vm._v(" " + _vm._s(_vm.amount) + " ")
              ])
            : _vm._e(),
          _vm.link
            ? _c(
                "no-cache-link",
                { staticClass: "link", attrs: { to: _vm.link } },
                [
                  _c("img-icons", {
                    attrs: { name: "linkRight", width: "20px" }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }