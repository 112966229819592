import request from '@/utils/request'
import { IGetListParams, IGetDashboardParams } from './types'

const path = 'carrier/aggregation'

export const getCarrierList = (params: IGetListParams) =>
  request({
    url: `${path}/list`,
    method: 'post',
    data: params
  })
export const getCarrierDashboard = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard`,
    method: 'post',
    data: params
  })

export const getAlarmRuleList = (params: any) => request({
  url: 'carrier/alarm',
  method: 'post',
  data: params
})

export const getCarrierRank = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/rank`,
    method: 'post',
    data: params
  })
export const getCarrierSubjectCount = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/subject/count`,
    method: 'post',
    data: params
  })
export const getCarrierTax = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/tax`,
    method: 'post',
    data: params
  })
export const getCarrierType = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/type`,
    method: 'post',
    data: params
  })
export const getCarrierWaybillChart = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/waybill/chart`,
    method: 'post',
    data: params
  })
