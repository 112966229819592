

















import { Component, Vue } from 'vue-property-decorator'
import { TagsViewModule } from '@/store/modules/tags-view'
import { AppModule } from '@/store/modules/app'

@Component({
  name: 'AppMain'
})
export default class extends Vue {
  get loading() {
    return AppModule.fetchLoading
  }

  get cachedViews() {
    return TagsViewModule.cachedViews
  }

  get key() {
    return this.$route.path
  }
}

