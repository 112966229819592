






































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IPathInfo } from './MapPannel.vue'

@Component({
  name: 'MapPannelBeiDou',
  components: {

  }
})
export default class MapPannelBeiDou extends Vue {
  @Prop({ default: () => [] }) pathInfos: IPathInfo[]
  @Prop({ default: () => [] }) BDPathInfos: IPathInfo[]
  @Prop() loading:boolean
  showBeiDouTrack = false
  actives:string[]=['nobeidou']
  handleChange() {
    console.log(1)
  }

  @Emit('visibleChange')
  handleToggleVisible(pathInfo, index) {
    pathInfo.visible = !pathInfo.visible
    return { index: index, isVisible: pathInfo.visible, cmpId: pathInfo.cmpId }
  }

  @Emit('fetchBeiDouTrack')
  handleFecthBeiDouTrack(pathInfo, index) {
    return pathInfo.subWaybillId
  }
}

