



























import { Component, Vue, Prop } from 'vue-property-decorator'
import CountTo from 'vue-count-to'
import NoCacheLink from './NoCacheLink.vue'

@Component({
  name: 'DashboardCard',
  components: {
    CountTo,
    NoCacheLink
  }
})
export default class extends Vue {
  @Prop() title: string
  @Prop() amount: number
  @Prop() unit: string
  @Prop() icon: string
  @Prop({ default: '' }) link: string
}

