








/* eslint-disable @typescript-eslint/no-empty-function */
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TagsViewModule } from '@/store/modules/tags-view'

@Component
export default class extends Vue {
  @Prop({ default: '' }) to: string
  @Prop({ default: () => {} }) beforeHook: Function
  @Prop({ default: () => {} }) afterHook: Function

  private async handleClick() {
    const view = (this.$router as any).matcher.match(this.to)
    if (this.beforeHook) {
      await this.beforeHook(this.to)
    }
    await TagsViewModule.delCachedView(view)
    this.$router.push({
      path: this.to
    })
    this.afterHook && this.afterHook(this.to)
  }
}

