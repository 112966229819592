












import { Component, Prop, Vue } from 'vue-property-decorator'
import TreeItem from '@/views/components/AuthorityTree/TreeItem.vue'

@Component({
  name: 'AuthorityTree',
  components: {
    TreeItem
  }
})
export default class extends Vue {
  @Prop({ default: [] }) data!: any[]
}

