import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const superviseRouter: RouteConfig = {
  path: '/regulation',
  name: 'Regulation',
  component: Layout,
  meta: {
    title: '行业监管',
    icon: 'bigScreen',
    alwaysShow: true,
    roles: ['industry_regulation']
  },
  children: [{
    path: 'regulation-detail',
    name: 'RegulationDetail',
    component: () => import('@/views/regulation/regulation-detail/index.vue'),
    meta: {
      title: '监管信息查询',
      roles: ['regulation_query']
    }
  },
  {
    path: 'inspect-task',
    name: 'InspectTask',
    component: () => import('@/views/regulation/inspect-task/index.vue'),
    meta: {
      title: '数据抽检处理'
      // roles: ['regulation_query']
    }
  },
  {
    path: 'waybill-appeal',
    name: 'WaybillAppeal',
    component: () => import('@/views/regulation/waybill-appeal/index.vue'),
    meta: {
      title: '运单申诉处理',
      noCache: true,
      roles: ['waybill_appeal']
    }
  },
  {
    path: 'external-anti-fraud',
    name: 'externalAntiFraud',
    component: () => import('@/views/regulation/external-anti-fraud/index.vue'),
    meta: {
      title: '外部监管系统管理',
      roles: ['external_anti_fraud']
    }
  },
  {
    path: 'external-anti-fraud-detail',
    name: 'externalAntiFraudDetail',
    component: () => import('@/views/regulation/external-anti-fraud/detail.vue'),
    meta: {
      title: '上级监管管理',
      hidden: true,
      roles: ['external_anti_fraud']
    }
  },
  {
    path: 'black-list',
    name: 'BlackList',
    component: () => import('@/views/regulation/black-list/index.vue'),
    meta: {
      title: '黑名单管理',
      roles: ['agent_black']
    }
  },
  {
    path: 'white-list',
    name: 'WhiteList',
    component: () => import('@/views/regulation/white-list/index.vue'),
    meta: {
      title: '规则白名单',
      roles: ['rule_white_list']
    }
  },
  {
    path: 'risk_alarm',
    name: 'RiskAlarm',
    component: () => import('@/views/regulation/risk-alarm/index.vue'),
    meta: {
      title: '风险预警',
      affix: true,
      roles: ['risk_alarm']
    }
  },
  {
    path: 'coordination',
    name: 'Coordination',
    component: () => import('@/views/regulation/coordination/index.vue'),
    meta: {
      title: '协同管理',
      roles: ['coordination']
    }
  },
  {
    path: 'coordination_detail/:id',
    name: 'CoordinationDetail',
    component: () => import('@/views/regulation/coordination/detail.vue'),
    meta: {
      title: '协同处理',
      hidden: true,
      // noCache: true,
      roles: ['coordination_create', 'coordination_detail']
    }
  }
  ]

}

export default superviseRouter
