import axios from "axios";
import { Message } from "element-ui";
import { UserModule } from "@/store/modules/user";
import { getMessage } from "@/utils/codeMessage";
import router from "@/router";
import { AppModule } from "@/store/modules/app";

const urlConfig = AppModule.urlConfig;

const service = axios.create({
  baseURL: urlConfig.url, // process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 300000
  // withCredentials: true // send cookies when cross-domain requests
});

// const LoadingRoutes = [
//   // 'dataquery/overview',
//   // 'datequery/consignor/',
//   // 'datequery/agent/',
//   // 'datequery/carrier/',
//   // 'datequery/driver/',
//   // 'datequery/vehicle/',
//   // 'regulation/regulation-detail'
//   '/dashboard',
//   '/industry'
// ]

function routeLoading(url: string) {
  if (url.indexOf("/dashboard") !== -1 || url.indexOf("/industry") !== -1) {
    // console.log('loadding...')
    AppModule.SetFetchLoading(true);
  }
}
function routeUnLoading() {
  AppModule.SetFetchLoading(false);
}

// Request interceptors
service.interceptors.request.use(
  config => {
    routeLoading(config.url);

    // Add X-Access-Token header to every request, you can add other custom headers here
    if (UserModule.token) {
      config.headers.token = UserModule.token;
    }
    if (config.headers.type === "access") {
      config.baseURL = urlConfig.url; // process.env.VUE_APP_BASE_API
      delete config.headers.type;
    } else {
      config.baseURL = urlConfig.url; // process.env.VUE_APP_BASE_API
    }
    config.headers["X-DESTINATION"] = urlConfig.destination;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// Response interceptors
service.interceptors.response.use(
  response => {
    routeUnLoading();
    // Some example codes here:
    // code == 20000: success
    // code == 1011: invalid access token
    // You can change this part for your own usage.
    const res = response.data;

    const errorHandler = (code, msg) => {
      const message = getMessage(code) || msg || "Error";
      if (code === 1011) {
        // token 失效
        UserModule.LogOut(true);
        setTimeout(() => {
          router
            .push({
              path: router.currentRoute.path,
              replace: true
            })
            .catch(err => {
              console.log(err.message);
            });
        }, 100);
      }
      Message({
        message,
        type: "error",
        duration: 5 * 1000
      });
      return new Error(message);
    };

    if (response.config.responseType === "blob") {
      if (res.type === "text/xml" || res.type === "application/octet-stream") {
        return res;
      } else {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const json = JSON.parse(reader.result as string);
            if (json.code !== 0) {
              reject(errorHandler(json.code, json.msg));
            } else {
              reject(new Error("报文格式错误"));
            }
          };
          reader.readAsText(res, "utf-8");
        });
      }
    }

    if (res.code !== 0) {
      return Promise.reject(errorHandler(res.code, res.msg));
    }
    return response.data;
  },
  error => {
    Message({
      message: error.msg || error.toString(),
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
