import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const riskRulesRouter: RouteConfig = {
  path: '/risk_rules',
  name: 'RiskRules',
  component: Layout,
  meta: {
    title: '风险规则策略',
    icon: 'riskRule',
    alwaysShow: true,
    roles: ['anti_fraud']
  },
  children: [{
    path: 'management',
    name: 'RiskRulesManagement',
    component: () => import('@/views/riskrules/management/index.vue'),
    meta: {
      title: '风险规则池',
      roles: ['anti_fraud_manage_query']
    }
  }, {
    path: 'enterprise-inspect-config',
    name: 'EnterpriseInspectConfig',
    component: () => import('@/views/regulation/enterprise-inspect-config/index.vue'),
    meta: {
      title: '企业抽检配置',
      roles: ['sample_inspect_config']
    }
  }, {
    path: 'enterprise-risk-set',
    name: 'EnterpriseRiskSet',
    component: () => import('@/views/riskrules/enterprise-risk-set/index.vue'),
    meta: {
      title: '企业风控配置',
      roles: ['agent_anti_conf']
    }
  }, {
    path: 'inspect_strategy',
    name: 'InspectStrategy',
    component: () => import('@/views/riskrules/inspect_strategy/index.vue'),
    meta: {
      title: '抽检策略管理',
      roles: ['sample_inspect_strategy_manage']
    }
  }, {
    path: 'detail/:id',
    name: 'RiskRuleDetail',
    component: () => import('@/views/riskrules/management/detail.vue'),
    meta: {
      title: '规则详情',
      hidden: true,
      noCache: true,
      roles: ['anti_fraud_manage_add', 'anti_fraud_manage_update', 'anti_fraud_manage_rule_detail']
    }
  }, {
    path: 'risk-template/list',
    name: 'RiskTemplateList',
    component: () => import('@/views/system/risk-template/index.vue'),
    meta: {
      title: '风险规则模板管理',
      noCache: true,
      hidden: true,
      roles: ['rule_template']
    }
  }, {
    path: 'risk-manage/list',
    name: 'RiskManageList',
    component: () => import('@/views/system/risk-manage/index.vue'),
    meta: {
      title: '风控方案管理',
      hidden: true,
      roles: ['rule_template']
    }
  }, {
    path: 'alarm',
    name: 'Alarm',
    component: () => import('@/views/regulation/alarm/index.vue'),
    meta: {
      title: '风险预警策略'
    }
  }]
}

export default riskRulesRouter
