


























import { Vue, Component, Prop } from 'vue-property-decorator'
import { isExternal } from '../../../utils/validate'
import { SettingFunction } from '../types'
import NoCacheLink from '../../NoCacheLink.vue'

interface Iconfig {
  baseUrl: string
  label: string | SettingFunction
  url?: string | SettingFunction
  noCache?: boolean
}

@Component({
  components: {
    NoCacheLink
  }
})
export default class MapLink extends Vue {
  @Prop({
    default: '',
    required: true
  }) value: string

  @Prop({
    default() {
      return {}
    }
  }) rowData: any

  @Prop({
    default: function() {
      return {
        baseUrl: '',
        label: ''
      }
    }
  }) config: Iconfig

  private get isExternal() {
    return isExternal(this.to)
  }

  private get noCache() {
    return this.config?.noCache
  }

  private get to() {
    if (this.config.url) {
      return typeof this.config.url === 'function' ? this.config.url(this.value, this.rowData) : this.config.url
    }
    if (!isExternal(this.value)) {
      return this.config.baseUrl ? `${this.config.baseUrl}/${this.value}` : this.value
    } else {
      return this.value
    }
  }

  private get label() {
    if (this.config.label) {
      if (typeof this.config.label === 'string') {
        return this.config.label || ''
      }
      if (typeof this.config.label === 'function') {
        return this.config.label(this.value, this.rowData)
      }
      return this.config.label
    } else {
      return this.value
    }
  }
}
