import showimg from './showimg'
import showimglist from './showimglist'

let _vue: any

const install = function(Vue: Vue.VueConstructor) {
  if (_vue) {
    return ''
  }
  _vue = Vue
  showimg.install(Vue)
  showimglist.install(Vue)
}

export default {
  install
}
