var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    _vm._l(_vm.actions, function(action) {
      return _c(
        "el-button",
        {
          key: action.action,
          attrs: { type: "primary", size: "mini" },
          on: {
            click: function($event) {
              return _vm.handleClick(action)
            }
          }
        },
        [_vm._v(" " + _vm._s(action.label) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }