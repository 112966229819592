const baseUrl = '../../../img/icons/'
const config = {
  bigScreen: '数据大屏@2x.png',
  dataQuery: '数据查询@2x.png',
  riskRule: '风险策略管理@2x.png',
  tax: '税务管理icon@2x.png',
  user: '默认头像@2x.png',
  linkRight: '椭圆形@2x.png',
  linkLeft: '',
  consignor: '托运人@2x.png',
  carrier: '承运人@2x.png',
  vehicle: '车辆icon@2x.png',
  driver: '司机@2x.png',
  agent: '网络货运企业@2x.png',
  expense: '运费支出@2x.png',
  income: '运费收入@2x.png',
  setting: '齿轮@2x.png',
  riskUnkonw: '风险未知@2x.png',
  riskNone: '无风险@2x.png', // add by zhangjing
  riskLow: '低风险icon@2x.png',
  riskMiddle: '中风险@2x.png',
  riskHigh: '高风险@2x.png',
  keyRiskRule: '关键风险@2x.png',
  generalRiskRule: '一般策略@2x.png',
  mediumRiskRule: '中等风险@2x.png',
  money: '支付@2x.png',
  track: '轨迹@2x.png',
  ok: '签收@2x.png',
  driverLoading: '装货@2x.png',
  driverUnloading: '卸货@2x.png',
  billing: '开单@2x.png',
  access: '接入管理@2x.png',
  systemSetting: '系统设置@2x.png',
  pushMonitor: '推送监控@2x.png',
  refresh: '刷新@2x.png',
  model: '风险模型@2x.png',
  pdf: 'pdf@2x.png'
}

export default Object.keys(config).reduce((pre, cur) => {
  pre[cur] = `${baseUrl}${config[cur]}`
  return pre
}, {})
