var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "range-container" },
    [
      _c("el-input", {
        attrs: { clearable: "" },
        model: {
          value: _vm.start,
          callback: function($$v) {
            _vm.start = $$v
          },
          expression: "start"
        }
      }),
      _c("span", { staticClass: "px-3" }, [_vm._v(_vm._s(_vm.separator))]),
      _c("el-input", {
        attrs: { clearable: "" },
        model: {
          value: _vm.end,
          callback: function($$v) {
            _vm.end = $$v
          },
          expression: "end"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }