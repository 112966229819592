























































































import { Component, Vue } from 'vue-property-decorator'
import { getAlarmRuleList, getPreAlarmRuleList } from '@/api/black_list'
import AlarmPop from '../components/alarm-pop.vue'
import AlarmList from '../components/alarm-list.vue'

@Component({
  name: 'RiskAlarm',
  components: {
    AlarmPop,
    AlarmList
  }
})

export default class extends Vue {
  listLoading = true
  list:any=[]
  data:any={}
  preList:any=[]
  preData:any={}
  currentAlarmId=''
  currentName=''

  listQuery = {
    frequency_types: [1, 2],
    subject_types: [1, 2, 3, 4, 5],
    page_query: {
      index: 1,
      limit: 10
    }
  }

  created() {
    this.getList()
  }

  async getList() {
    this.listQuery.page_query.limit = 100
    this.listLoading = true
    const { data } = await getAlarmRuleList(this.listQuery)
    this.list = data.items
    this.data = {
      items: this.list
    }

    this.getPreList(false)
    await this.$nextTick()
    this.listLoading = false
  }

  async getPreList(next:any) {
    this.listQuery.page_query.limit = 10
    if (next === 'next') {
      this.listQuery.page_query.index++
    }
    const { data } = await getPreAlarmRuleList(this.listQuery)
    if (next === 'next') {
      this.preList = [...this.preList, ...data.items]
    } else {
      this.preList = data.items
    }
    // this.preTotal = data.total
    this.preData = {
      total: data.total,
      items: this.preList
    }
  }

  changeSearch() {
    this.listQuery.page_query.index = 1
    this.getList()
  }

  showPop(row) {
    this.currentAlarmId = row.id
    this.currentName = row.name
    ;(this.$refs.alarmDialog as any).showDialog()
  }
}

