


















import { Vue, Component, Prop } from 'vue-property-decorator'
import TableList from '../TableList/index.vue'

@Component({
  components: {
    TableList
  }
})
export default class extends Vue {
  @Prop({ default: () => [] }) etcInvoiceInfo;

  invoiceListOptions = [{
    field: 'invoiceTypeAlias',
    label: '发票类型',
    filter() {
      return 'ETC发票'
    }
  }, {
    field: 'issueTime',
    label: '开票时间',
    filter: 'timeFilterShowSecond',
    width: 180
  },
  {
    field: 'invoiceCode',
    label: '发票代码',
    width: 150
  },
  {
    field: 'invoiceNumber',
    label: '发票号码',
    width: 150
  },
  {
    field: 'buyerName',
    label: '购买方',
    width: 180
  },
  {
    field: 'sellerName',
    label: '销售方',
    width: 180
  },
  {
    field: 'excludedAmount',
    label: '发票金额(元)',
    filter: 'amountFormatFilter'
  },
  {
    field: 'taxAmount',
    label: '税额(元)',
    filter: 'amountFormatFilter'
  }
  ]
}
