// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.agent-rank-container {\n  background-color: #fff;\n  padding: 16px;\n  margin-top: 16px;\n}\n.agent-rank-container .cell {\n    display: -webkit-box !important;\n    display: -ms-flexbox !important;\n    display: flex !important;\n    padding: 0 !important;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
