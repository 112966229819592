export const SubjectType = {
  IndustrialPark: 0, // 产业园
  Consignor: 1, // 托运人
  Agent: 2, // 网络货运商
  Carrier: 3, // 承运人
  Driver: 4, // 司机
  Vehicle: 5, // 车辆
};
export const SubjectTypeEnum = [
  {
    label: "产业园",
    value: 0,
    field: "",
  },
  {
    label: "托运人",
    value: 1,
    field: "consignor",
  },
  {
    label: "网络货运商",
    value: 2,
    field: "agent",
  },
  {
    label: "承运人",
    value: 3,
    field: "carrier",
  },
  {
    label: "司机",
    value: 4,
    field: "driver",
  },
  {
    label: "车辆",
    value: 5,
    field: "vehicle",
  },
];

export const TimeRangeType = {
  Yesterday: 0,
  Today: 1,
  Month: 2,
  Year: 3,
  Total: 4,
  LastWeek: 5,
};

export const RiskLevel = {
  // add by zhangjing
  0: {
    color: "#686868",
    text: "风险未知",
    class: "verify-unkonw",
  },
  1: {
    color: "#4F7DF9",
    text: "低风险",
    class: "verify-low",
  },
  2: {
    color: "#FF9800",
    text: "中风险",
    class: "verify-middle",
  },
  3: {
    color: "#EE3939",
    text: "高风险",
    class: "verify-high",
  },
  4: {
    color: "#17BB89",
    text: "无风险",
    class: "verify-none",
  },
};

export const riskScoreColor = ["#fa4c40", "#c6d531", "#44d13d", "#2ac58f"];

export const riskScoreMsg = ["欠佳", "一般", "良好", "优秀"];

export const riskScoreTypes = {
  // 0: '没有评分',
  1: "欠佳",
  2: "一般",
  3: "良好",
  4: "优秀",
};

export const RiskLevelMap = {
  // add by zhangjing
  None: 0,
  Low: 1,
  Middle: 2,
  High: 3,
};

export const RiskIcons = {
  //  // add by zhangjing
  0: "riskUnkonw",
  1: "riskLow",
  2: "riskMiddle",
  3: "riskHigh",
  4: "riskNone",
};

export const RiskRuleIcons = {
  关键策略: "keyRiskRule",
  中等策略: "mediumRiskRule",
  一般策略: "generalRiskRule",
};

export const RiskRuleType = [
  {
    value: 1,
    label: "关键策略",
  },
  {
    value: 2,
    label: "中等策略",
  },
  {
    value: 3,
    label: "一般策略",
  },
];

export const RiskRuleKind = {
  1: "轨迹",
  2: "合同",
  3: "运单",
  4: "支付",
  5: "运输节点",
  6: "资质信息",
  7: "经营特征",
  8: "外部风控",
};

export const WaybillStatus = {
  0: "已接单",
  1: "已完成",
};
export const SubWaybillStatusMap = {
  0: "已接单",
  1: "已完成",
};
export const WaybillTypeMap = {
  1: "自营",
  2: "撮合",
};

export const WaybillPayStatus = {
  0: "未支付",
  1: "托运人运费结清",
  2: "承运人运费结清",
  3: "运费已结清",
};
export const WaybillNewPayStatus = {
  0: "未支付",
  1: "部分支付",
  2: "已结清",
};
export const SubWaybillPayStatusMap = {
  0: "未支付",
  1: "托运人运费结清",
  2: "承运人运费结清",
  3: "运费已结清",
};

export const WaybillInvoiceStatus = {
  0: "未开票",
  1: "已开托运人发票",
  2: "已开承运人发票",
  3: "已开托运人与承运人发票",
  4: "已开部分承运人发票状态",
};
export const SubWaybillInvoiceStatusMap = {
  0: "未开票",
  1: "已开托运人发票",
  2: "已开承运人发票",
  3: "已开托运人与承运人发票",
  4: "已开部分承运人发票状态",
};

export const WaybillInvoiceRiskStatus = {
  0: "未评估",
  "1,4": "要素完整",
  2: "要素缺失",
  3: "要素异常",
  // 4: '无风险'
};

export const SubWaybillInvoiceRiskStatusMap = {
  0: "未评估",
  "1,4": "要素完整",
  2: "要素缺失",
  3: "要素异常",
  // 4: '无风险'
};
export const WaybillMarkMap = {
  0: "正常",
  1: "作废",
};

export const ApplyAccessTypeMap = {
  1: "网络货运企业接入",
  2: "分公司接入",
};
export const ApplyAccessType = {
  Agent: 1,
  BranchOffice: 2,
};

export const ApplyAccessStatus = {
  Applying: 0,
  Refused: 1,
  Pass: 2,
  Passed: 9,
  Disable: 3,
  Logout: 4,
  Void: -1,
};
export const ApplyAccessStatusActionMap = {
  [ApplyAccessStatus.Passed]: "启用",
  [ApplyAccessStatus.Disable]: "禁用",
  [ApplyAccessStatus.Void]: "作废",
  [ApplyAccessStatus.Logout]: "牌照注销",
};

export const ApplyAccessStatusMap = {
  [ApplyAccessStatus.Applying]: "申请中",
  [ApplyAccessStatus.Refused]: "已拒绝",
  [ApplyAccessStatus.Passed]: "已通过",
  [ApplyAccessStatus.Disable]: "禁用",
  [ApplyAccessStatus.Logout]: "牌照注销",
};

export const ServiceApplyStatusMap = {
  0: "已申请",
  1: "申请通过",
  2: "申请拒绝",
};

export const ServiceApplyStatus = {
  Applying: 0, // 已申请
  Passed: 1, // 申请通过
  Refused: 2, // 申请拒绝
};

export const ServiceApplyType = {
  1: "自营承运人开票服务",
  2: "托运人开票服务",
  3: "撮合承运人开票服务",
  4: "税务信息授权服务",
};

export const LogIconsMap = {
  0: "money",
  1: "track",
  2: "driverLoading",
  3: "billing",
};

export const InvoiceStatusMap = {
  2: "开票成功",
  5: "发票作废",
  6: "红冲",
};
export const InvoiceStatus = {
  Success: 2,
  Invalid: 5,
  Reverse: 6,
};

export const InvoiceStatusEnum = [
  { value: 2, label: "开票成功" },
  { value: 5, label: "发票作废" },
  { value: 6, label: "红冲" },
];

export const InvoicePushStatusDesc = {
  0: "申请待确认",
  1: "推送失败",
  2: "第一次成功获取开票结果",
  4: "已确认",
  5: "已失效",
  6: "申请撤销",
  7: "发票作废",
  8: "发票红冲",
  9: "推送成功",
  10: "开票失败",
  11: "人工审核",
};

export const InvoiceStatusDesc = {
  1: "开票已受理",
  2: "开票中",
  // 3: '推送失败',
  4: "开票失败",
  5: "开票成功",
  6: "开票部分成功",
  7: "红冲已受理",
  8: "红冲中",
  10: "红冲成功",
  11: "红冲失败",
};

export const IndividualInvoiceStatusDesc = {
  1: "开票已受理",
  2: "推送成功",
  3: "推送失败",
  4: "开票中",
  5: "开票失败",
  6: "开票成功",
};

export const InvoicePushStatus = {
  WaitPush: 0,
  PushFailed: 1,
  ObtainResult: 2,
  Repush: 3,
  Confirmed: 4,
  Invalid: 5,
  ApplyCancel: 6,
  TaxInvalid: 7,
  TaxRed: 8,
  PushSuccess: 9,
};

export const InvoicePushError = {
  TaxInterfaceError: 1,
  ServiceError: 2,
  SystemError: 3,
};

export const InvoicePushErrorMap = {
  1: "税务接口调用异常",
  2: "业务异常",
  3: "系统异常",
};

export const InvoiceTypeMap = {
  1: "托运人发票",
  2: "自营承运人发票",
  3: "撮合承运人发票",
};
export const InvoiceCategoryMap = {
  1: "增值税专票",
  2: "增值税普票",
  3: "其他",
};

export const UserStatus = {
  Normal: 1,
  Forbidden: 2,
};

export const UserStatusMap = {
  1: "正常",
  2: "禁用",
};
export const NoticeTypeMap = {
  1: "公告",
  2: "重要通知",
  3: "通知",
  4: "提示",
};
export const NoticeStatusMap = {
  1: "待发送",
  2: "已发送",
  3: "已通过待发送",
  4: "已拒绝",
  5: "已作废",
};
export const InspectContentType = {
  Track: 1,
  PaymentFlow: 2,
  AdditionalInfo: 3,
};
export const InspectContentTypeMap = {
  1: "轨迹异常",
  2: "非产业园支付",
  3: "补充信息",
  4: "无轨迹",
  5: "未上传装卸货信息",
  6: "非司机本人收款",
  7: "随机",
  8: "小黑卡",
};
export const InspectBusinessTypeMap = {
  1: "轨迹异常",
  3: "补充信息",
  4: "无轨迹",
  5: "未上传装卸货信息",
  7: "随机",
  8: "小黑卡",
};
export const InspectContentPayTypeMap = {
  2: "非产业园支付",
  6: "非司机本人收款",
};
export const InspectTaskStatus = {
  UnSubmit: 0,
  Submited: 1,
  Refused: 2,
  Passed: 3,
  Return: 4,
};
export const InspectTaskStatusMap = {
  0: "待处理",
  1: "待审批",
  2: "审批拒绝",
  3: "审批通过",
  4: "审批退回",
  5: "运单作废",
};
export const InspectLaunchTypeMap = {
  1: "系统抽检",
  2: "人工抽检",
};

export const InspectConfigStatusMap = {
  0: "待配置",
  1: "进行中",
  2: "已结束",
};

export const BlackListSourceType = {
  1: "手动添加",
  2: "数据预警",
  3: "系统添加",
};

export const WaybillAppealStatusType = {
  0: "待处理",
  1: "申诉成功",
  2: "部分申诉成功",
  3: "申诉失败",
  10: "待外部监管处理",
};

export const WaybillAppealSourceType = {
  0: "API",
  1: "企业端",
};

export const InternalStatusListType = {
  0: "待处理",
  1: "通过",
  2: "部分通过",
  3: "不通过",
  20: "不处理",
  "-1": "无需处理",
};

export const BlackListType = {
  1: "开票黑名单",
  2: "规则黑名单",
  3: "开票&规则黑名单",
  4: "规则灰名单",
};
export const agentType = {
  1: "托运人",
  2: "网货企业",
  3: "承运人",
  4: "司机",
  5: "车辆",
  6: "导流人",
};
export const AlarmQuoteStatusMap = {
  1: "启用",
  2: "禁用",
};

export const WhiteStatusMap = {
  1: "启用",
  2: "禁用",
  3: "已失效",
};

export const VerifyModMap = {
  1: "二/三/四要素认证",
  2: "活体认证",
  3: "短信认证",
};

export const VerifyStatusMap = {
  "-1": "未认证",
  0: "不通过",
  1: "通过",
};

export const VerifyStatusEnum = [
  { value: -1, desc: "未认证", color: "warning" },
  { value: 0, desc: "不通过", color: "danger" },
  { value: 1, desc: "通过", color: "success" },
];

export const AuthorityMap = {
  Access: "access",
  ApplyAccessList: "access_apply_list",
  BigScreen: "big_screen",
  BigScreenHome: "datav",
  DataQuery: "data_query",
  Overview: "overview",
  Agent: "agent_query",
  AgentDetail: "agent_detail",
  Consignor: "consignor_query",
  ConsignorDetail: "consignor_detail",
  Carrier: "carrier_query",
  CarrierDetail: "carrier_detail",
  Driver: "driver_query",
  DriverDetail: "driver_detail",
  Vehicle: "vehicle_query",
  VehicleDetail: "vehicle_detail",
  Waybill: "waybill_query",
  WaybillDetail: "waybill_detail",
  SubWaybill: "waybill_query",
  SubWaybillDetail: "waybill_detail",
  Track: "waybill_trajectory",

  Push: "invoice_apply", // 开票推送监控
  TaxPushCarrier: "invoice_apply_query", // 开票申请查询

  RiskRules: "anti_fraud", // 风险规则
  RiskRulesManagement: "anti_fraud_manage", // 风险规则管理
  RiskRukesHistory: "anti_fraud_effective", // 历史生效规则查询
  RiskRuleDetail: "anti_fraud_manage_rule_detail", // 规则详情

  Role: "role_manage",
  RoleList: "role_list_query",

  Authority: "",
  ServiceApply: "serve_apply_manage",
  ServiceApplyList: "serve_apply_list",

  System: "system_config",
  TaxRate: "tax_rate_config",
  User: "user_manage",
  Tax: "invoice_manage", // 税务管理
  TaxInvoiced: "invoice_query", // 已开发票查询

  // invoice_export_excel  //已开发票导出excel'
  // invoice_cancel_red_flush  //'取消红冲标记
  // invoice_red_flush   //标记为红冲
};

export const LogKeyMap = {
  // 创建运单
  source_no: { text: "货源单号", type: "" },
  send_time: { text: "发货时间", type: "time" },
  consignor_name: { text: "托运人姓名", type: "" },
  consignor_identity: { text: "托运人身份证", type: "" },
  goods_type: { text: "货物类型", type: "" },
  goods_weight: { text: "货物重量", type: "" },
  send_region: { text: "发货行政区划", type: "" },
  receive_region: { text: "收货行政区划", type: "" },

  // 完成运单
  receive_time: { text: "运单完成时间", type: "time" },
  sub_waybill_count: { text: "分段分单数", type: "" },
  carrier_count: { text: "承运人数量", type: "" },
  customer_ship_fee: { text: "客户运费", type: "amount" },
  carrier_ship_fee: { text: "承运人运费", type: "amount" },
  adjust_customer_shipping: { text: "调整客户运费", type: "boolean" },
  adjust_carrier_shipping: { text: "调整承运人运费", type: "boolean" },

  // 子运单开始运输
  carrier_name: { text: "承运人名称", type: "" },
  carrier_identity: { text: "承运人证件号", type: "" },
  driver_name: { text: "司机名称", type: "" },
  driver_identity: { text: "司机证件号", type: "" },
  driver_phone: { text: "司机手机号", type: "" },
  plate_number: { text: "车牌号", type: "" },
  sub_waybill_no: { text: "分段分单号", type: "" },
  start_time: { text: "起运时间", type: "time" },
  delivery_location: { text: "起运地点", type: "location" },
  delivery_pic: { text: "起运照片", type: "" },

  // 子运单结束运输
  unload_time: { text: "卸货时间", type: "time" },
  unload_location: { text: "卸货地点", type: "location" },
  unload_pic: { text: "卸货照片", type: "" },

  send_addr: { text: "装货地址", type: "" },
  receive_addr: { text: "卸货地址", type: "" },

  // 上传支付
  payer_name: { text: "付款方名称", type: "" },
  payer_identity: { text: "付款方证件号", type: "" },
  payee_name: { text: "收款方名称", type: "" },
  payee_identity: { text: "收款方证件号", type: "" },
  payer_bank_code: { text: "付款方银行", type: "" },
  payer_bank_account: { text: "付款方账号", type: "" },
  payee_bank_code: { text: "收款方银行", type: "" },
  payee_bank_account: { text: "收款方账号", type: "" },
  pay_amount: { text: "支付金额", type: "amount" },
  pay_time: { text: "支付时间", type: "time" },
  pay_type: { text: "支付方式", type: "" },
  bank_series_no: { text: "银行流水号", type: "" },

  // 上传合同
  contract_number: { text: "合同编号", type: "" },
  contract_type: { text: "合同类型", type: "" },
  contract_expire_start_time: { text: "合同开始时间", type: "time" },
  contract_expire_end_time: { text: "合同截止时间", type: "time" },
  contract_file_url: { text: "合同链接", type: "" },
  contract_subject_a_name: { text: "合同甲方名称", type: "" },
  contract_subject_a_identity: { text: "合同甲方证件号码", type: "" },
  contract_subject_b_name: { text: "合同乙方名称", type: "" },
  contract_subject_b_identity: { text: "合同乙方证件号码", type: "" },

  // 上传轨迹
  device_id: { text: "设备标识", type: "" },
  device_type: { text: "设备类型", type: "" },
  trace_start_time: { text: "轨迹开始时间", type: "time" },
  trace_end_time: { text: "轨迹结束时间", type: "time" },
  trace_start_location: { text: "轨迹起始点", type: "location" },
  trace_end_location: { text: "轨迹终止点", type: "location" },
  trace_count: { text: "轨迹点数", type: "" },

  // 上传保险
  insurance_policy_num: { text: "保单号", type: "" },
  insurance_company: { text: "保单公司", type: "" },
  insurance_type: { text: "保险类型", type: "" },
  insurance_premium: { text: "保费金额", type: "amount" },
  sum_insured: { text: "保额", type: "amount" },

  // 上传补充信息
  additional_content: { text: "补充说明", type: "" },
  additional_file_url: { text: "补充文件", type: "" },
  additional_infos: {
    text: "补充信息",
    type: "jsonArray",
    keys: [
      "additional_content",
      "additional_file_url",
      "platform_type_alias",
      "additional_operator",
    ],
  },
  additional_operator: { text: "操作人", type: "" },
  platform_type_alias: { text: "平台", type: "" },
  // 申请开票,
  apply_no: { text: "申请编号", type: "" },
  invoice_tax_included_amount: { text: "含税金额", type: "amount" },
  invoice_category: { text: "发票种类", type: "" },
  invoice_type: { text: "发票类型", type: "" },
  buyer_name: { text: "买方名称", type: "" },
  buyer_identity: { text: "买方证件号", type: "" },
  seller_name: { text: "卖方名称", type: "" },
  seller_identity: { text: "卖方证件号", type: "" },

  // 确认开票,
  confirm_no: { text: "确认编号", type: "" },

  // 完成开票,
  invoice_code: { text: "发票代码", type: "" },
  invoice_number: { text: "发票编号", type: "" },
  invoice_amount: { text: "含税金额", type: "amount" },
  added_tax_value: { text: "增值税额", type: "amount" },
  other_tax_value: { text: "其他代缴税额", type: "amount" },

  // 开票结果更新, 红冲/作废
  invoice_update_status: { text: "发票最新状态", type: "" },

  // 风控计算
  update_time: { text: "评分时间", type: "time" },
  last_appeal_time: { text: "上一次申诉时间", type: "time" },
  antifraud_result: { text: "风控结果", type: "" },
  last_risk_score: { text: "上一次评分", type: "" },
  anti_fraud_element_status: { text: "风控要素状态", type: "" },
  risk_score: { text: "当前评分", type: "" },
  force_operate: { text: "强制操作", type: "" },

  // 运单申诉
  appeal_no: { text: "申诉编号", type: "" },
  accept_no: { text: "受理编号", type: "" },
  appeal_people: { text: "申诉人", type: "" },
  appeal_result: { text: "申诉结果", type: "" },
  internal_operator: { text: "处理人", type: "" },
  internal_result: { text: "处理结果", type: "" },
  external_result: { text: "外部处理结果", type: "" },
  external_operator: { text: "外部处理人", type: "" },
  appeal_apply_time: { text: "申诉时间", type: "" },
  appeal_over_time: { text: "完结时间", type: "" },

  // 区块链字段,
  chain_status: { text: "链状态", type: "" },
  chain_hash: { text: "交易HASH", type: "" },
  block_hash: { text: "块HASH", type: "" },
  chain_block_number: { text: "区块高度", type: "" },
  chain_time: { text: "上链时间", type: "time" },

  etc_invoice_amount: { text: "ETC发票金额", type: "amount" },
  etc_invoice_count: { text: "ETC发票张数", type: "" },
  // 作废
  delete_reason: { text: "作废原因", type: "" },
  delete_time: { text: "作废时间", type: "time" },
};

export const hardDeviceMap = {
  // 设备
  deviceStatus: [
    {
      value: "",
      label: "全部",
    },
    {
      value: "1",
      label: "在线",
    },
    {
      value: "2",
      label: "离线",
    },
    {
      value: "3",
      label: "未激活",
    },
  ],
  //  充电状态
  chargerState: [
    {
      value: "",
      label: "全部",
    },
    {
      value: "0",
      label: "未充电",
    },
    {
      value: "1",
      label: "充电中",
    },
  ],
  powerRate: ["5", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
};

export const Colors = [
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
];

export const ColorsMap = {
  beidou: "#4169E1",
  beidou_new: "#4169E1",
  APP: "#E066FF",
  中交: "#CDCD00",
  中交兴路: "#8470FF",
  北斗: "#A2CD5A",
  小黑卡: "#000000",
  未知: "#EE9A00",
};
