import request from '@/utils/request'
import { IUpdateTaxRateParams } from '@/api/types'


export const getTaxRateConfigList = () => request({
  url: '/tax_rate_config/list',
  method: 'post'
})

export const updateTaxRate = (params: IUpdateTaxRateParams) => request({
  url: '/tax_rate_config/update',
  method: 'post',
  data: params
})
// 开票服务状态
export const getInvoiceActive = () => request({
  url: '/tax_rate_config/get_invoice_active',
  method: 'post'
})
// 开启关闭税务服务
export const swtichTaxServe = () => request({
  url: '/tax_rate_config/switch_tax_serve',
  method: 'post'
})
// 认证服务商
export const getCertifyProviderList = () => request({
  url: '/auth/provider',
  method: 'post'
})
export const modifyCertifyProvider = (params:{
  content:string
  id:string
}) => request({
  url: '/auth/provider/modify',
  method: 'post',
  data: params
})

export const getIdentifyTypeList = () => request({
  url: '/certification/type',
  method: 'post'
})
export const modifyIdentifyType = (params:{
  content:string
  id:string
}) => request({
  url: '/certification/type/modify',
  method: 'post',
  data: params
})

export const employedPageApi = (params: any) => request({
  url: '/self/employed/selfPage',
  method: 'post',
  data: params
})
export const employedApplyPageApi = (params: any) => request({
  url: '/self/employed/applyPage',
  method: 'post',
  data: params
})
export const employedInvoicePageApi = (params: any) => request({
  url: '/self/employed/invoicePage',
  method: 'post',
  data: params
})
export const employedInvoiceCallbackApi = (params: any) => request({
  url: '/self/employed/invoiceCallback',
  method: 'post',
  data: params
})
export const employedReportParamApi = (params: any) => request({
  url: '/self/employed/reportParam',
  method: 'post',
  data: params
})
