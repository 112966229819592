





















































































































































































































































import { Vue, Component } from "vue-property-decorator";
// import { parseTime } from '@/utils/index.ts'
import {
  getRuleDetail,
  addRule,
  updateRule,
  getGeneralExecutor,
  getExceptionType
} from "@/api/riskrules";
// import { IRiskRule } from '@/api/types'
import CodeEditor from "@/components/CodeEditor/index.vue";
import { RiskRuleType, RiskRuleKind } from "@/common/constant";
import AreaList from "@/common/components/AreaList/index.vue";
import _ from "lodash";
import { UserModule } from "@/store/modules/user";

// 1 关键策略
// 2 中等策略
// 3 一般策略

const riskRuleKind = Object.keys(RiskRuleKind).map(key => {
  return {
    value: Number(key),
    label: RiskRuleKind[key]
  };
});
@Component({
  name: "RiskRuleDetail",
  components: {
    CodeEditor,
    AreaList
  }
})
export default class RuleDetail extends Vue {
  public riskRuleType = RiskRuleType;
  public riskRuleKind = riskRuleKind;
  editStatus = false;
  rules = {
    name: [
      {
        required: true,
        message: "请输入规则名称",
        trigger: "blur"
      },
      {
        max: 500,
        message: "最长输入500字符",
        trigger: "blur"
      }
    ],
    // effective_time: [{
    //   required: true,
    //   message: '请选择规则生效时间',
    //   trigger: 'blur'
    // }],
    description: [
      {
        required: true,
        message: "请输入规则描述",
        trigger: "blur"
      },
      {
        max: 500,
        message: "最长输入500字符",
        trigger: "blur"
      }
    ]
  };

  commandRules = {
    executor: [
      {
        required: true,
        message: "请输入执行函数名",
        trigger: "blur"
      },
      {
        max: 500,
        message: "最长输入500字符",
        trigger: "blur"
      }
    ],
    result_msg: [
      {
        required: true,
        message: "请输入规则执行失败描述",
        trigger: "blur"
      },
      {
        max: 500,
        message: "最长输入500字符",
        trigger: "blur"
      }
    ]
  };

  formData: any = {
    create_time: 0,
    effective_time: "",
    description: "",
    effective: null,
    business_mode: null,
    areaList: [
      {
        province: "",
        city: "",
        district: ""
      }
    ],
    id: "",
    ineffective_time: 0,
    name: "",
    type: 1,
    code: "",
    command: "",
    general_executor: false,
    category: 1,
    exception_type: 21,
    business_type: null,
    risk_score: 0,
    if_appeal: false,
    allow_report: true,
    if_cross_park: false,
    status: true
  };

  command: any = {
    result_msg: "",
    executor: ""
  };

  exceptionTypes: any = [];
  businessTypes: any = [
    {
      name: "自营",
      value: 1
    },
    {
      name: "撮合",
      value: 2
    }
  ];

  generalExecutorCode = "";
  isCreate = false;
  originContent = "";
  content = "";
  canEdit = true;

  get pickerOptions() {
    return {
      disabledDate(date) {
        date.setHours(24);
        date.setMinutes(0);
        date.setMinutes(0);
        const time = date.getTime();
        return time < Date.now();
      }
    };
  }

  get id() {
    return this.$route.params.id || "";
  }

  handleAgentRegisterDistrictChange(address: any) {
    this.formData.areaList[address.index].city = address.city;
    this.formData.areaList[address.index].district = address.district;
    this.formData.areaList[address.index].province = address.province;
  }

  addAreaList() {
    this.formData.areaList.push({
      province: "",
      city: "",
      district: ""
    });
  }

  minusAreaList(index) {
    this.formData.areaList.splice(index, 1);
  }

  async created() {
    if (this.id === "create") {
      this.isCreate = true;
    }
    const res = await getExceptionType();
    this.exceptionTypes = res.data.exception_type_dto_list;

    const { data } = await getGeneralExecutor();

    this.generalExecutorCode = data.code;
    this.getData();

    this.editStatus = UserModule.username === "bsm";
  }

  async getData() {
    // 修改
    if (!this.isCreate) {
      const { data } = await getRuleDetail(this.id);

      const areaList = [];
      if (data.district_code) {
        const codeList = data.district_code.split(",");
        codeList.forEach(item => {
          const itemList = item.split("-");
          areaList.push({
            province: itemList[0],
            city: itemList[1],
            district: itemList[2]
          });
        });
      }
      if (data) {
        // this.formData = Object.assign({}, data, {
        //   effective_time: (data.effective_time || ''),
        //   areaList: areaList
        // })
        this.formData = {
          ...data,
          effective_time: data.effective_time || "",
          areaList: areaList.length
            ? areaList
            : [
                {
                  province: "",
                  city: "",
                  district: ""
                }
              ]
        };
        this.formData.business_mode =
          this.formData.business_mode === 0
            ? null
            : this.formData.business_mode;
        this.command = JSON.parse(data.command) || {};
        this.originContent = data.code;
      }
    }
  }

  handleRuleTypeChange(v) {
    if (v !== 2) {
      this.formData.if_appeal = false;
    }
  }

  handleTypeChange(isGeneral) {
    if (this.isCreate) {
      this.canEdit = !isGeneral;
    }
    if (isGeneral) {
      this.originContent = this.generalExecutorCode;
      this.formData.code = this.generalExecutorCode;
    } else {
      this.originContent = "";
    }
  }

  formValid() {
    const ruleFormDom: any = this.$refs.ruleForm;
    const commandForm: any = this.$refs.commandForm;
    return new Promise((resolve, reject) => {
      ruleFormDom.validate(isValid => {
        if (isValid) {
          if (this.formData.general_executor) {
            resolve(true);
            return;
          }
          commandForm.validate(v => {
            if (v) {
              resolve(true);
            } else {
              reject(new Error("表单校验失败"));
            }
          });
        } else {
          reject(new Error("表单校验失败"));
        }
      });
    });
  }

  handleDisabledDate(time) {
    const nowTime = Date.now();
    if (time < nowTime) {
      return true;
    } else {
      return false;
    }
  }

  handleSave() {
    if (!this.formData.general_executor) {
      this.formData.command = JSON.stringify(this.command);
    }

    const params: any = this.formData;

    const districtList = [];
    if (this.formData.areaList.length) {
      this.formData.areaList.forEach(item => {
        districtList.push(
          item.district
            ? item.district
            : item.city
            ? item.city
            : item.province
            ? item.province
            : ""
        );
      });
    }
    params.district_code = districtList.join(",");
    params.business_mode = params.business_mode || 0;

    this.formValid()
      .then(res => {
        if (this.isCreate) {
          delete params.id;
          addRule(params).then(res => {
            this.$message.success("创建风险规则成功");
            this.getData();
          });
        } else {
          updateRule(params).then(res => {
            this.$message.success("修改风险规则成功");
            this.getData();
          });
        }
      })
      .catch(e => {
        this.$message.error("请检查表格");
      });
  }

  handleCodeChange(code: string) {
    if (this.isCreate && this.formData.general_executor) {
    } else {
      this.formData.code = code;
    }
  }

  handleStatusRadio(e) {
    if (!e) {
      this.$confirm("禁用规则可能影响运单的风控结果，确认禁用吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(res => {
          this.formData.status = e;
        })
        .catch(e => {
          this.formData.status = true;
        });
    }
  }
}
