










import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import LineChart from '../../components/Charts/LineChart.vue'

@Component({
  components: {
    LineChart
  }
})
export default class extends Vue {
    @Prop() data: any
    @Prop({ default: [] }) keys: { field: string, label: string } []
    @Prop({ default: 'timestamp' }) xField: string
    @Prop({ default: 'bill_count' }) yField: string

    private lineChartData: any[] = []

    @Watch('data', { deep: true })
    onDataChange(data) {
      console.log('data change')
      this.lineChartData = this.genLineData(data, this.keys)
    }

    @Watch('keys')
    onKeysChange(keys) {
      console.log('keys change')
      this.lineChartData = this.genLineData(this.data, keys)
    }

    genLineData(data, keys) {
      const lineData = []
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const d = data[key.field]

        if (d && Array.isArray(d)) {
          for (let j = 0, len = d.length; j < len; j++) {
            lineData.push(Object.assign({
              _type: key.label
            }, d[j]))
          }
        }
      }
      return lineData
    }
}
