import { parseTime } from '@/common/utils'
import moment from 'moment'
import {
  WaybillStatus,
  SubWaybillStatusMap,
  WaybillPayStatus,
  SubWaybillPayStatusMap,
  WaybillInvoiceStatus,
  SubWaybillInvoiceStatusMap,
  WaybillInvoiceRiskStatus,
  SubWaybillInvoiceRiskStatusMap,
  ApplyAccessTypeMap,
  InvoicePushStatusDesc,
  LogKeyMap,
  VerifyModMap,
  VerifyStatusMap
} from '@/common/constant'
// Filter for article status
export const articleStatusFilter = (status: string) => {
  const statusMap: {
    [key: string]: string
  } = {
    published: 'success',
    draft: 'info',
    deleted: 'danger'
  }
  return statusMap[status]
}

// Filter to uppercase the first character
export const uppercaseFirstChar = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function timeFilter(time: any, format ? : string) {
  if (!time || time === '0') {
    return '-'
  }
  return parseTime(time, format || '{y}.{m}.{d}')
}

export function timeFilterShowSecond(time: any, format ? : string) {
  if (!time || time === '0') {
    return '-'
  }
  return parseTime(time, format || '{y}.{m}.{d} {h}:{i}:{s}')
}

export function timeDurationFilter(time: number) {
  const m = moment.duration(time, 'milliseconds')
  const hours = m.asHours()
  // const mins = Math.floor(m.asMinutes() - 60 * hours)
  return `${hours.toFixed(2)}`
}

export const waybillStatus = (status: number) => {
  const desc: string = WaybillStatus[status]

  return desc || ''
}
export const subWaybillStatus = (status: number) => {
  const desc: string = SubWaybillStatusMap[status]
  return desc || ''
}
export const waybillPayStatus = (status: number) => {
  const desc: string = WaybillPayStatus[status]
  return desc || ''
}
export const subWaybillPayStatus = (status: number) => {
  const desc: string = SubWaybillPayStatusMap[status]
  return desc || ''
}
export const waybillInvoiceStatus = (status: number) => {
  const desc: string = WaybillInvoiceStatus[status]
  return desc || ''
}
export const subWaybillInvoiceStatus = (status: number) => {
  const desc: string = SubWaybillInvoiceStatusMap[status]
  return desc || ''
}

export const waybillInvoiceRiskStatus = (status: number) => {
  const desc: string = WaybillInvoiceRiskStatus[status || 0]
  return desc || ''
}
export const subWaybillInvoiceRiskStatus = (status: number) => {
  const desc: string = SubWaybillInvoiceRiskStatusMap[status || 0]
  return desc || ''
}
export const invoicePushStatus = (status: number) => {
  const desc: string = InvoicePushStatusDesc[status]
  return desc || ''
}
export const verifyMod = (mode: number) => {

  const desc: string = VerifyModMap[mode]
  return desc || ''
}
export const verifyStatus = (status: number) => {
  if (status == undefined) {
    return ''

  }
  const desc: string = VerifyStatusMap[status.toString()]
  return desc || ''
}


export const logKeyName = (key: string) => {
  const obj: any = LogKeyMap[key]
  return (obj && obj.text) || ''
}
// export const logKeyContent = (content: string, key: string) => {
//   const obj: any = LogKeyMap[key]
//   if (!obj.type||!obj) {
//     return content;
//   }
//   let type = obj.type
//   if(!type){
//     return content;
//   }
//   let str = ''
//   switch (type) {
//     case 'time':
//       str = timeFilter(content, '{yyyy}年{mm}月{dd}日 {hh}:{ii}:{ss}');
//       break;
//     case 'amount':
//       str = amountFormatFilter(content);
//       break;
//     case 'jsonArray':
//       let keys = obj.keys
//       content.forEach(c => {
//         keys.forEach(k => {
//           str += c[k] + '<br/>'
//         })
//         str += '<br/>'
//       })
//        var dom = document.createElement('div')
//       dom.innerHTML=str
//       return dom
//       break;
//     default:
//       break;
//   }
//   return str;
// }

export const applyAccessType = (type: number) => {
  const desc: string = ApplyAccessTypeMap[type]
  return desc || ''
}
export const amountFormatWithUnit = (amount: number | string, tailNum) => {
  if (amount == null) {
    return ''
  }
  const unitList = [{
    amount: 1000,
    unit: '',
    tailNum: 2
  }, {
    amount: 10000000,
    unit: '万',
    tailNum: 4
  }, {
    amount: 100000000000,
    unit: '亿',
    tailNum: 4
  }, {
    amount: 1000000000000000,
    unit: '万亿',
    tailNum: 4
  }]
  let val = Number(amount)
  let unit = ''
  let index = 3
  let u
  while (index >= 0) {
    u = unitList[index]
    if (val < u.amount) {
      index--
    } else {
      break
    }
  }
  val = val / u.amount
  unit = u.unit
  let fixNum = u.tailNum
  if (tailNum !== undefined) {
    fixNum = tailNum
  }

  return `${val.toFixed(fixNum)}${unit}`
}

export const amountFormatFilter = (amount: number | string, tailNum = 2) => {
  if (amount == null) {
    return ''
  }
  // const unitList = [{
  //   amount: 1000,
  //   unit: ''
  // }, {
  //   amount: 10000000,
  //   unit: '万'
  // }, {
  //   amount: 100000000000,
  //   unit: '亿'
  // }, {
  //   amount: 1000000000000000,
  //   unit: '万亿'
  // }]
  // let val = Number(amount)
  // let unit = ''
  // let index = 3
  // let u
  // while (index >= 0) {
  //   u = unitList[index]
  //   if (val < u.amount) {
  //     index--
  //   } else {
  //     break
  //   }
  // }
  // val = val / u.amount
  // unit = u.unit

  // return `${val.toFixed(tailNum)}${unit}`
  return (Number(amount) / 1000).toFixed(tailNum)
}

export const volume = (value: number | string) => {
  const val = Number(value) / 1000000

  if (Number.isNaN(val)) {
    return ''
  }
  return val.toFixed(2)
}

export const weight = (value: number | string, unit = 1000) => {
  const val = Number(value) / unit

  if (Number.isNaN(val)) {
    return ''
  }

  return val.toFixed(2)
}

export const riskScore = (value: any) => {
  if (value < 0) {
    return '-'
  }
  return value
}

export const bool=(value:any)=>{
  return value?'是':'否'
}

export const antiBool=(value:any)=>{
  return value?'否':'是'
}

export * from './translate'
