import request from '@/utils/request'
import { IGetListParams } from './types'

const path = 'sub_waybill'

export const getSubWaybillList = (params: IGetListParams) =>
  request({
    url: `${path}/findPage`,
    method: 'post',
    data: params
  })

export const getSubWaybillDetail = (params: {id:string}) =>
  request({
    url: `${path}/detail`,
    method: 'post',
    data: params
  })
