var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      { staticStyle: { display: "flex", "flex-direction": "column" } },
      "div",
      _vm.$attrs,
      false
    ),
    _vm._l(_vm.value, function(text) {
      return _c("span", [_vm._v(" " + _vm._s(text) + " ")])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }