var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          staticClass: "bg-white",
          staticStyle: {
            padding: "40px",
            height: "100%",
            width: "100%",
            position: "relative"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "font-weight": "700", "font-size": "20px" } },
            [
              _vm._v(
                " " + _vm._s(_vm.mode === "detail" ? "查看详情" : "审核") + " "
              )
            ]
          ),
          _c("div", { staticStyle: { "margin-top": "24px" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between"
                }
              },
              [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("div", [
                    _vm._v("企业名称：" + _vm._s(_vm.rowData.agentName))
                  ]),
                  _c("div", { staticStyle: { "margin-left": "32px" } }, [
                    _vm._v(
                      " 业务时间范围：" +
                        _vm._s(_vm.rowData.businessMonth) +
                        " "
                    )
                  ])
                ]),
                _vm.rowData.state === 5
                  ? _c(
                      "div",
                      {
                        staticClass: "text-primary",
                        staticStyle: {
                          cursor: "pointer",
                          "text-decoration": "underline"
                        },
                        on: { click: _vm.handleDownload }
                      },
                      [_vm._v(" 下载申报明细 ")]
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticClass: "mt-16" },
              [
                _vm._v(" 合规业务统计： "),
                _c(
                  "el-table",
                  {
                    staticClass: "mt-16",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, border: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "title", label: "统计范围", width: "180" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "number1",
                        label: "合规业务产值(元)",
                        "min-width": "130"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "number2",
                        label: "税额对应产值(元)",
                        "min-width": "130"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "number3",
                        label: "税额产值与业务产值差异率",
                        "min-width": "130"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-16" },
              [
                _vm._v(" 本期申报税额明细： "),
                _c(
                  "el-table",
                  {
                    staticClass: "mt-16",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.detailTableData, border: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "applyMonth",
                        label: "业务时间",
                        width: "150"
                      }
                    }),
                    _vm._l(_vm.tableKeys, function(item) {
                      return _c("el-table-column", {
                        key: item.key,
                        attrs: {
                          prop: item.key,
                          label: item.label,
                          "min-width": item.width
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm.inEditMode
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "flex",
                                          staticStyle: {
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "mr-1",
                                            model: {
                                              value: scope.row[item.key].edit,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row[item.key],
                                                  "edit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row[item.key].edit"
                                            }
                                          }),
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                trigger: "hover",
                                                placement: "top"
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row[item.key].origin
                                                  )
                                                )
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "reference" },
                                                  slot: "reference"
                                                },
                                                [
                                                  scope.row[item.key].changed
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-refresh-left",
                                                        staticStyle: {
                                                          cursor: "pointer"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            scope.row[
                                                              item.key
                                                            ].edit =
                                                              scope.row[
                                                                item.key
                                                              ].origin
                                                          }
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "flex" },
                                        [
                                          _c("div", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              _vm._s(scope.row[item.key].value)
                                            )
                                          ]),
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                trigger: "hover",
                                                placement: "top"
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row[item.key].origin
                                                  )
                                                )
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "reference" },
                                                  slot: "reference"
                                                },
                                                [
                                                  scope.row[item.key].changed
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-warning-outline"
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _c("div", { staticClass: "mt-16" }, [_vm._v(" 税务文件数据： ")]),
            _vm.taxDataFileList.length
              ? _c(
                  "div",
                  { staticClass: "mt-16" },
                  _vm._l(_vm.taxDataFileList, function(item, idx) {
                    return _c(
                      "span",
                      { key: idx, staticStyle: { "margin-right": "24px" } },
                      [
                        _vm._v(" 文件名" + _vm._s(idx + 1) + "："),
                        _c(
                          "span",
                          {
                            staticClass: "text-primary",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.handleGoUrl(item.url)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "mt-16" }, [_vm._v("无")]),
            _c("div", { staticClass: "mt-16" }, [_vm._v(" 企业补充材料： ")]),
            _vm.companyReplenishFileList.length
              ? _c(
                  "div",
                  { staticClass: "mt-16" },
                  _vm._l(_vm.companyReplenishFileList, function(item, idx) {
                    return _c(
                      "span",
                      { key: idx, staticStyle: { "margin-right": "24px" } },
                      [
                        _vm._v(" 文件名" + _vm._s(idx + 1) + "："),
                        _c(
                          "span",
                          {
                            staticClass: "text-primary",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.handleGoUrl(item.url)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "mt-16" }, [_vm._v("无")]),
            _c(
              "div",
              { staticClass: "mt-16" },
              [
                _c(
                  "el-form",
                  {
                    ref: "loginForm",
                    attrs: { model: _vm.rowData, rules: _vm.rules }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "申报说明", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 2,
                            placeholder: "请输入内容",
                            disabled: ""
                          },
                          model: {
                            value: _vm.rowData.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.rowData, "remark", $$v)
                            },
                            expression: "rowData.remark"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "申报人姓名", prop: "lister" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请输入", disabled: "" },
                          model: {
                            value: _vm.rowData.lister,
                            callback: function($$v) {
                              _vm.$set(_vm.rowData, "lister", $$v)
                            },
                            expression: "rowData.lister"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: { "justify-content": "center", "margin-top": "24px" }
            },
            [
              _vm.mode === "declear"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        plain: "",
                        loading: _vm.submitloading
                      },
                      on: { click: _vm.handleRefuse }
                    },
                    [_vm._v("驳回")]
                  )
                : _vm._e(),
              _vm.mode !== "detail"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.submitloading },
                      on: { click: _vm.handleConfirm }
                    },
                    [_vm._v("审核通过")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("i", {
            staticClass: "el-icon-close",
            staticStyle: {
              position: "absolute",
              right: "20px",
              top: "20px",
              cursor: "pointer"
            },
            on: { click: _vm.close }
          }),
          _c(
            "el-dialog",
            {
              attrs: {
                center: "",
                modal: "",
                title: "驳回确认",
                visible: _vm.refuseConfirmVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.refuseConfirmVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                [
                  _vm._v(" 驳回原因："),
                  _c("el-input", {
                    staticStyle: { "margin-top": "8px" },
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.refuseReason,
                      callback: function($$v) {
                        _vm.refuseReason = $$v
                      },
                      expression: "refuseReason"
                    }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.refuseConfirmVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleConfirmRefuse }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }