
















import { DualAxes, G2 } from '@antv/g2plot'

import { Component, Prop, InjectReactive, Watch, Vue } from 'vue-property-decorator'
import { riskScoreColor } from '@/common/constant'
// import { mixins } from 'vue-class-component'
// import ResizeMixin from './mixins/resize1'
// import moment from 'moment'
import _ from 'lodash'

interface IData {
  columnData: any []
  lineData: any []
}

const { registerTheme } = G2
registerTheme('custom-theme', {
  colors10: [...riskScoreColor].reverse(),
  /** 20色板 */
  colors20: [...riskScoreColor].reverse(),
  columnWidthRatio: 0.2
})

@Component({
  name: 'LineChart'
})
export default class extends Vue {
  @Prop({ default: 'line-chart' }) private className!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: '100%' }) private height!: string

  @Prop({ default: 'timeCat' }) private type!: any
  @Prop({ default: '' }) private description!: string
  @Prop({ default: 'type' }) private xField!: string
  @Prop() private tooltipFilter: string
  protected chart!: any
  private sidebarElm ? : Element
  public charType = 'time'
  public format = 'YY-MM-DD'
  private colors = riskScoreColor
  @Prop({
    default() {
      return {
        columnData: [],
        lineData: []
      }
    }
  }) private data!: IData

  @Prop({ default: ['value', 'count'] }) private yField!: string []
  @Prop({ default: '' }) private stackField!: string
  @Prop({ default: '' }) private seriesField!: string
  @Prop({ default: '' }) private title!: string
  @Prop({ default: '' }) private leftTitle!: string
  @Prop({ default: '' }) private rightTitle!: string
  @Prop({ default: 1000 }) private lineMaxLimit: number
  @Prop({ default: 0 }) private lineMinLimit

  @InjectReactive({ from: 'dashboard', default: 'hh:mm' }) timeFormat!: string

  mounted() {
    // this.$nextTick(() => {
    //   this.initChart()
    // })
  }


  private get lineMax() {
    return Math.max(...this.data?.lineData.map(item => item[this.yField[1]])) || this.lineMaxLimit
  }

  private get lineMin() {
    return Math.min(...this.data?.lineData.map(item => item[this.yField[1]])) || this.lineMinLimit
  }

  private get lineAxisMax() {
    let max
    const range = this.lineMax - this.lineMin
    if (range === 0) {
      max = this.lineMaxLimit
    }
    max = Math.ceil(this.lineMax + range * 0.2)
    return Math.min(max, this.lineMaxLimit)
  }

  private get lineAxisMin() {
    let min
    const range = this.lineMax - this.lineMin
    if (range === 0) {
      min = this.lineMinLimit
    }
    min = Math.floor(this.lineMin - range * 0.2)
    return Math.max(min, this.lineMinLimit)
  }

  private get columnSize() {
    if (this.data.lineData && this.data.lineData.length) {
      return Math.floor((100 / this.data.lineData.length))
    } else {
      return 100
    }
  }

  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.destroy()
    this.chart = null
  }

  private get chartConfig() {
    const config:any = {
      data: [this.data?.columnData, this.data?.lineData],
      xField: this.xField,
      yField: this.yField,
      geometryOptions: [{
        geometry: 'column',
        isStack: true,
        seriesField: this.stackField
        // columnWidthRatio: 1,
      }, {
        geometry: 'line',
        seriesField: this.seriesField,
        color: '#1939b1'
      }],
      xAxis: {
        title: null,
        grid: null
      },
      yAxis: {
        [this.yField[0]]: {
          title: this.leftTitle ? {
            text: this.leftTitle
          } : null,
          grid: null,
          line: {},
          tickLine: {}
        },
        [this.yField[1]]: {
          title: this.rightTitle ? {
            text: this.rightTitle
          } : null,
          grid: null,
          line: {},
          tickLine: {},
          nice: true
          // max: this.lineAxisMax,
          // min: this.lineAxisMin
        }
      },
      // animation: false,
      theme: 'custom-theme'
    }
    return config
  }

  private initChart() {
    this.chart = new DualAxes(this.$refs.chart as HTMLElement, this.chartConfig)
    this.chart.render()
  }

  private updateChart() {
    this.chart.update(this.chartConfig)
  }

  @Watch('data', {
    deep: true
  })
  onDataUpdate(data: any, oldData: any) {
    if (!_.isEqual(data, oldData)) {
      this.$nextTick(() => {
        if (this.chart) {
          // this.chart.destroy()
          // this.chart = null
          // this.initChart()
          this.updateChart()
        } else {
          this.initChart()
        }
      })
    }
  }
}

