var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "waybill-addition-container" },
    [
      _c(
        "div",
        {
          staticClass: "content-title",
          staticStyle: { "font-weight": "500", color: "#4d4d4d" }
        },
        [
          _vm._v(" 补充信息"),
          _vm.isAddable
            ? _c("i", {
                staticClass: "el-icon-circle-plus add-btn",
                on: { click: _vm.hanldeAddFile }
              })
            : _vm._e()
        ]
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm._l(_vm.waybillAdditionInfo, function(addtion, idx) {
            return _c("div", { key: idx, staticClass: "addtion-content" }, [
              _c("span", { staticClass: "title" }, [_vm._v("补充文字：")]),
              _c("span", { staticClass: "content" }, [
                _vm._v(_vm._s(addtion.content))
              ]),
              _c("span", { staticClass: "title" }, [_vm._v("补充文件：")]),
              addtion.fileUrls && addtion.fileUrls.length
                ? _c(
                    "span",
                    { staticClass: "file-list" },
                    _vm._l(addtion.fileUrls.split(","), function(url, idx) {
                      return _c(
                        "el-image",
                        {
                          key: idx,
                          staticStyle: { width: "150px", height: "150px" },
                          attrs: {
                            src: url,
                            "preview-src-list": addtion.fileUrls.split(",")
                          }
                        },
                        [
                          url
                            ? _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error", scoped: "url" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleClickImage(url)
                                    }
                                  },
                                  slot: "error"
                                },
                                [
                                  _c("img-icons", {
                                    attrs: { name: "pdf", width: "150px" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e()
            ])
          }),
          !_vm.waybillAdditionInfo.length
            ? _c(
                "div",
                {
                  staticClass: "el-table__empty-block",
                  staticStyle: { height: "100%", width: "1153px" }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "el-table__empty-text",
                      staticStyle: { "font-size": "14px" }
                    },
                    [_vm._v("暂无数据")]
                  )
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showAddFileDialog, title: "上传补充信息" },
          on: {
            "update:visible": function($event) {
              _vm.showAddFileDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                [
                  _c("multi-upload-file", {
                    attrs: { "file-list": _vm.initFileList },
                    on: { input: _vm.handleFileListChange }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "说明" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.explain,
                      callback: function($$v) {
                        _vm.explain = $$v
                      },
                      expression: "explain"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.handleClickSubmit }
            },
            [_vm._v(" 提交 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }