



































































import { Vue, Component } from 'vue-property-decorator'

import AntiFraud from '@/common/business-components/WaybillDetail/AntiFraud.vue'
import { LogIconsMap } from '@/common/constant'
import { uploadMultiFiles } from '@/common/utils'
import {
  WaybillBase,
  WaybillGoods,
  WaybillAppealLog,
  WaybillTransport,
  WaybillPayment,
  WaybillInvoice,
  EtcInvoice,
  WaybillLog,
  WaybillRightPanel,
  WaybillAddition
} from '@/common/business-components'
import {
  IWaybillDetailData,
  IWaybillLogDTO
} from '@/common/business-components/index.d'

import { getWaybillDetail, replenish } from '@/api/waybill'
import WaybillRiskOperation from '@/views/components/WaybillDetail/WaybillRiskOperation.vue'
import WaybillRiskLog from '@/views/components/WaybillDetail/WaybillRiskLog.vue'
// import { forcePassInvoiceApply, recheckAntiFraud } from '@/api/tax'
import { getWaybillLog } from '@/api/log'



const format = '{y}.{m}.{d} {h}:{i}:{s}'

@Component({
  name: 'WaybillDetail',
  components: {
    AntiFraud,
    WaybillRightPanel,
    WaybillAppealLog,
    WaybillBase,
    WaybillGoods,
    WaybillTransport,
    WaybillPayment,
    WaybillInvoice,
    EtcInvoice,
    WaybillRiskOperation,
    WaybillLog,
    WaybillRiskLog,
    WaybillAddition
  }
})
export default class extends Vue {
  logIconsMap = LogIconsMap

  format = format
  loading = false
  data() {
    return {
      waybillBaseInfo: undefined,
      antiFraudInfo: undefined,
      waybillGoodsInfo: undefined,
      waybillTransportInfo: undefined,
      waybillPaymentInfo: undefined,
      waybillInvoiceInfo: undefined,
      etcInvoiceInfo: undefined,
      waybillAppealLog: undefined,
      waybillLogInfo: undefined,
      waybillAdditionInfo: undefined
    }
  }

  created() {
    this.getDetail()
  }

  async getDetail() {
    try {
      this.loading = true
      const { data } = await getWaybillDetail({
        id: this.$route.params.id as string
      })
      const logRes = await getWaybillLog({
        waybill_id: this.$route.params.id as string
      })
      data.waybill_log_info = logRes.data
      this.setData(data)
    } finally {
      this.loading = false
    }
  }

  setData(data) {
    this.waybillData = data
    this.$data.waybillBaseInfo = {
      consignor: data.consignor,
      consignor_did: data.consignor_did,
      filePdfPath: data.file_pdf_path,
      hasContract: data.has_contract,
      sourceWaybillNo: data.source_waybill_no,
      sendTime: data.send_time,
      receiveTime: data.receive_time,
      senderName: data.sender_name,
      senderPhone: data.sender_phone,
      loadDistrict: data.load_district,
      loadAddress: data.load_address,
      receiverName: data.receiver_name,
      receiverPhone: data.receiver_phone,
      receiveDistrict: data.receiver_district,
      receiveAddress: data.receive_address,
      deleted: data.deleted
    }

    this.$data.waybillLogInfo = data.waybill_log_info

    this.$data.antiFraudInfo = data.anti_fraud_statistics_dto || {}

    this.$data.antiFraudInfo.antiFraudWaybillDto.riskGrade = data.risk_grade
    this.$data.antiFraudInfo.antiFraudWaybillDto.riskGradeDesc = data.risk_grade_alias
    this.$data.waybillGoodsInfo = data.waybill_goods_statistics_dto || {}

    this.$data.waybillTransportInfo = Object.assign({
      id: data.id,
      waybillStatusAlias: data.waybill_status_alias,
      forecastTransportKm: data.forecast_transport_km,
      transportKm: data.transport_km,
      trajectoryNum: data.trajectory_num,
      vehicleCount: data.vehicle_count,
      customerShipFee: data.customer_ship_fee,
      sendDistrictCode: data.amap_load_district_code,
      receiveDistrictCode: data.amap_receiver_district_code,
      sendTime: data.send_time,
      receiveTime: data.receive_time
    }, data.sub_waybill_statistics_dto)

    this.$data.waybillPaymentInfo = Object.assign({
      // payStatusAlias: data.pay_status_alias,
      consignorPayStatusAlias: data.consignor_pay_status_alias,
      carrierPayStatusAlias: data.carrier_pay_status_alias,
      customerShipFee: data.customer_ship_fee,
      driverShipFee: data.driver_ship_fee,
      consignorActualAmount: data.consignor_actual_amount,
      carrierActualAmount: data.carrier_actual_amount,
      informationDetailDto: data.information_detail_dto
    }, data.payment_detail_statistics_dto)

    this.$data.waybillInvoiceInfo = Object.assign({
      invoiceStatusAlias: data.invoice_status_alias
    }, data.invoice_statistics_dto)
    this.$data.waybillAdditionInfo = data.additional_dto_list
    this.$data.etcInvoiceInfo = data.etc_detail_dto_list
    this.$data.waybillAppealLog = data.appeal_list
    this.logData = data.waybill_log_dto_list || []
  }

  handleClickTrack() {
    this.$router.push({
      path: '/dataquery/track',
      query: {
        waybill_id: this.waybillData.id
      }
    })
  }

  waybillData: IWaybillDetailData = {
    agent: '',
    carrier_shipping_fee: 0,
    carriers: '',
    consignor: '',
    drivers: '',
    id: '',
    invoice_risk_status: 0,
    invoice_status: 0,
    load_address: '',
    load_district: '',
    pay_status: 0,
    receive_address: '',
    receive_time: 0,
    receiver_district: '',
    receiver_name: '',
    receiver_phone: '',
    send_time: 0,
    sender_name: '',
    sender_phone: '',
    source_waybill_no: '',
    submit_time_end: 0,
    submit_time_start: 0,
    total_fee: 0,
    vehicle_count: 0,
    vehicles: '',
    waybill_no: '',
    waybill_status: 0,
    waybill_submit_time: 0,
    waybill_goods_statistics_dto: null,
    sub_waybill_statistics_dto: null,
    payment_detail_statistics_dto: null,
    invoice_statistics_dto: null,
    anti_fraud_statistics_dto: null,
    waybill_log_dto_list: null,
    risk_grade: 1,
    risk_grade_alias: '',
    file_pdf_path: '',
    has_contract: false,
    load_district_code: '',
    receiver_district_code: ''
  }

  logData: IWaybillLogDTO[] = []

  handleTrack(query: any) {
    this.$router.push({
      path: '/dataquery/track',
      query: query
    })
  }

  handleShowContract() {
    this.$showImgList([this.waybillData.file_pdf_path])
  }

  async handleUploadAddition({ fileList, explain }) {
    this.loading = true
    try {
      const urls = await uploadMultiFiles(fileList)
      await replenish({
        waybill_id: this.waybillData.id,
        file_urls: urls,
        explain: explain
      })
      this.$message.success('补充信息上传成功！')
      this.getDetail()
    } finally {
      this.loading = false
    }
  }
}

