var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticStyle: { "padding-bottom": "16px" } }, [
        _c(
          "span",
          { staticStyle: { "font-weight": "bold", "font-size": "16px" } },
          [_vm._v(_vm._s(_vm.agent_name))]
        ),
        _c("span", { staticStyle: { "margin-left": "16px" } }, [
          _vm._v("风控方案：" + _vm._s(_vm.scheme_name))
        ]),
        _c("span", { staticStyle: { "margin-left": "16px" } }, [
          _vm._v("区域规则：" + _vm._s(_vm.district_rule_switch))
        ])
      ]),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClickTab },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: {
              label: "生效规则(" + _vm.eff_rule_ids.length + ")",
              name: "active"
            }
          }),
          _c("el-tab-pane", {
            attrs: {
              label: "附加规则(" + _vm.add_rule_ids.length + ")",
              name: "add"
            }
          }),
          _c("el-tab-pane", {
            attrs: {
              label: "停用规则(" + _vm.stop_rule_ids.length + ")",
              name: "stop"
            }
          })
        ],
        1
      ),
      _c("search-filter", {
        attrs: { options: _vm.queryFilterOptions, "type-default": "input" },
        on: { search: _vm.handleFilter },
        model: {
          value: _vm.listQuery,
          callback: function($$v) {
            _vm.listQuery = $$v
          },
          expression: "listQuery"
        }
      }),
      _c("bsm-table", {
        attrs: {
          name: "riskrule-management",
          data: _vm.list,
          options: _vm.tableListOptions,
          "list-loading": _vm.listLoading,
          "show-index": true
        },
        on: { "button-action": _vm.handleActionCommand }
      }),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "8px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.showAddDialog }
            },
            [_vm._v(" 添加 ")]
          )
        ],
        1
      ),
      _vm.showAddRuleDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.showAddRuleDialog,
                title: "添加生效规则",
                width: "70%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.showAddRuleDialog = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "search-top" },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "filter-container",
                      staticStyle: {
                        "font-size": "14px",
                        "padding-bottom": "12px"
                      }
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "field-item",
                          staticStyle: {
                            width: "284px",
                            display: "inline-block",
                            "margin-right": "24px"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "mr-2 label",
                              staticStyle: {
                                width: "56px",
                                display: "inline-block",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("风控方案")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-input--small el-input--suffix",
                              staticStyle: { display: "inline-block" }
                            },
                            [
                              _c("map-select", {
                                staticStyle: { width: "220px" },
                                attrs: { config: { list: _vm.schemeNoEnum } },
                                model: {
                                  value: _vm.searchTopForm.scheme_no,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.searchTopForm,
                                      "scheme_no",
                                      $$v
                                    )
                                  },
                                  expression: "searchTopForm.scheme_no"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "field-item",
                          staticStyle: {
                            width: "690px",
                            display: "inline-block"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "mr-2 label",
                              staticStyle: {
                                width: "56px",
                                display: "inline-block",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("适用区域")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-input--small el-input--suffix",
                              staticStyle: { display: "inline-block" }
                            },
                            [
                              _c("area-list", {
                                attrs: {
                                  "province-code": _vm.searchTopForm.province,
                                  "city-code": _vm.searchTopForm.city,
                                  "county-code": _vm.searchTopForm.district
                                },
                                on: {
                                  onDistrictChange:
                                    _vm.handleSearchDistrictChange
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("search-filter", {
                attrs: {
                  options: _vm.allRuleQueryFilterOptions,
                  "type-default": "input"
                },
                on: { search: _vm.handleAllRuleFilter },
                model: {
                  value: _vm.allRuleListQuery,
                  callback: function($$v) {
                    _vm.allRuleListQuery = $$v
                  },
                  expression: "allRuleListQuery"
                }
              }),
              _c("table-list", {
                attrs: {
                  name: "all-rules",
                  data: _vm.allRuleList,
                  options: _vm.allRiskRuleTableOptions
                },
                on: { "button-action": _vm.handleActionCommand }
              }),
              _c("pagination", {
                attrs: {
                  total: _vm.allRuleTotal,
                  page: _vm.allRuleListQuery.page.index,
                  limit: _vm.allRuleListQuery.page.limit,
                  "page-sizes": [5]
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.allRuleListQuery.page, "index", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.allRuleListQuery.page, "limit", $event)
                  },
                  pagination: _vm.getAllRuleList
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showConfirmAddRuleDialog,
            title: "确认添加生效规则",
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.showConfirmAddRuleDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "规则生效时间", prop: "effective_time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "220px" },
                    attrs: {
                      type: "datetime",
                      placeholder: "选择日期时间",
                      "picker-options": _vm.pickerOptions,
                      "default-value": Date.now(),
                      "value-format": "timestamp"
                    },
                    model: {
                      value: _vm.addRuleData.effective_time,
                      callback: function($$v) {
                        _vm.$set(_vm.addRuleData, "effective_time", $$v)
                      },
                      expression: "addRuleData.effective_time"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAddActiveRule }
                },
                [_vm._v(" 确认 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }