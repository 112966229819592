












































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import ErrorLog from '@/components/ErrorLog/index.vue'
import Hamburger from '@/components/Hamburger/index.vue'
// import HeaderSearch from '@/components/HeaderSearch/index.vue'
import LangSelect from '@/components/LangSelect/index.vue'
import Screenfull from '@/components/Screenfull/index.vue'
import SizeSelect from '@/components/SizeSelect/index.vue'
import { isGreenSkin } from '@/utils/common'
import {
  getCoordinationBell
} from '@/api/notice'

@Component({
  name: 'Navbar',
  components: {
    Breadcrumb,
    ErrorLog,
    Hamburger,
    // HeaderSearch,
    LangSelect,
    Screenfull,
    SizeSelect
  }
})
export default class extends Vue {
  isGreenSkin = isGreenSkin()
  unRead = 0
  $bus: any

  get sidebar() {
    return AppModule.sidebar
  }

  get device() {
    return AppModule.device.toString()
  }

  get avatar() {
    return UserModule.avatar
  }

  async created() {
    UserModule.roles.includes('notice_list') && this.getNoticeNum()
  }

  async getNoticeNum() {
    try {
      const { data } = await getCoordinationBell()
      this.unRead = data
    } finally {
    }
  }

  viewNotice() {
    this.$router.push('/regulation/coordination')
  }

  mounted() {
    this.$bus.$on('updateNoticeNum', (e) => {
      this.getNoticeNum()
    })
  }

  toggleSideBar() {
    AppModule.ToggleSideBar(false)
  }

  async logout() {
    await UserModule.LogOut()
    this.$router.push(`/login?redirect=${this.$route.fullPath}`)
  }
}

