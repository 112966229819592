
























































































import { Component, Vue } from 'vue-property-decorator'
import {
  getNoticeList
} from '@/api/notice'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { getEnumArray } from '@/utils'
import { NoticeStatusMap, NoticeTypeMap, agentType } from '@/common/constant'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import Receiver from './components/receiver.vue'

@Component({
  name: 'NoteManage',
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    Receiver
  }
})
export default class extends Vue {
  list = []
  roleList: any = []
  total = 0
  currentNoticeId=''
  listLoading = true
  activeName = 'unSend'
  listQuery = {
    tab_type: 1,
    page_query: {
      index: 1,
      limit: 10
    }
  }

  queryFilterOptions: any[] = [{
    field: ['create_time_start', 'create_time_end'],
    label: '创建时间',
    type: 'dateRange',
    main: true
  }, {
    field: 'notice_type',
    label: '通知类型',
    type: 'select',
    config: {
      list: getEnumArray(NoticeTypeMap)
    },
    main: true
  }, {
    field: 'status',
    label: '通知状态',
    type: 'select',
    config: {
      list: getEnumArray(NoticeStatusMap)
    },
    main: true
  }, {
    field: ['send_time_start', 'send_time_end'],
    label: '发送时间',
    type: 'dateRange',
    main: true
  }, {
    field: 'title',
    label: '通知标题',
    type: 'input',
    main: true
  }, {
    field: 'agent_did',
    label: '接收人',
    component: MapSearchSelect,
    config: {
      field: 'agent'
    },
    main: true
  }]

  tableListOptions = [{
    field: 'id',
    type: 'actionSetting',
    label: '操作',
    align: 'center',
    attrs: {
      actionOptions: [{
        type: 'detail',
        label: '查看详情'
      }, {
        type: 'viewReceiver',
        label: '查看接收人'
      }
      ]
    },
    fixed: true
  }, {
    field: 'status_alias',
    label: '通知状态'
  }, {
    field: 'notice_type_alias',
    label: '通知类型'
  }, {
    field: 'title',
    label: '通知标题',
    type: 'linkLike',
    config: {
      label(val, row) {
        return val
      },
      handle: (v, row) => {
        this.handelActionDetail(row.id)
      }
    }
  }, {
    field: 'subjectType',
    label: '接收人类型',
    filter: (val) => {
      return agentType[val]
    }
  }, {
    field: 'receiver_type_alias',
    label: '接收类型'
  }, {
    field: 'status',
    label: '是否作废',
    filter: (val) => {
      return val === 5 ? '是' : '否'
    }
  }, {
    field: 'feedback_type_alias',
    label: '是否反馈'
  }, {
    field: 'creator',
    label: '创建人'
  }, {
    field: 'create_time',
    label: '创建时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'sender',
    label: '发送人'
  }, {
    field: 'actual_send_time',
    label: '发送时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'timing_time',
    label: '定时发送时间',
    filter: 'timeFilterShowSecond'
  }]

  created() {
    this.initListQuery()
    this.getList()
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
  }

  handleClickTab() {
    this.handleFilter()
  }

  async getList() {
    this.listLoading = true
    const listQuery = JSON.parse(JSON.stringify(this.listQuery))
    const { data } = await getNoticeList(listQuery)
    this.list = data.items
    this.total = data.total
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
    // await this.$nextTick()
    // this.listLoading = false
  }

  handleFilter() {
    this.listQuery.tab_type = this.activeName === 'unSend' ? 1 : 2
    this.getList()
  }

  handleAddNotice() {
    this.handelActionDetail('create')
  }

  handelActionDetail(id: string) {
    this.$router.push({
      path: `/notice/detail/${id}`
    })
  }


  handleButtonAction(action, id, row) {
    if (action === 'detail') {
      this.handelActionDetail(row.id)
    } else if (action === 'viewReceiver') {
      this.currentNoticeId = row.id
      ;(this.$refs.receiver as any).showDialog()
    }
  }
}

