




















































































































































import { Component, Vue } from 'vue-property-decorator'
import { validateForm } from '@/common/utils'
import UploadImage from '@/components/UploadImage/index.vue'
import { coordinationPost, coordinationDetail } from '@/api/notice'
import { UserModule } from '@/store/modules/user'
import {
  getFuzzyList
} from '@/api/users'
import { TagsViewModule } from '@/store/modules/tags-view'
import Tinymce from '@/components/Tinymce/index.vue'
import MultiUploadFile from '@/common/business-components/MultiUploadFile/index.vue'
import { uploadFile, uploadImage } from '@/api/file'
import {
  photoCompress,
  convertBase64UrlToFile
} from '@/utils/compress'
import moment from 'moment'

@Component({
  name: 'Detail',
  components: {
    UploadImage,
    MultiUploadFile,
    Tinymce
  }
})

export default class extends Vue {
  rules = {
    feedback_type: [{ required: true, message: '请选择是否需要回执', trigger: 'change' }],
    receiver: [{ required: true, message: '请填写接收人', trigger: 'change' }],
    title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
    content: [{ required: true, message: '请输入内容', trigger: 'blur' }]
  }

  feedback_type_map = [
    { value: 1, label: '是，纯文字' },
    // { value: 2, label: '是，图片或文件' },
    { value: 3, label: '是，文字加文件' }
  ]

  noticeId: any = ''
  loading = false
  dialogVisible = false
  dialogLoading = false
  queryLoading = false
  timingTimeDesc = ''
  receiverList = []
  industrialParkName = UserModule.industrial_park_name
  form = {
    status: 0,
    receiver: '',
    feedback_type: null,
    file_url: '',
    fileUrls: null,
    title: '',
    content: ''
  }

  fileList = []

  created() {
    this.noticeId = this.$route.params.id
    this.init()
  }

  momentTime(time) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss')
  }

  isDetail() {
    return this.noticeId !== 'create'
  }

  // async handleNoticeVoid() {
  //   this.$confirm('确定作废此协同吗?', '提示', {
  //     confirmButtonText: '确定',
  //     cancelButtonText: '取消',
  //     type: 'warning'
  //   }).then(() => {
  //     this.noticeVoidDo()
  //   })
  // }


  // async noticeVoidDo() {
  //   try {
  //     await noticeVoid({ id: this.noticeId })
  //     this.$message({
  //       type: 'success',
  //       message: '操作成功!'
  //     })
  //     this.closeCurrentView()
  //   } finally {

  //   }
  // }

  // async noticeSendDo() {
  //   try {
  //     await noticeSend({ id: this.noticeId })
  //     this.$message({
  //       type: 'success',
  //       message: '操作成功!'
  //     })
  //     this.closeCurrentView()
  //   } finally {
  //   }
  // }

  // async handleNoticeSend() {
  //   this.$confirm('确定发送此协同吗?', '提示', {
  //     confirmButtonText: '确定',
  //     cancelButtonText: '取消',
  //     type: 'warning'
  //   }).then(() => {
  //     this.noticeSendDo()
  //   })
  // }

  async remoteMethod(query) {
    if (query !== '') {
      this.queryLoading = true
      const listQuery = {
        username: query,
        page: {
          index: 1,
          limit: 5
        }
      }
      try {
        // const { data } = await transportAgentNameLike({ agent_name: query })
        const { data } = await getFuzzyList(listQuery)
        this.receiverList = data
      } finally {
        this.queryLoading = false
      }
    } else {
      this.receiverList = []
    }
  }

  async init() {
    if (this.isDetail()) {
      const { data } = await coordinationDetail({
        id: this.noticeId
      })
      this.form = { ...data }
      this.fileList = data.file_url.split(',')
    }
  }

  handlePreview() {
    this.closeCurrentView()
  }

  closeCurrentView() {
    let view = {}
    TagsViewModule.visitedViews.forEach((item) => {
      if (item.title === '协同管理') {
        view = item
      }
    })
    TagsViewModule.delView(view)
    this.$router.push('/regulation/coordination')
  }

  async onSubmit() {
    if (!await validateForm(this.$refs.form)) {
      return
    }
    // console.log(this.form)
    let urls = ''
    this.loading = true

    try {
      if (this.form.fileUrls && this.form.fileUrls.length) {
        urls = (await Promise.all(this.form.fileUrls.map(async img => {
          if (typeof img === 'string') {
            return img
          }
          if (img.status === 'success') {
            return img.url
          } else {
            const d = new FormData()
            const imgFile = img.raw
            if (imgFile.type.indexOf('image') < 0) {
              d.append('file', imgFile)
              const { data } = await uploadFile(d)
              return data?.resource_url || ''
            } else {
              const base64 = await photoCompress(imgFile, { width: 1000, quality: 0.8 })
              const file = convertBase64UrlToFile(base64, imgFile.name)
              d.append('pic', file)
              const { data } = await uploadImage(d)
              return data?.resource_url || ''
            }
          }
        }))).join(',')
      }

      this.form.file_url = urls
      await coordinationPost(this.form)
      this.$message.success('提交成功')
      this.closeCurrentView()
    } finally {
      this.loading = false
    }
  }
}

