var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carrier-detail-container bg-normal px-8 py-6" },
    [
      _c(
        "el-row",
        [
          _c(
            "dashboard-header",
            {
              attrs: {
                title: _vm.temp.carrier_info.name,
                "time-range": [_vm.params.start_time, _vm.params.end_time]
              },
              on: {
                onTimeRangeChange: _vm.handleTimeChange,
                onBusinessModeChange: _vm.handleBusinessChange,
                onClickTitle: function($event) {
                  return _vm.$refs["subjectDetail"].showSubjectDetail()
                },
                onTimeTypeChange: _vm.handleTimeTypeChange
              }
            },
            [
              _c(
                "span",
                { staticClass: "font-14", staticStyle: { color: "#808080" } },
                [
                  _c("span", { staticClass: "pl-10" }, [
                    _vm._v("电话：" + _vm._s(_vm.temp.carrier_info.phone))
                  ]),
                  _c("span", { staticClass: "pl-10" }, [
                    _vm._v("证件号：" + _vm._s(_vm.temp.carrier_info.identity))
                  ]),
                  _c("span", { staticClass: "pl-10" }, [
                    _vm._v("实名认证结果："),
                    _c(
                      "span",
                      {
                        class: _vm.verifyStatusColor(
                          _vm.temp.subject_verify.status
                        ),
                        on: {
                          click: function($event) {
                            _vm.identityInfoDialog = true
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("verifyStatus")(
                              _vm.temp.subject_verify.status
                            )
                          )
                        )
                      ]
                    )
                  ]),
                  _vm.temp.carrier_info.verify_status !== 1
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.verifyDo()
                            }
                          }
                        },
                        [_vm._v("去认证")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "实名认证信息",
                    visible: _vm.identityInfoDialog
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.identityInfoDialog = $event
                    }
                  }
                },
                [
                  _c(
                    "el-form",
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "认证主体名称" } },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.temp.carrier_info.name))
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10, offset: 2 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "认证主体证件号" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.temp.carrier_info.identity)
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "认证服务商" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.temp.subject_verify.verify_provider
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10, offset: 2 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "认证执行方" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.temp.subject_verify.verify_executor
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "认证方式" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("verifyMod")(
                                          _vm.temp.subject_verify.verify_mod
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10, offset: 2 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "认证时间" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("timeFilterShowSecond")(
                                          _vm.temp.subject_verify.verify_time
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("el-form-item", { attrs: { label: "认证结果" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("verifyStatus")(
                                _vm.temp.subject_verify.status
                              )
                            )
                          )
                        ])
                      ]),
                      _c("el-form-item", { attrs: { label: "认证附加信息" } }, [
                        _vm.temp.subject_verify.additional_infos
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.temp.subject_verify.additional_infos,
                                function(url, index) {
                                  return _c("el-image", {
                                    key: index,
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                      cursor: "pointer",
                                      "margin-left": "10px"
                                    },
                                    attrs: { src: url },
                                    on: {
                                      click: function($event) {
                                        return _vm.viewPic(url)
                                      }
                                    }
                                  })
                                }
                              ),
                              1
                            )
                          : _vm._e()
                      ]),
                      _c("el-form-item", { attrs: { label: "认证返回信息" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.temp.subject_verify.description))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "subject-detail-dialog",
                {
                  ref: "subjectDetail",
                  attrs: {
                    id: _vm.id,
                    type: "carrier",
                    title: "承运人信息",
                    "start-time": _vm.params.start_time,
                    "end-time": _vm.params.end_time,
                    "business-mode": _vm.params.business_mode
                  }
                },
                [
                  _c("risk-score", {
                    staticClass: "pr-3",
                    attrs: {
                      slot: "prefix",
                      value: _vm.temp.risk_score,
                      type: _vm.temp.risk_score_type
                    },
                    slot: "prefix"
                  })
                ],
                1
              ),
              _c(
                "no-cache-link",
                {
                  staticStyle: { position: "absolute", right: "24px" },
                  attrs: {
                    to:
                      "/regulation/regulation-detail?subject_did=" +
                      _vm.id +
                      "&subject_type=3"
                  }
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "mini" } },
                    [_vm._v(" 风险信息查询 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pt-4" },
        [
          _c(
            "el-col",
            { staticClass: "pr-2", attrs: { span: 16 } },
            [
              _c(
                "chart-container",
                {
                  attrs: {
                    title: "运单量",
                    amount: _vm.temp.waybill_count,
                    link: _vm.getLinkUrl(
                      "/dataquery/waybill" +
                        (_vm.params.start_time
                          ? "?waybillCreateTime=" +
                            _vm.params.start_time +
                            "-" +
                            _vm.params.end_time
                          : ""),
                      true
                    ),
                    unit: "单"
                  }
                },
                [
                  _c("line-area-chart", {
                    attrs: {
                      data: _vm.temp.waybill_count_list,
                      "x-field": "timestamp",
                      "y-field": "bill_count",
                      height: "152px"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "pl-2", attrs: { span: 8 } },
            [
              _c(
                "chart-container",
                {
                  attrs: {
                    title: "纳税税额（元）",
                    amount: _vm._f("amountFormatFilter")(_vm.totalTax)
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "152px",
                        position: "relative",
                        top: "-10px"
                      }
                    },
                    [
                      _vm.temp.tax_distribution.length &&
                      _vm.temp.tax_distribution[0].value
                        ? _c("donut-chart", {
                            attrs: {
                              data: _vm.temp.tax_distribution,
                              "angle-field": "value",
                              "color-field": "name",
                              model: "right",
                              type: "amount"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pt-4", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { staticClass: "el-col el-col-4_8" },
            [
              _c("dashboard-card", {
                attrs: {
                  title: "网络货运（家）",
                  amount: _vm.temp.agent_count,
                  unit: "",
                  icon: "agent",
                  link: _vm.getLinkUrl(
                    "/dataquery/agent?" +
                      (_vm.params.start_time
                        ? "startTime=" +
                          _vm.params.start_time +
                          "&endTime=" +
                          _vm.params.end_time
                        : "")
                  )
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "el-col el-col-4_8" },
            [
              _c("dashboard-card", {
                attrs: {
                  title: "托运人（人）",
                  amount: _vm.temp.consignor_count,
                  unit: "",
                  icon: "consignor",
                  link: _vm.getLinkUrl(
                    "/dataquery/consignor?" +
                      (_vm.params.start_time
                        ? "startTime=" +
                          _vm.params.start_time +
                          "&endTime=" +
                          _vm.params.end_time
                        : "")
                  )
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "el-col el-col-4_8" },
            [
              _c("dashboard-card", {
                attrs: {
                  title: "车辆（辆）",
                  amount: _vm.temp.vehicle_count,
                  unit: "",
                  icon: "vehicle",
                  link: _vm.getLinkUrl(
                    "/dataquery/vehicle?" +
                      (_vm.params.start_time
                        ? "startTime=" +
                          _vm.params.start_time +
                          "&endTime=" +
                          _vm.params.end_time
                        : "")
                  )
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "el-col el-col-4_8" },
            [
              _c("dashboard-card", {
                attrs: {
                  title: "司机（人）",
                  amount: _vm.temp.driver_count,
                  unit: "",
                  icon: "driver",
                  link: _vm.getLinkUrl(
                    "/dataquery/driver?" +
                      (_vm.params.start_time
                        ? "startTime=" +
                          _vm.params.start_time +
                          "&endTime=" +
                          _vm.params.end_time
                        : "")
                  )
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "el-col el-col-4_8" },
            [
              _c("dashboard-card", {
                attrs: {
                  title: "运费收入（元）",
                  amount: _vm._f("amountFormatFilter")(_vm.temp.income_sum),
                  unit: "",
                  icon: "income",
                  link: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pt-4", attrs: { gutter: 16 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "home-card" },
              [
                _c("div", { staticClass: "title-box" }, [
                  _c("div", { staticClass: "title" }, [_vm._v(" 近期预警 ")])
                ]),
                _c("alarm-list", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingData,
                      expression: "loadingData"
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["risk_alarm"],
                      expression: "['risk_alarm']"
                    }
                  ],
                  ref: "alarmList",
                  attrs: {
                    data: _vm.alarmData,
                    type: "recently",
                    "no-title": true
                  },
                  on: { showPop: _vm.showPop }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pt-4", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("ticket-card", {
                attrs: {
                  title: "待开票申请（全部）",
                  total: _vm.temp.not_yet_invoice.invoice_count,
                  "total-unit": "单",
                  amount: _vm.temp.not_yet_invoice.invoice_amount_sum,
                  "amount-unit": "元",
                  "link-label": "去处理"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("ticket-card", {
                attrs: {
                  title: "已开承运人发票 (张)",
                  total: _vm.temp.carrier_invoice.invoice_count,
                  "total-unit": "张",
                  amount: _vm.temp.carrier_invoice.invoice_amount_sum,
                  "amount-unit": "元",
                  "link-label": "去查看",
                  link:
                    "/tax/invoiced?invoiceType=2&sellerIdentity=" +
                    _vm.temp.carrier_info.identity +
                    "&sellerName=" +
                    _vm.temp.carrier_info.name +
                    "&" +
                    (_vm.params.start_time
                      ? "invoiceTime=" +
                        _vm.params.start_time +
                        "-" +
                        _vm.params.end_time
                      : "")
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pt-4", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c(
                "chart-container",
                { attrs: { title: "网络货运企业运单量排名(单)" } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "200px" } },
                    [
                      _c("bar-chart", {
                        attrs: {
                          data: _vm.temp.agent_waybill_rank_list,
                          "x-field": "value",
                          "y-field": "name"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c(
                "chart-container",
                { attrs: { title: "网络货运企业运费排名(元)" } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "200px" } },
                    [
                      _c("bar-chart", {
                        attrs: {
                          data: _vm.temp.agent_freight_rank_list,
                          "value-type": "amount",
                          "x-field": "value",
                          "y-field": "name",
                          color: "green"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c(
                "chart-container",
                { attrs: { title: "司机运单量排名(单)" } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "200px" } },
                    [
                      _c("bar-chart", {
                        attrs: {
                          data: _vm.temp.driver_waybill_rank_list,
                          "x-field": "value",
                          "y-field": "name",
                          color: "yellow"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c("chart-container", { attrs: { title: "司机运费排名(元)" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "200px" } },
                  [
                    _c("bar-chart", {
                      attrs: {
                        data: _vm.temp.driver_freight_rank_list,
                        "value-type": "amount",
                        "x-field": "value",
                        "y-field": "name",
                        color: "red"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pt-4", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("chart-container", { attrs: { title: "货物类型" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "200px" } },
                  [
                    _c("pie-chart", {
                      attrs: {
                        data: _vm.temp.goods_type_distribution,
                        "angle-field": "value",
                        "color-field": "name"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("chart-container", { attrs: { title: "收发货地址分布" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "200px" } },
                  [
                    _c("china-map", {
                      attrs: {
                        size: "small",
                        "delivery-distribution-data":
                          _vm.temp.delivery_province_distribution,
                        "receiver-distribution-data":
                          _vm.temp.receiver_province_distribution
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("chart-container", { attrs: { title: "业务类型" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "200px" } },
                  [
                    _c("pie-chart", {
                      attrs: {
                        data: _vm.temp.business_type_distribution,
                        "angle-field": "value",
                        "color-field": "name"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("chart-container", { attrs: { title: "运费支付方式" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "200px" } },
                  [
                    _c("pie-chart", {
                      attrs: {
                        data: _vm.temp.pay_type_distribution,
                        "angle-field": "value",
                        "color-field": "name",
                        type: "amount"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("alarm-pop", {
        ref: "alarmDialog",
        attrs: {
          "current-id": _vm.currentAlarmId,
          "current-name": _vm.currentName,
          "query-name": _vm.queryName
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }