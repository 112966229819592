var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-version-container",
      class: { collapse: _vm.collapse }
    },
    [
      _c("span", { staticClass: "version" }, [
        _vm._v(
          _vm._s(_vm.destination) +
            " " +
            _vm._s(_vm.collapse ? _vm.version : "版本号：" + _vm.version)
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }