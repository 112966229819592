





































import { Component, Prop, Vue, ModelSync } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'TreeItem'
})
export default class extends Vue {
  @Prop() data!: any
  @ModelSync('checked', 'change', { type: Boolean }) checkValue!: boolean


  childrenCheckedStatus: any[] = []
  expanded = true
  roles = UserModule.roleResourceIds

  shouldChecked() {
    let checkedCount = 0
    this.data.children.forEach(c => {
      if (c.checked) {
        checkedCount += 1
      }
    })

    if (checkedCount > 0) {
      return true
    } else {
      return false
    }
  }

  handleChildChange(c, idx) {
    this.updateChild(c, idx)
    this.$emit('change', this.shouldChecked())
  }

  updateChild(child, index) {
    this.data.children.splice(index, 1, child)
  }

  updateChildRecursively(child, isChecked) {
    child.children.forEach(c => {
      c.checked = isChecked
      if (c.children && c.children.length) {
        this.updateChildRecursively(c, isChecked)
      }
    })
  }

  // 根据父tree-item的值，更新子tree-item的值
  handleChange(value) {
    this.data.children.forEach((c, idx) => {
      if (c.checked !== value && this.roles.includes(c.id)) { // 当前用户不拥有的权限展示成灰色，不允许选择
        c.checked = value
        this.updateChildRecursively(c, c.checked)
        this.updateChild(c, idx)
      }
    })
  }
  // 接收父tree-item的值变化
  // @Watch('data')
  // onDataChange(newData, oldData) {
  //   console.log(newData)
  //   console.log(newData)
  //   if (newData.checked !== oldData.checked) {
  //     console.log(newData.description)
  //     const isChecked = newData.isChecked
  //     if (!isChecked) {
  //       this.children.forEach(c => {
  //         c.checked = false
  //       })
  //     }
  //   }
  //   return newData
  // }
}

