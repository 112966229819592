var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "10px 20px 20px 20px" } },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "个体户管理", name: "0" } }),
          _c("el-tab-pane", { attrs: { label: "开票申请查询", name: "1" } }),
          _c("el-tab-pane", { attrs: { label: "发票查询", name: "2" } })
        ],
        1
      ),
      _c(
        "keep-alive",
        [
          _vm.tabIndex === "0" ? _c("Manage") : _vm._e(),
          _vm.tabIndex === "1" ? _c("Invoice") : _vm._e(),
          _vm.tabIndex === "2" ? _c("InvoiceQuery") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }