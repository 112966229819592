


















































































































import { Vue, Component } from "vue-property-decorator";
import {
  getSubsidyTaxDetailApi,
  getTaxDataFileApi,
  downloadTaxDataFileApi
} from "@/api/tax";
import moment from "moment";
@Component({
  name: "DetailDialog",
  components: {}
})
export default class extends Vue {
  editDialogVisible: Boolean = false;
  tableData: any[] = [];
  detailData: any = {};
  detailTableData: any[] = [];
  fileList = [];

  closeDetailModal() {
    this.editDialogVisible = false;
  }

  async openDetailModal(row) {
    try {
      const res = await getSubsidyTaxDetailApi({
        agentDid: row.agentDid,
        month: row.month
      });
      this.detailData = res.data;
      this.detailTableData[0] = res.data.taxData;
      this.tableData = [
        {
          title: "本月",
          number1: this.filterAmount(res.data.matchData.monthBizValue),
          number2: this.filterAmount(res.data.matchData.monthTaxValue),
          number3: this.filterAmount(res.data.matchData.monthDiffRate, 100)
        },
        {
          title: "本年度",
          number1: this.filterAmount(res.data.matchData.yearBizValue),
          number2: this.filterAmount(res.data.matchData.yearTaxValue),
          number3: this.filterAmount(res.data.matchData.yearDiffRate, 100)
        },
        {
          title: "历史累计",
          number1: this.filterAmount(res.data.matchData.totalBizValue),
          number2: this.filterAmount(res.data.matchData.totalTaxValue),
          number3: this.filterAmount(res.data.matchData.totalDiffRate, 100)
        }
      ];
      this.getTaxFile();
      this.editDialogVisible = true;
    } catch (e) {
      //
    }
  }

  filterAmount(amount, scale = 1000) {
    if (amount == null) {
      return "";
    }
    return (Number(amount) / scale).toFixed(2);
  }

  async handleDownload() {
    try {
      const res = await downloadTaxDataFileApi({
        agentDid: this.detailData.agentDid,
        month: this.detailData.month
      });

      const data: any = [res];
      const filename = `税务文件数据.zip`;
      const blob = new Blob(data);
      const downloadElement = document.createElement("a");
      const href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = filename;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
    } catch (e) {
      //
    }
  }

  handleGoUrl(url) {
    window.open(url);
  }

  async getTaxFile() {
    try {
      this.fileList = [];
      const res = await getTaxDataFileApi({
        agentDid: this.detailData.agentDid,
        month: this.detailData.month
      });
      if (res && res.data) {
        res.data.forEach(item => {
          const url = item.url;
          const urlArr = url.split("/");
          const name = urlArr[urlArr.length - 1];
          this.fileList.push({
            name,
            url
          });
        });
      }
    } catch (e) {
      //
    }
  }

  formatMonth(v) {
    return moment(v).format("YYYY-MM");
  }
}
