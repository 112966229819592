var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "list-box" }, [
    !_vm.noTitle
      ? _c("p", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.type === "pre" ? "往期" : "近期") + "预警 ")
        ])
      : _vm._e(),
    _c(
      "ul",
      { staticClass: "item-box" },
      _vm._l(_vm.data.items, function(item, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "item",
            class: "item-" + item.level,
            on: {
              click: function($event) {
                return _vm.showPop(item)
              }
            }
          },
          [
            _c(
              "span",
              { staticClass: "bz", class: "bz-" + item.subject_type },
              [_vm._v(_vm._s(item.subject_type_alias))]
            ),
            _c("i", { staticClass: "el-icon-arrow-right" }),
            _c("div", { staticClass: "con" }, [
              _vm._v(
                " " +
                  _vm._s(item.name) +
                  "大于" +
                  _vm._s(item.threshold_desc) +
                  " "
              )
            ]),
            _c("div", { staticClass: "num" }, [
              _vm._v(" " + _vm._s(item.alarm_subject_count) + " ")
            ]),
            _c("div", { staticClass: "foot" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    item.schedule_run_time
                      ? _vm.momentTime(item.schedule_run_time)
                      : ""
                  )
                )
              ]),
              _c("span", [_vm._v("周期：" + _vm._s(item.frequency_type_alias))])
            ])
          ]
        )
      }),
      0
    ),
    _vm.type === "pre" &&
    _vm.data.items &&
    _vm.data.items.length < _vm.data.total
      ? _c("p", { staticClass: "more" }, [
          _c(
            "a",
            {
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  return _vm.getPreList("next")
                }
              }
            },
            [_vm._v("更多 "), _c("i", { staticClass: "el-icon-arrow-down" })]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }