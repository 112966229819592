

















import { Vue, Component, Prop } from 'vue-property-decorator'
import NoCacheLink from './NoCacheLink.vue'

@Component({
  components: {
    NoCacheLink
  }
})
export default class TicketCard extends Vue {
  @Prop({ default: '', required: true }) title: string
  @Prop({ default: 0, required: true }) total: number
  @Prop({ default: 0, required: true }) amount!: number
  @Prop({ default: '元' }) unit: string
  @Prop({ default: '' }) link!: string
}
