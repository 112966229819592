var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.to && _vm.isExternal
        ? _c(
            "a",
            {
              staticClass: "text-primary",
              attrs: { href: _vm.to, target: "_blank" }
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
        : _vm.to && !_vm.isExternal && !_vm.noCache
        ? _c(
            "router-link",
            { staticClass: "text-primary", attrs: { to: _vm.to } },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
        : _vm.to && !_vm.isExternal && _vm.noCache
        ? _c(
            "no-cache-link",
            { staticClass: "text-primary", attrs: { to: _vm.to } },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _c("span", [_vm._v(_vm._s(_vm.label))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }