







import { Vue, Component, Prop } from 'vue-property-decorator'

import MapInput from './map/MapInput.vue'
import MapSelect from './map/MapSelect.vue'
import MapDateRange from './map/MapDateRange.vue'
import MapLink from './map/MapLink.vue'
import MapLinkLike from './map/MapLinkLike.vue'
import MapDateFormat from './map/MapDateFormat.vue'
import MapDate from './map/MapDate.vue'
import ActionSetting from './ActionSetting/index.vue'
import Buttons from './Buttons/index.vue'
import MapSpans from './map/MapSpans.vue'
import MapRange from './map/MapRange.vue'


const config = {
  input: MapInput,
  select: MapSelect,
  dateRange: MapDateRange,
  date: MapDate,
  dateFormat: MapDateFormat,
  link: MapLink,
  linkLike: MapLinkLike,
  actionSetting: ActionSetting,
  buttons: Buttons,
  spans: MapSpans,
  number: 'el-input-number',
  range: MapRange
}


@Component
export default class TypeMap extends Vue {
  private isComponent = 'span'

  @Prop({ default: 'input' }) componentType!: string
  @Prop({ default: null }) component: any
  @Prop({ default: '' }) componentName: string

  private registerComponent() {
    if (this.component) {
      this.isComponent = 'custom-component'
      this.$options.components[this.isComponent] = this.component
    } else if (this.componentName) {
      this.isComponent = this.componentName
    } else {
      const key = Object.keys(config).find(key => key === this.componentType)
      if (!key) {
        throw new Error(`the field type: ${this.componentType} is not exit in config : ${config}`)
      }
      const component = config[key]
      if (typeof component === 'string') {
        this.isComponent = component
      } else {
        this.isComponent = `component-${key}`
        this.$options.components[this.isComponent] = component
      }
    }
  }

  beforeMount() {
    this.registerComponent()
  }
}
