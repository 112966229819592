

















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
// import areaData from '@/assets/data/area_code_2020.json'
import { getProvinceApi, getCityApi, getDistrictApi } from '@/api/users'


@Component
export default class AreaListAsync extends Vue {
  @Prop({
    default: function() {
      return {}
    }
  }) data: any

  province: any = ''
  city: any = ''
  district: any = ''
  provinceList:any=[]
  cityList:any=[]
  districtList:any=[]

  // get provinceList() {
  //   return areaData
  // }

  @Watch('data', {
    deep: true,
    immediate: true
  })
  onDataChange(val) {
    console.log(val)
    this.getProvinceData()

    if (val && val.province && val.province.code) {
      this.province = { ...this.data.province }
      this.changeProvince()


      if (this.data.city && this.data.city.code) {
        this.city = { ...this.data.city }
        this.changeCity()
      }
      if (this.data.district && this.data.district.code) {
        this.district = { ...this.data.district }
        this.changeDistrict()
      }
    } else {
      this.province = ''
      this.city = ''
      this.district = ''
    }
  }

  // created() {
  //   this.getProvinceData()
  // }

  // get cityList() {
  //   if (this.province) {
  //     return this.province.children
  //   } else {
  //     return []
  //   }
  // }

  // get districtList() {
  //   if (this.city) {
  //     return this.city.children
  //   } else {
  //     return []
  //   }
  // }

  async getProvinceData() {
    const { data } = await getProvinceApi()
    this.provinceList = data
  }

  async getCityData() {
    if (this.province) {
      const { data } = await getCityApi({ province_code: this.province.code })
      this.cityList = data
    }
  }

  async getDistrictData() {
    if (this.city) {
      const { data } = await getDistrictApi({ province_code: this.province.code, city_code: this.city.code })
      this.districtList = data
    }
  }

  changeProvince() {
    this.city = ''
    this.district = ''
    this.getCityData()
    this.emitChange()
  }

  changeCity() {
    this.district = ''
    this.getDistrictData()
    this.emitChange()
  }

  changeDistrict() {
    this.emitChange()
  }

  // @Watch('city')
  // onCityChange() {
  //   this.district = ''
  //   this.changeDistrict()
  // }

  // @Watch('district')
  // onDistrictSelected() {
  //   this.changeDistrict()
  // }

  emitChange() {
    this.$emit('onDistrictChange', {
      province: this.province && this.codeConvert(this.province.code),
      city: this.city && this.codeConvert(this.city.code),
      district: this.district && this.codeConvert(this.district.code)
    })
  }

  codeConvert(code: number) {
    return code.toString().slice(0, 6)
  }
}

