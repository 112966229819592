






































































































import { Component, Vue } from 'vue-property-decorator'
import {
  getUserList,
  switchUserState,
  editUser,
  deleteUser,
  editPassword,
  createUser
} from '@/api/users'
import { getRoleList } from '@/api/role'
import { IAgentData, IGetListParams } from '@/api/types'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { validateForm, getEnumArray } from '@/utils'
import { UserStatus, UserStatusMap } from '@/common/constant'
import MapRoleSelect from '@/views/components/TypeMap/map/MapRoleSelect.vue'
import { UserModule } from '@/store/modules/user'
import AreaListAsync from '@/components/AreaListAsync/index.vue'

@Component({
  name: 'User',
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    AreaListAsync
  }
})
export default class extends Vue {
  list: IAgentData[] = []
  roleList: any = []
  addressData: any = {}
  total = 0
  listLoading = true

  listQuery: IGetListParams = {
    page: {
      index: 1,
      limit: 10
    }
  }

  roleListQuery: IGetListParams = {
    page: {
      index: 1,
      limit: 100
    }
  }

  userForm: any = {
    id: '',
    nickname: '',
    role_id: '',
    username: '',
    province_code: '',
    city_code: '',
    district_code: ''
  }

  showDialog = false
  submitLoading = false
  rules: any = {
    username: [{ required: true, message: '请输入用户名' }],
    nickname: [{ required: true, message: '请输入昵称' }, { max: 20, message: '姓名最长20字符' }],
    role_id: [{ required: true, message: '请选择角色' }]
  }

  queryFilterOptions: any[] = [{
    field: 'username',
    label: '用户名',
    type: 'input',
    main: true,
    fixed: true
  }, {
    field: 'nickname',
    label: '姓名',
    type: 'input',
    main: true
  }, {
    field: 'role_id',
    label: '用户角色',
    component: MapRoleSelect,
    main: true
  }, {
    field: 'state',
    label: '用户状态',
    type: 'select',
    config: {
      list: getEnumArray(UserStatusMap)
    },
    main: true
  }]

  tableListOptions = [{
    field: 'id',
    type: 'actionSetting',
    label: '操作',
    align: 'center',
    width: 50,
    attrs: {
      actionOptions: [{
        type: 'enable',
        label: '启用',
        disabled: (config, row) => {
          return row.state === UserStatus.Normal
        }
      }, {
        type: 'disable',
        label: '禁用',
        disabled: (config, row) => {
          return row.state === UserStatus.Forbidden
        }
      },
      {
        type: 'edit',
        label: '编辑'
      },
      {
        type: 'delete',
        label: '删除'
      },
      {
        type: 'editPassword',
        label: '重置密码',
        disabled: (config, row) => {
          return row.state === UserStatus.Forbidden
        }
      }
      ]
    },
    fixed: true
  }, {
    field: 'username',
    label: '用户名'
  }, {
    field: 'stateDesc',
    label: '用户状态'
  }, {
    field: 'nickname',
    label: '姓名'
  }, {
    field: 'role',
    label: '用户角色'
  }, {
    field: 'provinceName',
    label: '省市区',
    filter: (value, row) => {
      return `${row.provinceName}${row.cityName}${row.districtName}`
    }

  }, {
    field: 'create_time',
    label: '创建时间',
    align: 'center',
    filter: 'timeFilter'
  }, {
    field: 'last_login_time',
    label: '最后一次登录时间',
    align: 'center',
    filter: (value) => {
      if (value === -1) {
        return '-'
      }
      return this.$options.filters.timeFilterShowSecond(value)
    }
  }, {
    field: 'avatar',
    label: '头像',
    type: 'link'
  }]

  created() {
    this.initListQuery()
    this.getList()
  }

  handleAgentRegsiterDistrictChange(address: any) {
    this.userForm.province_code = address.province
    this.userForm.city_code = address.city
    this.userForm.district_code = address.district
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
  }

  async getRoleList() {
    const { data } = await getRoleList(this.roleListQuery)
    this.roleList = data.items
  }

  async getList() {
    this.listLoading = true
    const { data } = await getUserList(this.listQuery)
    this.list = data.items
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }

  handleFilter() {
    this.listQuery.page.limit = 10
    this.getList()
  }

  async handleSubmit() {
    if (!await validateForm(this.$refs.userForm)) {
      return
    }
    this.userForm.province_code = this.userForm.province_code || ''
    this.userForm.city_code = this.userForm.city_code || ''
    this.userForm.district_code = this.userForm.district_code || ''
    this.submitLoading = true
    try {
      if (this.userForm.id) {
        await editUser(this.userForm)
        this.$message.success('保存成功')
      } else {
        const { data } = await createUser(this.userForm)
        this.$confirm(`创建用户${data.username}，密码为:${data.password}，请妥善保管！`, '提示')
      }
      this.showDialog = false
      this.getList()
    } finally {
      this.submitLoading = false
    }
  }

  handleDialogOpen() {
    this.getRoleList()
    const userFormDom: any = this.$refs.userForm
    userFormDom && userFormDom.resetFields()
  }

  handleAddUser() {
    this.userForm = {
      nickname: '',
      role_id: '',
      username: ''
    }
    this.addressData = new Date().getTime()
    this.showDialog = true
  }

  handelActionDetail(action, id, row) {
    if (action === 'enable' || action === 'disable') {
      switchUserState({ id: id }).then(res => {
        this.$message.success(action === 'enable' ? '启用成功' : '禁用成功')
        this.getList()
      })
      return
    }

    if (action === 'edit') {
      this.userForm = Object.assign({}, {
        id: id,
        nickname: row.nickname,
        role_id: row.role_id,
        username: row.username
      })
      this.addressData = {
        province: {
          code: row.province_code,
          name: row.provinceName
        },
        city: {
          code: row.city_code,
          name: row.cityName
        },
        district: {
          code: row.district_code,
          name: row.districtName
        }
      }
      this.showDialog = true
      return
    }

    if (action === 'delete') {
      this.$confirm('此操作将删除用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteUser({
          id: id
        }).then(res => {
          this.$message.success('删除成功')
          this.getList()
        })
      }).catch((e) => {
        console.log(e)
      })
      return
    }

    if (action === 'editPassword') {
      this.$confirm('此操作将重置用户密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        editPassword({
          id: id
        }).then(res => {
          const { data } = res
          this.$confirm(`已为用户${data.username}重置密码，新密码为:${data.password}，请妥善保管！`, '提示')
          if (UserModule.userId !== id) {
            this.getList()
          }
        })
      }).catch((e) => {
        console.log(e)
      })
    }
  }
}

