

















































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { IBlackListData, IGetBlackListParams } from "@/api/types";
// import { getInspectConfigAgentList, getInspectConfigList, findinspectStrategyDetail, findInspectStrategyListNoPage, updateInspectConfig } from '@/api/inspect'
import {
  getBlackList,
  getBlackListDetail,
  updateBlackList,
  removeBlackList,
  exportBlackList,
  getSubjectBrief,
  checkGreyExists
} from "@/api/black_list";
import { EnumActions } from "@/common/business-components/TypeMap/ActionSetting/index.vue";
import Pagination from "@/common/components/Pagination/index.vue";
import SearchFilter from "@/common/business-components/SearchFilter/index.vue";
import BsmTable from "@/views/components/BsmTable.vue";
import {
  BlackListSourceType,
  BlackListType,
  agentType
} from "@/common/constant";
import { getEnumArray, convertToExcel } from "@/common/utils";
// import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
// import UploadImage from '@/common/business-components/UploadImage/index.vue'
import MultiUploadFile from "@/common/business-components/MultiUploadFile/index.vue";
import { Form } from "element-ui";
import { uploadImages, uploadImage, uploadFile } from "@/api/file";
import { photoCompress, convertBase64UrlToFile } from "@/utils/compress";
import moment from "moment";

@Component({
  name: "BlackList",
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    // MapSearchSelect,
    // UploadImage,
    MultiUploadFile
  }
})
export default class extends Vue {
  list: IBlackListData[] = [];
  total = 0;
  listLoading = true;
  subLoading = false;
  disableBlack = false;
  visible = false;
  isEdit = false;
  listQuery: IGetBlackListParams = {
    agentDid: "",
    sourceType: "",
    page: {
      index: 1,
      limit: 10
    }
  };

  subjectTypeList = getEnumArray(agentType);

  formData = {
    subjectType: undefined,
    identityNo: "",
    // allDataBase: false,
    subjectName: "",
    agentDid: "",
    blackStrategy: 0,
    fileUrls: [],
    id: "",
    reason: "",
    legalIdentityNo: ""
  };

  fileList = [];

  rules = {
    subjectType: [
      {
        required: true,
        message: "主体类型必填",
        trigger: "blur"
      }
    ],
    identityNo: [
      {
        required: true,
        message: "证件号码必填",
        trigger: "blur"
      }
    ],
    reason: [
      {
        required: true,
        message: "加入黑名单原因必填",
        trigger: "blur"
      }
    ],
    blackStrategy: [
      {
        required: true,
        message: "拉黑策略必填",
        trigger: "blur"
      }
    ]
  };

  actionTypeMap = {
    [EnumActions.add]: {
      title: "新增抽检策略"
    },
    [EnumActions.detail]: {
      title: "抽检策略查看"
    },
    [EnumActions.edit]: {
      title: "抽检策略编辑"
    }
  };

  currentActionType: EnumActions = null;

  queryFilterOptions = [
    // {
    //   field: 'agentDid',
    //   label: '网络货运企业',
    //   component: MapSearchSelect,
    //   config: {
    //     field: 'agent'
    //   },
    //   main: true
    // },
    {
      field: "sourceType",
      label: "黑名单来源",
      type: "select",
      config: {
        list: getEnumArray(BlackListSourceType)
      },
      main: true
    },
    {
      field: "blackStrategy",
      label: "拉黑策略",
      type: "select",
      config: {
        list: getEnumArray(BlackListType)
      },
      main: true
    },
    {
      field: "agentType",
      label: "黑名单主体类型",
      type: "select",
      config: {
        list: getEnumArray(agentType)
      },
      main: true
    },
    {
      field: "identityNo",
      label: "主体证件号码",
      type: "input",
      main: true
    }
  ];

  tableListOptions = [
    {
      field: "id",
      type: "actionSetting",
      label: "操作",
      attrs: {
        actionOptions: [
          {
            type: EnumActions.edit,
            label: "编辑"
          },
          {
            type: EnumActions.detail,
            label: "查看"
          },
          {
            type: EnumActions.delete,
            label: "移出名单"
          }
          // {
          //   type: EnumActions.enable,
          //   label: "同步全区"
          // }
        ]
      },
      fixed: true
    },
    {
      field: "agentName",
      label: " 黑名单主体名称"
    },
    {
      field: "blackStrategy",
      label: "拉黑策略",
      filter(val) {
        return BlackListType[val];
      }
    },
    {
      field: "agentType",
      label: "黑名单主体类型",
      filter(val) {
        return agentType[val];
      }
    },
    {
      field: "identityNo",
      label: "主体证件号码"
      // type: 'link'
    },
    {
      field: "createTime",
      label: "黑名单加入时间",
      filter: "timeFilterShowSecond"
    },
    {
      field: "reason",
      label: "加入黑名单原因"
    },
    {
      field: "sourceType",
      label: "黑名单来源",
      filter(val) {
        return BlackListSourceType[val];
      }
    },
    {
      field: "createBy",
      label: "添加人"
    }
  ];

  created() {
    this.initListQuery();
    this.getList();
  }

  reSet() {
    (this.$refs.form as Form).resetFields();
    this.formData = {
      subjectType: undefined,
      identityNo: "",
      // allDataBase: false,
      subjectName: "",
      agentDid: "",
      blackStrategy: 0,
      fileUrls: [],
      id: "",
      reason: "",
      legalIdentityNo: ""
    };
    this.fileList = [];
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query);
    }
  }

  async getList() {
    this.listLoading = true;
    const { data } = await getBlackList(this.listQuery);
    this.list = data.items;
    this.total = data.total;
    await this.$nextTick();
    this.listLoading = false;
  }

  async changeSubjectIdentity() {
    const d: any = {
      subjectIdentity: this.formData.identityNo,
      subjectType: this.formData.subjectType
    };
    const { data } = await getSubjectBrief(d);
    this.formData.subjectName = data.subjectName || "当前主体并不在本园区";
  }

  changeSubjectType() {
    this.disableBlack = this.formData.subjectType !== 2;
    this.formData.blackStrategy = 0;
  }

  async getDetail(row) {
    const { data } = await getBlackListDetail(row.id);
    this.formData = { ...data };
    this.formData.subjectType = data.agentType;
    this.formData.subjectName = data.agentName;
    this.fileList = data.fileUrls ? data.fileUrls.split(",") : [];
    this.changeSubjectType();

    this.$nextTick(() => {
      // (this.$refs.mapSearchSelect as MapSearchSelect).init()
      // if (data.blackStrategy === 3) {
      //   this.formData.blackStrategy = ["1", "2"];
      // } else {
      //   const s = data.blackStrategy + ''
      //   this.formData.blackStrategy = s.split(',')
      // }
      this.formData.blackStrategy = data.blackStrategy;
    });
  }

  handleFilter() {
    this.listQuery.page.index = 1;
    this.listQuery.page.limit = 10;
    this.getList();
  }

  handleOpenConfig(val, row) {
    // this.getConfigDetail(val)
    this.visible = true;
  }

  handleCloseConfig() {
    this.reSet();
    this.subLoading = false;
    // this.inspectConfigList = []
  }

  handelActionDetail(id: string) {
    // this.$router.push({
    //   path: `/risk_rules/detail/${id}`
    // })
  }

  // async handleActionSwitch(command, id) {
  //   const text = command === EnumActions.enable ? '启用' : '停用'
  //   const res: any = await swithRuleStatus(id)
  //   if (res.code === 0) {
  //     this.$message.success(`${text}成功`)
  //     this.getList()
  //   } else {
  //     this.$message.error(`${text}失败`)
  //   }
  // }

  async handleSubmit() {
    const {
      subjectType,
      identityNo,
      blackStrategy,
      reason,
      id
    } = this.formData;
    try {
      const valid = await (this.$refs.form as Form).validate();
      if (!valid) {
        return;
      }

      if (blackStrategy === 4 && !this.isEdit) {
        // 规则灰名单
        const { data } = await checkGreyExists({
          agentType: subjectType,
          identityNo
        });
        if (data) {
          const resConfirm = await this.$confirm(
            "该主体已在规则灰名单中，再次操作添加将升级为规则黑名单，确定要添加吗？",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }
          );
          if (resConfirm === "confirm") {
            this.formData.reason =
              this.formData.reason +
              "(第二次设置规则灰名单,自动升级到规则黑名单)";
          }
        }
      }

      let urls = "";
      this.subLoading = true;
      if (typeof this.formData.fileUrls === "string") {
        urls = this.formData.fileUrls;
      } else {
        urls = (
          await Promise.all(
            this.formData.fileUrls.map(async img => {
              if (typeof img === "string") {
                return img;
              }
              if (img.status === "success") {
                return img.url;
              } else {
                const d = new FormData();
                const imgFile = img.raw;
                if (imgFile.type === "application/pdf") {
                  d.append("file", imgFile);
                  const { data } = await uploadFile(d);
                  return data?.resource_url || "";
                } else {
                  const base64 = await photoCompress(imgFile, {
                    width: 1000,
                    quality: 0.8
                  });
                  const file = convertBase64UrlToFile(base64, imgFile.name);
                  // if (file.size >= 2097152) {
                  //   this.$message.error('图片大小应小于2M，请重新选择图片')
                  //   throw new Error('图片上传过大')
                  // }
                  d.append("pic", file);
                  const { data } = await uploadImage(d);
                  return data?.resource_url || "";
                }
              }
            })
          )
        ).join(",");
      }
      // return;

      await updateBlackList({
        agentType: subjectType,
        identityNo: identityNo,
        blackStrategy: blackStrategy,
        id,
        reason: this.formData.reason,
        fileUrls: urls
      });
      this.subLoading = false;
      this.visible = false;
      this.$message.success("保存成功");
      this.getList();
    } catch (err) {
      console.log(err);
      if (err !== "cancel") {
        this.$message.error(err);
      }
      this.subLoading = false;
    }
  }

  handleAdd() {
    this.visible = true;
    this.isEdit = false;
    // something to do
    // this.handelActionDetail('create')
  }

  handleEdit(row) {
    this.visible = true;
    this.isEdit = true;
    this.getDetail(row);
    // something todo
  }

  handleDetail(row) {
    this.visible = true;
    this.getDetail(row);
  }

  handleRemove(id: string, row) {
    this.$confirm(
      `确认将 ${agentType[row.agentType]}—${row.identityNo} 移出黑名单？`,
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error"
      }
    ).then(() => {
      removeBlackList(id)
        .then(() => {
          this.$message.success("移出成功");
          this.getList();
        })
        .catch(err => {
          this.$message.error(err);
        });
    });
  }

  handleActionCommand(command: any, id?, row?) {
    this.currentActionType = command;
    if (command === EnumActions.add) {
      this.handleAdd();
    } else if (command === EnumActions.detail) {
      this.handleDetail(row);
    } else if (command === EnumActions.edit) {
      this.handleEdit(row);
    } else if (command === EnumActions.delete) {
      this.handleRemove(id, row);
    } else if (command === EnumActions.enable) {
      this.handleAllDataBase(id, row);
    }
  }

  handleAllDataBase(id: string, row) {
    this.$confirm(
      `确认将 ${agentType[row.agentType]}—${row.identityNo} 同步到全园区吗？`,
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error"
      }
    ).then(() => {
      row.allDataBase = true;
      updateBlackList(row)
        .then(() => {
          this.$message.success("同步成功");
          this.getList();
        })
        .catch(err => {
          this.$message.error(err);
        });
    });
  }

  handelConditionChange(o) {
    if (o) {
      const arr = o.conditionVal.split(" ");
      if (arr.length) {
        this.formData.legalIdentityNo = arr[arr.length - 1];
      } else {
        this.formData.legalIdentityNo = arr[0];
      }
    } else {
      this.formData.legalIdentityNo = "";
    }
  }

  @Watch("formData.agentDid")
  onAgentDidChange(now, old) {
    if (now && now !== old) {
    }
  }

  async exportList() {
    try {
      this.listLoading = true;
      const res = await exportBlackList(this.listQuery);
      const data = [res];
      const name = `黑名单_${moment()
        .format("YYYY_MM_DD")
        .toString()}`;
      convertToExcel(data, name);
      this.listLoading = false;
    } catch (err) {
      this.listLoading = false;
    }
  }
}
