







































































































































import { Component, Vue } from 'vue-property-decorator'
import { getAntiFraudRuleItemApi, getRuleListExternalApi, addRuleItemApi, updateRuleItemApi, delRuleItemApi } from '@/api/regulation'

import { EnumActions } from '@/common/business-components/TypeMap/ActionSetting/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { Form } from 'element-ui'


@Component({
  name: 'externalAntiFraudDetail',
  components: {
    BsmTable
  }
})
export default class extends Vue {
  list:any = []
  area:any = ''
  desc:any = ''
  popTitle:any = '添加'
  listLoading = true
  subLoading = false
  disableBlack = false
  visible = false
  listQuery = {
    channel: '',
    page: {
      index: 1,
      limit: 10
    }
  }

  riskRuleTypeList = []
  appealTypeList = [{
    label: '无需申诉',
    value: 0
  }, {
    label: '自动申诉',
    value: 1
  }, {
    label: '手动申诉',
    value: 2
  }, {
    label: '无法申诉',
    value: 3
  }]

  appealNodeList = [{
    label: '获取风控结果',
    value: 1
  }, {
    label: '司机信息更新',
    value: 2
  }, {
    label: '车辆信息更新',
    value: 3
  }]

  formData = {
    id: '',
    code: '',
    channel: this.$route.query.channel,
    description: '',
    ruleId: '',
    appealType: '',
    appealNode: ''
  }

  fileList = []

  rules = {
    subjectType: [{
      required: true,
      message: '主体类型必填',
      trigger: 'blur'
    }]
  }


  currentActionType: EnumActions = null

  tableListOptions = [{
    field: 'id',
    type: 'actionSetting',
    label: '操作',
    attrs: {
      actionOptions: [{
        type: EnumActions.edit,
        label: '编辑'
      }, {
        type: EnumActions.delete,
        label: '删除'
      }]
    },
    fixed: true
  }, {
    field: 'code',
    label: '核验项编码'
  },
  // {
  //   field: 'agentType',
  //   label: '黑名单主体类型',
  //   filter(val) {
  //     return agentType[val]
  //   }
  // },
  {
    field: 'description',
    label: '核验项描述'
    // type: 'link'
  }, {
    field: 'appealTypeDesc',
    label: '申诉类型',
    filter(val, row) {
      return val + (row.appealType === 1 ? '（' + row.appealNodeDesc + '）' : '')
    }
  }, {
    field: 'ruleName',
    label: '规则名称'
  }, {
    field: 'resultMsg',
    label: '失败描述'
  }]

  created() {
    this.initListQuery()
    this.getList()
    this.getRuleList()
  }

  reSet() {
    (this.$refs.form as Form).resetFields()
    this.formData = {
      id: '',
      code: '',
      channel: this.$route.query.channel,
      description: '',
      ruleId: '',
      appealType: '',
      appealNode: ''
    }
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
      this.desc = this.$route.query.desc
      this.area = this.$route.query.area
    }
  }

  async getList() {
    this.listLoading = true
    const { data } = await getAntiFraudRuleItemApi(this.listQuery)
    this.list = data
    await this.$nextTick()
    this.listLoading = false
  }

  async getRuleList() {
    const { data } = await getRuleListExternalApi()
    data.forEach((item) => {
      this.riskRuleTypeList.push({
        label: item.name,
        value: item.id
      })
    })
  }

  handleCloseConfig() {
    this.reSet()
    this.subLoading = false
  }

  async handleSubmit() {
    try {
      const valid = await (this.$refs.form as Form).validate()
      if (!valid) {
        return
      }
      this.subLoading = true

      this.formData.id ? await updateRuleItemApi(this.formData) : await addRuleItemApi(this.formData)
      this.subLoading = false
      this.visible = false
      this.$message.success('保存成功')
      this.getList()
    } catch (err) {
      this.$message.error(err)
      this.subLoading = false
    }
  }

  handleAdd() {
    this.visible = true
    this.popTitle = '添加'
  }

  handleEdit(row) {
    this.visible = true
    this.popTitle = '编辑'
    this.formData = { ...row }
  }

  handleRemove(id) {
    this.$confirm('确认将该数据删除吗？', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(() => {
      delRuleItemApi({ id: id }).then(() => {
        this.$message.success('删除成功')
        this.getList()
      }).catch(err => {
        this.$message.error(err)
      })
    })
  }

  handleActionCommand(command:any, id?, row?) {
    this.currentActionType = command
    if (command === EnumActions.edit) {
      this.handleEdit(row)
    } else if (command === EnumActions.delete) {
      this.handleRemove(row.id)
    } else if (command === EnumActions.add) {
      this.handleAdd()
    }
  }
}

