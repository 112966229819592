var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "panel-group", attrs: { gutter: 40 } },
    [
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function($event) {
                  return _vm.handleSetLineChartData("newVisitis")
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-people" },
                [
                  _c("svg-icon", {
                    staticClass: "card-panel-icon",
                    attrs: { name: "peoples" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v(" New Visits ")
                  ]),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: { "start-val": 0, "end-val": 102400, duration: 2600 }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function($event) {
                  return _vm.handleSetLineChartData("messages")
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-message" },
                [
                  _c("svg-icon", {
                    staticClass: "card-panel-icon",
                    attrs: { name: "message" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v(" Messages ")
                  ]),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: { "start-val": 0, "end-val": 81212, duration: 3000 }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function($event) {
                  return _vm.handleSetLineChartData("purchases")
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-money" },
                [
                  _c("svg-icon", {
                    staticClass: "card-panel-icon",
                    attrs: { name: "money" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v(" Purchases ")
                  ]),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: { "start-val": 0, "end-val": 9280, duration: 3200 }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function($event) {
                  return _vm.handleSetLineChartData("shoppings")
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-shopping" },
                [
                  _c("svg-icon", {
                    staticClass: "card-panel-icon",
                    attrs: { name: "shopping" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-panel-description" },
                [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v(" Shoppings ")
                  ]),
                  _c("count-to", {
                    staticClass: "card-panel-num",
                    attrs: { "start-val": 0, "end-val": 13600, duration: 3600 }
                  })
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }