var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inspect-detail" }, [
    _c("section", [_c("h3", [_vm._v("审批记录")]), _vm._t("operationList")], 2),
    _c(
      "section",
      { staticClass: "inspect-section" },
      [
        _c("h3", [_vm._v("抽检任务单信息")]),
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 10 } }, [
              _c("span", { staticClass: "title" }, [_vm._v("抽检任务单号：")]),
              _vm._v(_vm._s(_vm.inspectDetail.flowNo) + " ")
            ]),
            _c("el-col", { attrs: { span: 7 } }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("抽检任务生成时间：")
              ]),
              _vm._v(
                _vm._s(
                  _vm._f("timeFilterShowSecond")(_vm.inspectDetail.createTime)
                ) + " "
              )
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "title" }, [_vm._v("运单号：")]),
              _vm.path === "/external-supervise-inspect"
                ? _c("span", [_vm._v(_vm._s(_vm.inspectDetail.waybillNo))])
                : _c(
                    "a",
                    {
                      staticStyle: { color: "#4f7df9", cursor: "pointer" },
                      on: {
                        click: function($event) {
                          return _vm.handleTo(
                            "/dataquery/waybill/" + _vm.inspectDetail.waybillId
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.inspectDetail.waybillNo) + " ")]
                  )
            ])
          ],
          1
        ),
        _c(
          "el-row",
          { staticStyle: { "margin-top": "10px" } },
          [
            _c("el-col", { attrs: { span: 10 } }, [
              _c("span", { staticClass: "title" }, [_vm._v("抽检类型：")]),
              _vm._v(_vm._s(_vm.inspectDetail.strategyTypeAlias) + " ")
            ]),
            _c("el-col", { attrs: { span: 7 } }, [
              _c("span", { staticClass: "title" }, [_vm._v("抽检任务状态：")]),
              _vm._v(_vm._s(_vm.inspectDetail.statusAlias) + " ")
            ])
          ],
          1
        ),
        _c(
          "el-row",
          { staticStyle: { "margin-top": "10px" } },
          [
            _vm.isShipperClient
              ? _c("el-col", { attrs: { span: 10 } }, [
                  _c("span", { staticClass: "title" }, [_vm._v("承运平台：")]),
                  _vm._v(" " + _vm._s(_vm.inspectDetail.consignor) + " ")
                ])
              : _vm._e(),
            _c("el-col", { attrs: { span: 10 } }, [
              _c("span", { staticClass: "title" }, [_vm._v("被抽检人：")]),
              _vm._v(
                " " +
                  _vm._s(_vm.inspectDetail.subjectName) +
                  "（" +
                  _vm._s(_vm.agentTypeMap[_vm.inspectDetail.subjectType]) +
                  "） "
              )
            ]),
            !_vm.isEnterpriseClient && !_vm.isShipperClient
              ? _c("el-col", { attrs: { span: 7 } }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("抽检处理人：")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.inspectDetail.sampleSubjectName) +
                      "（" +
                      _vm._s(
                        _vm.agentTypeMap[_vm.inspectDetail.sampleSubjectType]
                      ) +
                      "） "
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.strategyType === _vm.inspectContentType.PaymentFlow,
            expression: "strategyType===inspectContentType.PaymentFlow"
          }
        ]
      },
      [
        _c("h3", [_vm._v("流水信息")]),
        _c(
          "el-row",
          { staticStyle: { "margin-bottom": "20px" } },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "title" }, [_vm._v("支付状态：")]),
              _vm._v(_vm._s(_vm.paymentDetail.payStatusAlias) + " ")
            ]),
            _c("el-col", { attrs: { span: 6, offset: 2 } }, [
              _c("span", { staticClass: "title" }, [_vm._v("总运费：")]),
              _vm._v(
                _vm._s(
                  _vm._f("amountFormatFilter")(_vm.paymentDetail.totalAmount)
                ) + " "
              )
            ]),
            _c("el-col", { attrs: { span: 6, offset: 2 } }, [
              _c("span", { staticClass: "title" }, [_vm._v("实收金额：")]),
              _vm._v(
                _vm._s(
                  _vm._f("amountFormatFilter")(
                    _vm.paymentDetail.actualReceivedAmount
                  )
                ) + " "
              )
            ])
          ],
          1
        ),
        _c(
          "el-row",
          { staticStyle: { "margin-bottom": "20px" } },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "title" }, [_vm._v("总承运人运费：")]),
              _vm._v(
                _vm._s(
                  _vm._f("amountFormatFilter")(
                    _vm.paymentDetail.totalCarrierAmount
                  )
                ) + " "
              )
            ]),
            _c("el-col", { attrs: { span: 6, offset: 2 } }, [
              _c("span", { staticClass: "title" }, [_vm._v("实付金额：")]),
              _vm._v(
                _vm._s(
                  _vm._f("amountFormatFilter")(
                    _vm.paymentDetail.actualPaidAmount
                  )
                ) + " "
              )
            ])
          ],
          1
        ),
        _c("table-list", {
          attrs: {
            name: "inspect-flow",
            data: _vm.paymentList,
            options: _vm.paymentOptions,
            "show-setting": false
          }
        })
      ],
      1
    ),
    _c(
      "section",
      [
        _c("h3", [
          _vm._v(
            _vm._s(
              _vm.strategyType === _vm.inspectContentType.Track
                ? "轨迹信息"
                : "补充信息"
            )
          )
        ]),
        _c("table-list", {
          attrs: {
            name: "inspect-track",
            data: _vm.trackList,
            height: "150",
            options: _vm.trackOptions,
            "show-setting": false
          }
        })
      ],
      1
    ),
    _c("section", [_vm._t("files")], 2),
    _c("section", [_vm._t("advice")], 2),
    _c("section", [_vm._t("operationButtons")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }