import request from '@/utils/request'
import { IGetListParams, IGetDashboardParams } from './types'

const path = 'driver/aggregation'

export const getDriverList = (params: IGetListParams) =>
  request({
    url: `${path}/list`,
    method: 'post',
    data: params
  })
export const getDriverDashboard = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard`,
    method: 'post',
    data: params
  })

export const getAlarmRuleList = (params: any) => request({
  url: 'driver/alarm',
  method: 'post',
  data: params
})

export const getDriverRank = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/rank`,
    method: 'post',
    data: params
  })
export const getDriverSubjectCount = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/subject/count`,
    method: 'post',
    data: params
  })
export const getDriverType = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/type`,
    method: 'post',
    data: params
  })
export const getDriverWaybillCount = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/waybill/count`,
    method: 'post',
    data: params
  })
