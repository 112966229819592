import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'

/* Layout */
import Layout from '@/layout/index.vue'

/* Router modules */
import dataQueryRouter from './modules/dataquery'
import bigScreenRouter from './modules/bigscreen'
import riskRulesRouter from './modules/riskrules'
import taxRoutes from './modules/tax'
import accessRoutes from './modules/access'
import systemRoutes from './modules/system'
import noteRoutes from './modules/note'
import serviceApplyRoutes from './modules/serviceApply'
import superviseRouter from './modules/supervise'
import compositeManage from './modules/compositeManage'
Vue.use(Router)

/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
export const constantRoutes: RouteConfig[] = [{
  path: '/redirect',
  component: Layout,
  meta: { hidden: true },
  children: [{
    path: '/redirect/:path(.*)',
    component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/index.vue')
  }]
},
{
  path: '/login',
  component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
  meta: { hidden: true }
},
// {
//   path: '/apply',
//   component: () => import('@/views/apply/index.vue'),
//   meta: { hidden: true }
// },
{
  path: '/auth-redirect',
  component: () => import(/* webpackChunkName: "auth-redirect" */ '@/views/login/auth-redirect.vue'),
  meta: { hidden: true }
},
{
  path: '/404',
  component: () => import(/* webpackChunkName: "404" */ '@/views/error-page/404.vue'),
  meta: { hidden: true }
},
{
  path: '/401',
  component: () => import(/* webpackChunkName: "401" */ '@/views/error-page/401.vue'),
  meta: { hidden: true }
},
{
  path: '/',
  redirect: '/home/gatherHome'
},
{
  path: '/user',
  component: Layout,
  name: 'Setting',
  meta: {
    title: '用户模块',
    icon: 'user',
    hidden: true
  },
  children: [{
    path: 'setting',
    name: 'UserSetting',
    component: () => import('@/views/setting/index.vue'),
    meta: {
      title: '用户设置',
      hidden: true
    }
  },
  {
    path: 'reset/password',
    name: 'ResetPassword',
    component: () => import('@/views/setting/resetPassword.vue'),
    meta: {
      title: '重置密码',
      hidden: true
    }
  }
  ]
}

  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/dashboard',
  //   children: [
  //     {
  //       path: 'dashboard',
  //       component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
  //       name: 'Dashboard',
  //       meta: {
  //         title: 'dashboard',
  //         icon: 'dashboard',
  //         affix: true
  //       }
  //     }
  //   ]
  // }
  // {
  //   path: '/documentation',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: "documentation" */ '@/views/documentation/index.vue'),
  //       name: 'Documentation',
  //       meta: { title: 'documentation', icon: 'documentation', affix: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/guide',
  //   component: Layout,
  //   redirect: '/guide/index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: "guide" */ '@/views/guide/index.vue'),
  //       name: 'Guide',
  //       meta: {
  //         title: 'guide',
  //         icon: 'guide',
  //         noCache: true
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/profile',
  //   component: Layout,
  //   redirect: '/profile/index',
  //   meta: { hidden: true },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/index.vue'),
  //       name: 'Profile',
  //       meta: {
  //         title: 'profile',
  //         icon: 'user',
  //         noCache: true
  //       }
  //     }
  //   ]
  // }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes: RouteConfig[] = [
  /** when your routing map is too long, you can split it into small modules **/
  bigScreenRouter,
  dataQueryRouter,
  riskRulesRouter,
  superviseRouter,
  taxRoutes,
  accessRoutes,
  serviceApplyRoutes,
  noteRoutes,
  compositeManage,
  systemRoutes
]

const createRouter = () => new Router({
  // mode: 'history',  // Disabled due to Github Pages doesn't support this, enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
