var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "waybill-risk-operation-contianer" },
    [
      _c(
        "div",
        { staticClass: "operation-btn-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.handleRecheckAntiFraud }
            },
            [_vm._v(" 重过风险 ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.riskGrade,
                  expression: "riskGrade"
                }
              ],
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.handleForceOperate(false)
                }
              }
            },
            [_vm._v(" 强制通过 ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.riskGrade,
                  expression: "riskGrade"
                }
              ],
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.handleForceOperate(true)
                }
              }
            },
            [_vm._v(" 强制不通过 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.handleTrail }
            },
            [_vm._v(" 试算 ")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isReject ? "强制拒绝" : "强制通过",
            visible: _vm.dialogVisible,
            width: "30%",
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确认是否强制调整此运单？")]),
          _c(
            "span",
            {
              staticStyle: {
                display: "block",
                "margin-top": "8px",
                "margin-bottom": "8px"
              }
            },
            [_vm._v("通过并顺利开票后，只能通过税务大厅作废")]
          ),
          !_vm.forcePassForm.forced
            ? _c(
                "el-form",
                { attrs: { model: _vm.forcePassForm, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.isReject ? "拒绝理由" : "通过理由",
                        prop: "forcePassReasons"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          placeholder: "请输入内容",
                          maxlength: "500"
                        },
                        model: {
                          value: _vm.forcePassForm.forcePassReasons,
                          callback: function($$v) {
                            _vm.$set(_vm.forcePassForm, "forcePassReasons", $$v)
                          },
                          expression: "forcePassForm.forcePassReasons"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("upload-image", {
                        ref: "uploadImage",
                        attrs: { "list-type": "picture" },
                        on: { input: _vm.handleFileChange }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSubmitForcePass }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "trial-result-dialog",
          attrs: {
            title: "试算结果",
            visible: _vm.trialDialogVisible,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.trialDialogVisible = $event
            }
          }
        },
        [
          _c(
            "h4",
            {
              staticClass: "trial-result-title",
              staticStyle: { "text-align": "center", "margin-top": "0" },
              style: { color: _vm.riskLevelConfig.color }
            },
            [_vm._v(" " + _vm._s(_vm.riskLevelConfig.text) + " ")]
          ),
          _c(
            "div",
            { staticClass: "trial-result-container" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.trialResult.anti_fraud_rule_result_view_list,
                    size: "mini"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "rule_name", label: "规则名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "pass", label: "是否通过", width: "90" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  size: "medium",
                                  type: scope.row.pass ? "success" : "danger"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.pass ? "已通过" : "未通过"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "result_msg", label: "返回信息" }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }