








import { Component, Prop, Vue } from 'vue-property-decorator'
import settings from '@/settings'
import { getVersion } from '@/api/version'
import { AppModule } from '@/store/modules/app'

@Component({
  name: 'SidebarVersion'
})
export default class extends Vue {
  @Prop({ required: true }) private collapse!: boolean

  private title = settings.title
  private version = ''
  private destination=AppModule.urlConfig.name
  created() {
    getVersion().then(({ data }) => {
      this.version = data.version
    })
  }
}

