import request from '@/utils/request'
import { IGetListParams, IWaybillForceOperationParams } from './types'

const path = 'waybill'

export const getWaybillList = (params: IGetListParams) =>
  request({
    url: `${path}/findPage`,
    method: 'post',
    data: params
  })

export const getWaybillDetail = (params: { id: string }) =>
  request({
    url: `${path}/detail`,
    method: 'post',
    data: params
  })


export const forcePassApply = (data: IWaybillForceOperationParams) => request({
  url: `${path}/force`,
  method: 'post',
  data: data
})
export const forceRejectApply = (data: IWaybillForceOperationParams) => request({
  url: `${path}/force/fail`,
  method: 'post',
  data: data
})
export const rerunAntiFraud = (data: {
  id: string
}) => request({
  url: `${path}/rerun`,
  method: 'post',
  data: data
})

export const trial = (data: { id: string }) => request({
  url: `${path}/trial`,
  method: 'post',
  data: data
})

export const replenish = (params: {
    file_urls: string
    explain: string
    waybill_id: string
  }) =>
  request({
    url: `${path}/replenish`,
    method: 'post',
    data: params
  })

export const waybillAppealApplyApi = (params: any) =>
  request({
    url: 'waybill/appeal/apply',
    method: 'post',
    data: params
  })

// 申诉清单
export const waybillAppealItemApi = (params: any) =>
  request({
    url: 'waybill/appeal/item',
    method: 'post',
    data: params
  })
