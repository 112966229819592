/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'like': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 21.6C6.4 16 1 11.3 1 7.2 1 3.4 4 2 6.3 2c1.3 0 4.1.5 5.7 4.5 1.6-4 4.5-4.5 5.7-4.5C20.3 2 23 3.6 23 7.2c0 4-5.1 8.6-11 14.4M17.7 1c-2.2 0-4.4 1-5.7 3.2A6.5 6.5 0 006.3 1C3 1 0 3.2 0 7.2c0 4.7 5.6 9.4 12 15.8 6.4-6.4 12-11.1 12-15.8 0-4-3.1-6.2-6.3-6.2"/>'
  }
})
