var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar", class: { "green-skin": _vm.isGreenSkin } },
    [
      _vm.isGreenSkin
        ? _c("span", { staticClass: "web-title" }, [
            _vm._v(" 网络货运（铜仁）监管服务平台 ")
          ])
        : _vm._e(),
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      !_vm.isGreenSkin
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-badge",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["notice_list"],
                  expression: "['notice_list']"
                }
              ],
              staticClass: "note-item",
              attrs: { value: _vm.unRead, hidden: _vm.unRead === 0, max: 99 },
              nativeOn: {
                click: function($event) {
                  return _vm.viewNotice($event)
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-message-solid",
                staticStyle: { "font-size": "20px" }
              })
            ]
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src: _vm.avatar || "../../../../img/icons/默认头像@2x.png"
                  }
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/user/setting" } },
                    [_c("el-dropdown-item", [_vm._v(" 个人设置 ")])],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/user/reset/password" } },
                    [_c("el-dropdown-item", [_vm._v(" 修改密码 ")])],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [_c("el-dropdown-item", [_vm._v(" 首页 ")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(" 退出登录 ")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }