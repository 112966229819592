var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("search-filter", {
        attrs: { options: _vm.queryFilterOptions, "type-default": "input" },
        on: { search: _vm.handleFilter },
        model: {
          value: _vm.listQuery,
          callback: function($$v) {
            _vm.listQuery = $$v
          },
          expression: "listQuery"
        }
      }),
      _c(
        "section",
        [
          _c("bsm-table", {
            attrs: {
              data: _vm.list,
              options: _vm.tableListOptions,
              "list-loading": _vm.listLoading,
              "show-index": true
            },
            on: {
              actionCommand: _vm.handleActionCommand,
              "button-action": _vm.handleActionCommand
            }
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          name: "tax-push",
          total: _vm.total,
          page: _vm.listQuery.page.index,
          limit: _vm.listQuery.page.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery.page, "index", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery.page, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "失败信息",
            visible: _vm.dialogVisible,
            width: "30%",
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "section",
            _vm._l(_vm.failedLogs, function(log, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticStyle: {
                    display: "block",
                    padding: "10px",
                    "border-bottom": "1px solid #eeeeee"
                  },
                  style: {
                    borderBottom:
                      index === _vm.failedLogs.length - 1
                        ? 0
                        : "1px solid #eeeeee"
                  }
                },
                [
                  _c("span", [_vm._v(_vm._s(log.error_msg))]),
                  _c(
                    "span",
                    { staticStyle: { float: "right", "margin-left": "30px" } },
                    [_vm._v(_vm._s(log.response))]
                  ),
                  _c(
                    "span",
                    { staticStyle: { float: "right", "margin-left": "30px" } },
                    [_vm._v(_vm._s(_vm._f("timeFilterShowSecond")(log.time)))]
                  )
                ]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "apply-detail-dialog",
          attrs: {
            visible: _vm.applyDetailDialogVisible,
            title: "查看详情",
            size: "large"
          },
          on: {
            "update:visible": function($event) {
              _vm.applyDetailDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticClass: "title" }, [
                  _vm._v("网络货运企业：")
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.applyDetail.agent_name))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "16px" } },
            [
              _c("el-col", { attrs: { span: 10 } }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("开票申请编号：")
                ]),
                _vm._v(" " + _vm._s(_vm.applyDetail.apply_no) + " ")
              ]),
              _c("el-col", { attrs: { span: 5, offset: 2 } }, [
                _c("span", { staticClass: "title" }, [_vm._v("发票类型：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.applyDetail.invoice_type_name))
                ])
              ]),
              _c("el-col", { attrs: { span: 5, offset: 2 } }, [
                _c("span", { staticClass: "title" }, [_vm._v("发票种类：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.applyDetail.invoice_category_name))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "16px" } },
            [
              _c("el-col", { attrs: { span: 10 } }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("开票申请时间：")
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm._f("timeFilter")(_vm.applyDetail.create_time))
                  )
                ])
              ]),
              _c(
                "el-col",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { span: 11, offset: 2 }
                },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.applyDetail.invoice_type === 2 ? "子运单" : "运单"
                      ) + "笔数："
                    )
                  ]),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#4F7DF9" },
                      attrs: { href: "javascript:###" },
                      on: { click: _vm.viewWaybill }
                    },
                    [_vm._v(_vm._s(_vm.applyDetail.item_count))]
                  )
                ]
              )
            ],
            1
          ),
          _c("div", { staticStyle: { "padding-top": "30px" } }, [
            _vm._v(
              " 开票状态：" +
                _vm._s(_vm.applyDetail.tax_push_status_alias) +
                " "
            ),
            _c("span", { staticClass: "ml-6" }, [
              _vm._v(
                "开票时间：" +
                  _vm._s(_vm._f("timeFilter")(_vm.applyDetail.invoice_time))
              )
            ]),
            _c("span", { staticClass: "ml-6" }, [
              _vm._v("发票代码："),
              _c(
                "a",
                {
                  staticStyle: { color: "#4f7df9" },
                  attrs: { href: "javascript:###" },
                  on: { click: _vm.viewInvoiced }
                },
                [_vm._v(_vm._s(_vm.applyDetail.invoice_code || ""))]
              ),
              _c("span", { staticClass: "ml-6" }, [
                _vm._v("发票号码：" + _vm._s(_vm.applyDetail.invoice_number))
              ])
            ])
          ]),
          _c(
            "table",
            {
              staticClass: "tax-detail-table",
              staticStyle: { "margin-top": "16px" },
              attrs: { cellspacing: "0" }
            },
            [
              _c("tr", [
                _c("th", { attrs: { colspan: "2" } }, [_vm._v(" 开票信息 ")]),
                _c("th", { attrs: { colspan: "2" } }, [_vm._v(" 推送金额 ")]),
                _c("th", { attrs: { colspan: "2" } }, [_vm._v(" 开票金额 ")])
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" 实付运费 ")]),
                _c("td", { attrs: { colspan: "4" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.carrier_paid_amount
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" 价税合计 ")]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.apply_tax_detail_view
                            .tax_included_amount
                        )
                      ) +
                      " "
                  )
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.return_tax_detail_view
                            .tax_included_amount
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" 不含税金额 ")]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.apply_tax_detail_view
                            .tax_excluding_amount
                        )
                      ) +
                      " "
                  )
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.return_tax_detail_view
                            .tax_excluding_amount
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" 增值税额 ")]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.apply_tax_detail_view.added_value_tax
                        )
                      ) +
                      " "
                  )
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.return_tax_detail_view.added_value_tax
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { attrs: { rowspan: "5" } }, [_vm._v(" 其他税种 ")]),
                _c("td", [_vm._v("城市建设维护税")]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.apply_tax_detail_view
                            .cal_city_build_tax
                        )
                      ) +
                      " "
                  )
                ]),
                _c("td", { attrs: { rowspan: "5" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.apply_tax_detail_view.other_tax
                        )
                      ) +
                      " "
                  )
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.return_tax_detail_view
                            .cal_city_build_tax
                        )
                      ) +
                      " "
                  )
                ]),
                _c("td", { attrs: { rowspan: "5" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.return_tax_detail_view.other_tax
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("个人所得税")]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.apply_tax_detail_view
                            .cal_individual_tax
                        )
                      ) +
                      " "
                  )
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.return_tax_detail_view
                            .cal_individual_tax
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("印花税")]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.apply_tax_detail_view.cal_stamp_tax
                        )
                      ) +
                      " "
                  )
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.return_tax_detail_view.cal_stamp_tax
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("教育附加税")]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.apply_tax_detail_view
                            .cal_education_tax
                        )
                      ) +
                      " "
                  )
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.return_tax_detail_view
                            .cal_education_tax
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("地方教育附加税")]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.apply_tax_detail_view
                            .cal_city_education_tax
                        )
                      ) +
                      " "
                  )
                ]),
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.return_tax_detail_view
                            .cal_city_education_tax
                        )
                      ) +
                      " "
                  )
                ])
              ])
            ]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "16px" },
              attrs: { type: "warning" },
              on: { click: _vm.viewTaxRate }
            },
            [_vm._v(" 查看税率 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }