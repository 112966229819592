







import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class ContainShowImg extends Vue {
  @Prop({
    default: '',
    required: true
  }) src: string

  private renderSrc = 'img/empty.jpeg'

  private handleClick() {
    this.$showImg(this.src)
  }

  private loadImg() {
    const img = new Image()
    img.onload = () => {
      this.renderSrc = this.src
    }
    img.onerror = () => {
      console.log(`图片：${this.src}加载失败`)
    }
    img.src = this.src
  }

  created() {
    if (this.src) {
      this.loadImg()
    }
  }

  @Watch('src')
  onSrcChange(src) {
    if (src) {
      this.loadImg()
    }
  }
}
