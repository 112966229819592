






































































































































import { Component, Vue } from 'vue-property-decorator'
import { ITaxRateConifg, IUpdateTaxRateParams } from '@/api/types'
import {
  getTaxRateConfigList,
  updateTaxRate,
  getInvoiceActive,
  swtichTaxServe
} from '@/api/system'
import BsmTable from '@/views/components/BsmTable.vue'
import { ElForm } from 'element-ui/types/form'

function percentFilter(val) {
  return `${val}%`
}

@Component({
  name: 'TaxRate',
  components: {
    BsmTable
  }
})
export default class extends Vue {
  list: ITaxRateConifg[] = []
  listLoading = true
  submitLoading = false
  showEdit = false
  invoiceActive = true
  temp = {
    id: '',
    tax_type_alias: '',
    actual_rate: 0,
    rate: 0,
    reduction: 0,
    threshold: 0,
    threshold_period: 0
  }

  get rules() {
    const rules = {
      rate: [{
        required: true,
        type: 'number',
        message: '请输入0-100之间的数字',
        min: 0,
        max: 100,
        trigger: 'blur'
      }],
      reduction: [{
        required: true,
        type: 'number',
        message: '请输入0-1之间的数字',
        min: 0,
        max: 1,
        trigger: 'blur'
      }],
      threshold: [{
        required: true,
        type: 'number',
        message: '请输入大于0的数字',
        min: 0,
        trigger: 'blur'
      }],
      threshold_period: [{
        required: true,
        message: '请选择起征周期',
        trigger: 'blur'
      }]
    }
    if (this.temp && this.temp.tax_type_alias === '增值税') {
      return Object.assign({
        threshold: [{
          required: true,
          type: 'number',
          message: '增值税起征点只能是0',
          min: 0,
          max: 0,
          trigger: 'blur'
        }]
      })
    } else {
      return rules
    }
  }

  resetTemp() {
    this.temp = {
      id: '',
      tax_type_alias: '',
      actual_rate: 0,
      rate: 0,
      reduction: 0,
      threshold: 0,
      threshold_period: 0
    }
  }

  tableListOptions = [{
    field: 'subject_id',
    type: 'actionSetting',
    label: '操作',
    align: 'center',
    width: 50,
    attrs: {
      actionOptions: [{
        type: 'edit',
        label: '编辑'
      }]
    },
    fixed: true
  }, {
    field: 'tax_type_alias',
    label: '税种',
    fixed: true
  }, {
    field: 'tax_base_alias',
    label: '税基'
  }, {
    field: 'actual_rate',
    label: '实际税率',
    align: 'center',
    filter: percentFilter
  }, {
    field: 'rate',
    label: '税率',
    align: 'center',
    filter: percentFilter
  }, {
    field: 'reduction',
    label: '普惠减免',
    align: 'center'
  }, {
    field: 'threshold',
    label: '起征点',
    align: 'center'
  }, {
    field: 'threshold_period_alias',
    label: '起征计算周期',
    align: 'center'
  }]

  created() {
    this.init()
  }

  init() {
    this.getList()
    getInvoiceActive().then(res => {
      const { data } = res
      this.invoiceActive = data.invoice_active
    })
  }

  async getList() {
    this.listLoading = true
    const { data } = await getTaxRateConfigList()
    this.list = data
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  handleActiveAndClose() {
    this.$confirm('是否启动/关闭服务?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      swtichTaxServe().then(res => {
        this.$message.success('操作成功')
        this.init()
      })
    }).catch(e => {
      console.log(e)
    })
  }

  handleActionCommand(type, val, row) {
    this.temp = row
    this.showEdit = true
  }

  async handleSubmit() {
    this.submitLoading = true
    const valid = await (this.$refs.form as ElForm).validate()
    try {
      if (valid) {
        await updateTaxRate({
          id: this.temp.id,
          rate: this.temp.rate,
          reduction: this.temp.reduction,
          threshold: this.temp.threshold,
          thresholdPeriod: this.temp.threshold_period
        })
        this.getList()
        this.$message.success('编辑成功')
        this.showEdit = false
      } else {
        this.$message.error('请输入正确参数')
      }
    } finally {
      this.submitLoading = false
    }
  }

  handleClose() {
    // this.resetTemp()
    (this.$refs.form as ElForm).resetFields()
    this.submitLoading = false
  }
}

