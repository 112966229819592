



























import { Component, Prop, Vue } from 'vue-property-decorator'
import { isExternal } from '@/utils/validate'
import { TagsViewModule } from '@/store/modules/tags-view'
import { deepEqual } from '@/utils'
import { isGreenSkin } from '@/utils/common'

@Component({
  name: 'SidebarItemLink'
})
export default class extends Vue {
  @Prop({ required: true }) to!: string

  isExternal = isExternal
  isGreenSkin = isGreenSkin()

  async handleClick() {
    if (this.$route.path === this.to) {
      return
    }
    const view = (this.$router as any).matcher.match(this.to)
    if (TagsViewModule.cachedViews.some(name => name === view.name)) {
      const oldView = TagsViewModule.visitedViews.find(v => (v.path === view.path || v.name === view.name))
      if (oldView && !deepEqual(oldView.query, view.query)) {
        await TagsViewModule.delCachedView(view)
      }
    }
    this.$router.push({
      path: this.to
    })
  }
}
