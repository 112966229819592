var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-date-picker", {
    attrs: { "value-format": "timestamp" },
    model: {
      value: _vm.time,
      callback: function($$v) {
        _vm.time = $$v
      },
      expression: "time"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }