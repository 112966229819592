var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tree-item",
      class: "tree-item-" + _vm.data.level,
      style: { paddingLeft: ((_vm.data.level - 1) * 25 || 15) + "px" }
    },
    [
      _c("span", {
        staticClass: "el-tree-node__expand-icon el-icon-caret-right",
        class: _vm.expanded ? "expanded" : "",
        staticStyle: { padding: "6px" },
        style: {
          visibility:
            _vm.data.children && _vm.data.children.length ? "visible" : "hidden"
        },
        on: {
          click: function($event) {
            _vm.expanded = !_vm.expanded
          }
        }
      }),
      _c(
        "el-checkbox",
        {
          key: _vm.data.id,
          staticClass: "item-checkbox",
          attrs: {
            label: _vm.data.id,
            disabled: !_vm.roles.includes(_vm.data.id)
          },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.checkValue,
            callback: function($$v) {
              _vm.checkValue = $$v
            },
            expression: "checkValue"
          }
        },
        [_vm._v(" " + _vm._s(_vm.data.description) + " ")]
      ),
      _c(
        "div",
        { staticClass: "item-group", class: _vm.expanded ? "expanded" : "" },
        _vm._l(_vm.data.children, function(children, index) {
          return _c("tree-item", {
            key: children.id,
            attrs: { data: children },
            on: {
              change: function($event) {
                return _vm.handleChildChange(children, index)
              }
            },
            model: {
              value: children.checked,
              callback: function($$v) {
                _vm.$set(children, "checked", $$v)
              },
              expression: "children.checked"
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }