import request from '@/utils/request'
import { IGetListParams, IGetDashboardParams } from './types'

const path = 'agent/aggregation'

export const getAgentList = (params: IGetListParams) =>
  request({
    url: `${path}/list`,
    method: 'post',
    data: params
  })
export const getAgentDashboard = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard`,
    method: 'post',
    data: params
  })

export const getAlarmRuleList = (params: any) => request({
  url: 'agent/alarm',
  method: 'post',
  data: params
})

export const getAgentInvoiceChart = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/invoice/chart`,
    method: 'post',
    data: params
  })
export const getAgentRank = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/rank`,
    method: 'post',
    data: params
  })
export const getAgentSubjectCount = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/subject/count`,
    method: 'post',
    data: params
  })
export const getAgentTax = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/tax`,
    method: 'post',
    data: params
  })
export const getAgentType = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/type`,
    method: 'post',
    data: params
  })
export const getAgentWaybillCount = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/waybill/count`,
    method: 'post',
    data: params
  })
