var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      style: { height: _vm.height },
      attrs: { label: "nmsl" }
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v(" 数据仪表盘 ")]),
      _c(
        "el-row",
        [
          _c(
            "el-row",
            { staticClass: "list-item-container", attrs: { gutter: 0 } },
            [
              _c("el-col", { attrs: { span: 7 } }, [
                _c(
                  "div",
                  { staticClass: "gauge-item" },
                  [
                    _c("gauge-chart", {
                      attrs: {
                        height: "160px",
                        value: _vm.data.onLine.value,
                        range: [0, 40, 60, 80, 100],
                        thickness: 2,
                        "content-val": function(ref) {
                          var percent = ref.percent

                          return Math.round(percent * 100 * 100) / 100 + "%"
                        },
                        "content-title": function() {
                          return "上线率"
                        },
                        statistic: {
                          title: {
                            offsetY: 12
                          },
                          content: {
                            offsetY: 0
                          }
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("el-col", { attrs: { span: 10 } }, [
                _c(
                  "div",
                  { staticClass: "gauge-item middle" },
                  [
                    _c("gauge-chart", {
                      attrs: {
                        height: "200px",
                        value: _vm.data.credit.value,
                        "content-title": function() {
                          return "评分"
                        },
                        statistic: {
                          title: {
                            offsetY: 12
                          },
                          content: {
                            offsetY: 0
                          }
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("el-col", { attrs: { span: 7 } }, [
                _c(
                  "div",
                  { staticClass: "gauge-item" },
                  [
                    _c("gauge-chart", {
                      attrs: {
                        value: _vm.data.activity.value,
                        height: "160px",
                        range: [0, 40, 60, 80, 100],
                        thickness: 2,
                        "content-val": function(ref) {
                          var percent = ref.percent

                          return Math.round(percent * 100 * 100) / 100 + "%"
                        },
                        "content-title": function() {
                          return "活跃度"
                        },
                        statistic: {
                          title: {
                            offsetY: 12
                          },
                          content: {
                            offsetY: 0
                          }
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }