






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MapDate extends Vue {
  @Prop({
    default: Date.now()
  }) value: number | string

  private get time() {
    return this.value
  }

  private set time(val) {
    this.$emit('input', val)
  }
}
