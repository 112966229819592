







import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MapSpans extends Vue {
  @Prop({ default: () => [] }) value: string[]
   @Prop({ default: 200 }) width: number
}

