















































































































































































































































































































































































































import 'echarts/theme/macarons.js' // Theme used in BarChart, LineChart, PieChart and RadarChart
import { Component, Vue, ProvideReactive } from 'vue-property-decorator'

import BarChart from '@/common/components/Charts/BarChart.vue'
import LineChart from '@/common/components/Charts/LineChart.vue'
import LineAreaChart from '@/common/components/Charts/LineAreaChart.vue'
import StackedColumnLineChart from '@/common/components/Charts/StackedColumnLineChart.vue'
import GaugeChart from '@/common/components/Charts/GaugeChart.vue'
import FilledMap from '@/common/components/Charts/FilledMap.vue'
import PieChart from '@/common/components/Charts/PieChart.vue'
import DonutChart from '@/common/components/Charts/DonutChart.vue'
import TinyLineChart from '@/common/components/Charts/TinyLineChart.vue'

import RadarChart from '@/common/components/Charts/RadarChart.vue'

import DashboardHeader from '@/common/business-components/DashboardHeader.vue'
import DashboardCard from '@/common/business-components/DashboardCard.vue'
import ChartContainer from '@/common/business-components/ChartContainer.vue'

import PanelGroup from './components/PanelGroup.vue'
import TodoList from './components/TodoList/index.vue'
import MultiGaugeChart from '@/common/business-components/MultiGaugeChart/index.vue'

import { IDashboardData, IGetDashboardParams } from '@/api/types'
import {
  getOverviewInvoice,
  getOverviewRank,
  getOverviewSubjectCount,
  getOverviewTax,
  getOverviewType,
  getOverviewWaybillChart,
  getRiskScore
} from '@/api/overview'
import moment from 'moment'

import ChinaMap from '@/common/business-components/ChinaMap/index.vue'

import { sum, mergeRule, processTableData, processRiskScoreData } from '@/utils'

import _ from 'lodash'

@Component({
  name: 'Overview',
  components: {
    // GithubCorner,
    BarChart,
    LineChart,
    StackedColumnLineChart,
    GaugeChart,
    PanelGroup,
    PieChart,
    RadarChart,
    TodoList,
    LineAreaChart,
    ChartContainer,
    DashboardHeader,
    DashboardCard,
    DonutChart,
    FilledMap,
    TinyLineChart,
    ChinaMap,
    MultiGaugeChart
  }
})
export default class extends Vue {
  @ProvideReactive('dashboard') timeFormat = 'MM.DD'

  waybillLineData = []
  freightLineData = []
  taxLineData = []
  invoiceLineData = []
  invoiceWaybillLineData = []

  totalFreightIncome = 0
  taxIncomeTotal = 0
  taxIncomeTypeTotal = 0
  taxTypeTotal = 0
  data: IDashboardData = {
    agent_count: 0,
    agent_count_list: [],
    carrier_count: 0,
    carrier_count_list: [],
    vehicle_count: 0,
    vehicle_count_list: [],

    // 运单笔数
    waybill_count: 0,
    invoiced_waybill_count_list: [], // 开票运单数
    new_waybill_count_list: [], // 新开运单数
    paid_waybill_count_list: [], // 支付运单数
    receive_waybill_count_list: [], // 签收运单数
    // 运费
    freight_income_count_list: [], // 运费收入
    freight_expense_count_list: [], // 运费支出
    actual_freight_count_list: [], // 运费实际支付

    // 税收
    agent_tax_income_list: [], // 网络货运企业税收收入
    carrier_tax_income_list: [], // 承运人税收收入
    tax_income_type_list: [], // 税收类型分布
    tax_type_list: [], // 税种分布

    // 发票开立
    invoice_count: 0, // 发票开立数
    total_invoice_amount: 0, // 发票开立数
    driver_invoice_count_list: [], // 司机发票
    consignor_invoice_count_list: [], // 托运人发票
    // 开票运单
    invoice_waybill_count: 0, // 开票运单数
    reject_invoice_waybill_count_list: [], // 拒绝单数
    pass_invoice_waybill_count_list: [], // 开票单数
    apply_invoice_waybill_count_list: [], // 申请单数

    agent_freight_rank_list: [],
    agent_waybill_rank_list: [],
    carrier_freight_rank_list: [],
    carrier_waybill_rank_list: [],

    delivery_province_distribution: [],
    receiver_province_distribution: [],
    pay_type_distribution: [],
    pay_duration_distribution: [],

    goods_type_distribution: [],
    business_type_distribution: []
  }

  riskScoreGaugeData = {
    onLine: {
      value: 0
    },
    credit: {
      value: 0
    },
    activity: {
      value: 0
    }
  }// 评分仪表盘

  stackedData = {
    columnData: [],
    lineData: []
  } // 评分混合图

  params = {
    start_time: moment().add(-30, 'days').startOf('day').valueOf(),
    end_time: moment().valueOf(),
    business_mode: 0,
    time_type: 1
  }

  get timeRange() {
    return [this.params.start_time, this.params.end_time]
  }

  created() {
    this.getData()
  }


  async getData() {
    const params = Object.assign({}, this.params)
    const temp = {}

    getOverviewInvoice(params).then(({ data }) => {
      this.data.invoice_count = data.invoice_count
      this.data.total_invoice_amount = data.total_invoice_amount / 1000
      this.data.driver_invoice_count_list = data.driver_invoice_count_list
      this.data.consignor_invoice_count_list = data.consignor_invoice_count_list
      this.data.invoice_waybill_count = data.invoice_waybill_count
      this.data.reject_invoice_waybill_count_list = data.reject_invoice_waybill_count_list
      this.data.pass_invoice_waybill_count_list = data.pass_invoice_waybill_count_list
      this.data.apply_invoice_waybill_count_list = data.apply_invoice_waybill_count_list

      this.processInvoiceData(data)
    })
    getOverviewRank(params).then(({ data }) => {
      this.data.agent_freight_rank_list = data.agent_freight_rank_list
      this.data.agent_waybill_rank_list = data.agent_waybill_rank_list
      this.data.carrier_freight_rank_list = data.carrier_freight_rank_list
      this.data.carrier_waybill_rank_list = data.carrier_waybill_rank_list
    })
    getOverviewSubjectCount(params).then(({ data }) => {
      this.data.agent_count = data.agent_count
      this.data.agent_count_list = data.agent_count_list
      this.data.carrier_count = data.carrier_count
      this.data.carrier_count_list = data.carrier_count_list
      this.data.vehicle_count = data.vehicle_count
      this.data.vehicle_count_list = data.vehicle_count_list
    })
    getOverviewTax(params).then(({ data }) => {
      this.data.agent_tax_income_list = data.agent_tax_income_list
      this.data.carrier_tax_income_list = data.carrier_tax_income_list
      this.data.tax_income_type_list = data.tax_income_type_list
      this.data.tax_type_list = data.tax_type_list
      this.processTaxData(data)
    })
    getOverviewType(params).then(({ data }) => {
      this.data.delivery_province_distribution = data.delivery_province_distribution
      this.data.receiver_province_distribution = data.receiver_province_distribution
      this.data.goods_type_distribution = data.goods_type_distribution
      this.data.business_type_distribution = data.business_type_distribution
      this.data.pay_type_distribution = data.pay_type_distribution
      this.data.pay_duration_distribution = data.pay_duration_distribution
    })
    getOverviewWaybillChart(params).then(({ data }) => {
      this.data.waybill_count = data.waybill_count
      this.data.invoiced_waybill_count_list = data.invoiced_waybill_count_list
      this.data.paid_waybill_count_list = data.paid_waybill_count_list
      this.data.receive_waybill_count_list = data.receive_waybill_count_list
      this.data.new_waybill_count_list = data.new_waybill_count_list
      this.data.freight_income_count_list = data.freight_income_count_list
      this.data.freight_expense_count_list = data.freight_expense_count_list
      this.data.actual_freight_count_list = data.actual_freight_count_list
      this.processWaybillChartData(data)
    })
    getRiskScore(params).then(({ data }) => {
      this.stackedData = processRiskScoreData(data.risk_score_statistics)


      this.riskScoreGaugeData.onLine.value = (data.online_percent / 100) || 0
      // eslint-disable-next-line camelcase
      this.riskScoreGaugeData.credit.value = data.risk_score?.risk_score || 0
      this.riskScoreGaugeData.activity.value = (data.active_percent / 100) || 0
    })
  }

  processTaxData(data: IDashboardData) {
    processTableData(data.carrier_tax_income_list, '承运人税收收入')
    this.taxLineData = data.carrier_tax_income_list

    this.taxIncomeTotal = sum(this.taxLineData, 'bill_count')
    this.taxIncomeTypeTotal = sum(data.tax_income_type_list, 'value')
    this.taxTypeTotal = sum(data.tax_type_list, 'value')
  }

  processInvoiceData(data:IDashboardData) {
    processTableData(data.driver_invoice_count_list, '司机发票')
    processTableData(data.consignor_invoice_count_list, '托运人发票')

    this.invoiceLineData = data.driver_invoice_count_list.concat(data.consignor_invoice_count_list)

    processTableData(data.reject_invoice_waybill_count_list, '拒绝单数')
    processTableData(data.pass_invoice_waybill_count_list, '开票单数')
    processTableData(data.apply_invoice_waybill_count_list, '申请单数')

    this.invoiceWaybillLineData = data.reject_invoice_waybill_count_list.concat(data.pass_invoice_waybill_count_list).concat(data.apply_invoice_waybill_count_list)
  }

  processWaybillChartData(data: IDashboardData) {
    // 图片数据符合
    processTableData(data.invoiced_waybill_count_list, '开票运单数')
    processTableData(data.new_waybill_count_list, '新开运单数')
    processTableData(data.paid_waybill_count_list, '支付运单数')
    processTableData(data.receive_waybill_count_list, '签收运单数')

    this.waybillLineData = data.invoiced_waybill_count_list.concat(data.new_waybill_count_list).concat(data.paid_waybill_count_list).concat(data.receive_waybill_count_list)

    processTableData(data.freight_income_count_list, '运费收入')
    processTableData(data.freight_expense_count_list, '运费支出')
    processTableData(data.actual_freight_count_list, '运费实际支付')

    this.freightLineData = data.freight_income_count_list.concat(data.freight_expense_count_list).concat(data.actual_freight_count_list)

    this.totalFreightIncome = _.reduce(data.freight_income_count_list, (result, freight) => {
      return result + freight.bill_count
    }, 0)
  }

  // processTableData(data: any[], type: string) {
  //   for (let i = 0, len = data.length; i < len; i++) {
  //     data[i].type = type
  //   }
  // }

  handleTimeChange({ startTime, endTime, timeFormat }) {
    this.params.start_time = startTime
    this.params.end_time = endTime
    this.timeFormat = timeFormat
    this.getData()
  }

  handleBusinessChange({ businessMode }) {
    this.params.business_mode = businessMode
    this.getData()
  }

  handleTimeTypeChange({ timeType }) {
    this.params.time_type = timeType
    this.getData()
  }
}
