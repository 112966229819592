var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "waybill-detail-container bg-normal px-10"
    },
    [
      _c("div", { staticClass: "head" }, [
        _c(
          "span",
          { staticClass: "pointer text-primary" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to:
                    "/dataquery/agent/" +
                    (_vm.waybillBaseInfo && _vm.waybillBaseInfo.agent_did)
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.waybillBaseInfo && _vm.waybillBaseInfo.agent) +
                    " "
                )
              ]
            )
          ],
          1
        ),
        _c("span", { staticStyle: { "margin-left": "40px" } }, [
          _vm._v(
            "运单号：" +
              _vm._s(_vm.waybillBaseInfo && _vm.waybillBaseInfo.waybillNo)
          )
        ]),
        _c("span", { staticStyle: { "margin-left": "40px" } }, [
          _vm._v(
            "运单类型：" +
              _vm._s(
                _vm.waybillBaseInfo && _vm.waybillBaseInfo.businessModeAlias
              )
          )
        ]),
        _c("span", { staticStyle: { "margin-left": "40px" } }, [
          _vm._v(
            "运单数据上传时间：" +
              _vm._s(
                _vm._f("timeFilterShowSecond")(
                  _vm.waybillBaseInfo && _vm.waybillBaseInfo.createTime
                )
              )
          )
        ]),
        _c("span", { staticStyle: { float: "right" } }, [
          _vm._v("数据已上传智慧物流联盟链")
        ])
      ]),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c(
            "waybill-base",
            {
              attrs: {
                "waybill-info": _vm.waybillBaseInfo,
                "anti-fraud-info": _vm.antiFraudWaybillInfo
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end"
                  }
                },
                [
                  _c("waybill-risk-log", {
                    staticStyle: { height: "196px" },
                    attrs: {
                      type:
                        _vm.antiFraudWaybillInfo &&
                        _vm.antiFraudWaybillInfo.antiFraudWaybillLogDto
                          .operateType
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("waybill-goods", {
            attrs: { "waybill-goods-info": _vm.waybillGoodsInfo }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("waybill-transport", {
            attrs: {
              "waybill-transport-info": _vm.waybillTransportInfo,
              type: "subwaybill"
            },
            on: { onTrack: _vm.handleTrack }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("waybill-payment", {
            attrs: { "waybill-payment-info": _vm.waybillPaymentInfo }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("waybill-invoice", {
            attrs: { "waybill-invoice-info": _vm.waybillInvoiceInfo }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("etc-invoice", {
            attrs: { "etc-invoice-info": _vm.etcInvoiceInfo }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("waybill-addition", {
            attrs: { "waybill-addition-info": _vm.waybillAdditionInfo }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("waybill-log", {
            attrs: { "waybill-log-info": _vm.waybillLogInfo }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }