/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'security': {
    width: 54,
    height: 64,
    viewBox: '0 0 54 64',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M26.996 1.759l4.763 2.928A101.5 101.5 0 0048.073 12.8h0l4.427 1.725V31.13a28.482 28.482 0 01-3.836 14.28A28.482 28.482 0 0138.19 55.848h0L27 62.27l-11.19-6.423A28.482 28.482 0 015.337 45.411 28.482 28.482 0 011.5 31.13h0v-16.6l3.637-1.395a101.5 101.5 0 0016.44-8.077h0l5.42-3.3z" _stroke="#17BB89" stroke-width="3" fill-opacity=".2" _fill="#17BB89"/><path pid="1" d="M27.153 0L26 .713v62.621l1.153.666L39 57.16a30 30 0 0015-25.98V13.5l-4.502-1.737a100 100 0 01-16.236-8.022L27.153 0z" fill-opacity=".3" _fill="#17BB89"/><path pid="2" _stroke="#17BB89" stroke-width="3" d="M18 29.41L24.974 37 36 25"/></g>'
  }
})
