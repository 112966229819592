




























































































import { Component, Vue } from "vue-property-decorator";
import { IRiskRule } from "@/api/types";
import {
  addSchemeApi,
  getSchemeNoApi,
  getActiveRiskRuleSchemeList,
  updateSchemeApi,
  getRiskRulesList
} from "@/api/riskrules";

import Pagination from "@/common/components/Pagination/index.vue";
import SearchFilter from "@/common/business-components/SearchFilter/index.vue";
import BsmTable from "@/views/components/BsmTable.vue";
import TableList from "@/common/business-components/TableList/index.vue";
import { validateForm } from "@/common/utils";

const showEffectiveStatus = val => {
  if (val) {
    return "启用";
  } else {
    return "禁用";
  }
};
const ifAppeal = val => {
  return val ? "是" : "否";
};

@Component({
  name: "RiskManageList",
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    TableList
  }
})
export default class extends Vue {
  list: IRiskRule[] = [];
  allRuleList: any = [];
  total = 0;
  listLoading = true;
  submitLoading = false;
  showEdit = false;
  showAddRuleDialog = false;
  // showConfirmAddRuleDialog = false
  listQuery = {
    anti_fraud_rule_id: "",
    page: {
      index: 1,
      limit: 10
    }
  };

  transferData = [];
  transferValue = [];

  temp = {
    rule_ids: "",
    name: "",
    status: null,
    scheme_no: null,
    optional_switch: 1
  };

  rules = {
    name: [
      {
        required: true,
        message: "请填写名称",
        trigger: "blur"
      }
    ]
  };

  allRuleListQuery = {
    region_type: 0,
    page: {
      index: 1,
      limit: 1000
    },
    source: {
      data: "",
      type: 1
    }
  };

  addRuleData = {
    effective_time: "",
    rule_id: ""
  };

  queryFilterOptions = [
    {
      field: "status",
      label: "状态",
      type: "select",
      config: {
        list: [
          {
            value: 1,
            label: "启用"
          },
          {
            value: 0,
            label: "禁用"
          }
        ]
      }
    }
  ].map(item => Object.assign({}, item, { main: true }));

  tableListOptions = [
    {
      field: "anti_fraud_rule_view.id",
      type: "actionSetting",
      label: "操作",
      attrs: {
        actionOptions: [
          {
            label: "编辑",
            type: "update"
          }
        ]
      },
      width: 50,
      fixed: true
    },
    {
      field: "scheme_no",
      label: "方案编号",
      width: 150
    },
    {
      field: "scheme_name",
      label: "方案名称"
    },
    {
      field: "status",
      label: "状态",
      filter: showEffectiveStatus
    },
    {
      field: "optional_switch",
      label: "企业端可选",
      filter: ifAppeal
    },
    {
      field: "create_time",
      label: "创建时间",
      filter: "timeFilter"
    },
    {
      field: "update_time",
      label: "更新时间",
      filter: "timeFilter"
    },
    {
      field: "optional_by",
      label: "更新人"
    }
  ];

  created() {
    this.initListQuery();
    this.getList();
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query);
    }
  }

  async getList() {
    this.listLoading = true;
    const { data } = await getActiveRiskRuleSchemeList(this.listQuery);
    this.listLoading = false;
    this.list = data?.items;
    this.total = data.total;
  }

  async getAllRuleList() {
    this.transferData = [];
    if (!this.allRuleList.length) {
      const { data } = await getRiskRulesList(this.allRuleListQuery);
      this.allRuleList = data.items;
    }
    this.allRuleList.forEach((item, idx) => {
      this.transferData.push({
        key: item.id,
        label:
          item.id +
          "|" +
          item.name +
          " - " +
          item.type_alias +
          " - " +
          (item.if_appeal ? "需要申诉" : "无需申诉") +
          (item.status ? "" : " -【已禁用】"),
        status: item.status
      });
    });
  }

  handleFilter() {
    this.listQuery.page.index = 1;
    this.listQuery.page.limit = 10;
    this.getList();
  }

  showAddDialog() {
    this.showAddRuleDialog = true;
    this.showEdit = false;
    this.transferValue = [];
    this.temp = {
      rule_ids: "",
      name: "",
      status: null,
      scheme_no: null,
      optional_switch: 1
    };
    this.getAllRuleList();
  }

  // handleDelete(id: string) {
  //   try {
  //     this.$confirm('确认从模板中删除该规则？', '提示', {
  //       confirmButtonText: '确定',
  //       cancelButtonText: '取消',
  //       type: 'warning'
  //     }).then(() => {
  //       removeRiskRuleFromTemplate({ id: id }).then(res => {
  //         this.$message.success('删除成功')
  //         this.getList()
  //       })
  //     })
  //   } catch (err) {
  //     this.$message.info('取消删除')
  //   }
  // }

  async handleSubmit() {
    if (!(await validateForm(this.$refs.form))) {
      return;
    }
    if (this.transferValue.length < 1) {
      this.$message.error("已勾选规则不能为空");
    }
    this.submitLoading = true;
    this.temp.rule_ids = this.transferValue.join(",");
    if (this.showEdit) {
      await updateSchemeApi(this.temp);
    } else {
      addSchemeApi(this.temp).then(() => {
        console.log(1);
      });
    }
    this.$message.success(this.showEdit ? "编辑成功" : "添加规则成功");
    this.getList();
    this.submitLoading = false;
    this.showAddRuleDialog = false;
  }

  async handleActionCommand(command, id, row) {
    if (command === "remove") {
      // this.handleDelete(row.id)
    } else if (command === "update") {
      this.showEdit = true;
      this.temp.name = row.scheme_name;
      this.temp.status = row.status;
      this.temp.optional_switch = row.optional_switch;
      this.temp.scheme_no = row.scheme_no;
      this.showAddRuleDialog = true;
      await this.getAllRuleList();
      const { data } = await getSchemeNoApi({ scheme_no: row.scheme_no });
      this.transferValue = data.rule_ids ? data.rule_ids.split(",") : [];

      this.checkStatus();
    }
  }

  checkStatus() {
    const newData = [];
    this.transferData.forEach(item => {
      if (item.status) {
        newData.push(item);
      } else {
        const it = this.transferValue.find(v => v === item.key);
        if (it) {
          newData.push(item);
        }
      }
    });
    this.transferData = newData;
  }

  handleTransferChange(val, arrow, arr) {
    if (arrow === "left") {
      let hasDisabled = false;
      arr.forEach(v => {
        const it = this.allRuleList.find(item => item.id === v && !item.status);
        if (it) {
          hasDisabled = true;
          return;
        }
      });
      if (hasDisabled) {
        this.checkStatus();
      }
    }
  }
}
