
















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { parseTime } from "@/common/utils";
import SearchFilter from "@/common/business-components/SearchFilter/index.vue";
import TableList from "@/common/business-components/TableList/index.vue";
import Pagination from "@/common/components/Pagination/index.vue";
import MapSearchSelect from "@/views/components/TypeMap/map/MapSearchSelect.vue";
import {
  getSubsidyTaxApplyPageApi,
  applySmsApi,
  declareConfApi,
  getDeclareConfApi,
  downloadApplyFileApi
} from "@/api/tax";
import DeclearComfirm from "./DeclearComfirm.vue";
import moment from "moment";
@Component({
  name: "ClaimTax",
  components: {
    SearchFilter,
    TableList,
    Pagination,
    DeclearComfirm
  }
})
export default class extends Vue {
  list: any[] = [];
  total = 0;
  listLoading = true;
  listQuery: any = {
    page: {
      index: 1,
      limit: 10
    }
  };
  settingDialogVisible = false;
  applySettingForm = {
    months_per_period: "",
    sms_frequency: "",
    apply_day: ""
  };

  StateMap = {
    0: "暂不申报",
    1: "待处理",
    2: "待审核",
    3: "驳回",
    4: "申报材料生成中",
    5: "申报完成"
  };
  queryFilterOptions = [
    {
      field: "agentDid",
      label: "网络企业",
      component: MapSearchSelect,
      config: {
        field: "agent"
      },
      main: true
    },
    {
      field: "date",
      label: "申报时间",
      componentName: "el-date-picker",
      attrs: {
        type: "monthrange",
        startPlaceholder: "开始月份",
        endPlaceholder: "结束月份"
      },
      main: true
    }
  ];

  tableListOptions = [
    {
      field: "id",
      type: "actionSetting",
      attrs: {
        actionOptions: [
          {
            label: "审核",
            type: "audit",
            disabled: (val, row) => {
              return row.state !== 2;
            }
          },
          {
            label: "查看详情",
            type: "detailAction",
            disabled: (val, row) => {
              return row.state !== 5 && row.state !== 4;
            }
          },
          {
            label: "提醒申报",
            type: "remindDeclare",
            disabled: (val, row) => {
              return row.state !== 1;
            }
          },
          {
            label: "下载明细",
            type: "download",
            disabled: (val, row) => {
              return row.state !== 5;
            }
          }
        ]
      },
      label: "操作",
      width: 50,
      align: "center",
      fixed: true
    },
    {
      field: "agentName",
      label: "网货企业名称"
    },
    {
      field: "startMonth",
      label: "业务时间范围",
      filter: (val, row) => {
        return `${moment(val).format("YYYY-MM")} 至 ${moment(
          row.endMonth
        ).format("YYYY-MM")}`;
      }
    },
    {
      field: "createTime",
      label: "申报时间",
      filter: "timeFilterShowSecond"
    },
    {
      field: "state",
      label: "申报状态",
      filter: v => {
        return this.StateMap[v] || "-";
      }
    },
    {
      field: "updateTime",
      label: "更新时间",
      filter: "timeFilterShowSecond"
    }
  ];

  getQueryParams() {
    const listQuery = JSON.parse(JSON.stringify(this.listQuery));
    if (listQuery.date) {
      listQuery.startMonth = moment(listQuery.date[0]).format("YYYY-MM-01");
      listQuery.endMonth = moment(listQuery.date[1])
        .endOf("month")
        .format("YYYY-MM-DD");
      listQuery.date = undefined;
    }
    return listQuery;
  }

  async getList() {
    try {
      this.listLoading = true;
      const { data } = await getSubsidyTaxApplyPageApi(this.getQueryParams());
      this.list = data.items;
      this.total = data.total;
    } catch (e) {
      //
    } finally {
      this.listLoading = false;
    }
  }

  handleFilter() {
    this.listQuery.page.index = 1;
    this.getList();
  }

  async handleActionCommand(command, id, row) {
    if (command === "detailAction") {
      (this.$refs.declearComfirmRef as any).open(row, "detail");
    } else if (command === "audit") {
      (this.$refs.declearComfirmRef as any).open(row);
    } else if (command === "remindDeclare") {
      applySmsApi({ id: row.id }).then(res => {
        this.$message.success("提醒成功");
      });
    } else if (command === "download") {
      try {
        const res = await downloadApplyFileApi({
          agentDid: row.agentDid,
          startMonth: row.startMonth,
          endMonth: row.endMonth,
          id: row.id
        });
        const data: any = [res];
        const filename = `申报明细.zip`;
        const blob = new Blob(data);
        const downloadElement = document.createElement("a");
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = filename;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      } catch (e) {
        //
      }
    }
  }

  async handleSetting() {
    try {
      const res = await getDeclareConfApi();
      this.applySettingForm = res?.data
        ? { ...res.data }
        : {
            apply_day: "",
            months_per_period: "",
            sms_frequency: ""
          };
      this.settingDialogVisible = true;
    } catch (e) {
      //
    }
    // this.applySettingForm = {
    //   months_per_period: "",
    //   sms_frequency: "",
    //   apply_day: ""
    // };
  }

  created() {
    this.getList();
  }

  async handleSaveSetting() {
    let error = "";
    for (let key in this.applySettingForm) {
      if (!this.applySettingForm[key]) {
        this.$message.warning("输入不能为空");
        return;
      }
      error = Number.isNaN(Number(this.applySettingForm[key]))
        ? "输入格式有误"
        : error;
    }
    console.log("11111111");
    if (error) {
      this.$message.warning(error);
      return;
    }
    console.log("22222222");
    try {
      console.log("333333333");
      await declareConfApi(this.applySettingForm);
      this.$message.success("设置成功");
      this.settingDialogVisible = false;
    } catch (e) {
      //
    }
  }
}
