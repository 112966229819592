

















































































import { Component, Vue } from 'vue-property-decorator'

import {
  getInvoiceQueueList,
  syncResult,
  invoiceUpdateStatus,
  exportInvoiceExcel,
  rePushCallback,
  syncFlushResult
} from '@/api/tax'
import { IPushQueueData } from '@/api/types'

import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { TagsViewModule } from '../../../store/modules/tags-view'
import { InvoiceStatusDesc } from '@/common/constant'
import { convertToExcel, getEnumArray } from '@/common/utils'
import { amountFormatFilter } from '@/common/filters'
import moment from 'moment'
import NoCacheLink from '@/common/business-components/NoCacheLink.vue'

import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'

@Component({
  name: 'invoiceQuery',
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    NoCacheLink
  }
})
export default class extends Vue {
  forcePassForm = {
    forcePassReasons: '',
    images: [],
    taxPushAntiFraudFailedId: ''
  };

  rules = {
    forcePassReasons: [
      { required: true, trigger: 'blur', message: '请输入强制通过理由' }
    ]
  };

  dialogVisible = false;
  dialogVisible2 = false;
  currentRow:any = {}
  applyDetail: any = {
    apply_tax_detail_view: {},
    return_tax_detail_view: {}
  };

  list: IPushQueueData[] = [];
  total = 0;
  totalMoney = 0;
  listLoading = true;
  failedLogs: any = '';
  listQuery = {
    // 0：待推送；1、推送失败；9、推送成功'
    status: null,
    // invoice_type: null,
    // tax_order: null,
    // tax_order_status_list: [],
    page: {
      index: 1,
      limit: 10
    }
  };

  queryFilterOptions = [
    {
      field: 'apply_no',
      label: '开票申请编号',
      main: true
    },
    {
      field: 'status',
      label: '开票状态',
      type: 'select',
      config: {
        list: getEnumArray(InvoiceStatusDesc)
      },
      main: true
    },
    {
      field: 'invoice_code',
      label: '发票代码',
      main: true
    },
    {
      field: 'invoice_number',
      label: '发票号码',
      main: true
    },
    {
      field: 'agent_did',
      label: '网络货运企业',
      component: MapSearchSelect,
      config: {
        field: 'agent'
      },
      main: true
    },
    {
      field: 'buyer_did',
      label: '托运人',
      component: MapSearchSelect,
      config: {
        field: 'consignor'
      },
      main: true
    },
    {
      field: ['apply_time_start', 'apply_time_end'],
      label: '申请时间',
      type: 'dateRange',
      main: true
    },
    {
      field: ['invoice_time_start', 'invoice_time_end'], // 'invoiceTime',
      label: '开票时间',
      type: 'dateRange',
      main: true
    }
  ];

  tableListOptions = [
    {
      field: 'id',
      type: 'actionSetting',
      attrs: {
        actionOptions: [
          {
            label: '同步开票结果',
            type: 'repush',
            disabled: function(config, row) {
              if (row.status === 1) {
                return false
              } else {
                return true
              }
            }
          },
          {
            label: '回调开票结果',
            type: 'confirmCallback'
          },
          // {
          //   label: '确认红冲失败',
          //   type: 'confirmHcFail',
          //   disabled: function(config, row) {
          //     if (row.status === 8) {
          //       return false
          //     } else {
          //       return true
          //     }
          //   }
          // },
          {
            label: '同步红冲结果',
            type: 'reflush',
            disabled: function(config, row) {
              return row.status !== 7
            }
          },
          {
            label: '查看发票地址',
            type: 'viewInvoiceAddress',
            disabled: function(config, row) {
              if (row.status === 8 || row.status === 5 || row.status === 10 || row.status === 11) {
                return false
              } else {
                return true
              }
            }
          }
        ]
      },
      label: '操作',
      width: 50,
      align: 'center',
      fixed: true
    },
    {
      field: 'apply_no',
      label: '开票申请编号',
      fixed: true
    },
    {
      field: 'create_time',
      label: '申请时间',
      filter: 'timeFilterShowSecond'
    },
    {
      field: 'invoice_category_desc',
      label: '发票类型'
    },
    {
      field: 'invoice_tax_included_amount',
      label: '开票金额（元）',
      filter: 'amountFormatFilter'
    },
    {
      field: 'tax',
      label: '税额（元）',
      filter: (v) => {
        return v ? amountFormatFilter(v) : '-'
      }
    },
    {
      field: 'status',
      label: '开票状态',
      type: 'linkLike',
      config: {
        label: val => {
          return val === 11 || val === 4 || val === 3 || val === 10
            ? InvoiceStatusDesc[val]
            : `<span style="color:#686868;cursor:default;">${InvoiceStatusDesc[val]}</span>`
        },
        handle: (val, row) => {
          if (val === 11 || val === 4 || val === 3) {
            this.popDialog(row)
          }
          if (val === 10) {
            this.popDialog2(row)
          }
        }
      }
    },
    {
      field: 'update_time',
      label: '更新时间',
      filter: 'timeFilterShowSecond',
      width: 100,
      align: 'center'
    },
    {
      field: 'invoice_time',
      label: '开票时间',
      filter: 'timeFilterShowSecond',
      width: 100,
      align: 'center'
    },
    {
      field: 'invoice_code',
      label: '发票代码'
    },
    {
      field: 'invoice_number',
      label: '发票号码'
    },
    {
      field: 'item_count',
      label: '运单数',
      type: 'link',
      config: {
        url(val, row) {
          const query = row.waybill_ids.join(',')
          return `/dataquery/waybill?waybillIds=${query}`
        },
        noCache: true
      }
      // config: {
      //   label: val => {
      //     return val
      //   },
      //   handle: (val, row) => {
      //     this.viewWaybill(row)
      //   }
      // }
    },
    {
      field: 'agent_name',
      label: '网络货运企业'
    },
    {
      field: 'agent_tax_no',
      label: '网络货运企业税号'
    },
    {
      field: 'buyer_name',
      label: '托运人',
      width: 180
    },
    {
      field: 'buyer_tax_no',
      label: '托运人税号',
      width: 180
    },
    {
      field: 'channel_desc',
      label: '开票平台'
    },
    {
      field: 'id',
      label: '平台订单号',
      width: 180
    }
  ];

  created() {
    this.initListQuery()
    this.getList()
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
      // this.listQuery.invoice_type &&
      //   (this.listQuery.invoice_type = Number(this.listQuery.invoice_type))
    }
  }

  // viewWaybill(row) {
  //   let path = ''
  //   const query: any = {}
  //   query.waybillIds = row.waybill_ids.join(',')
  //   path = '/dataquery/waybill'


  //   this.$router.push({
  //     path: path,
  //     query: query
  //   })
  // }

  popDialog(row) {
    this.failedLogs = row.error_msg
    this.dialogVisible = true
  }

  popDialog2(row) {
    this.currentRow = row
    this.dialogVisible2 = true
  }

  async getList() {
    this.listLoading = true
    const listQuery = JSON.parse(JSON.stringify(this.listQuery))
    const { data } = await getInvoiceQueueList(listQuery)
    this.list = data.items
    this.total = data.total
    this.totalMoney = data.data
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  handleFilter() {
    this.listQuery.page.index = 1
    this.getList()
  }

  async linkToDetail(id: string, row: any) {
    const path = '/dataquery/sub_waybill'
    const view = (this.$router as any).matcher.match(path)
    await TagsViewModule.delCachedView(view)
    this.$router.push({
      path,
      query: {
        subWaybillIds: row.link_ids
      }
    })
  }

  viewInvoiced() {
    const query: any = {}
    query.invoiceCode = this.applyDetail.invoice_code
    const path = '/tax/invoiced'
    this.$router.push({
      path: path,
      query: query
    })
  }

  handleActionCommand(command, id, row) {
    if (command === 'repush') {
      this.$confirm('此操作将发起同步, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          syncResult({
            id: id
          }).then(() => {
            this.$message.success('发起同步已申请')
            this.getList()
          })
        })
        .catch(e => {
          console.log(e)
        })
    } if (command === 'reflush') {
      syncFlushResult({
        id: id
      }).then(() => {
        this.$message.success('发起同步已申请')
        this.getList()
      })
    } else if (command === 'confirmCallback') {
      this.$confirm('此操作将回调开票, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          rePushCallback({
            id: id
          }).then(() => {
            this.$message.success('回调开票操作成功')
            this.getList()
          })
        })
        .catch(e => {
          console.log(e)
        })
    } else if (command === 'confirmHcFail') {
      this.$confirm('此操作将确认红冲失败, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          invoiceUpdateStatus({
            id: id,
            status: 11
          }).then(() => {
            this.$message.success('确认红冲失败操作成功')
            this.getList()
          })
        })
        .catch(e => {
          console.log(e)
        })
    } else if (command === 'viewInvoiceAddress') {
      let html = `<p style="padding:16px;">蓝票地址：${row.invoice_path}</p>`
      if (row.status === 10) {
        html += `<p style="padding:16px;">红票地址：${row.red_invoice_path}</p>`
      }
      this.$alert(html, '发票地址', {
        dangerouslyUseHTMLString: true
      })
    }
  }

  handleClick(type) {
    if (type === 'export') {
      exportInvoiceExcel(this.listQuery)
        .then(res => {
          const data: any = [res]
          const date: string = moment()
            .format('YYYY_MM_DD')
            .toString()
          convertToExcel(data, `托运人开票查询_${date}.xls`)
        })
        .catch(e => {
          console.log(e)
        })
    } else {
    }
  }
}
