import request from '@/utils/request'
import {
  IGetInspectStrategyListParams,
  IInspectStrategyDetailParams,
  IInspectStrategyDetailData,
  IInspectConfigAgentListParams,
  IInspectConfigListParams,
  IInspectUpdateParams
} from './types'
import { IPage } from '@/api/interface/common'

const inspect_flow_path = 'sample_inspect_flow'
const inspect_strategy_path = 'sample_inspect_strategy'
const inspect_config_path = 'sample_inspect_config'


// export interface IGetInspectStrategyListParams {
//   name: string
//   page: IPage
//   status: number | string
//   type: number | string
// }

// export interface IInspectStrategyListData {
//   agent_count: number
//   create_time: number
//   id: string
//   name: string
//   status: number
//   status_desc: string
//   strategy_no: string
//   type: number
//   type_desc: string
// }

// export interface IInspectStrategyDetailParams {
//   id: string
// // }
// export interface IInspectStrategyDetailData {
//   days: number | string
//   description: string
//   frequency_type: number | string
//   id: string
//   init: number | string
//   max_count: number | string
//   name: string
//   percentage: number | string
//   status: number | string
//   strategy_no ? : string
//   type: number | ''
// }

// export interface IInspectConfigListParams {
//   agentId: string
//   endTime: number | string
//   page: IPage
//   settledType: number | string
//   startTime: number | string
//   status: number | string
//   unsettledType: number | string
// }

export interface IInspectConfigData {
  agentName: string
  entryTime: number
  legalIdentityNo: string
  passedCnt: number
  pendingCnt: number
  rejectedCnt: number
  sendbackCnt: number
  status: number
  totalCnt: number
  waitConfirmCnt: number
}

export interface IGetInspectListParams {
  agent_did ? : string
  create_time_end ? : number
  create_time_start ? : number
  flow_no ? : string
  generate_type ? : number
  page_query: IPage
  status ? : number
  strategy_type ? : number
  waybill_no ? : string

}


export const inspectApprove = (params: any) => {
  return request({
    url: `${inspect_flow_path}/approve`,
    method: 'post',
    data: params
  })
}

export const inspectRefuse = (params: any) => {
  return request({
    url: `${inspect_flow_path}/refuse`,
    method: 'post',
    data: params
  })
}

export const getInspectList = (params: IGetInspectListParams) =>
  request({
    url: `${inspect_flow_path}/page`,
    method: 'post',
    data: params
  })

export const getInspectDetail = (params: {
    id: string
  }) =>
  request({
    url: `${inspect_flow_path}/approve_flow_info`,
    method: 'post',
    data: params
  })

export const getInspectOperationRecordList = (params: {
    flow_id: string
    page_query: IPage
  }) =>
  request({
    url: `${inspect_flow_path}/approve_page`,
    method: 'post',
    data: params
  })

export const manualCreateInspect = (params: any) =>
  request({
    url: `${inspect_flow_path}/manually_sampling`,
    method: 'post',
    data: params
  })

export const findInspectStrategyList = (params: IGetInspectStrategyListParams) => request({
  url: `${inspect_strategy_path}/findPage`,
  method: 'post',
  data: params
})

export const findinspectStrategyDetail = (params: IInspectStrategyDetailParams) =>
  request({
    url: `${inspect_strategy_path}/detail`,
    method: 'post',
    data: params
  })

export const createInspectStrategy = (params: IInspectStrategyDetailData) =>
  request({
    url: `${inspect_strategy_path}/create`,
    method: 'post',
    data: params
  })

export const editInspectStrategy = (params: IInspectStrategyDetailData) =>
  request({
    url: `${inspect_strategy_path}/edit`,
    method: 'post',
    data: params
  })

export const getInspectConfigAgentList = (params: IInspectConfigAgentListParams) =>
  request({
    url: `${inspect_config_path}/findPage`,
    method: 'post',
    data: params
  })

export const getInspectConfigShipperList = (params: IInspectConfigAgentListParams) =>
  request({
    url: 'consignor_sample_inspect_config/findPage',
    method: 'post',
    data: params
  })

export const getShipperConfigDetail = (params: any) =>
  request({
    url: 'consignor_sample_inspect_config/configList',
    method: 'post',
    data: params
  })

export const updateShipperConfig = (params: any) =>
  request({
    url: 'consignor_sample_inspect_config/updateCfg',
    method: 'post',
    data: params
  })

export const getInspectConfigList = (params: IInspectConfigListParams) =>
  request({
    url: `${inspect_config_path}/configList`,
    method: 'post',
    data: params
  })

export const findInspectStrategyListNoPage = () => request({
  url: `${inspect_config_path}/selectStrategyList`,
  method: 'post',
  data: {
    type: 2
  }
})

export const updateInspectConfig = (params: IInspectUpdateParams) => request({
  url: `${inspect_config_path}/updateCfg`,
  method: 'post',
  data: params
})

export const exportInspectList = (params: IGetInspectListParams) =>
  request({
    url: `${inspect_flow_path}/export`,
    method: 'post',
    data: params,
    responseType: 'blob'
  })
