
















































































































































































































import { Component, Vue } from "vue-property-decorator";
import { validateForm } from "@/common/utils";
import UploadImage from "@/components/UploadImage/index.vue";
import {
  noticePost,
  noticeDetail,
  noticeRefuse,
  noticeSend,
  noticeVoid
} from "@/api/notice";
import { getConditionList } from "@/api/condition";
import { UserModule } from "@/store/modules/user";
import { TagsViewModule } from "@/store/modules/tags-view";
import Tinymce from "@/components/Tinymce/index.vue";
import MultiUploadFile from "@/common/business-components/MultiUploadFile/index.vue";
import { uploadFile, uploadImage } from "@/api/file";
import { photoCompress, convertBase64UrlToFile } from "@/utils/compress";
import moment from "moment";

@Component({
  name: "Detail",
  components: {
    UploadImage,
    MultiUploadFile,
    Tinymce
  }
})
export default class extends Vue {
  rules = {
    notice_type: [
      { required: true, message: "请选择通知类型", trigger: "change" }
    ],
    // sender: [{ required: true, message: '请填写发送人', trigger: 'change' }],
    feedback_type: [
      { required: true, message: "请选择是否需要回执", trigger: "change" }
    ],
    title: [{ required: true, message: "请输入标题", trigger: "blur" }],
    content: [{ required: true, message: "请输入内容", trigger: "blur" }]
  };

  notice_type_map = [
    { value: 1, label: "公告（置顶一个月）" },
    { value: 2, label: "重要通知（置顶一周）" },
    { value: 3, label: "通知" },
    { value: 4, label: "提示" }
  ];

  subject_type_map = [
    { value: "1", label: "托运人" },
    { value: "2", label: "网货企业" }
  ];

  feedback_type_map = [
    { value: 1, label: "是，纯文字" },
    // { value: 2, label: '是，图片或文件' },
    { value: 3, label: "是，文字加文件" },
    { value: 4, label: "否" }
  ];

  noticeId: any = "";
  loading = false;
  dialogVisible = false;
  dialogLoading = false;
  queryLoading = false;
  isPublicNotice = false;
  timingTimeDesc = "";
  receiverList = [];
  industrialParkName = UserModule.industrial_park_name;
  form = {
    status: 0,
    subject_type: "2",
    receiver_type_alias: "",
    feedback_type: null,
    notice_type: "",
    sender: "",
    receiver_type: 2,
    agent_did_list: [],
    file_url: "",
    fileUrls: null,
    title: "",
    timing_time: 0,
    send_type: 1,
    content: ""
  };

  fileList = [];

  created() {
    this.noticeId = this.$route.params.id;
    this.init();
  }

  changeSubjectType() {
    this.form.agent_did_list = [];
  }

  momentTime(time) {
    return moment(time).format("YYYY-MM-DD HH:mm:ss");
  }

  isDetail() {
    return this.noticeId !== "create";
  }

  async handleNoticeVoid() {
    this.$confirm("确定作废此通知吗?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      this.noticeVoidDo();
    });
  }

  changeNoticeType(val) {
    if (val === 1) {
      this.isPublicNotice = true;
      this.form.feedback_type = 4;
      this.form.agent_did_list = [];
    } else {
      this.isPublicNotice = false;
    }
  }

  handleNoticeRefuse() {
    this.$confirm("确定拒绝此通知吗?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      this.noticeRefuseDo();
    });
  }

  async noticeRefuseDo() {
    try {
      await noticeRefuse({ id: this.noticeId });
      this.$message({
        type: "success",
        message: "操作成功!"
      });
      this.closeCurrentView();
    } finally {
    }
  }

  async noticeVoidDo() {
    try {
      await noticeVoid({ id: this.noticeId });
      this.$message({
        type: "success",
        message: "操作成功!"
      });
      this.closeCurrentView();
    } finally {
    }
  }

  async noticeSendDo() {
    try {
      await noticeSend({ id: this.noticeId });
      this.$message({
        type: "success",
        message: "操作成功!"
      });
      this.closeCurrentView();
    } finally {
    }
  }

  async handleNoticeSend() {
    this.$confirm("确定发送此通知吗?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      this.noticeSendDo();
    });
  }

  async remoteMethod(query) {
    if (query !== "") {
      this.queryLoading = true;
      try {
        const params: any = {
          page: {
            index: 1,
            limit: 10
          }
        };
        if (this.form.subject_type === "1") {
          params.consignor = query;
        } else {
          params.agent = query;
        }
        const { data } = await getConditionList(params);
        this.receiverList = data.items;
      } finally {
        this.queryLoading = false;
      }
    } else {
      this.receiverList = [];
    }
  }

  async init() {
    if (this.isDetail()) {
      const { data } = await noticeDetail({
        id: this.noticeId
      });
      this.form = { ...data };
      this.fileList = data.file_url.split(",");
      this.form.subject_type = data.subjectType + "";
    }
  }

  async showTimeDialog() {
    if (!(await validateForm(this.$refs.form))) {
      return;
    }
    this.dialogVisible = true;
  }

  handlePreview() {
    this.closeCurrentView();
  }

  closeCurrentView() {
    let view = {};
    TagsViewModule.visitedViews.forEach(item => {
      if (item.title === "通知管理") {
        view = item;
      }
    });
    TagsViewModule.delView(view);
    this.$router.push("/notice/manage");
  }

  async onSubmit(sendType: any) {
    if (!(await validateForm(this.$refs.form))) {
      return;
    }
    this.form.sender = this.form.sender || this.industrialParkName;
    this.form.send_type = sendType || 2; // 发送类型 1、即时发送 2、定时发送
    this.form.timing_time = sendType
      ? 0
      : new Date(this.timingTimeDesc).getTime();
    this.form.receiver_type = this.form.agent_did_list.length ? 1 : 2;

    // console.log(this.form)
    let urls = "";
    this.loading = true;

    try {
      if (this.form.fileUrls && this.form.fileUrls.length) {
        urls = (
          await Promise.all(
            this.form.fileUrls.map(async img => {
              if (typeof img === "string") {
                return img;
              }
              if (img.status === "success") {
                return img.url;
              } else {
                const d = new FormData();
                const imgFile = img.raw;
                if (imgFile.type.indexOf("image") < 0) {
                  d.append("file", imgFile);
                  console.log(d);
                  const { data } = await uploadFile(d);
                  return data?.resource_url || "";
                } else {
                  const base64 = await photoCompress(imgFile, {
                    width: 1000,
                    quality: 0.8
                  });
                  const file = convertBase64UrlToFile(base64, imgFile.name);
                  d.append("pic", file);
                  const { data } = await uploadImage(d);
                  return data?.resource_url || "";
                }
              }
            })
          )
        ).join(",");
      }

      this.form.file_url = urls;
      await noticePost(this.form);
      this.$message.success("提交成功");
      this.closeCurrentView();
    } finally {
      this.loading = false;
    }
  }
}
