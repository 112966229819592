







import { Line } from '@antv/g2plot'

import { Component, Prop, InjectReactive, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResizeMixin from './mixins/resize1'
import moment from 'moment'
import _ from 'lodash'
import { getTimeFormat } from '../../utils'

@Component({
  name: 'LineChart'
})
export default class extends mixins(ResizeMixin) {
  @Prop({ default: 'line-chart' }) private className!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: '100%' }) private height!: string

  @Prop({ default: 'timeCat' }) private type!: any
  @Prop({ default: '' }) private description!: string
  @Prop({ default: 'type' }) private seriesField!: string
  @Prop() private yFilter: string
  @Prop() private tooltipFilter: string
  @Prop({ default: () => {} }) private config: any
  @Prop() private timeFormat: string
  private localTimeFormat = 'MM.DD'
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  }

  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.destroy()
    this.chart = null
  }

  @Watch('data', {
    deep: true
  })
  onDataChange(newData, oldData) {
    console.log(newData)
    if (newData.length) {
      const startTime = newData[0][this.xField]
      const endTime = newData[newData.length - 1][this.xField]
      this.localTimeFormat = this.timeFormat ? this.timeFormat : getTimeFormat(startTime, endTime)
    } else {
      this.localTimeFormat = this.timeFormat ? this.timeFormat : 'MM.DD'
    }
  }

  private initChart() {
    const that = this
    const meta: any = {}
    meta[this.xField] = {
      range: [0, 1]
    }
    meta[this.yField] = {
      formatter: (v) => {
        if (that.yFilter) {
          return that.$options.filters[that.yFilter](v, 0)
        } else {
          return v
        }
      }
    }
    const config: any = _.merge({
      meta: meta,
      title: {
        visible: true,
        text: this.title
      },
      description: {
        visible: true,
        text: this.description
      },
      autoFit: true,
      data: this.data,
      padding: [100, 20, 40, 40],
      xField: this.xField,
      yField: this.yField,
      xAxis: {
        type: this.type,
        line: {
        },
        label: {
          autoRotate: false,
          formatter: function(val, b) {
            return moment(Number(b.id)).format(that.localTimeFormat).toString()
          },
          style: {
            fontSize: 12,
            textAlign: 'center',
            lineHeight: 20,
            fill: '#999999'
          }
        }
      },
      yAxis: {
        nice: true,
        grid: null,
        line: null,
        label: {
          offset: 12,
          style: {
            fontSize: 10,
            textAlign: 'center',
            lineHeight: 14,
            fill: '#999999',
            autoHide: false
          }
        }
      },
      legend: {
        visible: true,
        position: 'top-right',
        offsetX: -10,
        offsetY: 21
      },
      tooltip: {
        offset: 10,
        shared: false,
        domStyles: {
          'g2-tooltip-value': {
            marginLeft: '8px'
          }
        }
        // formatter: (x, y, z) => {
        //   let value = y
        //   if (that.tooltipFilter) {
        //     value = that.$options.filters[that.tooltipFilter](value)
        //   }
        //   return {
        //     name: z,
        //     value: value
        //   }
        // }
      },
      seriesField: this.seriesField,
      responsive: true,
      smooth: true
    }, this.config)

    this.chart = new Line(this.$refs.chart as HTMLElement, config)

    this.chart.render()
  }
}

