// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.showRightPanel {\n  overflow: hidden;\n  position: relative;\n  width: calc(100% - 15px);\n}\n.waybill-log-container {\n  padding: 32px;\n}\n.waybill-log-container .log-title {\n    font-size: 18px;\n    line-height: 25px;\n    font-weight: bold;\n    color: #1A1A1A;\n}\n.waybill-log-container .log-time {\n    font-size: 14px;\n    line-height: 20px;\n    color: #999999;\n}\n.waybill-log-container .log-desc {\n    font-size: 14px;\n    line-height: 20px;\n    color: #686868;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
