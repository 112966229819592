







import { Component, Vue, Watch, Prop, Model, Emit } from 'vue-property-decorator'

import ace from 'brace'
require('brace/ext/language_tools')
require('brace/mode/groovy')
@Component({
  name: 'CodeEditor'
})
export default class CodeEditor extends Vue {
  @Prop({ default: '' }) content!: string
  @Prop({ default: '' }) filename: string
  @Prop({ default: false }) canEditor: boolean

  public editor: any = {}
  private canUpdateText = true
  private textTimer
  private originValue = ''

  @Watch('content')
  contentChange(value) {
    this.originValue = value
    this.editor.setValue(value)
    this.editor.moveCursorTo(0, 0)
    this.editor.session.$undoManager.$undoStack = []
  }

  @Watch('canEditor')
  canEditChange(v) {
    this.editor.setReadOnly(!v)
  }

  private throttleText() {
    // 节流
    if (this.canUpdateText) {
      this.canUpdateText = false
      setTimeout(() => {
        this.$emit('update', this.editor.getValue())
        this.canUpdateText = true
      }, 500)
    }
  }

  private debounceText() {
    // 消抖
    clearTimeout(this.textTimer)
    this.textTimer = setTimeout(() => {
      this.$emit('update', this.editor.getValue())
    }, 500)
  }

  private listeningContent() {
    this.throttleText()
    this.debounceText()
  }

  mounted() {
    this.editor = ace.edit('editor')
    ace.acequire('ace/ext/language_tools')

    this.editor.setOptions({
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: false,
      autoScrollEditorIntoView: true
    })
    this.editor.$blockScrolling = Infinity
    this.editor.setShowPrintMargin(false)
    this.editor.setReadOnly(!this.canEditor)
    this.editor.session.setMode('ace/mode/groovy')

    this.editor.commands.addCommand({
      name: 'myUndo',
      bindKey: { win: 'Ctrl-Z', mac: 'Command-Z' },
      exec: editor => {
        const { $undoStack } = editor.session.$undoManager

        if ($undoStack.length === 1) {
          editor.undo()
          editor.setValue(this.originValue)
          editor.moveCursorTo(0, 0)
        } else {
          editor.undo()
        }
      },
      readOnly: false
    })
    const dom: any = this.$refs.editor
    dom.addEventListener('keyup', this.listeningContent)

    if (this.content) {
      this.contentChange(this.content)
    }
  }

  beforeDestroy() {
    const dom: any = this.$refs.editor
    dom.removeEventListener('keyup', this.listeningContent)
  }
}

