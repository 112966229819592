







import { Component, Vue } from 'vue-property-decorator'
// import ServiceWorkerUpdatePopup from '@/pwa/components/ServiceWorkerUpdatePopup.vue'

@Component({
  name: 'App',
  components: {
  //  ServiceWorkerUpdatePopup
  }
})
export default class extends Vue {}
