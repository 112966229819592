var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-container",
      class: { "green-skin": _vm.isGreenSkin }
    },
    [
      _vm.isGreenSkin
        ? _c("section", { staticClass: "title-box" }, [
            _c("img", {
              attrs: {
                src:
                  "//bsmxk-prod.oss-cn-hangzhou.aliyuncs.com/resource/img/login-text.png"
              }
            })
          ])
        : _vm._e(),
      _vm.isGreenSkin
        ? _c("img", {
            staticStyle: {
              position: "fixed",
              bottom: "40px",
              left: "50%",
              "margin-left": "-425px"
            },
            attrs: {
              width: "850",
              height: "60",
              src:
                "//bsmxk-prod.oss-cn-hangzhou.aliyuncs.com/resource/img/login-bottom-text.png"
            }
          })
        : _vm._e(),
      _c("section", { staticClass: "login-form" }, [
        !_vm.isGreenSkin
          ? _c("section", { staticClass: "logo-container" }, [
              _vm.isHaoyunbao
                ? _c("img", {
                    staticStyle: { width: "140px" },
                    attrs: {
                      src: require("../../../public/img/icons/logo-haoyunbao.png")
                    }
                  })
                : _c("img", {
                    attrs: {
                      src: require("../../../public/img/icons/logo@2x.png")
                    }
                  }),
              _c(
                "section",
                { staticClass: "pt-3 font-14 font-weight-medium text-main" },
                [_vm._v(" 网络货运监管服务平台 ")]
              )
            ])
          : _vm._e(),
        !_vm.isGreenSkin
          ? _c(
              "section",
              {
                staticClass: "text-center font-24 font-weight-medium",
                staticStyle: { "padding-top": "107px", color: "#333333" }
              },
              [_c("span", [_vm._v("登录")])]
            )
          : _c("section", { staticClass: "login-btn-box" }, [
              _c(
                "span",
                {
                  staticClass: "btn-login",
                  on: {
                    click: function($event) {
                      _vm.showLoginBox = true
                    }
                  }
                },
                [_vm._v("管理部门登录")]
              ),
              _c(
                "span",
                { staticClass: "btn-login", on: { click: _vm.jumpLogin } },
                [_vm._v("企业登录")]
              )
            ]),
        _c(
          "section",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isGreenSkin || _vm.showLoginBox,
                expression: "!isGreenSkin || showLoginBox"
              }
            ],
            staticClass: "form-container"
          },
          [
            _vm.isGreenSkin
              ? _c(
                  "span",
                  {
                    staticClass: "close-box",
                    on: {
                      click: function($event) {
                        _vm.showLoginBox = false
                      }
                    }
                  },
                  [_c("i", { staticClass: "el-icon-close" })]
                )
              : _vm._e(),
            _c(
              "el-form",
              {
                ref: "loginForm",
                attrs: { model: _vm.loginForm, rules: _vm.loginRules }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "账号/手机号", prop: "username" } },
                  [
                    _c("el-input", {
                      ref: "username",
                      attrs: { placeholder: "请输入账号或手机号" },
                      model: {
                        value: _vm.loginForm.username,
                        callback: function($$v) {
                          _vm.$set(_vm.loginForm, "username", $$v)
                        },
                        expression: "loginForm.username"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "密码", prop: "password" } },
                  [
                    _c("el-input", {
                      ref: "password",
                      attrs: { "show-password": "", placeholder: "请输入密码" },
                      nativeOn: {
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleLogin($event)
                        }
                      },
                      model: {
                        value: _vm.loginForm.password,
                        callback: function($$v) {
                          _vm.$set(_vm.loginForm, "password", $$v)
                        },
                        expression: "loginForm.password"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "section",
                  { staticClass: "login-button" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleLogin }
                      },
                      [_vm._v(" 登录 ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }