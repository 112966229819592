// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.tax-detail-table {\n  width: 100%;\n  text-align: center;\n  border-right: 1px solid #eeeeee;\n  border-bottom: 1px solid #eeeeee;\n}\n.tax-detail-table tr td,\n  .tax-detail-table tr th {\n    padding: 6px;\n    border-left: 1px solid #eeeeee;\n    border-top: 1px solid #eeeeee;\n}\n.apply-detail-dialog .title {\n  font-weight: bold;\n}\n.apply-detail-dialog .value {\n  color: #666;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
