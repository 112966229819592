




























































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import ChartContainer from '@/common/business-components/ChartContainer.vue'
import LineChart from '@/common/components/Charts/LineChart.vue'
import { getAlarmRuleList } from '@/api/black_list'
import {
  getNoticeList,
  getNoticeNewest,
  getCoordinationList
} from '@/api/notice'
import {
  getOverviewWaybillChart,
  agentScoreApi,
  industrialCountApi
} from '@/api/overview'
import { agentSortQuery } from '@/api/agent_rank'
import AlarmList from '@/views/regulation/components/alarm-list.vue'
import AlarmPop from '@/views/regulation/components/alarm-pop.vue'
import moment from 'moment'
import { processTableData } from '@/utils'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'GatherHome',
  components: {
    AlarmList,
    AlarmPop,
    ChartContainer,
    LineChart
  }
})
export default class extends Vue {
  roles = UserModule.roles

  data:any={}
  noticeNewest:any={}
  industrialCountData:any={}
  currentAlarmId=''
  currentName=''
  newsList:any=[]
  agentRankList:any=[]
  coordinationList:any=[]
  agentList:any=[]
  waybillLineData = []
  waybill_count = 0
  coordinationType = 1
  loadingData = true
  noticeNewestLoading = true
  noticeLoading = true
  coordinationLoading = true
  agentLoading = true
  rankLoading = true
  countLoading = true
  waybillLoading = true

  listQuery = {
    frequency_types: [1, 2],
    subject_types: [1, 2, 3, 4, 5],
    page_query: {
      index: 1,
      limit: 100
    }
  }


  mounted() {
    this.roles.includes('risk_alarm') && this.getList()
    this.roles.includes('notice_list') && this.getNewsList()
    this.roles.includes('newest_notice') && this.getNewest()
    this.roles.includes('agent_sort') && this.getRankList()
    this.roles.includes('overview') && this.getOverviewData()
    this.roles.includes('agent_score_rank') && this.agentScoreList()
    this.roles.includes('industrial_subject_count') && this.industrialCount()
    this.roles.includes('coordination') && this.viewCoordinationList(1)
  }

  async getOverviewData() {
    const params = {
      start_time: moment().add(-30, 'days').startOf('day').valueOf(),
      end_time: moment().valueOf(),
      business_mode: 0,
      time_type: 1
    }
    getOverviewWaybillChart(params).then(({ data }) => {
      this.waybillLoading = false
      processTableData(data.invoiced_waybill_count_list, '开票运单数')
      processTableData(data.new_waybill_count_list, '新开运单数')
      processTableData(data.paid_waybill_count_list, '支付运单数')
      processTableData(data.receive_waybill_count_list, '签收运单数')

      this.waybill_count = data.waybill_count
      this.waybillLineData = data.invoiced_waybill_count_list.concat(data.new_waybill_count_list).concat(data.paid_waybill_count_list).concat(data.receive_waybill_count_list)
    })
  }

  momentTime(time) {
    return moment(time).format('YYYY-MM-DD')
  }

  jumpTo(link) {
    this.$router.push({
      path: link
    })
  }

  async getList() {
    const { data } = await getAlarmRuleList(this.listQuery)
    this.loadingData = false
    this.data = {
      items: data.items
    }
  }

  async agentScoreList() {
    const { data } = await agentScoreApi()
    this.agentLoading = false
    this.agentList = data
  }

  async industrialCount() {
    const { data } = await industrialCountApi()
    this.countLoading = false
    this.industrialCountData = data
  }

  async getRankList() {
    const params = {
      start_time: moment().subtract(30, 'days').valueOf(),
      end_time: new Date().getTime(),
      business_mode: 0,
      time_type: 1,
      sort_type: 2 // 按运单笔数排序
    }
    const { data } = await agentSortQuery(params)
    this.rankLoading = false
    this.agentRankList = data.agent_sort_views.splice(0, 5)
  }

  async viewCoordinationList(type) {
    this.coordinationType = type
    const params = { tab_type: type, page_query: { index: 1, limit: 5 } }
    const { data } = await getCoordinationList(params)
    this.coordinationLoading = false
    this.coordinationList = data.items || []
  }

  async getNewest() {
    const { data } = await getNoticeNewest()
    this.noticeNewestLoading = false
    this.noticeNewest = data || {}
  }

  async getNewsList() {
    const query = {
      tab_type: 2,
      status: 2,
      page_query: {
        index: 1,
        limit: 5
      }
    }
    const { data } = await getNoticeList(query)
    this.noticeLoading = false
    this.newsList = data.items || []
  }

  showPop(row) {
    this.currentAlarmId = row.id
    this.currentName = row.name
    ;(this.$refs.alarmDialog as any).showDialog()
  }
}
