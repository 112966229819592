var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subject-detail-dialog" },
    [
      _vm._t("prefix"),
      _vm.isButtonVisible
        ? _c(
            "el-button",
            {
              staticStyle: { position: "absolute", right: "24px", top: "16px" },
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.showSubjectDetail }
            },
            [_vm._v(" 查看详情 ")]
          )
        : _vm._e(),
      _c(
        "no-cache-link",
        {
          staticStyle: { float: "right" },
          attrs: {
            to:
              "/regulation/regulation-detail?subject_did=" +
              _vm.id +
              "&subject_type=" +
              _vm.subjectType +
              "&start_time=" +
              _vm.startTime +
              "&end_time=" +
              _vm.endTime +
              "&business_mode=" +
              _vm.businessMode
          }
        },
        [
          _c("el-button", { attrs: { type: "primary", size: "mini" } }, [
            _vm._v(" 风险信息查询 ")
          ])
        ],
        1
      ),
      _vm._t("suffix"),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.isDialogVisible, title: _vm.title },
          on: {
            "update:visible": function($event) {
              _vm.isDialogVisible = $event
            }
          }
        },
        [
          _vm._l(_vm.data, function(value, key) {
            return [
              _vm.isVisible(value, _vm.data, _vm.typeDesc[key])
                ? _c(
                    "el-row",
                    { key: key, staticClass: "subject-detail-dialog-row" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "title", attrs: { span: 8 } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.typeDesc[key] && _vm.typeDesc[key].label
                              ) +
                              "： "
                          )
                        ]
                      ),
                      _vm.typeDesc[key] && _vm.typeDesc[key].type === "picture"
                        ? _c(
                            "el-col",
                            {
                              staticClass: "value-picture",
                              attrs: { span: 16 }
                            },
                            [
                              _c("contain-show-img", {
                                staticStyle: {
                                  width: "80px",
                                  height: "80px",
                                  "margin-right": "10px"
                                },
                                attrs: { src: value }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.typeDesc[key] && _vm.typeDesc[key].type === "text"
                        ? _c(
                            "el-col",
                            { staticClass: "value-text", attrs: { span: 16 } },
                            [_vm._v(" " + _vm._s(value) + " ")]
                          )
                        : _vm._e(),
                      _vm.typeDesc[key] && _vm.typeDesc[key].type === "time"
                        ? _c(
                            "el-col",
                            { staticClass: "value-text", attrs: { span: 16 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("timeFilterShowSecond")(value)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.typeDesc[key] && _vm.typeDesc[key].type === "weight"
                        ? _c(
                            "el-col",
                            { staticClass: "value-text", attrs: { span: 16 } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm._f("weight")(value)) + "Kg "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.typeDesc[key] &&
                      _vm.typeDesc[key].type === "link_parent_agent"
                        ? _c(
                            "el-col",
                            {
                              staticClass: "value-text value-link",
                              attrs: { span: 16 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    height: "25px",
                                    "line-height": "25px",
                                    cursor: "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleLink(
                                        _vm.data,
                                        _vm.typeDesc[key]
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(value) + " ")]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }