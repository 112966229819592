












































import { Component, Prop, Vue } from 'vue-property-decorator'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import Pagination from '@/common/components/Pagination/index.vue'
import { TableList } from '@/common/business-components/'
import { convertToExcel } from '@/common/utils'
import {
  downloadAlarmAgentExcel,
  getAlarmAgentList,
  IGetAlarmAgentListParams
} from '@/api/alarm'

@Component({
  name: 'alarmDialog',
  components: {
    Pagination,
    TableList,
    SearchFilter
  }
})
export default class extends Vue {
  @Prop() currentId: string
  @Prop() currentName: string
  @Prop() queryName: string

  alarmAgentListDialogVisible = false
  alarmAgent: any = []
  alarmAgentTotal = 0
  alarmAgentQuery: IGetAlarmAgentListParams = {
    name: '',
    page: {
      index: 1,
      limit: 5
    }
  }

  async showDialog() {
    this.alarmAgentListDialogVisible = true
    await this.$nextTick()
    this.getAlarmAgent()
    this.alarmAgentQuery.name = this.queryName
  }


  alarmAgentFilterOptions = [
    {
      field: 'name',
      label: '预警主体名称',
      // component: MapSearchSelect,
      main: true
    }, {
      field: ['startTime', 'endTime'],
      label: '预警周期',
      type: 'dateRange',
      main: true
    }
  ]

  alarmAgentOptions: any = [{
    field: 'subject_name',
    label: '主体名称'
  }, {
    field: 'unique_code',
    label: '主体证件号'
  }, {
    field: 'alarm_period_format',
    label: '预警周期',
    width: 200
  },
  {
    field: 'threshold_format',
    label: '当前预警值'
    // filter: (val) => {
    //   const quota = this.getCurrentAlarmQuota()
    //   if (quota.type === 3) {
    //     return val + '天'
    //   } else {
    //     return val
    //   }
    // }
  },
  {
    field: 'exceed_format',
    label: '当前运单比例（实际值）',
    width: 300
    // filter: (val) => {
    //   const quota = this.getCurrentAlarmQuota()
    //   if (quota.type === 3) {
    //     return val + '天'
    //   } else {
    //     return val
    //   }
    // }
  }
  ]

  async getAlarmAgent() {
    const { data } = await getAlarmAgentList(Object.assign(this.alarmAgentQuery, { alarmRuleId: this.currentId }))
    this.alarmAgent = data.items
    this.alarmAgentTotal = data.total
  }

  // getCurrentAlarmQuota() {
  //   return this.currentAlarmQuote
  // }

  handleAlarmAgentFilter() {
    this.alarmAgentQuery.page.index = 1
    this.alarmAgentQuery.page.limit = 5
    this.getAlarmAgent()
  }

  downloadAlarmAgent() {
    const params = Object.assign(this.alarmAgentQuery, { alarmRuleId: this.currentId })
    downloadAlarmAgentExcel(params)
      .then(res => {
        const data: any = [res]
        const name = `预警信息_${this.currentName}`
        convertToExcel(data, name)
      })
  }
}

