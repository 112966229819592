import request from '@/utils/request'
import { IPage } from '@/api/interface/common'
export interface IUpdateAlarmQuotaParams {
  days: number
  description: string
  frequency_type: number
  id: string
  informEnums: string
  name: string
  status: number
  threshold: number
}
export interface IGetAlarmQuotaListParams {
  page: IPage
  name ? : string
  status ? : number
}

export interface IGetAlarmAgentListParams {
  alarmRuleId?: string
  endTime?: number
  name?: string
  page: IPage
  startTime?: number
}

const path = 'industry_alarm_rule'
// 查看

export const getAlarmQuotaDetail = (params: { id: string }) => request({
  url: `${path}/detail`,
  method: 'post',
  data: params
})
// 更新策略

export const updateAlarmQuota = (params: IUpdateAlarmQuotaParams) => request({
  url: `${path}/edit`,
  method: 'post',
  data: params
})
// 分页

export const getAlarmQuotaList = (params: IGetAlarmQuotaListParams) => request({
  url: `${path}/findPage`,
  method: 'post',
  data: params
})
// 下载

export const downloadAlarmAgentExcel = (params: IGetAlarmAgentListParams) => request({
  url: 'industry_alarm_subject/download',
  method: 'post',
  data: params,
  responseType: 'blob'
})
// 分页

export const getAlarmAgentList = (params: IGetAlarmAgentListParams) => request({
  url: 'industry_alarm_subject/findPage',
  method: 'post',
  data: params
})
