var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("search-filter", {
        attrs: { options: _vm.queryFilterOptions, "type-default": "input" },
        on: { search: _vm.handleFilter },
        model: {
          value: _vm.listQuery,
          callback: function($$v) {
            _vm.listQuery = $$v
          },
          expression: "listQuery"
        }
      }),
      _c(
        "section",
        [
          _c("bsm-table", {
            attrs: {
              data: _vm.list,
              options: _vm.tableListOptions,
              "list-loading": _vm.listLoading,
              "show-setting": false,
              "show-index": true
            },
            on: {
              actionCommand: _vm.handleActionCommand,
              "button-action": _vm.handleActionCommand
            }
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          name: "tax-push",
          total: _vm.total,
          page: _vm.listQuery.page.index,
          limit: _vm.listQuery.page.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery.page, "index", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery.page, "limit", $event)
          },
          pagination: _vm.getList
        },
        scopedSlots: _vm._u([
          {
            key: "end",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["consignor_order_export"],
                        expression: "['consignor_order_export']"
                      }
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.handleClick("export")
                      }
                    }
                  },
                  [_vm._v(" 导出Excel ")]
                ),
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "14px", "margin-left": "16px" }
                  },
                  [
                    _vm._v(
                      "开票金额合计：" +
                        _vm._s(_vm._f("amountFormatFilter")(_vm.totalMoney)) +
                        "元"
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "失败信息",
            visible: _vm.dialogVisible,
            width: "30%",
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("section", [_vm._v(" " + _vm._s(_vm.failedLogs) + " ")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "红冲信息",
            visible: _vm.dialogVisible2,
            width: "400px",
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible2 = $event
            }
          }
        },
        [
          _c("section", [
            _c("p", [
              _vm._v("红字发票代码：" + _vm._s(_vm.currentRow.red_invoice_code))
            ]),
            _c("p", [
              _vm._v(
                "红字发票号码：" + _vm._s(_vm.currentRow.red_invoice_number)
              )
            ])
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible2 = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }