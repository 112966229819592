




























import { Component, Prop, Vue } from 'vue-property-decorator'
import NoCacheLink from './NoCacheLink.vue'

@Component({
  name: 'ChartContainer',
  components: {
    NoCacheLink
  }
})
export default class extends Vue {
  @Prop() title: string;
  @Prop() amount: number;
  @Prop() unit: string;
  @Prop() link: string;
}

