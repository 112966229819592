var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClickTab },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: "待处理",
                name: "applying",
                permission: ["tax_push_confirm_no_handle"]
              }
            },
            [
              _c("search-filter", {
                attrs: {
                  options: _vm.queryFilterOptions,
                  "type-default": "input"
                },
                on: { search: _vm.handleFilter },
                model: {
                  value: _vm.applyingListQuery,
                  callback: function($$v) {
                    _vm.applyingListQuery = $$v
                  },
                  expression: "applyingListQuery"
                }
              }),
              _c(
                "section",
                [
                  _c("bsm-table", {
                    attrs: {
                      name: "invoice-apply-confirm-table",
                      data: _vm.list,
                      options: _vm.unProcessedTableOptions,
                      "list-loading": _vm.listLoading,
                      "show-index": true
                    },
                    on: { "button-action": _vm.handleClick }
                  })
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0"
                  }
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.applyingListQuery.page.index,
                  limit: _vm.applyingListQuery.page.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.applyingListQuery.page, "index", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.applyingListQuery.page, "limit", $event)
                  },
                  pagination: _vm.getApplyList
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: "已处理",
                name: "handled",
                permission: ["tax_push_confirm_handled"]
              }
            },
            [
              _c("search-filter", {
                attrs: {
                  options: _vm.queryFilterOptions,
                  "type-default": "input"
                },
                on: { search: _vm.handleFilter },
                model: {
                  value: _vm.handledListQuery,
                  callback: function($$v) {
                    _vm.handledListQuery = $$v
                  },
                  expression: "handledListQuery"
                }
              }),
              _c(
                "section",
                [
                  _c("bsm-table", {
                    attrs: {
                      name: "invoice-apply-handled-table",
                      data: _vm.list,
                      options: _vm.hasProcessedTableOptions,
                      "list-loading": _vm.listLoading,
                      "show-index": true
                    },
                    on: { "button-action": _vm.handleClick }
                  })
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0"
                  }
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.handledListQuery.page.index,
                  limit: _vm.handledListQuery.page.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.handledListQuery.page, "index", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.handledListQuery.page, "limit", $event)
                  },
                  pagination: _vm.getHandledList
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "apply-detail-dialog",
          attrs: {
            visible: _vm.applyDetailDialogVisible,
            title: _vm.isApplying() ? "开票申请确认" : "查看详情",
            size: "large"
          },
          on: {
            "update:visible": function($event) {
              _vm.applyDetailDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticClass: "title" }, [
                  _vm._v("网络货运企业：")
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.applyDetail.agent_name))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "16px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("开票申请编号：")
                  ]),
                  _c(
                    "no-cache-link",
                    {
                      staticClass: "text-primary pointer",
                      attrs: {
                        "before-hook": _vm.noCacheLinkBeforeHook,
                        to:
                          "/dataquery/sub_waybill?subWaybillIds=" +
                          _vm.applyDetail.sub_waybill_ids
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.applyDetail.apply_no) + " ")]
                  )
                ],
                1
              ),
              _c("el-col", { attrs: { span: 5, offset: 2 } }, [
                _c("span", { staticClass: "title" }, [_vm._v("发票类型：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.applyDetail.invoice_type_name))
                ])
              ]),
              _c("el-col", { attrs: { span: 5, offset: 2 } }, [
                _c("span", { staticClass: "title" }, [_vm._v("发票种类：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.applyDetail.invoice_category_name))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "16px" } },
            [
              _c("el-col", { attrs: { span: 10 } }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("开票申请时间：")
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm._f("timeFilter")(_vm.applyDetail.create_time))
                  )
                ])
              ]),
              _c(
                "el-col",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { span: 11, offset: 2 }
                },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.applyDetail.invoice_type === 2 ? "子运单" : "运单"
                      ) + "笔数："
                    )
                  ]),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#4F7DF9" },
                      on: { click: _vm.viewWaybill }
                    },
                    [_vm._v(_vm._s(_vm.applyDetail.item_count))]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "32px", color: "#4F7DF9" } },
            [
              _vm._v(" 该承运人本月已在税务局开票"),
              _c(
                "span",
                { staticStyle: { color: "#EE3939", "font-size": "16px" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("amountFormatFilter")(
                        _vm.applyDetail.month_tax_excluding_amount
                      )
                    )
                  )
                ]
              ),
              _vm._v("元"),
              _c(
                "span",
                { staticStyle: { color: "#EE3939", "font-size": "16px" } },
                [_vm._v("【不包含本次申请金额】")]
              ),
              _vm._v("，请确认本次开票金额 ")
            ]
          ),
          _c(
            "table",
            {
              staticClass: "tax-detail-table",
              staticStyle: { "margin-top": "16px" },
              attrs: { cellspacing: "0" }
            },
            [
              _c("tr", [
                _c("th", { attrs: { colspan: "2" } }, [_vm._v(" 开票信息 ")]),
                _c("th", [_vm._v("网络货运企业申请金额")]),
                _c("th", { attrs: { colspan: "2" } }, [
                  _vm._v(" 风控计算金额 ")
                ])
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" 实付运费 ")]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.carrier_paid_amount
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" 价税合计 ")]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("amountFormatFilter")(
                        _vm.applyDetail.tax_included_amount
                      )
                    )
                  )
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.cal_tax_included_amount
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" 不含税金额 ")]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("amountFormatFilter")(
                        _vm.applyDetail.tax_excluding_amount
                      )
                    )
                  )
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.cal_tax_excluding_amount
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" 增值税额 ")]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("amountFormatFilter")(
                        _vm.applyDetail.added_value_tax
                      )
                    )
                  )
                ]),
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(
                          _vm.applyDetail.cal_added_value_tax
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { attrs: { rowspan: "5" } }, [_vm._v(" 其他税种 ")]),
                _c("td", [_vm._v("城市建设维护税")]),
                _c("td", { attrs: { rowspan: "5" } }),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("amountFormatFilter")(
                        _vm.applyDetail.cal_city_build_tax
                      )
                    )
                  )
                ]),
                _c("td", { attrs: { rowspan: "5" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("amountFormatFilter")(_vm.applyDetail.other_tax)
                      ) +
                      " "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("个人所得税")]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("amountFormatFilter")(
                        _vm.applyDetail.cal_individual_tax
                      )
                    )
                  )
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("印花税")]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("amountFormatFilter")(
                        _vm.applyDetail.cal_stamp_tax
                      )
                    )
                  )
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("教育附加税")]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("amountFormatFilter")(
                        _vm.applyDetail.cal_education_tax
                      )
                    )
                  )
                ])
              ]),
              _c("tr", [
                _c("td", [_vm._v("地方教育附加税")]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("amountFormatFilter")(
                        _vm.applyDetail.cal_city_education_tax
                      )
                    )
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" 操作 ")]),
                _c(
                  "td",
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { disabled: !_vm.isApplying(), label: 1 },
                        model: {
                          value: _vm.invoiceAmountSelect,
                          callback: function($$v) {
                            _vm.invoiceAmountSelect = $$v
                          },
                          expression: "invoiceAmountSelect"
                        }
                      },
                      [_vm._v(" 选择该开票金额 ")]
                    )
                  ],
                  1
                ),
                _c(
                  "td",
                  { attrs: { colspan: "2" } },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { disabled: !_vm.isApplying(), label: 2 },
                        model: {
                          value: _vm.invoiceAmountSelect,
                          callback: function($$v) {
                            _vm.invoiceAmountSelect = $$v
                          },
                          expression: "invoiceAmountSelect"
                        }
                      },
                      [_vm._v(" 选择该开票金额 ")]
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "16px" },
              attrs: { type: "warning" },
              on: { click: _vm.viewTaxRate }
            },
            [_vm._v(" 查看税率 ")]
          ),
          _c(
            "span",
            {
              staticClass: "apply-detaildialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.isApplying()
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmit(false)
                        }
                      }
                    },
                    [_vm._v("拒绝")]
                  )
                : _vm._e(),
              _vm.isApplying()
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmit(true)
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }