













import { Vue, Prop, Component } from 'vue-property-decorator'
enum ActionType {
  detail,
  add,
  del,
  edit
}
interface IACtion {
  action: ActionType
  label: string
}

@Component
export default class Actions extends Vue {
@Prop({ default: [] }) actions?: IACtion[]

private handleClick(action) {
  this.$emit('action-click', 'detail')
}
}
