





















































































































































































































import { Vue, Component } from "vue-property-decorator";
import moment from "moment";
import {
  getApplyDetailApi,
  getTaxDataFileApi,
  applyAuditApi,
  downloadApplyFileApi
} from "@/api/tax";
@Component({
  name: "DeclearComfirm",
  components: {}
})
export default class extends Vue {
  tableData: any[] = [];

  rowData: any = {};

  taxNumber = "";
  inEditMode: Boolean = false;
  visible: Boolean = false;
  taxDataFileList = [];
  companyReplenishFileList = [];
  submitloading: Boolean = false;
  mode: String = "declear";
  refuseConfirmVisible: Boolean = false;
  refuseReason: String = "";

  detailTableData: any[] = [];

  rules: any = {
    remark: { required: true, message: "请输入申报说明", trigger: "blur" },
    lister: {
      required: true,
      message: "请输入申报人姓名",
      trigger: "blur"
    }
  };

  tableKeys = [
    { key: "valueAddedTax", label: "增值税(元)", width: 140 },
    { key: "cityMcTax", label: "城市维护建设税(元)", width: 150 },
    { key: "eduSurTax", label: "教育费附加(元)", width: 125 },
    { key: "cityEduSurTax", label: "地方教育附加(元)", width: 140 },
    { key: "stampTax", label: "印花税(元)", width: 100 },
    { key: "orgIncomeTax", label: "企业所得税(元)", width: 125 },
    { key: "idvIncomeTax", label: "个人所得税(元)", width: 140 }
  ];

  filterAmount(amount, scale = 1000) {
    if (amount == null) {
      return "";
    }
    return (Number(amount) / scale).toFixed(2);
  }

  async handleDownload() {
    try {
      const res = await downloadApplyFileApi({
        agentDid: this.rowData.agentDid,
        startMonth: this.rowData.startMonth,
        endMonth: this.rowData.endMonth,
        id: this.rowData.id
      });
      const data: any = [res];
      const filename = `申报明细.zip`;
      const blob = new Blob(data);
      const downloadElement = document.createElement("a");
      const href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = filename;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
    } catch (e) {
      //
    }
  }

  async handleRefuse() {
    this.refuseConfirmVisible = true;
  }

  async handleConfirmRefuse() {
    this.submitloading = true;
    try {
      await applyAuditApi({
        id: this.rowData.id,
        state: 3,
        auditRemark: this.refuseReason
      });
      this.$message.success("驳回成功");
      this.$emit("success");
      this.refuseConfirmVisible = false;
      this.visible = false;
    } catch (e) {
      //
    } finally {
      this.submitloading = false;
    }
  }

  async handleConfirm() {
    this.submitloading = true;
    try {
      await applyAuditApi({
        id: this.rowData.id,
        state: 4
      });
      this.$message.success("审核成功");
      this.$emit("success");
      this.visible = false;
    } catch (e) {
      //
    } finally {
      this.submitloading = false;
    }
  }

  changeEditMode() {
    if (!this.inEditMode) {
      this.detailTableData.forEach((item, idx) => {
        for (const key in item) {
          if (typeof item[key] !== "string" && item[key] !== null) {
            item[key].edit = item[key].value;
          }
        }
      });
      this.inEditMode = !this.inEditMode;
    } else {
      this.detailTableData.forEach((item, idx) => {
        let isValid = true;
        for (const key in item) {
          if (typeof item[key] !== "string" && item[key] !== null) {
            const a = Number(item[key].edit);
            const isNaN = Number.isNaN(a);
            if (isNaN) {
              isValid = false;
            }
          }
        }
        if (isValid) {
          for (const key in item) {
            if (typeof item[key] !== "string" && item[key] !== null) {
              console.log();
              item[key].value = item[key].edit;
              item[key].changed = item[key].value !== item[key].origin;
            }
          }
          this.inEditMode = !this.inEditMode;
        } else {
          this.$message.warning("保存失败");
        }
      });
    }
  }

  async open(row, actionMode) {
    try {
      this.mode = actionMode || "declear";
      const { data } = await getApplyDetailApi({
        id: row.id
      });
      this.rowData = data;
      this.rowData.businessMonth = data.startMonth
        ? `${moment(data.startMonth).format("YYYY-MM")}`
        : "-";
      this.rowData.agentName = row.agentName;

      this.tableData = [
        {
          title: this.rowData.businessMonth,
          number1: this.filterAmount(data.monthBizValue),
          number2: this.filterAmount(data.monthTaxValue),
          number3:
            data.monthDiffRate !== null
              ? this.filterAmount(data.monthDiffRate, 100)
              : "-"
        },
        {
          title: "本年度",
          number1: this.filterAmount(data.yearBizValue),
          number2: this.filterAmount(data.yearTaxValue),
          number3:
            data.yearDiffRate !== null
              ? this.filterAmount(data.yearDiffRate, 100)
              : "-"
        },
        {
          title: "历史累计",
          number1: this.filterAmount(data.totalBizValue),
          number2: this.filterAmount(data.totalTaxValue),
          number3:
            data.totalDiffRate !== null
              ? this.filterAmount(data.totalDiffRate, 100)
              : "-"
        }
      ];

      this.detailTableData = [];
      data.items.forEach(it => {
        const obj = { applyMonth: moment(it.month).format("YYYY-MM") };
        this.tableKeys.forEach(item => {
          obj[item.key] = {
            value: this.filterAmount(it[item.key]),
            origin: this.filterAmount(it[item.key]),
            edit: null,
            changed: false
          };
        });
        this.detailTableData.push(obj);
      });

      this.getTaxFile(data.files);
      this.getCompanyReplenishFile(data.companyFiles);
      this.visible = true;
    } catch (e) {
      //
    }
  }

  async getTaxFile(files) {
    try {
      this.taxDataFileList = [];
      files.forEach(item => {
        const url = item.url;
        const urlArr = url.split("/");
        const name = urlArr[urlArr.length - 1];
        this.taxDataFileList.push({
          name,
          url
        });
      });
    } catch (e) {
      //
    }
  }

  async getCompanyReplenishFile(companyFiles) {
    try {
      this.companyReplenishFileList = [];

      companyFiles.forEach(item => {
        const url = item.url;
        const urlArr = url.split("/");
        const name = urlArr[urlArr.length - 1];
        this.companyReplenishFileList.push({
          name,
          url
        });
      });
    } catch (e) {
      //
    }
  }

  handleGoUrl(url) {
    window.open(url);
  }

  close() {
    this.visible = false;
  }
}
