












































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { IInspectConfigAgentListParams, IInspectConfigAgentData, IInspectStrategyDetailData } from '@/api/types'
import { getInspectConfigAgentList, getInspectConfigList, findInspectStrategyListNoPage, updateInspectConfig, getInspectConfigShipperList, getShipperConfigDetail, updateShipperConfig } from '@/api/inspect'
import { EnumActions } from '@/common/business-components/TypeMap/ActionSetting/index.vue'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { InspectContentTypeMap, InspectConfigStatusMap } from '@/common/constant'
import { getEnumArray } from '@/common/utils'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import { Form } from 'element-ui'



@Component({
  name: 'EnterpriseInspectConfig',
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    MapSearchSelect
  }
})
export default class extends Vue {
  list: IInspectConfigAgentData[] = []
  list2: any = []
  inspectStrategyList: IInspectStrategyDetailData[] = []
  total = 0
  total2 = 0
  listLoading = true
  subLoading = false
  visible = false
  activeName = this.$route.query.tab === '1' ? 'shipper' : 'enterprise'
  listQuery: IInspectConfigAgentListParams = {
    agent_did: '',
    end_time: '',
    settled_type: '',
    start_time: '',
    status: '',
    unsettled_type: '',
    page: {
      index: 1,
      limit: 10
    }
  }

  listQuery2: any = {
    agent_did: '',
    end_time: '',
    settled_type: '',
    start_time: '',
    status: '',
    unsettled_type: '',
    page: {
      index: 1,
      limit: 10
    }
  }

  inspectConfigList: any = []
  inspectConfigStatus = 1
  inspectAgentId = ''

  rules = {
    type: [{
      required: true,
      message: '数据抽检内容必选',
      trigger: 'change'
    }],
    strategyId: [{
      required: true,
      message: '数据抽检策略必选',
      trigger: 'change'
    }]
  }

  actionTypeMap = {
    [EnumActions.add]: {
      title: '新增抽检策略'
    },
    [EnumActions.detail]: {
      title: '抽检策略查看'
    },
    [EnumActions.edit]: {
      title: '抽检策略编辑'
    }
  }

  inspectContentTypeList = getEnumArray(InspectContentTypeMap)

  currentActionType: EnumActions = null

  queryFilterOptions = [
    {
      field: 'agent_did',
      label: '网络货运企业',
      component: MapSearchSelect,
      config: {
        field: 'agent'
      },
      main: true
    }, {
      field: 'status',
      label: '抽检状态',
      type: 'select',
      config: {
        list: getEnumArray(InspectConfigStatusMap)
      }
    }, {
      field: 'settled_type',
      label: '已配置抽检内容',
      type: 'select',
      config: {
        list: getEnumArray(InspectContentTypeMap)
      }
    },
    {
      field: 'unsettled_type',
      label: '未配置抽检内容',
      type: 'select',
      config: {
        list: getEnumArray(InspectContentTypeMap)
      }
    },
    {
      field: ['start_time', 'end_time'],
      label: '起止时间',
      type: 'dateRange'
    }, {
      field: 'strategy_id',
      label: '策略Id'
    }
  ].map(item => {
    return Object.assign({}, item, { main: true })
  })

  queryFilterOptions2 = [
    {
      field: 'consignor_did',
      label: '托运人',
      component: MapSearchSelect,
      config: {
        field: 'consignor'
      },
      main: true
    }, {
      field: 'status',
      label: '抽检状态',
      type: 'select',
      config: {
        list: getEnumArray(InspectConfigStatusMap)
      }
    }, {
      field: 'settled_type',
      label: '已配置抽检内容',
      type: 'select',
      config: {
        list: getEnumArray(InspectContentTypeMap)
      }
    },
    {
      field: 'unsettled_type',
      label: '未配置抽检内容',
      type: 'select',
      config: {
        list: getEnumArray(InspectContentTypeMap)
      }
    },
    {
      field: ['start_time', 'end_time'],
      label: '起止时间',
      type: 'dateRange'
    }, {
      field: 'strategy_id',
      label: '策略Id'
    }
  ].map(item => {
    return Object.assign({}, item, { main: true })
  })

  tableListOptions = [{
    field: 'agentDid',
    type: 'linkLike',
    label: '操作',
    config: {
      label() {
        return '抽检配置'
      },
      handle: (val, row) => {
        this.handleOpenConfig(val)
      }
    },
    fixed: true
  }, {
    field: 'agentName',
    label: '网络货运企业'
  }, {
    field: 'legalIdentityNo',
    label: '统一社会信用代码',
    type: 'link',
    config: {
      url(val, row) {
        return `/dataquery/agent?agent=${row.agentDid}`
      },
      noCache: true
    }
  }, {
    field: 'entryTime',
    label: '接入时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'status',
    label: '抽检状态',
    filter(val) {
      return InspectConfigStatusMap[val]
    }
  }, {
    field: 'totalCnt',
    label: '数据抽检笔数',
    type: 'link',
    config: {
      url(val, row) {
        return `/regulation/inspect-task?sample_did=${row.agentDid}&sample_subject_type=2`
      },
      noCache: true
    }
  }, {
    field: 'passedCnt',
    label: '通过笔数'
  }, {
    field: 'rejectedCnt',
    label: '拒绝笔数'
  }, {
    field: 'sendbackCnt',
    label: '退回笔数'
  }, {
    field: 'pendingCnt',
    label: '待处理笔数'
  }, {
    field: 'waitConfirmCnt',
    label: '待审批笔数'
  }]

  tableListOptions2 = [{
    field: 'consignorDid',
    type: 'linkLike',
    label: '操作',
    config: {
      label() {
        return '抽检配置'
      },
      handle: (val) => {
        this.handleOpenConfig(val)
      }
    },
    fixed: true
  }, {
    field: 'consignorName',
    label: '托运人'
  }, {
    field: 'identityNo',
    label: '统一社会信用代码'
    // type: 'link',
    // config: {
    //   url(val, row) {
    //     return `/dataquery/agent?agent=${row.agentDid}`
    //   },
    //   noCache: true
    // }
  }, {
    field: 'entryTime',
    label: '接入时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'status',
    label: '抽检状态',
    filter(val) {
      return InspectConfigStatusMap[val]
    }
  }, {
    field: 'totalCnt',
    label: '数据抽检笔数',
    type: 'link',
    config: {
      url(val, row) {
        return `/regulation/inspect-task?sample_did=${row.consignorDid}&sample_subject_type=1`
      },
      noCache: true
    }
  }, {
    field: 'passedCnt',
    label: '通过笔数'
  }, {
    field: 'rejectedCnt',
    label: '拒绝笔数'
  }, {
    field: 'sendbackCnt',
    label: '退回笔数'
  }, {
    field: 'pendingCnt',
    label: '待处理笔数'
  }, {
    field: 'waitConfirmCnt',
    label: '待审批笔数'
  }]

  created() {
    this.initListQuery()
    this.$route.query.tab === '1' ? this.getList2() : this.getList()
    this.getInspectStrategyList()
  }

  // activated() {
  //   this.getInspectStrategyList()
  // }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.activeName === 'enterprise' ? this.listQuery = Object.assign({}, this.listQuery, this.$route.query) : this.listQuery2 = Object.assign({}, this.listQuery2, this.$route.query)
    }
  }

  getFrequencyType(type, data) {
    const map = {
      1: '日',
      2: '周',
      3: '月'
    }
    if (type === 4) {
      return data.days
    } else {
      return map[type]
    }
  }

  get dialogTitle() {
    return this.actionTypeMap[this.currentActionType]?.title || ''
  }

  getInspectStrategyListByType(type) {
    if (type) {
      return this.inspectStrategyList.filter(item => item.type === type)
    } else {
      return this.inspectStrategyList
    }
  }

  handleClickTab() {
    this.activeName === 'enterprise' ? this.handleFilter() : this.handleFilter2()
  }

  async getList() {
    try {
      this.listLoading = true
      const { data } = await getInspectConfigAgentList(this.listQuery)
      this.list = data.items
      this.total = data.total
      // await this.$nextTick()
    } catch (err) {

    } finally {
      this.listLoading = false
    }
  }

  async getList2() {
    await this.$nextTick()
    try {
      this.listLoading = true
      const { data } = await getInspectConfigShipperList(this.listQuery2)
      this.list2 = data.items
      this.total2 = data.total
    } catch (err) {

    } finally {
      this.listLoading = false
    }
  }

  async getInspectStrategyList() {
    const { data } = await findInspectStrategyListNoPage()
    this.inspectStrategyList = data as any || []
  }


  handleFilter() {
    this.listQuery.page.index = 1
    this.listQuery.page.limit = 10
    this.getList()
  }

  handleFilter2() {
    this.listQuery2.page.index = 1
    this.listQuery2.page.limit = 10
    this.getList2()
  }

  handleAddConfig() {
    const cfg:any = {
      id: '',
      strategyId: '',
      ref: `${Date.now()}`,
      type: ''
    }
    Object.defineProperty(cfg, 'percentage', {
      get: () => {
        const strategy = this.inspectStrategyList.find(isl => isl.id === cfg.strategyId)
        if (strategy?.percentage) {
          return `${strategy?.percentage}%`
        } else {
          return ''
        }
      }
    })
    Object.defineProperty(cfg, 'max_count', {
      get: () => {
        const strategy = this.inspectStrategyList.find(isl => isl.id === cfg.strategyId)
        return strategy?.max_count || ''
      }
    })
    Object.defineProperty(cfg, 'frequency_type', {
      get: () => {
        const strategy = this.inspectStrategyList.find(isl => isl.id === cfg.strategyId)
        if (strategy) {
          return this.getFrequencyType(strategy.frequency_type, strategy)
        } else {
          return ''
        }
      }
    })
    this.inspectConfigList.push(cfg)
  }

  handleRemoveConfig(item) {
    const index = this.inspectConfigList.findIndex(cfg => cfg.id === item.id)
    if (item.id) {
      this.inspectConfigList.splice(index, 1, Object.assign({}, item, { deleted: 1 }))
    } else {
      this.inspectConfigList.splice(index, 1)
    }
  }

  async getConfigDetail(id: string) {
    this.inspectAgentId = id
    const { data } = this.activeName === 'enterprise' ? await getInspectConfigList({ id }) : await getShipperConfigDetail({ id })
    const list = this.activeName === 'enterprise' ? data.sampleInspectCfgList : data.cfgListView
    this.inspectConfigList = list.map((cfg) => {
      Object.defineProperty(cfg, 'percentage', {
        get: () => {
          const strategy = this.inspectStrategyList.find(isl => isl.id === cfg.strategyId)
          if (strategy?.percentage) {
            return `${strategy?.percentage}%`
          } else {
            return ''
          }
        }
      })
      Object.defineProperty(cfg, 'max_count', {
        get: () => {
          const strategy = this.inspectStrategyList.find(isl => isl.id === cfg.strategyId)
          return strategy?.max_count || ''
        }
      })
      Object.defineProperty(cfg, 'frequency_type', {
        get: () => {
          const strategy = this.inspectStrategyList.find(isl => isl.id === cfg.strategyId)
          if (strategy) {
            return this.getFrequencyType(strategy.frequency_type, strategy)
          } else {
            return ''
          }
        }
      })
      const strategy = this.inspectStrategyList.find(isl => isl.id === cfg.strategyId)
      Vue.set(cfg, 'type', strategy?.type || '')
      Vue.set(cfg, 'ref', cfg.id)
      return cfg
    })

    this.inspectConfigStatus = data.status
  }

  handleOpenConfig(val) {
    this.getConfigDetail(val)
    this.visible = true
  }

  handleCloseConfig() {
    this.inspectConfigList = []
  }

  handleTypeChange(cfg, val) {
    const strategy = this.inspectStrategyList.find(isl => isl.id === cfg.strategyId)
    if (!strategy || strategy.type !== val) {
      cfg.strategyId = ''
    }
  }

  handleStrategyChange(cfg) {
    if (cfg.strategyId) {
      const strategy = this.inspectStrategyList.find(isl => isl.id === cfg.strategyId)
      if (strategy) {
        cfg.type = strategy.type
      }
    }
  }

  async handleUpdateConfig() {
    const validList = await Promise.all(this.inspectConfigList
      .filter(cfg => !(cfg as any).deleted)
      .map((cfg) => {
        // debugger
        const form = (this.$refs[(cfg as any).ref])[0]
        return (form as Form).validate()
      }))
    if (!validList.find(valid => !valid)) {
      const params = {
        agent_did: this.inspectAgentId,
        consignor_did: this.inspectAgentId,
        sample_inspect_cfg_list: this.inspectConfigList.map(cfg => {
          return {
            id: cfg.id,
            strategyId: (cfg as any).strategyId,
            deleted: (cfg as any).deleted || 0
          }
        }),
        status: this.inspectConfigStatus
      }
      try {
        this.subLoading = true
        const { msg, code } = this.activeName === 'enterprise' ? await updateInspectConfig(params) : await updateShipperConfig(params)
        if (code === 0) {
          this.$message.success('配置修改成功')
          this.visible = false
        } else {
          this.$message.error(msg)
          this.visible = false
        }
        this.activeName === 'enterprise' ? this.getList() : this.getList2()
        this.subLoading = false
      } catch (err) {
        this.$message.error(err)
        // this.visible = false
        this.subLoading = false
      }
    }
  }
}

