









import { Vue, Component } from 'vue-property-decorator'
import { quickBiApi } from '@/api/overview'

@Component
export default class extends Vue {
  private src = ''

  private async getSrc() {
    const { data } = await quickBiApi()
    this.src = data.url || ''
  }

  private init() {
    this.getSrc()
  }

  created() {
    this.init()
  }
}

