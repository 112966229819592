var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          style: { width: _vm.width + "px" },
          attrs: { placeholder: "请选择", clearable: "" }
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.renderList, function(item, index) {
      return _c("el-option", {
        key: index,
        attrs: { value: item.value, label: item.label }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }