


















































































































































































































import { Component, Vue } from "vue-property-decorator";
import { IRiskRule } from "@/api/types";
import {
  getRiskRulesList,
  swithRuleStatus,
  deleteRule,
  getActiveRiskRuleSchemeList,
  recallBatchRerun,
  getRiskRulesListByKey,
  brushSheetApi,
  viewImplementationApi
} from "@/api/riskrules";
import { EnumActions } from "@/common/business-components/TypeMap/ActionSetting/index.vue";
import Pagination from "@/common/components/Pagination/index.vue";
import SearchFilter from "@/common/business-components/SearchFilter/index.vue";
import MapSelect from "@/common/business-components/TypeMap/map/MapSelect.vue";
import BsmTable from "@/views/components/BsmTable.vue";
import { RiskRuleKind } from "@/common/constant";
import AreaList from "@/common/components/AreaList/index.vue";
import UploadExcel from "@/common/components/UploadExcel/index.vue";
import ProgressDetailModal from "./ProgressDetailModal.vue";

const ifAppeal = val => {
  return val ? "是" : "否";
};
export const typeMap = {
  1: "关键策略",
  2: "中等策略",
  3: "一般策略"
};

const typeFilter = (type: number) => {
  return typeMap[type] || type;
};

@Component({
  name: "RiskRulesManagement",
  components: {
    Pagination,
    AreaList,
    SearchFilter,
    BsmTable,
    MapSelect,
    UploadExcel,
    ProgressDetailModal
  }
})
export default class extends Vue {
  list: IRiskRule[] = [];
  total = 0;
  listLoading = true;
  showDialog = false;
  isBatchRetry = false;
  showDialogRetry = false;
  unUploadState = true;
  loading = false;
  options = [];
  formRetry = {
    name: "",
    ruleIds: [],
    activeName: "first",
    waybillNo: "",
    type: 1
  };

  schemeNoEnum: any = [];

  retryFormData = null;
  searchTopForm: any = {
    scheme_no: "",
    district_code: ""
  };

  listQuery = {
    id: "",
    name: "",
    // effective: null,
    type: "",
    category: null,
    page: {
      index: 1,
      limit: 10
    },
    source: {
      data: "",
      type: 1
    },
    status: true
  };

  queryFilterOptions = [
    {
      field: "name",
      label: "规则名称"
    },
    {
      field: "id",
      label: "规则编号"
    },
    {
      field: "type",
      label: "规则类型",
      type: "select",
      config: {
        list: Object.keys(typeMap).map(key => {
          return {
            value: key,
            label: typeMap[key]
          };
        })
      }
    },
    {
      field: "category",
      label: "规则类别",
      type: "select",
      config: {
        list: Object.keys(RiskRuleKind).map(key => {
          return {
            value: key,
            label: RiskRuleKind[key]
          };
        })
      }
    },
    {
      field: "business_mode",
      label: "业务类型",
      type: "select",
      config: {
        list: [
          {
            value: 1,
            label: "自营"
          },
          {
            value: 2,
            label: "撮合"
          }
        ]
      }
    },
    {
      field: "status",
      label: "状态",
      type: "select",
      config: {
        list: [
          {
            value: true,
            label: "启用"
          },
          {
            value: false,
            label: "禁用"
          }
        ]
      },
      props: {
        clearable: true
      }
    }
  ].map(item => Object.assign({}, item, { main: true }));

  tableListOptions = [
    {
      field: "id",
      type: "actionSetting",
      attrs: {
        actionOptions: [
          {
            type: EnumActions.edit,
            label: "编辑"
          },
          {
            type: "progress",
            label: "查看实施情况"
          }
        ]
      },
      label: "操作",
      width: 50,
      align: "center",
      fixed: true
    },
    {
      field: "id",
      label: "规则编号",
      width: 150,
      fixed: true
    },
    {
      field: "name",
      label: "规则名称"
    },
    {
      field: "status",
      label: "状态",
      filter: (v, row) => {
        return v ? "启用" : ' <span class="text-danger">禁用</span>';
      }
    },
    {
      field: "type",
      label: "规则类型",
      filter: typeFilter
    },
    {
      field: "category",
      label: "规则类别",
      filter: (category: number, row) => {
        return RiskRuleKind[category] || category;
      }
    },
    {
      field: "business_mode",
      label: "业务类型",
      filter: (v: number, row) => {
        return v === 1 ? "自营" : v === 2 ? "撮合" : "";
      }
    },
    // {
    //   field: 'category',
    //   label: '风控方案',
    //   filter: (v, row) => {
    //     return RiskRuleKind[v]
    //   }
    // }, {
    //   field: 'category',
    //   label: '适用区域',
    //   filter: (v, row) => {
    //     return RiskRuleKind[v]
    //   }
    // },
    {
      field: "white_list_count",
      label: "白名单数量",
      type: "linkLike",
      config: {
        label(val, row) {
          return val;
        },
        handle: (v, row) => {
          this.handelActionJump(row);
        }
      }
    },
    {
      field: "risk_score",
      label: "风险分值",
      filter: "riskScore"
    },
    {
      field: "exception_type_alias",
      label: "异常类型"
    },
    {
      field: "allow_report",
      label: "是否允许上报",
      align: "center",
      filter: ifAppeal
    },
    {
      field: "if_appeal",
      label: "是否需要申诉",
      align: "center",
      filter: ifAppeal
    },
    {
      field: "if_cross_park",
      label: "是否跨产业园",
      align: "center",
      filter: ifAppeal
    },
    {
      field: "create_time",
      label: "规则创建时间",
      filter: "timeFilter",
      align: "center"
    }
  ];

  created() {
    this.initListQuery();
    this.getRuleSchemeList();
    this.getList();
    this.retryFormData = new FormData();
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query);
      this.searchTopForm = Object.assign(
        {},
        this.searchTopForm,
        this.$route.query
      );
    }
  }

  async getRuleSchemeList() {
    const { data } = await getActiveRiskRuleSchemeList({
      page: {
        index: 1,
        limit: 10
      }
    });
    data.items.forEach(item => {
      this.schemeNoEnum.push({
        value: item.scheme_no,
        label: item.scheme_name
      });
    });
  }

  handleAgentRegsiterDistrictChange(address: any) {
    this.searchTopForm.city = address.city;
    this.searchTopForm.district = address.district;
    this.searchTopForm.province = address.province;
  }

  async remoteMethod(query) {
    if (query !== "") {
      this.loading = true;
      const { data } = await getRiskRulesListByKey({
        keyword: query,
        size: 5
      });
      this.loading = false;
      this.options = data.map(item => {
        return { value: `${item.id}`, label: `${item.name}` };
      });
    } else {
      this.options = [];
    }
  }

  async handleSubmitRetry() {
    // if (!this.formRetry.ruleIds.length) {
    //   return this.$message.error('请填写规则')
    // }
    if (!this.formRetry.waybillNo && this.formRetry.activeName === "first") {
      return this.$message.error("请填写运单编号");
    }
    if (
      !this.retryFormData.get("file") &&
      this.formRetry.activeName === "second"
    ) {
      return this.$message.error("请上传excel");
    }
    const sheetRequest = JSON.stringify({
      ruleIds: this.formRetry.ruleIds,
      waybillNo: this.formRetry.waybillNo.split("\n"),
      type: this.formRetry.type
    });

    const blob = new Blob([sheetRequest], {
      type: "application/json"
    });
    this.retryFormData.append("sheetRequest", blob);

    const res = await brushSheetApi(this.retryFormData);
    this.showDialogRetry = false;
    this.$message.success(`已成功提交${res.data}条运单`);
  }

  handelActionJump(row) {
    this.$router.push({
      path: "/regulation/white-list",
      query: {
        rule_name: row.name
      }
    });
  }

  jumpTemplate() {
    this.$router.push({
      path: "/risk_rules/risk-manage/list"
    });
  }

  async getList() {
    this.listLoading = true;

    const queryParams = Object.assign({}, this.listQuery, this.searchTopForm);
    queryParams.district_code = this.searchTopForm.district
      ? this.searchTopForm.district
      : this.searchTopForm.city
      ? this.searchTopForm.city
      : this.searchTopForm.province
      ? this.searchTopForm.province
      : "";
    const { data } = await getRiskRulesList(queryParams);
    this.list = data.items;
    this.total = data.total;
    await this.$nextTick();
    this.listLoading = false;
  }

  handleFilter() {
    this.listQuery.page.index = 1;
    this.listQuery.page.limit = 10;
    this.getList();
  }

  handelActionDetail(id: string) {
    this.$router.push({
      path: `/risk_rules/detail/${id}`
    });
  }

  async handleActionSwitch(command, id) {
    const text = command === EnumActions.enable ? "启用" : "停用";
    const res: any = await swithRuleStatus(id);
    if (res.code === 0) {
      this.$message.success(`${text}成功`);
      this.getList();
    } else {
      this.$message.error(`${text}失败`);
    }
  }

  handleAdd() {
    // something to do
    this.handelActionDetail("create");
  }

  handleEdit(id: string) {
    // something todo
    this.handelActionDetail(id);
  }

  handleDelete(id: string) {
    try {
      this.$confirm("确认删除该规则？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        deleteRule({ id: id }).then(res => {
          this.$message.success("删除风险规则成功");
          this.getList();
        });
      });
    } catch (err) {
      this.$message.info("取消删除");
    }
  }

  handleActionCommand(command, id) {
    if (command === EnumActions.add) {
      this.handleAdd();
    } else if (command === EnumActions.delete) {
      this.handleDelete(id);
    } else if (command === EnumActions.edit) {
      this.handleEdit(id);
    } else if (command === "progress") {
      this.handleProgressStatus(id);
    } else {
      this.handleActionSwitch(command, id);
    }
  }

  handleOpenRetry() {
    this.showDialogRetry = true;
    this.retryFormData = new FormData();
    this.unUploadState = true;
    this.formRetry = {
      name: "",
      ruleIds: [],
      activeName: "first",
      waybillNo: "",
      type: 1
    };
  }

  handleFileRetryChange(file) {
    this.retryFormData.append("file", file);
    this.formRetry.name = file.name;
    this.unUploadState = false;
  }

  handleFileChange(file) {
    const data = new FormData();
    data.append("file", file);
    this.showDialog = false;
    // if (this.isBatchRetry) {
    //   antiFraudBatchRerun(data)
    // } else {
    recallBatchRerun(data);
    // }
  }

  async handleProgressStatus(id) {
    try {
      const { data } = await viewImplementationApi({
        rule_id: id
      });
      (this.$refs.progressDetailModal as any).open({
        ...data
      });
    } catch (e) {
      //
    }
  }
}
