
























import { Vue, Component, Prop } from 'vue-property-decorator'
import TableList from '../TableList/index.vue'
import { IWaybillGoodsStatisticsDTO } from '../index.d'

const waybillGoodsInfoDefault: IWaybillGoodsStatisticsDTO = {
  goodsDtoList: [{
    createTime: 0,
    goodsName: '',
    goodsType: '',
    goodsWorth: 0,
    id: '',
    quantity: 0,
    subWaybillId: '',
    updateTime: 0,
    volume: 0,
    weight: 0
  }],
  totalQuantity: 0,
  totalVolume: 0,
  totalWeight: 0,
  totalWorth: 0
}

@Component({
  components: {
    TableList
  }
})
export default class extends Vue {
  @Prop({ default: () => waybillGoodsInfoDefault }) waybillGoodsInfo ? : IWaybillGoodsStatisticsDTO
  goodsDtoListOptions = [{
    field: 'goodsName',
    label: '货物名称'
  },
  {
    field: 'goodsTypeAlias',
    label: '货物类型'
  },
  {
    field: 'quantity',
    label: '件数'
  },
  {
    field: 'weight',
    label: '重量(千克)',
    filter: 'weight'
  },
  {
    field: 'volume',
    label: '体积(方)',
    filter: 'volume'
  },
  {
    field: 'goodsWorth',
    label: '货值(元)',
    filter: 'amountFormatFilter'
  }
  ]
}
