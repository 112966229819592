
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'UploadImage'
})
export default class extends Vue {
  @Prop({ default: '' }) private value!: string
  @Prop({ default: 'multi' }) private mode: string
  @Prop({ default: 'picture-card' }) private listType:string
  private fileList = []

  private emitInput() {
    this.$emit('input', this.fileList)
  }

  private handleFileChange(res) {
    this.fileList.push(res)
    this.emitInput()
  }

  private handleFileRemove(res) {
    this.fileList = this.fileList.filter(item => item.uid !== res.uid)
    this.emitInput()
  }
  // public clearFiles(){
  //   this.$refs.upload.clearFiles()
  // }
}

