



import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { timeFilterShowSecond } from "../../filters";
// import { loadAMap } from './loadMap'
const win = window as any;
const AMap = win.AMap;
const AMapUI = win.AMapUI;

const markerStyles = [
  "green",
  "darkblue",
  "orange",
  "orchid",
  "gray",
  "yellow",
];

export interface IPath {
  name: string;
  path: any[][];
  color: string;
  visible: boolean;
}

export interface IPoint {
  label: string;
  points: any[][];
}

// function getDistance(lat1, lng1, lat2, lng2) {
//   var radLat1 = lat1 * Math.PI / 180.0
//   var radLat2 = lat2 * Math.PI / 180.0
//   var a = radLat1 - radLat2
//   var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0
//   var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
//     Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)))
//   s = s * 6378.137 // EARTH_RADIUS;
//   s = Math.round(s * 10000) / 10000
//   return s
// }

@Component
export default class KhyMap extends Vue {
  @Prop({
    default() {
      return [];
    },
  })
  paths: IPath[];

  @Prop({
    default() {
      return [];
    },
  })
  points: IPoint[];

  @Prop({
    default() {
      return [];
    },
  })
  districts: any[];

  container = "container-" + Date.now(); // `container-${Date.now()}`

  map: any = "";
  pathSimplifierIns: any = "";
  navgs = [];
  markers = [];
  SimpleMarker: any = "";
  simpleMarker: any = "";
  SimpleInfoWindow: any = "";
  DistrictSearch: any = "";
  winInfo: any = "";

  async init() {
    this.map = new AMap.Map(this.container, {
      center: [117.000923, 36.675807],
      zoom: 11,
    });
    if (process.env.VUE_APP_AMP_STYLE_KEY) {
      this.map.setMapStyle(
        "amap://styles/" + process.env.VUE_APP_AMP_STYLE_KEY
      );
    }
    this.map.addControl(
      new AMap.Scale({
        visible: true,
      })
    );

    await Promise.all([this.initPath(), this.initPoint(), this.initDistrict()]);
    this.setData();
  }

  initPath() {
    return new Promise((resolve) => {
      AMapUI.load(["ui/misc/PathSimplifier", "lib/$"], (PathSimplifier, $) => {
        if (!PathSimplifier.supportCanvas) {
          this.$message.error("当前环境不支持 Canvas！");
        }
        this.pathSimplifierIns = new PathSimplifier({
          zIndex: 100,
          map: this.map,
          getPath: function(pathData, pathIndex) {
            return pathData.path;
          },
          getHoverTitle: function(pathData, pathIndex, pointIndex) {
            if (pointIndex >= 0) {
              // point
              const item = pathData.path[pointIndex];
              if (item[2] !== -1) {
                return `${pathData.name}, 位置：${item[0]}, ${
                  item[1]
                }, 时间: ${timeFilterShowSecond(item[2])}`;
              }
            }

            // return pathData.name + '，点数量' + pathData.path.length
          },
          renderOptions: {
            renderAllPointsIfNumberBelow: 100, // 绘制路线节点，如不需要可设置为-1
            pathLineStyle: {
              dirArrowStyle: true,
            },
            getPathStyle: function(pathItem, zoom) {
              console.log(pathItem, 77777);
              var color = pathItem.pathData.color;
              var lineWidth = Math.round(4 * Math.pow(1.1, zoom - 3));

              return {
                pathLineStyle: {
                  strokeStyle: color,
                  lineWidth: lineWidth,
                },
                pathLineSelectedStyle: {
                  lineWidth: lineWidth + 2,
                },
                pathNavigatorStyle: {
                  // fillStyle: color
                  strokeStyle: "green",
                },
              };
            },
          },
        });
        resolve(this.pathSimplifierIns);
      });
    });
  }

  initPoint() {
    return new Promise((resolve) => {
      AMapUI.loadUI(
        ["overlay/SimpleMarker", "overlay/SimpleInfoWindow"],
        (SimpleMarker, SimpleInfoWindow) => {
          this.SimpleMarker = SimpleMarker;
          this.SimpleInfoWindow = SimpleInfoWindow;
          resolve(this.SimpleMarker);
        }
      );
    });
  }

  initDistrict() {
    return new Promise((resolve) => {
      AMap.plugin("AMap.DistrictSearch", () => {
        this.DistrictSearch = new AMap.DistrictSearch({
          // 返回行政区边界坐标等具体信息
          extensions: "all",
          // 设置查询行政区级别为 区
          level: "district",
        });
        resolve(this.DistrictSearch);
      });
    });
  }

  setData() {
    this.setPaths();
    this.setMarkers();
    this.setDistrict();
  }

  startNavg() {
    console.log(this.paths, 22222);
    for (let i = 0; i < this.paths.length; i++) {
      const path = this.paths[i].path;
      if (path.length > 0) {
        const startPoint = path[0];
        const endPoint = path[path.length - 1];
        this.setStartPoint(startPoint, i, this.paths[i].name);
        this.setEndPoint(endPoint, i, this.paths[i].name);
        const dis = AMap.GeometryUtil.distanceOfLine(path);
        const v = (Math.round(dis * 10000) / 10000 / 10) * 60 * 60; // m/h
        // const speed = getDistance(startPoint[1], startPoint[0], endPoint[1], endPoint[0]) / 5 * 60 * 60 * 1.3
        const navg = this.pathSimplifierIns.createPathNavigator(i, {
          loop: true,
          speed: v / 1000, // km/h
        });
        this.navgs.push(navg);
        navg.start();
      }
    }
  }

  showWinInfow(data, marker) {
    const infoTitle = `<strong>${data.title}</strong>`;
    const infoBody = `<p style="line-height:1.5;">${data.body}</p>`;

    if (this.winInfo) {
      this.winInfo.setInfoTitle(infoTitle);
      this.winInfo.setInfoBody(infoBody);
    } else {
      this.winInfo = new this.SimpleInfoWindow({
        infoTitle,
        infoBody,
        offset: new AMap.Pixel(0, -31),
      });
    }
    this.winInfo.open(this.map, marker.getPosition());
  }

  setPaths() {
    if (this.pathSimplifierIns) {
      this.pathSimplifierIns.setData(this.paths);
      this.startNavg();
    }
  }

  setMarkers() {
    if (this.SimpleMarker) {
      this.points.forEach((item, index) => {
        item.points.forEach((point) => {
          this.setMarker(
            point,
            item.label,
            markerStyles[index],
            "fresh",
            item.label
          );
        });
      });
    }
  }

  setMarker(
    position,
    label,
    iconStyle,
    iconTheme,
    title: string,
    zIndex = 999
  ) {
    iconStyle = iconStyle || "red";
    iconTheme = iconTheme || "numv1";
    const SimpleMarker = this.SimpleMarker;
    const iconLabel =
      typeof label === "string"
        ? {
            innerHTML: `<div>${label}</div>`,
            style: {
              color: "#fffa",
              fontSize: "12px",
              // position: 'relative'
            },
          }
        : label;
    let boydHtml = `位置：${position[0]}, ${
      position[1]
    }<br> 时间：${timeFilterShowSecond(position[2])}`;
    if (title.indexOf("轨迹") > -1) {
      boydHtml = `位置：${position[0]}, ${
        position[1]
      }<br> 时间：${timeFilterShowSecond(position[2])}<br> 设备编号：${
        position[3]
      }`;
    }
    if (title.indexOf("装货") > -1 || title.indexOf("卸货") > -1) {
      boydHtml = `设备编号：${position[3]}<br> 设备类型：${
        position[4]
      }<br> 位置：${position[0]}, ${
        position[1]
      }<br> 时间：${timeFilterShowSecond(position[2])}`;
    }
    const marker = new SimpleMarker({
      data: {
        title: title,
        body: boydHtml,
      },
      iconLabel,
      iconTheme,
      iconStyle,
      map: this.map,
      position,
      containerClassNames: "point_label",
      zIndex,
    });
    this.markers.push(marker);
    marker.on("click", (ev) => {
      const data = ev.target ? ev.target.opts.data : ev.target.data;
      this.showWinInfow(data, marker);
    });
  }

  setDistrict() {
    if (!this.DistrictSearch) {
      return;
    }

    this.districts.forEach((districtInfo, index) => {
      this.renderDistrict(districtInfo);
    });
  }

  renderDistrict(districtInfo: any) {
    this.DistrictSearch.search(districtInfo.code, (status, result) => {
      var district = result.districtList[0];
      var bounds = district.boundaries;

      var name = district.name;
      var polygons = [];

      var infoWindow = new AMap.InfoWindow({
        isCustom: true, // 使用自定义窗体
        content: `
        <div style="width:140px;height:50px;background-color:rgba(255,255,255,0.7);border:1px solid #1989fa;padding:10px;">
        <h4 style="display:block;font-size:14px;margin:0;">${name}</h4>
        <span style="display:block;font-size:14px;">时间：${districtInfo.description}</span>
        </div>
        `, // 传入 dom 对象，或者 html 字符串
      });

      var position = new AMap.LngLat(district.center.lng, district.center.lat);

      if (bounds) {
        for (var i = 0, l = bounds.length; i < l; i++) {
          // 生成行政区划polygon
          var polygon = new AMap.Polygon({
            map: this.map,
            strokeWeight: 2,
            path: bounds[i],
            fillOpacity: 0.1,
            fillColor: "#1989fa",
            strokeColor: "#1989fa",
            zIndex: 1,
            bubble: true,
          });
          polygon.on("click", () => {
            if (infoWindow.getIsOpen()) {
              infoWindow.close();
            } else {
              infoWindow.open(this.map, position);
            }
          });
          polygons.push(polygon);
        }
        // 地图自适应
        this.map.setFitView();
      }
    });
  }

  setStartPoint(position, index, name) {
    this.setMarker(
      position,
      {
        innerHTML: "<div>始</div>",
        style: {
          color: "#fffa",
          fontSize: "12px",
          marginTop: "-5px",
        },
      },
      "blue",
      null,
      name + "轨迹起点",
      1000
    );
  }

  setEndPoint(postion, index, name) {
    this.setMarker(
      postion,
      {
        innerHTML: "<div>终</div>",
        style: {
          color: "#fffa",
          fontSize: "12px",
          marginTop: "-5px",
        },
      },
      null,
      null,
      name + "轨迹终点",
      1000
    );
  }

  @Watch("paths", { deep: true })
  onPathsChange(now, old) {
    if (now !== old) {
      this.setPaths();
    }
  }

  @Watch("points", { deep: true })
  onPointsChange(now, old) {
    if (now !== old) {
    }
    this.setMarkers();
  }

  @Watch("districts", { deep: true })
  onDistrictsChange(now, old) {
    console.log(now);
    return now;

    // this.setMarkers()
  }

  mounted() {
    this.init();
  }

  beforeDestroy() {
    while (this.navgs && this.navgs.length > 0) {
      const navg = this.navgs.shift();
      navg.destroy();
    }

    this.pathSimplifierIns.destroy();
    this.map.destroy();
    this.winInfo = null;
    this.markers = null;
    this.pathSimplifierIns = null;
    this.SimpleMarker = null;
    this.DistrictSearch = null;
    this.map = null;
  }
}
