




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { addClass, removeClass } from '../../utils'

@Component({
  name: 'WaybillRightPanel'
})
export default class extends Vue {
  @Prop({ default: false }) clickNotClose!: boolean
  @Prop({ default: 158 }) buttonTop!: number

  show = false

  @Watch('show')
  onShowChange(value: boolean) {
    if (value && !this.clickNotClose) {
      this.addEventClick()
    }
    if (value) {
      addClass(document.body, 'showRightPanel')
    } else {
      removeClass(document.body, 'showRightPanel')
    }
  }

  mounted() {
    this.insertToBody()
  }

  beforeDestroy() {
    const elx = this.$refs.waybillRightPanel as Element
    elx.remove()
  }

  addEventClick() {
    window.addEventListener('click', this.closeSidebar)
  }

  closeSidebar(ev: MouseEvent) {
    const parent = (ev.target as HTMLElement).closest('.waybillRightPanel')
    const parentPop = (ev.target as HTMLElement).closest('.waybill-log-dialog')
    if (!parent && !parentPop) {
      this.show = false
      window.removeEventListener('click', this.closeSidebar)
    }
  }

  insertToBody() {
    const elx = this.$refs.waybillRightPanel as Element
    const body = document.querySelector('body')
    if (body) {
      body.insertBefore(elx, body.firstChild)
    }
  }
}

