var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "upload",
      staticClass: "upload-image",
      class: {
        "add-hide":
          (_vm.mode === "single" && _vm.files.length) ||
          (_vm.mode === "multi" &&
            _vm.limit &&
            _vm.files.length >= _vm.limit) ||
          _vm.$attrs.disabled,
        "actions-hide": _vm.$attrs.disabled
      },
      attrs: {
        multiple: false,
        "on-change": _vm.handleFileChange,
        "on-remove": _vm.handleFileRemove,
        "list-type": _vm.listType,
        "auto-upload": false,
        action: "''",
        accept:
          "image/*,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.heic, application/pdf, application/zip,application/x-zip,application/x-zip-compressed",
        "file-list": _vm.files,
        limit: _vm.limit,
        "on-exceed": _vm.handleExceed,
        "before-upload": _vm.handleBeforeUpload
      },
      scopedSlots: _vm._u([
        {
          key: "file",
          fn: function(file) {
            return _c(
              "div",
              { staticStyle: { width: "100%", height: "100%" } },
              [
                file.file.status !== "uploading" &&
                ["picture-card", "picture"].indexOf(_vm.listType) > -1
                  ? _c("img", {
                      staticClass: "el-upload-list__item-thumbnail",
                      attrs: { src: file.file.url, alt: "" },
                      on: {
                        click: function($event) {
                          return _vm.handleImageClick(file.file.url)
                        },
                        error: _vm.handleImageError
                      }
                    })
                  : _vm._e(),
                _c(
                  "a",
                  {
                    staticClass: "el-upload-list__item-name",
                    on: {
                      click: function($event) {
                        return _vm.handleClick(file)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "el-icon-document" }),
                    _vm._v(_vm._s(file.file.name) + " ")
                  ]
                ),
                _c(
                  "label",
                  { staticClass: "el-upload-list__item-status-label" },
                  [
                    _c("i", {
                      class: {
                        "el-icon-upload-success": true,
                        "el-icon-circle-check": _vm.listType === "text",
                        "el-icon-check":
                          ["picture-card", "picture"].indexOf(_vm.listType) > -1
                      }
                    })
                  ]
                ),
                _vm.listType === "picture-card"
                  ? _c(
                      "span",
                      { staticClass: "el-upload-list__item-actions" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-delete",
                            on: {
                              click: function($event) {
                                return _vm.handleRemove(file.file)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          }
        }
      ])
    },
    [_c("i", { staticClass: "el-icon-plus" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }