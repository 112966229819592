









import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MapInput extends Vue {
  @Prop({ default: 200 }) width: number

  @Prop({ default: '' }) value: string

  private get proxyVal() {
    return this.value
  }

  private set proxyVal(val) {
    this.$emit('input', val)
  }

  private get listeners() {
    const { input, ...listeners } = this.$listeners
    return listeners
  }
}
