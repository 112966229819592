/* eslint-disable camelcase */
import moment from 'moment'

// Parse the time to string
export const parseTime = (
  time ? : object | string | number | null,
  cFormat ? : string
): string | null => {
  if (time === undefined || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date: Date
  if (typeof time === 'object') {
    date = time as Date
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj: {
    [key: string]: number
  } = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return timeStr
}

// Format and filter json data using filterKeys array
export const formatJson = (filterKeys: any, jsonData: any) =>
  jsonData.map((data: any) => filterKeys.map((key: string) => {
    if (key === 'timestamp') {
      return parseTime(data[key])
    } else {
      return data[key]
    }
  }))

// Check if an element has a class
export const hasClass = (ele: HTMLElement, className: string) => {
  return !!ele.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
}

// Add class to element
export const addClass = (ele: HTMLElement, className: string) => {
  if (!hasClass(ele, className)) ele.className += ' ' + className
}

// Remove class from element
export const removeClass = (ele: HTMLElement, className: string) => {
  if (hasClass(ele, className)) {
    const reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

// Toggle class for the selected element
export const toggleClass = (ele: HTMLElement, className: string) => {
  if (!ele || !className) {
    return
  }
  let classString = ele.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  ele.className = classString
}

export const file2Base64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = function(e) {
      const base64: string = e.target.result as string
      resolve(base64.split(',')[1])
    }

    try {
      fileReader.readAsDataURL(file)
    } catch (e) {
      reject(e)
    }
  })
}

/*
 * @param x {Object} 对象1
 * @param y {Object} 对象2
 * @return  {Boolean} true 为相等，false 为不等
 */
export const deepEqual = function(x, y) {
  // 指向同一内存时
  if (x === y) {
    return true
  } else if ((typeof x === 'object' && x != null) && (typeof y === 'object' && y != null)) {
    if (Object.keys(x).length !== Object.keys(y).length) { return false }

    for (const prop in x) {
      // eslint-disable-next-line no-prototype-builtins
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) { return false }
      } else { return false }
    }

    return true
  } else { return false }
}

export const sum = function(list: any, key: string) {
  let total = 0
  list && list.forEach(i => {
    total += i[key]
  })
  return total
}

export const getEnumArray = function(map: any, keyLabel = 'value', valueLabel = 'label') {
  if (map instanceof Object) {
    const arry = []
    for (const k in map) {
      const v = map[k]
      const obj = {}
      let key: number | string = Number(k)
      if (isNaN(key)) {
        key = k
      }
      obj[keyLabel] = key
      obj[valueLabel] = v
      arry.push(obj)
    }

    return arry
  } else if (map instanceof Array) {
    return map
  } else {
    throw 'argument 0 is not a Object or a Array'
  }
}

export const mergeRule = function(objValue: any, srcValue: any) {
  if (objValue instanceof Array) {
    return objValue.concat(srcValue)
  } else if (!srcValue) {
    // new value is null or 0,return old value
    return objValue
  } else {
    return srcValue
  }
}

export const mergeOldDataRule = function(objValue: any, srcValue: any) {
  if (objValue instanceof Object && !srcValue) {
    return objValue
  } else {
    return srcValue
  }
}
export const validateForm = function(refDom: any) {
  return new Promise((resolve, reject) => {
    refDom.validate((valid) => {
      if (valid) {
        resolve(true)
      } else {
        console.error('表单校验失败')
        resolve(false)
      }
    }).catch(e => {
      reject(e)
    })
  })
}

export const processTableData = function(data: any[], type: string) {
  for (let i = 0, len = data.length; i < len; i++) {
    data[i].type = type
  }
}

export const processRiskScoreData = function(riskScoreData: {
  excellent_count: number
  general_count: number
  good_count: number
  not_good_count: number
  record_time: number
  risk_score: number
} []) {
  const riskScoreMap = {
    excellent_count: '优秀企业',
    good_count: '良好企业',
    general_count: '一般企业',
    not_good_count: '欠佳企业'
  }
  const columnData: any[] = []
  const lineData: any[] = []
  riskScoreData/* .sort((item1, item2) => {
    if (item1.record_time > item2.record_time) {
      return 1
    } else if (item1.record_time < item2.record_time) {
      return -1
    } else {
      return 0
    }
  }) */.forEach(item => {
      Object.keys(riskScoreMap).forEach(key => {
        columnData.push({
          type: riskScoreMap[key],
          time: ' ' + moment(item.record_time).format('YYYY-MM-DD').toString() + ' ', // 此处空格用于解决antv折线dian顶在坐标最前面的bug
          value: item[key]
        })
      })
      lineData.push({
        time: ' ' + moment(item.record_time).format('YYYY-MM-DD').toString() + ' ',
        count: item.risk_score,
        type: '评分'
      })
    })
  return {
    columnData,
    lineData
  }
}
