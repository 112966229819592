import request from '@/utils/request'
import { IGetBlackListParams, IUpdateBlackListParams } from './interface/black_list'

const black_list_path = 'rule_white_list'

export const getListApi = (params) => request({
  url: `${black_list_path}/page`,
  method: 'post',
  data: params
})

export const getBlackListDetail = (id: string) => request({
  url: `${black_list_path}/detail`,
  method: 'post',
  data: {
    id
  }
})

export const addList = (params) => request({
  url: `${black_list_path}/add`,
  method: 'post',
  data: params
})

export const updateList = (params) => request({
  url: `${black_list_path}/update`,
  method: 'post',
  data: params
})

export const removeList = (id: string) => request({
  url: `${black_list_path}/remove`,
  method: 'post',
  data: {
    id
  }
})

export const enableItem = (params) => request({
  url: `${black_list_path}/update_state`,
  method: 'post',
  data: params
})

export const exportBlackList = (params: IGetBlackListParams) => request({
  url: `${black_list_path}/export`,
  method: 'post',
  data: params,
  responseType: 'blob'
})

export const getAlarmRuleList = (params: any) => request({
  url: 'industry_alarm_rule/recently_page',
  method: 'post',
  data: params
})
export const getPreAlarmRuleList = (params: any) => request({
  url: 'industry_alarm_rule/previous_page',
  method: 'post',
  data: params
})
