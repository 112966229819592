





















































import moment from "moment";
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { TimeRangeType } from "../constant";
import { getTimeFormat } from "../utils";
export interface TimeRange {
  startTime?: number;
  endTime: number;
}
const genesisTime = new Date("2020-9-01 00:00:00").getTime();
@Component({
  name: "DashboardHeader",
})
export default class extends Vue {
  @Prop() title!: string;
  @Prop({ default: TimeRangeType.Month }) timeRangeType: number;
  @Prop({
    default: genesisTime,
  })
  startLimit: number;

  @Prop({
    default: moment()
      .endOf("day")
      .valueOf(),
  })
  endLimit: number;

  @Prop({
    default: "daterange",
  })
  type: string;

  @Prop({
    default: true,
  })
  shortCut: boolean;

  @Prop({
    default: () => [],
  })
  disabledShortCutOption: number[];

  @Prop() timeRange: number[];
  @Prop() businessMode: number;
  @Prop({ default: true }) showTimeType: boolean;

  private timePickerRange = [];
  private initTimeRange: any = "";
  private mode = 0;
  private timeType = 1;

  private pickerOptions: any = {
    disabledDate: (dateTime) => {
      return (
        dateTime.getTime() < this.startLimit ||
        dateTime.getTime() > this.endLimit
      );
    },
  };

  created() {
    if (this.timeRange) {
      this.timePickerRange = this.timeRange;
      this.initTimeRange = this.timeRange;
    }
    if (this.businessMode) {
      this.mode = this.businessMode;
    }
    if (this.shortCut) {
      const shortcunts = [
        {
          text: "今日",
          type: TimeRangeType.Today,
          onClick: (picker) => {
            const timeRange = this.getTimeRangeByType(TimeRangeType.Today);
            picker.$emit(
              "pick",
              this.timeRangeFilter(timeRange.startTime, timeRange.endTime)
            );
          },
        },
        {
          text: "昨日",
          type: TimeRangeType.Yesterday,
          onClick: (picker) => {
            const timeRange = this.getTimeRangeByType(TimeRangeType.Yesterday);
            picker.$emit(
              "pick",
              this.timeRangeFilter(timeRange.startTime, timeRange.endTime)
            );
          },
        },
        {
          text: "近30日",
          type: TimeRangeType.Month,
          onClick: (picker) => {
            const timeRange = this.getTimeRangeByType(TimeRangeType.Month);
            picker.$emit(
              "pick",
              this.timeRangeFilter(timeRange.startTime, timeRange.endTime)
            );
          },
        },
        {
          text: "本年度",
          type: TimeRangeType.Year,
          onClick: (picker) => {
            const timeRange = this.getTimeRangeByType(TimeRangeType.Year);
            picker.$emit(
              "pick",
              this.timeRangeFilter(timeRange.startTime, timeRange.endTime)
            );
          },
        },
        {
          text: "累计",
          type: TimeRangeType.Total,
          onClick: (picker) => {
            const timeRange = this.getTimeRangeByType(TimeRangeType.Total);
            // this.$emit('onTimeRangeChange', timeRange)
            picker.$emit(
              "pick",
              this.timeRangeFilter(timeRange.startTime, timeRange.endTime)
            );
          },
        },
      ];
      const temp = [];
      shortcunts.forEach((short) => {
        if (this.disabledShortCutOption.indexOf(short.type) == -1) {
          temp.push(short);
        }
      });
      this.pickerOptions.shortcuts = temp;
    }
  }

  private timeRangeFilter(startTime: number, endTime: number) {
    let start = startTime;
    let end = endTime;

    if (this.endLimit && start > this.endLimit) {
      return [];
    }
    if (this.startLimit && end < this.startLimit) {
      return [];
    }
    if (this.startLimit && startTime < this.startLimit) {
      start = this.startLimit;
    }
    if (this.endLimit && endTime > this.endLimit) {
      end = this.endLimit;
    }
    return [start, end];
  }

  private getTimeRangeByType(type: number) {
    const currentStmp = new Date().getTime();
    let startStmp = currentStmp;
    let endStmp = currentStmp;
    let timeFormat = "MM月DD";
    switch (type) {
      case TimeRangeType.Today:
        startStmp = moment()
          .startOf("day")
          .valueOf();
        endStmp = moment()
          .endOf("day")
          .valueOf();
        timeFormat = "HH:mm";
        break;
      case TimeRangeType.Yesterday:
        startStmp = moment()
          .add(-1, "days")
          .startOf("day")
          .valueOf();
        endStmp = moment()
          .add(-1, "days")
          .endOf("day")
          .valueOf();
        timeFormat = "HH:mm";
        break;
      case TimeRangeType.Month:
        startStmp = moment()
          .add(-30, "days")
          .startOf("day")
          .valueOf();
        timeFormat = "MM.DD";
        break;
      case TimeRangeType.Year:
        startStmp = moment()
          .startOf("year")
          .startOf("day")
          .valueOf();
        // endStmp = moment().endOf('year').endOf('day').valueOf()
        timeFormat = "MM月";
        break;
      case TimeRangeType.Total:
        return {
          startTime: genesisTime,
          endTime: endStmp,
          timeFormat: "YYYY.MM",
        };
      case TimeRangeType.LastWeek:
        startStmp = moment()
          .startOf("week")
          .add(-7, "days")
          .startOf("day")
          .valueOf();
        endStmp = moment()
          .endOf("week")
          .add(-7, "days")
          .endOf("day")
          .valueOf();
        timeFormat = "MM.DD";
        break;
      default:
        startStmp = moment()
          .startOf("day")
          .valueOf();
        break;
    }

    console.log([new Date(startStmp), new Date(endStmp)]);
    return {
      startTime: startStmp,
      endTime: endStmp,
      timeFormat: timeFormat,
    };
  }

  @Emit("onTimeRangeChange")
  handleSelect(type: number) {
    return this.getTimeRangeByType(type);
  }

  @Emit("onTimeRangeChange")
  handleDatePickerChange(value) {
    // 清除日期逻辑
    if (!value) {
      if (this.initTimeRange) {
        value = this.initTimeRange;
      } else {
        return this.getTimeRangeByType(this.timeRangeType);
      }
    }
    // 累计逻辑
    if (!(value instanceof Array)) {
      return value;
    }

    // 正常逻辑
    const startTime = value[0] || "";
    const endTime = value[1] || "";
    const start_moment = moment(startTime);
    const end_moment = moment(endTime);

    const timeFormat = getTimeFormat(startTime, endTime);
    //   console.log(value)
    //   console.log({
    //   startTime: start_moment.valueOf(),
    //   endTime: end_moment.valueOf(),
    //   timeFormat: timeFormat
    // })
    return {
      startTime: start_moment.valueOf(),
      endTime: end_moment.valueOf(),
      timeFormat: timeFormat,
    };
  }

  @Emit("onTimeTypeChange")
  handleTimeTypeChange(value: string) {
    return {
      timeType: this.timeType,
    };
  }

  @Emit("onBusinessModeChange")
  handleRadioChange(mode: string) {
    return {
      businessMode: this.mode,
    };
  }

  @Emit("onClickTitle")
  handleClickTitle(evt: any) {
    return evt;
  }
}
