



































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import AreaList from '@/common/components/AreaList/index.vue'
import { getActiveRiskRuleSchemeList, getAgentRiskRuleSchemeList, editAgentRiskRuleSchemeList } from '@/api/riskrules'
import MapSelect from '@/common/business-components/TypeMap/map/MapSelect.vue'
import { IInspectStrategyListData } from '@/api/types'
import { EnumActions } from '@/common/business-components/TypeMap/ActionSetting/index.vue'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { Form } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import areaData from '@/assets/data/area_code_2023.json'

@Component({
  name: 'EnterpriseRiskSet',
  components: {
    Pagination,
    SearchFilter,
    AreaList,
    MapSelect,
    BsmTable
  }
})
export default class extends Vue {
  list: IInspectStrategyListData[] = []
  total = 0
  listLoading = true
  subLoading = false
  visible = false
  listQuery = {
    name: '',
    type: '',
    status: '',
    page: {
      index: 1,
      limit: 10
    }
  }

  temp: any = {
    business_type: '',
    name: '',
    status: 1,
    province: '',
    city: '',
    district: ''
  }

  get days() {
    return this.days
  }

  get roles() {
    return UserModule.roles
  }

  get rules() {
    return {
      // name: [{
      //   required: true,
      //   message: '抽检策略名称必填',
      //   trigger: 'blur'
      // }],
      status: [{
        required: true,
        message: '策略状态必选',
        trigger: 'change'
      }]
    }
  }

  searchTopForm:any = {
    scheme_no: '',
    district_code: ''
  }

  schemeNoEnum:any = []

  currentActionType: EnumActions = null

  businessTypes = [{
    label: '业务类',
    value: 1
  }, {
    label: '支付类',
    value: 2
  }]

  queryFilterOptions = [
    {
      field: 'agent_did',
      label: '网络货运企业',
      component: MapSearchSelect,
      config: {
        field: 'agent'
      },
      main: true
    }, {
      field: 'district_rule_switch',
      label: '区域规则',
      type: 'select',
      config: {
        list: [{
          value: 1,
          label: '启用'
        }, {
          value: 2,
          label: '启用（异地）'
        }, {
          value: 0,
          label: '禁用'
        }]
      }
    }, {
      field: 'custom_status',
      label: '规则自定义',
      type: 'select',
      config: {
        list: [{
          value: 1,
          label: '是'
        }, {
          value: 0,
          label: '否'
        }]
      }
    }
  ].map(item => {
    return Object.assign({}, item, { main: true })
  })

  get tableListOptions() {
    return [{
      field: 'id',
      type: 'actionSetting',
      attrs: {
        actionOptions: [
          {
            type: EnumActions.edit,
            label: '编辑配置'
          },
          {
            type: EnumActions.detail,
            label: '管理规则'
          }
        ]
      },
      label: '操作',
      fixed: true
    }, {
      field: 'name',
      label: '网货企业名称'
    }, {
      field: 'tax_no',
      label: '网货企业税号'
    }, {
      field: 'district_name',
      label: '企业所在区域',
      filter: (v, row) => {
        let res = ''
        if (row.district_code) {
          const codeList = row.district_code.split('-')
          areaData.forEach((item) => {
            if (item.code === Number(codeList[0])) {
              res += item.name
              if (codeList[1]) {
                item.children.forEach((v) => {
                  if (v.code === Number(codeList[1])) {
                    res += v.name
                    if (codeList[2]) {
                      v.children.forEach((vv) => {
                        if (vv.code === Number(codeList[2])) {
                          res += vv.name
                        }
                      })
                    }
                  }
                })
              }
            }
          })
        }
        return res
      }
    },
    {
      field: 'scheme_name',
      label: '风控方案'
    }, {
      field: 'district_rule_switch',
      label: '区域规则',
      filter: (v, row) => {
        return v ? '启用' : '禁用'
      }
    }, {
      field: 'custom_status',
      label: '规则自定义',
      filter: (v, row) => {
        return v ? '是' : '否'
      }
    }, {
      field: 'edit_switch',
      label: '企业编辑权限',
      filter: (v, row) => {
        return v ? '启用' : '禁用'
      }
    }, {
      field: 'anti_fraud_third_party_name',
      label: '对接系统'
    }, {
      field: 'fusion_switch',
      label: '返回风控结果',
      filter: (v, row) => {
        return v ? '是' : '否'
      }
    }, {
      field: 'automatic_appeal_switch',
      label: '自动申诉异常',
      filter: (v, row) => {
        return v ? '是' : '否'
      }
    }]
  }

  created() {
    this.initListQuery()
    this.getRuleSchemeList()
    this.getList()
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
      this.searchTopForm = Object.assign({}, this.searchTopForm, this.$route.query)
    }
  }

  async getRuleSchemeList() {
    const { data } = await getActiveRiskRuleSchemeList({
      page: {
        index: 1,
        limit: 10
      }
    })
    data.items.forEach((item) => {
      this.schemeNoEnum.push({
        value: item.scheme_no,
        label: item.scheme_name
      })
    })
  }

  handleAgentRegsiterDistrictChange(address: any) {
    this.temp.city = address.city
    this.temp.district = address.district
    this.temp.province = address.province
  }

  handleSearchDistrictChange(address: any) {
    this.searchTopForm.city = address.city
    this.searchTopForm.district = address.district
    this.searchTopForm.province = address.province
  }

  async getList() {
    this.listLoading = true
    const queryParams = Object.assign({}, this.listQuery, this.searchTopForm)
    queryParams.district_code = this.searchTopForm.district ? this.searchTopForm.district : this.searchTopForm.city ? this.searchTopForm.city : this.searchTopForm.province ? this.searchTopForm.province : ''
    const { data } = await getAgentRiskRuleSchemeList(queryParams)
    this.list = data.items
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }

  async submitDetail() {
    try {
      const valid = await (this.$refs.form as Form).validate()
      if (valid) {
        try {
          this.temp.district_code = this.temp.district ? this.temp.district : this.temp.city ? this.temp.city : this.temp.province ? this.temp.province : ''
          const data = Object.assign({}, this.temp)

          await editAgentRiskRuleSchemeList(data)
          this.$message.success('编辑成功')
          this.getList()

          this.visible = false
          this.subLoading = false
        } catch (err) {
          this.subLoading = false
        }
      }
    } catch (err) {

    }
  }

  resetTemp() {
    this.temp = {
      business_type: '',
      name: '',
      status: 1,
      province: '',
      city: '',
      district: ''
    }
  }


  handleFilter() {
    this.listQuery.page.index = 1
    this.listQuery.page.limit = 10
    this.getList()
  }

  handelActionDetail(row) {
    // this.$router.push({
    //   path: `/risk_rules/detail/${id}`
    // })
    this.$router.push({
      path: `/access/active_rule/${row.agent_did}`,
      query: {
        name: row.name
      }
    })
  }

  handleClose() {
    this.resetTemp()
    this.visible = false
    this.subLoading = false
  }

  handleEdit(row) {
    this.resetTemp()
    this.temp = { ...row }
    if (row.district_code) {
      const list = row.district_code.split('-')
      this.temp.province = list[0]
      this.temp.city = list[1]
      this.temp.district = list[2]
    }
    this.visible = true
  }

  handleActionCommand(command, id, row) {
    this.currentActionType = command
    if (command === EnumActions.detail) {
      this.handelActionDetail(row)
    } else if (command === EnumActions.edit) {
      this.handleEdit(row)
    }
  }
}

