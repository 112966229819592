// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.setting-container {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding-top: 161px;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.setting-container .avatar-uploader .el-upload {\n    border: 1px dashed #d9d9d9;\n    border-radius: 6px;\n    cursor: pointer;\n    position: relative;\n    overflow: hidden;\n}\n.setting-container .avatar-uploader .el-upload:hover {\n    border-color: #409EFF;\n}\n.setting-container .avatar-uploader-icon {\n    font-size: 28px;\n    color: #8c939d;\n    width: 178px;\n    height: 178px;\n    line-height: 178px;\n    text-align: center;\n}\n.setting-container .avatar {\n    width: 178px;\n    height: 178px;\n    display: block;\n}\n.setting-container .avatar-container .avatar {\n    width: 148px;\n    height: 148px;\n    position: absolute;\n    z-index: 1;\n    background-color: #fff;\n}\n.setting-container .info-container {\n    margin-left: 78px;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
