








































































import { Vue, Component, Prop } from 'vue-property-decorator'
import AntiFraud from './AntiFraud.vue'

import {
  IWaybillBaseDTO,
  IAntiFraudStatisticsDTO
} from '../index.d'

const waybillInfoDefault: IWaybillBaseDTO = {
  agent: '',
  consignor: '',
  consignor_did: '',
  filePdfPath: '',
  hasContract: false,
  sourceWaybillNo: '',
  waybillNo: '',
  sendTime: 0,
  receiveTime: 0,
  senderName: '',
  senderPhone: '',
  loadDistrict: '',
  loadAddress: '',
  receiverName: '',
  receiverPhone: '',
  receiveDistrict: '',
  receiveAddress: '',
  deleted: false
}

const antiFrauidInfoDefault: IAntiFraudStatisticsDTO = {
  antiFraudResultDtoList: [],
  antiFraudWaybillDto: {
    executeStartTime: 0,
    executed: false,
    riskGrade: 0,
    riskGradeDesc: ''
  },
  antiFraudWaybillLogDto: {
    id: '',
    waybillId: '',
    reason: '',
    photos: [],
    createTime: 0,
    operator: ''
  }
}

@Component({
  components: {
    AntiFraud
  }
})
export default class extends Vue {
  @Prop({ required: true, default: () => waybillInfoDefault }) readonly waybillInfo!: IWaybillBaseDTO
  @Prop({ required: true, default: () => antiFrauidInfoDefault }) readonly antiFraudInfo!: IAntiFraudStatisticsDTO
  @Prop() waybillId:string
  @Prop() type:any

  isShipperClient = window.location.host.indexOf('shipper') > -1
}

