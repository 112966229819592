var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-card" },
    [
      _c("div", [
        _c(
          "section",
          { staticClass: "x-between" },
          [
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _vm.link
              ? _c(
                  "no-cache-link",
                  { attrs: { to: _vm.link } },
                  [
                    _c("img-icons", {
                      attrs: { name: "linkRight", width: "20px" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c("section", { staticClass: "amount" }, [
          _vm._v(" " + _vm._s(_vm.amount) + " ")
        ])
      ]),
      _c("img-icons", { attrs: { name: _vm.icon, width: "30px" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }