var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "img",
    _vm._g(
      {
        staticClass: "img-icons",
        style: { width: _vm.width },
        attrs: { src: _vm.src }
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }