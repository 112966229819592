import request from '@/utils/request'

const black_list_path = 'agent_blacklist'


export const getListApi = (params:any) => request({
  url: 'waybill/appeal/page',
  method: 'post',
  data: params
})

export const getWaybillAppealDetailApi = (params:any) => request({
  url: 'waybill/appeal/detail',
  method: 'post',
  data: params
})

export const waybillAppealAuditApi = (params:any) => request({
  url: 'waybill/appeal/audit',
  method: 'post',
  data: params
})

export const exportBlackList = (params:any) => request({
  url: `${black_list_path}/export`,
  method: 'post',
  data: params,
  responseType: 'blob'
})
