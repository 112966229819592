




























import { Component, Vue } from 'vue-property-decorator'
import { employedInvoicePageApi } from '@/api/system'
import { IPushQueueData } from '@/api/types'

import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { amountFormatFilter } from '@/common/filters'

@Component({
  name: 'InvoiceQuery',
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  forcePassForm = {
    forcePassReasons: '',
    images: [],
    taxPushAntiFraudFailedId: ''
  };

  rules = {
    forcePassReasons: [
      { required: true, trigger: 'blur', message: '请输入强制通过理由' }
    ]
  };


  currentRow:any = {}
  applyDetail: any = {
    apply_tax_detail_view: {},
    return_tax_detail_view: {}
  };

  list: IPushQueueData[] = [];
  total = 0;
  listLoading = true;
  failedLogs: any = '';
  listQuery = {
    // 0：待推送；1、推送失败；9、推送成功'
    status: null,
    batch_no: null,
    page: {
      index: 1,
      limit: 10
    }
  };

  queryFilterOptions = [
    {
      field: 'batch_no',
      label: '开票订单号',
      main: true
    },
    {
      field: 'purchaser_key',
      label: '购方名称或税号',
      main: true
    },
    {
      field: 'seller_key',
      label: '销方名称或税号',
      main: true
    },
    {
      field: ['invoice_time_start', 'invoice_time_end'], // 'invoiceTime',
      label: '开票时间',
      type: 'dateRange',
      main: true
    }
  ];

  tableListOptions = [
    {
      field: 'batch_no',
      label: '开票订单号',
      type: 'linkLike',
      config: {
        label: val => {
          return val
        },
        handle: (val) => {
          const query:any = { tabStatus: 1, batch_no: val }
          this.$router.push({
            path: '/manage/individual-invoice',
            query: query
          })
        }
      }
    },
    {
      field: 'purchaser_invoice_title',
      label: '购方名称'
    },
    {
      field: 'purchaser_tax_number',
      label: '购方税号'
    },
    {
      field: 'seller_invoice_title',
      label: '销方名称',
      width: 180
    },
    {
      field: 'seller_tax_number',
      label: '销方税号',
      width: 180
    },
    {
      field: 'invoice_category_desc',
      label: '发票类型'
    },
    {
      field: 'invoice_code',
      label: '发票代码'
    },
    {
      field: 'invoice_number',
      label: '发票号码'
    },
    {
      field: 'invoice_amount',
      label: '开票金额（元）',
      filter: 'amountFormatFilter'
    },
    {
      field: 'invoice_tax',
      label: '税率',
      filter: (v) => {
        return v ? amountFormatFilter(v) + '%' : '-'
      }
    },
    {
      field: 'invoice_url',
      label: '发票文件',
      type: 'linkLike',
      config: {
        label: val => {
          return val
        },
        handle: (val) => {
          window.open(val, '_blank')
        }
      }
    },
    {
      field: 'invoiceTime',
      label: '开票时间',
      filter: 'timeFilterShowSecond',
      width: 100,
      align: 'center'
    }
  ];

  created() {
    if (this.$route && this.$route.query && !this.$route.query.batch_no) {
      this.initListQuery()
      this.getList()
    }
  }

  activated() {
    if (this.$route && this.$route.query && this.$route.query.batch_no) {
      this.initListQuery()
      this.getList()
    }
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
      // this.listQuery.invoice_type &&
      //   (this.listQuery.invoice_type = Number(this.listQuery.invoice_type))
    }
  }

  viewWaybill(row) {
    let path = ''
    const query: any = {}
    query.waybillIds = row.waybill_ids.join(',')
    path = '/dataquery/waybill'


    this.$router.push({
      path: path,
      query: query
    })
  }


  async getList() {
    this.listLoading = true
    const listQuery = JSON.parse(JSON.stringify(this.listQuery))
    const { data } = await employedInvoicePageApi(listQuery)
    this.list = data.items
    this.total = data.total
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  handleFilter() {
    this.listQuery.page.index = 1
    this.getList()
  }

  viewInvoiced() {
    const query: any = {}
    query.invoiceCode = this.applyDetail.invoice_code
    const path = '/tax/invoiced'
    this.$router.push({
      path: path,
      query: query
    })
  }
}
