







import { Pie } from '@antv/g2plot'
// import { Chart } from '@antv/g2'
import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResizeMixin from './mixins/resize1'
import { merge } from 'lodash'

const models = {
  normal: {
    padding: 'auto',
    position: 'bottom-center',
    radius: 1
  },
  right: {
    padding: [0, 0, 0, 160],
    position: 'left-top',
    radius: 1
  }
}

var containerStyle = "color:#4d4d4d;font-size:14px;text-align:center;line-height:2;font-family:'-apple-system',BlinkMacSystemFont,'SegoeUI',Roboto,'HelveticaNeue',Helvetica,'PingFangSC','HiraginoSansGB','MicrosoftYaHei',SimSun,'sans-serif';pointer-events:none;"
var nameStyle = 'font-weight:300;white-space: nowrap;text-overflow: ellipsis;'
var valueStyle = 'font-size:32px;font-weight:bold;color:#4D4D4D'

function getTemplate(name, value, size) {
  var domStyle = containerStyle + 'width:' + size + 'px;'
  var nameDomStr = name ? '<span class="ring-guide-name" style=' + nameStyle + '>' + name + '</span><br/>' : ''
  var valueDomStr = '<span class="ring-guide-value" style=' + valueStyle + '>' + value + '</span>'
  return '<div class="ring-guide-html ' + '" style=' + domStyle + '>' + nameDomStr + valueDomStr + '</div>'
}

@Component({
  name: 'DonutChart'
})
export default class extends mixins(ResizeMixin) {
  @Prop({ default: 'donut-chart' }) private className!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: '100%' }) private height!: string
  @Prop({ default: '' }) private description!: string
  @Prop({ required: true }) private angleField: string
  @Prop({ required: true }) private colorField: string
  @Prop({ default: 'normal' }) private model!: string
  @Prop({ default: 'count' }) private type: string
  @Prop({
    default() {
      return {}
    }
  }) private config: any

  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  }

  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.destroy()
    this.chart = null
  }

  private initChart() {
    const mode = models[this.model]
    const that = this
    this.chart = new Pie(this.$refs.chart as HTMLElement, merge({
      autoFit: true,
      // title: this.title,
      // description: {
      //   visible: true,
      //   text: this.description // '环图指标卡能够代替tooltip，在环图中心挖空部分显示各分类的详细信息。'
      // },
      radius: mode.radius,
      innerRadius: 0.7,
      padding: mode.padding,
      data: this.data,
      angleField: this.angleField,
      colorField: this.colorField,
      interactions: [{ type: 'element-selected' }, { type: 'element-active' }, { type: 'pie-statistic-active' }],
      statistic: {
        title: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '12'
          },
          customHtml: (container, view, datum, data) => {
            if (datum) {
              return datum.name
            } else {
              return '总计'
            }
          }
        },
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '14'
          },
          customHtml: (container, view, datum, data) => {
            let value = datum ? datum.value : data.reduce((r, d) => r + d.value, 0)
            if (that.type === 'amount') {
              value = that.$options.filters.amountFormatFilter(value)
            }
            return value
          }
        }
      },
      label: false,
      legend: {
        position: mode.position,
        offsetX: 20,
        flipPage: true,
        itemSpacing: 1,
        maxWidth: 200
        // text: {
        //   style: {
        //     maxItemWidth: 200
        //   }
        // }
      }
    }, this.config))
    // let layers: any = this.chart['layers']

    // layers[0].on('afterchangedata', (a) => {
    //   layers[0].statistic.updateHtml(layers[0].statistic.getTotalHtmlString())
    // })

    this.chart.render()
  }
}

