




















































import { Vue, Component, Watch, PropSync, Prop } from 'vue-property-decorator'
import areaData from '@/assets/data/area_code_2023.json'

@Component
export default class AreaList extends Vue {
  @Prop({ type: String }) provinceCode!: string

  @Prop({ type: String }) cityCode!: string

  @Prop({ type: String }) countyCode!: string
  @Prop({ type: Number }) index!: number
  @Prop({ type: Boolean }) disabled!: boolean

  province: any = ''
  city: any = ''
  county: any = ''

  get provinceList() {
    return areaData
  }

  get cityList() {
    if (this.province) {
      return this.province.children
    } else {
      return []
    }
  }

  get countyList() {
    if (this.city) {
      return this.city.children
    } else {
      return []
    }
  }

  @Watch('provinceCode')
  provinceCodeChange(v, old) {
    if (old) {
      return old
    }
    if (!v) {
      return v
    }
    const code = Number(v)
    areaData.forEach(p => {
      if (p.code === code) {
        this.province = p
      }
    })
  }

  @Watch('cityCode')
  cityCodeChange(v, old) {
    if (old) {
      return v
    }
    if (!v) {
      return v
    }

    const code = Number(v)
    this.province.children.forEach(c => {
      if (c.code === code) {
        this.city = c
      }
    })
  }

  @Watch('countyCode')
  countyCodeChange(v, old) {
    if (old) {
      return v
    }
    if (!v) {
      return v
    }

    const code = Number(v)
    this.city.children.forEach(c => {
      if (c.code === code) {
        this.county = c
      }
    })
  }

  changeProvince(a) {
    this.city = ''
    this.county = ''
    this.emitChange()
  }

  changeCity(a) {
    this.county = ''
    this.emitChange()
  }

  changeDistrict(a) {
    this.emitChange()
  }

  mounted() {
    if (this.provinceCode) {
      areaData.forEach(p => {
        if (p.code === Number(this.provinceCode)) {
          this.province = p
        }
      })
    }
    if (this.cityCode) {
      this.province.children.forEach(c => {
        if (c.code === Number(this.cityCode)) {
          this.city = c
        }
      })
    }
    if (this.countyCode) {
      this.city.children.forEach(c => {
        if (c.code === Number(this.countyCode)) {
          this.county = c
        }
      })
    }
  }

  emitChange() {
    this.$emit('onDistrictChange', {
      province: this.province && this.codeConvert(this.province.code),
      city: this.city && this.codeConvert(this.city.code),
      district: this.county && this.codeConvert(this.county.code),
      index: this.index || 0
    })
  }

  codeConvert(code: number) {
    return code.toString().slice(0, 6)
  }
}

