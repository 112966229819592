// import moment from 'moment'
import { parseTime } from '../utils'
import { LogKeyMap, InvoicePushStatusDesc } from '../constant'

// Filter to uppercase the first character
export const uppercaseFirstChar = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function timeFilter(time: any, format ? : string) {
  return time && time !== '0' ? parseTime(time, format || '{y}.{m}.{d}') : '-'
}

export function timeFilterShowSecond(time: any, format ? : string) {
  return time && time !== '0' ? parseTime(time, format || '{y}.{m}.{d} {h}:{i}:{s}') : '-'
}
export const amountFormatFilter = (amount: number | string, tailNum = 2) => {
  if (amount == null) {
    return ''
  }
  return (Number(amount) / 1000).toFixed(tailNum)
}
export const amountFormatPerFilter = (amount: number | string, tailNum = 2) => {
  if (amount == null) {
    return ''
  }
  return (Number(amount) / 100000).toFixed(tailNum)
}

export const amountFormatWithUnit = (amount: number | string, tailNum) => {
  if (amount == null) {
    return ''
  }
  const unitList = [{
    amount: 1000,
    unit: '',
    tailNum: 2
  }, {
    amount: 10000000,
    unit: '万',
    tailNum: 4
  }, {
    amount: 100000000000,
    unit: '亿',
    tailNum: 4
  }, {
    amount: 1000000000000000,
    unit: '万亿',
    tailNum: 4
  }]
  let val = Number(amount)
  let unit = ''
  let index = 3
  let u
  while (index >= 0) {
    u = unitList[index]
    if (val < u.amount) {
      index--
    } else {
      break
    }
  }
  val = val / u.amount
  unit = u.unit
  let fixNum = u.tailNum
  if (tailNum !== undefined) {
    fixNum = tailNum
  }

  return `${val.toFixed(fixNum)}${unit}`
}

export const volume = (value: number | string) => {
  const val = Number(value) / 1000000

  if (Number.isNaN(val)) {
    return ''
  }
  return val.toFixed(2)
}

export const weight = (value: number | string, unit = 'kg') => {
  const unitMap = {
    kg: 1000,
    t: 1000000
  }
  const val = Number(value) / unitMap[unit]

  if (Number.isNaN(val)) {
    return ''
  }

  return val.toFixed(2)
}

export const distance = (value: number | string) => {
  const val = Number(value) / 1000

  if (Number.isNaN(val)) {
    return ''
  }

  return val.toFixed(2)
}

export const logKeyName = (key: string) => {
  const obj: any = LogKeyMap[key]
  return (obj && obj.text) || ''
}

export const invoicePushStatus = (status: number) => {
  const desc: string = InvoicePushStatusDesc[status]
  return desc || ''
}
