








import { Scatter } from '@antv/g2plot'
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResizeMixin from './mixins/resize1'
import { Unit } from '../../utils'
import _ from 'lodash'
const colors = {
  blue: '#5A86EE',
  green: '#3DC69D',
  yellow: '#FF9800',
  red: '#FF6464'
}

@Component({
  name: 'BubbleChart'
})
export default class extends Vue {
  @Prop({ default: 'scatter-chart' }) private className!: string
  @Prop() private id!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: 'blue' }) private color!: string

  @Prop({ default: 'x' }) public xField!: string;
  @Prop({ default: 'y' }) public yField!: string;
  @Prop({ default: 'colorValue' }) private colorField!: string
  @Prop({ default() { return [] } }) private colors!: any []
  @Prop({ default: 'sizeValue' }) private sizeField!: string

  @Prop({ type: Array, required: true }) public data: any[];
  @Prop({ default: '' }) public title!: string;
  @Prop({ default: 'count' }) public valueType: string
  @Prop({ default: () => {} }) private config: any

  @Prop({ default: '100%' }) private height: string
  @Prop({ default: 0 }) private min!: number
  @Prop() private max!: number
  @Prop({ default: 0 }) private xMin!: number
  @Prop() private xMax!: number
  @Prop({ default: false }) showLegend!: boolean

  private chart: any

  private valueFilter: any
  created() {
    if (this.valueType === 'amount') {
      this.valueFilter = this.$options.filters.amountFormatFilter
    }
  }

  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  }

  private get options() {
    return _.merge({
      appendPadding: [20, 20, 16, 20],
      autoFit: true,
      data: this.data,
      xField: this.xField,
      yField: this.yField,
      colorField: this.colorField,
      color: this.colors.length > 0 ? this.colors : ['#ffd500', '#82cab2', '#193442', '#d18768', '#7e827a'],
      size: [4, 30],
      shape: 'circle',
      sizeField: this.sizeField,
      pointStyle: {
        fillOpacity: 0.7,
        stroke: '#bbb'
      },
      xAxis: {
        grid: {
          line: {
            style: {
              stroke: '#DBDBDB'
            }
          }

        },
        line: {
          style: {
            stroke: '#DBDBDB'
          }
        },
        tickLine: null,
        label: {
          visible: true,
          autoRotate: true,
          autoHide: true
        },
        title: null,
        min: this.xMin,
        max: this.xMax
      },
      yAxis: {
        grid: {
          line: {
            style: {
              stroke: '#DBDBDB'
            }
          }
        },
        line: {
          style: {
            stroke: '#DBDBDB'
          }
        },
        tickLine: null,
        label: {
          visible: true,
          autoRotate: true,
          autoHide: true
        },
        min: this.min,
        max: this.max
      },
      legend: {
        visible: this.showLegend,
        position: 'bottom'
        // flipPage: true,
        // title: {
        //   visible: false
        // }
      }
    }, this.config)
  }

  // @Watch('data', {
  //   deep: true
  // })
  // onDataChange(data: any[], oldData: any[]) {
  //   if (!_.isEqual(data, oldData)) {
  //     this.chart.destroy()
  //     this.initChart()
  //   }
  // }
  @Watch('options', {
    deep: true
  })
  onOptionsChange(now, old) {
    if (!_.isEqual(now, old)) {
      this.chart.update(this.options)
      this.updateHook()
    }
  }

  private initChart() {
    this.chart = new Scatter(this.$refs.chart as HTMLElement, this.options)
    this.chart.render()
    this.updateHook()
    // this.initEvent()
  }

  private updateHook() {
    setTimeout(() => {
      this.$emit('update')
    }, 1000)
  }

  // private initEvent() {
  //   this.chart.on('annotation:click', (...args) => {
  //     console.log(...args)
  //     // this.$emit('')
  //   })
  // }
}

