import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const accessRoutes: RouteConfig = {
  path: '/access',
  component: Layout,
  name: 'Access',
  meta: {
    title: '接入管理',
    icon: 'access',
    alwaysShow: true,
    roles: ['access']
  },
  children: [{
    path: 'list',
    name: 'ApplyAccessList',
    component: () => import('@/views/apply/list.vue'),
    meta: {
      title: '接入列表',
      roles: ['access_apply_list']
    }
  },
  {
    path: 'active_rule/:id',
    name: 'ActiveRule',
    component: () => import('@/views/apply/active-rule/index.vue'),
    meta: {
      title: '企业规则管理',
      roles: ['agent_rule'],
      hidden: true
    }
  }, {
    path: 'history/:id',
    name: 'RiskRukesHistory',
    component: () => import('@/views/apply/history/index.vue'),
    meta: {
      title: '企业历史规则管理',
      roles: ['agent_rule_history'],
      hidden: true
    }
  }
  ]
}

export default accessRoutes
