var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "waybill-invoice-container" }, [
    _c(
      "div",
      {
        staticClass: "content-title",
        staticStyle: { "font-weight": "500", color: "#4d4d4d" }
      },
      [_vm._v(" 发票信息 ")]
    ),
    _c("div", { staticClass: "content-header" }, [
      _c("span", [
        _vm._v("开票状态：" + _vm._s(_vm.waybillInvoiceInfo.invoiceStatusAlias))
      ]),
      _c("span", [
        _vm._v(
          "总额度：" +
            _vm._s(
              _vm._f("amountFormatFilter")(
                _vm.waybillInvoiceInfo.totalInvoiceAmount
              )
            ) +
            "元"
        )
      ]),
      _c("span", [
        _vm._v(
          "总税额：" +
            _vm._s(
              _vm._f("amountFormatFilter")(_vm.waybillInvoiceInfo.totalTax)
            ) +
            "元"
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("table-list", {
          attrs: {
            data: _vm.waybillInvoiceInfo.invoiceDataDtoList,
            options: _vm.invoiceListOptions,
            "max-height": "300",
            "show-setting": false
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }