var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "waybill-payment-container" }, [
    _c(
      "div",
      {
        staticClass: "content-title",
        staticStyle: { "font-weight": "500", color: "#4d4d4d" }
      },
      [_vm._v(" 结算信息 ")]
    ),
    _c("div", { staticClass: "content-header" }, [
      _c("span", [
        _vm._v(
          "托运人支付状态：" +
            _vm._s(_vm.waybillPaymentInfo.consignorPayStatusAlias)
        )
      ]),
      _c("span", [
        _vm._v(
          "承运人支付状态：" +
            _vm._s(_vm.waybillPaymentInfo.carrierPayStatusAlias)
        )
      ]),
      _c("span", [
        _vm._v(
          "总运费：" +
            _vm._s(
              _vm._f("amountFormatFilter")(
                _vm.waybillPaymentInfo.customerShipFee
              )
            ) +
            "元"
        )
      ]),
      _c("span", [
        _vm._v(
          "实收金额：" +
            _vm._s(
              _vm._f("amountFormatFilter")(
                _vm.waybillPaymentInfo.consignorActualAmount
              )
            ) +
            "元"
        )
      ]),
      !_vm.isShipperClient
        ? _c("span", [
            _vm._v(
              "总承运人运费：" +
                _vm._s(
                  _vm._f("amountFormatFilter")(
                    _vm.waybillPaymentInfo.driverShipFee
                  )
                ) +
                "元"
            )
          ])
        : _vm._e(),
      !_vm.isShipperClient
        ? _c("span", [
            _vm._v(
              "实付金额：" +
                _vm._s(
                  _vm._f("amountFormatFilter")(
                    _vm.waybillPaymentInfo.carrierActualAmount
                  )
                ) +
                "元"
            )
          ])
        : _vm._e(),
      _vm.waybillPaymentInfo.informationDetailDto &&
      _vm.waybillPaymentInfo.informationDetailDto.amount
        ? _c("p", [
            _c("span", [
              _vm._v(
                "信息费金额：" +
                  _vm._s(
                    _vm._f("amountFormatFilter")(
                      _vm.waybillPaymentInfo.informationDetailDto.amount
                    )
                  ) +
                  "元"
              )
            ]),
            _c("span", [
              _vm._v(
                "信息费实付金额：" +
                  _vm._s(
                    _vm._f("amountFormatFilter")(
                      _vm.waybillPaymentInfo.informationDetailDto.actual_amount
                    )
                  ) +
                  "元"
              )
            ]),
            _c("span", [
              _vm._v(
                "信息费收款人：" +
                  _vm._s(_vm.waybillPaymentInfo.informationDetailDto.payee_name)
              )
            ]),
            _vm.waybillPaymentInfo.informationDetailDto.dispatching_protocol
              ? _c(
                  "a",
                  {
                    staticClass: "text-primary",
                    staticStyle: { "margin-left": "16px" },
                    attrs: {
                      href:
                        _vm.waybillPaymentInfo.informationDetailDto
                          .dispatching_protocol,
                      target: "_blank"
                    }
                  },
                  [_vm._v("查看合同")]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("table-list", {
          attrs: {
            data: _vm.waybillPaymentInfo.paymentDetailDtoList,
            options: _vm.settlementInfoOptions,
            "max-height": "300",
            "show-setting": false
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }