










import { Vue, Component, Prop } from 'vue-property-decorator'

interface Iconfig {
  label: string | Function
  handle?: Function
}

@Component({
  components: {
  }
})
export default class MapLinkLike extends Vue {
  @Prop({
    default: '',
    required: true
  }) value: string

  @Prop({
    default() {
      return {}
    }
  }) rowData: any

  @Prop({
    default: function() {
      return {
        label: ''
      }
    }
  }) config: Iconfig

  private get label() {
    if (typeof this.config.label === 'string') {
      return this.config.label || ''
    }
    if (typeof this.config.label === 'function') {
      return this.config.label(this.value, this.rowData)
    }
  }

  private clickHandle() {
    if (this.config.handle) {
      this.config.handle(this.value, this.rowData)
      this.$emit('click', {
        value: this.value,
        row: this.rowData
      })
    }
  }
}
