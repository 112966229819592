





























import { Component, Vue, Watch } from 'vue-property-decorator'
import Manage from './components/manage.vue'
import Invoice from './components/invoice-apply.vue'
import InvoiceQuery from './components/invoice-query.vue'

@Component({
  name: 'Individual',
  components: {
    Manage,
    Invoice,
    InvoiceQuery
  }
})
export default class extends Vue {
  tabIndex = this.$route.query.tabStatus || '0'
  activeName = this.$route.query.tabStatus || '0'

  @Watch('$route', {
    deep: true
  })
  onRouteChange(route) {
    if (route.query.tabStatus) {
      this.tabIndex = route.query.tabStatus
      this.activeName = route.query.tabStatus
    }
  }

  handleClick(tab) {
    this.$router.push('/manage/individual-invoice?tabStatus=' + tab.index)
  }
}

