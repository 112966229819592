
















































































































































































import { Component, Vue } from 'vue-property-decorator'

import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import AlarmPop from '../components/alarm-pop.vue'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import MapSelect from '@/common/business-components/TypeMap/map/MapSelect.vue'
import MapDateRange from '@/common/business-components/TypeMap/map/MapDateRange.vue'
import { validateForm, getEnumArray } from '@/common/utils'
import {
  AlarmQuoteStatusMap
} from '@/common/constant'
import InspectDetail from '@/common/business-components/Inspect/InspectDetail.vue'

import {
  getAlarmQuotaDetail,
  updateAlarmQuota,
  getAlarmQuotaList,
  IGetAlarmQuotaListParams
} from '@/api/alarm'

@Component({
  name: 'Alarm',
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    MapSelect,
    MapSearchSelect,
    MapDateRange,
    InspectDetail,
    AlarmPop
  }
})
export default class extends Vue {
  list: any = []

  subjectTypeList=[
    {
      label: '产业园',
      value: 0
    },
    {
      label: '托运人',
      value: 1
    },
    {
      label: '网络货运商',
      value: 2
    },
    {
      label: '承运人',
      value: 3
    },
    {
      label: '司机',
      value: 4
    },
    {
      label: '车辆',
      value: 5
    }
  ]

  levelList=[
    {
      label: '警告',
      value: 1
    },
    {
      label: '预警',
      value: 2
    },
    {
      label: '提示',
      value: 3
    }
  ]

  total = 0
  listLoading = true
  listQuery: IGetAlarmQuotaListParams = {
    page: {
      index: 1,
      limit: 10
    }
  }


  currentAlarmId = ''
  currentName = ''
  currentRowData:any = {}
  alarmQuoteDialogVisible: any = false
  alarmAgentListDialogVisible: any = false

  alarmQuotaForm: any = {
    description: '',
    frequency_type: 1,
    id: '',
    informs: [],
    name: '',
    level: 1,
    status: 0,
    subject_type: null,
    threshold: 0
  }

  alarmQuotaFormRule: any = {
    name: [{ required: true, message: '请输入预警指标名称', trigger: 'blur' }],
    threshold: [
      { required: true, message: '请输入预警指标阈值', trigger: 'blur' }, {

        trigger: 'blur',
        validator: this.checkThreshold
      }
    ],
    description: [{
      required: true,
      message: '请输入预警指标说明',
      trigger: 'blur'
    }],
    frequency_type: [{ required: true, message: '请选择预警频次', trigger: 'blur' }],
    informs: [{ required: true, message: '请勾选预警通知', trigger: 'blur' }],
    status: [{ required: true, message: '请选择预警指标状态', trigger: 'blur' }]
  }

  checkThreshold(rule, value, callback) {
    if (this.alarmQuotaForm.type === 3) {
      if (/^[0-9]+/.test(value)) {
        callback()
      } else {
        callback(new Error('预警指标阈值必须为数字'))
      }
    } else {
      if (/^([1-9][0-9]{0,1}|100)$/.test(value)) {
        callback()
      } else {
        callback(new Error('预警指标阈值必须为1-100数字'))
      }
    }
  }

  queryFilterOptions = [{
    field: 'name',
    label: '预警指标名称',
    main: true
  }, {
    field: 'status',
    label: '预警指标状态',
    type: 'select',
    config: {
      list: getEnumArray(AlarmQuoteStatusMap)
    },
    main: true
  }]


  tableListOptions = [{
    field: 'id',
    type: 'buttons',
    label: '操作',
    config: {
      options: [{
        label: '查看详情',
        action: 'detail',
        type: 'primary'
      }]
    },
    fixed: true
  }, {
    field: 'name',
    label: '预警指标名称',
    fixed: true
  }, {
    field: 'create_time',
    label: '指标创建时间',
    filter: 'timeFilter',
    width: 120,
    align: 'center'
  }, {
    field: 'threshold_desc',
    label: '指标阈值',
    align: 'center'
    // filter: (val, row) => {
    //   if (row.type === 3) {
    //     return val + '天'
    //   } else {
    //     return val
    //   }
    // }
  }, {
    field: 'status_desc',
    label: '指标状态',
    align: 'center'
  }, {
    field: 'alarm_subject_count',
    label: '当期预警企业数',
    type: 'linkLike',
    config: {
      label(val, row) {
        return val
      },
      handle: (v, row) => {
        this.viewAlarmAgentCount(row)
      }
    }
  }]

  created() {
    this.getList()
  }

  async getList() {
    this.listLoading = true
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data } = await getAlarmQuotaList(this.listQuery)
    this.list = data.items
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }



  handleFilter() {
    this.listQuery.page.index = 1
    this.listQuery.page.limit = 10
    this.getList()
  }



  handleActionCommand(type: string, id: string, row: any) {
    if (type === 'detail') {
      getAlarmQuotaDetail({ id: id }).then(res => {
        const { data } = res
        this.alarmQuotaForm = data
        this.alarmQuotaForm.informs = Array.isArray(data.informs) ? data.informs : data.informs.split(',')
        this.alarmQuoteDialogVisible = true
        this.currentRowData = { ...row }
      })
    }
  }

  async handleUpdateAlarmQuota() {
    if (!await validateForm(this.$refs.alarmQuotaForm)) {
      this.$message.error('请检查提交的数据')
      return
    }
    this.alarmQuotaForm.informs = this.alarmQuotaForm.informs.join(',')
    updateAlarmQuota(this.alarmQuotaForm).then(res => {
      this.$message.success('更新成功')
      this.getList()
      this.alarmQuoteDialogVisible = false
    })
  }

  viewAlarmAgentCount(row: any) {
    this.currentAlarmId = row.id
    this.currentName = row.name
    ;(this.$refs.alarmDialog as any).showDialog()
  }
}

