import request from '@/utils/request'
import { IGetTrajectoryList } from '@/api/types'

export const getTrajectoryList = (data: IGetTrajectoryList) => request({
  url: '/trajectory/list',
  method: 'post',
  data
})

export const getDriverDot = (data: IGetTrajectoryList) => request({
  url: '/trajectory/driver/dot',
  method: 'post',
  data
})



export const getBeiDouTrackList = (data:{
  waybill_id?: string
  sub_waybil_id?:string
}) => request({
  url: '/beidou_trajectory/detail',
  method: 'post',
  data
})
export const getBeiDouTrack = (data: {
  id?: string
}) => request({
  url: '/beidou_trajectory/fetch',
  method: 'post',
  data
})


