























































































































































import { Component, Vue } from "vue-property-decorator";
import { IRiskRule } from "@/api/types";
import AreaList from "@/common/components/AreaList/index.vue";
import MapSelect from "@/common/business-components/TypeMap/map/MapSelect.vue";
import {
  addAgentSchemeApi,
  delAgentSchemeApi,
  getAgentSchemeRiskRuleList,
  getRiskRulesList,
  getActiveRiskRuleSchemeList
} from "@/api/riskrules";
import Pagination from "@/common/components/Pagination/index.vue";
import SearchFilter from "@/common/business-components/SearchFilter/index.vue";
import BsmTable from "@/views/components/BsmTable.vue";
import TableList from "@/common/business-components/TableList/index.vue";
import { RiskRuleKind } from "@/common/constant";
import { parseTime } from "@/utils/index";

const showEffectiveStatus = val => {
  if (val) {
    return "启用";
  } else {
    return "停用";
  }
};
const ifAppeal = val => {
  return val ? "是" : "否";
};
export const typeMap = {
  1: "关键策略",
  2: "中等策略",
  3: "一般策略"
};

const typeFilter = (type: number) => {
  return typeMap[type] || type;
};

@Component({
  name: "ActiveRule",
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    MapSelect,
    AreaList,
    TableList
  }
})
export default class extends Vue {
  activeName = "active";
  list: IRiskRule[] = [];
  currentPageData: any = {};
  add_rule_ids: any = [];
  eff_rule_ids: any = [];
  stop_rule_ids: any = [];
  allRuleList: IRiskRule[] = [];
  total = 0;
  allRuleTotal = 0;
  scheme_name = "";
  district_rule_switch = "";
  listLoading = true;
  showAddRuleDialog = false;
  showConfirmAddRuleDialog = false;
  listQuery = {
    anti_fraud_rule_id: "",
    agent_did: this.agent_did,
    page: {
      index: 1,
      limit: 1000
    }
  };

  searchTopForm: any = {
    scheme_no: "",
    district_code: ""
  };

  schemeNoEnum: any = [];

  allRuleListQuery = {
    id: "",
    name: "",
    effective: null,
    type: "",
    category: null,
    exclude_agent_did: this.agent_did,
    page: {
      index: 1,
      limit: 5
    },
    source: {
      data: this.agent_did,
      type: 3
    }
  };

  addRuleData = {
    effective_time: "",
    rule_id: "",
    agent_did: ""
  };

  queryFilterOptions = [
    {
      field: "anti_fraud_rule_id",
      label: "规则编号"
    }
  ].map(item => Object.assign({}, item, { main: true }));

  allRuleQueryFilterOptions = [
    {
      field: "name",
      label: "规则名称"
    },
    {
      field: "id",
      label: "规则编号"
    },
    {
      field: "type",
      label: "规则类型",
      type: "select",
      config: {
        list: Object.keys(typeMap).map(key => {
          return {
            value: key,
            label: typeMap[key]
          };
        })
      }
    },
    {
      field: "category",
      label: "规则类别",
      type: "select",
      config: {
        list: Object.keys(RiskRuleKind).map(key => {
          return {
            value: key,
            label: RiskRuleKind[key]
          };
        })
      }
    },
    // {
    //   field: "effective",
    //   label: "规则状态",
    //   type: "select",
    //   config: {
    //     list: [
    //       {
    //         value: true,
    //         label: "启用"
    //       },
    //       {
    //         value: false,
    //         label: "停用"
    //       }
    //     ]
    //   }
    // },
    {
      field: "business_mode",
      label: "业务类型",
      type: "select",
      config: {
        list: [
          {
            value: 1,
            label: "自营"
          },
          {
            value: 2,
            label: "撮合"
          }
        ]
      }
    }
  ].map(item => Object.assign({}, item, { main: true }));

  tableListOptions = [
    {
      field: "id",
      type: "buttons",
      label: "操作",
      config: {
        options: [
          {
            label: "删除",
            action: "remove",
            type: "danger"
          }
        ]
      },
      fixed: true,
      width: 50,
      noFit: true
    },
    {
      field: "rule_id",
      label: "规则编号",
      width: 150,
      fixed: true
    },
    {
      field: "rule_name",
      label: "规则名称",
      filter: (v, row) => {
        return (
          v + (row.label ? ' <i class="el-icon-warning-outline"></i>' : "")
        );
      }
    },
    {
      field: "status",
      label: "状态",
      filter: (v, row) => {
        return v ? "启用" : ' <span class="text-danger">禁用</span>';
      }
    },
    {
      field: "type",
      label: "规则类型",
      width: 150,
      filter: typeFilter
    },
    {
      field: "category",
      label: "规则类别",
      filter: (category: number, row) => {
        return RiskRuleKind[category] || category;
      }
    },
    {
      field: "business_mode",
      label: "业务类型",
      filter: (v, row) => {
        return v === 1 ? "自营" : v === 2 ? "撮合" : "";
      }
    },
    // {
    //   field: 'scheme_name_list',
    //   label: '风控方案',
    //   filter: (v, row) => {
    //     return v && v.length ? v.join(',') : ''
    //   }
    // },
    // {
    //   field: 'district_codes',
    //   label: '适用区域'
    // },
    {
      field: "risk_score",
      label: "风险分值",
      filter: "riskScore"
    },
    {
      field: "exception_type_name",
      label: "异常类型"
    },
    {
      field: "allow_report",
      label: "是否允许上报",
      align: "center",
      filter: ifAppeal
    },
    {
      field: "if_appeal",
      label: "是否需要申诉",
      align: "center",
      filter: ifAppeal
    },
    {
      field: "create_time",
      label: "规则加入时间",
      filter: "timeFilter",
      align: "center"
    },
    {
      field: "effective_time",
      label: "规则生效时间",
      filter: "timeFilter",
      align: "center"
    }
  ];

  allRiskRuleTableOptions = [
    {
      field: "id",
      type: "buttons",
      label: "操作",
      config: {
        options: [
          {
            label: "选择",
            action: "add",
            type: "primary"
          }
        ]
      },
      fixed: true
    },
    {
      field: "id",
      label: "规则编号",
      width: 150,
      fixed: true
    },
    {
      field: "name",
      label: "规则名称"
    },
    {
      field: "type",
      label: "规则类型",
      filter: typeFilter
    },
    {
      field: "category",
      label: "规则类别",
      filter: (category: number, row) => {
        return RiskRuleKind[category] || category;
      }
    },
    {
      field: "business_mode",
      label: "业务类型",
      filter: (v, row) => {
        return v === 1 ? "自营" : v === 2 ? "撮合" : "";
      }
    },
    {
      field: "risk_score",
      label: "风险分值",
      filter: "riskScore"
    },
    {
      field: "exception_type_alias",
      label: "异常类型"
    },
    // {
    //   field: "effective",
    //   label: "规则状态",
    //   align: "center",
    //   filter: showEffectiveStatus
    // },
    {
      field: "allow_report",
      label: "是否允许上报",
      align: "center",
      filter: ifAppeal
    },
    {
      field: "if_appeal",
      label: "是否需要申诉",
      align: "center",
      filter: ifAppeal
    },
    {
      field: "create_time",
      label: "规则创建时间",
      filter: "timeFilter",
      align: "center"
    },
    {
      field: "ineffective_time",
      label: "规则失效时间",
      filter: "timeFilter",
      align: "center"
    }
  ];

  created() {
    this.initListQuery();
    this.getList();
  }

  get agent_did() {
    return this.$route.params.id;
  }

  get agent_name() {
    return this.$route.query.name;
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query);
    }
  }

  async getList() {
    this.listLoading = true;
    const { data } = await getAgentSchemeRiskRuleList(this.listQuery);
    this.currentPageData = { ...data };
    this.scheme_name = data.scheme_name;
    this.district_rule_switch = data.district_rule_switch ? "启用" : "禁用";
    this.add_rule_ids = data.add_rule_ids;
    this.eff_rule_ids = data.eff_rule_ids;
    this.stop_rule_ids = data.stop_rule_ids;
    this.list =
      this.activeName === "active"
        ? data.eff_rule_ids
        : this.activeName === "add"
        ? data.add_rule_ids
        : data.stop_rule_ids;
    this.total = data.eff_rule_ids.length;
    this.listLoading = false;
  }

  handleSearchDistrictChange(address: any) {
    this.searchTopForm.city = address.city;
    this.searchTopForm.district = address.district;
    this.searchTopForm.province = address.province;
  }

  async getRuleSchemeList() {
    const { data } = await getActiveRiskRuleSchemeList({
      page: {
        index: 1,
        limit: 10
      }
    });
    data.items.forEach(item => {
      this.schemeNoEnum.push({
        value: item.scheme_no,
        label: item.scheme_name
      });
    });
  }

  async getAllRuleList() {
    const queryParams = Object.assign(
      {},
      this.allRuleListQuery,
      this.searchTopForm
    );
    queryParams.district_code = this.searchTopForm.district
      ? this.searchTopForm.district
      : this.searchTopForm.city
      ? this.searchTopForm.city
      : this.searchTopForm.province
      ? this.searchTopForm.province
      : "";
    const { data } = await getRiskRulesList({ ...queryParams, status: 1 });
    this.allRuleList = data.items;
    this.allRuleTotal = data.total;
  }

  handleFilter() {
    this.listQuery.page.index = 1;
    this.listQuery.page.limit = 1000;
    this.getList();
  }

  handleAllRuleFilter() {
    this.allRuleListQuery.page.index = 1;
    this.allRuleListQuery.page.limit = 5;
    this.getAllRuleList();
  }

  handleClickTab(comp) {
    const { name } = comp;
    if (name === "active") {
      this.list = this.currentPageData.eff_rule_ids;
      this.total = this.currentPageData.eff_rule_ids.length;
    } else if (name === "add") {
      this.list = this.currentPageData.add_rule_ids;
      this.total = this.currentPageData.add_rule_ids.length;
    } else if (name === "stop") {
      this.list = this.currentPageData.stop_rule_ids;
      this.total = this.currentPageData.stop_rule_ids.length;
    }
  }

  showAddDialog() {
    this.searchTopForm = {
      district_code: "",
      province: "",
      city: "",
      district: ""
    };
    this.showAddRuleDialog = true;
    this.getAllRuleList();
    this.getRuleSchemeList();
  }

  handleDelete(row) {
    try {
      this.$confirm("确认删除该生效规则？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delAgentSchemeApi({
          rule_id: row.rule_id,
          agent_did: this.agent_did,
          id: row.id
        }).then(res => {
          this.$message.success("删除当前生效规则成功");
          this.getList();
        });
      });
    } catch (err) {
      this.$message.info("取消删除");
    }
  }

  handleActionCommand(command, id, row) {
    if (command === "add") {
      this.showConfirmAddRuleDialog = true;
      this.addRuleData.rule_id = id;
      this.addRuleData.effective_time = "";
      this.addRuleData.agent_did = this.agent_did;
    } else if (command === "remove") {
      this.handleDelete(row);
    }
  }

  confirmAddActiveRule() {
    addAgentSchemeApi({
      rule_id: this.addRuleData.rule_id,
      effective_time: this.addRuleData.effective_time || 0,
      agent_did: this.agent_did
    }).then(res => {
      this.$message.success("规则添加成功");
      this.showConfirmAddRuleDialog = false;
      this.getAllRuleList();
      this.getList();
    });
  }

  get pickerOptions() {
    const nowTime = Date.now();
    const timeFormat = parseTime(nowTime, "{h}:{i}:{s}");
    return {
      disabledDate(date) {
        date.setHours(24);
        date.setMinutes(0);
        date.setMinutes(0);
        const time = date.getTime();
        return time < Date.now();
      }
    };
  }
}
