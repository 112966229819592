import request from "@/utils/request";
import {
  ITaxPushModifyParams,
  IForcePassInvoiceApplyParams,
  IGetPushQueueListParams
} from "@/api/types";

export const getTaxPushConfig = () =>
  request({
    url: "/tax/push/config",
    method: "post"
  });

export const modifyTaxPushConfig = (params: ITaxPushModifyParams) =>
  request({
    url: "/tax/push/config/modify",
    method: "post",
    data: params
  });

export const forcePassInvoiceApply = (data: IForcePassInvoiceApplyParams) =>
  request({
    url: "/tax/push/anti/fraud/failed/log/force",
    method: "post",
    data: data
  });

export const recheckAntiFraud = (data: { taxPushAntiFraudFailedId: string }) =>
  request({
    url: "/tax/push/anti/fraud/failed/rerun",
    method: "post",
    data: data
  });

export const getPushQueueList = (data: IGetPushQueueListParams) =>
  request({
    url: "/tax/push/queue/page-list",
    method: "post",
    data: data
  });

export const getInvoiceQueueList = (data: any) =>
  request({
    url: "/consignor/invoice/order/page-list",
    method: "post",
    data: data
  });

export const invoiceUpdateStatus = (data: any) =>
  request({
    url: "/consignor/invoice/order/update_status",
    method: "post",
    data: data
  });

export const repush = (data: { id: string }) =>
  request({
    url: "/tax/push/queue/reput",
    method: "post",
    data: data
  });
export const reApply = (data: { id: string }) =>
  request({
    url: "consignor/invoice/order/reapply",
    method: "post",
    data: data
  });

export const confirmFail = (data: { id: string }) =>
  request({
    url: "/tax/push/queue/confirm-fail",
    method: "post",
    data: data
  });
export const confirmSuccess = (data: { id: string; uuid: string }) =>
  request({
    url: "/tax/push/queue/confirm-success",
    method: "post",
    data: data
  });

export const rePushCallback = (data: any) =>
  request({
    url: "consignor/invoice/order/re_push_callback",
    method: "post",
    data: data
  });

export const syncResult = (data: any) =>
  request({
    url: "consignor/invoice/order/sync_result",
    method: "post",
    data: data
  });

export const syncFlushResult = (data: any) =>
  request({
    url: "consignor/invoice/order/sync_red_flush_result",
    method: "post",
    data: data
  });

export const exportInvoiceDetail = (data: any) =>
  request({
    url: "/tax/push/queue/export",
    method: "post",
    data: data,
    responseType: "blob"
  });

export const exportInvoiceExcel = (data: any) =>
  request({
    url: "/consignor/invoice/order/export_excel",
    method: "post",
    data: data,
    responseType: "blob"
  });

export const importInvoiceResult = (data: any) =>
  request({
    url: "/tax/push/queue/import",
    method: "post",
    data: data
  });

// 开票申请确认

export const applyConfirm = (data: { choice: number; id: string }) =>
  request({
    url: "/tax/push/confirm/apply_confirm",
    method: "post",
    data: data
  });

// 开票申请拒绝

export const applyReject = (data: { id: string }) =>
  request({
    url: "/tax/push/confirm/apply_reject",
    method: "post",
    data: data
  });

// 申请确认信息比对

export const applyCompare = (data: any) =>
  request({
    url: "/tax/push/confirm/find_compare",
    method: "post",
    data: data
  });
export const applyCompareDetail = (data: any) =>
  request({
    url: "/tax/push/queue/compare_tax",
    method: "post",
    data: data
  });

// 已处理

export const getApplyHandledList = (data: any) =>
  request({
    url: "/tax/push/confirm/find_confirm_page",
    method: "post",
    data: data
  });
// 待处理
export const getApplyUnhandledList = (data: any) =>
  request({
    url: "/tax/push/confirm/find_queue_page",
    method: "post",
    data: data
  });

export const findSubawybillIds = (id: string) =>
  request({
    url: "/tax/push/confirm/find_sub_waybill_ids",
    method: "post",
    data: {
      id
    }
  });

export const handleFailsApi = (id: string) =>
  request({
    url: "/tax/push/queue/fail_handler",
    method: "post",
    data: {
      id,
      allRule: true
    }
  });

export const getSubsidyTaxPageApi = data =>
  request({
    url: "/subsidy/tax/value_page",
    method: "post",
    data
  });

export const getSubsidyTaxDetailApi = data =>
  request({
    url: "/subsidy/tax/value_detail",
    method: "post",
    data
  });

export const downloadTaxDataFileApi = data =>
  request({
    url: "/subsidy/tax/download_tax_data_file",
    method: "post",
    data,
    responseType: "blob"
  });

export const getTaxDataFileApi = data =>
  request({
    url: "/subsidy/tax/get_tax_data_file",
    method: "post",
    data
  });

export const getSubsidyTaxApplyPageApi = data =>
  request({
    url: "/subsidy/tax/apply_page",
    method: "post",
    data
  });

export const applySmsApi = data =>
  request({
    url: "/subsidy/tax/apply_sms",
    method: "post",
    data
  });

export const getApplyDetailApi = data =>
  request({
    url: "/subsidy/tax/apply_detail",
    method: "post",
    data
  });

export const applyAuditApi = data =>
  request({
    url: "/subsidy/tax/apply_audit",
    method: "post",
    data
  });

export const declareConfApi = data =>
  request({
    url: "/agent/declare/declare_conf",
    method: "post",
    data
  });

export const getDeclareConfApi = () =>
  request({
    url: "/agent/declare/get_declare_conf",
    method: "post"
  });

export const downloadApplyFileApi = data =>
  request({
    url: "/subsidy/tax/download_apply_file",
    method: "post",
    data,
    responseType: "blob"
  });

export const getValueStatApi = data =>
  request({
    url: "/subsidy/tax/value_stat",
    method: "post",
    data
  });
