import { DirectiveOptions } from 'vue'
import { LogKeyMap } from '../../constant'
import {
  timeFilter,
  amountFormatFilter
} from '../../filters'

import { isUrl } from '../../utils'
const win = window as any
const AMap = win.AMap
let geocoder: any
AMap.plugin('AMap.Geocoder', function() {
  geocoder = new AMap.Geocoder({
    // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
    city: '全国'
  })
  // 使用geocoder做地理/逆地理编码
})

function createLink(url: string) {
  const a = document.createElement('a')
  a.target = '_blank'
  a.href = url
  a.innerHTML = url
  a.setAttribute('style', 'color:#4f7df9;max-width: 270.84px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:inline-block;')
  return a
}

export const logContent: DirectiveOptions = {

  inserted: (el, binding, vnode) => {
    const { value } = binding
    const { key, content } = value
    const obj: any = LogKeyMap[key]
    if (!obj || !obj.type) {
      const str = (content === '' || content === undefined || content === null) ? '-' : content
      if (isUrl(str)) {
        const a = createLink(str)
        el.appendChild(a)
      } else {
        el.innerHTML = str
      }
    }

    const type = obj.type
    let str = content
    switch (type) {
      case 'time':
        str = timeFilter(content, '{yyyy}年{mm}月{dd}日 {hh}:{ii}:{ss}')
        el.innerHTML = str
        break
      case 'amount':
        str = amountFormatFilter(content)
        el.innerHTML = str
        break
      case 'jsonArray':
        // eslint-disable-next-line no-case-declarations
        const keys = obj.keys
        // const template = []
        for (let i = 0, len = content.length; i < len; i++) {
          const c = content[i]

          const div = document.createElement('div')
          keys.forEach(k => {
            const row = document.createElement('div')
            const title = LogKeyMap[k] ? LogKeyMap[k].text : ''
            const titleSpan = document.createElement('span')
            titleSpan.innerHTML = title + '：'
            row.appendChild(titleSpan)
            const value = c[k]
            if (value) {
              if (isUrl(value)) {
                const a = createLink(value)
                row.appendChild(a)
              } else {
                const d = document.createElement('span')
                d.innerHTML = value
                row.appendChild(d)
              }
              div.appendChild(row)
            }
          })
          el.appendChild(div)
        }
        break
      case 'location':
        geocoder.getAddress(content, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            const { regeocode } = result
            const { province, city, district } = regeocode.addressComponent
            str = province + city + district
            // result为对应的地理位置详细信息
          } else {
            str = '-'
          }
          el.innerHTML = str
        })
        break
      case 'boolean':
        el.innerHTML = content ? '是' : '否'
        break
      default:
        break
    }
  }
}
