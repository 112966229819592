



import { Radar } from '@antv/g2plot'
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
// import { mixins } from 'vue-class-component'
// import ResizeMixin from './mixins/resize1'

import _ from 'lodash'
const colors = {
  blue: '#5A86EE',
  green: '#3DC69D',
  yellow: '#FF9800',
  red: '#FF6464'
}


@Component({
  name: 'RadarChart'
})
export default class extends Vue {
  @Prop({ default: 'radar-chart' }) private className!: string
  @Prop() private id!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: 'blue' }) private color!: string
  @Prop({ default: 'x' }) public xField!: string;
  @Prop({ default: 'y' }) public yField!: string;
  @Prop({ type: Array, required: true }) public data: any[];
  @Prop({ default: '' }) public title!: string;
  @Prop({ default: 'count' }) public valueType: string
  @Prop({ default: 'type' }) private seriesField!: string
  @Prop({ default: '100%' }) private height: string
  @Prop({ default: () => {} }) private config: any


  private chart: any
  private valueFilter: any

  created() {
    if (this.valueType === 'amount') {
      this.valueFilter = this.$options.filters.amountFormatFilter
    }
  }

  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  }

  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.destroy()
    this.chart = null
  }
  @Watch('data', {
    deep: true
  })
  onDataChange(data: any[], oldData: any[]) {
    if (!_.isEqual(data, oldData)) {
      // this.chart.destroy();
      // this.initChart()
      this.chart.changeData(data)
      this.chart.update()
    }
  }


  private initChart() {

    this.chart = new Radar(this.$refs.chart as HTMLElement, _.merge({
      data: this.data,
      xField: this.xField,
      yField: this.yField,
      seriesField: this.seriesField,
      // padding: [0, 0],
      meta: {
        count: {
          range: [0, 1]
        }
      },
      xAxis: {
        line: null,
        tickLine: null,
        grid: {
          line: {
            style: {
              lineDash: null,
            },
          },
        },
      },
      yAxis: {
        label: null,
        type: 'log',
        // tickMethod: 'log',
        nice: true,
        min: 0,
        // grid: {
        //   alternateColor: ['rgba(0, 0, 0, 0.04)', null],
        // },
        // line: null,
        tickLine: null,
        grid: {
          line: {
            // style: {
            //   lineDash: null,
            // },
          },
        },
      },
      point: {
        size: 2,
        style: {
          cursor: 'pointer'
        }
      },
      area: {},

      legend: null,
      tooltip: {
        showCrosshairs:false,
        shared: false,
        fields: ['displayCount', 'count'],
        formatter: (obj: any) => {
          return { name: '数量', value: obj.displayCount }
        }
      }
    }, this.config))
    this.chart.on('point:click', (ev) => {
      this.$emit('onPointClick', ev)
    })
    this.chart.render()
  }
}

