












































































































import { Component, Vue } from 'vue-property-decorator'
import { IRiskRule } from '@/api/types'
import {
  addRiskRuleToTemplate,
  removeRiskRuleFromTemplate,
  getActiveRiskRuleOfTemplate,
  getRiskRulesList,
  updateRuleTemplate
} from '@/api/riskrules'

import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import TableList from '@/common/business-components/TableList/index.vue'
import { RiskRuleKind } from '@/common/constant'
import { ElForm } from 'element-ui/types/form'
import { validateForm } from '@/common/utils'
// import { parseTime } from '@/utils/index.ts'

const showEffectiveStatus = (val) => {
  if (val) {
    return '启用'
  } else {
    return '停用'
  }
}
const ifAppeal = (val) => {
  return val ? '是' : '否'
}
export const typeMap = {
  1: '关键策略',
  2: '中等策略',
  3: '一般策略'
}

const typeFilter = (type: number) => {
  return typeMap[type] || type
}

@Component({
  name: 'RiskTemplateList',
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    TableList
  }
})
export default class extends Vue {
  list: IRiskRule[] = []
  allRuleList: IRiskRule[] = []
  total = 0
  allRuleTotal = 0
  listLoading = true
  submitLoading = false
  showEdit = false
  showAddRuleDialog = false
  // showConfirmAddRuleDialog = false
  listQuery = {
    anti_fraud_rule_id: '',
    page: {
      index: 1,
      limit: 10
    }
  }

  temp = {
    rule_id: '',
    name: '',
    effective_time: ''
  }

  rules = {
    effective_time: [{
      required: true,
      message: '请选择时间',
      trigger: 'blur'
    }]
  }

  allRuleListQuery = {
    id: '',
    name: '',
    effective: null,
    type: '',
    category: null,
    page: {
      index: 1,
      limit: 5
    },
    source: {
      data: '',
      type: 2
    }
  }

  addRuleData = {
    effective_time: '',
    rule_id: ''
  }

  queryFilterOptions = [{
    field: 'anti_fraud_rule_id',
    label: '规则编号'
  }].map(item => Object.assign({}, item, { main: true }))

  allRuleQueryFilterOptions = [{
    field: 'name',
    label: '规则名称'
  },
  {
    field: 'id',
    label: '规则编号'
  },
  {
    field: 'type',
    label: '规则类型',
    type: 'select',
    config: {
      list: Object.keys(typeMap).map(key => {
        return {
          value: key,
          label: typeMap[key]
        }
      })
    }
  },
  {
    field: 'category',
    label: '规则类别',
    type: 'select',
    config: {
      list: Object.keys(RiskRuleKind).map(key => {
        return {
          value: key,
          label: RiskRuleKind[key]
        }
      })
    }
  },
  {
    field: 'effective',
    label: '规则状态',
    type: 'select',
    config: {
      list: [{
        value: true,
        label: '启用'
      }, {
        value: false,
        label: '停用'
      }]
    }
  }
  ].map(item => Object.assign({}, item, { main: true }))

  tableListOptions = [{
    field: 'anti_fraud_rule_view.id',
    type: 'actionSetting',
    label: '操作',
    attrs: {
      actionOptions: [
        {
          label: '编辑',
          type: 'update'
        },
        {
          label: '删除',
          type: 'remove'
        }]
    },
    width: 50,
    fixed: true
  }, {
    field: 'anti_fraud_rule_view.id',
    label: '规则编号',
    width: 150,
    fixed: true
  }, {
    field: 'anti_fraud_rule_view.name',
    label: '规则名称'
  }, {
    field: 'anti_fraud_rule_view.type',
    label: '规则类型',
    filter: typeFilter
  }, {
    field: 'anti_fraud_rule_view.category',
    label: '规则类别',
    filter: (category: number, row) => {
      return RiskRuleKind[category] || category
    }
  }, {
    field: 'anti_fraud_rule_view.risk_score',
    label: '风险分值',
    filter: 'riskScore'
  }, {
    field: 'anti_fraud_rule_view.exception_type_alias',
    label: '异常类型'
  }, {
    field: 'anti_fraud_rule_view.allow_report',
    label: '是否允许上报',
    align: 'center',
    filter: ifAppeal

  }, {
    field: 'if_appeal',
    label: '是否需要申诉',
    align: 'center',
    filter: ifAppeal

  }, {
    field: 'create_time',
    label: '规则加入时间',
    filter: 'timeFilter',
    align: 'center'
  },
  {
    field: 'anti_fraud_rule_view.effective_time',
    label: '规则生效时间',
    filter: 'timeFilter',
    align: 'center'
  }
  ]

  allRiskRuleTableOptions = [{
    field: 'id',
    type: 'buttons',
    label: '操作',
    config: {
      options: [{
        label: '选择',
        action: 'add',
        type: 'primary'
      }]
    },
    fixed: true
  }, {
    field: 'id',
    label: '规则编号',
    width: 300,
    fixed: true
  }, {
    field: 'name',
    label: '规则名称'
  }, {
    field: 'type',
    label: '规则类型',
    filter: typeFilter
  }, {
    field: 'category',
    label: '规则类别',
    filter: (category: number, row) => {
      return RiskRuleKind[category] || category
    }
  }, {
    field: 'risk_score',
    label: '风险分值',
    filter: 'riskScore'
  }, {
    field: 'exception_type_alias',
    label: '异常类型'
  }, {
    field: 'effective',
    label: '规则状态',
    align: 'center',
    filter: showEffectiveStatus
  }, {
    field: 'allow_report',
    label: '是否允许上报',
    align: 'center',
    filter: ifAppeal
  }, {
    field: 'if_appeal',
    label: '是否需要申诉',
    align: 'center',
    filter: ifAppeal
  }, {
    field: 'create_time',
    label: '规则创建时间',
    filter: 'timeFilter',
    align: 'center'
  }, {
    field: 'ineffective_time',
    label: '规则失效时间',
    filter: 'timeFilter',
    align: 'center'
  }]

  created() {
    this.initListQuery()
    this.getList()
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
  }

  async getList() {
    this.listLoading = true
    const { data } = await getActiveRiskRuleOfTemplate(this.listQuery)
    this.list = data.items
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }

  async getAllRuleList() {
    const { data } = await getRiskRulesList(this.allRuleListQuery)
    this.allRuleList = data.items
    this.allRuleTotal = data.total
  }

  handleFilter() {
    this.listQuery.page.index = 1
    this.listQuery.page.limit = 10
    this.getList()
  }

  handleAllRuleFilter() {
    this.allRuleListQuery.page.index = 1
    this.allRuleListQuery.page.limit = 10
    this.getAllRuleList()
  }

  showAddDialog() {
    this.showAddRuleDialog = true
    this.getAllRuleList()
  }

  handleDelete(id: string) {
    try {
      this.$confirm('确认从模板中删除该规则？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeRiskRuleFromTemplate({ id: id }).then(res => {
          this.$message.success('删除成功')
          this.getList()
        })
      })
    } catch (err) {
      this.$message.info('取消删除')
    }
  }

  handleAdd(id:string) {
    addRiskRuleToTemplate({
      rule_id: id
    }).then(res => {
      this.$message.success('规则添加至模板成功')
      this.getAllRuleList()
    })
  }

  async handleSubmit() {
    if (!await validateForm(this.$refs.form)) {
      return
    }
    this.submitLoading = true
    updateRuleTemplate(this.temp).then(() => {
      this.$message.success('编辑成功')
      this.getList()
      this.submitLoading = false
      this.showEdit = false
    })
  }

  handleActionCommand(command, id, row) {
    if (command === 'add') {
      this.addRuleData.rule_id = id
      this.handleAdd(id)
    } else if (command === 'remove') {
      this.handleDelete(row.id)
    } else if (command === 'update') {
      this.showEdit = true
      this.temp.rule_id = row.anti_fraud_rule_view.id
      this.temp.name = row.anti_fraud_rule_view.name
      this.temp.effective_time = row.anti_fraud_rule_view.effective_time || ''
    }
  }
}

