import request, { } from '@/utils/request'
import { IGetListParams } from '@/api/types'

export const getConditionList = (data: IGetListParams) => request({
  url: '/aggregation/condition/list',
  method: 'post',
  data
})

export const getConditionSubject = (did: string) => request({
  url: '/aggregation/condition/subject',
  method: 'post',
  data: {
    did
  }
})
