




















































































































































































































































































































import { Vue, Component, ProvideReactive } from 'vue-property-decorator'
import DashboardHeader from '@/common/business-components/DashboardHeader.vue'
import DashboardCard from '@/common/business-components/DashboardCard.vue'
import ChartContainer from '@/common/business-components/ChartContainer.vue'
import TicketCard from '@/common/business-components/TicketCard.vue'
import BarChart from '@/common/components/Charts/BarChart.vue'
import PieChart from '@/common/components/Charts/PieChart.vue'
import LineAreaChart from '@/common/components/Charts/LineAreaChart.vue'
import LineChart from '@/common/components/Charts/LineChart.vue'
import FilledMap from '@/common/components/Charts/FilledMap.vue'
import DonutChart from '@/common/components/Charts/DonutChart.vue'
import ContainShowImg from '@/common/components/ContainShowImg/index.vue'
import ChinaMap from '@/common/business-components/ChinaMap/index.vue'
import RiskScore from '@/views/components/RiskScore/index.vue'
import {
  getVehicleDashboard,
  getVehicleRank,
  getVehicleSubjectCount,
  getVehicleType,
  getVehicleWaybillChart,
  getAlarmRuleList
} from '@/api/vehicle'
import { IVehicleDashboardData, IGetDashboardParams } from '@/api/types'
import { mergeRule, mergeOldDataRule } from '@/utils'
import moment from 'moment'
import SubjectDetailDialog from '@/views/components/SubjectDetailDialog/index.vue'
import AlarmList from '@/views/regulation/components/alarm-list.vue'
import AlarmPop from '@/views/regulation/components/alarm-pop.vue'

import _ from 'lodash'
@Component({
  name: 'VehicleDetail',
  components: {
    ChartContainer,
    LineAreaChart,
    LineChart,
    AlarmPop,
    AlarmList,
    DashboardHeader,
    DashboardCard,
    TicketCard,
    BarChart,
    PieChart,
    FilledMap,
    DonutChart,
    ContainShowImg,
    ChinaMap,
    SubjectDetailDialog,
    RiskScore
  }
})
export default class extends Vue {
  @ProvideReactive('dashboard') timeFormat = 'MM.DD'

  params = {
    start_time: moment().add(-30, 'days').startOf('day').valueOf(),
    end_time: moment().valueOf(),
    subject_id: '',
    business_mode: 0,
    time_type: 1
  }

  data: IVehicleDashboardData = {
    vehicle_info: {
      vehicle_number: '',
      transport_licence_no: '',
      owner_name: ''
    },
    waybill_count: 0,
    waybill_count_list: [],
    consignor_count: 0,
    agent_count: 0,
    carrier_count: 0,
    driver_count: 0,

    income_sum: 0, // 运费收入
    expense_sum: 0, // 运费支出

    agent_waybill_rank_list: [],
    agent_freight_rank_list: [],

    driver_waybill_rank_list: [],
    driver_freight_rank_list: [],

    goods_type_distribution: [],
    receiver_province_distribution: [],
    delivery_province_distribution: [],
    business_type_distribution: [],
    pay_type_distribution: [],
    risk_score: 0,
    risk_score_type: 0
  }

  alarmData = {}
  loadingData = true
  currentAlarmId=''
  currentName=''
  queryName=''
  listQuery = {
    subject_id: this.id,
    page_query: {
      index: 1,
      limit: 100
    }
  }

  created() {
    this.getData()
    this.getList()
  }

  showPop(row) {
    this.currentAlarmId = row.id
    this.currentName = row.name
    this.queryName = this.data.vehicle_info ? this.data.vehicle_info.vehicle_number : ''
    ;(this.$refs.alarmDialog as any).showDialog()
  }

  async getList() {
    const { data } = await getAlarmRuleList(this.listQuery)
    this.loadingData = false
    this.alarmData = {
      items: data
    }
  }

  get id() {
    return this.$route.params.id
  }

  updateData(data, temp) {
    temp = _.mergeWith(temp, data, mergeRule)
    if (temp) {
      this.$nextTick(() => {
        _.mergeWith(this.data, temp, mergeOldDataRule)
        // this.data = temp
      })
    }
  }

  async getData() {
    const params = Object.assign({}, this.params, {
      subject_id: this.id
    })
    const temp = {}
    getVehicleRank(params).then(({ data }) => {
      this.data.agent_waybill_rank_list = data.agent_waybill_rank_list
      this.data.agent_freight_rank_list = data.agent_freight_rank_list
      this.data.driver_waybill_rank_list = data.driver_waybill_rank_list
      this.data.driver_freight_rank_list = data.driver_freight_rank_list
    })
    getVehicleSubjectCount(params).then(({ data }) => {
      this.data.agent_count = data.agent_count
      this.data.consignor_count = data.consignor_count
      this.data.carrier_count = data.carrier_count
      this.data.driver_count = data.driver_count
      this.data.expense_sum = data.expense_sum
      this.data.income_sum = data.income_sum
    })
    getVehicleType(params).then(({ data }) => {
      this.data.goods_type_distribution = data.goods_type_distribution
      this.data.delivery_province_distribution = data.delivery_province_distribution
      this.data.receiver_province_distribution = data.receiver_province_distribution
      this.data.business_type_distribution = data.business_type_distribution
      this.data.pay_type_distribution = data.pay_type_distribution
    })
    getVehicleWaybillChart(params).then(({ data }) => {
      this.data.vehicle_info = data.vehicle_info
      this.data.waybill_count = data.waybill_count
      this.data.waybill_count_list = data.waybill_count_list
      this.data.risk_score = data.risk_score.risk_score
      this.data.risk_score_type = data.risk_score.risk_score_type
    })
  }

  getLinkUrl(url: string) {
    if (url.indexOf('?') === -1) {
      return `${url}?vehicle=${this.id}`
    } else {
      return `${url}&vehicle=${this.id}`
    }
  }

  handleTimeChange({ startTime, endTime, timeFormat }) {
    this.params.start_time = startTime
    this.params.end_time = endTime
    this.timeFormat = timeFormat
    this.getData()
  }

  handleBusinessChange({ businessMode }) {
    this.params.business_mode = businessMode
    this.getData()
  }

  handleTimeTypeChange({ timeType }) {
    this.params.time_type = timeType
    this.getData()
  }
}

