var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "接收人清单",
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "search-row",
          staticStyle: { "padding-bottom": "10px" }
        },
        [
          _c("el-switch", {
            attrs: { "active-text": "已读", "inactive-text": "未读" },
            on: {
              change: function($event) {
                return _vm.getList()
              }
            },
            model: {
              value: _vm.listQuery.if_read,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "if_read", $$v)
              },
              expression: "listQuery.if_read"
            }
          }),
          _c(
            "span",
            { staticStyle: { "margin-left": "50px", "margin-right": "10px" } },
            [_vm._v("是否回执")]
          ),
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              on: {
                change: function($event) {
                  return _vm.getList()
                }
              },
              model: {
                value: _vm.listQuery.if_receipt,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "if_receipt", $$v)
                },
                expression: "listQuery.if_receipt"
              }
            },
            _vm._l(_vm.ifReceiptMap, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { value: item.value, label: item.label }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "section",
        [
          _c("bsm-table", {
            attrs: {
              data: _vm.list,
              "show-setting": false,
              options: _vm.tableListOptions,
              "list-loading": _vm.listLoading,
              "show-index": true,
              height: "400"
            }
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page_query.index,
          limit: _vm.listQuery.page_query.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery.page_query, "index", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery.page_query, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }