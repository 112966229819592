import { riskScoreMsg } from '../constant'

export const getRiskScore = ({ percent }) => {
  const gradeList = [0.4, 0.6, 0.8, 1]
  if (percent === 0) {
    return riskScoreMsg[0]
  }
  const index = gradeList.findIndex(item => item >= percent)
  if (index > -1 && index <= (riskScoreMsg.length - 1)) {
    return riskScoreMsg[index]
  } else {
    return riskScoreMsg[riskScoreMsg.length - 1]
  }
}
