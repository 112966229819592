// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.waybill-risk-operation-contianer {\n  margin: 10px 0;\n}\n.waybill-risk-operation-contianer .operation-btn-container {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n}\n.waybill-risk-operation-contianer .operation-btn-container button {\n      margin: 8px;\n}\n.trial-result-dialog .trial-result-container .el-col {\n  margin: 16px 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
