







































import { Vue, Component, Prop } from 'vue-property-decorator'
import TableList from '../TableList/index.vue'
import {
  IWaybillPaymentData
} from '../index.d'

const waybillPaymentDefault: IWaybillPaymentData = {
  paymentDetailDtoList: [],
  payStatusAlias: '',
  customerShipFee: 0,
  driverShipFee: 0,
  consignorActualAmount: 0,
  carrierActualAmount: 0,
  informationDetailDto: {}
}

@Component({
  components: {
    TableList
  }
})
export default class extends Vue {
  @Prop({ default: () => waybillPaymentDefault }) waybillPaymentInfo;

  isShipperClient = window.location.host.indexOf('shipper') > -1

  settlementInfoOptions = [{
    field: 'settlementType',
    label: '结算类型'
  }, {
    field: 'payTime',
    label: '结算时间',
    filter: 'timeFilterShowSecond'
  },
  {
    field: 'counterParty',
    label: '交易对手'
  },
  {
    field: 'payTypeAlias',
    label: '结算方式'
  },
  {
    field: 'payerBankName',
    label: '付款方机构'
  },
  {
    field: 'bankSeriesNo',
    label: '支付机构流水号',
    type: 'link',
    config: {
      url: (url, row) => {
        return row.voucher
      },
      label: (l) => {
        return l
      }
    }
  },
  // {
  //   field: 'insuranceCompany',
  //   label: '保险公司'
  // }, {

  //   field: 'insuranceNo',
  //   label: '保险单号'
  // },
  {
    field: 'shippingFee',
    label: '支付费用(元)',
    filter: 'amountFormatFilter'
  },
  {
    field: 'contractNumber',
    label: '代收合同',
    type: 'link',
    config: {
      url: (url, row) => {
        return row.filePdfPath
      },
      label: (l) => {
        return l
      }
    }
  }

  ]
}

