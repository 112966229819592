var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "openimg-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.visible, title: "查看图片", width: "720px" },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c("div", [
            _c("div", { staticClass: "py-11 px-12 img-container" }, [
              _c("img", { attrs: { src: _vm.src } })
            ]),
            _c(
              "section",
              { staticClass: "x-center" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.handleClick } },
                  [_vm._v(" 关闭 ")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }