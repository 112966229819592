










































import { Component, Prop, Vue } from 'vue-property-decorator'
import settings from '@/settings'
import { isGreenSkin, isHaoyunbao } from '@/utils/common'

@Component({
  name: 'SidebarLogo'
})
export default class extends Vue {
  @Prop({ required: true }) private collapse!: boolean

  private title = settings.title
  isGreenSkin = isGreenSkin()
  isHaoyunbao = isHaoyunbao()
}
