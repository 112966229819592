



























import { Component, Vue } from 'vue-property-decorator'
import { getConsignorList } from '@/api/consignor'
import { IConsignorData, IGetListParams } from '@/api/types'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { resolveList } from '@/utils/common'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import { getEnumArray } from '@/common/utils'
import { riskScoreTypes } from '@/common/constant'

@Component({
  name: 'Consignor',
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  private list: IConsignorData[] = []
  private total = 0
  private listLoading = true
  private listQuery: IGetListParams = {
    page: {
      index: 1,
      limit: 10
    }
  }

  private queryFilterOptions = [...[{
    field: 'consignor',
    label: '名称或证件号码',
    main: true,
    component: MapSearchSelect
  }, {
    field: 'agent',
    label: '网络货运名称或证件号码',
    component: MapSearchSelect
  }, {
    field: 'carrier',
    label: '承运人名称或证件号码',
    component: MapSearchSelect
  }, {
    field: 'driver',
    label: '司机名称或证件号码',
    component: MapSearchSelect
  }, {
    field: 'vehicle',
    label: '车辆牌照号码',
    component: MapSearchSelect
  }, {
    field: 'startTime',
    label: '',
    hidden: true
  }, {
    field: 'endTime',
    label: '',
    hidden: true
  }].map(item => {
    return Object.assign({}, item, {
      config: {
        field: item.field
      }
    })
  }), {
    field: 'risk_score_types',
    label: '评分等级',
    type: 'select',
    config: {
      list: getEnumArray(riskScoreTypes),
      multiple: true
    }
  }, {
    field: ['risk_score_left', 'risk_score_right'],
    label: '评分分值',
    type: 'range'
  }]

  private tableListOptions = [{
    field: 'subject_id',
    type: 'link',
    label: '操作',
    config: {
      baseUrl: '/dataquery/consignor',
      label: '查看详情'
    },
    fixed: true
  }, {
    field: 'consignor_name',
    label: '名称',
    width: 220,
    fixed: true
  }, {
    field: 'consignor_identity',
    label: '证件号码',
    width: 200
  },
  {
    field: 'risk_score',
    label: '评分',
    align: 'center',
    filter: 'riskScore'
  },
  {
    field: 'risk_score_msg',
    label: '评分等级',
    align: 'center'
  },
  {
    field: 'waybill_count',
    label: '运单笔数',
    align: 'center'
  }, {
    field: 'expense',
    label: '运费支出(元)',
    align: 'center',
    width: 150,
    filter: 'amountFormatFilter'
  }, {
    field: 'agent_count',
    label: '网络货运企业数量',
    width: 150,
    align: 'center'
  }, {
    field: 'carrier_count',
    label: '承运人数量',
    align: 'center'
  }, {
    field: 'driver_count',
    label: '司机数量',
    align: 'center'
  }, {
    field: 'vehicle_count',
    label: '车辆数量',
    align: 'center'
  }, {
    field: 'first_time',
    label: '首笔运单时间',
    filter: 'timeFilter',
    width: 120,
    align: 'center'
  }, {
    field: 'invoice_count',
    label: '受票数量',
    align: 'center'
  }, {
    field: 'invoice_money_sum',
    label: '受票金额(元)',
    align: 'center',
    width: 150,
    filter: 'amountFormatFilter'
  }]

  created() {
    this.initListQuery()
    this.getList()
  }

  private initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
    this.listQuery.startTime = Number(this.$route?.query?.startTime) || ''
    this.listQuery.endTime = Number(this.$route?.query?.endTime) || ''
  }

  private async getList() {
    this.listLoading = true
    const { risk_score, ...listQuery } = this.listQuery
    if (risk_score) {
      listQuery.risk_score_left = risk_score[0] || ''
      listQuery.risk_score_right = risk_score[1] || ''
    }
    const { data } = await getConsignorList(listQuery)
    this.list = resolveList(data.items)
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }

  private handleFilter() {
    this.listQuery.page.index = 1
    this.getList()
  }

  private handelActionDetail({ id }) {
    this.$router.push({
      path: `consignor/${id}`
    })
  }
}

