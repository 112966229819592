






























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import UploadImage from '@/components/UploadImage/index.vue'
import { forcePassApply, rerunAntiFraud, forceRejectApply, trial } from '@/api/waybill'
import { IWaybillForceOperationParams, IAntiFraudTrailResultDTO } from '@/api/types'
import { uploadImage } from '@/api/file'
import {
  photoCompress,
  convertBase64UrlToFile
} from '@/utils/compress'
import { RiskLevel } from '@/common/constant'
@Component({
  components: {
    UploadImage
  }
})
export default class extends Vue {
[x: string]: any
  @Prop({ required: true }) waybillId!: string
  @Prop({ required: true }) isForced!: boolean
  @Prop({ required: true }) riskGrade!: number

  forcePassForm = {
    forcePassReasons: '',
    images: [],
    waybillId: '',
    forced: false
  }

  RiskLevel = RiskLevel

  rules = {
    forcePassReasons: [{ required: true, trigger: 'blur', message: '请输入强制通过理由' }]
  }

  dialogVisible = false
  trialDialogVisible = false
  isReject = false
  resetForcePassForm() {
    this.forcePassForm = {
      forcePassReasons: '',
      images: [],
      waybillId: '',
      forced: false
    }
  }

  riskLevelConfig:any = {}

  trialResult: IAntiFraudTrailResultDTO = {
    anti_fraud_rule_result_view_list: [],
    execute_end_time: 0,
    execute_start_time: 0,
    risk_grade: 0,
    waybill_id: 0
  }

  handleForceOperate(isReject: boolean) {
    if (this.isForced) {
      this.$message.error('该运单已强制通过/不通过，不允许调整')
      return
    }
    this.resetForcePassForm()
    this.forcePassForm.waybillId = this.waybillId
    this.forcePassForm.forced = this.isForced
    this.isReject = isReject
    this.dialogVisible = true
  }

  handleRecheckAntiFraud() {
    rerunAntiFraud({
      id: this.waybillId
    }).then(res => {
      const { data } = res
      this.$message.success('重新审核运单风险成功')
      this.$emit('update')
    })
  }

  handleTrail() {
    trial({
      id: this.waybillId
    }).then(res => {
      const { data } = res
      this.trialResult = data
      this.riskLevelConfig = RiskLevel[data.risk_grade]
      this.trialDialogVisible = true
    })
  }

  handleFileChange(files) {
    this.forcePassForm.images = files
  }

  async handleSubmitForcePass() {
    const images = this.forcePassForm.images
    const urls = []

    if (images.length > 3) {
      this.$message.error('强制通过或者拒绝最多上传3张照片')
      return
    }
    for (let i = 0, len = images.length; i < len; i++) {
      const d = new FormData()
      const imageFile = images[i].raw
      const base64 = await photoCompress(imageFile, { width: 1000, quality: 0.8 })
      const file = convertBase64UrlToFile(base64, imageFile.name)

      // limit image size to 2M
      if (file.size >= 2097152) {
        this.$message.error('图片大小应小于2M，请重新选择图片')
        return
      }
      d.append('pic', file)
      const { data } = await uploadImage(d)
      urls.push(data.resource_url)
    }

    if (!this.forcePassForm.forcePassReasons) {
      this.$message.error('请输入强制通过或者拒绝的理由')
      return
    }

    const params: IWaybillForceOperationParams = {
      waybill_id: this.forcePassForm.waybillId,
      photo: urls.join(','),
      reason: this.forcePassForm.forcePassReasons
    }
    try {
      if (this.isReject) {
        await forceRejectApply(params)
        this.$message.success('强制拒绝成功')
      } else {
        await forcePassApply(params)
        this.$message.success('强制通过成功')
      }
    } catch (e) {
      console.log(e)
    }

    this.dialogVisible = false
    this.$emit('update')
  }
}

