


































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

import Pagination from "@/common/components/Pagination/index.vue";
import SearchFilter from "@/common/business-components/SearchFilter/index.vue";
import BsmTable from "@/views/components/BsmTable.vue";
import MapSearchSelect from "@/views/components/TypeMap/map/MapSearchSelect.vue";
import MapSelect from "@/common/business-components/TypeMap/map/MapSelect.vue";
import MapDateRange from "@/common/business-components/TypeMap/map/MapDateRange.vue";
import { validateForm, getEnumArray, convertToExcel } from "@/common/utils";
import {
  InspectContentTypeMap,
  InspectTaskStatusMap,
  InspectLaunchTypeMap,
  InspectTaskStatus,
  agentType
} from "@/common/constant";
import InspectDetail from "@/common/business-components/Inspect/InspectDetail.vue";
import { TableList } from "@/common/business-components/";
import moment from "moment";

import {
  getInspectList,
  getInspectOperationRecordList,
  getInspectDetail,
  manualCreateInspect,
  inspectApprove,
  inspectRefuse,
  exportInspectList
} from "@/api/inspect";

@Component({
  name: "InspectTask",
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    MapSelect,
    MapSearchSelect,
    MapDateRange,
    InspectDetail,
    TableList
  },
  beforeRouteLeave: function(to, from, next) {
    this.inspectDetailVisible = false;
    next();
  }
})
export default class extends Vue {
  InspectTaskStatus = InspectTaskStatus;
  list: any = [];

  total = 0;
  listLoading = true;
  listQuery = {
    page_query: {
      index: 1,
      limit: 10
    }
  };

  searchTopForm: any = {
    did: "",
    sample_did: "",
    subject_type: "",
    sample_subject_type: ""
  };

  operationList: any = [];
  operationListTotal = 0;
  operationListQuery: any = {
    flow_id: "",
    page_query: {
      index: 1,
      limit: 5
    }
  };

  inspectContentTypeEnum: any = getEnumArray(InspectContentTypeMap);
  subjectTypeEnum: any = [
    {
      label: "托运人",
      value: "1"
    },
    {
      label: "网货企业",
      value: "2"
    },
    {
      label: "承运人",
      value: "3"
    },
    {
      label: "司机",
      value: "4"
    },
    {
      label: "车辆",
      value: "5"
    }
  ];

  sampleSubjectTypeEnum: any = [
    {
      label: "托运人",
      value: "1"
    },
    {
      label: "网货企业",
      value: "2"
    }
  ];

  subjectMap: any = {
    1: "consignor",
    2: "agent",
    3: "carrier",
    4: "driver",
    5: "vehicle"
  };

  currentInspectStatus: any = 0;
  manualInspectDialogVisible: any = false;
  inspectDetailVisible: any = false;
  paymentList: any = [];
  paymentDetail: any = {};
  trackList: any = [];
  resultFileList: any = [];
  inspectDetail: any = {};
  inspectDetailStrategyType: any = 3;
  inspectDetailFlowId: any = "";
  manualInspectForm: any = {
    subjectType: 2,
    agentDid: "",
    sample_subject_type: "",
    contentType: "",
    inspectCount: ""
  };

  manualInspectFormRule: any = {
    agentDid: [{ required: true, message: "请选择被抽检人", trigger: "blur" }],
    subjectType: [
      { required: true, message: "请选择抽检主体", trigger: "blur" }
    ],
    contentType: [
      { required: true, message: "请选择抽检内容", trigger: "blur" }
    ],
    inspectCount: [
      { required: true, message: "请输入抽检单数", trigger: "blur" },
      {
        pattern: /^([1-9][0-9]{0,1}|100)$/,
        message: "抽检单数必须是数字，且小于100",
        trigger: "blur"
      }
    ]
  };

  queryFilterOptions = [
    //   {
    //   field: 'did',
    //   label: '网络货运企业',
    //   component: MapSearchSelect,
    //   main: true
    // },
    {
      field: "waybill_no",
      label: "运单号码",
      main: true
    },
    {
      field: ["create_time_start", "create_time_end"],
      label: "抽检任务生成时间",
      type: "dateRange",
      main: true
    },
    {
      field: "status",
      label: "抽检任务状态",
      type: "select",
      config: {
        list: getEnumArray(InspectTaskStatusMap)
      },
      main: true
    },
    {
      field: "flow_no",
      label: "抽检单号",
      main: true
    },
    {
      field: "strategy_type",
      label: "抽检内容",
      type: "select",
      config: {
        list: getEnumArray(InspectContentTypeMap)
      },
      main: true
    },
    {
      field: "generate_type",
      label: "抽检发起类型",
      type: "select",
      config: {
        list: getEnumArray(InspectLaunchTypeMap)
      },
      main: true
    }
  ];

  tableListOptions = [
    {
      field: "id",
      type: "actionSetting",
      label: "操作",
      attrs: {
        actionOptions: [
          {
            label: "查看详情",
            type: "detail"
          }
        ]
      },
      fixed: true
    },
    {
      field: "flow_no",
      label: "抽检任务单号",
      width: 220,
      fixed: true
    },
    {
      field: "strategy_type_alias",
      label: "抽检内容",
      width: 200
    },
    {
      field: "generate_type_alias",
      label: "抽检发起类型",
      align: "center"
    },
    {
      field: "status_alias",
      label: "抽检任务状态"
    },
    {
      field: "subject_name",
      label: "被抽检人",
      filter(val, row) {
        return val + "（" + agentType[row.subject_type] + "）";
      }
    },
    {
      field: "sample_subject_name",
      label: "抽检处理人",
      filter(val, row) {
        return val + "（" + agentType[row.sample_subject_type] + "）";
      }
    },
    // {
    //   field: 'subject_name',
    //   label: '网络货运企业',
    //   width: 220,
    //   type: 'link',
    //   config: {
    //     url(val, row) {
    //       return `/dataquery/agent/${row.did}`
    //     }
    //   }
    // },
    {
      field: "waybill_no",
      label: "运单号",
      type: "link",
      config: {
        url(val, row) {
          return `/dataquery/waybill/${row.waybill_id}`;
        }
      }
    },
    {
      field: "create_time",
      label: "抽检任务生成时间",
      filter: "timeFilterShowSecond",
      width: 120,
      align: "center"
    },
    {
      field: "finish_time",
      label: "抽检任务完成时间",
      filter: "timeFilterShowSecond",
      width: 120,
      align: "center"
    },
    {
      field: "agent_name",
      label: "网货企业名称",
      width: 120
    },
    {
      field: "consignor_name",
      label: "托运人名称",
      width: 120
    }
  ];

  operationRecordOptions: any = [
    {
      field: "create_time",
      label: "操作时间",
      filter: "timeFilterShowSecond"
    },
    {
      field: "operate_advice",
      label: "操作意见"
    },
    {
      field: "operate_action_alias",
      label: "操作动作"
    },
    {
      field: "create_by",
      label: "操作人"
    }
  ];

  created() {
    this.initQueryParams();
    this.getList();
  }

  initQueryParams() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query);
      this.searchTopForm = Object.assign(
        {},
        this.searchTopForm,
        this.$route.query
      );
    }
  }

  async getList() {
    this.listLoading = true;
    const queryParams = Object.assign({}, this.listQuery, this.searchTopForm);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data } = await getInspectList(queryParams);
    this.list = data.items;
    this.total = data.total;
    await this.$nextTick();
    this.listLoading = false;
  }

  getOperationList() {
    const params = Object.assign(this.operationListQuery, {
      flow_id: this.inspectDetailFlowId
    });

    getInspectOperationRecordList(params).then(res => {
      const { data } = res;
      this.operationList = data.items;
      this.operationListTotal = data.total;
    });
  }

  handleFilter() {
    this.listQuery.page_query.index = 1;
    this.listQuery.page_query.limit = 10;
    this.getList();
  }

  handleChangeType(v) {
    console.log(v);
    Vue.set(this.manualInspectForm, "agentDid", "");
  }

  changeSearchSubjectType(v) {
    console.log(v);
    Vue.set(this.searchTopForm, "did", "");
  }

  changeSearchSampleSubjectType(v) {
    console.log(v);
    Vue.set(this.searchTopForm, "sample_did", "");
  }

  canOperated() {
    // Submited Refused Passed Return
    return (
      this.currentInspectStatus !== InspectTaskStatus.UnSubmit &&
      this.currentInspectStatus !== InspectTaskStatus.Passed &&
      this.currentInspectStatus !== InspectTaskStatus.Return &&
      this.currentInspectStatus !== InspectTaskStatus.Refused
    );
  }

  handleActionCommand(type: string, id: string, row: any) {
    this.inspectDetailFlowId = id;
    this.currentInspectStatus = row.status;
    this.getOperationList();
    getInspectDetail({ id: id }).then(res => {
      const { data } = res;
      this.paymentList =
        (data.payment_dto && data.payment_dto.paymentDetailDtoList) || [];
      this.paymentDetail = data.payment_dto || {};
      this.trackList = data.trajectory_dto_list || [];
      this.inspectDetail = data.sample_inspect_flow_dto || {};
      this.inspectDetailStrategyType = data.strategy_type;
      this.resultFileList = this.inspectDetail.resultFileUrls.split(",");
      this.inspectDetailVisible = true;
    });
  }

  async handleManualCreateInspect() {
    if (!(await validateForm(this.$refs.manualInspectForm))) {
      // this.$message.error('请检查表单数据！')
      return;
    }
    const params: any = {
      subject_type: this.manualInspectForm.subjectType,
      // subject_type: '2',
      sample_subject_type: this.manualInspectForm.sample_subject_type,
      did: this.manualInspectForm.agentDid,
      type: this.manualInspectForm.contentType,
      waybill_count: this.manualInspectForm.inspectCount
    };

    if (
      this.manualInspectForm.firstFinishTime &&
      this.manualInspectForm.firstFinishTime.length
    ) {
      params.create_time_end = this.manualInspectForm.firstFinishTime[1].getTime();
      params.create_time_start = this.manualInspectForm.firstFinishTime[0].getTime();
    }
    if (
      this.manualInspectForm.waybillCreateTime &&
      this.manualInspectForm.waybillCreateTime.length
    ) {
      params.waybill_create_time_end = this.manualInspectForm.waybillCreateTime[1].getTime();
      params.waybill_create_time_start = this.manualInspectForm.waybillCreateTime[0].getTime();
    }
    if (
      !(
        (this.manualInspectForm.waybillCreateTime &&
          this.manualInspectForm.waybillCreateTime.length) ||
        (this.manualInspectForm.firstFinishTime &&
          this.manualInspectForm.firstFinishTime.length)
      )
    ) {
      this.$message.error("请选择运单起始时间或者创建时间");
      return;
    }

    manualCreateInspect(params).then(res => {
      const { data } = res;
      if (data) {
        this.$message.success(`抽检成功，共生成${data}单抽检任务单`);
        this.manualInspectDialogVisible = false;
        this.getList();
      } else {
        this.$message.error("无满足抽检要求的运单，无法生成抽检任务单");
      }
    });
  }

  handleOperation(status: number) {
    if (!this.paymentDetail.approveAdvice) {
      this.$message.error("请输入审批意见！");
      return;
    }

    if (status === InspectTaskStatus.Refused) {
      inspectRefuse({
        approve_advice: this.paymentDetail.approveAdvice,
        flow_id: this.inspectDetailFlowId,
        status: status
      }).then(() => {
        this.$message.success("拒绝成功");
        this.inspectDetailVisible = false;
        this.getList();
      });
    } else {
      inspectApprove({
        approve_advice: this.paymentDetail.approveAdvice,
        flow_id: this.inspectDetailFlowId,
        status: status
      }).then(() => {
        this.$message.success("操作成功！");
        this.inspectDetailVisible = false;
        this.getList();
      });
    }
  }

  openManualInspectDialog() {
    this.manualInspectForm = {};
    this.manualInspectDialogVisible = true;
  }

  viewPic(url) {
    window.open(url, "_blank");
  }

  async exportExcel() {
    try {
      this.listLoading = true;
      const res = await exportInspectList(this.listQuery);
      const data = [res];
      const name = `抽检任务_${moment()
        .format("YYYY_MM_DD")
        .toString()}`;
      convertToExcel(data, name);
      this.listLoading = false;
    } catch (err) {
      this.listLoading = false;
    }
  }
}
