import request from "@/utils/request";

import {
  IGetRiskRuleListParams,
  IGetRiskRuleHistoryParams,
  IAddAndUpdateRiskRuleParams,
  IPage
} from "@/api/types";

export const getRiskRulesList = data =>
  request({
    url: "/anti-fraud/rule/list",
    method: "post",
    data
  });
export const getRiskRulesListByKey = data =>
  request({
    url: "/anti-fraud/rule/keyword",
    method: "post",
    data
  });
export const swithRuleStatus = (id: string) =>
  request({
    url: "/anti-fraud/rule/status/switch",
    method: "post",
    data: { id }
  });

export const getRulesHistory = (data: IGetRiskRuleHistoryParams) =>
  request({
    url: "/anti-fraud/rule/history/effective",
    method: "post",
    data
  });

export const getRuleDetail = (id: string) =>
  request({
    url: "/anti-fraud/rule",
    method: "post",
    data: { id }
  });

export const addRule = (data: IAddAndUpdateRiskRuleParams) =>
  request({
    url: "/anti-fraud/rule/add",
    method: "post",
    data: data
  });

export const addSchemeApi = (data: any) =>
  request({
    url: "/anti-fraud/scheme/add",
    method: "post",
    data: data
  });

export const addAgentSchemeApi = (data: any) =>
  request({
    url: "/anti-fraud/agent/scheme/rule-add",
    method: "post",
    data: data
  });

export const delAgentSchemeApi = (data: any) =>
  request({
    url: "/anti-fraud/agent/scheme/rule-del",
    method: "post",
    data: data
  });

export const deleteRule = (data: { id: string }) =>
  request({
    url: "/anti-fraud/rule/delete?id=" + data.id,
    method: "post",
    data: data
  });
export const updateRule = (data: IAddAndUpdateRiskRuleParams) =>
  request({
    url: "/anti-fraud/rule/update",
    method: "post",
    data: data
  });

export const getGeneralExecutor = () =>
  request({
    url: "/anti-fraud/general/executor",
    method: "post"
  });

export const getExceptionType = () =>
  request({
    url: "/anti-fraud/exception/types",
    method: "post"
  });

export const addActiveRule = (params: {
  effective_time: string | number;
  rule_id: string;
  agent_did: string;
}) =>
  request({
    url: "/effective-anti-fraud/rule/add",
    method: "post",
    data: params
  });
export const removeActiveRule = (params: { id: string }) =>
  request({
    url: "/effective-anti-fraud/rule/delete",
    method: "post",
    data: params
  });
export const getActiveRuleList = (params: {
  anti_fraud_rule_id: string;
  page: IPage;
  agent_did: string;
}) =>
  request({
    url: "/effective-anti-fraud/rule/list",
    method: "post",
    data: params
  });

// 新增风控模板规则

export const addRiskRuleToTemplate = (params: { rule_id: string }) =>
  request({
    url: "anti-fraud/template/add",
    method: "post",
    data: params
  });

export const updateRuleTemplate = (params: any) =>
  request({
    url: "anti-fraud/template/updateEffectiveTime",
    method: "post",
    data: params
  });

// 删除风控模板规则

export const removeRiskRuleFromTemplate = (params: { id: string }) =>
  request({
    url: "anti-fraud/template/delete",
    method: "post",
    data: params
  });

// 分页获取生效的风控板规则模列表

export const getActiveRiskRuleOfTemplate = (params: {
  anti_fraud_rule_id: string;
  page: IPage;
}) =>
  request({
    url: "anti-fraud/template/list",
    method: "post",
    data: params
  });

export const getActiveRiskRuleSchemeList = (params: any) =>
  request({
    url: "anti-fraud/scheme/list",
    method: "post",
    data: params
  });
export const getAgentRiskRuleSchemeList = (params: any) =>
  request({
    url: "anti-fraud/agent/scheme/list",
    method: "post",
    data: params
  });
export const editAgentRiskRuleSchemeList = (params: any) =>
  request({
    url: "anti-fraud/agent/scheme/edit",
    method: "post",
    data: params
  });
export const getAgentSchemeRiskRuleList = (params: any) =>
  request({
    url: "anti-fraud/agent/scheme/rule-list",
    method: "post",
    data: params
  });

export const getSchemeNoApi = (params: any) =>
  request({
    url: "anti-fraud/scheme/get",
    method: "post",
    data: params
  });

export const updateSchemeApi = (params: any) =>
  request({
    url: "anti-fraud/scheme/update",
    method: "post",
    data: params
  });

// 批量运单重过风险
export const antiFraudBatchRerun = (data: any) =>
  request({
    url: "waybill/rerun/batch",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
export const recallBatchRerun = (data: any) =>
  request({
    url: "waybill/recall/batch",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
export const brushSheetApi = (data: any) =>
  request({
    url: "waybill/brushSheet",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });

export const viewImplementationApi = (params: any) =>
  request({
    url: "anti-fraud/rule-item/view-implementation",
    method: "post",
    data: params
  });
