import request from '@/utils/request'

const path = 'request_log'

export const getSubWaybillLog = (params: {
    sub_waybill_id: string
    waybill_id: string
  }) =>
  request({
    url: `${path}/subWaybill`,
    method: 'post',
    data: params
  })

export const getWaybillLog = (params: {
    waybill_id: string
  }) =>
  request({
    url: `${path}/waybill`,
    method: 'post',
    data: params
  })
