var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "waybill-transport-container" }, [
    _c(
      "div",
      {
        staticClass: "content-title",
        staticStyle: { "font-weight": "500", color: "#4d4d4d" }
      },
      [_vm._v(" 运输信息 ")]
    ),
    _c("div", { staticClass: "content-header" }, [
      _c("span", [
        _vm._v(
          "运输状态：" + _vm._s(_vm.waybillTransportInfo.waybillStatusAlias)
        )
      ]),
      _c("span", [
        _vm._v("总车辆数：" + _vm._s(_vm.waybillTransportInfo.vehicleCount))
      ]),
      _c("span", [
        _vm._v(
          "总运费：" +
            _vm._s(
              _vm._f("amountFormatFilter")(
                _vm.waybillTransportInfo.customerShipFee
              )
            ) +
            "元"
        )
      ]),
      _c("span", [
        _vm._v(
          "预测运输距离：" +
            _vm._s(_vm.waybillTransportInfo.forecastTransportKm || 0) +
            "km"
        )
      ]),
      _c("span", [
        _vm._v(
          "上传运输距离：" +
            _vm._s(_vm.waybillTransportInfo.transportKm || 0) +
            "km"
        )
      ]),
      _c("span", [
        _vm._v(
          "上传轨迹数量：" +
            _vm._s(_vm.waybillTransportInfo.trajectoryNum || 0) +
            "条"
        )
      ])
    ]),
    _c(
      "div",
      { staticStyle: { position: "relative" } },
      [
        _c(
          "el-button",
          {
            staticStyle: {
              position: "absolute",
              right: "0",
              bottom: "8px",
              "font-size": "14px"
            },
            attrs: { type: "primary", plain: "", size: "small" },
            on: { click: _vm.handleClickTrack }
          },
          [_vm._v(" 查看追踪轨迹 ")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("table-list", {
          attrs: {
            data: _vm.waybillTransportInfo.subWaybillDtoList,
            options: _vm.subWaybillDtoListOptions,
            "max-height": "300",
            "show-setting": false
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }