var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-8" },
    [
      _c(
        "el-card",
        { attrs: { border: "" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSave }
                },
                [_vm._v(" " + _vm._s(_vm.isCreate ? "创建" : "保存") + " ")]
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.formData,
                    "label-width": "150px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: !_vm.editStatus },
                          on: { change: _vm.handleStatusRadio },
                          model: {
                            value: _vm.formData.status,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "status", $$v)
                            },
                            expression: "formData.status"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v(" 启用 ")
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v(" 禁用 ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "执行器类别", prop: "general_executor" }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: !_vm.isCreate },
                          on: { change: _vm.handleTypeChange },
                          model: {
                            value: _vm.formData.general_executor,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "general_executor", $$v)
                            },
                            expression: "formData.general_executor"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v(" 自定义 ")
                          ]),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v(" 通用 ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "税务规则类型", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择规则类型" },
                          on: { change: _vm.handleRuleTypeChange },
                          model: {
                            value: _vm.formData.type,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "type", $$v)
                            },
                            expression: "formData.type"
                          }
                        },
                        _vm._l(_vm.riskRuleType, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否需要申诉", prop: "if_appeal" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.formData.type !== 2 },
                          model: {
                            value: _vm.formData.if_appeal,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "if_appeal", $$v)
                            },
                            expression: "formData.if_appeal"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v(" 否 ")
                          ]),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v(" 是 ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否允许上报", prop: "allow_report" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formData.allow_report,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "allow_report", $$v)
                            },
                            expression: "formData.allow_report"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v(" 否 ")
                          ]),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v(" 是 ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否跨产业园", prop: "if_cross_park" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.formData.if_cross_park,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "if_cross_park", $$v)
                            },
                            expression: "formData.if_cross_park"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v(" 否 ")
                          ]),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v(" 是 ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规则类别", prop: "category" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择规则类别" },
                          model: {
                            value: _vm.formData.category,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "category", $$v)
                            },
                            expression: "formData.category"
                          }
                        },
                        _vm._l(_vm.riskRuleKind, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "异常类型", prop: "exception_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择规则类型" },
                          model: {
                            value: _vm.formData.exception_type,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "exception_type", $$v)
                            },
                            expression: "formData.exception_type"
                          }
                        },
                        _vm._l(_vm.exceptionTypes, function(item) {
                          return _c("el-option", {
                            key: item.exceptionType,
                            attrs: {
                              label: item.name,
                              value: item.exceptionType
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "风险分值", prop: "risk_score" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "194px" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.formData.risk_score,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "risk_score", $$v)
                          },
                          expression: "formData.risk_score"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务类型", prop: "business_mode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: true,
                            placeholder: "请选择规则类型"
                          },
                          model: {
                            value: _vm.formData.business_mode,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "business_mode", $$v)
                            },
                            expression: "formData.business_mode"
                          }
                        },
                        _vm._l(_vm.businessTypes, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "适用区域" } },
                    _vm._l(_vm.formData.areaList, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: { "padding-bottom": "8px" }
                        },
                        [
                          _c("area-list", {
                            attrs: {
                              "province-code": item.province,
                              "city-code": item.city,
                              "county-code": item.district,
                              index: index
                            },
                            on: {
                              onDistrictChange:
                                _vm.handleAgentRegisterDistrictChange
                            }
                          }),
                          index > 0
                            ? _c("el-button", {
                                staticStyle: { "margin-left": "8px" },
                                attrs: {
                                  type: "warning",
                                  plain: "",
                                  icon: "el-icon-minus"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.minusAreaList(index)
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("el-button", {
                            staticStyle: { "margin-left": "8px" },
                            attrs: { type: "default", icon: "el-icon-plus" },
                            on: { click: _vm.addAreaList }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "默认生效时间", prop: "effective_time" }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "194px" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期时间",
                          "default-value": Date.now(),
                          "value-format": "timestamp"
                        },
                        model: {
                          value: _vm.formData.effective_time,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "effective_time", $$v)
                          },
                          expression: "formData.effective_time"
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.isCreate
                    ? _c("el-form-item", { attrs: { label: "规则编号" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.formData.id))])
                      ])
                    : _vm._e(),
                  !_vm.isCreate
                    ? _c("el-form-item", { attrs: { label: "规则创建时间" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("timeFilter")(_vm.formData.create_time)
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  !_vm.isCreate
                    ? _c("el-form-item", { attrs: { label: "规则失效时间" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("timeFilter")(
                                _vm.formData.ineffective_time
                              )
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规则名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "496px" },
                        model: {
                          value: _vm.formData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规则描述", prop: "description" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "496px" },
                        model: {
                          value: _vm.formData.description,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.formData.general_executor
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "执行规则", prop: "command" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "496px" },
                            attrs: { disabled: _vm.isCreate ? false : true },
                            model: {
                              value: _vm.formData.command,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "command", $$v)
                              },
                              expression: "formData.command"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              !_vm.formData.general_executor
                ? _c(
                    "el-form",
                    {
                      ref: "commandForm",
                      attrs: {
                        rules: _vm.commandRules,
                        model: _vm.command,
                        "label-width": "150px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "执行函数名", prop: "executor" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "496px" },
                            attrs: { disabled: _vm.isCreate ? false : true },
                            model: {
                              value: _vm.command.executor,
                              callback: function($$v) {
                                _vm.$set(_vm.command, "executor", $$v)
                              },
                              expression: "command.executor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "失败描述", prop: "result_msg" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "496px" },
                            model: {
                              value: _vm.command.result_msg,
                              callback: function($$v) {
                                _vm.$set(_vm.command, "result_msg", $$v)
                              },
                              expression: "command.result_msg"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c("code-editor", {
            staticStyle: { height: "400px" },
            attrs: { "can-editor": _vm.canEdit, content: _vm.originContent },
            on: {
              "update:content": function($event) {
                _vm.originContent = $event
              },
              update: _vm.handleCodeChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }