var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "authority-tree" },
    [_c("tree-item", { attrs: { data: _vm.data } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }