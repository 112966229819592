









import { Vue, Component, Prop } from 'vue-property-decorator'
import config from './config'

@Component
export default class ImgIcons extends Vue {
  @Prop({
    default: ''
  }) name: string

  @Prop({
    default: '14px'
  }) width: string | number

  private get src() {
    return config[this.name]
  }
}

