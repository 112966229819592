






























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import ContainShowImg from '@/common/components/ContainShowImg/index.vue'
import { NoCacheLink } from '@/common/business-components/'
import { Description } from './Field'
import {
  getConsignorDetail,
  getAgentDetail,
  getCarrierDetail,
  getDriverDetail,
  getVehicleDetail
} from '@/api/subject'
import { SubjectTypeEnum } from '@/common/constant'
const SubjectType = {
  Person: 1,
  Enterprise: 2
}

@Component({
  name: 'SubjectDetailDialog',
  components: {
    ContainShowImg,
    NoCacheLink
  }
})
export default class extends Vue {
  @Prop() title: string
  @Prop() type: string
  @Prop() id: string
  @Prop({ default: false }) isButtonVisible: boolean
  @Prop() startTime:number
  @Prop() endTime:number
  @Prop() businessMode:number
  data: any = {}
  isDialogVisible = false
  typeDesc: any = {}
  subjectType: number

  created() {
    this.typeDesc = Description[this.type]
    SubjectTypeEnum.forEach(item => {
      if (this.type === item.field) {
        this.subjectType = item.value
      }
    })
  }

  isVisible(value: any, row: any, description: any) {
    if (!description) {
      return false
    }
    const hidden: any = description.hidden

    if (hidden instanceof Function) {
      return !hidden(value, row)
    }
    return !hidden
  }

  getSubjectDetail(id: string, type:string) {
    let func
    switch (type) {
      case 'consignor':
        func = getConsignorDetail
        break
      case 'agent':
        func = getAgentDetail
        break
      case 'carrier':
        func = getCarrierDetail
        break
      case 'driver':
        func = getDriverDetail
        break
      case 'vehicle':
        func = getVehicleDetail
        break
      default:
        break
    }

    func({ subject_id: id }).then(res => {
      const { data } = res
      if (data.type && data.type === SubjectType.Person) {
        this.data = data.personal_detail_view
      } else if (data.type && data.type === SubjectType.Enterprise) {
        this.data = data.enterprise_detail_view
      } else {
        this.data = this.objFun(data)
        console.log(this.data)
      }
      (!this.isDialogVisible) && (this.isDialogVisible = true)
    })
  }

  objFun(obj) {
    var res = {} // 定义一个对象，用来存储结果
    function isObj(obj, parentName = undefined) { // 定义一个函数，用来对obj进行遍历
      for (var key in obj) {
        if (Object.prototype.toString.call(obj[key]) === '[object Object]') { // 如果值为对象，则进行递归
          isObj(obj[key], key)
        } else { // 不为对象则将值添加给res
          parentName ? res[parentName + '_' + key] = obj[key] : res[key] = obj[key]
        }
      }
    }
    isObj(obj) // 调用函数
    return res // 返回结果
  }

  public showSubjectDetail() {
    this.getSubjectDetail(this.id, this.type)
  }

  handleLink(data, fieldConfig:any) {
    const type = fieldConfig.type
    switch (type) {
      case 'link_parent_agent':
        this.getSubjectDetail(data.parent_did, 'agent')
        break
      default:
        break
    }
  }
}

