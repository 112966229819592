import request from '@/utils/request'
//import { IGetListParams, IGetDashboardParams } from './types'

const path = 'role'



export const addRole = (params: {
  name: string
  resource_id_list: string[]
}) => request({
  url: `${path}/add`,
  method: 'post',
  data: params
})

export const getRoleConfig = () => request({
  url: `${path}/config`,
  method: 'post'
})

export const deleteRole = (params: { id: string }) => request({
  url: `${path}/delete`,
  method: 'post',
  data: params
})

export const getRoleList = (params) => request({
  url: `${path}/list`,
  method: 'post',
  data: params
})

export const getRole = (params: {
  id: string
}) => request({
  url: `${path}/get`,
  method: 'post',
  data: params
})

export const renameRole = (params: {
  id: string
  name: string
}) => request({
  url: `${path}/rename`,
  method: 'post',
  data: params
})

export const updateRoleAuthority = (params: {
  id: string
  resource_id_list: string[]
}) => request({
  url: `${path}/resource/update`,
  method: 'post',
  data: params
})

export const getRoleUserCount = (params: { id: string }) => request({
  url: `${path}/user/count`,
  method: 'post',
  data: params
})
