






















































































































































import { Component, Vue } from 'vue-property-decorator'
import { getApplyAccessList, approveAccess } from '@/api/access'
import { IApplyAccessData, IGetApplyAccessListParams } from '@/api/types'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { ApplyAccessTypeMap, ApplyAccessStatus, ApplyAccessStatusActionMap } from '@/common/constant'
import { getEnumArray } from '@/utils'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import { routeEnterprise } from '@/api/thirdPartyRoute'

@Component({
  name: 'ApplyAccessList',
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  private list: IApplyAccessData[] = []
  private passedList: IApplyAccessData[] = []
  private refusedList: IApplyAccessData[] = []
  private applyingList: IApplyAccessData[] = []
  private total = 0
  private totalApplying = 0
  private listLoading = true
  private activeName = 'applying'

  private applyingListQuery: IGetApplyAccessListParams = {
    state: ApplyAccessStatus.Applying,
    page: {
      index: 1,
      limit: 10
    }
  }

  private passedListQuery: IGetApplyAccessListParams = {
    state: ApplyAccessStatus.Passed,
    page: {
      index: 1,
      limit: 10
    }
  }

  private refusedListQuery: IGetApplyAccessListParams = {
    state: ApplyAccessStatus.Refused,
    page: {
      index: 1,
      limit: 10
    }
  }

  private disableListQuery: IGetApplyAccessListParams = {
    state: ApplyAccessStatus.Disable,
    page: {
      index: 1,
      limit: 10
    }
  }

  private logoutListQuery: IGetApplyAccessListParams = {
    state: ApplyAccessStatus.Logout,
    page: {
      index: 1,
      limit: 10
    }
  }


  private rejectReason = ''
  private currentState = ApplyAccessStatus.Applying

  private queryFilterOptions: any[] = [{
    field: 'agent_did',
    label: '企业名称或证件号码',
    main: true,
    component: MapSearchSelect
  }, {
    field: 'apply_type',
    label: '认证方式',
    type: 'select',
    main: true,
    config: {
      list: getEnumArray(ApplyAccessTypeMap)
    }
  }]

  private get actionOptions() {
    if (this.currentState === ApplyAccessStatus.Applying) {
      return [{
        label: '通过',
        type: ApplyAccessStatus.Pass
      }, {
        label: '拒绝',
        type: ApplyAccessStatus.Refused
      }]
    } else if (this.currentState === ApplyAccessStatus.Refused) {
      return [{
        type: ApplyAccessStatus.Void,
        label: ApplyAccessStatusActionMap[ApplyAccessStatus.Void]
      }, {
        type: ApplyAccessStatus.Logout,
        label: ApplyAccessStatusActionMap[ApplyAccessStatus.Logout]
      }]
    } else {
      return [{
        type: ApplyAccessStatus.Passed,
        label: ApplyAccessStatusActionMap[ApplyAccessStatus.Passed],
        disabled: () => {
          return this.currentState !== ApplyAccessStatus.Disable
        }
      }, {
        type: ApplyAccessStatus.Disable,
        label: ApplyAccessStatusActionMap[ApplyAccessStatus.Disable],
        disabled: (val, rowData) => {
          return !(this.currentState === ApplyAccessStatus.Passed && rowData.state === 9)
        }
      }, {
        type: ApplyAccessStatus.Void,
        label: ApplyAccessStatusActionMap[ApplyAccessStatus.Void],
        disabled: () => {
          return this.currentState === ApplyAccessStatus.Void || this.currentState === ApplyAccessStatus.Logout
        }
      }, {
        type: ApplyAccessStatus.Logout,
        label: ApplyAccessStatusActionMap[ApplyAccessStatus.Logout],
        disabled: () => {
          return this.currentState === ApplyAccessStatus.Void || this.currentState === ApplyAccessStatus.Logout
        }
      }, {
        type: 'agent_rule',
        label: '规则管理',
        disabled: () => {
          return this.currentState !== ApplyAccessStatus.Passed
        }
      },
      {
        type: 'history_rule',
        label: '历史规则',
        disabled: () => {
          return this.currentState !== ApplyAccessStatus.Passed
        }
      },
      {
        type: 'route_enterprise',
        label: '跳转企业端',
        disabled: () => {
          return this.currentState !== ApplyAccessStatus.Passed
        }
      }
      ]
    }
  }

  private get tableListOptions() {
    const defaultArr = [{
      field: 'apply_agent_name',
      label: '企业名称',
      fixed: true
    },
    { field: 'apply_type_name', label: '认证方式' },
    {
      field: 'join_type',
      label: '接入方式',
      filter: (v: number, row) => {
        return v === 1 ? '产业园' : '直连'
      }
    },
    {
      field: 'create_time',
      label: '申请时间',
      filter: 'timeFilter',
      hidden: () => {
        return !this.isApplying()
      }
    }, {
      field: 'freight_licence_issue_time',
      label: '网络货运牌照下发时间',
      filter: 'timeFilter'
    },
    {
      field: 'approve_time',
      label: '申请处理时间',
      filter: 'timeFilter',
      hidden: () => {
        return this.isApplying()
      }
    },
    { field: 'apply_social_credit_code', label: '企业社会信用代码' },
    {
      field: 'transport_license_pic',
      label: '道路运输证照片',
      type: 'link',
      config: {
        baseUrl: '',
        label: '查看图片'
      }
    },
    {
      field: 'apply_business_license_pic',
      label: '营业执照照片',
      type: 'link',
      config: {
        baseUrl: '',
        label: '查看图片'
      }
    },
    { field: 'parent_agent_name', label: '母公司名' }
    ]
    if (this.currentState === ApplyAccessStatus.Refused) {
      return defaultArr
    } else {
      return [{
        field: 'enterprise_did',
        type: 'actionSetting',
        label: '操作',
        width: 100,
        attrs: {
          actionOptions: this.actionOptions
        },
        fixed: true
      }, ...defaultArr]
    }
  }

  created() {
    this.getList(this.applyingListQuery)
  }

  private async getList(queryFilter) {
    this.listLoading = true
    const { data } = await getApplyAccessList(queryFilter)
    this.total = data.total
    this.list = data.items
    if (this.currentState === ApplyAccessStatus.Applying) {
      this.totalApplying = data.total
    }

    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  private handleFilter() {
    let listQuery: any = {}
    switch (this.currentState) {
      case ApplyAccessStatus.Applying:
        listQuery = this.applyingListQuery
        break
      case ApplyAccessStatus.Refused:
        listQuery = this.refusedListQuery
        break
      case ApplyAccessStatus.Passed:
        listQuery = this.passedListQuery
        break
      case ApplyAccessStatus.Disable:
        listQuery = this.disableListQuery
        break
      case ApplyAccessStatus.Logout:
        listQuery = this.logoutListQuery
        break
      default:
        listQuery = this.applyingListQuery
        break
    }
    listQuery.page.index = 1
    this.getList(listQuery)
  }

  private handleClickTab(comp) {
    const { name } = comp
    switch (name) {
      case 'applying':
        this.currentState = ApplyAccessStatus.Applying
        break
      case 'refused':
        this.currentState = ApplyAccessStatus.Refused
        break
      case 'passed':
        this.currentState = ApplyAccessStatus.Passed
        break
      case 'disable':
        this.currentState = ApplyAccessStatus.Disable
        break
      case 'logout':
        this.currentState = ApplyAccessStatus.Logout
        break
      default:
        break
    }
    this.handleFilter()
  }

  private isApplying() {
    return this.currentState === ApplyAccessStatus.Applying
  }


  private handleActionCommand(action, id, row) {
    let title = ''
    let message = ''
    let confirmFn = null
    let inputPlaceholder = ''
    switch (action) {
      case ApplyAccessStatus.Pass:
        title = '此操作将通过该企业申请, 是否继续?'
        message = '通过成功!'
        confirmFn = this.$confirm
        break
      case ApplyAccessStatus.Refused:
        title = '此操作将拒绝该企业申请, 是否继续?'
        message = '拒绝成功!'
        confirmFn = this.$prompt
        inputPlaceholder = '请输入拒绝理由'
        break
      case ApplyAccessStatus.Void:
        title = `该操作不可撤销，确认标记${row.apply_agent_name}-${row.apply_social_credit_code}的接入状态为作废？`
        message = '操作成功，状态已经更新为作废'
        confirmFn = this.$confirm
        break
      case ApplyAccessStatus.Logout:
        title = `该操作不可撤销，确认标记${row.apply_agent_name}-${row.apply_social_credit_code}的接入状态为牌照注销？`
        message = '操作成功，状态已经更新为牌照注销'
        confirmFn = this.$confirm
        break
      case ApplyAccessStatus.Passed:
        message = '操作成功，状态已更新为启用'
        break
      case ApplyAccessStatus.Disable:
        message = '操作成功，状态已更新为禁用'
        break
      case 'agent_rule':
        this.$router.push({
          path: `active_rule/${id}`,
          query: {
            name: row.apply_agent_name
          }
        })

        return
      case 'history_rule':
        this.$router.push({
          path: `history/${id}`,
          query: {
            name: row.apply_agent_name
          }
        })
        return
      case 'route_enterprise':
        routeEnterprise({ id: id }).then(({ data }) => {
          window.open(data.url + '?token=' + data.token, '_blank')
        })
        return
      default:
    }
    if (!confirmFn) {
      approveAccess({
        enterprise_did: id,
        state: action
      }).then(() => {
        this.$message({
          type: 'success',
          message: message
        })
        this.handleFilter()
      })
    } else if (confirmFn === this.$confirm) {
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        approveAccess({
          enterprise_did: id,
          state: action
        }).then(() => {
          this.$message({
            type: 'success',
            message: message
          })
          this.handleFilter()
        })
      }).catch((e) => {
        console.log(e)
      })
    } else if (confirmFn === this.$prompt) {
      this.$prompt(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        inputPlaceholder: inputPlaceholder
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      }).then((res: any) => {
        const { value } = res
        if (!value) {
          this.$message.error(inputPlaceholder)
          return false
        }
        approveAccess({
          enterprise_did: id,
          state: action,
          reject_reason: value
        }).then(() => {
          this.$message({
            type: 'success',
            message: message
          })
          this.handleFilter()
        })
      }).catch((e) => {
        console.log(e)
      })
    }
  }
}

