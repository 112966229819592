

























































































































































































































































// tax_push_confirm_apply
import { Component, Vue } from 'vue-property-decorator'
import {
  applyConfirm,
  applyReject,
  applyCompare,
  getApplyHandledList,
  getApplyUnhandledList,
  findSubawybillIds
} from '@/api/tax'
import { IGetInvoiceApplyListParams, IInvoiceApplyData } from '@/api/types'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import { TagsViewModule } from '@/store/modules/tags-view'
import NoCacheLink from '@/common/business-components/NoCacheLink.vue'

@Component({
  name: 'InvoiceApplyConfirm',
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    NoCacheLink
  }
})
export default class extends Vue {
  list: IInvoiceApplyData[] = []
  total = 0
  totalApplying = 0
  listLoading = true
  activeName = 'applying'
  applyDetail: any = {}
  applyDetailDialogVisible = false
  invoiceAmountSelect = 0

  applyingListQuery: IGetInvoiceApplyListParams = {
    page: {
      index: 1,
      limit: 10
    }
  }

  handledListQuery: IGetInvoiceApplyListParams = {
    page: {
      index: 1,
      limit: 10
    }
  }

  rejectReason = ''

  queryFilterOptions: any[] = [{
    field: 'agent_did',
    label: '网络货运企业',
    main: true,
    component: MapSearchSelect
  }, {
    field: 'carrier_did',
    label: '开票主体',
    component: MapSearchSelect,
    main: true,
    config: {
      field: 'carrier'
    }
  }, {
    field: ['applyTimeRangeStart', 'applyTimeRangeEnd'], // 'applyTimeRange',
    label: '开票申请时间',
    type: 'dateRange',
    main: true
  }, {
    field: 'apply_no',
    label: '开票申请编号',
    main: true
  }]


  tableListDefaultOptions = [
    {
      field: 'agent_name',
      label: '网络货运企业'
    },
    {
      field: 'carrier_name',
      label: '开票主体'
    },
    {
      field: 'confirm_no',
      label: '确认编号'
    },
    {
      field: 'create_time',
      label: '申请时间',
      filter: 'timeFilter'
    },
    {
      field: 'item_count',
      label: '运单/子运单笔数'
    },
    {
      field: 'invoice_type_name',
      label: '申请发票类型'
    },
    {
      field: 'invoice_category_name',
      label: '发票种类'
    },
    {
      field: 'carrier_paid_amount',
      label: '总运费',
      filter: 'amountFormatFilter'
    },
    {
      field: 'tax_included_amount',
      label: '申请开票金额',
      filter: 'amountFormatFilter'
    },
    {
      field: 'apply_no',
      label: '开票申请编号',
      fixed: true,
      type: 'linkLike',
      config: {
        label(val) {
          return val
        },
        handle: (val, row) => {
          this.linkToSubWbillHandle(val, row)
        }
      }
    }
  ]

  get unProcessedTableOptions() {
    return [{
      field: 'tax_push_id',
      type: 'buttons',
      label: '操作',
      config: {
        options: [{
          label: '申请确认',
          action: 'pass',
          type: 'primary'
        }]
      },
      // hidden: () => {
      //   return !this.isApplying()
      // },
      fixed: true
    }, ...this.tableListDefaultOptions]
  }

  get hasProcessedTableOptions() {
    return [{
      field: 'tax_push_id',
      type: 'buttons',
      label: '查看',
      config: {
        options: [{
          label: '查看详情',
          action: 'view',
          type: 'primary'
        }]
      },
      // hidden: () => {
      //   return this.isApplying()
      // },
      fixed: true
    }, ...this.tableListDefaultOptions, {
      field: 'confirm_status_name',
      label: '风控处理状态'
      // hidden: () => {
      //   return this.isApplying()
      // }
    }]
  }

  get computeListQuery() {
    let query: any = {}
    if (this.isApplying()) {
      query = Object.assign({}, this.applyingListQuery)
    } else {
      query = Object.assign({}, this.handledListQuery)
    }
    return query
  }

  noCacheLinkBeforeHook(to) {
    this.applyDetailDialogVisible = false
  }

  async linkToSubWbill(id) {
    const { data } = await findSubawybillIds(id)
    if (data) {
      const view = (this.$router as any).matcher.match('/dataquery/sub_waybill')
      await TagsViewModule.delCachedView(view)
      this.$router.push({
        path: '/dataquery/sub_waybill',
        query: {
          subWaybillIds: data
        }
      })
    }
  }

  async linkToSubWbillHandle(val, row) {
    this.linkToSubWbill(row.tax_push_id)
  }


  created() {
    this.getApplyList()
  }

  async getApplyList() {
    this.listLoading = true
    const { data } = await getApplyUnhandledList(this.computeListQuery)
    this.total = data.total
    this.list = data.items

    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  async getHandledList() {
    this.listLoading = true
    const { data } = await getApplyHandledList(this.computeListQuery)
    this.total = data.total
    this.list = data.items

    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  handleFilter() {
    if (this.isApplying()) {
      this.applyingListQuery.page.index = 1
      this.getApplyList()
    } else {
      this.handledListQuery.page.index = 1
      this.getHandledList()
    }
  }

  handleClickTab() {
    this.handleFilter()
  }

  isApplying() {
    return this.activeName === 'applying'
  }

  handleClick(action, id, row) {
    applyCompare({
      id: id
    }).then(res => {
      this.applyDetail = res.data
      this.invoiceAmountSelect = res.data.choose_way || 1
      this.applyDetailDialogVisible = true
    })
  }

  viewTaxRate() {
    this.applyDetailDialogVisible = false
    this.$router.push({
      path: '/tax/tax_rate'
    })
  }

  viewWaybill() {
    this.applyDetailDialogVisible = false
    let path = ''
    const query: any = {}

    if (this.applyDetail.invoice_type === 2) {
      query.subWaybillIds = this.applyDetail.sub_waybill_ids
      path = '/dataquery/sub_waybill'
    } else {
      query.waybillIds = this.applyDetail.waybill_ids
      path = '/dataquery/waybill'
    }

    this.$router.push({
      path: path,
      query: query
    })
  }

  handleSubmit(isConfirm: boolean) {
    if (isConfirm) {
      this.$confirm(`此操作将选择${(this.invoiceAmountSelect !== 2) ? '网络货运企业申请金额' : '风控计算金额'}作为开票金额, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        applyConfirm({
          id: this.applyDetail.tax_push_id,
          choice: this.invoiceAmountSelect
        }).then(res => {
          this.$message({
            type: 'success',
            message: '确认成功!'
          })
          this.applyDetailDialogVisible = false
          this.handleFilter()
        })
      }).catch((e) => {
        console.log(e)
      })
    } else {
      this.$confirm('此操作将拒绝开票申请, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        applyReject({
          id: this.applyDetail.tax_push_id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '拒绝成功!'
          })
          this.applyDetailDialogVisible = false
          this.handleFilter()
        })
      }).catch((e) => {
        console.log(e)
      })
    }
  }
}

