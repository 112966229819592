




























import { Vue, Component } from 'vue-property-decorator'

@Component
export default class ShowImg extends Vue {
  private visible = false
  private src = 'img/empty.jpeg'

  public open(src: string) {
    if (src) {
      this.loadImg(src)
    }
    this.visible = true
  }

  private loadImg(src) {
    const img = new Image()
    img.onload = () => {
      this.src = src
    }
    img.onerror = () => {
      console.log(`图片：${this.src}加载失败`)
    }
    img.src = src
  }

  private close() {
    this.visible = false
  }

  private handleClick() {
    this.visible = false
  }

  private handleClose() {
    this.src = 'img/empty.jpeg'
  }
  // @Prop({
  //   default: false,
  //   required: true
  // }) visible: Boolean

  // @Prop({
  //   default: '',
  //   required: true
  // }) src: string
}

