export const Storage:IStorage = {
  getItem(key) {
    let data = window.localStorage.getItem(key)
    try {
      data = JSON.parse(data)
      return data
    } catch (e) {
      return data
    }
  },
  setItem(key, data) {
    if (typeof data == 'object') {
      data = JSON.stringify(data)
    }
    window.localStorage.setItem(key, data)
  },
  removeItem(key) {
    window.localStorage.removeItem(key)
  },
  clear() {
    window.localStorage.clear()
  }
}


export interface IStorage {
  getItem: Function
  setItem: Function
  removeItem?: Function
  clear ? : Function
}
