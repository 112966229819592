import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const taxRoutes: RouteConfig = {
  path: '/system',
  component: Layout,
  name: 'System',
  meta: {
    title: '系统设置',
    icon: 'systemSetting',
    alwaysShow: true,
    roles: ['system_config']
  },
  children: [{
    path: 'role/list',
    name: 'RoleList',
    component: () => import('@/views/role/index.vue'),
    meta: {
      title: '角色管理',
      // noCache: true,
      roles: ['role_list_query']
    }
  },
  {
    path: 'authority/:id',
    name: 'Authority',
    component: () => import('@/views/role/authority.vue'),
    meta: {
      title: '角色权限管理',
      hidden: true,
      // noCache: true,
      roles: ['role_add', 'role_rename', 'role_resource_update']
    }
  },
  {
    path: 'user',
    name: 'User',
    component: () => import('@/views/system/user/index.vue'),
    meta: {
      title: '用户管理',
      // noCache: true,
      roles: ['user_list']
    }
  },
  {
    path: 'action-log',
    name: 'ActionLog',
    component: () => import('@/views/system/action-log/index.vue'),
    meta: {
      title: '用户操作日志',
      // noCache: true,
      roles: ['action_log']
    }
  },
  {
    path: 'provider/list',
    name: 'ProviderList',
    component: () => import('@/views/system/provider/index.vue'),
    meta: {
      title: '认证服务商管理',
      // noCache: true,
      roles: ['auth_provider_get']
    }
  },
  {
    path: 'identify-type/list',
    name: 'IdentifyTypeList',
    component: () => import('@/views/system/identify-type/index.vue'),
    meta: {
      title: '认证方式管理',
      // noCache: true,
      roles: ['certificate_type']
    }
  }
  ]
}

export default taxRoutes
