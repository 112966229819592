























































































import { Component, Vue } from 'vue-property-decorator'
import {
  getLogList,
  getEnumActionLog,
  getEnumSubActionLog
} from '@/api/users'
import { IAgentData } from '@/api/types'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import MapFuzzySelect from '@/views/components/TypeMap/map/MapFuzzySelect.vue'
import AreaListAsync from '@/components/AreaListAsync/index.vue'

@Component({
  name: 'User',
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    AreaListAsync
  }
})
export default class extends Vue {
  list: IAgentData[] = []
  roleList: any = []
  addressData: any = {}
  total = 0
  listLoading = true

  optionsList=[]
  optionsList2=[]
  listQuery = {
    type: '',
    sub_type: '',
    page: {
      index: 1,
      limit: 10
    }
  }

  actionLog:any = '{}'

  showDialog = false

  queryFilterOptions: any[] = [{
    field: 'operator',
    label: '操作人',
    component: MapFuzzySelect,
    main: true
  }, {
    field: ['start_time', 'end_time'],
    label: '操作时间',
    type: 'dateRange',
    main: true
  }]

  tableListOptions = [{
    field: 'id',
    type: 'actionSetting',
    label: '操作',
    align: 'center',
    attrs: {
      actionOptions: [
        {
          type: 'detail',
          label: '详情'
        }
      ]
    },
    fixed: true
  }, {
    field: 'operator',
    label: '操作人'
  }, {
    field: 'type',
    label: '操作类型',
    filter: (v, row) => {
      return row.type + '-' + row.subType
    }
  }, {
    field: 'time',
    label: '操作时间',
    align: 'center',
    filter: 'timeFilterShowSecond'
  }]

  created() {
    this.getDataList()
    this.getList()
  }

  async getDataList() {
    const res = await getEnumActionLog()
    this.optionsList = res.data || []
  }

  async getList() {
    this.listLoading = true
    const { data } = await getLogList(this.listQuery)
    this.list = data.items
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }

  handleFilter() {
    this.listQuery.page.limit = 10
    this.getList()
  }

  async handleChange(val) {
    if (val) {
      this.listQuery.sub_type = ''
      const res = await getEnumSubActionLog({ code: val })
      this.optionsList2 = res.data || []
    } else {
      this.optionsList2 = []
    }
  }

  handelActionDetail(action, id, row) {
    if (action === 'detail') {
      this.actionLog = row.param
      this.showDialog = true
    }
  }
}

