var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-input",
    _vm._g(
      _vm._b(
        {
          style: { width: _vm.width + "px" },
          attrs: { clearable: "" },
          model: {
            value: _vm.proxyVal,
            callback: function($$v) {
              _vm.proxyVal = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "proxyVal"
          }
        },
        "el-input",
        _vm.$attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }