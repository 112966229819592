var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "span",
        {
          staticStyle: {
            "margin-bottom": "20px",
            display: "inline-block",
            "font-size": "16px",
            "font-weight": "bold",
            color: "#686868"
          }
        },
        [_vm._v(_vm._s(_vm.agent_name))]
      ),
      _c("search-filter", {
        attrs: { options: _vm.queryFilterOptions, "type-default": "input" },
        on: { search: _vm.handleFilter },
        model: {
          value: _vm.listQuery,
          callback: function($$v) {
            _vm.listQuery = $$v
          },
          expression: "listQuery"
        }
      }),
      _c(
        "section",
        [
          _c("bsm-table", {
            attrs: {
              name: "riskrule-history",
              data: _vm.list,
              options: _vm.tableListOptions,
              "list-loading": _vm.listLoading,
              "show-index": true
            },
            on: { "action-detail": _vm.handelActionDetail }
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page.index,
          limit: _vm.listQuery.page.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery.page, "index", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery.page, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }