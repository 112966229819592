




























































import { Component, Vue } from 'vue-property-decorator'
import { update } from '@/api/users'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import UploadImage from '@/components/UploadImage/index.vue'
import { UserModule } from '@/store/modules/user'
import ContainShowImg from '@/common/components/ContainShowImg/index.vue'

import { uploadImage } from '@/api/file'

@Component({
  name: 'UserSetting',
  components: {
    UploadImage,
    ContainShowImg
  }
})
export default class extends Vue {
  userForm = {
    avatar: UserModule.avatar,
    nickname: UserModule.nickname,
    phone: UserModule.phone,
    username: UserModule.username,
    rolename: UserModule.roleName
  }

  loadding = false

  async update() {
    await UserModule.Update(this.userForm)
    this.$message.success('更新图片成功')
  }

  async handleFileChange(file) {
    var formData = new FormData()
    formData.append('pic', file.raw, file.name)
    this.loadding = true
    try {
      const { data } = await uploadImage(formData)
      this.userForm.avatar = data.resource_url
    } finally {
      this.loadding = false
    }
  }
}

