var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "120px",
            size: "small"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block" },
              attrs: { label: "通知类型", prop: "notice_type" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", disabled: _vm.isDetail() },
                  on: { change: _vm.changeNoticeType },
                  model: {
                    value: _vm.form.notice_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "notice_type", $$v)
                    },
                    expression: "form.notice_type"
                  }
                },
                _vm._l(_vm.notice_type_map, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { value: item.value, label: item.label }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block", "margin-left": "20px" },
              attrs: { label: "接收人类型", prop: "subject_type" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", disabled: _vm.isDetail() },
                  on: { change: _vm.changeSubjectType },
                  model: {
                    value: _vm.form.subject_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "subject_type", $$v)
                    },
                    expression: "form.subject_type"
                  }
                },
                _vm._l(_vm.subject_type_map, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { value: item.value, label: item.label }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block", "margin-left": "20px" },
              attrs: { label: "是否需要回执", prop: "feedback_type" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择",
                    disabled: _vm.isDetail() || _vm.isPublicNotice
                  },
                  model: {
                    value: _vm.form.feedback_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "feedback_type", $$v)
                    },
                    expression: "form.feedback_type"
                  }
                },
                _vm._l(_vm.feedback_type_map, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { value: item.value, label: item.label }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block" },
              attrs: { label: "发送人", prop: "sender" }
            },
            [
              _c("el-input", {
                staticClass: "select-receiver",
                attrs: {
                  placeholder: _vm.industrialParkName,
                  disabled: _vm.isDetail()
                },
                model: {
                  value: _vm.form.sender,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sender", $$v)
                  },
                  expression: "form.sender"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "接收人" } },
            [
              !_vm.isDetail()
                ? _c(
                    "el-select",
                    {
                      staticClass: "select-receiver",
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: _vm.isDetail() || _vm.isPublicNotice,
                        multiple: "",
                        filterable: "",
                        remote: "",
                        "default-first-option": "",
                        placeholder: "默认全产业园，点击此处搜索企业发送",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.queryLoading
                      },
                      model: {
                        value: _vm.form.agent_did_list,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "agent_did_list", $$v)
                        },
                        expression: "form.agent_did_list"
                      }
                    },
                    _vm._l(_vm.receiverList, function(item) {
                      return _c("el-option", {
                        key: item.conditionKey,
                        attrs: {
                          label: item.conditionVal,
                          value: item.conditionKey
                        }
                      })
                    }),
                    1
                  )
                : _c("el-input", {
                    attrs: { disabled: _vm.isDetail() },
                    model: {
                      value: _vm.form.receiver_type_alias,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "receiver_type_alias", $$v)
                      },
                      expression: "form.receiver_type_alias"
                    }
                  })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "通知标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isDetail(),
                  maxlength: "64",
                  placeholder: "请输入通知标题"
                },
                model: {
                  value: _vm.form.title,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "附件", prop: "file" } },
            [
              _c("multi-upload-file", {
                attrs: {
                  "file-list": _vm.fileList,
                  limit: 5,
                  disabled: _vm.isDetail()
                },
                model: {
                  value: _vm.form.fileUrls,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "fileUrls", $$v)
                  },
                  expression: "form.fileUrls"
                }
              }),
              !_vm.isDetail()
                ? _c("section", { staticClass: "text-danger" }, [
                    _vm._v(" 支持图片、doc、xls格式文件上传，5M以内，最多5个 ")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "通知内容", prop: "content" } },
            [
              _vm.isDetail()
                ? _c("div", {
                    staticClass: "textarea-content",
                    domProps: { innerHTML: _vm._s(_vm.form.content) }
                  })
                : _c("tinymce", {
                    attrs: { height: "calc(100vh - 550px)" },
                    model: {
                      value: _vm.form.content,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content"
                    }
                  })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { "text-align": "center" } },
            [
              !_vm.isDetail()
                ? _c("el-button", { on: { click: _vm.handlePreview } }, [
                    _vm._v(" 取消 ")
                  ])
                : _vm._e(),
              !_vm.isDetail()
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["notice_create"],
                          expression: "['notice_create']"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.showTimeDialog }
                    },
                    [_vm._v(" 定时创建 ")]
                  )
                : _vm._e(),
              !_vm.isDetail()
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["notice_create"],
                          expression: "['notice_create']"
                        }
                      ],
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit(1)
                        }
                      }
                    },
                    [_vm._v(" 创建 ")]
                  )
                : _vm._e(),
              !!_vm.isDetail() &&
              (_vm.form.status === 2 || _vm.form.status === 3)
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["notice_void"],
                          expression: "['notice_void']"
                        }
                      ],
                      attrs: { type: "danger", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.handleNoticeVoid()
                        }
                      }
                    },
                    [_vm._v(" 作废 ")]
                  )
                : _vm._e(),
              _vm.isDetail() && _vm.form.status === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["notice_refuse"],
                          expression: "['notice_refuse']"
                        }
                      ],
                      attrs: { type: "warning", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.handleNoticeRefuse()
                        }
                      }
                    },
                    [_vm._v(" 拒绝 ")]
                  )
                : _vm._e(),
              _vm.isDetail() && _vm.form.status === 1
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["notice_send"],
                          expression: "['notice_send']"
                        }
                      ],
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.handleNoticeSend()
                        }
                      }
                    },
                    [_vm._v(" 确认发送 ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "定时创建",
            visible: _vm.dialogVisible,
            width: "500px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "section",
            [
              _c("p", [_vm._v("选择定时创建的时间：")]),
              _c("el-date-picker", {
                attrs: { type: "datetime", placeholder: "选择日期时间" },
                model: {
                  value: _vm.timingTimeDesc,
                  callback: function($$v) {
                    _vm.timingTimeDesc = $$v
                  },
                  expression: "timingTimeDesc"
                }
              }),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !!_vm.timingTimeDesc,
                      expression: "!!timingTimeDesc"
                    }
                  ]
                },
                [
                  _vm._v(
                    " 点击确定后，本通知将在 " +
                      _vm._s(_vm.momentTime(_vm.timingTimeDesc)) +
                      " 创建 "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }