






























































import { Component, Vue } from 'vue-property-decorator'
import Todo, { ITodo } from './Todo.vue'

const STORAGE_KEY = 'todos'
const filters: { [ key: string ]: Function } = {
  all: (todos: ITodo[]) => todos,
  active: (todos: ITodo[]) => todos.filter(todo => !todo.done),
  completed: (todos: ITodo[]) => todos.filter(todo => todo.done)
}
const defalutList = [
  { text: 'star this repository', done: false },
  { text: 'fork this repository', done: false },
  { text: 'follow author', done: false },
  { text: 'vue-typescript-admin-template', done: true },
  { text: 'vue', done: true },
  { text: 'element-ui', done: true },
  { text: 'axios', done: true },
  { text: 'webpack', done: true }
]

@Component({
  name: 'TodoList',
  components: {
    Todo
  },
  filters: {
    pluralize: (n: number, s: string) => n === 1 ? s : s + 's',
    capitalize: (s: string) => s.charAt(0).toUpperCase() + s.slice(1)
  }
})
export default class extends Vue {
  private visibility = 'all'
  private todos = defalutList

  get allChecked() {
    return this.todos.every(todo => todo.done)
  }

  get filters() {
    return filters
  }

  get filteredTodos() {
    return filters[this.visibility](this.todos)
  }

  get remaining() {
    return this.todos.filter(todo => !todo.done).length
  }

  private setLocalStorage() {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(this.todos))
  }

  private addTodo(e: KeyboardEvent) {
    const text = (e.target as HTMLInputElement).value
    if (text.trim()) {
      this.todos.push({
        text,
        done: false
      })
      this.setLocalStorage()
    }
    (e as any).target.value = ''
  }

  private toggleTodo(todo: ITodo) {
    todo.done = !todo.done
    this.setLocalStorage()
  }

  private deleteTodo(todo: ITodo) {
    this.todos.splice(this.todos.indexOf(todo), 1)
    this.setLocalStorage()
  }

  private editTodo({ todo, value }: { todo: ITodo, value: string }) {
    todo.text = value
    this.setLocalStorage()
  }

  private clearCompleted() {
    this.todos = this.todos.filter(todo => !todo.done)
    this.setLocalStorage()
  }

  private toggleAll({ done }: { done: boolean }) {
    this.todos.forEach(todo => {
      todo.done = done
      this.setLocalStorage()
    })
  }
}
