import request from '@/utils/request'

const path = 'third-party/router'

export const routeEnterprise = (params: { id: string }) =>{
    return request({
        url: `${path}/enterprise`,
        method: 'post',
        data: params
      })
}
