import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const dataQueryRoutes: RouteConfig = {
  path: '/dataquery',
  component: Layout,
  name: 'DataQuery',
  meta: {
    title: '数据查询',
    icon: 'dataQuery',
    alwaysShow: true,
    roles: ['data_query']
  },
  children: [{
    path: 'overview',
    name: 'Overview',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      title: '数据概览',
      affix: true,
      noCache: true,
      roles: ['overview']
    }
  },
  {
    path: 'quick_bi',
    name: 'quickBi',
    component: () => import('@/views/dataquery/bi/index.vue'),
    meta: {
      title: '即席查询',
      roles: ['quick_bi']
    }
  },
  {
    path: 'agent',
    name: 'Agent',
    component: () => import('@/views/dataquery/agent/index.vue'),
    meta: {
      title: '网络货运企业查询',
      roles: ['agent_query']
    }
  },
  {
    path: 'agent/:id',
    name: 'AgentDetail',
    component: () => import('@/views/dataquery/agent/detail.vue'),
    meta: {
      title: '网络货运企业详情',
      hidden: true,
      noCache: true,
      roles: ['agent_detail']
    }
  }, {
    path: 'agent_rank',
    name: 'AgentRank',
    component: () => import('@/views/dataquery/agent_rank/index.vue'),
    meta: {
      title: '网络货运企业排名',
      roles: ['agent_sort']
    }
  },
  {
    path: 'consignor',
    name: 'Consignor',
    component: () => import('@/views/dataquery/consignor/index.vue'),
    meta: {
      title: '托运人查询',
      roles: ['consignor_query']
    }
  },
  {
    path: 'consignor/:id',
    name: 'ConsignorDetail',
    component: () => import('@/views/dataquery/consignor/detail.vue'),
    meta: {
      title: '托运人详情',
      hidden: true,
      noCache: true,
      roles: ['consignor_detail']
    }
  },
  {
    path: 'carrier',
    name: 'Carrier',
    component: () => import('@/views/dataquery/carrier/index.vue'),
    meta: {
      title: '承运人查询',
      roles: ['carrier_query']
    }
  },
  {
    path: 'carrier/:id',
    name: 'CarrierDetail',
    component: () => import('@/views/dataquery/carrier/detail.vue'),
    meta: {
      title: '承运人详情',
      hidden: true,
      noCache: true,
      roles: ['carrier_detail']
    }
  },
  {
    path: 'driver',
    name: 'Driver',
    component: () => import('@/views/dataquery/driver/index.vue'),
    meta: {
      title: '司机查询',
      roles: ['driver_query']
    }
  },
  {
    path: 'driver/:id',
    name: 'DriverDetail',
    component: () => import('@/views/dataquery/driver/detail.vue'),
    meta: {
      title: '司机详情',
      hidden: true,
      noCache: true,
      roles: ['driver_detail']
    }
  },
  {
    path: 'vehicle',
    name: 'Vehicle',
    component: () => import('@/views/dataquery/vehicle/index.vue'),
    meta: {
      title: '车辆查询',
      roles: ['vehicle_query']
    }
  },
  {
    path: 'vehicle/:id',
    name: 'VehicleDetail',
    component: () => import('@/views/dataquery/vehicle/detail.vue'),
    meta: {
      title: '车辆详情',
      hidden: true,
      noCache: true,
      roles: ['vehicle_detail']
    }
  },
  {
    path: 'waybill',
    name: 'Waybill',
    component: () => import('@/views/dataquery/waybill/index.vue'),
    meta: {
      title: '运单明细查询',
      // noCache: true,
      roles: ['waybill_query']
    }
  },
  {
    path: 'waybill/:id',
    name: 'WaybillDetail',
    component: () => import('@/views/dataquery/waybill/detail.vue'),
    meta: {
      title: '运单详情',
      hidden: true,
      noCache: true,
      roles: ['waybill_detail']
    }
  },
  {
    path: 'sub_waybill',
    name: 'SubWaybill',
    component: () => import('@/views/dataquery/sub-waybill/index.vue'),
    meta: {
      title: '分段分单查询',
      roles: ['waybill_query']
    }
  },
  {
    path: 'sub_waybill/:id',
    name: 'SubWaybillDetail',
    component: () => import('@/views/dataquery/sub-waybill/detail.vue'),
    meta: {
      title: '分段分单详情',
      hidden: true,
      noCache: true,
      roles: ['waybill_detail']
    }
  },
  {
    path: 'track',
    name: 'Track',
    component: () => import('@/views/dataquery/track/index.vue'),
    meta: {
      title: '轨迹',
      hidden: true,
      noCache: true,
      roles: ['waybill_trajectory']
    }
  }
  //   {
  //   path: 'invalid_waybill',
  //   name: 'InvalidWaybill',
  //   component: () => import('@/views/dataquery/invalid-waybill/index.vue'),
  //   meta: {
  //     title: '作废运单查询',
  //     roles: ['void_waybill_list']
  //   }
  // }
  ]
}

export default dataQueryRoutes
