



import { Line } from '@antv/g2plot'

import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResizeMixin from './mixins/resize1'
// import moment from 'moment'

@Component({
  name: 'TinyLineChart'
})
export default class extends mixins(ResizeMixin) {
  @Prop({ default: 'tiny-chart' }) private className!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: '100%' }) private height!: string

  @Prop({ default: 'time' }) private type!: 'linear' | 'time' | 'cat' | 'log' | 'pow'
  @Prop({ default: '' }) private description!: string
  @Prop({ default: 'type' }) private seriesField!: string
  @Prop() private yFilter: string
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  }

  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.destroy()
    this.chart = null
  }

  private initChart() {
    const meta: any = {}
    let that = this
    meta[this.xField] = {
      range: [0.1, 0.90]
    }
    meta[this.xField] = {
      range: [0, 1]
    }

    meta[this.yField] = {
      formatter: (v) => {
        if (that.yFilter) {
          return that.$options.filters[that.yFilter](v, 2)
        } else {
          return v
        }
      }
    }
    const config: any = {
      autoFit: true,
      meta: meta,
      data: this.data,
      xField: this.xField,
      yField: this.yField,
      xAxis: {
        type: this.type,
        line: null,
        label: null,
      },
      padding: [3, 0, 3, 104],
      yAxis: {
        grid: null,
        label: null
      },
      legend: {
        position: 'left-center',
        visible: true,
        offsetX: -10
      },
      seriesField: this.seriesField,
      responsive: true,
      smooth: true,
      tooltip: {
        offset: 10,
        shared: false,
        domStyles: {
          'g2-tooltip-value': {
            marginLeft: '8px'
          }
        },
      },
    }
    this.chart = new Line(this.$refs.chart as HTMLElement, config)
    this.chart.render()
  }
}

