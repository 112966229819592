
































import { Component, Vue } from 'vue-property-decorator'
import { IRole } from '@/api/types'
import { getRoleList, deleteRole, renameRole, getRoleUserCount, getRole } from '@/api/role'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { UserModule } from '@/store/modules/user'


export const typeMap = {
  1: '关键策略',
  2: '中等策略',
  3: '一般策略'
}



@Component({
  name: 'RoleList',
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  list: IRole[] = []
  total = 0
  listLoading = true
  listQuery = {
    page: {
      index: 1,
      limit: 10
    }
  }

  tableListOptions = [{
    field: 'role_name',
    label: '角色名称',
    width: 300,
    fixed: true
  }, {
    field: 'immutable',
    label: '可否修改',
    width: 300,
    filter: (immutable) => {
      if (immutable) {
        return '否'
      } else {
        return '是'
      }
    }

  }, {
    type: 'buttons',
    width: 80,
    label: ' ',
    config: {
      options: [{
        label: '修改权限',
        action: 'edit',
        type: 'primary'
      }, {
        label: '重命名',
        action: 'renname',
        type: 'normal'
      }, {
        label: '删除',
        action: 'delete',
        type: 'danger'
      }]
    }
  // }, {
  //   type: 'buttons',
  //   width: 80,
  //   label: ' ',
  //   config: {
  //     options: [{
  //       label: '重命名',
  //       action: 'renname',
  //       type: 'normal'
  //     }]
  //   }
  // }, {
  //   type: 'buttons',
  //   width: 80,
  //   label: ' ',
  //   config: {
  //     options: [{
  //       label: '删除',
  //       action: 'delete',
  //       type: 'danger'
  //     }]
  //   }
  }]

  created() {
    this.getList()
  }

  async getList() {
    this.listLoading = true
    const { data } = await getRoleList(this.listQuery)
    this.list = data.items
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }

  handleFilter() {
    this.listQuery.page.limit = 10
    this.getList()
  }

  async handelActionDetail(id: string) {
    if (id === 'create') {
      this.$router.push({
        path: `/system/authority/${id}`
      })
    } else {
      const { data } = await getRole({
        id: id
      })

      if (this.isInclude(data.resource_ids, UserModule.roleResourceIds)) {
        this.$router.push({
          path: `/system/authority/${id}`
        })
      } else {
        this.$message.error('无权限修改该数据')
      }
    }
  }


  isInclude(aa, bb) {
    return aa.every((item) => {
      return bb.some((sub) => {
        return sub === item
      })
    })
  }


  handleButtonAction(actionType: string, id: string, row: any) {
    if (actionType === 'edit') {
      this.handelActionDetail(row.id)
    } else if (actionType === 'delete') {
      this.handleDelete(row.id)
    } else if (actionType === 'renname') {
      this.handleRename(row.id)
    }
  }

  handleAddRole() {
    this.handelActionDetail('create')
  }

  handleDelete(id: string) {
    getRoleUserCount({
      id: id
    }).then(res => {
      const { data } = res
      this.$confirm(`当前该角色有${data.user_count}位用户使用，是否确认删除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteRole({ id: id }).then(res => {
          this.$message.success('删除角色成功')
          this.getList()
        })
      }).catch(e => {
        console.log(e)
      })
    })
  }

  handleRename(id: string) {
    this.$prompt('此操作将重命名角色名称', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      inputPattern: /^\S{1,6}$/,
      inputErrorMessage: '角色名为1~6位非空字符',
      inputPlaceholder: '请输角色的新名称'
    }).then((res: any) => {
      const { value } = res
      if (!value) {
        this.$message.error('请输角色名称')
      }
      renameRole({
        id: id,
        name: value
      }).then(res => {
        this.$message.success('角色重命名成功!')
        this.getList()
      })
    }).catch((e) => {
      console.log(e)
    })
  }
}

