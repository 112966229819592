import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const taxRoutes: RouteConfig = {
  path: '/manage',
  component: Layout,
  name: 'CompositeManage',
  meta: {
    title: '综合管理',
    alwaysShow: true,
    icon: 'model',
    roles: ['integrated_management']
  },
  children: [{
    path: 'individual-invoice',
    name: 'Individual',
    component: () => import('@/views/system/individual/index.vue'),
    meta: {
      title: '个体户开票',
      roles: ['self_employed_management']
    }
  }]
}

export default taxRoutes
