
































import { Component, Vue } from 'vue-property-decorator'
// import { update } from '@/api/users'
// import Pagination from '@/common/components/Pagination/index.vue'
// import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
// import BsmTable from '@/views/components/BsmTable.vue'
import UploadImage from '@/components/UploadImage/index.vue'
import { UserModule } from '@/store/modules/user'
import ContainShowImg from '@/common/components/ContainShowImg/index.vue'
import AuthorityTree from '@/views/components/AuthorityTree/index.vue'
import { updateRoleAuthority, addRole, getRole } from '@/api/role'

const config = {
  label: 'description',
  isLeaf: (data, node) => {
    return node.level === 3
  }
}

@Component({
  name: 'Authority',
  components: {
    UploadImage,
    ContainShowImg,
    AuthorityTree
  }
})

export default class extends Vue {
  data: any = []
  defaultProps: any = config

  checkedCities = []
  roleId: any = ''
  name = ''
  resourceIds = []
  created() {
    this.roleId = this.$route.params.id
    this.init()
  }

  isEdit() {
    return this.roleId !== 'create'
  }

  async init() {
    if (this.isEdit()) {
      const { data } = await getRole({
        id: this.roleId
      })
      this.resourceIds = data.resource_ids
    }

    this.data = {
      level: 0,
      children: this.addLevelsAndCheckState(UserModule.roleTree.children, 1)
    }
  }

  addLevelsAndCheckState(data, level) {
    if (data.length) {
      data.forEach(d => {
        d.level = level
        d.checked = this.isEdit() ? this.resourceIds.includes(d.id) : d.default_access
        if (d.children && d.children.length) {
          d.children = this.addLevelsAndCheckState(d.children, level + 1)
        }
      })
    }
    return data
  }

  getCheckedIds(data) {
    let temp = []
    const children = data.children

    if (children && children.length) {
      children.forEach(d => {
        if (d.checked) {
          console.log(d.description + ':' + 'checked')
          temp.push(d.id)
        }
        temp = temp.concat(this.getCheckedIds(d))
      })
    }
    return temp
  }

  handleSaveChange() {
    const ids = this.getCheckedIds(this.data)

    if (this.isEdit()) {
      updateRoleAuthority({
        id: this.roleId,
        resource_id_list: ids
      }).then(res => {
        console.log(res)
        this.$message.success('更新权限成功')
        this.$router.push('/system/role/list')
      })
    } else {
      if (!this.name) {
        this.$message.error('请输入角色名称')
        return
      }

      addRole({
        name: this.name,
        resource_id_list: ids
      }).then(res => {
        console.log(res)
        this.$message.success('添加角色成功')
        this.$router.push('/system/role/list')
      })
    }
  }
}

