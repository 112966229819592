

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'UploadImage'
})
export default class extends Vue {
  @Prop({ default: '' }) private value!: string
  @Prop({ default: 'multi' }) private mode: string
  @Prop({ default: 'picture-card' }) private listType: string
  @Prop({
    default: function() {
      return []
    }
  }) fileList: any

  @Prop({ default: 0 }) private limit: number

  @Prop({ default: 1024 * 1024 * 5 }) maxSize: number

  private files = []

  private get maxSizeLabel() {
    const sizeLabelList = [{
      value: 1024 * 1024 * 1024,
      label: 'GB'
    }, {
      value: 1024 * 1024,
      label: 'MB'
    }, {
      value: 1024,
      label: 'B'
    }]
    let sizeLabel = sizeLabelList.find(item => (this.maxSize / item.value) >= 1)
    if (!sizeLabel) {
      sizeLabel = sizeLabelList[2]
    }
    return `${Math.round((this.maxSize / sizeLabel.value) * 100) / 100}${sizeLabel.label}`
  }

  created() {
    this.fileListChange(this.fileList)
  }

  @Watch('fileList', {
    deep: true
  })
  fileListChange(files: any) {
    const temp = []
    files.forEach(f => {
      if (f) {
        temp.push({
          url: f
        })
      }
    })
    this.files = temp
  }


  private emitInput() {
    this.$emit('input', this.files)
  }

  private handleFileChange(res, fileList) {
    const size = res.size
    if (size > this.maxSize) {
      this.$message.error(`文件大小不能超过 ${this.maxSizeLabel}`)
      fileList.splice(fileList.length - 1, 1)
    } else {
      if (this.mode === 'single') {
        this.files = [res]
      } else {
      // this.files = this.files.concat([res])
        this.files.push(res)
      }
      this.emitInput()
    }
  }

  private handleFileRemove(res) {
    this.files = this.files.filter(item => item.uid !== res.uid)
    this.emitInput()
  }

  private handleExceed(file, fileList) {
    // console.log(file, fileList)
    this.$message.error('文件上传数量超过最大限制')
  }

  private handleBeforeUpload(file) {
    // const size = file.size
    // if (size > this.maxSize) {
    //   this.$message.error(`文件大小不能超过 ${this.maxSizeLabel}`)
    //   return false
    // } else {
    //   return true
    // }
  }
}

