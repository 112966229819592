var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "destroy-on-close": true,
            "show-close": false,
            visible: _vm.showDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "font-500",
              attrs: { slot: "title" },
              slot: "title"
            },
            [_vm._v(" 规则实施情况 ")]
          ),
          _c("div", { attrs: { slot: "" }, slot: "default" }, [
            _c(
              "div",
              {
                staticClass: "pb-4",
                staticStyle: { "border-bottom": "1px solid #cfcece" }
              },
              [
                _c(
                  "el-row",
                  { staticClass: "mt-4" },
                  [
                    _c("el-col", { attrs: { span: 8 } }, [
                      _vm._v(" 规则编号：" + _vm._s(_vm.formData.rule_id))
                    ]),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _vm._v(" 规则名称：" + _vm._s(_vm.formData.rule_name))
                    ])
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "mt-4" },
                  [
                    _c("el-col", { attrs: { span: 8 } }, [
                      _vm._v(" 规则类型：" + _vm._s(_vm.formData.type))
                    ]),
                    _c("el-col", { attrs: { span: 8 } }, [
                      _vm._v(" 是否需要申诉：" + _vm._s(_vm.formData.if_appeal))
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c("div", { staticClass: "mt-4" }, [
                  _vm._v("关联方案：" + _vm._s(_vm.formData.scheme_names))
                ]),
                _c("div", { staticClass: "mt-4" }, [
                  _vm._v("适用区域：" + _vm._s(_vm.address || "-"))
                ]),
                _c("div", { staticClass: "mt-4" }, [_vm._v("附加企业：")]),
                _c(
                  "el-table",
                  {
                    staticClass: "mt-4",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.formData.add_agent_scheme, border: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "agent_name",
                        label: "企业名称"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "scheme_name",
                        label: "风控方案"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "mt-4" }, [_vm._v("停用企业：")]),
                _c(
                  "el-table",
                  {
                    staticClass: "mt-4",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.formData.stop_agent_scheme, border: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "agent_name",
                        label: "企业名称"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "scheme_name",
                        label: "风控方案"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticStyle: { display: "flex", "justify-content": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [_c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")])],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }