



import { Area } from '@antv/g2plot'
import { Component, Prop, InjectReactive } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResizeMixin from './mixins/resize1'
import moment from 'moment'

const themes = {
  blue: {
    color: '#1677FF',
    area: {
      fill: 'l(90) 0:#4F7DF9 1:#ffffff',
      lineWidth: 0
    }
  },
  yellow: {
    color: '#FF8700',
    area: {
      fill: 'l(90) 0:#FF9800 1:#ffffff',
      lineWidth: 0
    }
  },
  green: {
    color: '#17BB89',
    area: {
      fill: 'l(90) 0:#31DC85 1:#ffffff',
      lineWidth: 0
    }
  }
}

@Component({
  name: 'LineAreaChart'
})
export default class extends mixins(ResizeMixin) {
  @Prop({ default: 'line-area-chart' }) private className!: string;
  @Prop({ default: '100%' }) private width!: string;
  @Prop({ default: '100%' }) private height!: string;
  @Prop({ default: 'blue' }) private color!: string;

  @InjectReactive({ from: 'dashboard', default: 'MM.DD' }) timeFormat!: string

  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  }

  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.destroy()
    this.chart = null
  }

  private initChart() {
    const that = this
    const theme = themes[this.color]
    const meta = {}
    const tickType: any = 'timeCat'
    meta[this.yField] = {
      alias: '数量',
      formatter: (v) => {
        return v.toString()
      },
      range: [0.1, 0.90]
    }
    meta[this.xField] = {
      range: [0, 1]
    }

    this.chart = new Area(
      this.$refs.chart as HTMLElement, {
        // title: {
        //   text: '',
        //   visible: false
        // },
        meta: meta,
        data: this.data,
        xField: this.xField,
        yField: this.yField,
        padding: [10, 0, 40, 0],
        color: theme.color,
        xAxis: {
          type: tickType,
          tickInterval: 1,
          label: {
            autoRotate: false,
            formatter: function(val, b, c) {
              if (b.value === 1 || b.value === 0) {
                return ''
              }else{
                  return moment(Number(b.id)).format(that.timeFormat).toString()
              }

            },

            // position: 'center',
            style: {
              fontSize: 12,
              // textAlign: 'right',
              lineHeight: 17,
              fill: '#999999'
            }
          },
          line: null,
          tickLine: null,
          grid: null,
        },
        yAxis: false,
        tooltip: {
          offset: 10,
          domStyles: {
            'g2-tooltip-value': {
              marginLeft: '8px'
            }
          }
        },
        areaStyle: theme.area,
        smooth: true
      }
    )
    this.chart.render()
  }
}

