var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "section",
        [
          _c("bsm-table", {
            attrs: {
              "show-setting": false,
              name: "role",
              data: _vm.list,
              options: _vm.tableListOptions,
              "list-loading": _vm.listLoading,
              "show-index": true
            },
            on: { "button-action": _vm.handleButtonAction }
          })
        ],
        1
      ),
      _c(
        "pagination",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total>0"
            }
          ],
          attrs: {
            total: _vm.total,
            page: _vm.listQuery.page.index,
            limit: _vm.listQuery.page.limit
          },
          on: {
            "update:page": function($event) {
              return _vm.$set(_vm.listQuery.page, "index", $event)
            },
            "update:limit": function($event) {
              return _vm.$set(_vm.listQuery.page, "limit", $event)
            },
            pagination: _vm.getList
          }
        },
        [
          _c(
            "template",
            { slot: "end" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleAddRole }
                },
                [_vm._v(" 创建 ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }