import ShowImgList from '@/components/ShowImgList/index.vue'

let vm
const install = function(Vue: Vue.VueConstructor) {
  Vue.prototype.$showImgList = function(srcs: string[]) {
    if (!vm) {
      vm = new ShowImgList({ parent: this.$root }).$mount()
      this.$root.$el.appendChild(vm.$el)
    }
    return vm.open(srcs)
  }
}

export default {
  install
}
