import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const noteRoutes: RouteConfig = {
  path: '/notice',
  component: Layout,
  name: 'Notice',
  meta: {
    title: '通知管理',
    alwaysShow: true,
    icon: 'pushMonitor',
    roles: ['notice_manage']
  },
  children: [{
    path: 'manage',
    name: 'NoteManage',
    component: () => import('@/views/note/manage/index.vue'),
    meta: {
      title: '通知列表',
      affix: true,
      roles: ['notice_list', 'notice_page', 'notice_detail']
    }
  }, {
    path: 'detail/:id',
    name: 'NoteDetail',
    component: () => import('@/views/note/manage/detail.vue'),
    meta: {
      title: '通知管理',
      hidden: true,
      roles: ['notice_create']
    }
  }]
}

export default noteRoutes
