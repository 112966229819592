import request from "@/utils/request";
import {
  IGetServiceApplyListParams,
  IServiceApplyConfirmParams
} from "./interface/access";

const path = "serve_apply";

export const getServiceApplyDetail = (params: any) =>
  request({
    url: `${path}/get_invoice_config_detail`,
    method: "post",
    data: params
  });

export const confirmServiceApply = (params: any) =>
  request({
    url: `${path}/invoice_config_confirm`,
    method: "post",
    data: params
  });

export const rejectServiceApply = (params: {
  serveApplyId: string;
  refuseReason?: string;
}) =>
  request({
    url: `${path}/invoice_config_reject`,
    method: "post",
    data: params
  });

export const getServiceApplyList = (params: IGetServiceApplyListParams) =>
  request({
    url: `${path}/list`,
    method: "post",
    data: params
  });

export const exportExcel = (params: IGetServiceApplyListParams) =>
  request({
    url: `${path}/export_excel`,
    method: "post",
    data: params,
    responseType: "blob"
  });

export const enableStateUpdate = (params: any) =>
  request({
    url: `${path}/enable_state_update`,
    method: "post",
    data: params
  });
