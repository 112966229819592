// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.tree-item .group-level-2 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.tree-item .item-group {\n  display: block;\n  overflow: hidden;\n  height: 0;\n}\n.tree-item .item-group.expanded {\n    height: auto;\n}\n.tree-item.tree-item-0 > .item-checkbox {\n  display: none;\n}\n.tree-item.tree-item-0 .el-icon-caret-right {\n  display: none;\n}\n.tree-item.tree-item-1 {\n  padding: 10px;\n}\n.tree-item.tree-item-1:hover {\n    background-color: #F5F7FA;\n}\n.tree-item.tree-item-1 .el-checkbox__inner {\n    height: 18px;\n    width: 18px;\n}\n.tree-item.tree-item-1 .el-checkbox__inner:after {\n      height: 9px;\n      left: 6px;\n}\n.tree-item.tree-item-2 {\n  padding-bottom: 10px;\n}\n.tree-item.tree-item-2 .el-checkbox__inner {\n    height: 16px;\n    width: 16px;\n}\n.tree-item.tree-item-2 .el-checkbox__inner:after {\n      height: 8px;\n      left: 5px;\n}\n.tree-item.tree-item-3 .el-checkbox__inner {\n  height: 14px;\n  width: 14px;\n}\n.tree-item.tree-item-3 .el-checkbox__inner:after {\n    height: 7px;\n    left: 4px;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
