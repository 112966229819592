var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "table-list-container" },
    [
      _vm.showSetting
        ? _c(
            "section",
            { staticClass: "py-4 pt-3 flex" },
            [
              _vm._t("action"),
              _c(
                "span",
                {
                  staticClass: "flex x-center",
                  staticStyle: {
                    "margin-left": "auto",
                    "align-items": "flex-end",
                    "font-size": "14px",
                    color: "#4d4d4d"
                  }
                },
                [
                  _c("span", {
                    staticClass: "el-icon-s-tools",
                    staticStyle: { cursor: "pointer", "margin-top": "2px" },
                    on: { click: _vm.showColumnSetting }
                  }),
                  _c("span", { staticClass: "ml-2" }, [_vm._v("表格配置")])
                ]
              )
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-table",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "multipleTable",
              attrs: {
                fit: "",
                data: _vm.data,
                height: _vm.inTableHeight,
                "highlight-current-row": ""
              }
            },
            "el-table",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm.showSelction
            ? _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "50",
                  align: "center",
                  fixed: _vm.indexFixed
                }
              })
            : _vm._e(),
          _vm.showIndex
            ? _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  width: "50",
                  align: "center",
                  fixed: _vm.indexFixed
                }
              })
            : _vm._e(),
          _vm._l(_vm.fields, function(option, index) {
            return [
              (typeof option.hidden === "function"
                ? !option.hidden()
                : !option.hidden) && !option.originType
                ? _c(
                    "af-table-column",
                    _vm._b(
                      {
                        key: index,
                        attrs: {
                          prop: option.field
                            ? option.field.indexOf(".").length
                              ? option.field
                              : option.field.split(".")[0]
                            : option.field,
                          label: option.label,
                          "min-width": option.width,
                          width: option.type === "actionSetting" ? 50 : "",
                          align: option.align || "left",
                          fixed: option.fixed,
                          fit: option.noFit,
                          type: option.originType
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !option.type &&
                                  !option.componentName &&
                                  !option.component
                                    ? _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.dealValue(
                                              _vm.resolveGetter(
                                                scope.row,
                                                option.field
                                              ),
                                              scope.row,
                                              option.filter,
                                              option.filterParams
                                            )
                                          )
                                        }
                                      })
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "type-map",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    "component-type":
                                                      option.type,
                                                    component: option.component,
                                                    "component-name":
                                                      option.componeName,
                                                    "row-data": scope.row,
                                                    config: option.config
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row[option.field],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        option.field,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[option.field]"
                                                  }
                                                },
                                                "type-map",
                                                option.attrs,
                                                false
                                              ),
                                              _vm.$listeners
                                            )
                                          )
                                        ],
                                        1
                                      )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      "af-table-column",
                      option.cloumnAttrs,
                      false
                    )
                  )
                : _vm._e(),
              option.originType
                ? _c("af-table-column", {
                    key: index,
                    attrs: {
                      "min-width": option.width,
                      type: option.originType
                    }
                  })
                : _vm._e()
            ]
          })
        ],
        2
      ),
      _vm._t("default"),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.settingDialogVisible, title: "表格配置" },
          on: {
            "update:visible": function($event) {
              _vm.settingDialogVisible = $event
            },
            close: _vm.closeSetting
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.subLoading,
                  expression: "subLoading"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "table-setting-field-container" },
                [
                  _c("draggable-column-setting", {
                    attrs: {
                      list1: _vm.displayFields,
                      list2: _vm.hiddenFields,
                      "disabled-list": _vm.fixedFields,
                      "list1-title": "展示栏",
                      "list2-title": "隐藏栏"
                    }
                  })
                ],
                1
              ),
              _c(
                "section",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleColumnChange }
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c("el-button", { on: { click: _vm.handleCloseSetting } }, [
                    _vm._v(" 取消 ")
                  ])
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }