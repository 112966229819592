var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "draggable-column-setting" }, [
    _c(
      "div",
      {
        staticClass: "draggable-column-setting-list",
        style: { width: _vm.list1width }
      },
      [
        _c("h3", [_vm._v(_vm._s(_vm.list1Title))]),
        _c(
          "draggable",
          {
            staticClass: "dragArea",
            attrs: {
              list: _vm.renderList1,
              group: "article",
              move: _vm.handleMove
            }
          },
          _vm._l(_vm.renderList1, function(element) {
            return _c(
              "div",
              {
                key: element,
                staticClass: "list-complete-item",
                class: _vm.isDisabled(element) ? "disabled" : ""
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "list-complete-item-handle",
                    staticStyle: { flex: "1" }
                  },
                  [_vm._v(" " + _vm._s(element) + " ")]
                ),
                _c("div", [
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.deleteEle(element)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass:
                          "el-icon-remove-outline list-complete-item-del-icon",
                        staticStyle: { color: "#ff4949" }
                      })
                    ]
                  )
                ])
              ]
            )
          }),
          0
        )
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "draggable-column-setting-list",
        style: { width: _vm.list2width }
      },
      [
        _c("h3", [_vm._v(_vm._s(_vm.list2Title))]),
        _c(
          "draggable",
          {
            staticClass: "dragArea",
            attrs: { list: _vm.list2, group: "article" }
          },
          _vm._l(_vm.list2, function(element) {
            return _c(
              "div",
              { key: element, staticClass: "list-complete-item" },
              [
                _c(
                  "div",
                  {
                    staticClass: "list-complete-item-handle2",
                    staticStyle: { flex: "1" },
                    on: {
                      click: function($event) {
                        return _vm.pushEle(element)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(element) + " ")]
                ),
                _c("div", [
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.pushEle(element)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-plus-outline",
                        staticStyle: { color: "#17BB89" }
                      })
                    ]
                  )
                ])
              ]
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }