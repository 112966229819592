

























import { Vue, Component, Prop } from 'vue-property-decorator'
import { getFuzzyList } from '@/api/users'
import { EnumCondition } from '@/api/types'

interface IConfig {
  field: EnumCondition
  itemLabelFn?: Function
}

interface IOptions {
  id: string
  username: string
}

@Component
export default class MapFuzzySelect extends Vue {
  // @Prop({ default: '', required: true }) value: string
  @Prop({ default: 200 }) width: number
  @Prop({ default: 'id' }) values: string

  @Prop({
    default: function() {
      return {
        field: 'username'
      }
    },
    required: true
  }) config: IConfig

  options: IOptions[] = []
  filterVal = ''
  loading = false


  async getData() {
    this.loading = true
    const { data } = await getFuzzyList(Object.assign({}, {
      [this.config.field]: this.filterVal
    }))
    this.loading = false
    this.options = data || []
  }


  remoteMethod(query) {
    const val = `${query}`.trim()
    if (val) {
      this.filterVal = val
      this.getData()
    }
  }

  handleClear() {
    this.filterVal = ''
    this.options = []
  }

  // handleChange(val) {
  //   const o = this.options.find(item => item.nickname === val)
  //   if (o) {
  //     this.$emit('conditionChange', Object.assign({}, o))
  //   } else {
  //     this.$emit('conditionChange', '')
  //   }
  // }
}
