


















































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  getCoordinationList,
  coordinationDetail,
  coordinationReply
} from '@/api/notice'
import UploadImage from '@/common/components/UploadImage/index.vue'
import MapFuzzySelect from '@/views/components/TypeMap/map/MapFuzzySelect.vue'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { uploadImage } from '@/common/api/file'

@Component({
  name: 'Coordination',
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    UploadImage
  }
})
export default class extends Vue {
  list = []
  roleList: any = []
  total = 0
  currentNoticeId=''
  listLoading = true
  btnLoading = false
  dialogVisible = false
  activeName = '1'
  listQuery = {
    tab_type: 1,
    page_query: {
      index: 1,
      limit: 10
    }
  }

  fileList = []

  noticeData = {
    reply_url: '',
    title: '',
    status: false,
    notice_type_alias: '',
    reply_content: '',
    sender: '',
    content: '',
    feedback_type: null,
    actual_send_time: ''
  }

  form = {
    reply_content: '',
    reply_url: '',
    coordination_id: '',
    urlList: []
  }

  queryFilterOptions: any[] = [{
    field: ['create_time_start', 'create_time_end'],
    label: '发送时间',
    type: 'dateRange',
    main: true
  }, {
    field: 'title',
    label: '协同标题',
    type: 'input',
    main: true
  }, {
    field: 'receiver',
    label: '接收人',
    component: MapFuzzySelect,
    attrs: {
      values: 'username'
    },
    main: true
  }]

  queryFilterOptions2: any[] = [{
    field: ['create_time_start', 'create_time_end'],
    label: '发送时间',
    type: 'dateRange',
    main: true
  }, {
    field: 'title',
    label: '协同标题',
    type: 'input',
    main: true
  }]

  tableListOptions1 = [{
    field: 'id',
    type: 'actionSetting',
    label: '操作',
    align: 'center',
    attrs: {
      actionOptions: [{
        type: 'deal',
        label: '处理回执'
      }
      ]
    },
    fixed: true
  }, {
    field: 'title',
    label: '协同标题',
    type: 'linkLike',
    config: {
      label(val, row) {
        return val
      },
      handle: (v, row) => {
        this.handleDeal(row)
      }
    }
  }, {
    field: 'feedback_type_alias',
    label: '是否需要回执'
  }, {
    field: 'status',
    label: '回执状态',
    filter: (val) => {
      return val ? '已回执' : '待回执'
    }
  }, {
    field: 'create_time',
    label: '发送时间',
    filter: 'timeFilterShowSecond'
  }]

  tableListOptions = [{
    field: 'title',
    label: '协同标题',
    type: 'linkLike',
    config: {
      label(val, row) {
        return val
      },
      handle: (v, row) => {
        this.handelActionDetail(row.id)
      }
    }
  }, {
    field: 'feedback_type_alias',
    label: '是否需要回执'
  }, {
    field: 'status',
    label: '回执状态',
    filter: (val) => {
      return val ? '已回执' : '待回执'
    }
  }, {
    field: 'receiver',
    label: '接收人'
  }, {
    field: 'create_time',
    label: '发送时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'reply_content',
    label: '回执内容',
    type: 'linkLike',
    config: {
      label(val, row) {
        return val ? (val.length > 10 ? val.substr(0, 10) + '...' : val) : ''
      },
      handle: (v, row) => {
        this.handelOpen(row.reply_content)
      }
    }
  }, {
    field: 'reply_url',
    label: '回执文件',
    type: 'link',
    config: {
      baseUrl: '',
      label: '查看文件'
    }
  }]

  $bus: any

  created() {
    this.initListQuery()
    this.getList()
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
  }

  handleClickTab() {
    this.handleFilter()
  }

  async getImage(raw: any) {
    const d = new FormData()

    const file = raw

    // limit image size to 2M
    if (file.size >= 6291456) {
      this.$message.error('文件大小应小于6M，请重新选择图片')
      return
    }

    d.append('pic', file)
    try {
      const { data } = await uploadImage(d)
      return data.resource_url
    } finally {

    }
  }

  async getList() {
    this.listLoading = true
    const listQuery = JSON.parse(JSON.stringify(this.listQuery))
    const { data } = await getCoordinationList(listQuery)
    this.list = data.items
    this.total = data.total
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
    // await this.$nextTick()
    // this.listLoading = false
  }

  handleFilter() {
    this.listQuery.tab_type = Number(this.activeName)
    this.getList()
  }

  handelOpen(text) {
    this.$alert(text, '回执内容', {
      confirmButtonText: '确定'
    })
  }

  async handleFileChange(files) {
    this.form.reply_url = files.length ? await this.getImage(files[0].raw) : ''
  }

  handleAddNotice() {
    this.handelActionDetail('create')
  }

  handelActionDetail(id: string) {
    this.$router.push({
      path: `/regulation/coordination_detail/${id}`
    })
  }

  async onSubmit() {
    if (!this.form.reply_content) {
      this.$message.error('请填写回复意见')
      return
    }
    if (this.noticeData.feedback_type === 3 && !this.form.reply_url) {
      this.$message.error('请上传回执文件')
      return
    }
    this.$confirm('确定提交此回复吗?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.submitDo()
    })
  }


  async handleButtonAction(action, id, row) {
    if (action === 'detail') {
      this.handelActionDetail(row.id)
    } else if (action === 'deal') {
      this.handleDeal(row)
    }
  }

  async handleDeal(row) {
    try {
      const { data } = await coordinationDetail({ id: row.id })
      this.noticeData = data
      this.form.coordination_id = row.id
      this.form.reply_content = data.reply_content || ''
      this.fileList = data.file_url.split(',')
      this.dialogVisible = true
    } finally {

    }
  }

  async submitDo() {
    this.btnLoading = true

    try {
      await coordinationReply(this.form)
      this.$message.success('提交成功')
      this.dialogVisible = false
      this.getList()
      this.$bus.$emit('updateNoticeNum', 'updateNoticeNum')
    } finally {
      this.btnLoading = false
    }
  }

  closeDialog() {
    this.fileList = []
    this.dialogVisible = false
  }
}
