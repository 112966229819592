














import { Component, Vue } from 'vue-property-decorator'
import { getAntiFraudChannelApi } from '@/api/regulation'
import { EnumActions } from '@/common/business-components/TypeMap/ActionSetting/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { agentType } from '@/common/constant'
import { getEnumArray } from '@/common/utils'
import { Form } from 'element-ui'


@Component({
  name: 'externalAntiFraud',
  components: {
    BsmTable
  }
})
export default class extends Vue {
  list: any = []
  total = 0
  listLoading = true
  subLoading = false
  disableBlack = false
  visible = false
  listQuery:any = {
    // page: {
    //   index: 1,
    //   limit: 10
    // }
  }

  subjectTypeList = getEnumArray(agentType)

  formData = {
    subjectType: undefined,
    identityNo: '',
    // allDataBase: false,
    subjectName: '',
    agentDid: '',
    blackStrategy: [],
    fileUrls: [],
    id: '',
    reason: '',
    legalIdentityNo: ''
  }

  currentActionType: EnumActions = null

  tableListOptions = [{
    field: 'id',
    type: 'actionSetting',
    label: '操作',
    attrs: {
      actionOptions: [{
        type: EnumActions.detail,
        label: '异常管理'
      }]
    },
    fixed: true
  }, {
    field: 'area',
    label: '行政区划'
  }, {
    field: 'desc',
    label: '系统名称'
  }]

  created() {
    this.getList()
  }

  reSet() {
    (this.$refs.form as Form).resetFields()
    this.formData = {
      subjectType: undefined,
      identityNo: '',
      // allDataBase: false,
      subjectName: '',
      agentDid: '',
      blackStrategy: [],
      fileUrls: [],
      id: '',
      reason: '',
      legalIdentityNo: ''
    }
  }

  async getList() {
    this.listLoading = true
    const { data } = await getAntiFraudChannelApi(this.listQuery)
    this.list = data
    await this.$nextTick()
    this.listLoading = false
  }


  handleOpenConfig(val, row) {
    // this.getConfigDetail(val)
    this.visible = true
  }

  handleCloseConfig() {
    this.reSet()
    this.subLoading = false
    // this.inspectConfigList = []
  }


  handleActionCommand(command:any, id?, row?) {
    this.currentActionType = command
    if (command === EnumActions.detail) {
      this.$router.push('/regulation/external-anti-fraud-detail?channel=' + row.code + '&area=' + row.area + '&desc=' + row.desc)
    }
  }
}

