var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.meta || !_vm.item.meta.hidden
    ? _c(
        "div",
        {
          class: [
            _vm.isCollapse ? "simple-mode" : "full-mode",
            { "first-level": _vm.isFirstLevel }
          ]
        },
        [
          !_vm.alwaysShowRootMenu &&
          _vm.theOnlyOneChild &&
          !_vm.theOnlyOneChild.children
            ? [
                _vm.theOnlyOneChild.meta
                  ? _c(
                      "sidebar-item-link",
                      {
                        attrs: { to: _vm.resolvePath(_vm.theOnlyOneChild.path) }
                      },
                      [
                        _vm.isExternal(
                          _vm.resolvePath(_vm.theOnlyOneChild.path)
                        )
                          ? _c(
                              "li",
                              {
                                staticClass: "el-menu-item",
                                staticStyle: { "padding-left": "40px" }
                              },
                              [
                                _vm.theOnlyOneChild.meta.icon
                                  ? _c("img-icons", {
                                      staticClass: "img-icons",
                                      attrs: {
                                        name: _vm.theOnlyOneChild.meta.icon
                                      }
                                    })
                                  : _vm._e(),
                                _vm.theOnlyOneChild.meta.title
                                  ? _c(
                                      "span",
                                      {
                                        attrs: { slot: "title" },
                                        slot: "title"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.theOnlyOneChild.meta.title)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _c(
                              "el-menu-item",
                              {
                                class: {
                                  "submenu-title-noDropdown": _vm.isFirstLevel
                                },
                                attrs: {
                                  index: _vm.resolvePath(
                                    _vm.theOnlyOneChild.path
                                  )
                                }
                              },
                              [
                                _vm.theOnlyOneChild.meta.icon
                                  ? _c("img-icons", {
                                      staticClass: "img-icons",
                                      attrs: {
                                        name: _vm.theOnlyOneChild.meta.icon
                                      }
                                    })
                                  : _vm._e(),
                                _vm.theOnlyOneChild.meta.title
                                  ? _c(
                                      "span",
                                      {
                                        attrs: { slot: "title" },
                                        slot: "title"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.theOnlyOneChild.meta.title)
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _c(
                "el-submenu",
                {
                  attrs: {
                    index: _vm.resolvePath(_vm.item.path),
                    "popper-append-to-body": ""
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _vm.item.meta && _vm.item.meta.icon
                        ? _c("img-icons", {
                            staticClass: "img-icons",
                            attrs: { name: _vm.item.meta.icon }
                          })
                        : _vm._e(),
                      _vm.item.meta && _vm.item.meta.title
                        ? _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.item.meta.title))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.item.children
                    ? _vm._l(_vm.item.children, function(child) {
                        return _c("sidebar-item", {
                          key: child.path,
                          staticClass: "nest-menu",
                          attrs: {
                            item: child,
                            "is-collapse": _vm.isCollapse,
                            "is-first-level": false,
                            "base-path": _vm.resolvePath(child.path)
                          }
                        })
                      })
                    : _vm._e()
                ],
                2
              )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }