














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MapDateRange extends Vue {
  @Prop({ default: '' }) value: string
  @Prop({ default: '' }) fields0: number
  @Prop({ default: '' }) fields1: number
  private get time() {
    if (this.fields0 && this.fields1) {
      return [new Date(Number(this.fields0)), new Date(Number(this.fields1))]
    } else {
      return ''
    }
    // if (this.value) {
    //   return this.value.split('-').map(item => {
    //     return new Date(Number(item))
    //   })
    // } else {
    //   return ''
    // }
  }

  private set time(timeList) {
    if (timeList) {
      const val = timeList.map(time => {
        return time.getTime()
      })
      this.$emit('update:fields0', val[0])
      this.$emit('update:fields1', val[1])
    } else {
      this.$emit('update:fields0', '')
      this.$emit('update:fields1', '')
    }
  }
}
