
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Gauge } from '@antv/g2plot'
import { isEqual, merge } from 'lodash'
import { riskScoreColor } from '@/common/constant'

@Component({
  name: 'GaugeChart'
})

export default class extends Vue {
  @Prop({ default: '100%' }) private width!: string;
  @Prop({ default: '100%' }) private height!: string;
  @Prop({ default: '' }) private title!: string;

  @Prop({ default: 0 }) private value!: number
  @Prop({ default: true }) private forceFit!: boolean;

  @Prop({ default: 8 }) private rangeSize!: number;
  @Prop({ default: 3 }) private thickness!: number;
  @Prop({
    default() {
      return function({ percent }) {
        return ''
      }
    }
  }) private contentTitle: string | Function

  @Prop({
    default() {
      return ({ percent }) => {
        return `${percent * this.len}`
      }
    }
  }) private contentVal: number | string | Function

  @Prop({ default: '' }) private statistic!: string | any

  // @Prop({ default: '80%' }) private slotTop: string
  @Prop({
    default() {
      return [0, 400, 600, 800, 1000]
    }
  }) private range!: number[]

  @Prop({
    default() {
      return riskScoreColor
    }
  }) colors: string[]

  @Prop({
    default() {
      return {}
    }
  }) private config: any

  private get percent() {
    return (Math.round((this.value / this.len) * 10000) / 10000) || 0
  }

  private get bgColor() {
    const len = this.colors.length
    if (len === 1) {
      return this.colors[0]
    } else {
      let bgStr = 'l(0) '
      const step = 1 / (len - 1)
      for (let i = 0; i < len - 1; i++) {
        bgStr += `${step * i}:${this.colors[i]} `
      }
      bgStr += `1:${this.colors[len - 1]}`
      return bgStr
    }
  }

  private get getColor() {
    const gradeList = this.range.slice(1)
    if (this.value === 0) {
      return this.colors[0]
    }
    const index = gradeList.findIndex(item => item >= this.value)
    if (index > -1 && index <= (this.colors.length - 1)) {
      return this.colors[index]
    } else {
      return this.colors[this.colors.length - 1]
    }
  }

  private get min() {
    return this.range?.length ? this.range[0] : 0
  }

  private get max() {
    return this.range?.length ? this.range[this.range.length - 1] : 1000
  }

  private get len() {
    return this.max - this.min
  }

  private get contentTitleFn() {
    if (typeof this.contentTitle === 'string') {
      return ({ percent }) => this.contentTitle
    } else {
      return this.contentTitle
    }
  }

  private get contentValFn() {
    if (this.contentVal === 'string ' || this.contentVal === 'number') {
      return ({ percent }) => `${this.contentTitle}`
    } else {
      return this.contentVal
    }
  }

  private get chartConfig() {
    return merge({
      percent: this.percent,
      // color: this.colors,
      min: this.min,
      max: this.max,
      range: {
        ticks: [0, 1],
        // color: `l(0) 0:${this.colors[0]} 0.33:${this.colors[1]} 0.66:${this.colors[2]} 1:${this.colors[3]}`
        color: this.bgColor
      },
      rangeSize: this.rangeSize,
      title: {
        visible: !!this.title,
        text: this.title || ''
      },
      alignTo: 'left',
      forceFit: this.forceFit,
      statistic: merge({
        title: {
          formatter: this.contentTitleFn,
          style: ({ percent }) => {
            return {
              lineHeight: 1,
              fontSize: 12,
              color: this.getColor
            }
          }
        },
        content: {
          formatter: this.contentValFn,
          offsetY: -14,
          style: ({ percent }) => {
            return {
              fontSize: 18,
              color: this.getColor,
              fontWeight: 'bold'
            }
          }
        }
      }, this.statistic),
      indicator: {
        pointer: {
          style: {
            fill: this.colors[this.colors.length - 1],
            stroke: this.colors[this.colors.length - 1]
          }
        },
        pin: {
          style: {
            r: 5,
            // fill: this.colors[this.colors.length - 1],
            stroke: this.colors[this.colors.length - 1]
          }
        }
      },
      // pivot: {
      //   visible: true,
      //   thickness: this.thickness,
      //   pointer: {
      //     visible: true,
      //     style: {
      //       fill: this.getColor
      //     }
      //   },
      //   base: {
      //     visible: false
      //   },
      //   pin: {
      //     visible: false
      //   }
      // },
      axis: {
        label: {
          formatter: (v) => {
            return Number(v) * this.len
          },
          style: {
            fontSize: 8
          }
        }
      }
    }, this.config)
  }

  private chart

  private initChart() {
    this.chart = new Gauge(this.$refs.chart as HTMLElement, this.chartConfig as any)
    this.chart.render()
  }

  @Watch('value')
  private onDataChange(now, old) {
    if (now !== old) {
      this.$nextTick(() => {
        this.chart.changeData(this.percent)
        // if (this.chart) {
        //   this.chart.updateConfig(this.chartConfig)
        //   this.chart.render()
        // }
      })
    }
  }

  @Watch('contentTitle')
  private onContentTitleChange(now, old) {
    if (now !== old) {
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.changeData(this.percent)
          // this.chart.updateConfig(this.chartConfig)
          // this.chart.render()
        }
      })
    }
  }

  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  }

  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.destroy()
    this.chart = null
  }
}
