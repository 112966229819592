import request from '@/utils/request'

const path = 'upload'

export const uploadImage = (params: any) => request({
  url: `${path}/photo`,
  method: 'post',
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  data: params
})

export const uploadImages = (paramsList: any[]) => {
  return Promise.all(paramsList.map(params => {
    return request({
      url: `${path}/photo`,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: params
    })
  }))
}

export const uploadFile = (params: any) => request({
  url: `${path}/file`,
  method: 'post',
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  data: params
})
