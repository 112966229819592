




import { Vue, Component, Prop } from 'vue-property-decorator'

interface IConfig {
  format: string
}

@Component
export default class MapDateFormat extends Vue {
  @Prop({ default: '', required: true }) value: string
  @Prop({
    default: function() {
      return {
        format: '{y}.{m}.{d} {h}:{d}:{s}'
      }
    }
  }) config: IConfig
}

