








import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResizeMixin from './mixins/resize1'

import { Chart, registerShape, getEngine } from '@antv/g2'
import * as G2 from '@antv/g2'
import _ from 'lodash'
const colors = {
  blue: '#5A86EE',
  green: '#3DC69D',
  yellow: '#FF9800',
  red: '#FF6464'
}

@Component({
  name: 'BarChart'
})
export default class extends Vue {
  @Prop({ default: 'bar-chart' }) private className!: string
  @Prop() private id!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: 'blue' }) private color!: string
  @Prop({ default: 'x' }) public xField!: string;
  @Prop({ default: 'y' }) public yField!: string;
  @Prop({ type: Array, required: true }) public data: any[];
  @Prop({ default: '' }) public title!: string;
  @Prop({ default: 'count' }) public valueType: string
  @Prop({default:'name'}) idField:string

  private chart: Chart
  private valueFilter: any
  private height='100%'
  created() {
    if (this.valueType === 'amount') {
      this.valueFilter = this.$options.filters.amountFormatFilter
    }
  }

  mounted() {
    if (this.data && this.data.length) {
      this.$nextTick(() => {
        this.initChart()
      })
    }
  }

  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.destroy()
    this.chart = null
  }

  @Watch('data', {
    deep: true
  })
  onDataChange(data: any[], oldData: any[]) {
    if (!_.isEqual(data, oldData)) {
      this.height = data.length * 20 + '%'
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.destroy()
          this.chart = null
          this.initChart()
          // this.chart.changeData(data)
        } else {
          this.initChart()
        }
      })
    }
  }

  private initChart() {
    const that = this
    this.chart = new Chart({
      container: this.$refs.chart as HTMLElement,
      autoFit: true,
      padding: [0, 20, 10, 20],
      localRefresh: false
    })

    const G = getEngine('canvas')
    this.chart.data(this.data.reverse())
    this.chart.scale('value', {
      nice: true
    })
    this.chart.coordinate().transpose()
    this.chart.interval()
      .position([this.idField,'value','name'])
      .color('value', [colors[this.color]])
      .size(4)
      .label({
        fields: ['name', 'value',this.idField],
        callback: () => {
          return {
            type: 'interval',
            layout: {
              type: 'overlap'
            },
            offset: 0,
            content: (data, obj: any, index) => {
              const starPoint = obj.points[3]
              const valueLen = data.value.toString().length
              const group = new G.Group({})
              const actualX: any = (obj.x - 20)
              const endPoint: any = obj.points[2]

              group.addShape('text', {
                name: 'value-name',
                attrs: {
                  y: 22,
                  x: 0,
                  fontSize: 12, // 字体大小
                  fill: '#686868',
                  text: data.name,
                  textBaseline: 'end',
                  textAlign: 'left',
                  width: 20
                }
              })
              group.moveTo(-obj.x + 20, 0)
              group.addShape('text', {
                name: 'label-value',
                attrs: {
                  y: 22,
                  x: actualX / endPoint.y - valueLen * 7,
                  fontSize: 12, // 字体大小
                  fill: '#686868',
                  text: this.valueFilter ? this.valueFilter(data.value) : data.value,
                  textBaseline: 'end',
                  textAlign: 'left'
                }
              })

              return group
            },
            position: (a, b, c) => {
              return 'right'
            }
          }
        }
      })


    this.chart.axis(this.idField, {
      title: null,
      grid: null,
      tickLine: null,
      line: null,
      label: null
    })
    this.chart.axis('value', {
      title: null,
      grid: null,
      label: null,
      tickLine: null,
      line: null
    })
    this.chart.legend(false)
    this.chart.tooltip(false)
    // this.chart.tooltip({
    //   offset: 10,
    //   domStyles: {
    //     'g2-tooltip-value': {
    //       marginLeft: '8px'
    //     }
    //   },
    //   itemTpl: '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>数量: {value}</li>'
    // })
    this.chart.render()
  }
}

