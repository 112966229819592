var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { attrs: { placement: "" }, on: { command: _vm.handleCommand } },
    [
      _c(
        "span",
        { staticClass: "el-dropdown-link" },
        [_c("img-icons", { attrs: { name: "setting" } })],
        1
      ),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.actionOptions, function(option) {
          return _c(
            "el-dropdown-item",
            {
              key: option.type,
              attrs: {
                disabled: _vm.resolveProp(option.disabled),
                command: option.type
              }
            },
            [_vm._v(" " + _vm._s(_vm.resolveProp(option.label)) + " ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }