




























import { Component, Vue } from 'vue-property-decorator'
import {
  exportInvoiceHistory,
  getInvoiceHistoryList
} from '@/api/invoice'
import { IInvoiceHistoryData, IGetInvoiceHistoryListParams } from '@/api/types'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { InvoiceCategoryMap, InvoiceTypeMap, InvoiceStatus, InvoiceStatusEnum } from '@/common/constant'
import { getEnumArray } from '@/utils/index'
import moment from 'moment'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import { TagsViewModule } from '@/store/modules/tags-view'
import { convertToExcel } from '@/common/utils'
const InvoiceCategoryMapArray = getEnumArray(InvoiceCategoryMap)
const InvoiceTypeMapArray = getEnumArray(InvoiceTypeMap)
/**
 * 开票操作记录
 * @type {String}
 */
@Component({
  name: 'InvoiceHistory',
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  private list: IInvoiceHistoryData[] = []
  private total = 0
  private listLoading = true
  private listQuery: IGetInvoiceHistoryListParams = {
    push_date: moment().format('YYYY-MM'),
    page: {
      index: 1,
      limit: 10
    }
  }

  private queryFilterOptions = [{
    field: 'push_date',
    label: '推送月份',
    componentName: 'el-date-picker',
    attrs: {
      type: 'month',
      format: 'yyyy-MM',
      valueFormat: 'yyyy-MM'
    },
    main: true
  }]

  private tableListOptions = [{
    field: 'id',
    type: 'buttons',
    label: '操作',
    width: 50,
    align: 'center',
    config: {
      options: [{
        label: '导出开票明细',
        action: 'export',
        type: 'primary'
      }]
    },
    fixed: true
  }, {
    field: 'create_time',
    label: '操作时间',
    width: 220,
    filter: 'timeFilter'
  }, {
    field: 'operator',
    label: '操作人',
    width: 150
  }, {
    field: 'push_date',
    label: '推送时间',
    width: 150
  }]

  created() {
    this.initListQuery()
    this.getList()
  }

  private initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
  }

  private async getList() {
    this.listLoading = true

    const { data } = await getInvoiceHistoryList(this.listQuery)
    this.list = data.items
    this.total = data.total
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  private handleFilter() {
    this.listQuery.page.index = 1
    this.getList()
  }

  private async handleActionCommand(command, id, row) {
    if (command === 'export') {
      exportInvoiceHistory({
        id: id
      }).then(res => {
        const data: any = [res]
        convertToExcel(data, `开票明细_${row.push_date}`)
      })
    }
  }
}

