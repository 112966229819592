var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "upload",
      staticClass: "upload-image",
      class: { "add-hide": _vm.mode === "single" && _vm.fileList.length },
      attrs: {
        multiple: false,
        "on-change": _vm.handleFileChange,
        "on-remove": _vm.handleFileRemove,
        "list-type": _vm.listType,
        "auto-upload": false,
        action: "''",
        accept: "image/png, image/jpeg, image/jpg, image/gif, image/bmp,.heic"
      }
    },
    [_c("i", { staticClass: "el-icon-plus" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }