


















































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import TableList from '../TableList/index.vue'
import MultiUploadFile from '../MultiUploadFile/index.vue'
import {
  IWaybillAdditionData
} from '../index.d'

const waybillAdditionDefault: IWaybillAdditionData[] = []

@Component({
  components: {
    TableList,
    MultiUploadFile
  }
})
export default class extends Vue {
  @Prop({ default: () => waybillAdditionDefault }) waybillAdditionInfo
  @Prop({ default: false }) isAddable: boolean

  showAddFileDialog = false
  initFileList: any = []
  fileList: any = []
  explain = ''

  handleClickImage(url: string) {
    window.open(url, '_blank')
  }

  hanldeAddFile() {
    this.initFileList = []
    this.explain = ''
    this.$nextTick(() => {
      this.showAddFileDialog = true
    })
  }

  handleFileListChange(files: any) {
    this.fileList = files
  }

  @Emit('uploadAddition')
  handleClickSubmit() {
    this.showAddFileDialog = false
    return {
      fileList: this.fileList,
      explain: this.explain
    }
  }
}

