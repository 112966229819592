












import { Vue, Component, Prop } from 'vue-property-decorator'

interface IOption {
  action: string
  type?: string
  label: string
  size?: string
}

interface IButtonsconfig {
  options: IOption[]
}

@Component({})
export default class Buttons extends Vue {
  @Prop({
    default() {
      return {
        options: []
      }
    }
  }) config: IButtonsconfig

  @Prop({
    default: ''
  }) value: string | number

  @Prop({
    default() {
      return {}
    }
  }) rowData: any

  private get options() {
    return this.config.options || []
  }

  private handleClick(action) {
    this.$emit('button-action', action, this.value, this.rowData)
  }
}
