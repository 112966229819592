import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'
import { AppModule } from '@/store/modules/app'

const urlConfig = AppModule.urlConfig

const riskRulesRouter: RouteConfig = {
  path: '/home',
  name: 'Home',
  component: Layout,
  meta: {
    title: '首页',
    icon: 'bigScreen',
    alwaysShow: true,
    roles: ['big_screen']
  },
  children: [
    {
      path: urlConfig.datav,
      name: 'BigScreenHome',
      meta: {
        title: '主大屏',
        roles: ['datav']
      }
    },
    {
      path: 'gatherHome',
      name: 'GatherHome',
      component: () => import('@/views/gather-home/index.vue'),
      meta: {
        title: '聚合首页',
        affix: true,
        noCache: true,
        roles: ['aggregation_home_page']
      }
    }
  ]
}

export default riskRulesRouter
