



























import { Component, Vue } from 'vue-property-decorator'
import { getAgentList } from '@/api/agent'
import { IAgentData, IGetListParams } from '@/api/types'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
// import BsmTable from '@/views/components/BsmTable.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { resolveList } from '@/utils/common'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import { getEnumArray } from '@/common/utils'
import { riskScoreTypes } from '@/common/constant'
// import { timeFilter } from '@/filters'

@Component({
  name: 'Agent',
  components: {
    Pagination,
    SearchFilter,
    // BsmTable,
    BsmTable
  }
})
export default class extends Vue {
  private list: IAgentData[] = []
  private total = 0
  private listLoading = true
  private listQuery: IGetListParams = {
    page: {
      index: 1,
      limit: 10
    }
  }



  private get queryFilterOptions(): any[] {
    return [...[{
      field: 'agent',
      label: '企业名称或证件号码',
      main: true,
      component: MapSearchSelect
    }, {
      field: 'consignor',
      label: '托运人名称或证件号码',
      component: MapSearchSelect
    }, {
      field: 'carrier',
      label: '承运人名称或证件号码',
      component: MapSearchSelect
    }, {
      field: 'driver',
      label: '司机名称或证件号码',
      component: MapSearchSelect
    }, {
      field: 'vehicle',
      label: '车辆牌照号码',
      component: MapSearchSelect
    }].map(item => {
      return Object.assign({}, item, {
        config: {
          field: item.field
        }
      })
    }), {
      field: 'risk_score_types',
      label: '评分等级',
      type: 'select',
      config: {
        list: getEnumArray(riskScoreTypes),
        multiple: true
      }
    }, {
      field: ['risk_score_left', 'risk_score_right'],
      label: '评分分值',
      type: 'range'
    }]
  }

  private tableListOptions = [{
    field: 'subject_id',
    type: 'link',
    label: '操作',
    config: {
      baseUrl: '/dataquery/agent',
      label: '查看详情'
    },
    fixed: true
  }, {
    field: 'agent_name',
    label: '名称',
    width: 220,
    fixed: true
  }, {
    field: 'agent_identity',
    label: '证件号码',
    width: 200
  }, {
    field: 'risk_score',
    label: '评分',
    align: 'center',
    filter: 'riskScore'
  }, {
    field: 'risk_score_msg',
    label: '评分等级',
    align: 'center'
  }, {
    field: 'waybill_count',
    label: '运单笔数',
    align: 'center'
  }, {
    field: 'income_sum',
    label: '运费收入(元)',
    width: 150,
    filter: 'amountFormatFilter',
    align: 'center'
  }, {
    field: 'expense',
    label: '运费支出(元)',
    width: 150,
    filter: 'amountFormatFilter',
    align: 'center'
  }, {
    field: 'actual_freight',
    label: '运费实际支付(元)',
    width: 150,
    filter: 'amountFormatFilter',
    align: 'center'
  }, {
    field: 'consignor_count',
    label: '托运人数量',
    align: 'center'
  }, {
    field: 'carrier_count',
    label: '承运人数量',
    align: 'center'
  }, {
    field: 'driver_count',
    label: '司机数量',
    align: 'center'
  }, {
    field: 'vehicle_count',
    label: '车辆数量',
    align: 'center'
  }, {
    field: 'first_time',
    label: '接入时间',
    filter: 'timeFilter',
    width: 120,
    align: 'center'
  }, {
    field: 'tax_sum',
    label: '纳税总额(元)',
    align: 'center',
    width: 150,
    filter: 'amountFormatFilter'
  }, {
    field: 'behalf_tax_sum',
    label: '代缴总额(元)',
    width: 120,
    align: 'center',
    filter: 'amountFormatFilter'
  }]

  created() {
    this.initListQuery()
    this.getList()
  }

  private initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
    this.listQuery.startTime = Number(this.$route?.query?.startTime) || ''
    this.listQuery.endTime = Number(this.$route?.query?.endTime) || ''
  }

  private async getList() {
    this.listLoading = true
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { risk_score, ...listQuery } = this.listQuery
    if (risk_score) {
      listQuery.risk_score_left = risk_score[0] || ''
      listQuery.risk_score_right = risk_score[1] || ''
    }
    const { data } = await getAgentList(listQuery)
    this.list = resolveList(data?.items)
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }

  private handleFilter() {
    this.listQuery.page.limit = 10
    this.getList()
  }

  private handelActionDetail({ id }) {
    this.$router.push({
      path: `consignor/${id}`
    })
  }
}

