/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'warning': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<g _stroke="#999" _fill="none" fill-rule="evenodd"><path pid="0" d="M10.118 3.519a1.495 1.495 0 011.678.694h0l7.018 12.031c.208.358.252.766.155 1.138a1.495 1.495 0 01-1.451 1.118h0H3.482c-.414 0-.79-.168-1.06-.44a1.495 1.495 0 01-.236-1.816h0l7.018-12.03c.209-.358.543-.598.914-.695zM10.5 8v1.6M10.5 10.6v5"/></g>'
  }
})
