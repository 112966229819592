var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "waybill-invoice-container" }, [
    _c(
      "div",
      {
        staticClass: "content-title",
        staticStyle: { "font-weight": "500", color: "#4d4d4d" }
      },
      [_vm._v(" 运单申诉记录 ")]
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("table-list", {
          attrs: {
            data: _vm.etcInvoiceInfo,
            options: _vm.invoiceListOptions,
            "max-height": "300",
            "show-setting": false
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }