var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClickTab },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "待处理", name: "1" } },
            [
              _c("search-filter", {
                attrs: {
                  options: _vm.queryFilterOptions2,
                  "type-default": "input"
                },
                on: { search: _vm.handleFilter },
                model: {
                  value: _vm.listQuery,
                  callback: function($$v) {
                    _vm.listQuery = $$v
                  },
                  expression: "listQuery"
                }
              }),
              _c(
                "section",
                [
                  _c("bsm-table", {
                    attrs: {
                      data: _vm.list,
                      options: _vm.tableListOptions1,
                      "list-loading": _vm.listLoading,
                      "show-index": true,
                      "show-setting": false
                    },
                    on: { actionCommand: _vm.handleButtonAction }
                  })
                ],
                1
              ),
              _c(
                "pagination",
                {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.page_query.index,
                    limit: _vm.listQuery.page_query.limit
                  },
                  on: {
                    "update:page": function($event) {
                      return _vm.$set(_vm.listQuery.page_query, "index", $event)
                    },
                    "update:limit": function($event) {
                      return _vm.$set(_vm.listQuery.page_query, "limit", $event)
                    },
                    pagination: _vm.getList
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "end" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["notice_create"],
                              expression: "['notice_create']"
                            }
                          ],
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.handleAddNotice }
                        },
                        [_vm._v(" 创建协同 ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "我收到的", name: "2" } },
            [
              _c("search-filter", {
                attrs: {
                  options: _vm.queryFilterOptions2,
                  "type-default": "input"
                },
                on: { search: _vm.handleFilter },
                model: {
                  value: _vm.listQuery,
                  callback: function($$v) {
                    _vm.listQuery = $$v
                  },
                  expression: "listQuery"
                }
              }),
              _c(
                "section",
                [
                  _c("bsm-table", {
                    attrs: {
                      data: _vm.list,
                      options: _vm.tableListOptions,
                      "list-loading": _vm.listLoading,
                      "show-index": true,
                      "show-setting": false
                    },
                    on: { actionCommand: _vm.handleButtonAction }
                  })
                ],
                1
              ),
              _c(
                "pagination",
                {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.page_query.index,
                    limit: _vm.listQuery.page_query.limit
                  },
                  on: {
                    "update:page": function($event) {
                      return _vm.$set(_vm.listQuery.page_query, "index", $event)
                    },
                    "update:limit": function($event) {
                      return _vm.$set(_vm.listQuery.page_query, "limit", $event)
                    },
                    pagination: _vm.getList
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "end" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["notice_create"],
                              expression: "['notice_create']"
                            }
                          ],
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.handleAddNotice }
                        },
                        [_vm._v(" 创建协同 ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "我发出的", name: "3" } },
            [
              _c("search-filter", {
                attrs: {
                  options: _vm.queryFilterOptions,
                  "type-default": "input"
                },
                on: { search: _vm.handleFilter },
                model: {
                  value: _vm.listQuery,
                  callback: function($$v) {
                    _vm.listQuery = $$v
                  },
                  expression: "listQuery"
                }
              }),
              _c(
                "section",
                [
                  _c("bsm-table", {
                    attrs: {
                      data: _vm.list,
                      options: _vm.tableListOptions,
                      "list-loading": _vm.listLoading,
                      "show-index": true,
                      "show-setting": false
                    },
                    on: { actionCommand: _vm.handleButtonAction }
                  })
                ],
                1
              ),
              _c(
                "pagination",
                {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.page_query.index,
                    limit: _vm.listQuery.page_query.limit
                  },
                  on: {
                    "update:page": function($event) {
                      return _vm.$set(_vm.listQuery.page_query, "index", $event)
                    },
                    "update:limit": function($event) {
                      return _vm.$set(_vm.listQuery.page_query, "limit", $event)
                    },
                    pagination: _vm.getList
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "end" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["notice_create"],
                              expression: "['notice_create']"
                            }
                          ],
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.handleAddNotice }
                        },
                        [_vm._v(" 创建协同 ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "协同详情",
            visible: _vm.dialogVisible,
            width: "70%",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.closeDialog
          }
        },
        [
          _c("section", { staticClass: "notice-con" }, [
            _c("p", { staticClass: "h2" }, [
              _vm._v(" " + _vm._s(_vm.noticeData.title) + " ")
            ]),
            _c("div", { staticClass: "con-tip" }, [
              _c("p", [
                _vm._v("发送人："),
                _c("span", { staticClass: "con" }, [
                  _vm._v(_vm._s(_vm.noticeData.sender))
                ])
              ]),
              _c("p", [
                _vm._v("发送时间："),
                _c("span", { staticClass: "con" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("timeFilterShowSecond")(_vm.noticeData.create_time)
                    )
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "14px",
                    "margin-top": "6px"
                  }
                },
                [
                  _c("span", { staticStyle: { color: "#999" } }, [
                    _vm._v("附件：")
                  ]),
                  _c("multi-upload-file", {
                    attrs: { "file-list": _vm.fileList, disabled: true }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", {
            staticClass: "con-div",
            domProps: { innerHTML: _vm._s(_vm.noticeData.content) }
          }),
          !_vm.noticeData.status
            ? [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticStyle: {
                      "padding-top": "16px",
                      "border-top": "1px solid #E0E0E0"
                    },
                    attrs: {
                      model: _vm.form,
                      "label-width": "80px",
                      size: "small"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "回复意见", prop: "content" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            disabled: _vm.noticeData.status
                          },
                          model: {
                            value: _vm.form.reply_content,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "reply_content", $$v)
                            },
                            expression: "form.reply_content"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.noticeData.feedback_type === 3
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "回执文件", prop: "reply_url" } },
                          [
                            !_vm.noticeData.status &&
                            _vm.noticeData.feedback_type === 3
                              ? _c("upload-image", {
                                  attrs: {
                                    mode: "single",
                                    "file-list": _vm.form.urlList
                                  },
                                  on: { input: _vm.handleFileChange }
                                })
                              : _vm._e(),
                            _vm.noticeData.reply_url
                              ? _c(
                                  "a",
                                  {
                                    staticStyle: { color: "#4f7df9" },
                                    attrs: {
                                      href: _vm.noticeData.reply_url,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v("查看文件")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              !_vm.noticeData.status
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.btnLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.closeDialog }
                    },
                    [_vm._v("关闭")]
                  )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }