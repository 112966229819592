import Vue, { DirectiveOptions } from 'vue'

import 'normalize.css'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'

// import '@/styles/element-variables.scss'
import '@/styles/index.scss'

import App from '@/App.vue'
import store from '@/store'
import { AppModule } from '@/store/modules/app'
import router from '@/router'
import i18n from '@/lang'
import '@/icons/components'
import '@/permission'
import '@/utils/error-log'
// import '@/pwa/register-service-worker'
import * as filters from '@/filters'
import * as directives from '@/directives'
import components from '@/components'
import Extends from '@/extends'

import AFTableColumn from 'af-table-column'

Vue.prototype.$bus = new Vue() // 组件传值的总线

Vue.use(ElementUI, {
  size: AppModule.size, // Set element-ui default size
  i18n: (key: string, value: string) => i18n.t(key, value)
})

Vue.use(AFTableColumn, {
  fontSize: 16
})
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})

// Register global directives
Object.keys(directives).forEach(key => {
  Vue.directive(key, (directives as {
    [key: string]: DirectiveOptions
  })[key])
})

// Register global filter functions
Object.keys(filters).forEach(key => {
  Vue.filter(key, (filters as {
    [key: string]: Function
  })[key])
})

Object.keys(components).forEach(key => {
  console.log(components[key])
  Vue.component(key, components[key])
})

Vue.use(Extends)

Vue.config.productionTip = false
Vue.config.errorHandler = (err) => {
  console.error('global error catch:' + err.toString())
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
