














import { Component, Vue } from 'vue-property-decorator'
import { IRiskRule } from '@/api/types'
import { getRulesHistory } from '@/api/riskrules'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import moment from 'moment'
import { RiskRuleKind } from '@/common/constant'

const showEffectiveStatus = (val) => {
  if (val) {
    return '生效'
  } else {
    return '失效'
  }
}
const typeMap = {
  1: '关键策略',
  2: '中等策略',
  3: '一般策略'
}
const typeFilter = (type: number) => {
  return typeMap[type] || type
}
@Component({
  name: 'RiskRukesHistory',
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  private list: IRiskRule[] = []
  private total = 0
  private listLoading = true
  private listQuery = {
    time: moment().startOf('day').valueOf(),
    type: null,
    category: null,
    agent_did: this.agent_did,
    page: {
      index: 1,
      limit: 10
    }
  }
  private get agent_did() {
    return this.$route.params.id
  }
  private get renderListQuery() {
    return Object.assign({}, this.listQuery, {
      time: this.listQuery.time ? (Number(this.listQuery.time) + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000) : null
    })
  }

  private queryFilterOptions = [{
      field: 'time',
      label: '查询日期',
      componentName: 'el-date-picker',
      attrs: {
        valueFormat: 'timestamp'
      },
      main: true
    },
    {
      field: 'type',
      label: '规则类型',
      type: 'select',
      main: true,
      config: {
        list: Object.keys(typeMap).map(key => {
          return {
            value: Number(key),
            label: typeMap[key]
          }
        })
      }
    },
    {
      field: 'category',
      label: '规则类别',
      type: 'select',
      main: true,
      config: {
        list: Object.keys(RiskRuleKind).map(key => {
          return {
            value: Number(key),
            label: RiskRuleKind[key]
          }
        })
      }
    }
  ]

  private tableListOptions = [{
      field: 'anti_fraud_rule_view.id',
      label: '规则编号',
      width: 150,
      fixed: true
    }, {
      field: 'anti_fraud_rule_view.name',
      label: '规则名称'
    }, {
      field: 'anti_fraud_rule_view.type',
      label: '规则类型',
      align: 'center',
      filter: typeFilter
    }, {
      field: 'anti_fraud_rule_view.category',
      label: '规则类别',
      align: 'center',
      filter: (type: number) => {
        return RiskRuleKind[type] || type
      }
    },
    {
      field: 'action_time',
      label: '操作时间',
      align: 'center',
      filter: 'timeFilter'
    }
  ]

  created() {
    this.getList()
  }

  private async getList() {
    this.listLoading = true
    const { data } = await getRulesHistory(this.renderListQuery)
    this.list = data.items
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }

  private handleFilter() {
    this.listQuery.page.limit = 10
    this.getList()
  }

  private handelActionDetail({ id }) {
    this.$router.push({
      path: `consignor/${id}`
    })
  }
}

