



























import { Vue, Component, Prop } from 'vue-property-decorator'
import { getConditionList, getConditionSubject } from '@/api/condition'
import { EnumCondition } from '@/api/types'

interface IConfig {
  field: EnumCondition
  itemLabelFn?: Function
}

interface IOptions {
  conditionKey: string
  conditionVal: string
}

@Component
export default class MapDateSelect extends Vue {
  // @Prop({ default: '', required: true }) value: string
  @Prop({ default: 200 }) width: number

  @Prop({
    default: function() {
      return {
        field: 'agent'
      }
    },
    required: true
  }) config: IConfig

  private options: IOptions[] = []
  private filterVal = ''
  private loading = false
  private total = 0

  private listQuery = {
    page: {
      index: 1,
      limit: 100
    }
  }

  private async getData() {
    this.loading = true
    this.listQuery.page.index = 1
    const { data } = await getConditionList(Object.assign({}, this.listQuery, {
      [this.config.field]: this.filterVal
    }))
    this.loading = false
    this.total = data?.total || 0
    this.options = data?.items || []
  }

  private async getMoreData() {
    this.loading = true
    const { data } = await getConditionList(Object.assign({}, this.listQuery, {
      [this.config.field]: this.filterVal
    }))
    this.loading = false
    this.total = data.total || 0
    this.options = this.options.concat(data.items || [])
  }

  private remoteMethod(query) {
    const val = `${query}`.trim()
    if (val) {
      this.filterVal = val
      this.getData()
    }
  }

  private handleClear() {
    this.filterVal = ''
    this.options = []
  }

  private handleChange(val) {
    const o = this.options.find(item => item.conditionKey === val)
    if (o) {
      this.$emit('conditionChange', Object.assign({}, o))
    } else {
      this.$emit('conditionChange', '')
    }
  }

  private handleLoadmore() {
    if (this.total > this.options.length) {
      this.getMoreData()
    }
  }

  public async init() {
    const { data } = await getConditionSubject(this.$attrs.value)
    this.options = [{
      conditionKey: this.$attrs.value,
      conditionVal: `${data.name} ${data.identity}`
    }]
  }

  created() {
    if (this.$attrs.value) {
      this.init()
    }
  }
}
