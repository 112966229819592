// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.select-receiver input::-webkit-input-placeholder {\n  color: #1f2d3d;\n}\n.select-receiver input::-moz-placeholder {\n  color: #1f2d3d;\n}\n.select-receiver input:-ms-input-placeholder {\n  color: #1f2d3d;\n}\n.select-receiver input::-ms-input-placeholder {\n  color: #1f2d3d;\n}\n.select-receiver input::placeholder {\n  color: #1f2d3d;\n}\n.textarea-content {\n  height: calc(100vh - 400px);\n  padding: 10px;\n  border: 1px solid #dfe4ed;\n  overflow: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
