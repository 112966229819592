var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-container bg-normal px-8 py-6" },
    [
      _c(
        "el-row",
        [
          _c(
            "dashboard-header",
            {
              attrs: {
                title: _vm.data.driver_info.name,
                "time-range": [_vm.params.start_time, _vm.params.end_time]
              },
              on: {
                onTimeRangeChange: _vm.handleTimeChange,
                onBusinessModeChange: _vm.handleBusinessChange,
                onClickTitle: function($event) {
                  return _vm.$refs["subjectDetail"].showSubjectDetail()
                },
                onTimeTypeChange: _vm.handleTimeTypeChange
              }
            },
            [
              _c(
                "span",
                { staticClass: "font-14", staticStyle: { color: "#808080" } },
                [
                  _c("span", { staticClass: "pl-10" }, [
                    _vm._v("电话：" + _vm._s(_vm.data.driver_info.phone))
                  ]),
                  _c("span", { staticClass: "pl-10" }, [
                    _vm._v("证件号：" + _vm._s(_vm.data.driver_info.identityNo))
                  ]),
                  _c("span", { staticClass: "pl-10" }, [
                    _vm._v(
                      "实名认证结果：" +
                        _vm._s(
                          _vm.verify_status_map[
                            _vm.data.driver_info.verify_status
                          ]
                        )
                    )
                  ]),
                  _vm.data.driver_info.verify_status !== 1
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.verifyDo()
                            }
                          }
                        },
                        [_vm._v("去认证")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "subject-detail-dialog",
                {
                  ref: "subjectDetail",
                  attrs: {
                    id: _vm.id,
                    type: "driver",
                    title: "司机信息",
                    "start-time": _vm.params.start_time,
                    "end-time": _vm.params.end_time,
                    "business-mode": _vm.params.business_mode
                  }
                },
                [
                  _c("risk-score", {
                    staticClass: "pr-3",
                    attrs: {
                      slot: "prefix",
                      value: _vm.data.risk_score,
                      type: _vm.data.risk_score_type
                    },
                    slot: "prefix"
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pt-4" },
        [
          _c(
            "el-col",
            { staticClass: "pr-2", attrs: { span: 12 } },
            [
              _c(
                "chart-container",
                {
                  attrs: {
                    title: "运单量",
                    amount: _vm.data.waybill_count,
                    unit: "单",
                    link: _vm.getLinkUrl(
                      "/dataquery/waybill" +
                        (_vm.params.start_time
                          ? "?waybillCreateTime=" +
                            _vm.params.start_time +
                            "-" +
                            _vm.params.end_time
                          : ""),
                      true
                    )
                  }
                },
                [
                  _c("line-area-chart", {
                    attrs: {
                      data: _vm.data.waybill_count_list,
                      "x-field": "timestamp",
                      "y-field": "bill_count",
                      height: "151px"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("el-col", { staticClass: "pl-2", attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "pa-6 bg-white text-main pb-12" },
              [
                _c("section", { staticClass: "font-16 py-1" }, [
                  _vm._v(" 证件情况 ")
                ]),
                _c(
                  "el-row",
                  { staticClass: "pt-8", attrs: { gutter: 56 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 8 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "certificate-item",
                                attrs: { span: 12 }
                              },
                              [
                                _c("contain-show-img", {
                                  attrs: {
                                    src: _vm.data.driver_info.licenceFrontPic
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "certificate-item",
                                attrs: { span: 12 }
                              },
                              [
                                _c("contain-show-img", {
                                  attrs: {
                                    src: _vm.data.driver_info.licenceReversePic
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("el-row", { staticClass: "pt-4 font-12" }, [
                          _c("span", [
                            _vm._v(
                              "驾驶证有效期：" +
                                _vm._s(
                                  _vm._f("timeFilter")(
                                    _vm.data.driver_info.licenceExpireTime
                                  )
                                )
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 8 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "certificate-item",
                                attrs: { span: 12 }
                              },
                              [
                                _c("contain-show-img", {
                                  attrs: {
                                    src:
                                      _vm.data.driver_info.qualificationFrontPic
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "certificate-item",
                                attrs: { span: 12 }
                              },
                              [
                                _c("contain-show-img", {
                                  attrs: {
                                    src:
                                      _vm.data.driver_info
                                        .qualificationReversePic
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("el-row", { staticClass: "pt-4 font-12" }, [
                          _c("span", [
                            _vm._v(
                              "从业资格证有效期：" +
                                _vm._s(
                                  _vm._f("timeFilter")(
                                    _vm.data.driver_info.qualificationExpireTime
                                  )
                                )
                            )
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "24px" } },
                            [
                              _vm._v(
                                "验证状态：" +
                                  _vm._s(
                                    _vm.data.driver_info &&
                                      _vm.data.driver_info
                                        .qualification_verify === 1
                                      ? "通过"
                                      : _vm.data.driver_info &&
                                        _vm.data.driver_info
                                          .qualification_verify === 2
                                      ? "失败"
                                      : "未认证"
                                  )
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pt-4", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { staticClass: "el-col el-col-4_8" },
            [
              _c("dashboard-card", {
                attrs: {
                  title: "托运人（人）",
                  amount: _vm.data.consignor_count,
                  unit: "",
                  icon: "consignor",
                  link: _vm.getLinkUrl(
                    "/dataquery/consignor?" +
                      (_vm.params.start_time
                        ? "startTime=" +
                          _vm.params.start_time +
                          "&endTime=" +
                          _vm.params.end_time
                        : "")
                  )
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "el-col el-col-4_8" },
            [
              _c("dashboard-card", {
                attrs: {
                  title: "网络货运（家）",
                  amount: _vm.data.agent_count,
                  unit: "",
                  icon: "agent",
                  link: _vm.getLinkUrl(
                    "/dataquery/agent?" +
                      (_vm.params.start_time
                        ? "startTime=" +
                          _vm.params.start_time +
                          "&endTime=" +
                          _vm.params.end_time
                        : "")
                  )
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "el-col el-col-4_8" },
            [
              _c("dashboard-card", {
                attrs: {
                  title: "承运人（人）",
                  amount: _vm.data.carrier_count,
                  unit: "",
                  icon: "carrier",
                  link: _vm.getLinkUrl(
                    "/dataquery/carrier?" +
                      (_vm.params.start_time
                        ? "startTime=" +
                          _vm.params.start_time +
                          "&endTime=" +
                          _vm.params.end_time
                        : "")
                  )
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "el-col el-col-4_8" },
            [
              _c("dashboard-card", {
                attrs: {
                  title: "车辆（辆）",
                  amount: _vm.data.vehicle_count,
                  unit: "",
                  icon: "vehicle",
                  link: _vm.getLinkUrl(
                    "/dataquery/vehicle?" +
                      (_vm.params.start_time
                        ? "startTime=" +
                          _vm.params.start_time +
                          "&endTime=" +
                          _vm.params.end_time
                        : "")
                  )
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "el-col el-col-4_8" },
            [
              _c("dashboard-card", {
                attrs: {
                  title: "运费收入（元）",
                  amount: _vm._f("amountFormatFilter")(_vm.data.income_sum),
                  unit: "",
                  icon: "income",
                  link: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pt-4", attrs: { gutter: 16 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "home-card" },
              [
                _c("div", { staticClass: "title-box" }, [
                  _c("div", { staticClass: "title" }, [_vm._v(" 近期预警 ")])
                ]),
                _c("alarm-list", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingData,
                      expression: "loadingData"
                    },
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["risk_alarm"],
                      expression: "['risk_alarm']"
                    }
                  ],
                  ref: "alarmList",
                  attrs: {
                    data: _vm.alarmData,
                    type: "recently",
                    "no-title": true
                  },
                  on: { showPop: _vm.showPop }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pt-4", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c(
                "chart-container",
                { attrs: { title: "网络货运企业运单量排名(单)" } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "200px" } },
                    [
                      _c("bar-chart", {
                        attrs: {
                          data: _vm.data.agent_waybill_rank_list,
                          "x-field": "value",
                          "y-field": "name"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c(
                "chart-container",
                { attrs: { title: "网络货运企业运费排名(元)" } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "200px" } },
                    [
                      _c("bar-chart", {
                        attrs: {
                          data: _vm.data.agent_freight_rank_list,
                          "value-type": "amount",
                          "x-field": "value",
                          "y-field": "name",
                          color: "green"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c(
                "chart-container",
                { attrs: { title: "车辆运单量排名(单)" } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "200px" } },
                    [
                      _c("bar-chart", {
                        attrs: {
                          data: _vm.data.vehicle_waybill_rank_list,
                          "x-field": "value",
                          "y-field": "name",
                          color: "yellow"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c("chart-container", { attrs: { title: "车辆运费排名(元)" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "200px" } },
                  [
                    _c("bar-chart", {
                      attrs: {
                        data: _vm.data.vehicle_freight_rank_list,
                        "value-type": "amount",
                        "x-field": "value",
                        "y-field": "name",
                        color: "red"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "pt-4", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("chart-container", { attrs: { title: "货物类型" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "200px" } },
                  [
                    _c("pie-chart", {
                      attrs: {
                        data: _vm.data.goods_type_distribution,
                        "angle-field": "value",
                        "color-field": "name"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("chart-container", { attrs: { title: "收发货地址分布" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "200px" } },
                  [
                    _c("china-map", {
                      attrs: {
                        size: "small",
                        "delivery-distribution-data":
                          _vm.data.delivery_province_distribution,
                        "receiver-distribution-data":
                          _vm.data.receiver_province_distribution
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("chart-container", { attrs: { title: "业务类型" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "200px" } },
                  [
                    _c("pie-chart", {
                      attrs: {
                        data: _vm.data.business_type_distribution,
                        "angle-field": "value",
                        "color-field": "name"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("chart-container", { attrs: { title: "运费支付方式" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "200px" } },
                  [
                    _c("pie-chart", {
                      attrs: {
                        data: _vm.data.pay_type_distribution,
                        "angle-field": "value",
                        "color-field": "name",
                        type: "amount"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("alarm-pop", {
        ref: "alarmDialog",
        attrs: {
          "current-id": _vm.currentAlarmId,
          "current-name": _vm.currentName,
          "query-name": _vm.queryName
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }