export const VUE_APP_BASE_APIS = {
  // 本地环境
  localhost: {
    // url: 'http://192.168.7.237:8081/',
    url: 'https://bi.tongren.bsmpre.56ctms.com/',
    // url: 'https://bi.tongren.bsmtest.56ctms.com/',
    destination: 'TongRen',
    name: '本地',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=66420d430b9412d7827b65eac7cd905b'
  },

  // 测试环境
  'insight.tongren.bsmtest.56ctms.com': {
    url: 'https://bi.tongren.bsmtest.56ctms.com/',
    destination: 'TongRen',
    name: '铜仁',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=66420d430b9412d7827b65eac7cd905b'
  },
  'insight.taiyuan.bsmtest.56ctms.com': {
    url: 'https://bi.taiyuan.bsmtest.56ctms.com/',
    destination: 'TaiYuan',
    name: '太原',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=72dfd05a60cf609f5b199c63268a423a'
  },
  'insight.huhehaote.bsmtest.56ctms.com': {
    url: 'https://bi.huhehaote.bsmtest.56ctms.com/',
    destination: 'HuHeHaoTe',
    name: '呼和浩特',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=634370776ff32090cde774ff9120452d'
  },
  'insight.zhuhai.bsmtest.56ctms.com': {
    url: 'https://bi.zhuhai.bsmtest.56ctms.com/',
    destination: 'ZhuHai',
    name: '珠海',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=ea82fcade66e43328fb2ea8f4738278d'
  },
  'insight.anshun.bsmtest.56ctms.com': {
    url: 'https://bi.anshun.bsmtest.56ctms.com/',
    destination: 'AnShun',
    name: '安顺',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=34de8addbd92488e847995d79c77e5f2'
  },
  'insight.dazhou.bsmtest.56ctms.com': {
    url: 'https://bi.dazhou.bsmtest.56ctms.com/',
    destination: 'DaZhou',
    name: '达州',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=9d1978cff2164a179f9e6dd088c37428'
  },
  'insight.suzhou.bsmtest.56ctms.com': {
    url: 'https://bi.suzhou.bsmtest.56ctms.com/',
    destination: 'SuZhou',
    name: '宿州',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=c55b98b16b6d49f3ae389544e3c3d284'
  },
  'insight.linfen.bsmtest.56ctms.com': {
    url: 'https://bi.linfen.bsmtest.56ctms.com/',
    destination: 'LinFen',
    name: '临汾',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=8c904c3a7557452892fc055fffb34201'
  },
  'insight.fuzhou.bsmtest.56ctms.com': {
    url: 'https://bi.fuzhou.bsmtest.56ctms.com/',
    destination: 'FuZhou',
    name: '抚州',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=f6c0a368454a4ce182402068e97d83bb'
  },
  'insight.anqing.bsmtest.56ctms.com': {
    url: 'https://bi.anqing.bsmtest.56ctms.com/',
    destination: 'AnQing',
    name: '安庆',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=c35fc3b7da304156bbace0142d47a79a'
  },
  'insight.huanggang.bsmtest.56ctms.com': {
    url: 'https://bi.huanggang.bsmtest.56ctms.com/',
    destination: 'HuangGang',
    name: '黄冈',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=184face7205b4ef78467d491d6aa1584'
  },
  'insight.shanxian.bsmtest.56ctms.com': {
    url: 'https://bi.shanxian.bsmtest.56ctms.com/',
    destination: 'ShanXian',
    name: '单县',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=3efdb29b14244b7ea1aed4b5ffb89a2f'
  },
  'insight.yingquan.bsmtest.56ctms.com': {
    url: 'https://bi.yingquan.bsmtest.56ctms.com/',
    destination: 'YingQuan',
    name: '颍泉',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=13006f577cfe45758f451d8f3e61d468'
  },
  'insight.jieshou.bsmtest.56ctms.com': {
    url: 'https://bi.jieshou.bsmtest.56ctms.com/',
    destination: 'JieShou',
    name: '界首',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=2478154cee144a77af945c86cf6ec344'
  },
  'insight.yuncheng.bsmtest.56ctms.com': {
    url: 'https://bi.yuncheng.bsmtest.56ctms.com/',
    destination: 'YunCheng',
    name: '运城',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=3b8ca5db0b98488e984cc3336dc02656'
  },
  'insight.yangquan.bsmtest.56ctms.com': {
    url: 'https://bi.yangquan.bsmtest.56ctms.com/',
    destination: 'YangQuan',
    name: '阳泉',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=13cc7f6f2b2b495fafdf49db3773d119'
  },
  'insight.fuqing.bsmtest.56ctms.com': {
    url: 'https://bi.fuqing.bsmtest.56ctms.com/',
    destination: 'FuQing',
    name: '福清',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=443412ca43f5470aa3a2fb66279ca8ea'
  },
  'insight.zhengzhou.bsmtest.56ctms.com': {
    url: 'https://bi.zhengzhou.bsmtest.56ctms.com/',
    destination: 'ZhengZhou',
    name: '郑州',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=cae2983459ad4f85b2e02bff683cef94'
  },
  'insight.wuhu.bsmtest.56ctms.com': {
    url: 'https://bi.wuhu.bsmtest.56ctms.com/',
    destination: 'WuHu',
    name: '芜湖',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=6b183c6ff41843979687de75dce63155'
  },
  'insight.chuzhou.bsmtest.56ctms.com': {
    url: 'https://bi.chuzhou.bsmtest.56ctms.com/',
    destination: 'ChuZhou',
    name: '滁州',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=f270a95d4701454e8eedb9d6be595d4f'
  },
  'insight.rest.bsmtest.56ctms.com': {
    url: 'https://bi.rest.bsmtest.56ctms.com/',
    destination: 'Rest',
    name: '全国',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=ef12fa8f90fd468986736719b125b671'
  },
  'insight.yingdong.bsmtest.56ctms.com': {
    url: 'https://bi.yingdong.bsmtest.56ctms.com/',
    destination: 'YingDong',
    name: '颖东',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=68bf99bfa555482fa33e7c5fde69162d'
  },
  'insight.haoyunbao.bsmtest.56ctms.com': {
    url: 'https://bi.haoyunbao.bsmtest.56ctms.com/',
    destination: 'HaoYunBao',
    name: '好运宝',
    datav:
      'https://datav.aliyuncs.com/share/608db9d5b98c272cf6c7c516eebb06e9?destination=a4284206ba69452b880fb9c1f61018ed'
  },

  // 预发环境
  'insight.bsmpre.56ctms.com': {
    url: 'https://bi.tongren.bsmpre.56ctms.com/',
    destination: 'TongRen',
    name: '铜仁',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=66420d430b9412d7827b65eac7cd905b'
  },
  'insight.tongren.bsmpre.56ctms.com': {
    url: 'https://bi.tongren.bsmpre.56ctms.com/',
    destination: 'TongRen',
    name: '铜仁',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=66420d430b9412d7827b65eac7cd905b'
  },
  'insight.taiyuan.bsmpre.56ctms.com': {
    url: 'https://bi.taiyuan.bsmpre.56ctms.com/',
    destination: 'TaiYuan',
    name: '太原',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=72dfd05a60cf609f5b199c63268a423a'
  },
  'insight.huhehaote.bsmpre.56ctms.com': {
    url: 'https://bi.huhehaote.bsmpre.56ctms.com/',
    destination: 'HuHeHaoTe',
    name: '呼和浩特',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=634370776ff32090cde774ff9120452d'
  },
  'insight.zhuhai.bsmpre.56ctms.com': {
    url: 'https://bi.zhuhai.bsmpre.56ctms.com/',
    destination: 'ZhuHai',
    name: '珠海',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=ea82fcade66e43328fb2ea8f4738278d'
  },
  'insight.anshun.bsmpre.56ctms.com': {
    url: 'https://bi.anshun.bsmpre.56ctms.com/',
    destination: 'AnShun',
    name: '安顺',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=34de8addbd92488e847995d79c77e5f2'
  },
  'insight.dazhou.bsmpre.56ctms.com': {
    url: 'https://bi.dazhou.bsmpre.56ctms.com/',
    destination: 'DaZhou',
    name: '达州',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=9d1978cff2164a179f9e6dd088c37428'
  },
  'insight.suzhou.bsmpre.56ctms.com': {
    url: 'https://bi.suzhou.bsmpre.56ctms.com/',
    destination: 'SuZhou',
    name: '宿州',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=c55b98b16b6d49f3ae389544e3c3d284'
  },
  'insight.linfen.bsmpre.56ctms.com': {
    url: 'https://bi.linfen.bsmpre.56ctms.com/',
    destination: 'LinFen',
    name: '临汾',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=8c904c3a7557452892fc055fffb34201'
  },
  'insight.fuzhou.bsmpre.56ctms.com': {
    url: 'https://bi.fuzhou.bsmpre.56ctms.com/',
    destination: 'FuZhou',
    name: '抚州',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=f6c0a368454a4ce182402068e97d83bb'
  },
  'insight.anqing.bsmpre.56ctms.com': {
    url: 'https://bi.anqing.bsmpre.56ctms.com/',
    destination: 'AnQing',
    name: '安庆',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=c35fc3b7da304156bbace0142d47a79a'
  },
  'insight.huanggang.bsmpre.56ctms.com': {
    url: 'https://bi.huanggang.bsmpre.56ctms.com/',
    destination: 'HuangGang',
    name: '黄冈',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=184face7205b4ef78467d491d6aa1584'
  },
  'insight.shanxian.bsmpre.56ctms.com': {
    url: 'https://bi.shanxian.bsmpre.56ctms.com/',
    destination: 'ShanXian',
    name: '单县',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=3efdb29b14244b7ea1aed4b5ffb89a2f'
  },
  'insight.yingquan.bsmpre.56ctms.com': {
    url: 'https://bi.yingquan.bsmpre.56ctms.com/',
    destination: 'YingQuan',
    name: '颍泉',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=13006f577cfe45758f451d8f3e61d468'
  },
  'insight.jieshou.bsmpre.56ctms.com': {
    url: 'https://bi.jieshou.bsmpre.56ctms.com/',
    destination: 'JieShou',
    name: '界首',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=2478154cee144a77af945c86cf6ec344'
  },
  'insight.yuncheng.bsmpre.56ctms.com': {
    url: 'https://bi.yuncheng.bsmpre.56ctms.com/',
    destination: 'YunCheng',
    name: '运城',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=3b8ca5db0b98488e984cc3336dc02656'
  },
  'insight.yangquan.bsmpre.56ctms.com': {
    url: 'https://bi.yangquan.bsmpre.56ctms.com/',
    destination: 'YangQuan',
    name: '阳泉',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=13cc7f6f2b2b495fafdf49db3773d119'
  },
  'insight.fuqing.bsmpre.56ctms.com': {
    url: 'https://bi.fuqing.bsmpre.56ctms.com/',
    destination: 'FuQing',
    name: '福清',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=443412ca43f5470aa3a2fb66279ca8ea'
  },
  'insight.zhengzhou.bsmpre.56ctms.com': {
    url: 'https://bi.zhengzhou.bsmpre.56ctms.com/',
    destination: 'ZhengZhou',
    name: '郑州',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=cae2983459ad4f85b2e02bff683cef94'
  },
  'insight.wuhu.bsmpre.56ctms.com': {
    url: 'https://bi.wuhu.bsmpre.56ctms.com/',
    destination: 'WuHu',
    name: '芜湖',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=6b183c6ff41843979687de75dce63155'
  },
  'insight.chuzhou.bsmpre.56ctms.com': {
    url: 'https://bi.chuzhou.bsmpre.56ctms.com/',
    destination: 'ChuZhou',
    name: '滁州',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=f270a95d4701454e8eedb9d6be595d4f'
  },
  'insight.rest.bsmpre.56ctms.com': {
    url: 'https://bi.rest.bsmpre.56ctms.com/',
    destination: 'Rest',
    name: '全国',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=ef12fa8f90fd468986736719b125b671'
  },
  'insight.yingdong.bsmpre.56ctms.com': {
    url: 'https://bi.yingdong.bsmpre.56ctms.com/',
    destination: 'YingDong',
    name: '颖东',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=68bf99bfa555482fa33e7c5fde69162d'
  },
  'insight.haoyunbao.bsmpre.56ctms.com': {
    url: 'https://bi.haoyunbao.bsmpre.56ctms.com/',
    destination: 'HaoYunBao',
    name: '好运宝',
    datav:
      'https://datav.aliyuncs.com/share/fd760a86804801d24fe580baad1e989b?destination=a4284206ba69452b880fb9c1f61018ed'
  },

  // 线上环境
  'insight.bsm.kuaihuoyun.com': {
    url: 'https://bi.guizhou.zhihuiwuliu.org.cn/',
    destination: 'TongRen',
    name: '铜仁',
    datav:
      'https://datav.aliyuncs.com/share/da1816f9bc14869f16cf33704d759a46?destination=66420d430b9412d7827b65eac7cd905b'
  },
  'insight.guizhou.kuaihuoyun.com': {
    url: 'https://bi.guizhou.zhihuiwuliu.org.cn/',
    destination: 'TongRen',
    name: '铜仁',
    datav:
      'https://datav.aliyuncs.com/share/da1816f9bc14869f16cf33704d759a46?destination=66420d430b9412d7827b65eac7cd905b'
  },
  'insight.guizhou.zhihuiwuliu.org.cn': {
    url: 'https://bi.guizhou.zhihuiwuliu.org.cn/',
    destination: 'TongRen',
    name: '铜仁',
    datav:
      'https://datav.aliyuncs.com/share/da1816f9bc14869f16cf33704d759a46?destination=66420d430b9412d7827b65eac7cd905b'
  },
  'insight.shanxi.kuaihuoyun.com': {
    url: 'https://bi.shanxi.zhihuiwuliu.org.cn/',
    destination: 'TaiYuan',
    name: '太原',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=72dfd05a60cf609f5b199c63268a423a'
  },
  'insight.shanxi.zhihuiwuliu.org.cn': {
    url: 'https://bi.shanxi.zhihuiwuliu.org.cn/',
    destination: 'TaiYuan',
    name: '太原',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=72dfd05a60cf609f5b199c63268a423a'
  },
  'insight.neimenggu.zhihuiwuliu.org.cn': {
    url: 'https://bi.neimenggu.zhihuiwuliu.org.cn/',
    destination: 'HuHeHaoTe',
    name: '呼和浩特',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=634370776ff32090cde774ff9120452d'
  },
  'insight.guangdong.zhihuiwuliu.org.cn': {
    url: 'https://bi.guangdong.zhihuiwuliu.org.cn/',
    destination: 'ZhuHai',
    name: '珠海',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=ea82fcade66e43328fb2ea8f4738278d'
  },
  'insight.anshun.zhihuiwuliu.org.cn': {
    url: 'https://bi.anshun.zhihuiwuliu.org.cn/',
    destination: 'AnShun',
    name: '安顺',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=34de8addbd92488e847995d79c77e5f2'
  },
  'insight.dazhou.zhihuiwuliu.org.cn': {
    url: 'https://bi.dazhou.zhihuiwuliu.org.cn/',
    destination: 'DaZhou',
    name: '达州',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=9d1978cff2164a179f9e6dd088c37428'
  },
  'insight.suzhou.zhihuiwuliu.org.cn': {
    url: 'https://bi.suzhou.zhihuiwuliu.org.cn/',
    destination: 'SuZhou',
    name: '宿州',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=c55b98b16b6d49f3ae389544e3c3d284'
  },
  'insight.linfen.zhihuiwuliu.org.cn': {
    url: 'https://bi.linfen.zhihuiwuliu.org.cn/',
    destination: 'LinFen',
    name: '临汾',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=8c904c3a7557452892fc055fffb34201'
  },
  'insight.fuzhou.zhihuiwuliu.org.cn': {
    url: 'https://bi.fuzhou.zhihuiwuliu.org.cn/',
    destination: 'FuZhou',
    name: '抚州',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=f6c0a368454a4ce182402068e97d83bb'
  },
  'insight.anqing.zhihuiwuliu.org.cn': {
    url: 'https://bi.anqing.zhihuiwuliu.org.cn/',
    destination: 'AnQing',
    name: '安庆',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=c35fc3b7da304156bbace0142d47a79a'
  },
  'insight.huanggang.zhihuiwuliu.org.cn': {
    url: 'https://bi.huanggang.zhihuiwuliu.org.cn/',
    destination: 'HuangGang',
    name: '黄冈',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=184face7205b4ef78467d491d6aa1584'
  },
  'insight.shanxian.zhihuiwuliu.org.cn': {
    url: 'https://bi.shanxian.zhihuiwuliu.org.cn/',
    destination: 'ShanXian',
    name: '单县',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=3efdb29b14244b7ea1aed4b5ffb89a2f'
  },
  'insight.yingquan.zhihuiwuliu.org.cn': {
    url: 'https://bi.yingquan.zhihuiwuliu.org.cn/',
    destination: 'YingQuan',
    name: '颍泉',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=13006f577cfe45758f451d8f3e61d468'
  },
  'insight.jieshou.zhihuiwuliu.org.cn': {
    url: 'https://bi.jieshou.zhihuiwuliu.org.cn/',
    destination: 'JieShou',
    name: '界首',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=2478154cee144a77af945c86cf6ec344'
  },
  'insight.yuncheng.zhihuiwuliu.org.cn': {
    url: 'https://bi.yuncheng.zhihuiwuliu.org.cn/',
    destination: 'YunCheng',
    name: '运城',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=3b8ca5db0b98488e984cc3336dc02656'
  },
  'insight.yangquan.zhihuiwuliu.org.cn': {
    url: 'https://bi.yangquan.zhihuiwuliu.org.cn/',
    destination: 'YangQuan',
    name: '阳泉',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=13cc7f6f2b2b495fafdf49db3773d119'
  },
  'insight.fuqing.zhihuiwuliu.org.cn': {
    url: 'https://bi.fuqing.zhihuiwuliu.org.cn/',
    destination: 'FuQing',
    name: '福清',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=443412ca43f5470aa3a2fb66279ca8ea'
  },
  'insight.zhengzhou.zhihuiwuliu.org.cn': {
    url: 'https://bi.zhengzhou.zhihuiwuliu.org.cn/',
    destination: 'ZhengZhou',
    name: '郑州',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=cae2983459ad4f85b2e02bff683cef94'
  },
  'insight.wuhu.zhihuiwuliu.org.cn': {
    url: 'https://bi.wuhu.zhihuiwuliu.org.cn/',
    destination: 'WuHu',
    name: '芜湖',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=6b183c6ff41843979687de75dce63155'
  },
  'insight.chuzhou.zhihuiwuliu.org.cn': {
    url: 'https://bi.chuzhou.zhihuiwuliu.org.cn/',
    destination: 'ChuZhou',
    name: '滁州',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=f270a95d4701454e8eedb9d6be595d4f'
  },
  'insight.rest.zhihuiwuliu.org.cn': {
    url: 'https://bi.rest.zhihuiwuliu.org.cn/',
    destination: 'Rest',
    name: '全国',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=ef12fa8f90fd468986736719b125b671'
  },
  'insight.yingdong.zhihuiwuliu.org.cn': {
    url: 'https://bi.yingdong.zhihuiwuliu.org.cn/',
    destination: 'YingDong',
    name: '颖东',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=68bf99bfa555482fa33e7c5fde69162d'
  },
  'insight.haoyunbao.zhihuiwuliu.org.cn': {
    url: 'https://bi.haoyunbao.zhihuiwuliu.org.cn/',
    destination: 'HaoYunBao',
    name: '好运宝',
    datav:
      'https://datav.aliyuncs.com/share/da51c5a2261fbeaa04fad056e149ac9c?destination=a4284206ba69452b880fb9c1f61018ed'
  }
}
