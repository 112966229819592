// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.waybill-log-dialog {\n  z-index: 2000000;\n}\n.waybill-log-dialog .content-wrap {\n    display: block;\n}\n.waybill-log-dialog .content-wrap .key {\n      color: #363636;\n      font-weight: bold;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
