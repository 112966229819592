var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "120px",
            size: "small"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block" },
              attrs: { label: "是否需要回执", prop: "feedback_type" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", disabled: _vm.isDetail() },
                  model: {
                    value: _vm.form.feedback_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "feedback_type", $$v)
                    },
                    expression: "form.feedback_type"
                  }
                },
                _vm._l(_vm.feedback_type_map, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { value: item.value, label: item.label }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block", "margin-left": "20px" },
              attrs: { label: "接收人", prop: "receiver" }
            },
            [
              !_vm.isDetail()
                ? _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.isDetail(),
                        filterable: "",
                        remote: "",
                        "default-first-option": "",
                        placeholder: "请填写接收人",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.queryLoading
                      },
                      model: {
                        value: _vm.form.receiver,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "receiver", $$v)
                        },
                        expression: "form.receiver"
                      }
                    },
                    _vm._l(_vm.receiverList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.username, value: item.username }
                      })
                    }),
                    1
                  )
                : _c("el-input", {
                    attrs: { disabled: _vm.isDetail() },
                    model: {
                      value: _vm.form.receiver,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "receiver", $$v)
                      },
                      expression: "form.receiver"
                    }
                  })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "协同标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isDetail(),
                  maxlength: "64",
                  placeholder: "请输入协同标题"
                },
                model: {
                  value: _vm.form.title,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "附件", prop: "file" } },
            [
              _c("multi-upload-file", {
                attrs: {
                  "file-list": _vm.fileList,
                  limit: 5,
                  disabled: _vm.isDetail()
                },
                model: {
                  value: _vm.form.fileUrls,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "fileUrls", $$v)
                  },
                  expression: "form.fileUrls"
                }
              }),
              !_vm.isDetail()
                ? _c("section", { staticClass: "text-danger" }, [
                    _vm._v(" 支持图片、doc、xls格式文件上传，5M以内，最多5个 ")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "协同内容", prop: "content" } },
            [
              _vm.isDetail()
                ? _c("div", {
                    staticClass: "textarea-content",
                    domProps: { innerHTML: _vm._s(_vm.form.content) }
                  })
                : _c("tinymce", {
                    attrs: { height: "calc(100vh - 500px)" },
                    model: {
                      value: _vm.form.content,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content"
                    }
                  })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { "text-align": "center" } },
            [
              !_vm.isDetail()
                ? _c("el-button", { on: { click: _vm.handlePreview } }, [
                    _vm._v(" 取消 ")
                  ])
                : _vm._e(),
              !_vm.isDetail()
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["notice_create"],
                          expression: "['notice_create']"
                        }
                      ],
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit()
                        }
                      }
                    },
                    [_vm._v(" 创建 ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }