























import { Vue, Component, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

export enum EnumActions {
  publish = 'publish',
  disable = 'disable',
  enable = 'enable',
  add = 'add',
  edit = 'edit',
  delete = 'delete',
  detail = 'detail',
  // copyAdd = 'coypAdd'
}

interface IAction {
  type: EnumActions
  label: string | Function
  disabled?: boolean | Function
}

@Component
export default class ActionSetting extends Vue {
  @Prop({ default: '' }) value: string | number
  @Prop({
    default: function() {
      return {}
    }
  }) rowData :any

  @Prop({
    default() {
      return []
    }
  }) actionOptions: IAction[]

  private resolveProp(prop: any) {
    if (typeof prop === 'function') {
      return prop(this.value, this.rowData)
    } else {
      return prop
    }
  }

  private handleCommand(command) {
    this.$emit('actionCommand', command, this.value, cloneDeep(this.rowData))
  }
}
