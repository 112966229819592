import { ApplyAccessType } from '@/common/constant'

export const Description = {
  // 托运人
  consignor: {
    business_address: { label: '营业地址', type: 'text' },
    business_license_pic: { label: '营业执照照片', type: 'picture' },
    contacts_name: { label: '企业联系人', type: 'text' },
    legal_person_identity: { label: '法人代表身份证号', type: 'text' },
    legal_person_name: { label: '法人代表姓名', type: 'text' },
    name: { label: '名称', type: 'text' },
    phone: { label: '企业联系人号码', type: 'text' },
    register_address: { label: '注册地址', type: 'text' },
    social_credit_code: { label: '证件号码', type: 'text' },
    household_address: { label: '户籍地址', type: 'text' },
    identity: { label: '身份证号', type: 'text' },
    identity_frontal_pic: { label: '身份证正面照', type: 'picture' },
    identity_reverse_pic: { label: '身份证反面照', type: 'picture' },
    permanent_address: { label: '常住地址', type: 'text' },
    update_by_name: { label: '更新企业', type: 'text' },
    update_time: { label: '更新时间', type: 'time' },
    type: { label: '类型', type: 'text' }
  },
  // 网络货运企业
  agent: {
    // "access_apply_type": { label: "接入类型", type: 'text' },
    access_apply_type_desc: { label: '接入类型描述', type: 'text' },
    business_address: { label: '营业地址', type: 'text' },
    business_license_pic: { label: '营业执照照片', type: 'picture' },
    contacts_name: { label: '企业联系人', type: 'text' },
    legal_person_identity: { label: '法人代表身份证号', type: 'text' },
    legal_person_name: { label: '法人代表姓名', type: 'text' },
    licence_effect_from: { label: '道路运输许可证有效期开始时间', type: 'time' },
    licence_effect_to: { label: '道路运输许可证有效期结束时间', type: 'time' },
    licence_no: { label: '道路运输经营许可证号', type: 'text' },
    licence_pic: { label: '道路运输经营许可证照片', type: 'picture' },
    name: { label: '名称', type: 'text' },
    // "parent_did": { label: "母公司Did", type: 'text' },
    parent_name: {
      label: '母公司名称',
      type: 'link_parent_agent',
      hidden: (value, row) => {
        // eslint-disable-next-line eqeqeq
        return row.access_apply_type == ApplyAccessType.Agent
      }
    },
    // "parent_social_credit_code": { label: "母公司Did", type: 'text' },
    phone: { label: '企业联系人号码', type: 'text' },
    register_address: { label: '注册地址', type: 'text' },
    social_credit_code: { label: '证件号码', type: 'text' }
  },
  // 承运人
  carrier: {
    type: { label: '类型', type: 'text' },
    business_address: { label: '营业地址', type: 'text' },
    business_license_pic: { label: '营业执照照片', type: 'picture' },
    contacts_name: { label: '企业联系人', type: 'text' },
    legal_person_identity: { label: '法人代表身份证号', type: 'text' },
    legal_person_name: { label: '法人代表姓名', type: 'text' },
    name: { label: '名称', type: 'text' },
    phone: { label: '企业联系人号码', type: 'text' },
    register_address: { label: '注册地址', type: 'text' },
    social_credit_code: { label: '证件号码', type: 'text' },
    household_address: { label: '户籍地址', type: 'text' },
    identity: { label: '身份证号', type: 'text' },
    identity_frontal_pic: { label: '身份证正面照', type: 'picture' },
    identity_reverse_pic: { label: '身份证反面照', type: 'picture' },
    permanent_address: { label: '常住地址', type: 'text' },
    update_by_name: { label: '更新企业', type: 'text' },
    update_time: { label: '更新时间', type: 'time' }
  },
  // 司机
  driver: {
    driving_class: { label: '准驾车型', type: 'text' },
    household_address: { label: '身份证地址', type: 'text' },
    identity: { label: '身份证号', type: 'text' },
    identity_frontal_pic: { label: '身份证正面照', type: 'picture' },
    identity_reverse_pic: { label: '身份证反面照', type: 'picture' },
    licence: { label: '驾驶证编号', type: 'text' },
    licence_frontal_pic: { label: '驾驶证正面照', type: 'picture' },
    licence_issuing: { label: '发证机关', type: 'text' },
    licence_reverse_pic: { label: '驾驶证反面照', type: 'picture' },
    name: { label: '姓名', type: 'text' },
    party_member_alias: { label: '是否党员', type: 'text' },
    veteran_alias: { label: '是否退伍军人', type: 'text' },
    permanent_address: { label: '常住地址', type: 'text' },
    phone: { label: '手机号', type: 'text' },
    qualification_cert_frontal_pic: { label: '从业资格证正面照', type: 'picture' },
    qualification_cert_no: { label: '从业资格证号', type: 'text' },
    qualification_cert_reverse_pic: { label: '从业资格证反面照', type: 'picture' },
    qualification_cert_valid_to: { label: '从业资格证有效期截止时间', type: 'time' },
    update_time: { label: '更新时间', type: 'time' },
    valid_from: { label: '驾驶证有效期起始时间', type: 'time' },
    valid_to: { label: '驾驶证有效期结束时间', type: 'time' },
    update_by_name: { label: '更新企业', type: 'text' },
    identity_frontal_dto_typeMatch: { label: '身份证正面照类型匹配', type: 'text' },
    identity_frontal_dto_standardDesc: { label: '身份证正面照拍摄规范', type: 'text' },
    identity_frontal_dto_riskType: { label: '身份证正面照伪造风险', type: 'text' },
    identity_reverse_dto_typeMatch: { label: '身份证反面照类型匹配', type: 'text' },
    identity_reverse_dto_standardDesc: { label: '身份证反面照拍摄规范', type: 'text' },
    identity_reverse_dto_riskType: { label: '身份证反面照伪造风险', type: 'text' },
    licence_frontal_dto_typeMatch: { label: '驾驶证正面照类型匹配', type: 'text' },
    licence_frontal_dto_standardDesc: { label: '驾驶证正面照拍摄规范', type: 'text' },
    licence_frontal_dto_riskType: { label: '驾驶证正面照伪造风险', type: 'text' }
  },
  // 车辆
  vehicle: {
    driving_licence_frontal_pic: { label: '行驶证正面照', type: 'picture' },
    driving_licence_reverse_pic: { label: '行驶证反面照', type: 'picture' },
    driving_licence_valid_to: { label: '行驶证有效期', type: 'time' },
    energy_type: { label: '车辆能源类型', type: 'text' },
    issue_date: { label: '行驶证发证日期', type: 'time' },
    licence_issuing: { label: '行驶证发证机关发', type: 'text' },
    master_name: { label: '业户名称', type: 'text' },
    owner: { label: '车辆所有人', type: 'text' },
    plate_color_desc: { label: '车辆颜色', type: 'text' },
    plate_number: { label: '车辆号码', type: 'text' },
    ratified_load_capacity: { label: '车辆核定质量', type: 'weight' },
    register_date: { label: '行驶证注册日期', type: 'time' },
    tonnage: { label: '车辆总质量', type: 'weight' },
    trailer_plate_number: { label: '挂车牌号', type: 'text' },
    transport_business_cert_no: { label: '道路运输经营许可证号', type: 'text' },
    transport_licence_frontal_pic: { label: '运输证照片(正面)', type: 'picture' },
    transport_licence_no: { label: '道路运输证号', type: 'text' },
    transport_licence_reverse_pic: { label: '运输证照片(反面)', type: 'picture' },
    transport_licence_valid_to: { label: '道路运输证有效期', type: 'time' },
    type_desc: { label: '车辆类型', type: 'text' },
    use_character: { label: '车辆使用性质', type: 'text' },
    vin: { label: '车辆识别代码', type: 'text' },
    driving_licence_dto_typeMatch: { label: '行驶证类型匹配', type: 'text' },
    driving_licence_dto_standardDesc: { label: '行驶证拍摄规范', type: 'text' },
    driving_licence_dto_riskType: { label: '行驶证伪造风险', type: 'text' }
  }
}
