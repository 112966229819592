












































import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  name: 'alarmList'
})
export default class extends Vue {
  @Prop() data: any
  @Prop() type: string
  @Prop() noTitle: boolean

  getPreList(next:any) {
    this.$emit('getPreList', next)
  }

  showPop(item) {
    this.$emit('showPop', item)
  }

  momentTime(time) {
    return moment(time).format('YYYY-MM-DD')
  }
}

