
































































































































































































































































































































import { Vue, Component, ProvideReactive } from "vue-property-decorator";
import {
  getRegulationWaybillIds,
  getRegulationRiskChart,
  getRegulationRiskRule,
  getRegulationSubjectInfo,
  getRegulationSubjectRisk,
  getRegulationWaybillChart,
  getRegulationWaybillRank,
  getRegulationWaybillFeeChart,
  IGetRegulationInfoParams
} from "@/api/regulation";
import { IAgentDashboardData } from "@/api/types";
import {
  BarChart,
  PieChart,
  LineAreaChart,
  LineChart,
  DonutChart,
  RadarChart,
  BubbleChart,
  StackedColumnLineChart,
  GaugeChart
} from "@/common/components/Charts";
import {
  ChinaMap,
  MultiLineChart,
  MultiGaugeChart,
  DashboardHeader,
  DashboardCard,
  ChartContainer,
  TicketCard,
  TableList
} from "@/common/business-components";
import { SubjectType, SubjectTypeEnum } from "@/common/constant";

import { processTableData, processRiskScoreData } from "@/utils";

import MapSearchSelect from "@/views/components/TypeMap/map/MapSearchSelect.vue";
import BsmTable from "@/views/components/BsmTable.vue";

import moment from "moment";
import _ from "lodash";
import { weight, distance, amountFormatFilter } from "@/common/filters";
import { getValueStatApi } from "@/api/tax";
const comp = Vue.component("spans", {
  render: function(createElement) {
    const rowData = this.$attrs["row-data"];
    const field = this.$attrs.data;
    const filter = this.$attrs.filter;
    const style = this.$attrs.style || {};
    const temp = [];
    const data = rowData[field];
    if (data instanceof Array) {
      rowData[field] &&
        rowData[field].forEach(d => {
          let v = d;
          if (filter) {
            v = filter(d);
          }
          const dom = createElement("div", {
            style: style,
            domProps: {
              innerHTML: v
            }
          });
          temp.push(dom);
        });
    } else {
      temp.push(
        createElement("div", {
          style: style,
          domProps: {
            innerHTML: data
          }
        })
      );
    }

    return createElement(
      "div",
      {
        style: style
      },
      temp
    );
  }
});

const TimeType = {
  InvoiceTime: 1,
  ReportTime: 2
};
const bubbleConfig: any = {
  xField: "goodsWeight",
  yField: "mile",
  colorField: "averageFee",
  sizeField: "waybillCount",

  xAxis: {
    type: "log",
    label: {
      formatter: v => {
        return parseInt(v).toFixed(0);
      }
    },
    title: { text: "重量/t" }
  },
  yAxis: {
    label: {
      formatter: v => {
        return parseInt(v).toFixed(0);
      }
    },
    title: { text: "运输距离/km" }
  },
  meta: {
    goodsWeight: {
      alias: "重量",
      formatter: v => {
        return weight(v, "t");
      }
    },
    mile: {
      alias: "距离",
      formatter: v => {
        return distance(v);
      }
    },
    averageFee: {
      alias: "金额",
      formatter: v => {
        return amountFormatFilter(v);
      }
    },
    waybillCount: {
      alias: "数量"
    }
  },
  tooltip: {
    visible: true,
    fields: ["goodsWeight", "mile", "averageFee", "waybillCount"]
  }
};

@Component({
  name: "RegulationDetail",
  components: {
    ChartContainer,
    LineAreaChart,
    LineChart,
    DashboardHeader,
    DashboardCard,
    TicketCard,
    BarChart,
    PieChart,
    DonutChart,
    ChinaMap,
    MultiLineChart,
    RadarChart,
    BsmTable,
    BubbleChart,
    MapSearchSelect,
    StackedColumnLineChart,
    GaugeChart,
    MultiGaugeChart,
    TableList
  }
})
export default class extends Vue {
  @ProvideReactive("dashboard") timeFormat = "MM.DD";
  subjectEnum = SubjectTypeEnum;
  isDialogVisible = false;
  loadding = false;
  bubbleConfig = bubbleConfig;

  subjectSearchConfig: any = {
    field: "agent"
  };

  riskRuleTableOptions: any = [
    {
      field: "exceptionName",
      label: "异常类型"
    },
    {
      field: "ruleNames",
      label: "规则简称",
      component: comp,
      attrs: {
        data: "ruleNames"
      }
    },
    {
      field: "ruleLevelAlias",
      label: "规则级别",
      component: comp,
      width: 100,
      attrs: {
        data: "ruleLevelAlias"
      }
    },
    {
      field: "receivedList",
      label: "已签收运单风控比例",
      component: comp,
      attrs: {
        style: {
          width: "100px",
          display: "inline-block"
        },
        data: "receivedList"
      }
    },
    {
      field: "payedList",
      label: "已支付运单风控比例",
      component: comp,
      attrs: {
        style: {
          width: "100px",
          display: "inline-block"
        },
        data: "payedList"
      }
    }
  ];

  BusinessTableOptions: any = [
    {
      field: "waybillNum",
      label: "运单笔数"
    },
    {
      field: "bizValue",
      label: "运费收入（元）",
      filter: "p-money"
    },
    {
      field: "freightExpend",
      label: "运费支出（元）",
      filter: "p-money"
    },
    {
      field: "freightRealExpend",
      label: "运费实际支出（元）",
      filter: "p-money"
    },
    {
      field: "valueAddedTax",
      label: "缴纳增值税额（元）",
      filter: "p-money"
    },
    {
      field: "taxValue",
      label: "税额对应产值（元）",
      filter: "p-money"
    },
    {
      field: "diffRate",
      label: "税额产值与业务产值差异率",
      width: 200
    }
  ];

  IndustrialParkBusinessOptions: any = [
    {
      field: "agentName",
      label: "企业名称"
    },
    ...this.BusinessTableOptions
  ];

  AgentBusinessTableOptions: any = [
    {
      field: "scopeType",
      label: "业务类型"
    },
    ...this.BusinessTableOptions
  ];
  companyBusinessTableData = {
    data: [],
    total: 0,
    curPage: 1
  };
  selType = 1;

  totalTax = 0;

  detail: any = {};

  subjectInfo: any = {};

  regulationRadarData: {
    count: number;
    exceptionName: string;
    exceptionType: number;
  }[] = [];

  exceptionTitleList: {
    exceptionName: string;
    exceptionType: number;
    exceptionTypeName: string;
    payedCount: number;
    receivedCount: number;
    ruleLevelAlias: string;
    ruleName: string;
  }[] = [];

  riskScoreLineChartConfig: any = {
    xAxis: { line: { visible: true } },
    yAxis: { line: { visible: true } },
    padding: [10],
    legend: { visible: false }
  };

  subjectRiskScore: any = {};
  subjectRiskScoreStatistic: any = {
    onLine: {
      value: 0
    },
    credit: {
      value: 0
    },
    activity: {
      value: 0
    }
  };

  subjectRiskScoreDataList = {
    columnData: [],
    lineData: []
  }; // 评分混合图

  exceptionName: string;
  ids: string[] = [];

  riskRuleTableData: any = [];
  totalRiskRuleTableData: any = [];
  waybillChartData: any = [];

  waybillRankData: any = {
    agent_waybill_count_rank: [],
    carrier_waybill_count_rank: [],
    consignor_waybill_count_rank: [],
    payee_waybill_count_rank: []
  };

  waybillCount = 0;
  waybillFeeChartData: any = {
    expense_detail_list: [],
    income_detail_list: []
  };

  exceptionType: any;
  params: IGetRegulationInfoParams = {
    start_time: moment()
      .subtract(1, "month")
      .startOf("month")
      .valueOf(),
    end_time: moment()
      .startOf("month")
      .valueOf(),
    subject_did: "",
    business_mode: 0,
    subject_type: SubjectType.IndustrialPark,
    time_type: TimeType.InvoiceTime
  };

  subjectType: number = this.params.subject_type;
  subjectDid: string = this.params.subject_did;
  created() {
    this.initQueryParams();
    this.init();
  }

  initQueryParams() {
    if (this.$route && this.$route.query) {
      this.params = Object.assign({}, this.params, this.$route.query);
      this.params.start_time = Number(this.params.start_time);
      this.params.end_time = Number(this.params.end_time);
      this.params.business_mode = Number(this.params.business_mode);
      this.params.subject_type = Number(this.params.subject_type);
      this.subjectType = this.params.subject_type;
      this.subjectDid = this.params.subject_did;
    }
  }

  // 初始化：获取主体信息以及图标数据
  async init() {
    this.params = Object.assign(this.params, {
      subject_type: this.subjectType,
      subject_did: this.subjectDid
    });

    this.getChartInfo();
  }

  // 获取图标数据
  async getChartInfo() {
    await Promise.all([
      this.getSubjectInfo(),
      this.getSubjectRiskInfo(),
      this.getRadarChartData(),
      this.getRiskRuleTableData(),
      this.getWaybillChart(),
      this.getWaybillRank(),
      this.getWaybillFeeChart(),
      this.getCompanyBusiness()
    ]);
  }

  // 获取主体信息
  async getSubjectInfo() {
    const { data } = await getRegulationSubjectInfo(this.params);
    this.subjectInfo = data;
  }

  handleSelType() {
    this.companyBusinessTableData.curPage = 1;
    this.getCompanyBusiness();
  }

  async getCompanyBusiness() {
    if (this.isIndustryPark || this.params.subject_type === SubjectType.Agent) {
      const { data } = await getValueStatApi({
        startMonth: moment(this.params.start_time).format("YYYY-MM-01"),
        endMonth: moment(this.params.end_time).format("YYYY-MM-01"),
        subjectType: this.isIndustryPark ? 1 : 2,
        page: {
          index: this.companyBusinessTableData.curPage,
          limit: 10
        },
        type: this.isIndustryPark ? this.selType : null
      });

      const newData = [];
      if (data?.items) {
        const options = this.getBusinessTableOptions();
        data.items.forEach(item => {
          const obj: any = {};
          options.forEach(it => {
            if (it.filter === "p-money") {
              obj[it.field] = item[it.field] ? item[it.field] / 1000 : 0;
            } else if (it.field === "scopeType") {
              obj.scopeType = item.type === 1 ? "自营" : "撮合";
            } else {
              obj[it.field] = item[it.field];
            }
          });
          newData.push(obj);
        });
      }

      this.companyBusinessTableData.data = newData;
      this.companyBusinessTableData.total = data.total;
    }
  }

  // 风险情况雷达图
  async getRadarChartData() {
    this.loadding = true;
    const { data } = await getRegulationRiskChart(this.params);

    data.anti_fraud_exception_dto_list.forEach(d => {
      d.type =
        this.params.time_type === TimeType.InvoiceTime
          ? "开单时间"
          : "上报时间";
      d.displayCount = d.count;
    });
    this.regulationRadarData = data.anti_fraud_exception_dto_list;
    this.exceptionTitleList = data.exception_type_dto_list;
    this.loadding = false;
  }

  // 产业园或主体信用评分
  async getSubjectRiskInfo() {
    const { data } = await getRegulationSubjectRisk(this.params);
    this.subjectRiskScore = data.risk_score;
    this.subjectRiskScoreStatistic = {
      onLine: {
        value: data.online_percent / 100
      },
      credit: {
        value: data.risk_score.risk_score
      },
      activity: {
        value: data.active_percent / 100
      }
    };
    this.subjectRiskScoreDataList = processRiskScoreData(
      data.risk_score_statistics
    );
  }

  // 风险规则情况表
  async getRiskRuleTableData() {
    const { data } = await getRegulationRiskRule(this.params);
    // eslint-disable-next-line camelcase
    const { risk_chart_detail_dto_list, risk_chart_title_dto } = data;

    this.totalRiskRuleTableData = [
      {
        exceptionName: risk_chart_title_dto.exceptionName,
        ruleNames: [risk_chart_title_dto.ruleName],
        ruleLevelAlias: [risk_chart_title_dto.ruleLevelAlias],
        payedList: [risk_chart_title_dto.payedCount],
        receivedList: [risk_chart_title_dto.receivedCount]
      }
    ].concat(risk_chart_detail_dto_list);
    this.riskRuleTableData = [].concat(this.totalRiskRuleTableData);
  }

  pickRiskRuleTableData() {
    const temp = [];
    this.exceptionTitleList.forEach(data => {
      if (data.exceptionType === this.exceptionType) {
        temp.push({
          exceptionName: data.exceptionName,
          ruleNames: [data.ruleName],
          ruleLevelAlias: [data.ruleLevelAlias],
          payedList: [data.payedCount],
          receivedList: [data.receivedCount]
        });
      }
    });
    this.totalRiskRuleTableData.forEach(data => {
      if (data.exceptionType === this.exceptionType) {
        temp.push(data);
      }
    });
    this.riskRuleTableData = temp;
  }

  viewAllRiskRuleTableData() {
    // this.exceptionType = ''
    // this.getRiskRuleTableData()
    this.riskRuleTableData = [].concat(this.totalRiskRuleTableData);
  }

  // 运单笔数图表
  async getWaybillChart() {
    const { data } = await getRegulationWaybillChart(this.params);

    processTableData(data.new_waybill_list, "新开运单数");
    processTableData(data.payed_waybill_list, "支付运单数");
    processTableData(data.received_waybill_list, "签收运单数");
    processTableData(data.waybill_create_list, "开票运单数");

    this.waybillChartData = data.new_waybill_list
      .concat(data.payed_waybill_list)
      .concat(data.received_waybill_list)
      .concat(data.waybill_create_list);
    this.waybillCount = data.waybill_count;
  }

  // 各主体单量排名
  async getWaybillRank() {
    const { data } = await getRegulationWaybillRank(this.params);
    this.waybillRankData = data;
  }

  // 运费散点图(运费收入、支出)
  async getWaybillFeeChart() {
    const { data } = await getRegulationWaybillFeeChart(this.params);

    const expenseDetailList: any = [];
    const incomeDetailList: any = [];

    data.expense_detail_list.forEach(d => {
      if (d.averageFee || d.waybillCount) {
        expenseDetailList.push({
          mile: (d.startY + d.endY) / 2,
          goodsWeight: (d.startX + d.endX) / 2,
          averageFee: d.averageFee,
          waybillCount: d.waybillCount
        });
      }
    });
    data.income_detail_list.forEach(d => {
      if (d.averageFee || d.waybillCount) {
        incomeDetailList.push({
          mile: (d.startY + d.endY) / 2,
          goodsWeight: (d.startX + d.endX) / 2,
          averageFee: d.averageFee,
          waybillCount: d.waybillCount
        });
      }
    });
    this.waybillFeeChartData = {
      expense_detail_list: expenseDetailList,
      income_detail_list: incomeDetailList
    };
  }

  isIndustryPark() {
    return this.params.subject_type === SubjectType.IndustrialPark;
  }

  isSelectIndustryPark() {
    return this.subjectType === SubjectType.IndustrialPark;
  }

  handleChangeSubject() {
    this.isDialogVisible = true;
  }

  handleSubjectSearchConfirm() {
    if (!this.subjectDid && !this.isSelectIndustryPark()) {
      this.$message.error("请选择主体");
    } else {
      this.isDialogVisible = false;
      this.init();
    }
  }

  handleSubjectTypeChange(type) {
    if (this.isSelectIndustryPark()) {
      this.subjectDid = "";
    }
    SubjectTypeEnum.forEach(e => {
      if (e.value === type) {
        this.subjectSearchConfig.field = e.field;
      }
    });
  }

  handleTimeChange({ startTime, endTime, timeFormat }) {
    this.params.start_time = startTime || 0;
    this.params.end_time = endTime;
    this.timeFormat = timeFormat;
    this.getChartInfo();
  }

  handleBusinessChange({ businessMode }) {
    this.params.business_mode = businessMode;
    this.getChartInfo();
  }

  handleTimeTypeChange() {
    this.getChartInfo();
  }

  handlePointClick(ev: any) {
    const { data } = ev;

    this.exceptionType = data.data.exceptionType;

    this.pickRiskRuleTableData();
  }

  getBusinessTableOptions() {
    return this.isIndustryPark()
      ? this.IndustrialParkBusinessOptions
      : this.AgentBusinessTableOptions;
  }
}
