import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'
@Component({
  name: 'ResizeMixin'
})
export default class extends Vue {
  @Prop({ default: 'x' }) public xField!: string;
  @Prop({ default: 'y' }) public yField!: string;
  @Prop({ type: Array, required: true }) public data: any[];
  @Prop({ default: '' }) public title!: string;

  protected chart!: any
  private sidebarElm ? : Element
  public charType = 'time'
  public format = 'YY-MM-DD'
  mounted() {
    this.initResizeEvent()
    this.initSidebarResizeEvent()
  }

  beforeDestroy() {
    this.destroyResizeEvent()
    this.destroySidebarResizeEvent()
  }

  activated() {
    this.initResizeEvent()
    this.initSidebarResizeEvent()
  }

  deactivated() {
    this.destroyResizeEvent()
    this.destroySidebarResizeEvent()
  }

  @Watch('data', {
    deep: true
  })
  onDataUpdate(data: any[], oldData: any[]) {
    if (!_.isEqual(data, oldData)) {
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.changeData(data)
          // this.chart.repaint()
        }
      })
    }
  }

  private chartResizeHandler() {
    if (this.chart) {
      this.chart.update()
    }
  }

  private sidebarResizeHandler(e: TransitionEvent) {
    if (e.propertyName === 'width') {
      this.chartResizeHandler()
    }
  }

  private initResizeEvent() {
    if (this.chartResizeHandler) {
      window.addEventListener('resize', this.chartResizeHandler)
    }
  }

  private destroyResizeEvent() {
    if (this.chartResizeHandler) {
      window.removeEventListener('resize', this.chartResizeHandler)
    }
  }

  private initSidebarResizeEvent() {
    this.sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    if (this.sidebarElm) {
      this.sidebarElm.addEventListener('transitionend', this.sidebarResizeHandler as EventListener)
    }
  }

  private destroySidebarResizeEvent() {
    if (this.sidebarElm) {
      this.sidebarElm.removeEventListener('transitionend', this.sidebarResizeHandler as EventListener)
    }
  }
}
