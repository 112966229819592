import ShowImg from '@/components/ShowImg/index.vue'

let vm
const install = function(Vue: Vue.VueConstructor) {
  Vue.prototype.$showImg = function(src: string) {
    if (!vm) {
      vm = new ShowImg({ parent: this.$root }).$mount()
      this.$root.$el.appendChild(vm.$el)
    }
    return vm.open(src)
  }
}

export default {
  install
}
