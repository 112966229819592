




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Pagination from '@/common/components/Pagination/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import {
  noticeAgentPage
} from '@/api/notice'

@Component({
  name: 'Receiver',
  components: {
    Pagination,
    BsmTable
  }
})
export default class extends Vue {
  @Prop() noticeId: string

  @Watch('noticeId')
  watchValueFn(val) {
    if (val) {
      this.listQuery.notice_id = val
    }
  }

  showDialog() {
    this.listQuery.if_read = false
    this.listQuery.if_receipt = ''
    this.dialogVisible = true
    this.getList()
  }

  dialogVisible = false
  list = []
  total = 0
  listLoading = true

  listQuery = {
    if_read: false,
    if_receipt: '',
    notice_id: '',
    page_query: {
      index: 1,
      limit: 10
    }
  }

  ifReceiptMap = [
    { value: 1, label: '是' },
    { value: 2, label: '等待回执' },
    { value: 3, label: '无需回执' }
  ]

  // {
  //   1: '是',
  //   2: '等待回执',
  //   3: '无需回执'
  // }

  tableListOptions = [{
    field: 'agent_name',
    label: '接收人'
  }, {
    field: 'if_read',
    label: '是否已读',
    filter: (val) => {
      return val ? '是' : '否'
    }
  }, {
    field: 'if_receipt_alias',
    label: '是否已回执'
  }, {
    field: 'content',
    label: '回执内容'
  }, {
    field: 'url',
    label: '回执文件',
    type: 'link',
    config: {
      baseUrl: '',
      label: '查看文件'
    }
  }]

  async getList() {
    this.listLoading = true
    this.listQuery.notice_id = this.noticeId
    const { data } = await noticeAgentPage(this.listQuery)
    this.list = data.items
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }

  handleFilter() {
    this.getList()
  }
}

