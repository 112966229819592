























import { Vue, Component, Prop } from 'vue-property-decorator'
import TableList from '../TableList/index.vue'
import {
  IWaybillInvoiceData
} from '../index.d'

const waybillInvoiceDefault: IWaybillInvoiceData = {
  invoiceStatusAlias: '',
  totalInvoiceAmount: 0,
  totalTax: 0,
  invoiceDataDtoList: []
}

@Component({
  components: {
    TableList
  }
})
export default class extends Vue {
  @Prop({ default: () => waybillInvoiceDefault }) waybillInvoiceInfo;

  invoiceListOptions = [{
    field: 'invoiceTypeAlias',
    label: '发票类型'
  }, {
    field: 'invoiceTime',
    label: '开票时间',
    filter: 'timeFilterShowSecond',
    width: 180
  },
  {
    field: 'invoiceCode',
    label: '发票代码',
    width: 150
  },
  {
    field: 'invoiceNumber',
    label: '发票号码',
    width: 150
  },
  {
    field: 'buyerName',
    label: '购买方',
    width: 180
  },
  {
    field: 'sellerName',
    label: '销售方',
    width: 180
  },
  {
    field: 'invoiceAmount',
    label: '发票金额(元)',
    filter: 'amountFormatFilter'
  },
  {
    field: 'tax',
    label: '税额(元)',
    filter: 'amountFormatFilter'
  }
  ]
}

