var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "track-map-container" },
    [
      _vm.noTrace
        ? _c("el-alert", {
            attrs: {
              title: "提示",
              description: "未上报轨迹信息",
              type: "error",
              center: "",
              "show-icon": ""
            }
          })
        : _vm._e(),
      _c("khy-map", {
        attrs: {
          paths: _vm.paths,
          points: _vm.renderDots,
          districts: _vm.districts
        }
      }),
      _c("map-pannel", {
        attrs: {
          "path-infos": _vm.pathInfos,
          "b-d-path-infos": _vm.BDPathInfos
        },
        on: {
          visibleChange: _vm.handleVisibleChange,
          fetchBeiDouTrack: _vm.handleFetchBeiDouTrack
        }
      }),
      _c("map-pannel-bei-dou", {
        attrs: {
          loading: _vm.isBeiDouLoading,
          "path-infos": _vm.BDListPathInfos
        },
        on: {
          visibleChange: _vm.handleVisibleChange,
          fetchBeiDouTrack: _vm.handleFetchBeiDouTrack
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }