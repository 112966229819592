


































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
// import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import TableList from '@/common/business-components/TableList/index.vue'
import { InspectContentType, agentType } from '@/common/constant'
import { getUrlParams } from '@/common/utils'

@Component({
  name: 'InspectDetail',
  components: {
    TableList
  }
})
export default class extends Vue {
  @Prop({ default: () => { return [] } }) paymentList: any
  @Prop({ default: () => { return {} } }) paymentDetail: any
  @Prop({ default: () => { return [] } }) trackList: any
  @Prop({ default: () => { return {} } }) inspectDetail: any

  @Prop({ default: InspectContentType.PaymentFlow }) strategyType: any
  @Prop({ default: false }) isEdit: boolean
  @Prop({ default: false }) isSubmit: boolean
  @Prop({ default: false }) isEnterprise: boolean

  inspectContentType = InspectContentType
  approveAdvice = ''

  isEnterpriseClient = window.location.host.indexOf('enterprise') > -1 || window.location.host.indexOf('9529') > -1
  isShipperClient = window.location.host.indexOf('shipper') > -1

  get path() {
    return this.$route.path || ''
  }

  get agentTypeMap() {
    return agentType
  }

  created() {
    console.log(this.inspectDetail)
  }

  handleTo(path) {
    if (this.isShipperClient) {
      this.$router.push({
        path: path,
        query: {
          token: decodeURI(getUrlParams('token')),
          destination: decodeURI(getUrlParams('destination'))
        }
      })
    } else {
      this.$router.push({
        path: path
      })
    }
  }

  paymentOptions: any = [{
    field: 'settlementType',
    label: '结算类型'
  }, {
    field: 'payTime',
    label: '结算时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'counterParty',
    label: '交易对手'
  }, {
    field: 'payTypeAlias',
    label: '结算方式'
  },
  {
    field: 'payerBankName',
    label: '付款机构'
  },
  {
    field: 'bankSeriesNo',
    label: '支付机构流水号'
  },
  {
    field: 'shippingFee',
    label: '支付费用（元）',
    filter: 'amountFormatFilter'
  }
  ]

  trackOptions: any = [{
    field: 'subWaybillNo',
    label: '分段分单号',
    type: this.path === '/external-supervise-inspect' ? '' : 'link',
    config: {
      url(val, row) {
        console.log(row)
        return `/dataquery/sub_waybill/${row.subWaybillId}?token=${getUrlParams('token')}&destination=${getUrlParams('destination')}`
      }
    }
  },
  {
    field: 'subWaybillStatusAlias',
    label: '运输状态'
  },
  {
    field: 'vehicleNumber',
    label: '车牌号',
    type: 'link',
    config: {
      url(val, row) {
        return `/dataquery/vehicle/${val}`
      }
    }
  },
  {
    field: 'driverName',
    label: '司机',
    type: 'link',
    config: {
      url(val, row) {
        return `/dataquery/driver/${row.driverDid}`
      }
    }
  },
  {
    field: 'driverPhone',
    label: '电话'
  },
  {
    field: 'loadTime',
    label: '起运时间',
    filter: 'timeFilterShowSecond'
  },
  {
    field: 'loadAddress',
    label: '装货地址'
  },
  {
    field: 'receiveTime',
    label: '卸货时间',
    filter: 'timeFilterShowSecond'
  },
  {
    field: 'receiveAddress',
    label: '卸货地址'
  }
  // {
  //   label: '操作',
  //   type: 'link',
  //   config: {
  //     url(val, row) {
  //       return `/dataquery/sub_waybill/${row.subWaybillId}`
  //     },
  //     label: '查看详情'
  //   }
  // }
  ]
}

