














import { Vue, Component, Prop } from 'vue-property-decorator'

interface IConfig {
  separator: string
}


@Component
export default class MapRange extends Vue {
  // @Prop({
  //   default() {
  //     return ['', '']
  //   },
  //   required: true
  // }) value!: string[]

  @Prop({ default: '' }) fields0: string| number
  @Prop({ default: '' }) fields1: string| number

  @Prop({
    default() {
      return {
        separator: '~'
      }
    },
    required: true
  }) config: IConfig

  private checkNumber(val) {
    var reg = new RegExp('^[0-9]*$')
    if (reg.test(val)) {
      return true
    } else {
      return false
    }
  }

  private get start() {
    return this.fields0
  }

  private set start(val) {
    if (this.checkNumber(val)) {
      this.$emit('update:fields0', val)
    }
  }

  private get end() {
    return this.fields1
  }

  private set end(val) {
    if (this.checkNumber(val)) {
      this.$emit('update:fields1', val)
    }
  }

  private get separator() {
    return this.config.separator || '~'
  }
}

