var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editDialogVisible,
            title: " 查看详情",
            width: "800px",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.editDialogVisible = $event
            },
            close: _vm.closeDetailModal
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("企业名称：" + _vm._s(_vm.detailData.agentName))
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "年月：" + _vm._s(_vm.formatMonth(_vm.detailData.month))
                    )
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-16" },
                [
                  _vm._v(" 合规业务统计： "),
                  _c(
                    "el-table",
                    {
                      staticClass: "mt-16",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableData, border: "" }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          label: "统计范围",
                          width: "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "number1",
                          label: "合规业务产值(元)",
                          "min-width": "130"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "number2",
                          label: "税额对应产值(元)",
                          "min-width": "130"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "number3",
                          label: "税额产值与业务产值差异率",
                          "min-width": "130"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-16" },
                [
                  _vm._v(" 税务数据明细： "),
                  _c(
                    "el-table",
                    {
                      staticClass: "mt-16",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.detailTableData, border: "" }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "valueAddedTax",
                          label: "增值税(元)",
                          "min-width": "90"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "cityMcTax",
                          label: "城市维护建设税(元)",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "eduSurTax",
                          label: "教育费附加(元)",
                          "min-width": "125"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "cityEduSurTax",
                          label: "地方教育附加(元)",
                          "min-width": "140"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "stampTax",
                          label: "印花税(元)",
                          "min-width": "100"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orgIncomeTax",
                          label: "企业所得税(元)",
                          "min-width": "125"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "idvIncomeTax",
                          label: "个人所得税(元)",
                          "min-width": "125"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "mt-16" }, [
                _vm._v(" 税务文件数据："),
                _c(
                  "span",
                  {
                    staticClass: "text-primary",
                    staticStyle: { cursor: "pointer" },
                    on: { click: _vm.handleDownload }
                  },
                  [_vm._v("下载")]
                )
              ]),
              _c(
                "div",
                { staticClass: "mt-16" },
                _vm._l(_vm.fileList, function(item, idx) {
                  return _c(
                    "span",
                    { staticStyle: { "margin-right": "24px" } },
                    [
                      _vm._v(" 文件名" + _vm._s(idx + 1) + "："),
                      _c(
                        "span",
                        {
                          staticClass: "text-primary",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function($event) {
                              return _vm.handleGoUrl(item.url)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.closeDetailModal } }, [
                _vm._v("关闭")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }