import request from '@/utils/request'

export const getNoticeList = (data: any) => request({
  url: '/notice/page',
  method: 'post',
  data
})
export const getCoordinationList = (data: any) => request({
  url: '/coordination/page',
  method: 'post',
  data
})
export const getNoticeNewest = () => request({
  url: '/notice/newest_one',
  method: 'post'
})
export const getCoordinationBell = () => request({
  url: '/coordination/bell',
  method: 'post'
})
export const noticePost = (data: any) => request({
  url: '/notice/post',
  method: 'post',
  data
})
export const coordinationReply = (data: any) => request({
  url: '/coordination/reply',
  method: 'post',
  data
})
export const coordinationPost = (data: any) => request({
  url: '/coordination/post',
  method: 'post',
  data
})
export const coordinationDetail = (data: any) => request({
  url: '/coordination/detail',
  method: 'post',
  data
})
export const noticeDetail = (data: any) => request({
  url: '/notice/detail',
  method: 'post',
  data
})
export const noticeRefuse = (data: any) => request({
  url: '/notice/refuse',
  method: 'post',
  data
})
export const noticeSend = (data: any) => request({
  url: '/notice/confirm_send',
  method: 'post',
  data
})
export const noticeVoid = (data: any) => request({
  url: '/notice/void',
  method: 'post',
  data
})
export const noticeAgentPage = (data: any) => request({
  url: '/notice_agent/page',
  method: 'post',
  data
})
export const transportAgentNameLike = (data: any) => request({
  url: '/transport_agent/name_like',
  method: 'post',
  data
})
