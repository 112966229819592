// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.subject-detail-dialog {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.subject-detail-dialog .prefix {\n    margin-right: 10px;\n}\n.subject-detail-dialog .subject-detail-dialog-row {\n    padding-bottom: 6px;\n}\n.subject-detail-dialog .subject-detail-dialog-row .title {\n      color: #686868;\n      font-weight: bold;\n      font-size: 14px;\n      text-align: right;\n      line-height: 20px;\n}\n.subject-detail-dialog .subject-detail-dialog-row .value-text {\n      color: #686868;\n      font-size: 14px;\n      line-height: 20px;\n}\n.subject-detail-dialog .subject-detail-dialog-row .value-link {\n      color: #4f7df9;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
