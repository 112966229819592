






import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import AdminDashboard from './admin/index.vue'

@Component({
  name: 'Overview',
  components: {
    AdminDashboard
  }
})
export default class extends Vue {
  currentRole = 'admin-dashboard';

  get roles() {
    return UserModule.roles
  }
}
