// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.donut-chart .g2plot-htmlStatistic .ring-guide-name {\n  font-size: 12px !important;\n  color: #686868 !important;\n  line-height: 17px;\n}\n.donut-chart .g2plot-htmlStatistic .ring-guide-value {\n  font-size: 18px !important;\n  color: #363636 !important;\n  line-height: 25px;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
