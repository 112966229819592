



import { Pie } from '@antv/g2plot'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResizeMixin from './mixins/resize1'
import _ from 'lodash'
@Component({
  name: 'PieChart'
})

export default class extends mixins(ResizeMixin) {
  @Prop({ default: 'pie-chart' }) private className!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: '100%' }) private height!: string
  @Prop({ required: true }) private angleField: string
  @Prop({ required: true }) private colorField: string
  @Prop({ default: 'count' }) private type: string
  @Prop({ default: function() { return {} } }) private config: any

  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  }

  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.destroy()
    this.chart = null
  }

  private initChart() {
    const that = this

    const config: any = _.merge({
      autoFit: true,
      radius: 0.8,
      data: this.data,
      angleField: this.angleField,
      colorField: this.colorField,
      label: null,
      interactions: [ { type: 'element-active' }],
      tooltip: {
        offset: 0,
        formatter: (item, label) => {
          let value = item.value
          if (that.type === 'amount') {
            value = that.$options.filters.amountFormatFilter(value)
          }
          return {
            name: item.name,
            value: value
          }
        },
        // domStyles: {
        //   'g2-tooltip-value': {
        //     marginLeft: '8px'
        //   }
        // }
      },
      // innerRadius: 0.3,
      legend: {
        position: 'bottom',
        // text: {
        //   style: {
        //     fontSize: 12,
        //     lineHeight: 17,
        //     fill: '#686868'
        //   }
        // },
        flipPage: true
      }
    }, this.config)

    this.chart = new Pie(this.$refs.chart as HTMLElement, config)
    this.chart.render()
  }
}

