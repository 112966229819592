










import { Vue, Component, Prop } from 'vue-property-decorator'

const tagMap = {
  1: '强制通过',
  2: '强制不通过'
}

@Component
export default class extends Vue {
  @Prop({ default: 0 }) private type: 1 | 2

  private get label() {
    return tagMap[this.type] || ''
  }
}
