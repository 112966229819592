

























































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  getServiceApplyDetail,
  rejectServiceApply,
  getServiceApplyList,
  confirmServiceApply,
  enableStateUpdate,
  exportExcel
} from "@/api/serviceApply";
import { IGetServiceApplyListParams, IServiceApplyData } from "@/api/types";
import Pagination from "@/common/components/Pagination/index.vue";
import SearchFilter from "@/common/business-components/SearchFilter/index.vue";
import BsmTable from "@/views/components/BsmTable.vue";
import {
  ServiceApplyStatusMap,
  ServiceApplyType,
  ServiceApplyStatus
} from "@/common/constant";
import { getEnumArray, validateForm, convertToExcel } from "@/common/utils";
import MapSearchSelect from "@/views/components/TypeMap/map/MapSearchSelect.vue";
import moment from "moment";
import { AppModule } from "@/store/modules/app";

@Component({
  name: "ServiceApplyList",
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  list: IServiceApplyData[] = [];
  total = 0;
  listLoading = true;
  dialogVisible = false;
  currentRow: any = {};
  status = ServiceApplyStatus.Applying;
  serveApplyId = "";
  listQuery: IGetServiceApplyListParams = {
    page: {
      index: 1,
      limit: 10
    }
  };
  invoicePlatformName = "";
  invoicePlatform = "";

  taxDialogVisible = false;
  taxDialogAction = "";
  taxCompanyData = {
    social_credit_code: "",
    agent_name: "",
    refuseReason: "",
    applyMonth: null
  };

  platformMap = {
    huisuiyun: "慧穗云"
  };

  invoiceMap = {
    1: "千元版",
    2: "万元版",
    3: "十万元版",
    4: "百万元版",
    5: "千万元版"
  };

  detailDialogTitle = "";

  detailDialogVisible = false;
  confirming = false;

  taxApplyInfoForm: any = {
    extJson: {},
    agentName: "",
    taxNumber: "",
    taxRegisterNumber: "",
    bankName: "",
    bankNo: "",
    accountBank: "",
    taxThreeProtocolNumber: "",
    taxDepartmentCode: "",
    taxOfficer: "",
    contactPerson: "",
    contactPhone: "",
    contactIdentity: "",
    apply_business_license_pic: "",
    serveApplyId: "",
    apply_type: null
  };

  taxApplyConfirmForm: any = {
    channelId: "",
    channelSecret: "",
    dataSource: "",
    privateKey: "",
    publicKey: "",
    extJson: {}
  };

  rules = {
    channelId: [{ required: true, message: "请输入企业账号", trigger: "blur" }],
    channelSecret: [
      { required: true, message: "请输入企业密码", trigger: "blur" }
    ],
    dataSource: [
      { required: true, message: "请输入企业编码", trigger: "blur" }
    ],
    privateKey: [{ required: true, message: "请输入私钥", trigger: "blur" }],
    publicKey: [{ required: true, message: "请输入公钥", trigger: "blur" }]
  };

  queryFilterOptions = [
    {
      field: "agent_did",
      label: "企业名称或证件号码",
      main: true,
      component: MapSearchSelect
    },
    {
      field: "type",
      label: "服务类型",
      type: "select",
      main: true,
      config: {
        list: getEnumArray(ServiceApplyType)
      }
    },
    {
      field: "status",
      label: "申请状态",
      type: "select",
      main: true,
      config: {
        list: getEnumArray(ServiceApplyStatusMap)
      }
    }
  ];

  tableListOptions = [
    {
      field: "serveApplyId",
      type: "actionSetting",
      label: "操作",
      attrs: {
        actionOptions: [
          {
            label: "查看详情",
            type: "detail"
          },
          {
            label: "通过",
            type: "pass",
            disabled: function(config, row) {
              return !(row.status === ServiceApplyStatus.Applying);
            }
          },
          {
            label: "拒绝",
            type: "refuse",
            disabled: function(config, row) {
              return !(row.status === ServiceApplyStatus.Applying);
            }
          },
          {
            label: "编辑",
            type: "update",
            disabled: function(config, row) {
              return row.status !== 1;
            }
          }
        ]
      },
      fixed: true
    },
    {
      field: "agent_name",
      label: "企业名称",
      fixed: true
    },
    { field: "social_credit_code", label: "统一社会信用代码" },
    { field: "apply_type_name", label: "服务类型" },
    {
      field: "create_time",
      label: "申请时间",
      filter: "timeFilter"
    },
    { field: "status_name", label: "申请状态" },
    {
      field: "approve_time",
      label: "处理时间",
      filter: "timeFilter"
    }
  ];

  created() {
    this.getList();
  }

  async getList() {
    this.listLoading = true;
    const { data } = await getServiceApplyList(this.listQuery);
    this.list = data.items;
    this.total = data.total;
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  handleFilter() {
    this.listQuery.page.index = 1;
    this.getList();
  }

  isApplying() {
    return this.status === ServiceApplyStatus.Applying;
  }

  isPassed() {
    return this.status === ServiceApplyStatus.Passed;
  }

  isRefused() {
    return this.status === ServiceApplyStatus.Refused;
  }

  isConfiming() {
    return this.confirming;
  }

  isTongRen() {
    return AppModule.urlConfig.destination === "TongRen";
  }

  async handleClickConfirming() {
    if (!(await validateForm(this.$refs.taxApplyConfirmForm))) {
      return;
    }
    this.$confirm("此操作将通过该企业的服务接入申请, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      const params = Object.assign(this.taxApplyConfirmForm, {
        serveApplyId: this.serveApplyId
      });
      confirmServiceApply(params).then(res => {
        this.$message({
          type: "success",
          message: "通过成功!"
        });
        this.detailDialogVisible = false;
        this.getList();
      });
    });
  }

  handleClickExport() {
    exportExcel(this.listQuery).then(res => {
      const data: any = [res];
      const date: string = moment()
        .format("YYYY_MM_DD")
        .toString();
      convertToExcel(data, `服务申请列表_${date}`);
    });
  }

  async submitUpdate() {
    console.log(this.currentRow);
    enableStateUpdate({
      agentDid: this.currentRow.agent_did,
      enabled: this.currentRow.enabled,
      applyType: this.currentRow.apply_type
    }).then(res => {
      this.dialogVisible = false;
      this.$message({
        type: "success",
        message: "操作成功!"
      });
      this.getList();
    });
  }

  invoicePlatformMap = {
    baiwang: "百望",
    bureau_tongren: "税务局-铜仁",
    huisuiyun: "慧穗云"
  };
  handleActionCommand(action, id, row) {
    // add operation record to global property
    this.serveApplyId = row.serve_apply_id;
    this.status = row.status;
    this.confirming = false;
    this.invoicePlatformName = "";
    this.invoicePlatform = "";

    if (row.apply_type === 4 && action !== "update") {
      this.taxCompanyData.social_credit_code = row.social_credit_code;
      this.taxCompanyData.agent_name = row.agent_name;
      this.taxCompanyData.refuseReason = "";
      this.taxCompanyData.applyMonth = null;
      this.taxDialogAction = action;
      this.taxDialogVisible = true;
      return;
    }
    if (action === "pass") {
      this.confirming = true;
      this.taxApplyConfirmForm = {
        dataSource: this.isTongRen() ? "" : row.social_credit_code
      };
      this.taxApplyInfoForm.apply_type = row.apply_type;
      if (row.apply_type === 2) {
        getServiceApplyDetail({
          serveApplyId: row.serve_apply_id,
          applyType: row.apply_type || 1
        }).then(res => {
          const { data } = res;
          this.taxApplyInfoForm = Object.assign({}, data.config || {});
          this.taxApplyInfoForm.extJson =
            data.config && data.config.extJson
              ? JSON.parse(data.config.extJson)
              : {};
          this.taxApplyConfirmForm = Object.assign({}, data.config);
          this.taxApplyConfirmForm.extJson =
            data.config && data.config.extJson
              ? JSON.parse(data.config.extJson)
              : {};

          this.taxApplyInfoForm.apply_type = row.apply_type;
        });
      } else if (row.apply_type === 3) {
        getServiceApplyDetail({
          serveApplyId: row.serve_apply_id,
          applyType: row.apply_type || 1
        }).then(res => {
          const { data } = res;
          this.invoicePlatformName = this.invoicePlatformMap[
            data.config.invoicePlatform
          ];
          this.invoicePlatform = data.config.invoicePlatform;
        });
      }

      this.detailDialogTitle =
        row.apply_type === 3
          ? "撮合承运人开票服务"
          : row.apply_type === 2
          ? "托运人开票服务"
          : "自营承运人开票服务";
      this.detailDialogVisible = true;
      // this.taxApplyConfirmForm.dataSource = row.social_credit_code
    } else if (action === "refuse") {
      this.$confirm("此操作将拒绝该企业服务接入申请, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        rejectServiceApply({
          serveApplyId: row.serve_apply_id
        }).then(res => {
          this.$message({
            type: "success",
            message: "拒绝成功!"
          });
          this.getList();
        });
      });
    } else if (action === "detail") {
      this.taxApplyInfoForm.apply_type = row.apply_type;
      getServiceApplyDetail({
        serveApplyId: row.serve_apply_id,
        applyType: row.apply_type || 1
      }).then(res => {
        const { data } = res;
        this.taxApplyInfoForm = Object.assign({}, data.config || {});
        this.taxApplyInfoForm.apply_type = row.apply_type;
        this.taxApplyInfoForm.extJson =
          data.config && data.config.extJson
            ? JSON.parse(data.config.extJson)
            : {};
        this.taxApplyConfirmForm = Object.assign({}, data.config || {});
        this.taxApplyConfirmForm.extJson =
          data.config && data.config.extJson
            ? JSON.parse(data.config.extJson)
            : {};
        this.detailDialogVisible = true;
        this.detailDialogTitle =
          row.apply_type === 3
            ? "撮合承运人开票服务"
            : row.apply_type === 2
            ? "托运人开票服务"
            : "自营承运人开票服务";
        this.invoicePlatformName = this.invoicePlatformMap[
          data.config.invoicePlatform
        ];
        this.invoicePlatform = data.config.invoicePlatform;
      });
    } else if (action === "update") {
      this.dialogVisible = true;
      this.currentRow = { ...row };
    }
  }

  taxSubmitAction() {
    if (this.taxDialogAction === "pass") {
      this.$confirm("此操作将通过该企业的服务接入申请, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        confirmServiceApply({
          serveApplyId: this.serveApplyId,
          auditConfigJson: JSON.stringify({
            first_apply_month: this.taxCompanyData.applyMonth
          })
        }).then(res => {
          this.$message({
            type: "success",
            message: "通过成功!"
          });
          this.taxDialogVisible = false;
          this.getList();
        });
      });
    } else if (this.taxDialogAction === "refuse") {
      rejectServiceApply({
        serveApplyId: this.serveApplyId,
        refuseReason: this.taxCompanyData.refuseReason
      }).then(res => {
        this.$message({
          type: "success",
          message: "拒绝成功!"
        });
        this.taxDialogVisible = false;
        this.getList();
      });
    }
  }
}
