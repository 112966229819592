



































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { getListApi, addList, updateList, removeList, enableItem } from '@/api/white_list'
import { EnumActions } from '@/common/business-components/TypeMap/ActionSetting/index.vue'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import { Form } from 'element-ui'
import { getEnumArray, uploadMultiFiles } from '@/common/utils'
import MultiUploadFile from '@/common/business-components/MultiUploadFile/index.vue'

import {
  WhiteStatusMap
} from '@/common/constant'
import {
  // getRiskRulesList,
  getRiskRulesListByKey
} from '@/api/riskrules'


@Component({
  name: 'WhiteList',
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    MultiUploadFile,
    MapSearchSelect
  }
})
export default class extends Vue {
  list = []
  total = 0
  listLoading = true
  subLoading = false
  visible = false
  visible2 = false
  queryLoading = false
  receiverList = []
  initFileList: any = []
  currentFileList:any = []
  fileList: any = []
  listQuery = {
    page: {
      index: 1,
      limit: 10
    }
  }

  formData:any = {
    rule_id: '',
    carrier_code: '',
    consignor_code: '',
    driver_identity: '',
    enterprise_code: '',
    failure_time: '',
    notes: '',
    vehicle_number: ''
  }

  rules = {
    rule_id: [{
      required: true,
      message: '请选择正确的规则',
      trigger: 'blur'
    }]
  }

  currentActionType: EnumActions = null

  subjectTypeEnum:any = [{
    label: '托运人',
    value: '1'
  }, {
    label: '网货企业',
    value: '2'
  }, {
    label: '承运人',
    value: '3'
  }, {
    label: '司机',
    value: '4'
  }, {
    label: '车辆',
    value: '5'
  }]


  queryFilterOptions = [
    {
      field: 'rule_name',
      label: '规则名称',
      type: 'input',
      main: true
    }, {
      field: 'rule_id',
      label: '规则编号',
      type: 'input',
      main: true
    }, {
      field: 'agent_type',
      label: '主体类型',
      type: 'select',
      config: {
        list: this.subjectTypeEnum
      },
      main: true
    }, {
      field: 'identity_no',
      label: '主体证件号码',
      type: 'input',
      main: true
    }, {
      field: 'status',
      label: '状态',
      type: 'select',
      config: {
        list: getEnumArray(WhiteStatusMap)
      },
      main: true
    }, {
      field: ['start_date', 'end_date'],
      label: '截止时间',
      type: 'dateRange',
      main: true
    }
  ]

  get pickerOptions() {
    return {
      disabledDate(date) {
        date.setHours(24)
        date.setMinutes(0)
        date.setMinutes(0)
        const time = date.getTime()
        return time < Date.now()
      }
    }
  }

  tableListOptions = [{
    field: 'id',
    type: 'actionSetting',
    label: '操作',
    attrs: {
      actionOptions: [
        {
          type: EnumActions.enable,
          label: (val, row) => {
            return row.status === 1 ? '禁用' : '启用'
          },
          disabled: (val, row) => {
            return row.status === 3
          }
        },
        {
          type: EnumActions.edit,
          label: '编辑'
        },
        {
          type: EnumActions.delete,
          label: '删除'
        }
      ]
    },
    fixed: true
  }, {
    field: 'rule_name',
    label: '规则名称'
  }, {
    field: 'rule_id',
    label: '规则编号'
  }, {
    field: 'enterprise_code',
    label: '网络货运企业'
  }, {
    field: 'consignor_code',
    label: '托运人'
  }, {
    field: 'carrier_code',
    label: '承运人'
  }, {
    field: 'driver_identity',
    label: '司机'
  }, {
    field: 'vehicle_number',
    label: '车辆'
  }, {
    field: 'evidence_file_urls',
    label: '证明材料',
    type: 'linkLike',
    config: {
      label(val, row) {
        return row.evidence_file_urls ? '查看' : ''
      },
      handle: (v, row) => {
        this.viewImages(row)
      }
    }
  }, {
    field: 'status',
    label: '状态',
    filter(val) {
      return WhiteStatusMap[val]
    }
  }, {
    field: 'created_by',
    label: '添加人'
  }, {
    field: 'source',
    label: '来源',
    filter(val) {
      return val === 1 ? '监管后台' : 'API'
    }
  }, {
    field: 'create_time',
    label: '添加时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'failure_time',
    label: '截止时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'notes',
    label: '备注'
  }]

  created() {
    this.initListQuery()
    this.getList()
  }

  handleClickImage(url: string) {
    window.open(url, '_blank')
  }

  reSet() {
    this.formData = {
      rule_id: '',
      carrier_code: '',
      consignor_code: '',
      driver_identity: '',
      enterprise_code: '',
      failure_time: '',
      notes: '',
      vehicle_number: ''
    }
    this.receiverList = []
  }

  viewImages(row) {
    this.currentFileList = row.evidence_file_urls || ''
    this.$nextTick(() => {
      this.visible2 = true
    })
  }

  handleFileListChange(files: any) {
    this.fileList = files
  }

  async remoteMethod(query) {
    if (query !== '') {
      this.queryLoading = true
      try {
        // const { data } = await getRiskRulesList({
        //   name: query,
        //   page: {
        //     index: 1,
        //     limit: 10
        //   },
        //   source: {
        //     data: ':id',
        //     type: 3
        //   }
        // })
        const { data } = await getRiskRulesListByKey({
          keyword: query,
          size: 5
        })
        this.receiverList = data
      } finally {
        this.queryLoading = false
      }
    } else {
      this.receiverList = []
    }
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
  }

  async getList() {
    this.listLoading = true
    const { data } = await getListApi(this.listQuery)
    this.list = data?.items || []
    this.total = data?.total
    await this.$nextTick()
    this.listLoading = false
  }


  handleFilter() {
    this.listQuery.page.index = 1
    this.listQuery.page.limit = 10
    this.getList()
  }

  async handleSubmit() {
    const valid = await (this.$refs.form as Form).validate()
    if (!valid) {
      return
    }
    if (!this.formData.carrier_code &&
      !this.formData.consignor_code && !this.formData.driver_identity && !this.formData.enterprise_code && !this.formData.vehicle_number) {
      this.$message.error('除规则必填外，其他参数必填一项')
      return
    }
    const submitData = { ...this.formData }
    submitData.failure_time = this.formData.failure_time ? this.formData.failure_time + (24 * 60 * 60000 - 1000) : ''
    this.$confirm(`确认${submitData.id ? '修改' : '新增'}此白名单吗？`, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async() => {
      const urls = await uploadMultiFiles(this.fileList)
      submitData.evidence_file_urls = urls
      this.handleSubmitDo(submitData)
    })
  }

  async handleSubmitDo(submitData) {
    try {
      this.subLoading = true
      submitData.id ? await updateList(submitData) : await addList(submitData)
      this.subLoading = false
      this.visible = false
      this.$message.success('保存成功')
      this.getList()
    } catch (err) {
      console.log(err)
      this.$message.error(err)
      this.subLoading = false
    }
  }

  handleAdd() {
    this.reSet()
    this.initFileList = []
    this.visible = true
  }

  handleEdit(id, row) {
    this.reSet()
    this.formData = { ...row }
    this.formData.failure_time = row.failure_time ? row.failure_time - (24 * 60 * 60000 - 1000) : ''
    this.initFileList = row.evidence_file_urls ? row.evidence_file_urls.split(',') : []
    this.remoteMethod(row.rule_id)
    this.visible = true
  }

  handleEnable(id, row) {
    enableItem({
      id: id,
      status: row.status === 1 ? 2 : 1
    }).then(() => {
      this.$message.success((row.status === 1 ? '禁用' : '启用') + '成功')
      this.getList()
    }).catch(err => {
      this.$message.error(err)
    })
  }

  handleRemove(id: string, row) {
    this.$confirm(`确认将 ${row.rule_name} 移出白名单吗？`, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(() => {
      removeList(id).then(() => {
        this.$message.success('移出成功')
        this.getList()
      }).catch(err => {
        this.$message.error(err)
      })
    })
  }

  handleActionCommand(command, id, row) {
    this.currentActionType = command
    if (command === EnumActions.add) {
      this.handleAdd()
    } else if (command === EnumActions.enable) {
      this.handleEnable(id, row)
    } else if (command === EnumActions.edit) {
      this.handleEdit(id, row)
    } else if (command === EnumActions.delete) {
      this.handleRemove(id, row)
    }
  }
}

