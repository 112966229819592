export const resolveList = (list: any[]) => {
  const aliasMap = {
    agent_alias: 'agent_count',
    carrier_alias: 'carrier_count',
    consignor_alias: 'consignor_count',
    vehicle_alias: 'vehicle_count',
    driver_alias: 'driver_count'
  }
  return list.map(item => {
    Object.keys(aliasMap).filter(k => !!item[k]).forEach(key => {
      const val = aliasMap[key]
      let unit = '人'
      if (key === 'agent_alias') {
        unit = '家'
      }
      if (key === 'vehicle_alias') {
        unit = '辆'
      }
      if (Number(item[val]) > 1) {
        item[val] = `${item[key]}等${item[val]}${unit}`
      } else {
        item[val] = `${item[key]}${item[val]}${unit}`
      }
    })
    return item
  })
}

export const isGreenSkin = () => {
  const urlList = [
    // 'localhost',
    'insight.tongren.bsmtest.56ctms.com',
    'insight.bsmpre.56ctms.com',
    'insight.tongren.bsmpre.56ctms.com',
    'insight.bsm.kuaihuoyun.com',
    'insight.guizhou.kuaihuoyun.com',
    'insight.guizhou.zhihuiwuliu.org.cn'
  ]
  const url = window.location.hostname
  return urlList.includes(url)
}

export const isHaoyunbao = () => {
  const urlList = [
    // 'localhost',
    'insight.haoyunbao.bsmtest.56ctms.com',
    'insight.haoyunbao.56ctms.com',
    'insight.haoyunbao.bsmpre.56ctms.com',
    'insight.haoyunbao.zhihuiwuliu.org.cn'
  ]
  const url = window.location.hostname
  return urlList.includes(url)
}
