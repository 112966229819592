var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ticket-card-container bg-white" }, [
    _c("div", { staticClass: "left" }, [
      _c("label", [_vm._v(_vm._s(_vm.title))]),
      _c("label", { staticClass: "text-strong" }, [
        _vm._v(_vm._s(_vm.total || 0))
      ])
    ]),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c(
          "no-cache-link",
          {
            style: { visibility: _vm.link ? "visible" : "hidden" },
            attrs: { to: _vm.link }
          },
          [_c("img-icons", { attrs: { name: "linkRight", width: "20px" } })],
          1
        ),
        _c("span", { staticClass: "font-14" }, [
          _vm._v(_vm._s(_vm._f("amountFormatFilter")(_vm.amount || 0))),
          _c("span", [_vm._v(" " + _vm._s(_vm.unit))])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }