var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-header" }, [
    _vm.title
      ? _c(
          "div",
          { staticClass: "slot-container" },
          [
            _c(
              "span",
              {
                staticClass: "title name",
                on: { click: _vm.handleClickTitle }
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _vm._t("default")
          ],
          2
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "select-contianer" },
      [
        _c("div", {
          staticClass: "end",
          staticStyle: { display: "inline-flex" }
        }),
        _vm.showTimeType
          ? _c(
              "div",
              { staticClass: "end", staticStyle: { "margin-left": "450px" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "small" },
                    on: { change: _vm.handleTimeTypeChange },
                    model: {
                      value: _vm.timeType,
                      callback: function($$v) {
                        _vm.timeType = $$v
                      },
                      expression: "timeType"
                    }
                  },
                  [
                    _c("el-radio-button", { key: 2, attrs: { label: 2 } }, [
                      _vm._v(" 上报时间 ")
                    ]),
                    _c(
                      "el-radio-button",
                      { key: 1, staticClass: "pl-10", attrs: { label: 1 } },
                      [_vm._v(" 开单时间 ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c("el-date-picker", {
          staticClass: "time-range-picker",
          attrs: {
            type: _vm.type,
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": ["00:00:00", "23:59:59"],
            "picker-options": _vm.pickerOptions
          },
          on: { change: _vm.handleDatePickerChange },
          model: {
            value: _vm.timePickerRange,
            callback: function($$v) {
              _vm.timePickerRange = $$v
            },
            expression: "timePickerRange"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }