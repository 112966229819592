import request from '@/utils/request'


export interface RegulationDetailRequestParams {
  end_time: number
  waybill_ids: string[]
  start_time: number

}

export interface IGetRegulationInfoParams {
  end_time: number
  start_time: number
  subject_did: string
  subject_type ? : number
  time_type ? : number
  business_mode ? : number
  exception_types?:string[]
  exception_type_name?:string
}

// 获取主体信息
export const getRegulationSubjectInfo = (params: IGetRegulationInfoParams) =>
  request({
    url: '/industry/subject_info',
    method: 'post',
    data: params
  })
// 异常类型关联ids
export const getRegulationWaybillIds = (params: IGetRegulationInfoParams) =>
  request({
    url: '/industry/waybill_ids',
    method: 'post',
    data: params
  })
// 风险情况雷达图
export const getRegulationRiskChart = (params: IGetRegulationInfoParams) =>
  request({
    url: '/industry/risk_chart',
    method: 'post',
    data: params
  })


// 产业园或主体信用评分
export const getRegulationSubjectRisk = (params: IGetRegulationInfoParams) =>
  request({
    url: '/industry/subject_risk_score',
    method: 'post',
    data: params
  })


// 风险规则情况表
export const getRegulationRiskRule = (params: IGetRegulationInfoParams) =>
  request({
    url: '/industry/risk_rule_excel',
    method: 'post',
    data: params
  })


// 运单笔数图表
export const getRegulationWaybillChart = (params: IGetRegulationInfoParams) =>
  request({
    url: '/industry/waybill_chart',
    method: 'post',
    data: params
  })
// 各主体单量排名
export const getRegulationWaybillRank = (params: IGetRegulationInfoParams) =>
  request({
    url: '/industry/waybill_count_rank',
    method: 'post',
    data: params
  })
// 运费散点图(运费收入、支出)
export const getRegulationWaybillFeeChart = (params: IGetRegulationInfoParams) =>
  request({
    url: '/industry/waybill_fee_chart',
    method: 'post',
    data: params
  })

export const getAntiFraudChannelApi = (params:any) =>
  request({
    url: '/anti-fraud/channel',
    method: 'post',
    data: params
  })

export const getAntiFraudRuleItemApi = (params:any) =>
  request({
    url: '/anti-fraud/rule-item/list',
    method: 'post',
    data: params
  })

export const getRuleListExternalApi = () =>
  request({
    url: '/anti-fraud/rule/list-external',
    method: 'post'
  })

export const addRuleItemApi = (params:any) =>
  request({
    url: '/anti-fraud/rule-item/add',
    method: 'post',
    data: params
  })

export const updateRuleItemApi = (params:any) =>
  request({
    url: '/anti-fraud/rule-item/update',
    method: 'post',
    data: params
  })

export const delRuleItemApi = (params:any) =>
  request({
    url: '/anti-fraud/rule-item/del',
    method: 'post',
    data: params
  })
