
































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import Actions from './Actions.vue'
import TypeMap from '../TypeMap/index.vue'
import DraggableColumnSetting from './DraggableColumnSetting.vue'
import { Storage, IStorage } from '../../utils/storage'
import _ from 'lodash'

export interface ITableOption {
  field: string // 数据的字段名称
  label: string // 表头名称
  width ? : string // 表格列的宽度
  type ? : string // 根据类型判断使用的组件
  originType?:string// el-table的原生type
  filter ? : (...params) => {} | string // 过滤器
  cloumnAttrs ? : object // 表格列属性设置 目前为el-table-column的属性配置,
  attrs ? : object // 表格单元格内组件属性设置，当启用type时会将该对象上的所有属性传入组件内部
  component ? : any // 表格单元格对应的组件，该字段会覆盖掉type类型和componeName
  componentName ? : string // 组件名称，已经注册过的组件，或者全局组件的名称。该字段会覆盖掉type的效果
  config ? : any // 组件的配置文件，通常用于自定义组件的传递的配置文件
  hidden ? : Function | boolean // 是否去除该栏（没有改字段）
  fixed ? : boolean | string
  invisible ? : boolean // 是否默认隐藏
  noFit?:boolean
}

@Component({
  components: {
    Actions,
    TypeMap,
    Draggable,
    DraggableColumnSetting
  },
  filters: {}
})
export default class extends Vue {
  @Prop({
    default: function() {
      return []
    },
    required: true
  }) data: any[]

  @Prop({
    default: function() {
      return []
    },
    required: true
  }) options: ITableOption[]

  @Prop({
    default: false
  }) listLoading: boolean

  @Prop({
    default: false
  }) showIndex: boolean

  @Prop({
    default: false
  }) showSelction: boolean

  @Prop({
    default: ''
  }) name: string

  @Prop({
    default: 0
  }) height: number

  @Prop({
    default: true
  }) showSetting: boolean

  @Prop() storage?: IStorage

  @Watch('listLoading')
  onShowChange(value: boolean) {
    if (!value) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.changeTableHight()
        }, 10)
      })
    }
  }

  setFunc = null

  subLoading = false
  inTableHeight = null

  rInitTable = false// 显示字段更新时表格自动计算会出现问题，所以要表格销毁重新渲染
  hiddenFields: string[] = []
  displayFields: string[] = []
  fixedFields:string[]=[]
  // fields: any[] = []
  settingDialogVisible = false
  cacheKey = ''
  get indexFixed() {
    let fixed = false
    this.options.forEach(o => {
      const hidden: any = o.hidden
      if (o.fixed && !(hidden && hidden())) {
        fixed = true
      }
    })
    return fixed
  }

  get fields() {
    // return this.options.filter(item => !!this.displayFields.find(label => label === item.label))
    const newOptions = []
    this.displayFields.forEach(label => {
      this.options.find(item => {
        if (label === item.label) {
          newOptions.push(item)
        }
      })
    })
    return newOptions
  }

  mounted() {
    this.initField()
    const that = this
    window.onresize = function() {
      clearTimeout(that.setFunc)
      that.setFunc = setTimeout(() => {
        that.changeTableHight()
      }, 200)
    }
  }


  changeTableHight() {
    if (this.height) {
      // 如果有传进来高度就取消自适应
      this.inTableHeight = this.height
      ;(this.$refs.multipleTable as any).doLayout()
      return
    }
    let tableHeight = window.innerHeight || document.body.clientHeight
    // 高度设置
    const disTop = (this.$refs.multipleTable as any).$el
    // 如果表格上方有元素则减去这些高度适应窗口，44是底下留白部分
    tableHeight -= disTop.offsetTop + 76
    if (disTop.offsetParent) tableHeight -= disTop.offsetParent.offsetTop
    if (disTop.offsetParent && disTop.offsetParent.offsetParent) tableHeight -= disTop.offsetParent.offsetParent.offsetTop
    this.inTableHeight = tableHeight < 300 ? 300 : tableHeight
    // 重绘表格
    ;(this.$refs.multipleTable as any).doLayout()
  }

  initField() {
    this.rInitTable = false
    const hiddenFields: any = this.getHiddenFieldsCache()
    const displayFields: any = this.getDisplayFieldsCache()
    const fixedFields:any = []
    const visibleOption = _.filter(this.options, (o) => {
      if (o.fixed) {
        fixedFields.push(o.label)
      }
      const hidden: any = o.hidden
      return !(hidden && hidden())
    })
    const map = _.keyBy(visibleOption, 'label')
    const displayTemp = []
    const hiddenTemp = []
    const newDisplayCache = []
    const newHiddenCache = []

    displayFields.forEach((label, idx) => {
      const option = map[label]
      // 老字段
      if (option) {
        newDisplayCache.push(option.label)
        displayTemp.push(option)
      }
    })
    hiddenFields.forEach((label, idx) => {
      const option = map[label]
      if (option) {
        newHiddenCache.push(label)
      }
    })

    this.options.forEach((option, idx) => {
      // 新字段，不隐藏
      if (displayFields.indexOf(option.label) === -1 && hiddenFields.indexOf(option.label) === -1) {
        if (option.invisible) {
          newHiddenCache.push(option.label)
        } else {
          newDisplayCache.push(option.label)
          displayTemp.push(option)
        }
      }
    })
    this.hiddenFields = newHiddenCache
    this.displayFields = newDisplayCache
    this.fixedFields = fixedFields
    // this.fields = displayTemp
    this.$nextTick(() => {
      this.rInitTable = true
      // this.changeTableHight()
    })
  }

  handleColumnChange() {
    this.subLoading = true
    this.setDisplayFieldsCache(this.displayFields)
    this.setHiddenFieldsCache(this.hiddenFields)

    setTimeout(() => {
      this.$emit('column-save', this.displayFields, this.hiddenFields)
      this.settingDialogVisible = false
      this.subLoading = false
      this.initField()
    }, 50)
  }

  closeSetting() {
    this.initField()
    this.$emit('column-cancel')
  }

  handleCloseSetting() {
    this.settingDialogVisible = false
  }

  public getHiddenFieldsCache() {
    const storage = this.storage || Storage
    return storage.getItem(`${this.name}_table_hidden_column`) || []
  }

  public getDisplayFieldsCache() {
    const storage = this.storage || Storage
    return storage.getItem(`${this.name}_table_display_column`) || []
  }

  setHiddenFieldsCache(value: any) {
    const storage = this.storage || Storage
    storage.setItem(`${this.name}_table_hidden_column`, value)
  }

  setDisplayFieldsCache(value: any) {
    const storage = this.storage || Storage
    storage.setItem(`${this.name}_table_display_column`, value)
  }

  resolveEmpetyVal(val) {
    if (val === null || val === undefined || val === '') {
      return '-'
    } else {
      return val
    }
  }

  dealValue(val, row:any, filter ? : any, filterParams = []) {
    if (filter) {
      if (typeof filter === 'string') {
        const filterFn = this.$options.filters[filter]
        return this.resolveEmpetyVal((filterFn && filterFn(val, ...filterParams)) || val)
      } else if (typeof filter === 'function') {
        return this.resolveEmpetyVal(filter(val, row, ...filterParams))
      } else {
        return this.resolveEmpetyVal(val)
      }
    } else {
      return this.resolveEmpetyVal(val)
    }
  }

  resolveGetter(row:any, str:any) {
    const strs = str.split('.')
    let origin = row
    for (let i = 0; i < strs.length; i++) {
      const s = strs[i]
      if (origin[s] != undefined && origin[s] != null) {
        origin = origin[s]
      } else {
        return null
      }
    }
    return origin
  }

  handleActionClick(action, data) {
    this.$emit(`action-${action}`, data)
  }

  public showColumnSetting() {
    this.settingDialogVisible = true
  }
}

