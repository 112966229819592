





















































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

import {
  getPushQueueList,
  repush,
  confirmFail,
  confirmSuccess,
  exportInvoiceDetail,
  applyCompareDetail,
  handleFailsApi
} from "@/api/tax";
import { IGetPushQueueListParams, IPushQueueData } from "@/api/types";

import Pagination from "@/common/components/Pagination/index.vue";
import SearchFilter from "@/common/business-components/SearchFilter/index.vue";
import BsmTable from "@/views/components/BsmTable.vue";
import { TagsViewModule } from "../../../store/modules/tags-view";
import {
  InvoicePushStatus,
  InvoicePushStatusDesc,
  InvoicePushError,
  InvoiceTypeMap
} from "@/common/constant";
import { convertToExcel, getEnumArray } from "@/common/utils";
import _ from "lodash";
import moment from "moment";
import NoCacheLink from "@/common/business-components/NoCacheLink.vue";

import MapSearchSelect from "@/views/components/TypeMap/map/MapSearchSelect.vue";

@Component({
  name: "TaxPushCarrier",
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    NoCacheLink
  }
})
export default class extends Vue {
  forcePassForm = {
    forcePassReasons: "",
    images: [],
    taxPushAntiFraudFailedId: ""
  };

  rules = {
    forcePassReasons: [
      { required: true, trigger: "blur", message: "请输入强制通过理由" }
    ]
  };

  dialogVisible = false;
  applyDetailDialogVisible = false;
  applyDetail: any = {
    apply_tax_detail_view: {},
    return_tax_detail_view: {}
  };

  list: IPushQueueData[] = [];
  total = 0;
  listLoading = true;
  failedLogs: string[] = [];
  listQuery = {
    // 0：待推送；1、推送失败；9、推送成功'
    status: null,
    invoice_type: null,
    tax_order: null,
    tax_order_status_list: [],
    // 当月1 次月2
    push_date: moment().format("YYYY-MM"),
    page: {
      index: 1,
      limit: 10
    }
  };

  queryFilterOptions = [
    {
      field: "subject_did",
      label: "承运人",
      component: MapSearchSelect,
      config: {
        field: "carrier"
      },
      main: true
    },
    {
      field: "agent_did",
      label: "网络货运企业",
      component: MapSearchSelect,
      config: {
        field: "agent"
      },
      main: true
    },
    {
      field: "push_date",
      label: "推送月份",
      componentName: "el-date-picker",
      attrs: {
        type: "month",
        format: "yyyy-MM",
        valueFormat: "yyyy-MM"
      },
      main: true
    },
    {
      field: "invoice_type",
      label: "发票类型",
      type: "select",
      config: {
        list: getEnumArray(InvoiceTypeMap)
      },
      main: true
    },
    {
      field: "status",
      label: "发票状态",
      type: "select",
      config: {
        list: getEnumArray(InvoicePushStatusDesc)
      },
      main: true
    },
    {
      field: "applyNo",
      label: "申请编号",
      main: true
    },
    {
      field: "confirmNo",
      label: "确认编号",
      main: true
    },
    {
      field: "tax_order",
      label: "税务订单状态",
      type: "select",
      config: {
        list: [
          { label: "司机税务登记异常", value: "1" },
          { label: "待扣款订单", value: "2" },
          { label: "已扣款订单", value: "3" },
          { label: "待金三验证是否缴税成功订单", value: "4,5" },
          { label: "扣款失败，作废订单", value: "6" },
          { label: "缴税失败订单", value: "7,8" }
        ]
      },
      main: true
    }
  ];

  tableListOptions = [
    {
      field: "id",
      type: "actionSetting",
      attrs: {
        actionOptions: [
          {
            label: "重新推送",
            type: "repush",
            disabled: function(config, row) {
              if (row.status !== InvoicePushStatus.PushFailed) {
                return true;
              } else {
                return false;
              }
            }
          },
          {
            label: "异常处理",
            type: "abnormalProcess",
            disabled: function(config, row) {
              if (row.tax_order_status_alias === "缴税失败订单") {
                return false;
              } else {
                return true;
              }
            }
          },
          {
            label: "确认成功",
            type: "confirmSuccess",
            disabled: function(config, row) {
              const failedRes =
                row.failed_response[row.failed_response.length - 1];
              if (
                row.status === InvoicePushStatus.PushFailed &&
                failedRes &&
                failedRes.error_type === InvoicePushError.TaxInterfaceError
              ) {
                return false;
              } else {
                return true;
              }
            }
          },
          {
            label: "确认失败",
            type: "confirmFail",
            disabled: function(config, row) {
              if (row.status !== InvoicePushStatus.PushFailed) {
                return true;
              } else {
                return false;
              }
            }
          },
          {
            label: "查看失败信息",
            type: "viewError",
            disabled: function(config, row) {
              if (
                row.status !== InvoicePushStatus.PushFailed &&
                row.status !== 10
              ) {
                return true;
              } else {
                return false;
              }
            }
          },
          {
            label: "查看详情",
            type: "detail"
          },
          {
            label: "查看发票",
            type: "viewTax",
            disabled: function(config, row) {
              if (row.status === 2 || row.status === 7 || row.status === 8) {
                return false;
              } else {
                return true;
              }
            }
          }
        ]
      },
      label: "操作",
      width: 50,
      align: "center",
      fixed: true
    },
    {
      field: "apply_no",
      label: "开票申请编号",
      fixed: true
    },
    {
      field: "invoice_type",
      label: "发票类型",
      filter: val => {
        return InvoiceTypeMap[val];
      }
    },
    {
      field: "tax_order_status_alias",
      label: "税务订单状态"
    },
    {
      field: "agent_name",
      label: "网络货运企业"
    },
    {
      field: "subject_name",
      label: "承运人名称",
      width: 180
    },
    {
      field: "status",
      label: "推送状态",
      type: "linkLike",
      config: {
        label: val => {
          return val === 10 || val === 1
            ? InvoicePushStatusDesc[val]
            : `<span style="color:#686868;cursor:default;">${InvoicePushStatusDesc[val]}</span>`;
        },
        handle: (val, row) => {
          if (val === 10 || val === 1) {
            this.popDialog(row);
          }
        }
      }
    },
    // {
    //   field: 'status',
    //   label: '推送状态',
    //   filter: 'invoicePushStatus',
    //   width: 180,
    //   align: 'center'

    // },
    {
      field: "apply_time",
      label: "申请时间",
      filter: "timeFilterShowSecond",
      width: 100,
      align: "center"
    },
    {
      field: "push_time",
      label: "推送时间",
      filter: "timeFilterShowSecond",
      width: 100,
      align: "center"
    },
    {
      field: "last_update_time",
      label: "最后一次更新时间",
      filter: "timeFilterShowSecond",
      width: 100,
      align: "center"
    },
    {
      field: "item_count",
      label: "运单或子运单笔数",
      width: 100,
      align: "center"
    }
  ];

  created() {
    this.initListQuery();
    this.getList();
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query);
      this.listQuery.invoice_type &&
        (this.listQuery.invoice_type = Number(this.listQuery.invoice_type));
    }
  }

  popDialog(row) {
    this.failedLogs = row.failed_response;
    this.dialogVisible = true;
  }

  async getList() {
    this.listLoading = true;
    this.listQuery.tax_order_status_list = [];
    if (this.listQuery.tax_order) {
      const item =
        this.listQuery.tax_order.indexOf(",") > 0
          ? this.listQuery.tax_order.split(",")[0]
          : this.listQuery.tax_order;
      this.listQuery.tax_order_status_list.push(Number(item));
      if (this.listQuery.tax_order.indexOf(",") > 0) {
        this.listQuery.tax_order_status_list.push(
          Number(this.listQuery.tax_order.split(",")[1])
        );
      }
    }
    const listQuery = JSON.parse(JSON.stringify(this.listQuery));
    delete listQuery.tax_order;
    const { data } = await getPushQueueList(listQuery);
    this.list = data.items;
    this.total = data.total;
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  handleFilter() {
    this.listQuery.page.index = 1;
    this.getList();
  }

  async linkToDetail(id: string, row: any) {
    const path = "/dataquery/sub_waybill";
    const view = (this.$router as any).matcher.match(path);
    await TagsViewModule.delCachedView(view);
    this.$router.push({
      path,
      query: {
        subWaybillIds: row.link_ids
      }
    });
  }

  noCacheLinkBeforeHook(to) {
    this.applyDetailDialogVisible = false;
  }

  viewWaybill() {
    this.applyDetailDialogVisible = false;
    let path = "";
    const query: any = {};

    if (this.applyDetail.invoice_type === 2) {
      query.subWaybillIds = this.applyDetail.sub_waybill_ids;
      path = "/dataquery/sub_waybill";
    } else {
      query.waybillIds = this.applyDetail.waybill_ids;
      path = "/dataquery/waybill";
    }

    this.$router.push({
      path: path,
      query: query
    });
  }

  viewInvoiced() {
    this.applyDetailDialogVisible = false;
    const query: any = {};
    query.invoiceCode = this.applyDetail.invoice_code;
    const path = "/tax/invoiced";
    this.$router.push({
      path: path,
      query: query
    });
  }

  viewTaxRate() {
    this.applyDetailDialogVisible = false;
    this.$router.push({
      path: "/tax/tax_rate"
    });
  }

  async handleClickDetail(id, row) {
    applyCompareDetail({
      id: id
    }).then(res => {
      this.applyDetail = res.data;
      this.applyDetailDialogVisible = true;
    });
  }

  handleActionCommand(command, id, row) {
    if (command === "repush") {
      this.$confirm("此操作将重新推送, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          repush({
            id: id
          }).then(res => {
            this.$message.success("重新推送成功");
            this.getList();
          });
        })
        .catch(e => {
          console.log(e);
        });
    } else if (command === "viewError") {
      this.failedLogs = row.failed_response;
      this.dialogVisible = true;
    } else if (command === "detail") {
      // this.linkToDetail(id, row)
      this.handleClickDetail(id, row);
    } else if (command === "viewTax") {
      this.$router.push({
        path: "/tax/invoiced",
        query: { summaryUUID: row.external_invoice_id }
      });
    } else if (command === "confirmFail") {
      confirmFail({
        id: id
      }).then(res => {
        this.$message.success("确认失败操作成功");
        this.getList();
      });
    } else if (command === "confirmSuccess") {
      this.$prompt("请输入确认成功的uuid", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then((res: any) => {
        const { value } = res;
        if (!value) {
          this.$message.error("请输入uuid");
          return false;
        }
        confirmSuccess({
          id: id,
          uuid: value
        }).then(res => {
          this.$message.success("确认成功操作成功");
          this.getList();
        });
      });
    } else if (command === "abnormalProcess") {
      handleFailsApi(row.id);
    }
  }

  handleClick(type) {
    if (type === "export") {
      exportInvoiceDetail(this.listQuery)
        .then(res => {
          const data: any = [res];
          const date: string = moment()
            .format("YYYY_MM_DD")
            .toString();
          convertToExcel(data, `开票明细_${date}.xls`);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
    }
  }
}
