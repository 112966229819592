
























































import { Vue, Component } from "vue-property-decorator";
import areaData from "@/assets/data/area_code_2023.json";

@Component({
  name: "ProgressDetailModal",
  components: {}
})
export default class ProgressDetailModal extends Vue {
  showDialog = false;

  formData = {
    rule_name: "",
    rule_id: "",
    type: "",
    if_appeal: "",
    scheme_names: "",
    district_codes: "",
    add_agent_scheme: [],
    stop_agent_scheme: []
  };

  address = "";

  async open(data) {
    this.formData = data;
    this.address = "";
    this.getAddress();
    this.showDialog = true;
  }

  close() {
    this.showDialog = false;
  }

  getAddress() {
    if (this.formData.district_codes) {
      const adcodeList = this.formData.district_codes.split(",");
      adcodeList.forEach((code, idx) => {
        const codeArr = code.split("-");
        const provinceCode = codeArr[0];
        const cityCode = codeArr[1];
        const provinceItem = areaData.find(v => v.code === +provinceCode);
        this.address = `${this.address}${
          idx === 0 ? "" : "，"
        }${provinceItem?.name || ""}`;
        if (
          provinceItem &&
          provinceItem.children &&
          provinceItem.children.length
        ) {
          const cityItem = provinceItem.children.find(
            cv => cv.code === +cityCode
          );
          if (cityItem) {
            this.address = `${this.address}-${cityItem?.name || ""}`;
            if (cityItem.children && cityItem.children.length && codeArr[2]) {
              const districtItem = cityItem.children.find(
                dv => dv.code === +codeArr[2]
              );
              if (districtItem) {
                this.address = `${this.address}-${districtItem?.name || ""}`;
              }
            }
          }
        }
      });
    }
  }
}
