var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticClass: "search-top",
          staticStyle: { padding: "0 0 16px 8px", "font-size": "14px" }
        },
        [
          _c("span", [_vm._v("行政区划：" + _vm._s(_vm.area))]),
          _c("span", { staticStyle: { "margin-left": "16px" } }, [
            _vm._v("系统名称：" + _vm._s(_vm.desc))
          ])
        ]
      ),
      _c("bsm-table", {
        attrs: {
          name: "black-list",
          data: _vm.list,
          options: _vm.tableListOptions,
          "list-loading": _vm.listLoading,
          "show-index": true,
          "show-setting": false
        },
        on: { actionCommand: _vm.handleActionCommand }
      }),
      _c(
        "div",
        {
          staticClass: "pagination-container",
          staticStyle: { "padding-top": "16px" }
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function($event) {
                  return _vm.handleActionCommand("add")
                }
              }
            },
            [_vm._v(" 添加 ")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-container",
          attrs: { title: _vm.popTitle, visible: _vm.visible, width: "600px" },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.handleCloseConfig
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.formData,
                    "label-width": "130px",
                    disabled: _vm.currentActionType === "detail"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "核验项编码" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.code,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "code", $$v)
                          },
                          expression: "formData.code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "核验项描述" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.description,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "申诉类型", prop: "appealType" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.formData.appealType,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "appealType", $$v)
                            },
                            expression: "formData.appealType"
                          }
                        },
                        _vm._l(_vm.appealTypeList, function(config, i) {
                          return _c("el-option", {
                            key: i,
                            attrs: { label: config.label, value: config.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.formData.appealType === 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "申诉节点", prop: "appealNode" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.formData.appealNode,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "appealNode", $$v)
                                },
                                expression: "formData.appealNode"
                              }
                            },
                            _vm._l(_vm.appealNodeList, function(config, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: {
                                  label: config.label,
                                  value: config.value
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "0 0 16px 0",
                        "font-size": "15px",
                        "font-weight": "500"
                      }
                    },
                    [_vm._v(" 监管规则映射 ")]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规则名称", prop: "ruleId" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.formData.ruleId,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "ruleId", $$v)
                            },
                            expression: "formData.ruleId"
                          }
                        },
                        _vm._l(_vm.riskRuleTypeList, function(config, i) {
                          return _c("el-option", {
                            key: i,
                            attrs: { label: config.label, value: config.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "失败描述" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.formData.resultMsg,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "resultMsg", $$v)
                          },
                          expression: "formData.resultMsg"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.visible = false
                    }
                  }
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.subLoading },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v(" 保存 ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }