import { render, staticRenderFns } from "./index.vue?vue&type=template&id=068e0226&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wsy/Desktop/code/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('068e0226')) {
      api.createRecord('068e0226', component.options)
    } else {
      api.reload('068e0226', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=068e0226&", function () {
      api.rerender('068e0226', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/action-log/index.vue"
export default component.exports