var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "china-map-container" },
    [
      _c(
        "div",
        {
          staticClass: "toggle-btn-group",
          class: { mini: _vm.size === "small" }
        },
        [
          _c(
            "span",
            {
              staticClass: "toggle-btn-dispatch",
              class: { active: _vm.type === "dispatch" },
              on: {
                click: function($event) {
                  return _vm.handleToggleColorType("dispatch")
                }
              }
            },
            [_vm._v("发货")]
          ),
          _c(
            "span",
            {
              staticClass: "toggle-btn-receive",
              class: { active: _vm.type === "receive" },
              on: {
                click: function($event) {
                  return _vm.handleToggleColorType("receive")
                }
              }
            },
            [_vm._v("收货")]
          )
        ]
      ),
      _c("filled-map", {
        attrs: {
          data: _vm.data,
          gradient: _vm.gradient,
          step: _vm.step,
          size: _vm.size,
          max: _vm.max
        }
      }),
      _c("div", { staticClass: "tip", class: { mini: _vm.size === "small" } }, [
        _c("span", { staticClass: "scale-min" }, [_vm._v("0")]),
        _c("span", {
          staticClass: "line",
          style: {
            background:
              "-webkit-linear-gradient(left,#ffffff 0%," + _vm.color + " 100%)"
          }
        }),
        _c("span", { staticClass: "scale-max" }, [_vm._v(_vm._s(_vm.max))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }