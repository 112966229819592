var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-authority-container" },
    [
      _c(
        "el-row",
        { staticClass: "top-row" },
        [
          !_vm.isEdit()
            ? _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入角色名称" },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: 6, offset: _vm.isEdit() ? 0 : 1 } },
            [
              _c(
                "el-button",
                {
                  attrs: { offset: 1, type: "primary" },
                  on: { click: _vm.handleSaveChange }
                },
                [_vm._v(" " + _vm._s(_vm.isEdit() ? "保存" : "创建") + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "authority-tree-container" },
        [_c("authority-tree", { attrs: { data: _vm.data } })],
        1
      ),
      _c("el-row")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }