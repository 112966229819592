import request from '@/utils/request'
import { IGetListParams, IGetDashboardParams } from './types'

const path = 'consignor/aggregation'

export const getConsignorList = (params: IGetListParams) =>
  request({
    url: `${path}/list`,
    method: 'post',
    data: params
  })

export const getConsignorDashboard = (params: IGetDashboardParams) =>
  request({
    url: `${path}/dashboard`,
    method: 'post',
    data: params
  })

export const getAlarmRuleList = (params: any) => request({
  url: 'consignor/alarm',
  method: 'post',
  data: params
})

export const getConsignorInvoice = (params:IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/invoice`,
    method: 'post',
    data: params
  })
export const getConsignorRank = (params:IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/rank`,
    method: 'post',
    data: params
  })
export const getConsignorSubjectCount = (params:IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/subject/count`,
    method: 'post',
    data: params
  })
export const getConsignorType = (params:IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/type`,
    method: 'post',
    data: params
  })
export const getConsignorWaybillChart = (params:IGetDashboardParams) =>
  request({
    url: `${path}/dashboard/waybill/chart`,
    method: 'post',
    data: params
  })
export const setVerify = (params:any) =>
  request({
    url: '/subject_verify/start',
    method: 'post',
    data: params
  })
