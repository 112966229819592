var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("dashboard-header", {
        attrs: {
          "end-limit": _vm.endLimit,
          "time-range": [_vm.params.start_time, _vm.params.end_time],
          "disabled-short-cut-option": _vm.disabledShortCutOption
        },
        on: {
          onTimeRangeChange: _vm.handleTimeChange,
          onBusinessModeChange: _vm.handleBusinessChange,
          onTimeTypeChange: _vm.handleTimeTypeChange
        }
      }),
      _c("div", { staticClass: "agent-rank-container" }, [
        _c(
          "section",
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["agent_sort_export"],
                    expression: "['agent_sort_export']"
                  }
                ],
                attrs: {
                  size: "mini",
                  type: "primary",
                  loading: _vm.downLoading
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.exportList($event)
                  }
                }
              },
              [_vm._v(" 下载排名表 ")]
            ),
            _c("section", { staticClass: "pt-4 font-12" }, [
              _c("p", [
                _vm._v(
                  "本报告统计周期为" +
                    _vm._s(_vm.cycleStart) +
                    "至" +
                    _vm._s(_vm.cycleEnd) +
                    "，报告期内产业园新增接入的网络货运企业" +
                    _vm._s(_vm.report.new_access_agents) +
                    "家"
                )
              ]),
              _c("p", [
                _vm._v(
                  "截止统计周期末，产业园共接入网络货运企业" +
                    _vm._s(_vm.report.accumulate_access_agents) +
                    "家，其中活跃网络货运企业" +
                    _vm._s(_vm.report.active_agents) +
                    "家，产业园活跃度" +
                    _vm._s(_vm.report.active_percent / 100) +
                    "%。"
                )
              ]),
              _c("p", [_vm._v("活跃的定义：单月发生业务30单及以上")])
            ])
          ],
          1
        ),
        _c(
          "section",
          { staticClass: "table-container" },
          [
            _c("bsm-table", {
              ref: "bsm_table",
              attrs: {
                name: "agent_rank",
                data: _vm.list,
                options: _vm.tableListOptions,
                "list-loading": _vm.listLoading,
                "show-index": true
              },
              on: { "action-detail": _vm.handelActionDetail }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }