










import { Vue, Component, Prop } from 'vue-property-decorator'
import TableList from '@/common/business-components/TableList/index.vue'
import { UserModule } from '@/store/modules/user'
import { IStorage } from '@/common/utils/storage'

@Component({
  components: {
    TableList
  },
  filters: {}
})
export default class extends Vue {
  $bus:any

  storage: IStorage = {
    getItem: (key: string) => {
      return UserModule.tableSetting[key]
    },
    setItem: (key: string, value: any) => {
      UserModule.SetTableSettingItem({ key: key, value: value })
    }
  }

  handleColumnSave(displayFields: any, hiddenFields: any) {
    UserModule.SaveUserTableSetting().then(res => {
      this.$message.success('设置保存成功')
      this.$bus.$emit('refreshCurrent', this.$route)
    }).catch(e => {
      console.log(e)
      this.$message.error('设置保存失败')
    })
  }

  public getHiddenFieldsCache() {
    return (this.$refs.table_list as TableList).getHiddenFieldsCache()
  }

  public getDisplayFieldsCache() {
    return (this.$refs.table_list as TableList).getDisplayFieldsCache()
  }
}

