// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.map-pannel-bei-dou {\n  position: absolute;\n  width: 220px;\n  z-index: 1000;\n  top: 0;\n  right: 10px;\n  background-color: white;\n}\n.map-pannel-bei-dou .content {\n    font-size: 12px;\n    margin-left: 10px;\n}\n.map-pannel-bei-dou .header-icon {\n    margin-left: 10px;\n}\n.map-pannel-bei-dou .el-icon-info:before {\n    margin-right: 5px;\n    content: \"\\2022\";\n    font-size: 20px;\n}\n.map-pannel-bei-dou .line-hide {\n    color: #e6ebf5 !important;\n}\n.map-pannel-bei-dou .el-collapse-item__wrap {\n    border-bottom: 0;\n}\n.map-pannel-bei-dou .el-collapse-item__content {\n    padding-bottom: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
