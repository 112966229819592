/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'umbrella': {
    width: 21,
    height: 20,
    viewBox: '0 0 21 20',
    data: '<g _stroke="#999" _fill="none" fill-rule="evenodd"><path pid="0" d="M10.556 0v17.066a1.934 1.934 0 01-3.869 0v-1.323h0"/><path pid="1" d="M19.111 11A8.556 8.556 0 102 11h17.111z" _fill="#FFF"/></g>'
  }
})
