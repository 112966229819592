var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.currentName,
        "modal-append-to-body": false,
        visible: _vm.alarmAgentListDialogVisible,
        "destroy-on-close": true,
        size: "large",
        width: "80%"
      },
      on: {
        "update:visible": function($event) {
          _vm.alarmAgentListDialogVisible = $event
        }
      }
    },
    [
      _c("search-filter", {
        attrs: {
          options: _vm.alarmAgentFilterOptions,
          "type-default": "input"
        },
        on: { search: _vm.handleAlarmAgentFilter },
        model: {
          value: _vm.alarmAgentQuery,
          callback: function($$v) {
            _vm.alarmAgentQuery = $$v
          },
          expression: "alarmAgentQuery"
        }
      }),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-col",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.downloadAlarmAgent }
                },
                [_vm._v(" 下载 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("table-list", {
        attrs: {
          "auto-fit": false,
          data: _vm.alarmAgent,
          options: _vm.alarmAgentOptions,
          "show-setting": false,
          height: "500"
        }
      }),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.alarmAgentTotal > 0,
            expression: "alarmAgentTotal>0"
          }
        ],
        attrs: {
          "auto-scroll": false,
          "page-sizes": [5],
          total: _vm.alarmAgentTotal,
          page: _vm.alarmAgentQuery.page.index,
          limit: _vm.alarmAgentQuery.page.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.alarmAgentQuery.page, "index", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.alarmAgentQuery.page, "limit", $event)
          },
          pagination: _vm.getAlarmAgent
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }