




























import { Vue, Component, Prop } from "vue-property-decorator";
import { parseTime } from "@/common/utils";
import DetailDialog from "./DetailDialog.vue";
import SearchFilter from "@/common/business-components/SearchFilter/index.vue";
import TableList from "@/common/business-components/TableList/index.vue";
import Pagination from "@/common/components/Pagination/index.vue";
import { getSubsidyTaxPageApi } from "@/api/tax";
import moment from "moment";
import MapSearchSelect from "@/views/components/TypeMap/map/MapSearchSelect.vue";

@Component({
  name: "DataCompare",
  components: { DetailDialog, SearchFilter, TableList, Pagination }
})
export default class extends Vue {
  list: any[] = [];
  total = 0;
  listLoading = true;
  listQuery: any = {
    agent: null,
    page: {
      index: 1,
      limit: 10
    }
  };

  queryFilterOptions = [
    {
      field: "agentDid",
      label: "网络企业",
      component: MapSearchSelect,
      config: {
        field: "agent"
      },
      main: true
    },
    {
      field: "date",
      label: "年月",
      componentName: "el-date-picker",
      attrs: {
        type: "monthrange",
        startPlaceholder: "开始月份",
        endPlaceholder: "结束月份"
      },
      main: true
    }
  ];

  tableListOptions = [
    {
      field: "id",
      type: "actionSetting",
      attrs: {
        actionOptions: [
          {
            label: "查看详情",
            type: "detailAction"
          }
        ]
      },
      label: "操作",
      width: 50,
      align: "center",
      fixed: true
    },
    {
      field: "agentName",
      label: "网货企业名称"
    },
    {
      field: "month",
      label: "年月",
      width: 150,
      filter: v => {
        return moment(v).format("YYYY-MM");
      }
    },
    {
      field: "updateTime",
      label: "更新时间",
      filter: "timeFilterShowSecond"
    }
  ];

  getQueryParams() {
    const listQuery = JSON.parse(JSON.stringify(this.listQuery));
    if (listQuery.date) {
      listQuery.startMonth = moment(listQuery.date[0]).format("YYYY-MM-01");
      listQuery.endMonth = moment(listQuery.date[1])
        .endOf("month")
        .format("YYYY-MM-DD");
      listQuery.date = undefined;
    }
    return listQuery;
  }

  async getList() {
    this.listLoading = true;
    const { data } = await getSubsidyTaxPageApi(this.getQueryParams());

    this.list = data.items;
    this.total = data.total;

    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  handleFilter() {
    this.listQuery.page.index = 1;
    this.getList();
  }

  handleActionCommand(command, id, row) {
    if (command === "detailAction") {
      const dialog: any = this.$refs.detailDialogRef;
      dialog.openDetailModal(row);
    }
  }

  created() {
    this.getList();
  }
}
