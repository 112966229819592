var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          style: { width: _vm.width + "px" },
          attrs: {
            filterable: "",
            remote: "",
            "reserve-keyword": "",
            placeholder: "请输入关键词",
            clearable: "",
            "remote-method": _vm.remoteMethod,
            loading: _vm.loading
          },
          on: { clear: _vm.handleClear }
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.options, function(item) {
      return _c(
        "el-option",
        {
          key: item.id,
          attrs: {
            label: item.username,
            value: _vm.values === "username" ? item.username : item.id
          }
        },
        [_vm._t("option")],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }