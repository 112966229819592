var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "waybill-base-container " + (_vm.waybillInfo.deleted ? "deleted" : "")
    },
    [
      _c("div", { staticClass: "content-title x-between" }, [
        _c(
          "span",
          {
            staticClass: "header-title",
            staticStyle: { "font-weight": "500", color: "#4d4d4d" }
          },
          [_vm._v("运单基础信息")]
        ),
        _vm.waybillInfo.hasContract && !_vm.isShipperClient
          ? _c(
              "a",
              {
                staticClass: "text-primary contract-link",
                attrs: {
                  target: "_blank",
                  disabled: !_vm.waybillInfo.hasContract,
                  href: _vm.waybillInfo.filePdfPath
                }
              },
              [_vm._v("托运合同查询")]
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "content-header" }, [
        _vm.isShipperClient
          ? _c("span", [_vm._v("承运平台：" + _vm._s(_vm.waybillInfo.agent))])
          : _c(
              "span",
              [
                _vm._v(" 托运人： "),
                _c(
                  "router-link",
                  {
                    staticClass: "pointer text-primary",
                    attrs: {
                      to:
                        "/dataquery/consignor/" + _vm.waybillInfo.consignor_did
                    }
                  },
                  [_vm._v(_vm._s(_vm.waybillInfo.consignor))]
                )
              ],
              1
            ),
        _vm.isShipperClient
          ? _c("span", { staticStyle: { "margin-left": "72px" } }, [
              _vm._v("运单号：" + _vm._s(_vm.waybillInfo.waybillNo))
            ])
          : _c("span", { staticStyle: { "margin-left": "72px" } }, [
              _vm._v("货源单号：" + _vm._s(_vm.waybillInfo.sourceWaybillNo))
            ]),
        _c("span", { staticStyle: { "margin-left": "72px" } }, [
          _vm._v(
            "发货时间：" +
              _vm._s(_vm._f("timeFilterShowSecond")(_vm.waybillInfo.sendTime))
          )
        ]),
        _c("span", { staticStyle: { "margin-left": "72px" } }, [
          _vm._v(
            "签收时间：" +
              _vm._s(
                _vm._f("timeFilterShowSecond")(_vm.waybillInfo.receiveTime)
              )
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "content-body" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "address-container pa-4" }, [
                  _c("div", { staticClass: "title" }, [_vm._v(" 发货信息 ")]),
                  _c("span", [
                    _vm._v("发货人：" + _vm._s(_vm.waybillInfo.senderName))
                  ]),
                  _c("span", [
                    _vm._v("联系人电话：" + _vm._s(_vm.waybillInfo.senderPhone))
                  ]),
                  _c("span", [
                    _vm._v("发货区域：" + _vm._s(_vm.waybillInfo.loadDistrict))
                  ]),
                  _c("span", [
                    _vm._v(
                      "发货详细地址：" + _vm._s(_vm.waybillInfo.loadAddress)
                    )
                  ])
                ])
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "address-container pa-4" }, [
                  _c("div", { staticClass: "title" }, [_vm._v(" 收货信息 ")]),
                  _c("span", [
                    _vm._v("收货人：" + _vm._s(_vm.waybillInfo.receiverName))
                  ]),
                  _c("span", [
                    _vm._v(
                      "联系人电话：" + _vm._s(_vm.waybillInfo.receiverPhone)
                    )
                  ]),
                  _c("span", [
                    _vm._v(
                      "收货区域：" + _vm._s(_vm.waybillInfo.receiveDistrict)
                    )
                  ]),
                  _c("span", [
                    _vm._v(
                      "收货详细地址：" + _vm._s(_vm.waybillInfo.receiveAddress)
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "verify-container pa-4" },
            [
              _c(
                "anti-fraud",
                {
                  attrs: {
                    data: _vm.antiFraudInfo,
                    "waybill-id": _vm.waybillId,
                    type: _vm.type
                  }
                },
                [_vm._t("default")],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }