






































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

import {
  findInspectStrategyList,
  findinspectStrategyDetail,
  createInspectStrategy,
  editInspectStrategy
} from "@/api/inspect";
import {
  IInspectStrategyDetailData,
  IInspectStrategyListData
} from "@/api/types";
import { EnumActions } from "@/common/business-components/TypeMap/ActionSetting/index.vue";
import Pagination from "@/common/components/Pagination/index.vue";
import SearchFilter from "@/common/business-components/SearchFilter/index.vue";
import BsmTable from "@/views/components/BsmTable.vue";
import {
  InspectContentTypeMap,
  InspectBusinessTypeMap,
  InspectContentPayTypeMap
} from "@/common/constant";
import { getEnumArray } from "@/common/utils";
import { Form } from "element-ui";
import { UserModule } from "@/store/modules/user";

@Component({
  name: "InspectStrategy",
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  list: IInspectStrategyListData[] = [];
  total = 0;
  listLoading = true;
  subLoading = false;
  visible = false;
  listQuery = {
    name: "",
    type: "",
    status: "",
    page: {
      index: 1,
      limit: 10
    }
  };

  temp: IInspectStrategyDetailData = {
    business_type: "",
    days: undefined,
    description: "描述",
    frequency_type: "",
    id: "",
    init: "",
    max_count: "",
    name: "",
    percentage: "",
    status: 1,
    strategy_no: "",
    type: ""
  };

  get days() {
    return this.days;
  }

  get inspectDescription() {
    const typeMap = {
      1: "所有命中轨迹类规则的运单（没有轨迹的规则除外）",
      2: "支付类型不是产业园支付【需要数据上做区分】且运单支付状态=已支付/承运人运费结清",
      3: "命中中等规则的运单且已经提交补充信息",
      4: "命中没有轨迹的运单",
      5: "已签收且命中交通不合规的运单",
      6: "命中非司机本人收款的运单",
      7: "抽取任意运单",
      8: "运单有装卸货信息，且设备类型为小黑卡"
    };
    return typeMap[this.temp.type] || "";
  }

  get roles() {
    return UserModule.roles;
  }

  get rules() {
    return {
      name: [
        {
          required: true,
          message: "抽检策略名称必填",
          trigger: "blur"
        }
      ],
      type: [
        {
          required: true,
          message: "抽检内容必填",
          trigger: "blur"
        }
      ],
      business_type: [
        {
          required: true,
          message: "抽检类型必填",
          trigger: "blur"
        }
      ],
      percentage: [
        {
          required: true,
          message: "数据抽检数量必填",
          trigger: "blur"
        }
      ],
      max_count: [
        {
          required: true,
          message: "抽检数量上限必填",
          trigger: "blur"
        }
      ],
      frequency_type: [
        {
          required: true,
          message: "数据抽检频率必填",
          trigger: "change"
        },
        {
          validator: (rule, value, callback) => {
            if (value === 4 && !this.temp.days) {
              callback(new Error("请填写自定义天数"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }
      ],
      status: [
        {
          required: true,
          message: "策略状态必选",
          trigger: "change"
        }
      ],
      description: [
        {
          required: true,
          message: "抽检运单范围必填",
          trigger: "blur"
        }
      ],
      init: [
        {
          required: true,
          message: "是否做默认抽检必选",
          trigger: "change"
        }
      ]
    };
  }

  actionTypeMap = {
    [EnumActions.add]: {
      title: "新增抽检策略"
    },
    [EnumActions.detail]: {
      title: "抽检策略查看"
    },
    [EnumActions.edit]: {
      title: "抽检策略编辑"
    }
  };

  currentActionType: EnumActions = null;

  businessTypes = [
    {
      label: "业务类",
      value: 1
    },
    {
      label: "支付类",
      value: 2
    }
  ];

  inspectContentTypes = getEnumArray(InspectBusinessTypeMap);

  queryFilterOptions = [
    {
      field: "name",
      label: "抽检策略名称"
      // attrs: {}
    },
    {
      field: "status",
      label: "策略状态",
      type: "select",
      config: {
        list: [
          {
            value: 1,
            label: "启用"
          },
          {
            value: 2,
            label: "禁用"
          }
        ]
      }
    },
    {
      field: "type",
      label: "抽检内容",
      type: "select",
      config: {
        list: getEnumArray(InspectContentTypeMap)
      }
    }
  ].map(item => {
    return Object.assign({}, item, { main: true });
  });

  get tableListOptions() {
    return [
      {
        field: "id",
        type: "actionSetting",
        attrs: {
          actionOptions: [
            {
              type: EnumActions.detail,
              label: "查看",
              disabled: (val, row) => {
                return !this.roles.find(
                  role => role === "sample_inspect_strategy_detail"
                );
              }
            },
            {
              type: EnumActions.edit,
              label: "编辑",
              disabled: (val, row) => {
                return !this.roles.find(
                  role => role === "sample_inspect_strategy_edit"
                );
              }
            }
          ]
        },
        label: "操作",
        fixed: true
      },
      {
        field: "name",
        label: "抽检策略名称",
        type: "linkLike",
        config: {
          label(val) {
            return val;
          }
        }
      },
      {
        field: "strategy_no",
        label: "抽检策略编号",
        type: "linkLike",
        config: {
          label(val) {
            return val;
          },
          handle: (val, row) => {
            if (
              this.roles.find(role => role === "sample_inspect_strategy_detail")
            ) {
              this.handleActionCommand(EnumActions.detail, row.id);
            } else {
              this.$message.error("没有权限");
            }
          }
        }
      },
      {
        field: "status_desc",
        label: "抽检策略状态"
      },
      {
        field: "create_time",
        label: "创建时间",
        filter: "timeFilterShowSecond"
      },
      {
        field: "agent_count",
        label: "应用网货企业数量",
        type: "link",
        config: {
          url(val, row) {
            return `/risk_rules/enterprise-inspect-config?strategy_id=${row.id}`;
          },
          noCache: true
        }
      },
      {
        field: "consignor_count",
        label: "应用托运人数量",
        type: "link",
        config: {
          url(val, row) {
            return `/risk_rules/enterprise-inspect-config?strategy_id=${row.id}&tab=1`;
          },
          noCache: true
        }
      },
      {
        field: "type_desc",
        label: "策略面向对象"
      }
    ];
  }

  created() {
    this.initListQuery();
    this.getList();
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query);
    }
  }

  businessTypeChange(val) {
    this.temp.type = "";
    this.inspectContentTypes =
      val === 1
        ? getEnumArray(InspectBusinessTypeMap)
        : getEnumArray(InspectContentPayTypeMap);
  }

  get dialogTitle() {
    return this.actionTypeMap[this.currentActionType]?.title || "";
  }

  async getList() {
    this.listLoading = true;
    const { data } = await findInspectStrategyList(this.listQuery);
    this.list = data.items;
    this.total = data.total;
    await this.$nextTick();
    this.listLoading = false;
  }

  async getDetail(id) {
    const { data } = await findinspectStrategyDetail({ id });
    this.temp = data;
    this.temp.days = data.days || undefined;
    this.inspectContentTypes =
      this.temp.business_type === 1
        ? getEnumArray(InspectBusinessTypeMap)
        : getEnumArray(InspectContentPayTypeMap);
  }

  async submitDetail() {
    try {
      const valid = await (this.$refs.form as Form).validate();
      if (valid) {
        try {
          const data = Object.assign({}, this.temp, {
            description: this.inspectDescription
          });
          delete data.strategy_no;
          this.subLoading = true;
          if (!data.id) {
            // delete data.id
            // delete data.days
            await createInspectStrategy(data);
            this.$message.success("新增抽检策略成功");
            this.getList();
          } else {
            await editInspectStrategy(data);
            this.$message.success("抽检策略编辑成功");
            this.getList();
          }
          this.visible = false;
          this.subLoading = false;
        } catch (err) {
          this.subLoading = false;
        }
      }
    } catch (err) {}
  }

  resetTemp() {
    (this.$refs.form as Form).resetFields();
    this.temp = {
      days: undefined,
      description: "描述",
      frequency_type: "",
      business_type: "",
      id: "",
      init: "",
      max_count: "",
      name: "",
      percentage: "",
      status: 1,
      strategy_no: "",
      type: ""
    };
  }

  handleFilter() {
    this.listQuery.page.index = 1;
    this.listQuery.page.limit = 10;
    this.getList();
  }

  handelActionDetail(id: string) {
    // this.$router.push({
    //   path: `/risk_rules/detail/${id}`
    // })
  }

  // async handleActionSwitch(command, id) {
  //   const text = command === EnumActions.enable ? '启用' : '停用'
  //   const res: any = await swithRuleStatus(id)
  //   if (res.code === 0) {
  //     this.$message.success(`${text}成功`)
  //     this.getList()
  //   } else {
  //     this.$message.error(`${text}失败`)
  //   }
  // }

  handleClose() {
    this.resetTemp();
    this.visible = false;
    this.subLoading = false;
  }

  handleAdd() {
    this.visible = true;
    // something to do
    // this.handelActionDetail('create')
  }

  handleEdit(id: string) {
    this.visible = true;
    this.getDetail(id);
    // something todo
    // this.handelActionDetail(id)
  }

  handleDetail(id: string) {
    this.visible = true;
    this.getDetail(id);
  }

  handleActionCommand(command, id) {
    this.currentActionType = command;
    if (command === EnumActions.add) {
      this.handleAdd();
    } else if (command === EnumActions.detail) {
      this.handleDetail(id);
    } else if (command === EnumActions.edit) {
      this.handleEdit(id);
    }
  }

  @Watch("temp.frequency_type")
  onFrequencyTypeChange(now, old) {
    if (now !== 4) {
      this.temp.days = undefined;
    }
  }
}
