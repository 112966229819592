var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tax-rate-config-container" },
    [
      _c(
        "el-row",
        { staticClass: "header-container" },
        [
          _c("el-col", { attrs: { span: 2, offset: 1 } }, [
            _vm._v(" 系统状态： ")
          ]),
          _c(
            "el-col",
            {
              class: _vm.invoiceActive ? "active" : "unactive",
              attrs: { span: 2 }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.invoiceActive ? "运行中" : "已关闭") + " "
              )
            ]
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: _vm.invoiceActive ? "danger" : "primary"
                  },
                  on: { click: _vm.handleActiveAndClose }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.invoiceActive ? "关闭" : "开启") + " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "pt-4" },
        [
          _c("bsm-table", {
            attrs: {
              data: _vm.list,
              options: _vm.tableListOptions,
              "list-loading": _vm.listLoading,
              "show-index": true
            },
            on: { actionCommand: _vm.handleActionCommand }
          })
        ],
        1
      ),
      _c(
        "section",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.showEdit,
                title: "税种：" + _vm.temp.tax_type_alias,
                width: "500px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.showEdit = $event
                },
                close: _vm.handleClose
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { rules: _vm.rules, model: _vm.temp }
                    },
                    [
                      _c("el-form-item", { attrs: { label: "实际税率" } }, [
                        _vm._v(" " + _vm._s(_vm.temp.actual_rate) + "% ")
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "rate", label: "税率" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "number",
                              clearable: "",
                              controls: false,
                              step: 0.0001,
                              precision: 4,
                              min: 0
                            },
                            model: {
                              value: _vm.temp.rate,
                              callback: function($$v) {
                                _vm.$set(_vm.temp, "rate", $$v)
                              },
                              expression: "temp.rate"
                            }
                          }),
                          _c("span", { staticClass: "suff-span" }, [
                            _vm._v("%")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "reduction", label: "普惠减免" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              step: 0.000001,
                              controls: false,
                              precision: 6,
                              min: 0
                            },
                            model: {
                              value: _vm.temp.reduction,
                              callback: function($$v) {
                                _vm.$set(_vm.temp, "reduction", $$v)
                              },
                              expression: "temp.reduction"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "threshold", label: "起征点" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              controls: false,
                              step: 1,
                              "step-strictly": "",
                              min: 0
                            },
                            model: {
                              value: _vm.temp.threshold,
                              callback: function($$v) {
                                _vm.$set(_vm.temp, "threshold", $$v)
                              },
                              expression: "temp.threshold"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "threshold_period",
                            label: "起征计算周期"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.temp.threshold_period,
                                callback: function($$v) {
                                  _vm.$set(_vm.temp, "threshold_period", $$v)
                                },
                                expression: "temp.threshold_period"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 1, label: "次" }
                              }),
                              _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      !_vm.temp ||
                                      _vm.temp.tax_type_alias !== "增值税",
                                    expression:
                                      "!temp || temp.tax_type_alias !== '增值税'"
                                  }
                                ],
                                attrs: { value: 2, label: "自然月" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "pt-5 x-center" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            type: "primary",
                            loading: _vm.submitLoading
                          },
                          on: { click: _vm.handleSubmit }
                        },
                        [_vm._v(" 确定 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }