import { RouteConfig } from "vue-router";
import Layout from "@/layout/index.vue";

const taxRoutes: RouteConfig = {
  path: "/tax",
  component: Layout,
  name: "Tax",
  meta: {
    title: "税务管理",
    icon: "tax",
    alwaysShow: true,
    roles: ["invoice_manage"]
  },
  children: [
    {
      path: "invoice_apply_confirm",
      name: "InvoiceApplyConfirm",
      component: () => import("@/views/tax/invoice-apply-confirm/index.vue"),
      meta: {
        title: "开票金额确认",
        roles: ["tax_push_confirm"]
      }
    },
    {
      path: "carrier",
      name: "TaxPushCarrier",
      component: () => import("@/views/tax/carrier/index.vue"),
      meta: {
        title: "开票申请查询", // 原承运人推送
        roles: ["invoice_apply_query"]
      }
    },
    {
      path: "invoice_history",
      name: "InvoiceHistory",
      component: () => import("@/views/tax/invoice-history/index.vue"),
      meta: {
        title: "开票历史记录",
        roles: ["invoice_history"]
      }
    },
    {
      path: "invoiced",
      name: "TaxInvoiced",
      component: () => import("@/views/tax/invoiced/index.vue"),
      meta: {
        title: "已开发票查询",
        roles: ["invoice_query"]
        // noCache: true
      }
    },
    {
      path: "invoice_query",
      name: "invoiceQuery",
      component: () => import("@/views/tax/invoice-query/index.vue"),
      meta: {
        title: "托运人开票查询",
        roles: ["consignor_order"]
      }
    },
    {
      path: "tax_rate",
      name: "TaxRate",
      component: () => import("@/views/system/taxrate/index.vue"),
      meta: {
        title: "税务服务配置",
        roles: ["tax_rate_config_list"]
      }
    },
    {
      path: "tax_auth",
      name: "TaxAuth",
      component: () => import("@/views/system/tax-auth/index.vue"),
      meta: {
        title: "税务信息授权",
        roles: ["tax_rate_config_list"]
      }
    }
  ]
};

export default taxRoutes;
