


















import { Vue, Component, Prop } from 'vue-property-decorator'

interface IItem {
  label: string
  value: string | number | boolean
}

interface IConfig {
  list?: IItem[]
  multiple?: boolean
}

@Component
export default class MapSelect extends Vue {
  @Prop({ default: 200 }) width: number
  @Prop({
    default: function() {
      return {
        list: [],
        multiple: false
      }
    }
  }) config: IConfig

  private get renderList() {
    if (this.config && this.config.list) {
      return this.config.list
    } else {
      return []
    }
  }

  private get multiple() {
    return !!this.config.multiple
  }
}
