import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const serviceApplyRoutes: RouteConfig = {
  path: '/service-apply',
  component: Layout,
  name: 'ServiceApply',
  meta: {
    title: '服务申请管理',
    icon: 'billing',
    alwaysShow: true,
    roles: ['serve_apply_manage']
  },
  children: [{
    path: 'list',
    name: 'ServiceApplyList',
    component: () => import(/* webpackChunkName: "login" */ '@/views/service-apply/index.vue'),
    meta: {
      title: '申请列表',
      roles: ['serve_apply_list']
    }
  }]
}

export default serviceApplyRoutes
