


























import { Component, Vue } from 'vue-property-decorator'
import { IWaybillData, IGetListParams } from '@/api/types'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
// import { SelectType } from '@/views/components/TypeMap/map/MapStatusSelect.vue'
// import { forcePassInvoiceApply, recheckAntiFraud } from '@/api/tax'

import { getWaybillList } from '@/api/waybill'
import {
  WaybillTypeMap,
  WaybillStatus,
  WaybillNewPayStatus,
  WaybillInvoiceStatus,
  WaybillInvoiceRiskStatus,
  WaybillMarkMap,
  riskScoreTypes
} from '@/common/constant'
import { getEnumArray } from '@/common/utils'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'

@Component({
  name: 'Waybill',
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  list: IWaybillData[] = []
  total = 0
  listLoading = true
  listQuery: IGetListParams = {
    waybillIds: '',
    waybillCreateTime: '',
    page: {
      index: 1,
      limit: 10
    }
  }

  get computeListQuery() {
    const query: any = Object.assign({}, this.listQuery)
    return query
  }

  queryFilterOptions = [{
    field: 'agentDid',
    label: '网络货运企业',
    component: MapSearchSelect,
    config: {
      field: 'agent'
    },
    main: true
  }, {
    field: 'waybillNo',
    label: '运单号码',
    main: true
  },
  {
    field: 'waybillStatus',
    label: '运单状态',
    type: 'select',
    config: {
      list: getEnumArray(WaybillStatus)
    },
    main: true
  }, {
    field: 'sourceWaybillNo',
    label: '货源单号'
  }, {
    field: ['waybillCreateTimeStart', 'waybillCreateTimeEnd'], // 'waybillCreateTime',
    label: '起止时间',
    type: 'dateRange'
  }, {
    field: ['createTimeStart', 'createTimeEnd'], // 'waybillCreateTime',
    label: '上报时间',
    type: 'dateRange'
  }, {
    field: 'supplyStatus',
    label: '是否有补充信息',
    type: 'select',
    config: {
      list: [{ value: 0, label: '否' }, { value: 1, label: '是' }]
    }
  }, {
    field: 'consignorPayStatus',
    label: '托运人支付状态',
    type: 'select',
    config: {
      list: getEnumArray(WaybillNewPayStatus)
    }
  }, {
    field: 'carrierPayStatus',
    label: '承运人支付状态',
    type: 'select',
    config: {
      list: getEnumArray(WaybillNewPayStatus)
    }
  }, {
    field: 'invoiceStatus',
    label: '开票状态',
    type: 'select',
    config: {
      list: getEnumArray(WaybillInvoiceStatus)
    }
  }, {
    field: 'riskGrades',
    label: '风险评估状态',
    type: 'select',
    config: {
      list: getEnumArray(WaybillInvoiceRiskStatus)
    }
  }, {
    field: 'businessMode',
    label: '运单类型',
    type: 'select',
    config: {
      list: getEnumArray(WaybillTypeMap)
    }
  }, {
    field: 'consignorDid',
    label: '托运人名称或证件号码',
    component: MapSearchSelect,
    config: {
      field: 'consignor'
    }
  }, {
    field: 'carrierDid',
    label: '承运人名称或证件号码',
    component: MapSearchSelect,
    config: {
      field: 'carrier'
    }
  }, {
    field: 'driverDid',
    label: '司机名称或证件号码',
    component: MapSearchSelect,
    config: {
      field: 'driver'
    }
  }, {
    field: 'vehicle',
    label: '车辆牌照号码',
    component: MapSearchSelect,
    config: {
      field: 'vehicle'
    }
  }, {
    field: 'deleted',
    label: '运单标记',
    type: 'select',
    config: {
      list: getEnumArray(WaybillMarkMap)
    }
  }, {
    field: 'riskScoreTypes',
    label: '评分等级',
    type: 'select',
    config: {
      list: getEnumArray(riskScoreTypes),
      multiple: true
    }
  }, {
    field: ['riskScoreLeft', 'riskScoreRight'],
    label: '评分分值',
    type: 'range'
  }]

  tableListOptions = [{
    field: 'id',
    type: 'link',
    label: '操作',
    config: {
      baseUrl: '/dataquery/waybill',
      label: '查看详情'
    },
    fixed: true
  },
  {
    field: 'waybill_no',
    label: '运单号码',
    width: 200,
    fixed: true
  },
  {
    field: 'risk_grade_alias',
    label: '风险评估状态',
    width: 150,
    align: 'center'
  },
  {
    field: 'business_mode_alias',
    label: '运单类型',
    width: 100
  },
  {
    field: 'agent',
    label: '网络货运企业',
    type: 'link',
    config: {
      url(val, row) {
        return `/dataquery/agent/${row.agent_did}`
      }
    }
  },
  {
    field: 'risk_score',
    label: '评分',
    align: 'center',
    filter: 'riskScore'
  },
  {
    field: 'risk_score_msg',
    label: '等级',
    align: 'center'
  },
  {
    field: 'source_waybill_no',
    label: '货源单号',
    width: 180
  },

  {
    field: 'consignor',
    label: '托运人',
    type: 'link',
    config: {
      url(val, row) {
        return `/dataquery/consignor/${row.consignor_did}`
      }
    }
  },
  {
    field: 'load_district',
    label: '发货地行政区划'
  },
  {
    field: 'receiver_district',
    label: '收货人行政区划'
  },
  {
    field: 'sender_name',
    label: '发货人名称',
    width: 200
  },
  {
    field: 'receiver_name',
    label: '收货人名称',
    width: 200
  },
  {
    field: 'carriers',
    label: '承运人',
    type: 'link',
    config: {
      url(val, row) {
        return `/dataquery/carrier/${row.carrierDids}`
      }
    }
  }, {
    field: 'drivers',
    label: '司机',
    type: 'link',
    config: {
      url(val, row) {
        return `/dataquery/driver/${row.driverDids}`
      }
    }
  }, {
    field: 'vehicles',
    label: '车辆',
    type: 'link',
    config: {
      url(val, row) {
        return `/dataquery/vehicle/${val}`
      }
    }
  }, {
    field: 'customer_ship_fee',
    label: '总运费(元)',
    width: 150,
    align: 'center',
    filter: 'amountFormatFilter'
  }, {
    field: 'driver_ship_fee',
    label: '承运人运费(元)',
    width: 150,
    align: 'center',
    filter: 'amountFormatFilter'
  }, {
    field: 'waybill_status_alias',
    label: '运单状态',
    align: 'centerr'
  }, {
    field: 'consignor_pay_status_alias',
    label: '托运人支付状态',
    align: 'center'
  }, {
    field: 'carrier_pay_status_alias',
    label: '承运人支付状态',
    align: 'center'
  }, {
    field: 'invoice_status_alias',
    label: '开票状态',
    align: 'center'
  }, {
    field: 'waybill_create_time',
    label: '开单时间',
    filter: 'timeFilterShowSecond',
    width: 180,
    align: 'center'
  },
  {
    field: 'receive_time',
    label: '完成时间',
    filter: 'timeFilterShowSecond',
    align: 'center',
    width: 180
  }, {
    field: 'create_time',
    label: '上报时间',
    filter: 'timeFilterShowSecond',
    align: 'center',
    width: 180
  },
  {
    field: 'supply_status',
    label: '是否有补充信息',
    filter: 'bool',
    align: 'center',
    width: 180
  },
  {
    field: 'deleted_alias',
    label: '运单标记',
    width: 180,
    align: 'center'
  }
  ]

  created() {
    this.initListQuery()
    this.getList()
  }

  onShow() {
    console.log(11110)
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
    this.listQuery.invoiceApplyNo = ((this.$route.query && this.$route.query.invoiceApplyNo) || '').toString() || ''
  }

  async getList() {
    this.listLoading = true
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { riskScore, ...listQuery } = this.computeListQuery
    if (riskScore) {
      listQuery.riskScoreLeft = riskScore[0] || ''
      listQuery.riskScoreRight = riskScore[1] || ''
    }
    const { data } = await getWaybillList(listQuery)
    this.list = data.items
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }

  handleFilter() {
    this.listQuery.page.index = 1
    this.getList()
  }

  handelActionDetail({ id }) {
    this.$router.push({
      path: `consignor/${id}`
    })
  }
}

