








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: 0 }) value!: number
  @Prop({ default: 0 }) type!: number

  private colors = ['#fa4c40', '#fa4c40', '#c6d531', '#44d13d', '#2ac58f']
  private get showColor() {
    return this.colors[this.type]
  }
}
