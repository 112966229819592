


































































import { Vue, Component } from 'vue-property-decorator'

import { LogIconsMap } from '@/common/constant'
// import { uploadMultiFiles } from '@/common/utils'

import WaybillRiskOperation from '@/views/components/WaybillDetail/WaybillRiskOperation.vue'
import WaybillRiskLog from '@/views/components/WaybillDetail/WaybillRiskLog.vue'
import { getSubWaybillDetail } from '@/api/subWaybill'
import { getSubWaybillLog } from '@/api/log'
// import { replenish } from '@/api/waybill'

import {
  WaybillBase,
  WaybillGoods,
  WaybillTransport,
  WaybillPayment,
  WaybillInvoice,
  EtcInvoice,
  WaybillLog,
  WaybillRightPanel,
  WaybillAddition
} from '@/common/business-components'

import {
  ISubWaybillDetailData
} from '@/common/business-components/index.d'

@Component({
  name: 'SubWaybillDetail',
  components: {
    WaybillRightPanel,
    WaybillBase,
    WaybillGoods,
    WaybillTransport,
    WaybillPayment,
    WaybillInvoice,
    EtcInvoice,
    WaybillLog,
    WaybillRiskOperation,
    WaybillRiskLog,
    WaybillAddition
  }
})
export default class extends Vue {
  logIconsMap = LogIconsMap
  logData = []

  filePdfPath = ''
  hasContract = false
  loading = false

  data() {
    return {
      waybillBaseInfo: undefined,
      antiFraudWaybillInfo: undefined,
      waybillGoodsInfo: undefined,
      waybillTransportInfo: undefined,
      waybillPaymentInfo: undefined,
      waybillInvoiceInfo: undefined,
      etcInvoiceInfo: undefined,
      waybillLogInfo: undefined,
      waybillAdditionInfo: undefined
    }
  }

  created() {
    this.getDetail()
  }

  async getDetail() {
    try {
      this.loading = true
      const { data } = await getSubWaybillDetail({
        id: this.$route.params.id as string
      })
      const logRes = await getSubWaybillLog({
        sub_waybill_id: this.$route.params.id as string,
        waybill_id: data.waybill_dto.id
      })
      data.waybill_log_info = logRes.data
      this.setData(data)
    } finally {
      this.loading = false
    }
  }

  setData(data) {
    this.subWaybillData = data
    this.$data.waybillBaseInfo = Object.assign({}, data.waybill_dto, {
      agent_did: data.agent_did,
      carrier_did: data.carrier_did,
      consignor_did: data.consignor_did,
      deleted: data.deleted
    })
    this.$data.antiFraudWaybillInfo = data.anti_fraud_statistics_dto
    this.$data.antiFraudWaybillInfo.antiFraudWaybillDto.riskGrade = data.waybill_dto.riskGrade
    this.$data.antiFraudWaybillInfo.antiFraudWaybillDto.riskGradeDesc = data.waybill_dto.riskGradeAlias
    this.$data.waybillGoodsInfo = data.waybill_goods_statistics_dto

    this.logData = data.waybill_log_dto_list || []

    this.$data.waybillLogInfo = data.waybill_log_info
    this.$data.waybillTransportInfo = {
      id: data.id,
      waybillStatusAlias: data.waybill_dto.waybillStatusAlias,
      forecastTransportKm: data.waybill_dto.forecastTransportKm,
      transportKm: data.waybill_dto.transportKm,
      trajectoryNum: data.waybill_dto.trajectoryNum,
      vehicleCount: data.waybill_dto.vehicleCount,
      customerShipFee: data.waybill_dto.customerShipFee,
      sendDistrictCode: data.amap_load_district_code,
      receiveDistrictCode: data.amap_receiver_district_code,
      sendTime: data.waybill_dto.sendTime,
      receiveTime: data.waybill_dto.receiveTime,
      subWaybillDtoList: [{
        subWaybillNo: data.sub_waybill_no,
        subWaybillStatusAlias: data.sub_waybill_status_alias,
        vehicle: data.vehicle,
        driver: data.driver,
        driverDid: data.driver_did,
        driverPhone: data.driver_phone,
        loadTime: data.load_time,
        loadWeight: data.load_weight,
        receiveWeight: data.receive_weight,
        receiveTime: data.receive_time,
        driverShipFee: data.driver_ship_fee,
        carrier: data.carrier,
        carrierDid: data.carrier_did,
        contractNumber: data.contract_number,
        filePdfPath: data.file_pdf_path
      }]
    }

    this.$data.waybillPaymentInfo = Object.assign({
      // payStatusAlias: data.waybill_dto.payStatusAlias,
      consignorPayStatusAlias: data.waybill_dto.consignorPayStatusAlias,
      carrierPayStatusAlias: data.waybill_dto.carrierPayStatusAlias,
      customerShipFee: data.waybill_dto.customerShipFee,
      driverShipFee: data.waybill_dto.driverShipFee,
      consignorActualAmount: data.waybill_dto.consignorActualAmount,
      carrierActualAmount: data.waybill_dto.carrierActualAmount,
      informationDetailDto: data.information_detail_dto
    }, data.payment_detail_statistics_dto)

    this.$data.waybillInvoiceInfo = Object.assign({
      invoiceStatusAlias: data.waybill_dto.invoiceStatusAlias
    }, data.invoice_statistics_dto)

    this.$data.waybillAdditionInfo = data.additional_dto_list
    this.$data.etcInvoiceInfo = data.etc_detail_dto_list

    this.contractPaths = data.contract_paths
    this.filePdfPath = data.filePdfPath
    this.hasContract = data.hasContract
  }

  contractPaths = []
  subWaybillData: ISubWaybillDetailData = {
    agent: '',
    carrier: '',
    consignor: '',
    customer_ship_fee: 0,
    delivery_address: '',
    delivery_city: '',
    delivery_district: '',
    delivery_province: '',
    driver: '',
    driver_phone: '',
    driver_ship_fee: 0,
    id: '',
    insurance_company: '',
    insurance_no: '',
    invoice_risk_status: 0,
    invoice_status: 0,
    load_time: 0,
    pay_status: 0,
    receive_time: 0,
    shipping_address: '',
    shipping_city: '',
    shipping_district: '',
    shipping_province: '',
    sub_waybill_no: '',
    sub_waybill_status: 0,
    vehicle: '',
    file_pdf_path: '',
    contract_number: '',
    waybill_dto: null,
    waybill_log_dto_list: [], // IWaybillLogDTO
    waybill_goods_statistics_dto: null,
    payment_detail_statistics_dto: null,
    invoice_statistics_dto: null,
    anti_fraud_statistics_dto: null

  }

  handleTrack(query: any) {
    console.log(query, 111)
    this.$router.push({
      path: '/dataquery/track',
      query: query
    })
  }

  handleShowContract() {
    this.$showImgList([this.$data.waybillBaseInfo.filePdfPath])
  }
}

