var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-date-picker", {
    staticStyle: { width: "200px" },
    attrs: {
      type: "daterange",
      "range-separator": "-",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "default-time": ["00:00:00", "23:59:59"],
      format: "yyyy.M.d",
      size: "small"
    },
    model: {
      value: _vm.time,
      callback: function($$v) {
        _vm.time = $$v
      },
      expression: "time"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }