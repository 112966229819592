









































































import Draggable from 'vuedraggable'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'DraggableColumnSetting',
  components: {
    Draggable
  }
})
export default class extends Vue {
  @Prop({ default: () => [] }) private list1!: string[]
  @Prop({ default: () => [] }) private list2!: string[]
  @Prop({ default: () => [] }) private disabledList: string[]
  @Prop({ default: 'list1' }) private list1Title!: string
  @Prop({ default: 'list2' }) private list2Title!: string
  @Prop({ default: '48%' }) private list1width!: string
  @Prop({ default: '48%' }) private list2width!: string


  private get renderList1() {
    return this.list1.sort((a, b) => {
      const fa = this.disabledList.find(item => item === a)
      const fb = this.disabledList.find(item => item === b)
      if (!fa && fb) {
        return 1
      } else if (fa && !fb) {
        return -1
      } else {
        return 0
      }
    })
  }

  private isNotInList1(v: string) {
    return this.list1.every(k => v !== k)
  }

  private isNotInList2(v: string) {
    return this.list2.every(k => v !== k)
  }

  private deleteEle(ele: string) {
    if (this.isDisabled(ele)) {
      return
    }

    for (const item of this.list1) {
      if (item === ele) {
        const index = this.list1.indexOf(item)
        this.list1.splice(index, 1)
        break
      }
    }
    if (this.isNotInList2(ele)) {
      this.list2.unshift(ele)
    }
  }

  private pushEle(ele: string) {
    if (this.isDisabled(ele)) {
      return
    }
    for (const item of this.list2) {
      if (item === ele) {
        const index = this.list2.indexOf(item)
        this.list2.splice(index, 1)
        break
      }
    }
    if (this.isNotInList1(ele)) {
      this.list1.push(ele)
    }
  }

  private isDisabled(element: string) {
    return this.disabledList.indexOf(element) !== -1
  }

  private handleMove(evt) {
    return !this.isDisabled(evt.draggedContext.element)
  }
}

