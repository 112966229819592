// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.carrier-detail-container .success {\n  color: #17BB89 !important;\n  cursor: pointer;\n  pointer-events: all;\n}\n.carrier-detail-container .danger {\n  color: #F5222D !important;\n  pointer-events: all;\n  cursor: pointer;\n}\n.carrier-detail-container .warning {\n  color: #FAAD14 !important;\n  pointer-events: none;\n  cursor: no-drop;\n}\n.home-card {\n  background-color: #fff;\n  border-radius: 4px;\n  border: 0 solid #eeeeee;\n  padding: 8px;\n  -webkit-transition-duration: 0.3s;\n          transition-duration: 0.3s;\n}\n.home-card .el-card {\n    border: none;\n}\n.home-card .el-card.is-hover-shadow:hover {\n    -webkit-box-shadow: none;\n            box-shadow: none;\n}\n.home-card .title-box {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-pack: justify;\n        -ms-flex-pack: justify;\n            justify-content: space-between;\n    padding: 16px;\n    border-bottom: 1px solid #ebebeb;\n}\n.home-card .title-box .title {\n      font-size: 16px;\n      font-weight: 700;\n      color: #333;\n}\n.home-card .title-box .more {\n      font-size: 14px;\n      font-weight: 400;\n      color: #979797;\n      cursor: pointer;\n}\n.home-card .list-box {\n    border: none;\n    padding: 0 16px;\n    margin: 0;\n    min-height: 172px;\n}\n.home-card:hover {\n  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);\n          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
