var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    _vm._l(_vm.options, function(option) {
      return _c(
        "el-button",
        {
          key: option.action,
          staticClass: "pr-1",
          attrs: {
            type: option.type || "primary",
            size: option.size || "mini"
          },
          on: {
            click: function($event) {
              return _vm.handleClick(option.action)
            }
          }
        },
        [_vm._v(_vm._s(option.label))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }