






































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import TableList from '../TableList/index.vue'
import {
  IWaybillTransportData
} from '../index.d'

const transportInfoDefault: IWaybillTransportData = {
  id: '',
  waybillStatusAlias: '',
  vehicleCount: 0,
  customerShipFee: 0,
  subWaybillDtoList: [],
  sendDistrictCode: '',
  receiveDistrictCode: '',
  sendTime: 0,
  receiveTime: 0
}

@Component({
  components: {
    TableList
  }
})
export default class extends Vue {
[x: string]: any;
  @Prop({ default: () => transportInfoDefault }) waybillTransportInfo;
  @Prop({ default: 'waybill' }) type: string

  isShipperClient = window.location.host.indexOf('shipper') > -1

  subWaybillDtoListOptions = [{
    field: 'subWaybillNo',
    label: '分段分单号',
    width: 180
  }, {
    field: 'subWaybillStatusAlias',
    label: '运输状态'
  },
  {
    field: 'vehicle',
    label: '车牌号',
    type: 'link',
    config: {
      url(val, row) {
        return `/dataquery/vehicle/${val}`
      }
    }
  },
  {
    field: 'driver',
    label: '司机',
    type: 'link',
    config: {
      url(val, row) {
        return `/dataquery/driver/${row.driverDid}`
      }
    }
  },
  {
    field: 'driverPhone',
    label: '联系电话',
    width: 150
  },
  {
    field: 'loadTime',
    label: '起运时间',
    filter: 'timeFilterShowSecond'
  },
  {
    field: 'loadWeight',
    label: '装货重量(千克)',
    filter: 'weight'

  },
  {
    field: 'receiveWeight',
    label: '卸货重量(千克)',
    filter: 'weight'

  },
  // {
  // field: 'insuranceCompany',
  // label: '保险公司'
  // }, {
  // field: 'insuranceNo',
  // label: '保险单号'
  // },
  {
    field: 'receiveTime',
    label: '卸货时间',
    filter: 'timeFilterShowSecond'
  },
  {
    field: 'driverShipFee',
    label: '运费(元)',
    filter: 'amountFormatFilter'
  },
  {
    field: 'carrier',
    label: '承运人',
    type: 'link',
    config: {
      url(val, row) {
        return `/dataquery/carrier/${row.carrierDid}`
      }
    }
  }, {
    field: 'contractNumber',
    label: '实际承运合同',
    type: 'link',
    config: {
      url: (url, row) => {
        return this.isShipperClient ? '' : row.filePdfPath
      },
      label: (l) => {
        return l
      }
    }
  }
  ]

  @Emit('onTrack')
  handleClickTrack() {
    const query: any = {
      sendDistrict: this.waybillTransportInfo.sendDistrictCode,
      receiveDistrict: this.waybillTransportInfo.receiveDistrictCode,
      sendTime: this.$options.filters.timeFilter(this.waybillTransportInfo.sendTime),
      receiveTime: this.$options.filters.timeFilter(this.waybillTransportInfo.receiveTime)
    }
    if (this.type === 'waybill') {
      query.waybill_id = this.waybillTransportInfo.id
    } else {
      query.sub_waybill_id = this.waybillTransportInfo.id
    }
    if (this.isShipperClient) {
      query.token = this.$route.query.token
      query.destination = this.$route.query.destination
    }
    return query
  }
}

