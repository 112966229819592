







import { Scene } from '@antv/l7'
import { CountryLayer } from '@antv/l7-district'
import { Mapbox } from '@antv/l7-maps'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

const mapType = {
  normal: {
    zoom: 2.4,
    center: [104.2825, 31.9]
  },
  small: {
    zoom: 1.1,
    center: [104.2825, 31.9]
  }
}
// #4F7DF9

@Component({
  name: 'FilledMap'
})
export default class extends Vue {
  @Prop({ default: 'filled-map' }) private className!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: '100%' }) private height!: string
  @Prop({ type: Array, required: true }) public data: any[];
  @Prop({ default: 'name' }) private xField: string
  @Prop({ default: 'value' }) private yField: string
  @Prop({ default: 'normal' }) private size!: 'normal' | 'small'
  @Prop() private step: number
  @Prop() private max: number
  @Prop() private gradient: string[]

  private scene: Scene
  // private max: number = 100
  private min = 0
  // private step: number = 8
  private interval: number

  private countryLayer: CountryLayer

  @Watch('gradient')
  onGradientChange() {
    this.updateMap(this.data)
  }

  @Watch('max')
  onMaxChange(value: number) {
    this.interval = value / this.step
  }

  @Watch('step')
  onStepChange(value: number) {
    this.interval = this.max / value
  }

  @Watch('data')
  onDataChange(data: any[], oldData: any[]) {
    if (oldData.length === 0 && data.length === 0) {
      return
    }
    this.updateMap(data)
  }

  // TODO:BUG may exist which is related to network speed
  // init chart when data first changed(chart is not init when data not change)
  private updateMap(data) {
    if (!data) {
      return
    }
    this.$nextTick(() => {
      if (this.scene && this.countryLayer && this.countryLayer.fillLayer) {
        this.countryLayer.updateData(data)
      } else {
        this.initChart()
      }
    })
  }

  created() {
    // this.genGradient()
  }

  mounted() {
    // this.$nextTick(() => {
    //   this.initChart()
    // })
  }

  beforeDestroy() {
    if (!this.scene) {
      return
    }
    this.scene.destroy()
    this.scene = null
  }

  private initChart() {
    const config = mapType[this.size]
    this.scene = new Scene({
      id: this.$refs.map as HTMLDivElement,
      map: new Mapbox({
        center: [104.2825, 31.9],
        pitch: 0,
        style: 'blank',
        zoom: config.zoom,
        minZoom: config.zoom,
        maxZoom: config.zoom,
        rotateEnable: false
      }),
      logoVisible: false
    })
    this.scene.on('loaded', () => {
      this.countryLayer = new CountryLayer(this.scene, {
        data: this.data,
        joinBy: ['NAME_CHN', this.xField],
        autoFit: true,
        depth: 1,
        label: {
          enable: false
        },
        chinaNationalStroke: '#999999',
        chinaNationalWidth: 1,
        provinceStroke: '#999999',
        cityStroke: '#EBCCB4',
        cityStrokeWidth: 1,
        fill: {
          color: {
            field: [this.yField],
            values: (value) => {
              // exclude undefined value
              if (value === undefined) {
                return this.gradient[0]
              }
              const index = Math.ceil(value / this.interval)
              // NaN colored #fff
              if (isNaN(index)) {
                return this.gradient[0]
              }
              // Max value colored max color
              if (value === this.max) {
                return this.gradient[this.gradient.length - 1]
              }
              // Zero value colored min value
              if (value === 0) {
                return this.gradient[0]
              }
              return this.gradient[index]
            }
          }
        },

        popup: {
          enable: true,
          Html: props => {
            return `<span>${props.NAME_CHN}:${props.value || 0}</span>`
          }
        }
      })
    })
  }
}

