



















































import { Component, Vue } from 'vue-property-decorator'
import { employedApplyPageApi, employedInvoiceCallbackApi, employedReportParamApi } from '@/api/system'
import { IPushQueueData } from '@/api/types'

import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { IndividualInvoiceStatusDesc } from '@/common/constant'
import { getEnumArray } from '@/common/utils'

@Component({
  name: 'invoiceApply',
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  dialogVisible = false;
  currentRow:any = {}
  list: IPushQueueData[] = [];
  total = 0;
  listLoading = true;
  failedLogs: any = '{}';
  listQuery = {
    status: null,
    batch_no: null,
    page: {
      index: 1,
      limit: 10
    }
  };

  queryFilterOptions = [
    // {
    //   field: 'agent_did',
    //   label: '网络货运企业',
    //   component: MapSearchSelect,
    //   config: {
    //     field: 'agent'
    //   },
    //   main: true
    // },
    {
      field: 'agent_key',
      label: '网络货运企业',
      main: true
    },
    {
      field: 'self_key',
      label: '收款人',
      main: true
    },
    {
      field: ['batch_create_start', 'batch_create_end'],
      label: '申请时间',
      type: 'dateRange',
      main: true
    },
    {
      field: ['batch_finish_start', 'batch_finish_end'], // 'invoiceTime',
      label: '完成时间',
      type: 'dateRange',
      main: true
    },
    {
      field: 'order_status',
      label: '开票状态',
      type: 'select',
      config: {
        list: getEnumArray(IndividualInvoiceStatusDesc)
      },
      main: true
    },
    {
      field: 'apply_no',
      label: '开票凭证号',
      main: true
    },
    {
      field: 'report_order_no',
      label: '推送订单号',
      main: true
    },
    {
      field: 'batch_no',
      label: '开票订单号',
      main: true
    }
  ];

  tableListOptions = [
    {
      field: 'id',
      type: 'actionSetting',
      attrs: {
        actionOptions: [
          {
            label: '回调开票结果',
            type: 'confirmCallback'
          }
        ]
      },
      label: '操作',
      width: 50,
      align: 'center',
      fixed: true
    },
    {
      field: 'apply_no',
      label: '开票凭证号',
      fixed: true
    },
    {
      field: 'agent_name',
      label: '网络货运企业'
    },
    {
      field: 'agent_social_credit_code',
      label: '网络货运企业税号'
    },
    {
      field: 'self_employed_name',
      label: '收款人名称',
      width: 180
    },
    {
      field: 'self_employed_tax_no',
      label: '收款人证件号',
      width: 180
    },
    {
      field: 'pay_amount',
      label: '收款金额（元）',
      filter: 'amountFormatFilter'
    },
    {
      field: 'pay_finish_time',
      label: '收款时间',
      filter: 'timeFilterShowSecond'
    },
    {
      field: 'invoice_category_desc',
      label: '申请发票类型'
    },
    {
      field: 'status_desc',
      label: '开票状态'
    },
    {
      field: 'report_order_no',
      label: '推送订单号',
      type: 'linkLike',
      config: {
        label: (val, row) => {
          return val || '<span style="color:#686868;cursor:default;">-</span>'
        },
        handle: (val, row) => {
          val && this.viewPop(row)
        }
      }
    },
    {
      field: 'batch_no',
      label: '开票订单号',
      type: 'linkLike',
      config: {
        label: val => {
          return val
        },
        handle: (val) => {
          const query:any = { tabStatus: 2, batch_no: val }
          this.$router.push({
            path: '/manage/individual-invoice',
            query: query
          })
        }
      }
    },
    {
      field: 'waybill_cnt',
      label: '关联运单数',
      type: 'linkLike',
      config: {
        label: val => {
          return val
        },
        handle: (val, row) => {
          this.viewWaybill(row)
        }
      }
    },
    {
      field: 'batch_create_time',
      label: '申请时间',
      filter: 'timeFilterShowSecond'
    },
    {
      field: 'batch_finish_time',
      label: '完成时间',
      filter: 'timeFilterShowSecond',
      width: 100,
      align: 'center'
    }
  ];

  created() {
    if (!this.$route.query.batch_no && !this.$route.query.self_key) {
      this.initListQuery()
      this.getList()
    }
  }

  activated() {
    if (this.$route.query.batch_no || this.$route.query.self_key) {
      this.initListQuery()
      this.getList()
    }
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
  }

  viewWaybill(row) {
    let path = ''
    const query: any = {}
    query.waybillIds = row.waybill_ids.join(',')
    path = '/dataquery/waybill'
    this.$router.push({
      path: path,
      query: query
    })
  }

  async viewPop(row) {
    const { data } = await employedReportParamApi({
      apply_no: row.apply_no
    })
    if (data) {
      this.failedLogs = data
      this.dialogVisible = true
    } else {
      this.$message.error('暂无记录')
    }
  }


  async getList() {
    this.listLoading = true
    const listQuery = JSON.parse(JSON.stringify(this.listQuery))
    const { data } = await employedApplyPageApi(listQuery)
    this.list = data.items
    this.total = data.total
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  handleFilter() {
    this.listQuery.page.index = 1
    this.getList()
  }



  handleActionCommand(command, id, row) {
    if (command === 'confirmCallback') {
      this.$confirm('此操作将回调开票, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          employedInvoiceCallbackApi({
            batch_no: row.batch_no
          }).then(() => {
            this.$message.success('回调开票操作成功')
            this.getList()
          })
        })
        .catch(e => {
          console.log(e)
        })
    } else if (command === 'viewInvoiceAddress') {
      let html = `<p style="padding:16px;">蓝票地址：${row.invoice_path}</p>`
      if (row.status === 10) {
        html += `<p style="padding:16px;">红票地址：${row.red_invoice_path}</p>`
      }
      this.$alert(html, '发票地址', {
        dangerouslyUseHTMLString: true
      })
    }
  }
}
