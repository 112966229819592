



















































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import GaugeChart from '../../components/Charts/GaugeChart.vue'

interface IData {
  value: number
  statistic?: string | any
}

interface IMultiData {
  onLine: IData
  credit: IData
  activity: IData
}

@Component({
  components: {
    GaugeChart
  }
})
export default class extends Vue {
  @Prop({
    default() {
      return {
        onLine: {
          value: 0
        },
        credit: {
          value: 0
        },
        activity: {
          value: 0
        }
      }
    }
  }) data!: IMultiData

  @Prop({ default: '100%' }) height!: string
}
