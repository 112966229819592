

































































































































































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { RiskLevel, RiskLevelMap, RiskIcons, RiskRuleIcons } from '../../constant'
import { waybillAppealApplyApi, waybillAppealItemApi } from '@/api/waybill'
import { IAntiFraudStatisticsDTO } from '../index.d'
import ContainShowImg from '../../components/ContainShowImg/index.vue'
import GaugeChart from '../../components/Charts/GaugeChart.vue'
import TableList from '../TableList/index.vue'
import { getRiskScore } from '../../utils/business'
import { Form } from 'element-ui'
import { uploadMultiFiles } from '@/common/utils'
import MultiUploadFile from '@/common/business-components/MultiUploadFile/index.vue'

@Component({
  name: 'AntiFraud',
  components: {
    ContainShowImg,
    MultiUploadFile,
    GaugeChart,
    TableList
  }
})
export default class extends Vue {
  @Prop() data: IAntiFraudStatisticsDTO
  @Prop() waybillId:string
  @Prop() type:any

  RiskLevel = RiskLevel
  riskIcons = RiskIcons
  riskRuleIcons = RiskRuleIcons
  passedColor = RiskLevel[RiskLevelMap.Low].color
  deniedColor = RiskLevel[RiskLevelMap.High].color
  showDialog = false
  unPasslist = []
  dialogVisible = false
  appealVisible = false
  subLoading = false
  initFileList: any = []
  fileList: any = []
  waybillAppealItem:any = []
  currentHost = window.location.host

  formData = {
    explain: '',
    file_urls: ''
  }

  rules = {
    explain: [{
      required: true,
      message: '申诉说明必填',
      trigger: 'blur'
    }]
  }

  appealItemListOptions = [{
    field: 'ruleId',
    label: '规则编号'
  },
  {
    field: 'ruleName',
    label: '规则名称'
  }, {
    field: 'resultMsg',
    label: '异常原因'
  }]

  proofStatusMap = {
    0: '',
    1: '无需自证',
    2: '待自证',
    3: '已自证'
  }

  ruleOptions = [{
    field: 'id',
    label: '规则编号'
  }, {
    field: 'category',
    label: '规则类别'
  }, {
    field: 'name',
    label: '规则名称'
  }, {
    field: 'resultMsg',
    label: '规则判断详情'
  }, {
    field: 'hitParkName',
    label: '冲突产业园'
  }, {
    field: 'waybillNoList',
    label: '碰撞运单号',
    filter: (val) => {
      const v = val.length ? val.join(',') : ''
      return v
    }
  }]

  async waybillAppeal() {
    const res = await waybillAppealItemApi({ id: this.waybillId })
    this.waybillAppealItem = res.data || []
    this.initFileList = []
    this.appealVisible = true
  }

  handleFileListChange(files: any) {
    this.fileList = files
  }

  handleClick(antiFraudRuleDtoList = []) {
    this.showDialog = true
    this.unPasslist = antiFraudRuleDtoList
  }

  handleClickForcePassDetail() {
    this.dialogVisible = true
  }

  handleCloseConfig() {
    this.reSet()
    this.appealVisible = false
    this.subLoading = false
  }

  async handleSubmit() {
    try {
      const valid = await (this.$refs.form as Form).validate()
      if (!valid) {
        return
      }
      if (!this.fileList.length) {
        return this.$message.error('请至少上传一份申诉材料')
      }
      const urls = await uploadMultiFiles(this.fileList)
      await waybillAppealApplyApi({
        waybill_id: this.waybillId,
        file_urls: urls,
        explain: this.formData.explain
      })
      this.subLoading = false
      this.appealVisible = false
      this.$message.success('提交成功')
    } catch (err) {
      console.log(err)
      this.$message.error(err)
      this.subLoading = false
    }
  }

  getRiskScore = getRiskScore

  reSet() {
    (this.$refs.form as Form).resetFields()
    this.formData = {
      explain: '',
      file_urls: ''
    }
    this.fileList = []
  }

  // @Emit("riskUpdate")
  // handUpdate(val) {
  //   return val
  // }
}

