   function canvasDataURL(path: string, obj: any) {
     return new Promise((resolve, reject) => {
       var img = new Image();
       let imageType = path.split('.')[1]
       img.src = path;
       img.onload = function() {
         var that:any = this;
         // 默认按比例压缩
         var w = that.width,
           h = that.height,
           scale = w / h;
         if (w > obj.width) {
           w = obj.width || w;
           h = obj.height || (w / scale);
         }

         var quality = 0.9; // 默认图片质量为0.7
         //生成canvas
         var canvas = document.createElement('canvas');
         var ctx = canvas.getContext('2d');
         // 创建属性节点
         var anw = document.createAttribute("width");
         anw.nodeValue = w;
         var anh = document.createAttribute("height");
         anh.nodeValue = h;
         canvas.setAttributeNode(anw);
         canvas.setAttributeNode(anh);
         ctx.drawImage(that, 0, 0, w, h);
         // 图像质量
         if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
           quality = obj.quality;
         }
         // quality值越小，所绘制出的图像越模糊
         var base64 = canvas.toDataURL('image/'+imageType, quality);
         // 回调函数返回base64的值
         resolve(base64);
         //callback(base64);
       }
     })

   }

   /**
    * 将以base64的图片url数据转换为Blob
    * @param urlData
    *            用url方式表示的base64图片数据
    */
   export const convertBase64UrlToBlob = function(urlData: any) {
     var arr = urlData.split(','),
       mime = arr[0].match(/:(.*?);/)[1],
       bstr = atob(arr[1]),
       n = bstr.length,
       u8arr = new Uint8Array(n);
     while (n--) {
       u8arr[n] = bstr.charCodeAt(n);
     }
     return new Blob([u8arr], {
       type: mime
     });
   }
   export const convertBase64UrlToFile = function(urlData: any,fileName:string) {
     var arr = urlData.split(','),
       mime = arr[0].match(/:(.*?);/)[1],
       bstr = atob(arr[1]),
       n = bstr.length,
       u8arr = new Uint8Array(n);
     while (n--) {
       u8arr[n] = bstr.charCodeAt(n);
     }
     let file:any = new File([u8arr],fileName,{type:mime})
     return file
   }

   /*
       三个参数
       file：一个是文件(类型是图片格式)，
       w：一个是文件压缩的后宽度，宽度越小，字节越小：{width:xx,height:xx}
       photoCompress()
       */
   export const photoCompress = function(file: any, w: { width: number, height ? : number,quality?:number }) {
     return new Promise((resolve, reject) => {
       var ready = new FileReader();
       /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
       ready.readAsDataURL(file);
       ready.onload = function() {
         var re:string = <string>this.result;
         canvasDataURL(re, w).then(base64 => {
           resolve(base64)
         }).catch(e => {
           reject(new Error('压缩图片出错'))
         })
       }
     })

   }
