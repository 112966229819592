

















import { Vue, Component, Prop } from 'vue-property-decorator'
import { getRoleList } from '@/api/role'

interface IItem {
  label: string
  value: string | number | boolean
}

interface IConfig {
  list ? : IItem[]
}

@Component({
  name: 'MapRoleSelect'
})
export default class MapRoleSelect extends Vue {
  @Prop({ default: 200 }) width: number
  @Prop({
    default: function() {
      return {
        list: []
      }
    }
  }) config: IConfig

  private renderList: any[] = []
  created() {
    getRoleList({
      page: {
        index: 1,
        limit: 100
      }
    }).then(({ data }) => {
      const temp = []
      data.items.forEach(r => {
        temp.push({
          value: r.id,
          label: r.role_name
        })
      })
      this.renderList = temp
    })
  }

  // private get renderList() {
  //   if (this.config && this.config.list) {
  //     return this.config.list
  //   } else {
  //     return []
  //   }
  // }
}

