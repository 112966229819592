import request from "@/utils/request";
import {
  IGetBlackListParams,
  IUpdateBlackListParams
} from "./interface/black_list";

const black_list_path = "agent_blacklist";

export const getBlackList = (params: IGetBlackListParams) =>
  request({
    url: `${black_list_path}/findPage`,
    method: "post",
    data: params
  });
export const getSubjectBrief = (params: any) =>
  request({
    url: "agent/getSubjectBrief",
    method: "post",
    data: params
  });

export const getBlackListDetail = (id: string) =>
  request({
    url: `${black_list_path}/detail`,
    method: "post",
    data: {
      id
    }
  });

export const updateBlackList = (params: any) =>
  request({
    url: `${black_list_path}/addOrModify`,
    method: "post",
    data: params
  });

export const removeBlackList = (id: string) =>
  request({
    url: `${black_list_path}/remove`,
    method: "post",
    data: {
      id
    }
  });

export const exportBlackList = (params: IGetBlackListParams) =>
  request({
    url: `${black_list_path}/export`,
    method: "post",
    data: params,
    responseType: "blob"
  });

export const getAlarmRuleList = (params: any) =>
  request({
    url: "industry_alarm_rule/recently_page",
    method: "post",
    data: params
  });
export const getPreAlarmRuleList = (params: any) =>
  request({
    url: "industry_alarm_rule/previous_page",
    method: "post",
    data: params
  });

export const checkGreyExists = (params: any) =>
  request({
    url: "agent_blacklist/rule_grey_exists",
    method: "post",
    data: params
  });
