









































import { Component, Vue } from 'vue-property-decorator'
import {
  getInvoicedList,
  redFlush,
  cancelRedFlush,
  exportExcel
} from '@/api/invoice'
import { IInvoiceData, IGetInvoiceListParams } from '@/api/types'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { InvoiceCategoryMap, InvoiceTypeMap, InvoiceStatus, InvoiceStatusEnum } from '@/common/constant'
import { getEnumArray } from '@/utils/index'
import moment from 'moment'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import { TagsViewModule } from '@/store/modules/tags-view'
const InvoiceCategoryMapArray = getEnumArray(InvoiceCategoryMap)
const InvoiceTypeMapArray = getEnumArray(InvoiceTypeMap)
/**
 * 已开发票页面
 * @type {String}
 */
@Component({
  name: 'TaxInvoiced',
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  list: IInvoiceData[] = []
  total = 0
  listLoading = true
  listQuery: IGetInvoiceListParams = {
    invoiceTime: '',
    summaryUUID: '',
    page: {
      index: 1,
      limit: 10
    }
  }

  queryFilterOptions = [{
    field: 'invoiceCode',
    label: '发票代码'
  }, {
    field: 'invoiceNumber',
    label: '发票编号'
  }, {
    field: 'invoiceType',
    label: '发票类型',
    type: 'select',
    config: {
      list: InvoiceTypeMapArray
    }
  }, {
    field: 'invoiceCategory',
    label: '发票种类',
    type: 'select',
    config: {
      list: InvoiceCategoryMapArray
    }
  }, {
    field: 'invoiceStatus',
    label: '发票状态',
    type: 'select',
    config: {
      list: InvoiceStatusEnum
    }
  }, {
    field: 'buyerIdentity',
    label: '购买方证件号码'
  }, {
    field: 'buyerName',
    label: '购买方名称'
  }, {
    field: 'sellerIdentity',
    label: '销售方证件号码'
  }, {
    field: 'sellerName',
    label: '销售方名称'
  }, {
    field: ['invoiceTimeStart', 'invoiceTimeEnd'], // 'invoiceTime',
    label: '开票时间',
    type: 'dateRange'
  }, {
    field: 'agentDid',
    label: '网络货运企业',
    component: MapSearchSelect,
    config: {
      field: 'agent'
    }
  }].map(item => {
    return Object.assign({}, item, {
      main: true
    })
  })

  tableListOptions = [{
    field: 'id',
    type: 'actionSetting',
    label: '操作',
    width: 50,
    align: 'center',
    attrs: {
      actionOptions: [{
        label: '标记红冲发票',
        type: 'markReverse',
        disabled: function(config, row) {
          if (row.invoice_status === InvoiceStatus.Success) {
            return false
          } else {
            return true
          }
        }
      },
      {
        label: '取消红冲标记',
        type: 'unmarkReverse',
        disabled: function(config, row) {
          if (row.invoice_status === InvoiceStatus.Reverse) {
            return false
          } else {
            return true
          }
        }
      },
      {
        label: '查看详情',
        type: 'detail'
      }
      ]
    },
    fixed: true
  }, {
    field: 'invoice_number',
    label: '发票号码',
    width: 150,
    fixed: true
  }, {
    field: 'agent_name',
    label: '网络货运企业',
    width: 220
  }, {
    field: 'invoice_type_alias',
    label: '发票类型',
    width: 150
  }, {
    field: 'invoice_status_alias',
    label: '发票状态',
    width: 150
  }, {
    field: 'invoice_amount',
    label: '含税金额(元)',
    width: 150,
    align: 'center',
    filter: 'amountFormatFilter'
  }, {
    field: 'excluding_amount',
    label: '金额',
    width: 150,
    align: 'center',
    filter: 'amountFormatFilter'
  },
  {
    field: 'invoice_time',
    label: '开票时间',
    filter: 'timeFilter',
    width: 120,
    align: 'center'
  },

  {
    field: 'invoice_code',
    label: '发票代码',
    width: 150
  },
  {
    field: 'buyer_name',
    label: '购买方',
    width: 220
  }, {
    field: 'buyer_identity',
    label: '购买方证件',
    width: 200
  },
  {
    field: 'seller_name',
    label: '销售方'
  }, {
    field: 'seller_identity',
    label: '销售方证件',
    width: 200
  },
  {
    field: 'invoice_category_alias',
    label: '发票种类',
    width: 150
  }, {
    field: 'added_value_tax',
    label: '增值税额',
    width: 150,
    align: 'center',
    filter: 'amountFormatFilter'
  },
  {
    field: 'city_build_tax',
    label: '城市维护建设税',
    align: 'center',
    filter: 'amountFormatFilter'
  }, {
    field: 'individual_tax',
    label: '个人所得税',
    align: 'center',
    filter: 'amountFormatFilter'
  }, {
    field: 'education_tax',
    label: '教育费附加',
    align: 'center',
    filter: 'amountFormatFilter'
  }, {
    field: 'city_education_tax',
    label: '地方教育费附加',
    align: 'center',
    filter: 'amountFormatFilter'
  }, {
    field: 'stamp_tax',
    label: '印花税',
    align: 'center',
    filter: 'amountFormatFilter'
  }
  ]

  created() {
    this.initListQuery()
    this.getList()
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
    if (this.$route && this.$route.query && this.$route.query.invoiceType !== undefined && this.$route.query.invoiceType !== null) {
      this.listQuery.invoiceType = Number(this.$route.query.invoiceType)
    }
  }

  getQueryParams() {
    const listQuery = JSON.parse(JSON.stringify(this.listQuery))
    return listQuery
  }

  async getList() {
    this.listLoading = true
    const { data } = await getInvoicedList(this.getQueryParams())
    this.list = data.items
    this.total = data.total
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  handleFilter() {
    this.listQuery.page.index = 1
    delete this.listQuery.summaryUUID
    this.getList()
  }

  async handleActionCommand(command, id, row) {
    if (command === 'markReverse') {
      redFlush({
        id: id
      }).then(res => {
        this.$message.success('标记红冲成功')
        this.getList()
      })
    } else if (command === 'unmarkReverse') {
      cancelRedFlush({
        id: id
      }).then(res => {
        this.$message.success('取消标记红冲成功')
        this.getList()
      })
    } else if (command === 'detail') {
      //       async linkToDetail(id: string, row: any) {
      //   const path = '/dataquery/sub_waybill'
      //   const view = (this.$router as any).matcher.match(path)
      //   await TagsViewModule.delCachedView(view)
      //   this.$router.push({
      //     path,
      //     query: {
      //       subWaybillIds: row.link_ids
      //     }
      //   })
      // }
      const path = '/tax/carrier'
      const view = (this.$router as any).matcher.match(path)
      await TagsViewModule.delCachedView(view)
      this.$router.push({
        path: path,
        query: {
          push_date: row.push_date,
          invoice_type: row.invoice_type,
          external_invoice_id: row.external_invoice_id
        }
      })
    }
  }

  handleExportExcel() {
    const params = this.getQueryParams()
    exportExcel(params).then(res => {
      const data:any = [res]
      const date:string = moment().format('YYYY_MM_DD').toString()
      const name = `数据导出_${date}.xls`
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob(data)
        window.navigator.msSaveOrOpenBlob(blob, name)
      } else {
        /* 火狐谷歌的文件下载方式 */
        var blob = new Blob(data)
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob)
        downloadElement.href = href
        downloadElement.download = name
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement)
        window.URL.revokeObjectURL(href)
      }
    }).catch(e => {
      console.log(e)
    })
  }
}

