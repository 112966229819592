



























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { exportBlackList } from '@/api/black_list'
import { getListApi, getWaybillAppealDetailApi, waybillAppealAuditApi } from '@/api/waybill_appeal'
import { EnumActions } from '@/common/business-components/TypeMap/ActionSetting/index.vue'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import TableList from '@/common/business-components/TableList/index.vue'
import { WaybillAppealStatusType, InternalStatusListType, WaybillAppealSourceType, agentType } from '@/common/constant'
import { getEnumArray, convertToExcel } from '@/common/utils'
// import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
// import UploadImage from '@/common/business-components/UploadImage/index.vue'
import MultiUploadFile from '@/common/business-components/MultiUploadFile/index.vue'
import { Form } from 'element-ui'
import { uploadImages, uploadImage, uploadFile } from '@/api/file'
import {
  photoCompress,
  convertBase64UrlToFile
} from '@/utils/compress'
import moment from 'moment'


@Component({
  name: 'WaybillAppeal',
  components: {
    Pagination,
    SearchFilter,
    BsmTable,
    TableList,
    // MapSearchSelect,
    // UploadImage,
    MultiUploadFile
  }
})
export default class extends Vue {
  list: any = []
  total = 0
  listLoading = true
  subLoading = false
  disableBlack = false
  visible = false
  listQuery: any = {
    externalStatus: null,
    internalStatus: null,
    status: null,
    externalStatusList: null,
    page: {
      index: 1,
      limit: 10
    }
  }

  appealItemListOptions = [{
    field: 'ruleId',
    label: '规则编号'
  },
  {
    field: 'ruleName',
    label: '规则名称'
  }, {
    field: 'resultMsg',
    label: '异常原因'
  }, {
    field: 'pass',
    label: '处理结果',
    filter: (v, row) => {
      return v ? '通过' : '不通过'
    }
  }]

  logListOptions = [{
    field: 'recordResult',
    label: '处理内容'
  },
  {
    field: 'propose',
    label: '处理意见'
  }, {
    field: 'appealResult',
    label: '申诉结果'
  }, {
    field: 'createTime',
    label: '处理时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'operator',
    label: '处理人'
  }]

  subjectTypeList = getEnumArray(agentType)

  formData:any = {
    propose: '',
    id: '',
    recordId: ''
  }

  fileList = []

  rules = {
    propose: [{
      required: true,
      message: '处理意见必填',
      trigger: 'blur'
    }]
  }

  currentActionType: EnumActions = null

  queryFilterOptions = [
    // {
    //   field: 'agentDid',
    //   label: '网络货运企业',
    //   component: MapSearchSelect,
    //   config: {
    //     field: 'agent'
    //   },
    //   main: true
    // },
    {
      field: 'appealNo',
      label: '申诉编号',
      type: 'input',
      main: true
    },
    {
      field: 'acceptNo',
      label: '受理编号',
      type: 'input',
      main: true
    },
    {
      field: 'agentName',
      label: '网货企业',
      type: 'input',
      main: true
    },
    {
      field: 'waybillNo',
      label: '运单号',
      type: 'input',
      main: true
    },
    {
      field: 'status',
      label: '申诉结果',
      type: 'select',
      config: {
        list: getEnumArray(WaybillAppealStatusType),
        multiple: true
      },
      main: true
    }, {
      field: 'internalStatus',
      label: '处理结果',
      type: 'select',
      config: {
        list: getEnumArray(InternalStatusListType),
        multiple: true
      },
      main: true
    }, {
      field: 'externalStatus',
      label: '外部处理结果',
      type: 'select',
      config: {
        list: getEnumArray(InternalStatusListType),
        multiple: true
      },
      main: true
    },
    {
      field: ['createStart', 'createEnd'],
      label: '申诉时间',
      type: 'dateRange',
      main: true
    }, {
      field: 'source',
      label: '来源',
      type: 'select',
      config: {
        list: getEnumArray(WaybillAppealSourceType)
      },
      main: true
    }
  ]

  tableListOptions = [{
    field: 'id',
    type: 'actionSetting',
    label: '操作',
    attrs: {
      actionOptions: [{
        type: EnumActions.edit,
        label: '处理',
        disabled: (val, row) => {
          return row.status !== 0
        }
      }, {
        type: EnumActions.detail,
        label: '查看详情',
        disabled: (val, row) => {
          return row.status === 0
        }
      }]
    },
    fixed: true
  }, {
    field: 'appealNo',
    label: '申诉编号'
  }, {
    field: 'acceptNo',
    label: '受理编号'
  }, {
    field: 'agentName',
    label: '网货企业'
  }, {
    field: 'appealPeople',
    label: '申诉人'
  }, {
    field: 'waybillNo',
    label: '运单号'
  }, {
    field: 'appealResult',
    label: '申诉结果'
  }, {
    field: 'internalResult',
    label: '处理结果'
  }, {
    field: 'internalOperator',
    label: '处理人'
  }, {
    field: 'externalResult',
    label: '外部处理结果'
  }, {
    field: 'externalOperator',
    label: '外部处理人'
  }, {
    field: 'createTime',
    label: '申诉时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'updateTime',
    label: '更新时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'sourceDesc',
    label: '来源'
  }]

  created() {
    this.initListQuery()
    this.getList()
  }

  reSet() {
    (this.$refs.form as Form).resetFields()
    this.formData = {
      propose: '',
      id: '',
      recordId: ''
    }
    this.fileList = []
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
  }

  async getList() {
    this.listLoading = true
    this.listQuery.externalStatusList = this.listQuery.externalStatus && this.listQuery.externalStatus.length ? this.listQuery.externalStatus : null
    this.listQuery.internalStatusList = this.listQuery.internalStatus && this.listQuery.internalStatus.length ? this.listQuery.internalStatus : null
    this.listQuery.statusList = this.listQuery.status && this.listQuery.status.length ? this.listQuery.status : null
    const { data } = await getListApi(this.listQuery)
    this.list = data.items
    this.total = data.total
    await this.$nextTick()
    this.listLoading = false
  }

  async getDetail(row) {
    const { data } = await getWaybillAppealDetailApi({ id: row.id })
    this.formData = { ...data }
    this.fileList = data.fileUrls ? data.fileUrls.split(',') : []
  }


  handleFilter() {
    this.listQuery.page.index = 1
    this.listQuery.page.limit = 10
    this.getList()
  }

  handleClickImage(url: string) {
    window.open(url, '_blank')
  }

  handleOpenConfig(val, row) {
    // this.getConfigDetail(val)
    this.visible = true
  }

  handleCloseConfig() {
    this.reSet()
    this.subLoading = false
    // this.inspectConfigList = []
  }


  handelActionDetail(id: string) {
    // this.$router.push({
    //   path: `/risk_rules/detail/${id}`
    // })
  }

  jumpAgentDetail(id) {
    window.open(`/#/dataquery/agent/${id}`)
  }

  jumpOrderDetail(id) {
    window.open(`/#/dataquery/waybill/${id}`)
  }

  async handleSubmit(type) {
    const { propose, id, recordId } = this.formData
    try {
      const valid = await (this.$refs.form as Form).validate()
      if (!valid) {
        return
      }
      await waybillAppealAuditApi({
        id,
        recordId,
        propose,
        recordStatus: type
      })
      this.subLoading = false
      this.visible = false
      this.$message.success('保存成功')
      this.getList()
    } catch (err) {
      console.log(err)
      this.$message.error(err)
      this.subLoading = false
    }
  }

  handleAdd() {
    this.visible = true
    // something to do
    // this.handelActionDetail('create')
  }

  handleEdit(row) {
    this.visible = true
    this.getDetail(row)
    // something todo
  }

  handleDetail(row) {
    this.visible = true
    this.getDetail(row)
  }

  handleActionCommand(command:any, id?, row?) {
    this.currentActionType = command
    if (command === EnumActions.detail) {
      this.handleDetail(row)
    } else if (command === EnumActions.edit) {
      this.handleEdit(row)
    }
  }

  async exportList() {
    try {
      this.listLoading = true
      const res = await exportBlackList(this.listQuery)
      const data = [res]
      const name = `黑名单_${moment().format('YYYY_MM_DD').toString()}`
      convertToExcel(data, name)
      this.listLoading = false
    } catch (err) {
      this.listLoading = false
    }
  }
}

