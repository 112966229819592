import request from '@/utils/request'
import {
  IApplyAccessParams,
  IApproveAccessParams,
  IGetApplyAccessListParams
} from './types'

const path = 'access'

export const applyAccess = (params: IApplyAccessParams) => {
  return request({
    url: `${path}/apply`,
    method: 'post',
    data: params
  })
}

export const getApplyAccessList = (params: IGetApplyAccessListParams) =>
  request({
    url: `${path}/apply/list`,
    method: 'post',
    data: params
  })

export const approveAccess = (params: IApproveAccessParams) =>
  request({
    url: `${path}/approve`,
    method: 'post',
    data: params
  })
