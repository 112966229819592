var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("dashboard-header", {
        attrs: { "time-range": _vm.timeRange },
        on: {
          onTimeRangeChange: _vm.handleTimeChange,
          onBusinessModeChange: _vm.handleBusinessChange,
          onTimeTypeChange: _vm.handleTimeTypeChange
        }
      }),
      _c(
        "el-row",
        { staticClass: "gutter" },
        [
          _c(
            "el-card",
            { attrs: { shadow: "hover" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("multi-gauge-chart", {
                        attrs: { data: _vm.riskScoreGaugeData, height: "270px" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c("stacked-column-line-chart", {
                        attrs: {
                          title: "历史评分变化图",
                          "left-title": "网络货运企业数量",
                          "right-title": "评分",
                          height: "300px",
                          "x-field": "time",
                          "y-field": ["value", "count"],
                          "stack-field": "type",
                          "series-field": "type",
                          data: _vm.stackedData
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "gutter", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "chart-container",
                {
                  attrs: {
                    title: "网络货运企业(家)",
                    amount: _vm.data.agent_count,
                    link:
                      "/dataquery/agent?" +
                      (_vm.params.start_time
                        ? "startTime=" +
                          _vm.params.start_time +
                          "&endTime=" +
                          _vm.params.end_time
                        : "")
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "140px" } },
                    [
                      _c("line-area-chart", {
                        attrs: {
                          data: _vm.data.agent_count_list,
                          "x-field": "timestamp",
                          "y-field": "bill_count"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "chart-container",
                {
                  attrs: {
                    title: "承运人(人)",
                    amount: _vm.data.carrier_count,
                    link:
                      "/dataquery/carrier?" +
                      (_vm.params.start_time
                        ? "startTime=" +
                          _vm.params.start_time +
                          "&endTime=" +
                          _vm.params.end_time
                        : "")
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "140px" } },
                    [
                      _c("line-area-chart", {
                        attrs: {
                          color: "yellow",
                          data: _vm.data.carrier_count_list,
                          "x-field": "timestamp",
                          "y-field": "bill_count"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "chart-container",
                {
                  attrs: {
                    title: "车辆(辆)",
                    amount: _vm.data.vehicle_count,
                    link:
                      "/dataquery/vehicle?" +
                      (_vm.params.start_time
                        ? "startTime=" +
                          _vm.params.start_time +
                          "&endTime=" +
                          _vm.params.end_time
                        : "")
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "140px" } },
                    [
                      _c("line-area-chart", {
                        attrs: {
                          color: "green",
                          data: _vm.data.vehicle_count_list,
                          "x-field": "timestamp",
                          "y-field": "bill_count"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "gutter", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "chart-container",
                {
                  staticStyle: { height: "240px" },
                  attrs: {
                    title: "运单笔数(笔)",
                    amount: _vm.data.waybill_count
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "line-chart-container" },
                    [
                      _c("line-chart", {
                        attrs: {
                          data: _vm.waybillLineData,
                          "series-field": "type",
                          "x-field": "timestamp",
                          "y-field": "bill_count"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "gutter", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "chart-container",
                {
                  staticStyle: { height: "240px" },
                  attrs: {
                    title: "运费收入支出(元)",
                    amount: _vm._f("amountFormatFilter")(_vm.totalFreightIncome)
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "line-chart-container" },
                    [
                      _c("line-chart", {
                        attrs: {
                          data: _vm.freightLineData,
                          "y-filter": "amountFormatWithUnit",
                          "tooltip-filter": "amountFormatWithUnit",
                          "series-field": "type",
                          "x-field": "timestamp",
                          "y-field": "bill_count"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "gutter", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "chart-container",
                {
                  attrs: {
                    title: "税收收入(元)",
                    amount: _vm._f("amountFormatFilter")(_vm.taxIncomeTotal),
                    link: ""
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "143px",
                        padding: "27px",
                        "padding-top": "8px",
                        "padding-bottom": "58px"
                      }
                    },
                    [
                      _c("tiny-line-chart", {
                        attrs: {
                          data: _vm.taxLineData,
                          "x-field": "timestamp",
                          "y-field": "bill_count",
                          "y-filter": "amountFormatFilter"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "chart-container",
                {
                  attrs: {
                    title: "税收类型分布(元)",
                    amount: _vm._f("amountFormatFilter")(
                      _vm.taxIncomeTypeTotal
                    ),
                    link: ""
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "140px",
                        position: "relative",
                        top: "-10px"
                      }
                    },
                    [
                      _vm.data.tax_income_type_list.length
                        ? _c("donut-chart", {
                            attrs: {
                              data: _vm.data.tax_income_type_list,
                              "angle-field": "value",
                              "color-field": "name",
                              model: "right",
                              type: "amount"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "chart-container",
                {
                  attrs: {
                    title: "税种分布(元)",
                    amount: _vm._f("amountFormatFilter")(_vm.taxTypeTotal),
                    link: ""
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "140px",
                        position: "relative",
                        top: "-10px"
                      }
                    },
                    [
                      _vm.data.tax_type_list.length
                        ? _c("donut-chart", {
                            attrs: {
                              data: _vm.data.tax_type_list,
                              "angle-field": "value",
                              "color-field": "name",
                              model: "right",
                              type: "amount"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "gutter", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "chart-container",
                {
                  staticStyle: { height: "240px" },
                  attrs: {
                    title: "发票开立(笔)，开票总金额(元)",
                    amount:
                      _vm.data.invoice_count +
                      "," +
                      _vm.data.total_invoice_amount,
                    link:
                      "/tax/invoiced?" +
                      (_vm.params.start_time
                        ? "invoiceTime=" +
                          _vm.params.start_time +
                          "-" +
                          _vm.params.end_time
                        : "")
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "line-chart-container" },
                    [
                      _c("line-chart", {
                        attrs: {
                          data: _vm.invoiceLineData,
                          "series-field": "type",
                          "x-field": "timestamp",
                          "y-field": "bill_count"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "gutter", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "chart-container",
                {
                  staticStyle: { height: "240px" },
                  attrs: {
                    title: "开票运单(笔)",
                    amount: _vm.data.invoice_waybill_count,
                    link:
                      "/dataquery/waybill?" +
                      (_vm.params.start_time
                        ? "waybillCreateTime=" +
                          _vm.params.start_time +
                          "-" +
                          _vm.params.end_time
                        : "")
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "line-chart-container" },
                    [
                      _c("line-chart", {
                        attrs: {
                          data: _vm.invoiceWaybillLineData,
                          "series-field": "type",
                          "x-field": "timestamp",
                          "y-field": "bill_count"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "gutter", attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c(
                "chart-container",
                { attrs: { title: "网络货运企业运单量排名(单)" } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "200px" } },
                    [
                      _c("bar-chart", {
                        attrs: {
                          data: _vm.data.agent_waybill_rank_list,
                          "x-field": "value",
                          "y-field": "name"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c(
                "chart-container",
                { attrs: { title: "网络货运企业运费排名(元)" } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "200px" } },
                    [
                      _c("bar-chart", {
                        attrs: {
                          data: _vm.data.agent_freight_rank_list,
                          "value-type": "amount",
                          "x-field": "value",
                          "y-field": "name",
                          color: "green"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c(
                "chart-container",
                { attrs: { title: "承运人运单量排名(单)" } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "200px" } },
                    [
                      _c("bar-chart", {
                        attrs: {
                          data: _vm.data.carrier_waybill_rank_list,
                          "x-field": "value",
                          "y-field": "name",
                          color: "yellow"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 6 } },
            [
              _c(
                "chart-container",
                { attrs: { title: "承运人运费排名(元)" } },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "200px" } },
                    [
                      _c("bar-chart", {
                        attrs: {
                          data: _vm.data.carrier_freight_rank_list,
                          "value-type": "amount",
                          "x-field": "value",
                          "y-field": "name",
                          color: "red"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 12 } },
            [
              _c("chart-container", { attrs: { title: "收发货地址分布" } }, [
                _c(
                  "div",
                  { staticStyle: { height: "473px" } },
                  [
                    _c("china-map", {
                      attrs: {
                        "delivery-distribution-data":
                          _vm.data.delivery_province_distribution,
                        "receiver-distribution-data":
                          _vm.data.receiver_province_distribution
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 12 } },
            [
              _c(
                "el-row",
                { staticClass: "gutter", attrs: { gutter: 16 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, lg: 12 } },
                    [
                      _c("chart-container", { attrs: { title: "货物类型" } }, [
                        _c(
                          "div",
                          { staticStyle: { height: "200px" } },
                          [
                            _c("pie-chart", {
                              attrs: {
                                data: _vm.data.goods_type_distribution,
                                "angle-field": "value",
                                "color-field": "name"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, lg: 12 } },
                    [
                      _c("chart-container", { attrs: { title: "业务类型" } }, [
                        _c(
                          "div",
                          { staticStyle: { height: "200px" } },
                          [
                            _c("pie-chart", {
                              attrs: {
                                data: _vm.data.business_type_distribution,
                                "angle-field": "value",
                                "color-field": "name"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, lg: 12 } },
                    [
                      _c(
                        "chart-container",
                        { attrs: { title: "运费支付方式" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { height: "200px" } },
                            [
                              _c("pie-chart", {
                                attrs: {
                                  data: _vm.data.pay_type_distribution,
                                  "angle-field": "value",
                                  "color-field": "name",
                                  type: "amount"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, lg: 12 } },
                    [
                      _c(
                        "chart-container",
                        { attrs: { title: "平均付款时长" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { height: "200px" } },
                            [
                              _c("pie-chart", {
                                attrs: {
                                  data: _vm.data.pay_duration_distribution,
                                  "angle-field": "value",
                                  "color-field": "name",
                                  type: "amount"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }