var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chart-container",
      class: _vm.className,
      style: { height: _vm.height, width: _vm.width }
    },
    [
      _vm.title
        ? _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _c("div", { ref: "chart", staticStyle: { width: "100%" } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }