// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.app-main {\n  /* 50= navbar  50  */\n  width: 100%;\n  position: relative;\n  overflow: hidden;\n}\n.fixed-header + .app-main {\n  padding-top: 60px;\n  height: 100vh;\n  overflow: auto;\n}\n.hasTagsView .app-main {\n  /* 84 = navbar + tags-view = 50 + 34 */\n  min-height: calc(100vh - 100px);\n}\n.hasTagsView .fixed-header + .app-main {\n  padding-top: 100px;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
