var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClickTab },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "网货企业", name: "enterprise" } },
            [
              _c("search-filter", {
                attrs: {
                  options: _vm.queryFilterOptions,
                  "type-default": "input"
                },
                on: { search: _vm.handleFilter },
                model: {
                  value: _vm.listQuery,
                  callback: function($$v) {
                    _vm.listQuery = $$v
                  },
                  expression: "listQuery"
                }
              }),
              _c(
                "section",
                [
                  _c("bsm-table", {
                    attrs: {
                      name: "enterprise-inspect-config",
                      data: _vm.list,
                      options: _vm.tableListOptions,
                      "list-loading": _vm.listLoading,
                      "show-index": true,
                      "show-setting": false
                    }
                  })
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0"
                  }
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page.index,
                  limit: _vm.listQuery.page.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.listQuery.page, "index", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.listQuery.page, "limit", $event)
                  },
                  pagination: _vm.getList
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "托运人", name: "shipper" } },
            [
              _c("search-filter", {
                attrs: {
                  options: _vm.queryFilterOptions2,
                  "type-default": "input"
                },
                on: { search: _vm.handleFilter2 },
                model: {
                  value: _vm.listQuery2,
                  callback: function($$v) {
                    _vm.listQuery2 = $$v
                  },
                  expression: "listQuery2"
                }
              }),
              _c(
                "section",
                [
                  _c("bsm-table", {
                    attrs: {
                      name: "shipper-inspect-config",
                      data: _vm.list2,
                      options: _vm.tableListOptions2,
                      "list-loading": _vm.listLoading,
                      "show-index": true,
                      "show-setting": false
                    }
                  })
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total2 > 0,
                    expression: "total2>0"
                  }
                ],
                attrs: {
                  total: _vm.total2,
                  page: _vm.listQuery2.page.index,
                  limit: _vm.listQuery2.page.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.listQuery2.page, "index", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.listQuery2.page, "limit", $event)
                  },
                  pagination: _vm.getList2
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-container",
          attrs: { title: "抽检配置", visible: _vm.visible, width: "800px" },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.handleCloseConfig
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-container" },
            [
              _vm._l(
                _vm.inspectConfigList.filter(function(item) {
                  return !item.deleted
                }),
                function(item, index) {
                  return _c(
                    "el-card",
                    { key: index, staticClass: "box-card config-item" },
                    [
                      _c("div", { staticClass: "config-item-index" }, [
                        _c("span", { staticClass: "index" }, [
                          _vm._v(_vm._s(index + 1))
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "config-item-close",
                          on: {
                            click: function($event) {
                              return _vm.handleRemoveConfig(item)
                            }
                          }
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      ),
                      _c(
                        "el-form",
                        {
                          ref: item.ref,
                          refInFor: true,
                          attrs: {
                            "label-width": "120px",
                            model: item,
                            rules: _vm.rules
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "type",
                                size: "mini",
                                label: "数据抽检内容"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  on: {
                                    change: function($event) {
                                      return _vm.handleTypeChange(item, $event)
                                    }
                                  },
                                  model: {
                                    value: item.type,
                                    callback: function($$v) {
                                      _vm.$set(item, "type", $$v)
                                    },
                                    expression: "item.type"
                                  }
                                },
                                _vm._l(_vm.inspectContentTypeList, function(
                                  config,
                                  i
                                ) {
                                  return _c("el-option", {
                                    key: i,
                                    attrs: {
                                      label: config.label,
                                      value: config.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                size: "mini",
                                label: "数据抽检策略",
                                prop: "strategyId",
                                clearable: ""
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  on: {
                                    change: function($event) {
                                      return _vm.handleStrategyChange(item)
                                    }
                                  },
                                  model: {
                                    value: item.strategyId,
                                    callback: function($$v) {
                                      _vm.$set(item, "strategyId", $$v)
                                    },
                                    expression: "item.strategyId"
                                  }
                                },
                                _vm._l(
                                  _vm.getInspectStrategyListByType(item.type),
                                  function(strategy, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: {
                                        value: strategy.id,
                                        label: strategy.name,
                                        disabled: strategy.status === 2
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { size: "mini", label: "数据抽检比例" } },
                            [
                              _c("el-input", {
                                attrs: { value: item.percentage, disabled: "" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { size: "mini", label: "抽检数量上限" } },
                            [
                              _c("el-input", {
                                attrs: { value: item.max_count, disabled: "" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { size: "mini", label: "数据抽检频率" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  value: item.frequency_type
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              ),
              _c(
                "el-card",
                { staticClass: "box-card add-btn" },
                [
                  _c("el-button", {
                    staticClass: "btn",
                    attrs: {
                      type: "primary",
                      circle: "",
                      icon: "el-icon-circle-plus-outline"
                    },
                    on: { click: _vm.handleAddConfig }
                  }),
                  _c("span", [_vm._v("新增抽检配置项")])
                ],
                1
              )
            ],
            2
          ),
          _c(
            "div",
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业抽检开关" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled: !_vm.inspectConfigList.filter(function(
                            item
                          ) {
                            return !item.deleted
                          }).length,
                          "active-text": "启用",
                          "inactive-text": "关闭",
                          "active-value": 1,
                          "inactive-value": 2
                        },
                        model: {
                          value: _vm.inspectConfigStatus,
                          callback: function($$v) {
                            _vm.inspectConfigStatus = $$v
                          },
                          expression: "inspectConfigStatus"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.visible = false
                    }
                  }
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.subLoading, type: "primary" },
                  on: { click: _vm.handleUpdateConfig }
                },
                [_vm._v(" 保存 ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }