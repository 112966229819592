


















import { Vue, Component, Prop } from 'vue-property-decorator'
import TableList from '../TableList/index.vue'

@Component({
  components: {
    TableList
  }
})
export default class extends Vue {
  @Prop({ default: () => [] }) etcInvoiceInfo;

  invoiceListOptions = [{
    field: 'appealNo',
    label: '申诉编号'
    // filter() {
    //   return 'ETC发票'
    // }
  }, {
    field: 'acceptNo',
    label: '受理编号',
    type: 'link',
    config: {
      url(val, row) {
        return `/${window.location.host.indexOf('insight') > -1 || window.location.host.indexOf('9527') > -1 ? 'regulation' : 'supervise'}/waybill-appeal?acceptNo=${val}`
      }
    }
  }, {
    field: 'appealPeople',
    label: '申诉人'
  }, {
    field: 'appealResult',
    label: '申诉结果'
  }, {
    field: 'internalResult',
    label: '处理结果'
  }, {
    field: 'internalOperator',
    label: '处理人'
  }, {
    field: 'externalResult',
    label: '外部处理结果'
  }, {
    field: 'externalOperator',
    label: '外部处理人'
  }, {
    field: 'createTime',
    label: '申诉时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'updateTime',
    label: '更新时间',
    filter: 'timeFilterShowSecond'
  }, {
    field: 'sourceDesc',
    label: '来源'
  }
  ]
}
