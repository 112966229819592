










































import { Vue, Component } from 'vue-property-decorator'

@Component
export default class ShowImgList extends Vue {
  private visible = false
  private srcs: string[] = []

  private get renderSrcs() {
    const srcs = Object.assign([], this.srcs)
    const list = []
    while (srcs.length) {
      if (srcs.length > 3) {
        list.push(srcs.splice(0, 3))
      } else {
        list.push(srcs.splice(0, srcs.length))
      }
    }
    return list
  }

  public open(srcs: string[]) {
    this.srcs = srcs
    this.visible = true
  }

  private close() {
    this.visible = false
  }

  private handleClick() {
    this.visible = false
  }

  private handleClose() {
    this.srcs = []
  }

  private handleLink(src) {
    this.$showImg(src)
  }
  // @Prop({
  //   default: false,
  //   required: true
  // }) visible: Boolean

  // @Prop({
  //   default: '',
  //   required: true
  // }) src: string
}

