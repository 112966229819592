import request from '@/utils/request'
import { IAgentRankParams, IAgentRankDto } from '@/api/types'

// const path = 'agent/aggregation'
export const agentSortQuery = (params: IAgentRankParams) => {
  return request({
    url: '/agent_sort/list',
    method: 'post',
    data: params
  })
}

export const exportAgentSort = (params: IAgentRankParams) => {
  return request({
    url: '/agent_sort/export',
    method: 'post',
    data: params,
    responseType: 'blob'
  })
}
