import request from '@/utils/request'
import { IGetInvoiceListParams } from './types'

const path = 'invoice'

export const getInvoicedList = (params: IGetInvoiceListParams) =>
  request({
    url: `${path}/invoiced`,
    method: 'post',
    data: params
  })

export const redFlush = (params: { id: string }) =>
  request({
    url: `${path}/red_flush`,
    method: 'post',
    data: params
  })
export const cancelRedFlush = (params: { id: string }) =>
  request({
    url: `${path}/cancel_red_flush`,
    method: 'post',
    data: params
  })

export const exportExcel = (params: IGetInvoiceListParams) =>
  request({
    url: `${path}/export_excel`,
    method: 'post',
    data: params,
    responseType: 'blob'
  })


export const exportInvoiceHistory=(params: { id: string }) => request({
  url: `export_history/export`,
  method: 'post',
  data: params,
  responseType: 'blob'
})
export const getInvoiceHistoryList=(params:any) => request({
  url: `export_history/page`,
  method: 'post',
  data: params
})
