






































import { Component, Vue } from 'vue-property-decorator'
import {
  employedPageApi
} from '@/api/system'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'

@Component({
  name: 'IndividualManage',
  components: {
    BsmTable,
    Pagination,
    SearchFilter
  }
})
export default class extends Vue {
  list = []
  listLoading = true
  submitLoading = false
  showEdit = false
  srcList=[]
  invoiceActive = true
  total = 0;
  listQuery = {
    page: {
      index: 1,
      limit: 10
    }
  }

  queryFilterOptions = [
    {
      field: 'self_key',
      label: '个体户名称或纳税人识别号',
      main: true
    },
    {
      field: 'legal_key',
      label: '经营者姓名或证件号',
      main: true
    },
    {
      field: 'status',
      label: '个体户状态',
      type: 'select',
      config: {
        list: [{
          label: '待提交',
          value: 0
        }, {
          label: '待注册',
          value: 1
        }, {
          label: '注册中',
          value: 2
        }, {
          label: '注册成功',
          value: 3
        }, {
          label: '注册失败',
          value: 4
        }]
      },
      main: true
    },
    {
      field: 'tax_status',
      label: '税务状态',
      type: 'select',
      config: {
        list: [{
          label: '未实名',
          value: 0
        }, {
          label: '已实名',
          value: 1
        }]
      },
      main: true
    },
    {
      field: 'pay_auth_status',
      label: '支付认证状态',
      type: 'select',
      config: {
        list: [{
          label: '未认证',
          value: 0
        }, {
          label: '认证中',
          value: 1
        }, {
          label: '认证失败',
          value: 2
        }, {
          label: '认证成功',
          value: 3
        }]
      },
      main: true
    }
  ]

  tableListOptions = [{
    field: 'subject_id',
    type: 'actionSetting',
    label: '操作',
    align: 'center',
    width: 50,
    attrs: {
      actionOptions: [{
        type: 'view',
        label: '查看开票记录'
      }]
    },
    fixed: true
  }, {
    field: 'name',
    label: '个体户名称'
  }, {
    field: 'tax_number',
    label: '纳税人识别号'
  }, {
    field: 'status_desc',
    label: '个体户状态',
    type: 'linkLike',
    config: {
      label: (val, row) => {
        return row.status === 3
          ? val
          : `<span style="color:#686868;cursor:default;">${val}</span>`
      },
      handle: (val, row) => {
        if (row.status === 3) {
          this.viewImage(row)
        }
      }
    }
  }, {
    field: 'tax_status_desc',
    label: '税务状态'
  }, {
    field: 'pay_auth_status_desc',
    label: '支付认证状态'
  }, {
    field: 'legal_name',
    label: '经营者姓名'
  }, {
    field: 'legal_identity_no',
    label: '经营者证件号'
  }, {
    field: 'register_place',
    label: '注册地'
  }, {
    field: 'register_phone',
    label: '注册手机号'
  }, {
    field: 'register_address',
    label: '注册地址'
  }, {
    field: 'bank_name',
    label: '开户行'
  }, {
    field: 'bank_account_no',
    label: '银行账号'
  }, {
    field: 'create_time',
    label: '创建时间',
    filter: 'timeFilterShowSecond',
    width: 100
  }, {
    field: 'update_time',
    label: '更新时间',
    filter: 'timeFilterShowSecond',
    width: 100
  }
  ]

  created() {
    this.init()
  }

  init() {
    this.getList()
  }

  viewImage(row) {
    if (row.business_license_url) {
      this.srcList = [row.business_license_url]
      this.$nextTick(() => {
        (this.$refs.elImage as any).clickHandler()
      })
    } else {
      this.$message.error('暂无营业执照照片')
    }
  }

  async getList() {
    this.listLoading = true
    const listQuery = JSON.parse(JSON.stringify(this.listQuery))
    const { data } = await employedPageApi(listQuery)
    this.list = data.items
    this.total = data.total
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  handleFilter() {
    this.listQuery.page.index = 1
    this.getList()
  }



  handleActionCommand(command, val, row) {
    if (command === 'view') {
      const query:any = { tabStatus: 1, self_key: row.name }
      this.$router.push({
        path: '/manage/individual-invoice',
        query: query
      })
    }
  }
}

