var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "risk-score-container" }, [
    _vm._v(" 评分："),
    _c("span", { staticClass: "score", style: { color: _vm.showColor } }, [
      _vm._v(_vm._s(_vm.value))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }