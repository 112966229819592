import moment from 'moment'
import {
  photoCompress,
  convertBase64UrlToFile
} from './compress'
import { uploadImage, uploadFile } from '../api/file'
// Parse the time to string
export const parseTime = (
  time ? : object | string | number | null,
  cFormat ? : string
): string | null => {
  if (time === undefined || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date: Date
  if (typeof time === 'object') {
    date = time as Date
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj: {
    [key: string]: number
  } = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return timeStr
}

// Format and filter json data using filterKeys array
export const formatJson = (filterKeys: any, jsonData: any) =>
  jsonData.map((data: any) => filterKeys.map((key: string) => {
    if (key === 'timestamp') {
      return parseTime(data[key])
    } else {
      return data[key]
    }
  }))

// Check if an element has a class
export const hasClass = (ele: HTMLElement, className: string) => {
  return !!ele.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
}

// Add class to element
export const addClass = (ele: HTMLElement, className: string) => {
  if (!hasClass(ele, className)) ele.className += ' ' + className
}

// Remove class from element
export const removeClass = (ele: HTMLElement, className: string) => {
  if (hasClass(ele, className)) {
    const reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

// Toggle class for the selected element
export const toggleClass = (ele: HTMLElement, className: string) => {
  if (!ele || !className) {
    return
  }
  let classString = ele.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  ele.className = classString
}

export const file2Base64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = function(e) {
      const base64: string = e.target.result as string
      resolve(base64.split(',')[1])
    }

    try {
      fileReader.readAsDataURL(file)
    } catch (e) {
      reject(e)
    }
  })
}

/*
 * @param x {Object} 对象1
 * @param y {Object} 对象2
 * @return  {Boolean} true 为相等，false 为不等
 */
export const deepEqual = function(x, y) {
  // 指向同一内存时
  if (x === y) {
    return true
  } else if ((typeof x === 'object' && x != null) && (typeof y === 'object' && y != null)) {
    if (Object.keys(x).length !== Object.keys(y).length) { return false }

    for (const prop in x) {
      // eslint-disable-next-line no-prototype-builtins
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) { return false }
      } else { return false }
    }

    return true
  } else { return false }
}

export const sum = function(list: any, key: string) {
  let total = 0
  list && list.forEach(i => {
    total += i[key]
  })
  return total
}

export const getEnumArray = function(map: any, keyLabel = 'value', valueLabel = 'label') {
  if (map instanceof Object) {
    const arry = []
    for (const k in map) {
      const v = map[k]
      const obj = {}
      let key: number | string = Number(k)
      if (isNaN(key)) {
        key = k
      }
      obj[keyLabel] = key
      obj[valueLabel] = v
      arry.push(obj)
    }

    return arry
  } else if (map instanceof Array) {
    return map
  } else {
    throw 'argument 0 is not a Object or a Array'
  }
}

export const mergeRule = function(objValue: any, srcValue: any) {
  if (objValue instanceof Array) {
    return objValue.concat(srcValue)
  } else if (!srcValue) {
    // new value is null or 0,return old value
    return objValue
  } else {
    return srcValue
  }
}

export const mergeOldDataRule = function(objValue: any, srcValue: any) {
  if (objValue instanceof Object && !srcValue) {
    return objValue
  } else {
    return srcValue
  }
}
export const validateForm = function(refDom: any) {
  return new Promise((resolve, reject) => {
    refDom.validate((valid) => {
      if (valid) {
        resolve(true)
      } else {
        console.error('表单校验失败')
        resolve(false)
      }
    }).catch(e => {
      reject(e)
    })
  })
}
export const isUrl = function(str: string) {
  const reg = /^((http|https):\/\/)/
  return reg.test(str)
}

export const convertToExcel = function(data: any, name: string) {
  const filename = `${name}.xls`
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob(data)
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    /* 火狐谷歌的文件下载方式 */
    const blob = new Blob(data)
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
    downloadElement.href = href
    downloadElement.download = filename
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(href)
  }
}

export const Unit = {
  m2Km: (value: any, fixed = 3) => {
    return (value / 1000).toFixed(fixed)
  },
  g2Kg: (value: any, fixed = 3) => {
    return (value / 1000).toFixed(fixed)
  }
}

export const getTimeFormat = function(startTime:number, endTime:number) {
  const start_moment = moment(startTime)
  const end_moment = moment(endTime)

  const hour = end_moment.diff(start_moment, 'hour')
  let timeFormat = 'MM月DD'
  if (hour <= 24) {
    // 24小时内
    timeFormat = 'HH:mm'
  }
  if (hour > 24 && hour <= 168) {
    // 7天内
    timeFormat = 'D日H时'
  } else if (hour > 168 && hour <= 2160) {
    // 3个月内
    timeFormat = 'MM.DD'
  } else if (hour > 2160 && hour <= 8760) {
    // 1年内
    timeFormat = 'MM月'
  } else if (hour > 8760) {
    // 1年以上
    timeFormat = 'YYYY.MM'
  }
  return timeFormat
}

export function formatCurrency(num, decimal = 2) {
  num = num.toString().replace(/\$|\,/g, '')
  const decimalNum = Math.pow(10, decimal)
  if (isNaN(num)) { num = '0' }
  const sign = (num == (num = Math.abs(num)))
  num = Math.floor(num * decimalNum + 0.50000000001)
  // let cents:any = (num % decimalNum).toString()
  let cents = ''
  if (decimal) {
    cents = (num / decimalNum).toFixed(decimal).split('.')[1]
  }
  num = Math.floor(num / decimalNum).toString()
  // if (cents < 10) { cents = '0' + cents }
  for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
    num = num.substring(0, num.length - (4 * i + 3)) + ',' +
  num.substring(num.length - (4 * i + 3))
  }
  return (((sign) ? '' : '-') + num + (decimal ? ('.' + cents) : ''))
}

export function formatDecimal(num: number, decimal = 2) {
  return formatMaxDecimal(num, decimal).toFixed(decimal)
}

export function formatMaxDecimal(num: number, decimal = 2) {
  const maxDecimal = Math.pow(10, decimal)
  return Math.round(num * maxDecimal) / maxDecimal
}
export const uploadMultiFiles = async function(files: any = [], size?: number) {
  const urls = []
  for (let i = 0, len = files.length; i < len; i++) {
    const orignFile = files[i]
    // 原url:Object 或者是String
    if (!orignFile.raw) {
      urls.push(orignFile.url || orignFile)
      continue
    }

    const d = new FormData()
    const imageFile = orignFile.raw
    if (imageFile.type === 'application/pdf') {
      d.append('file', imageFile)
      const { data } = await uploadFile(d)
      urls.push(data.resource_url)
    } else {
      const base64 = await photoCompress(imageFile, { width: 1000, quality: 0.8 })
      const file = convertBase64UrlToFile(base64, imageFile.name)

      // limit image size to size
      if (size && (file.size > size)) {
        throw new Error('图片大小应小于2M，请重新选择图片')
      }

      d.append('pic', file)
      const { data } = await uploadImage(d)
      urls.push(data.resource_url)
    }
  }

  return urls.join(',')
}

export function getUrlParams(params) {
  // 通过 ? 分割获取后面的参数字符串
  const urlStr = window.location.href.split('?')[1]
  // 创建空对象存储参数
  const obj = {}
  // 再通过 & 将每一个参数单独分割出来
  if (urlStr) {
    const paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
      const arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1]
    }
    return obj[params]
  } else {
    return ''
  }
}
