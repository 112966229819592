var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "waybill-detail-container bg-normal px-10"
    },
    [
      _c("div", { staticClass: "head" }, [
        _c(
          "span",
          { staticClass: "pointer text-primary" },
          [
            _c(
              "router-link",
              {
                attrs: { to: "/dataquery/agent/" + _vm.waybillData.agent_did }
              },
              [_vm._v(" " + _vm._s(_vm.waybillData.agent) + " ")]
            )
          ],
          1
        ),
        _c("span", { staticStyle: { "margin-left": "40px" } }, [
          _vm._v("运单号：" + _vm._s(_vm.waybillData.waybill_no))
        ]),
        _c("span", { staticStyle: { "margin-left": "40px" } }, [
          _vm._v("运单类型：" + _vm._s(_vm.waybillData.business_mode_alias))
        ]),
        _c("span", { staticStyle: { "margin-left": "40px" } }, [
          _vm._v(
            "运单数据上传时间：" +
              _vm._s(
                _vm._f("timeFilterShowSecond")(_vm.waybillData.create_time)
              )
          )
        ]),
        _c("span", { staticStyle: { float: "right" } }, [
          _vm._v("数据已上传智慧物流联盟链")
        ])
      ]),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c(
            "waybill-base",
            {
              attrs: {
                "waybill-info": _vm.waybillBaseInfo,
                "anti-fraud-info": _vm.antiFraudInfo,
                "waybill-id": _vm.waybillData.id
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end"
                  }
                },
                [
                  _c("waybill-risk-operation", {
                    attrs: {
                      "waybill-id": _vm.waybillData.id,
                      "risk-grade":
                        _vm.antiFraudInfo &&
                        _vm.antiFraudInfo.antiFraudWaybillDto.riskGrade,
                      "is-forced": !!(
                        _vm.antiFraudInfo &&
                        _vm.antiFraudInfo.antiFraudWaybillLogDto.id
                      )
                    },
                    on: { update: _vm.getDetail }
                  }),
                  _c("waybill-risk-log", {
                    attrs: {
                      type:
                        _vm.antiFraudInfo &&
                        _vm.antiFraudInfo.antiFraudWaybillLogDto.operateType
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("waybill-goods", {
            attrs: { "waybill-goods-info": _vm.waybillGoodsInfo }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("waybill-transport", {
            attrs: { "waybill-transport-info": _vm.waybillTransportInfo },
            on: { onTrack: _vm.handleTrack }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("waybill-payment", {
            attrs: { "waybill-payment-info": _vm.waybillPaymentInfo }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("waybill-invoice", {
            attrs: { "waybill-invoice-info": _vm.waybillInvoiceInfo }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("etc-invoice", {
            attrs: { "etc-invoice-info": _vm.etcInvoiceInfo }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("waybill-appeal-log", {
            attrs: { "etc-invoice-info": _vm.waybillAppealLog }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c("waybill-addition", {
            attrs: {
              "is-addable": true,
              "waybill-addition-info": _vm.waybillAdditionInfo
            },
            on: { uploadAddition: _vm.handleUploadAddition }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("waybill-log", {
            attrs: { "waybill-log-info": _vm.waybillLogInfo }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }