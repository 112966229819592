



























import { Component, Vue } from 'vue-property-decorator'
import { getDriverList } from '@/api/driver'
import { IDriverData, IGetListParams } from '@/api/types'
import Pagination from '@/common/components/Pagination/index.vue'
import SearchFilter from '@/common/business-components/SearchFilter/index.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { resolveList } from '@/utils/common'
import MapSearchSelect from '@/views/components/TypeMap/map/MapSearchSelect.vue'
import { getEnumArray } from '@/common/utils'
import { riskScoreTypes } from '@/common/constant'

@Component({
  name: 'Driver',
  components: {
    Pagination,
    SearchFilter,
    BsmTable
  }
})
export default class extends Vue {
  list: IDriverData[] = []
  total = 0
  listLoading = true
  listQuery: IGetListParams = {
    page: {
      index: 1,
      limit: 10
    }
  }

   queryFilterOptions = [...[{
     field: 'driver',
     label: '名称或证件号码',
     main: true,
     component: MapSearchSelect
   }, {
     field: 'agent',
     label: '网络货运名称或证件号码',
     component: MapSearchSelect
   }, {
     field: 'consignor',
     label: '托运人名称或证件号码',
     component: MapSearchSelect

   }, {
     field: 'carrier',
     label: '承运人名称或证件号码',
     component: MapSearchSelect
   }, {
     field: 'vehicle',
     label: '车辆牌照号码',
     component: MapSearchSelect
   }, {
     field: 'startTime',
     label: '',
     hidden: true
   }, {
     field: 'endTime',
     label: '',
     hidden: true
   }].map(item => {
     return Object.assign({}, item, {
       config: {
         field: item.field
       }
     })
   }), {
     field: 'risk_score_types',
     label: '评分等级',
     type: 'select',
     config: {
       list: getEnumArray(riskScoreTypes),
       multiple: true
     }
   }, {
     field: ['risk_score_left', 'risk_score_right'],
     label: '评分分值',
     type: 'range'
   }]

  tableListOptions = [{
    field: 'subject_id',
    type: 'link',
    label: '操作',
    config: {
      baseUrl: '/dataquery/driver',
      label: '查看详情'
    },
    fixed: true
  }, {
    field: 'driver_name',
    label: '名称',
    fixed: true
  }, {
    field: 'driver_identity',
    label: '证件号码',
    width: 180
  },
  {
    field: 'risk_score',
    label: '评分',
    align: 'center',
    filter: 'riskScore'
  },
  {
    field: 'risk_score_msg',
    label: '评分等级',
    align: 'center'
  },
  {
    field: 'waybill_count',
    label: '运单笔数',
    align: 'center'
  }, {
    field: 'income_sum',
    label: '运费收入(元)',
    align: 'center',
    filter: 'amountFormatFilter'
  },
  {
    field: 'actual_freight',
    label: '实收运费(元)',
    align: 'center',
    filter: 'amountFormatFilter'
  }, {
    field: 'consignor_count',
    label: '托运人数量',
    align: 'center'
  }, {
    field: 'agent_count',
    label: '网络货运企业数量',
    align: 'center'
  }, {
    field: 'carrier_count',
    label: '承运人数量',
    align: 'center'
  }, {
    field: 'vehicle_count',
    label: '车辆数量',
    align: 'center'
  }, {
    field: 'first_time',
    label: '首笔运单时间',
    filter: 'timeFilter',
    align: 'center',
    width: 120
  }, {
    field: 'party_member_alias',
    label: '是否党员',
    align: 'center'
  }, {
    field: 'veteran_alias',
    label: '是否退伍军人',
    align: 'center'
  }]

  created() {
    this.initListQuery()
    this.getList()
  }

  initListQuery() {
    if (this.$route && this.$route.query) {
      this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    }
    this.listQuery.startTime = Number(this.$route?.query?.startTime) || ''
    this.listQuery.endTime = Number(this.$route?.query?.endTime) || ''
  }

  async getList() {
    this.listLoading = true
    const { risk_score, ...listQuery } = this.listQuery
    if (risk_score) {
      listQuery.risk_score_left = risk_score[0] || ''
      listQuery.risk_score_right = risk_score[1] || ''
    }
    const { data } = await getDriverList(listQuery)
    this.list = resolveList(data.items)
    this.total = data.total
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  handleFilter() {
    this.listQuery.page.index = 1
    this.getList()
  }

  handelActionDetail({ id }) {
    this.$router.push({
      path: `consignor/${id}`
    })
  }
}

