// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.china-map-container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n.china-map-container .toggle-btn-group {\n    position: absolute;\n    margin-top: -32px;\n    right: 0;\n    padding-right: 24px;\n    z-index: 1;\n}\n.china-map-container .toggle-btn-dispatch,\n  .china-map-container .toggle-btn-receive {\n    display: inline-block;\n    width: 50px;\n    height: 24px;\n    line-height: 24px;\n    border: 1px solid #eeeeee;\n    color: #686868;\n    border-radius: 2px;\n    font-size: 10px;\n    text-align: center;\n    cursor: pointer;\n}\n.china-map-container .toggle-btn-receive {\n    margin-left: 8px;\n}\n.china-map-container .toggle-btn-dispatch.active {\n    border-color: #17BB89;\n    color: #17BB89;\n}\n.china-map-container .toggle-btn-receive.active {\n    border-color: #4F7DF9;\n    color: #4F7DF9;\n}\n.china-map-container .tip {\n    position: absolute;\n    left: 18px;\n    bottom: 21px;\n}\n.china-map-container .tip span {\n      color: #31403c;\n      font-size: 10px;\n}\n.china-map-container .tip .line {\n      width: 80px;\n      height: 8px;\n      display: inline-block;\n      margin-left: 8px;\n      margin-right: 8px;\n}\n.china-map-container .toggle-btn-group.mini {\n    left: 15px;\n    bottom: 15px;\n    margin-top: 0;\n}\n.china-map-container .tip.mini {\n    left: inherit;\n    right: 12px;\n    bottom: 20px;\n}\n.china-map-container .tip.mini .line {\n      width: 48px;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
