// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.tags-view-wrapper .tags-view-item {\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n}\n.tags-view-wrapper .tags-view-item .el-icon-close {\n    width: 16px;\n    height: 16px;\n    vertical-align: 2px;\n    border-radius: 50%;\n    text-align: center;\n    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);\n    -webkit-transform-origin: 100% 50%;\n            transform-origin: 100% 50%;\n}\n.tags-view-wrapper .tags-view-item .el-icon-close:before {\n      -webkit-transform: scale(0.6);\n              transform: scale(0.6);\n      display: inline-block;\n      vertical-align: -3px;\n}\n.tags-view-wrapper .tags-view-item .el-icon-close:hover {\n      background-color: #b4bccc;\n      color: #fff;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#20232a",
	"menuText": "#B1B1B1",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
