import request from '@/utils/request'
import { IGetDashboardParams, IRiskCoreParams } from './types'

const path = '/aggregation/dashboard'

export const getOverviewInvoice = (params: IGetDashboardParams) =>
  request({
    url: `${path}/invoice`,
    method: 'post',
    data: params
  })
export const getOverviewRank = (params: IGetDashboardParams) =>
  request({
    url: `${path}/rank`,
    method: 'post',
    data: params
  })
export const getOverviewSubjectCount = (params: IGetDashboardParams) =>
  request({
    url: `${path}/subject/count`,
    method: 'post',
    data: params
  })
export const getOverviewTax = (params: IGetDashboardParams) =>
  request({
    url: `${path}/tax`,
    method: 'post',
    data: params
  })
export const getOverviewType = (params: IGetDashboardParams) =>
  request({
    url: `${path}/type`,
    method: 'post',
    data: params
  })
export const getOverviewWaybillChart = (params: IGetDashboardParams) =>
  request({
    url: `${path}/waybill/chart`,
    method: 'post',
    data: params
  })

export const getRiskScore = (params: IRiskCoreParams) =>
  request({
    url: `${path}/risk/score`,
    method: 'post',
    data: params
  })
export const quickBiApi = () =>
  request({
    url: '/quick-bi/waybill',
    method: 'post'
  })
export const agentScoreApi = () =>
  request({
    url: '/agent/score/rank',
    method: 'post'
  })
export const industrialCountApi = () =>
  request({
    url: '/industrial/subject/count',
    method: 'post'
  })
