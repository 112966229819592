var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search-top" },
        [
          _c(
            "el-row",
            {
              staticClass: "filter-container",
              staticStyle: { "font-size": "14px", "padding-bottom": "12px" }
            },
            [
              _c(
                "el-col",
                {
                  staticClass: "field-item",
                  staticStyle: {
                    width: "312px",
                    display: "inline-block",
                    "margin-right": "24px"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "mr-2 label",
                      staticStyle: {
                        width: "84px",
                        display: "inline-block",
                        "text-align": "right"
                      }
                    },
                    [_vm._v("风控方案")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-input--small el-input--suffix",
                      staticStyle: { display: "inline-block" }
                    },
                    [
                      _c("map-select", {
                        staticStyle: { width: "220px" },
                        attrs: { config: { list: _vm.schemeNoEnum } },
                        model: {
                          value: _vm.searchTopForm.scheme_no,
                          callback: function($$v) {
                            _vm.$set(_vm.searchTopForm, "scheme_no", $$v)
                          },
                          expression: "searchTopForm.scheme_no"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-col",
                {
                  staticClass: "field-item",
                  staticStyle: { width: "690px", display: "inline-block" }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "mr-2 label",
                      staticStyle: {
                        width: "84px",
                        display: "inline-block",
                        "text-align": "right"
                      }
                    },
                    [_vm._v("所在区域")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-input--small el-input--suffix",
                      staticStyle: { display: "inline-block" }
                    },
                    [
                      _c("area-list", {
                        on: { onDistrictChange: _vm.handleSearchDistrictChange }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("search-filter", {
        attrs: { options: _vm.queryFilterOptions, "type-default": "input" },
        on: { search: _vm.handleFilter },
        model: {
          value: _vm.listQuery,
          callback: function($$v) {
            _vm.listQuery = $$v
          },
          expression: "listQuery"
        }
      }),
      _c(
        "section",
        [
          _c("bsm-table", {
            attrs: {
              name: "inspect_strategy",
              data: _vm.list,
              options: _vm.tableListOptions,
              "list-loading": _vm.listLoading,
              "show-index": true
            },
            on: {
              actionCommand: _vm.handleActionCommand,
              "action-detail": _vm.handelActionDetail
            }
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page.index,
          limit: _vm.listQuery.page.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery.page, "index", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery.page, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm.visible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog-container",
              attrs: {
                title: "编辑企业风控配置",
                visible: _vm.visible,
                width: "668px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.visible = $event
                },
                close: _vm.handleClose
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.temp,
                        "label-width": "130px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "name", label: "网货企业名称" } },
                        [_c("span", [_vm._v(_vm._s(_vm.temp.name))])]
                      ),
                      _c("el-form-item", { attrs: { label: "网货企业税号" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.temp.tax_no))])
                      ]),
                      _c("p", { staticClass: "title" }, [_vm._v(" 监管配置 ")]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "企业注册所在区域",
                            prop: "apply_registration_district"
                          }
                        },
                        [
                          _c("area-list", {
                            attrs: {
                              "province-code": _vm.temp.province,
                              "city-code": _vm.temp.city,
                              "county-code": _vm.temp.district
                            },
                            on: {
                              onDistrictChange:
                                _vm.handleAgentRegsiterDistrictChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "风控方案" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.temp.scheme_no,
                                callback: function($$v) {
                                  _vm.$set(_vm.temp, "scheme_no", $$v)
                                },
                                expression: "temp.scheme_no"
                              }
                            },
                            _vm._l(_vm.schemeNoEnum, function(type) {
                              return _c("el-option", {
                                key: type.value,
                                attrs: { value: type.value, label: type.label }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "district_rule_switch",
                            label: "区域规则"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.temp.district_rule_switch,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.temp,
                                    "district_rule_switch",
                                    $$v
                                  )
                                },
                                expression: "temp.district_rule_switch"
                              }
                            },
                            [_vm._v(" 启用 ")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.temp.district_rule_switch,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.temp,
                                    "district_rule_switch",
                                    $$v
                                  )
                                },
                                expression: "temp.district_rule_switch"
                              }
                            },
                            [_vm._v(" 禁用 ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "edit_switch", label: "企业编辑权限" }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.temp.edit_switch,
                                callback: function($$v) {
                                  _vm.$set(_vm.temp, "edit_switch", $$v)
                                },
                                expression: "temp.edit_switch"
                              }
                            },
                            [_vm._v(" 启用 ")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.temp.edit_switch,
                                callback: function($$v) {
                                  _vm.$set(_vm.temp, "edit_switch", $$v)
                                },
                                expression: "temp.edit_switch"
                              }
                            },
                            [_vm._v(" 禁用 ")]
                          )
                        ],
                        1
                      ),
                      _c("p", { staticClass: "title" }, [
                        _vm._v(" 外部监管配置 ")
                      ]),
                      _c("el-form-item", { attrs: { label: "对接系统" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.temp.anti_fraud_third_party_name) +
                            " "
                        )
                      ]),
                      _c("el-form-item", { attrs: { label: "返回风控结果" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.temp.fusion_switch ? "是" : "否") +
                            " "
                        )
                      ]),
                      _c("el-form-item", { attrs: { label: "自动申诉异常" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.temp.automatic_appeal_switch ? "是" : "否"
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.visible = false
                        }
                      }
                    },
                    [_vm._v(" 取 消 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.subLoading },
                      on: { click: _vm.submitDetail }
                    },
                    [_vm._v(" 保存 ")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }