


















































































































































































































































































































































import { Vue, Component, ProvideReactive } from 'vue-property-decorator'
import {
  getAgentDashboard,
  getAgentInvoiceChart,
  getAgentRank,
  getAgentSubjectCount,
  getAgentTax,
  getAgentType,
  getAgentWaybillCount,
  getAlarmRuleList
} from '@/api/agent'
import AlarmList from '@/views/regulation/components/alarm-list.vue'
import AlarmPop from '@/views/regulation/components/alarm-pop.vue'
import { IAgentDashboardData } from '@/api/types'
import {
  DashboardHeader,
  DashboardCard,
  ChartContainer,
  TicketCard,
  NoCacheLink,
  ChinaMap,
  MultiLineChart
} from '@/common/business-components'
import {
  BarChart,
  PieChart,
  LineAreaChart,
  LineChart,
  FilledMap,
  DonutChart
} from '@/common/components/Charts'

import RiskScore from '@/views/components/RiskScore/index.vue'

import SubjectDetailDialog from '@/views/components/SubjectDetailDialog/index.vue'
import { sum, mergeRule, mergeOldDataRule } from '@/utils'
import moment from 'moment'
import _ from 'lodash'

@Component({
  name: 'AgentDetail',
  components: {
    ChartContainer,
    LineAreaChart,
    LineChart,
    AlarmPop,
    DashboardHeader,
    DashboardCard,
    TicketCard,
    BarChart,
    PieChart,
    FilledMap,
    DonutChart,
    AlarmList,
    ChinaMap,
    MultiLineChart,
    SubjectDetailDialog,
    RiskScore,
    NoCacheLink
  }
})
export default class extends Vue {
  @ProvideReactive('dashboard') timeFormat = 'MM.DD'

  multiLineChartKeys = [
    { field: 'apply_invoice_list', label: '申请单数' },
    { field: 'pass_invoice_list', label: '开票单数' }
  ]

  totalTax = 0
  data: IAgentDashboardData = {
    invoiced_count: 0,
    agent_info: {
      enterpriseName: '',
      legalPhone: '',
      legalIdentityNo: '',
      legalName: ''
    },
    waybill_count: 0,
    waybill_count_list: [],
    consignor_count: 0,
    carrier_count: 0,
    driver_count: 0,
    vehicle_count: 0,
    income_sum: 0,
    expense_sum: 0,
    not_yet_invoice: {
      invoice_amount_sum: 0,
      invoice_count: 0
    },
    carrier_invoice: {
      invoice_amount_sum: 0,
      invoice_count: 0
    },
    consignor_invoice: {
      invoice_amount_sum: 0,
      invoice_count: 0
    },
    apply_invoice_list: [],
    pass_invoice_list: [],

    consignor_waybill_rank_list: [],
    consignor_freight_rank_list: [],
    carrier_waybill_rank_list: [],
    carrier_freight_rank_list: [],
    goods_type_distribution: [],
    receiver_province_distribution: [],
    delivery_province_distribution: [],
    business_type_distribution: [],
    pay_type_distribution: [],
    tax_distribution: [],
    risk_score: 0,
    risk_score_type: 0
  }

  alarmData = {}
  loadingData = true
  currentAlarmId=''
  queryName=''
  currentName=''
  listQuery = {
    subject_id: this.id,
    page_query: {
      index: 1,
      limit: 100
    }
  }

  params = {
    start_time: moment().add(-30, 'days').startOf('day').valueOf(),
    end_time: moment().valueOf(),
    subject_id: '',
    business_mode: 0,
    time_type: 1
  }

  detail: any = {}

  get id() {
    return this.$route.params.id
  }

  showPop(row) {
    this.currentAlarmId = row.id
    this.currentName = row.name
    this.queryName = this.data.agent_info.enterpriseName
    ;(this.$refs.alarmDialog as any).showDialog()
  }

  async getList() {
    const { data } = await getAlarmRuleList(this.listQuery)
    this.loadingData = false
    this.alarmData = {
      items: data
    }
  }

  updateData(data, temp) {
    temp = _.mergeWith(temp, data, mergeRule)
    if (temp) {
      this.$nextTick(() => {
        this.data = Object.assign({}, _.mergeWith(this.data, temp, mergeOldDataRule))
        this.totalTax = sum(this.data.tax_distribution, 'value')
      })
    }
  }

  async getData() {
    const params = Object.assign({}, this.params, {
      subject_id: this.id
    })
    const temp = {}
    // getAgentInvoiceChart(params).then(res => this.updateData(res.data, temp))
    // getAgentRank(params).then(res => this.updateData(res.data, temp))
    // getAgentSubjectCount(params).then(res => this.updateData(res.data, temp))
    // getAgentTax(params).then(res => this.updateData(res.data, temp))
    // getAgentType(params).then(res => this.updateData(res.data, temp))
    // getAgentWaybillCount(params).then(res => this.updateData(res.data, temp))
    getAgentInvoiceChart(params).then(({ data }) => {
      this.data.invoiced_count = data.invoiced_count
      this.data.pass_invoice_list = data.pass_invoice_list
      this.data.apply_invoice_list = data.apply_invoice_list
    })
    getAgentRank(params).then(({ data }) => {
      this.data.carrier_freight_rank_list = data.carrier_freight_rank_list
      this.data.carrier_waybill_rank_list = data.carrier_waybill_rank_list
      this.data.consignor_freight_rank_list = data.consignor_freight_rank_list
      this.data.consignor_waybill_rank_list = data.consignor_waybill_rank_list
    })
    getAgentSubjectCount(params).then(({ data }) => {
      this.data.consignor_count = data.consignor_count
      this.data.carrier_count = data.carrier_count
      this.data.vehicle_count = data.vehicle_count
      this.data.driver_count = data.driver_count
    })
    getAgentTax(params).then(({ data }) => {
      this.data.tax_distribution = data.tax_distribution || []
      this.data.income_sum = data.income_sum
      this.data.expense_sum = data.expense_sum
      this.data.not_yet_invoice = data.not_yet_invoice
      this.data.carrier_invoice = data.carrier_invoice
      this.data.consignor_invoice = data.consignor_invoice

      this.totalTax = sum(data.tax_distribution, 'value')
    })
    getAgentType(params).then(({ data }) => {
      this.data.receiver_province_distribution = data.receiver_province_distribution
      this.data.pay_type_distribution = data.pay_type_distribution
      this.data.goods_type_distribution = data.goods_type_distribution
      this.data.delivery_province_distribution = data.delivery_province_distribution
      this.data.business_type_distribution = data.business_type_distribution
    })
    getAgentWaybillCount(params).then(({ data }) => {
      this.data.waybill_count = data.waybill_count
      this.data.waybill_count_list = data.waybill_count_list
      this.data.agent_info = data.agent_info
      this.data.risk_score = data.risk_score.risk_score
      this.data.risk_score_type = data.risk_score.risk_score_type
    })
  }

  handleTimeChange({ startTime, endTime, timeFormat }) {
    this.params.start_time = startTime
    this.params.end_time = endTime
    this.timeFormat = timeFormat
    this.getData()
  }

  handleBusinessChange({ businessMode }) {
    this.params.business_mode = businessMode
    this.getData()
  }

  handleTimeTypeChange({ timeType }) {
    this.params.time_type = timeType
    this.getData()
  }

  getLinkUrl(url: string, withDid = false) {
    const suff = withDid ? 'Did' : ''
    if (url.indexOf('?') === -1) {
      return `${url}?agent${suff}=${this.id}`
    } else {
      return `${url}&agent${suff}=${this.id}`
    }
  }

  created() {
    this.getData()
    this.getList()
  }
}

