




























































































































import { Component, Vue } from 'vue-property-decorator'
import CountTo from 'vue-count-to'

@Component({
  name: 'PanelGroup',
  components: {
    CountTo
  }
})
export default class extends Vue {
  private handleSetLineChartData(type: string) {
    this.$emit('handleSetLineChartData', type)
  }
}
