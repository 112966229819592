















































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { merge } from 'lodash'
import { convertToExcel } from '@/common/utils/'
import { agentSortQuery, exportAgentSort } from '@/api/agent_rank'
import { IAgentRankDto } from '@/api/types'
import DashboardHeader from '@/common/business-components/DashboardHeader.vue'
import BsmTable from '@/views/components/BsmTable.vue'
import { weight } from '@/filters'
import { TimeRangeType } from '@/common/constant'

const mkSortFnCH = (key) => (item1, item2) => {
  return item1[key].localeCompare(item2[key])
}

@Component({
  name: 'AgentRank',
  components: {
    BsmTable,
    DashboardHeader
  }
})
export default class extends Vue {
  list: IAgentRankDto[] = []
  total = 0
  listLoading = true
  downLoading = false
  report = {
    new_access_agents: 0,
    accumulate_access_agents: 0,
    active_agents: 0,
    active_percent: 0
  }

  disabledShortCutOption=[TimeRangeType.Today]

  endLimit = moment().startOf('day').valueOf() - 1000

  params = {
    start_time: moment().day(-6).startOf('day').valueOf(),
    end_time: moment().day(0).endOf('day').valueOf(),
    business_mode: 0,
    time_type: 1
  }

  get cycleStart() {
    if (this.params.start_time) {
      return moment(this.params.start_time).format('YYYY-MM-DD HH:mm:ss')
    } else {
      return '2020-09-01 00:00:00'
    }
  }

  get cycleEnd() {
    return moment(this.params.end_time).format('YYYY-MM-DD HH:mm:ss')
  }

  get timeRange() {
    return {
      startTime: this.params.start_time,
      endTime: this.params.end_time
    }
  }

  defaultFields = [{
    field: 'agent_did',
    type: 'link',
    label: '网络货运企业名称',
    cloumnAttrs: {
      'sort-method': mkSortFnCH('agent_name')
    },
    config: {
      baseUrl: '/dataquery/agent',
      label: (val, rowData) => rowData.agent_name
    },
    fixed: true
  }, {
    field: 'state_alias',
    label: '企业状态'
    // fixed: true
  }, {
    field: 'active',
    label: '是否活跃',
    width: 120
    // fixed: true
  }, {
    field: 'access_days',
    label: '接入天数',
    width: 100
    // fixed: true
  }, {
    field: 'risk_score',
    label: '评分',
    align: 'center',
    filter: 'riskScore'
  }, {
    field: 'create_waybill_days',
    label: '开单天数',
    align: 'center'
  }, {
    field: 'waybill_count',
    label: '运单笔数',
    width: 150,
    align: 'center'
  }, {
    field: 'income',
    label: '运费收入(元)',
    filter: 'amountFormatFilter',
    align: 'center'
  }, {
    field: 'actual_expense',
    label: '运费实际支付(元)',
    filter: 'amountFormatFilter',
    align: 'center'
  }, {
    field: 'drivers',
    label: '司机数量',
    align: 'center'
  }, {
    field: 'vehicles',
    label: '车辆数量',
    align: 'center'
  }, {
    field: 'weight',
    label: '货运量(吨)',
    align: 'center',
    filter: (val) => weight(val, 1000 * 1000)
  }]

  optionalFields = [{
    field: 'first_waybill_time',
    label: '首笔运单时间',
    filter: 'timeFilter'
    // align: 'center'
  }, {
    field: 'last_waybill_time',
    label: '末笔运单时间',
    filter: 'timeFilter'
    // align: 'center'
  }, {
    field: 'expense',
    label: '运费支出(元)',
    filter: 'amountFormatFilter'
    // width: 150
  }, {
    field: 'actual_expense_bank',
    label: '运费支出-银行转账(元)',
    filter: 'amountFormatFilter'
    // width: 150
  }, {
    field: 'actual_expense_oil',
    label: '运费支出-油卡(元)',
    filter: 'amountFormatFilter',
    width: 150
  }, {
    field: 'actual_expense_etc',
    label: '运费支出-ETC(元)',
    filter: 'amountFormatFilter',
    width: 150
  }, {
    field: 'actual_expense_other',
    label: '运费支出-其他(元)',
    filter: 'amountFormatFilter',
    width: 150
  }, {
    field: 'invoiced_amount',
    label: '承运人开票金额(元)',
    filter: 'amountFormatFilter',
    width: 150
  }, {
    field: 'invoiced_waybill_count',
    label: '开票运单笔数'
  }, {
    field: 'payed_count',
    label: '支付运单笔数'
  }, {
    field: 'average_income',
    label: '运费收入均价(元)',
    filter: 'amountFormatFilter'
  }, {
    field: 'average_expense',
    label: '运费支出均价(元)',
    filter: 'amountFormatFilter'
  }, {
    field: 'average_transport_time',
    label: '平均运输时长(小时)',
    filter: 'timeDurationFilter'
  }, {
    field: 'transport_mile',
    label: '总运输距离(米)'
  }, {
    field: 'average_transport_mile',
    label: '平均运输距离(米)'
  }, {
    field: 'average_delay_upload_time',
    label: '平均延迟上报时间(小时)',
    filter: 'timeDurationFilter'
  }, {
    field: 'consignors',
    label: '托运人数量'
  }, {
    field: 'verified_consignors',
    label: '已认证托运人数量'
  }, {
    field: 'carriers',
    label: '承运人数量'
  }, {
    field: 'verified_carriers',
    label: '已认证承运人数量'
  }, {
    field: 'check_carriers',
    label: '资质审查承运人数量'
  }, {
    field: 'verified_drivers',
    label: '已认证司机数量'
  }, {
    field: 'check_drivers',
    label: '资质审查司机数量'
  }, {
    field: 'verified_vehicles',
    label: '已认证车辆数量'
  }, {
    field: 'check_vehicles',
    label: '资质审查车辆数量'
  }, {
    field: 'invoiced_count',
    label: '承运人发票开票张数'
  }, {
    field: 'tax',
    label: '纳税总额(元)',
    filter: 'amountFormatFilter'
  }, {
    field: 'additional_count',
    label: '补充信息运单笔数'
  }, {
    field: 'active_days',
    label: '活跃天数'
  }, {
    field: 'payed_high_grade_count',
    label: '已支付高风险运单笔数'
  }, {
    field: 'middle_grade_count',
    label: '中等风险运单笔数'
  }, {
    field: 'abnormal_trajectory_count',
    label: '轨迹异常'
  }, {
    field: 'missing_trajectory_count',
    label: '轨迹缺失'
  }, {
    field: 'abnormal_online_vehicle_count',
    label: '车辆在线异常'
  }, {
    field: 'abnormal_contract_time_count',
    label: '合同时间异常'
  }, {
    field: 'related_waybill_count',
    label: '关联方运单'
  }, {
    field: 'abnormal_waybill_time_count',
    label: '运单逻辑异常(时间)'
  }, {
    field: 'abnormal_waybill_info_count',
    label: '运单逻辑异常(运单信息)'
  }, {
    field: 'waybill_overload_count',
    label: '运单超载'
  }, {
    field: 'abnormal_waybill_amount_count',
    label: '运费金额异常'
  }, {
    field: 'ultra_short_transport_count',
    label: '超短途运输'
  }, {
    field: 'abnormal_transport_qualification_count',
    label: '运输资质异常'
  }, {
    field: 'make_up_history_count',
    label: '补历史单'
  }, {
    field: 'none_real_time_report_count',
    label: '非实时上报'
  }, {
    field: 'vehicle_overload_count',
    label: '车辆超载'
  }, {
    field: 'missing_driver_operation_count',
    label: '司机操作缺失'
  }, {
    field: 'abnormal_driver_operation_count',
    label: '司机操作异常'
  }, {
    field: 'abnormal_pay_amount_count',
    label: '支付金额异常'
  }, {
    field: 'none_personal_receive_count',
    label: '非本人收款'
  }, {
    field: 'abnormal_waybill_flow_count',
    label: '运费流水异常'
  }, {
    field: 'untrusted_pay_channel_count',
    label: '非可信支付渠道'
  }].map(item => Object.assign({}, item, {
    invisible: true,
    align: 'center'
  }))

  get tableListOptions() {
    return [...this.defaultFields, ...this.optionalFields].map(item => {
      return merge(item, {
        cloumnAttrs: {
          sortable: true
        }
      })
    })
  }

  created() {
    // this.initListQuery()
    this.getList()
  }

  initListQuery() {
    // if (this.$route && this.$route.query) {
    //   this.listQuery = Object.assign({}, this.listQuery, this.$route.query)
    // // }
    // this.listQuery.startTime = Number(this.$route?.query?.startTime) || ''
    // this.listQuery.endTime = Number(this.$route?.query?.endTime) || ''
  }

  async getList() {
    this.listLoading = true
    const { data } = await agentSortQuery(Object.assign({}, this.params))
    // eslint-disable-next-line camelcase
    this.list = data?.agent_sort_views
    this.report = {
      accumulate_access_agents: data.accumulate_access_agents || 0,
      active_agents: data.active_agents || 0,
      active_percent: data.active_percent,
      new_access_agents: data.new_access_agents || 0
    }
    await this.$nextTick()
    this.listLoading = false
  }

  async exportList() {
    try {
      this.downLoading = true
      let showColumns = this.getDisplayFieldsCache()
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.defaultFields.map(item => {
          if (item.field === 'agent_did') {
            return 'agent_name'
          } else {
            return item.field
          }
        })
      }
      const res = await exportAgentSort(Object.assign({}, this.params, {
        show_columns: showColumns
      }))
      const data = [res]
      const name = `网络货运企业排名_${moment().format('YYYY_MM_DD').toString()}`
      convertToExcel(data, name)
      this.downLoading = false
    } catch (err) {
      this.downLoading = false
    }
  }

  handelActionDetail({ id }) {
    this.$router.push({
      path: `consignor/${id}`
    })
  }

  handleTimeChange({ startTime, endTime, timeFormat }) {
    this.params.start_time = startTime
    this.params.end_time = endTime
    this.getList()
  }

  handleBusinessChange({ businessMode }) {
    this.params.business_mode = businessMode
    this.getList()
  }

  handleTimeTypeChange({ timeType }) {
    this.params.time_type = timeType
    this.getList()
  }

  getDisplayFieldsCache() {
    return (this.$refs.bsm_table as BsmTable).getDisplayFieldsCache().map(label => {
      console.log(label)
      const item = this.tableListOptions.find(option => option.label === label)
      if (item?.field === 'agent_did') {
        return 'agent_name'
      } else {
        return item?.field || ''
      }
    }).filter(val => !!val)
  }
}

