var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container pb-page flex",
      staticStyle: {
        "align-items": "center",
        "justify-content": "center",
        "padding-top": "0"
      }
    },
    [
      _c(
        "div",
        { staticStyle: { height: "100%", width: "100%" } },
        [
          _c(
            "el-menu",
            {
              attrs: { mode: "horizontal", "default-active": _vm.activeIndex },
              on: { select: _vm.handleSelect }
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [
                _vm._v("税务数据比对")
              ]),
              _c("el-menu-item", { attrs: { index: "2" } }, [
                _vm._v("企业税务申报")
              ])
            ],
            1
          ),
          _vm.activeIndex === "1"
            ? _c("DataCompare", { staticClass: "mt-20" })
            : _vm._e(),
          _vm.activeIndex === "2"
            ? _c("ClaimTax", { staticClass: "mt-20" })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }