var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("search-filter", {
        attrs: { options: _vm.queryFilterOptions, "type-default": "input" },
        on: { search: _vm.handleFilter },
        model: {
          value: _vm.listQuery,
          callback: function($$v) {
            _vm.listQuery = $$v
          },
          expression: "listQuery"
        }
      }),
      _c(
        "section",
        [
          _c("bsm-table", {
            attrs: {
              name: "inspect_strategy",
              data: _vm.list,
              options: _vm.tableListOptions,
              "list-loading": _vm.listLoading,
              "show-index": true
            },
            on: {
              actionCommand: _vm.handleActionCommand,
              "action-detail": _vm.handelActionDetail
            }
          })
        ],
        1
      ),
      _c(
        "pagination",
        {
          attrs: {
            total: _vm.total,
            page: _vm.listQuery.page.index,
            limit: _vm.listQuery.page.limit
          },
          on: {
            "update:page": function($event) {
              return _vm.$set(_vm.listQuery.page, "index", $event)
            },
            "update:limit": function($event) {
              return _vm.$set(_vm.listQuery.page, "limit", $event)
            },
            pagination: _vm.getList
          }
        },
        [
          _c(
            "template",
            { slot: "end" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["sample_inspect_strategy_create"],
                      expression: "['sample_inspect_strategy_create']"
                    }
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.handleActionCommand("add", null)
                    }
                  }
                },
                [_vm._v(" 新增策略 ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-container",
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.visible,
            width: "800px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    disabled: _vm.currentActionType === "detail",
                    rules: _vm.rules,
                    model: _vm.temp,
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name", label: "抽检策略名称" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.temp.name,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "name", $$v)
                          },
                          expression: "temp.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "business_type", label: "抽检类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { disabled: _vm.currentActionType === "edit" },
                          on: { change: _vm.businessTypeChange },
                          model: {
                            value: _vm.temp.business_type,
                            callback: function($$v) {
                              _vm.$set(_vm.temp, "business_type", $$v)
                            },
                            expression: "temp.business_type"
                          }
                        },
                        _vm._l(_vm.businessTypes, function(type) {
                          return _c("el-option", {
                            key: type.value,
                            attrs: { value: type.value, label: type.label }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "strategy_no", label: "抽检策略编号" } },
                    [
                      _c("el-input", {
                        attrs: {
                          value: _vm.temp.strategy_no || "系统自动生成",
                          disabled: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "type", label: "抽检内容" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.temp.type,
                            callback: function($$v) {
                              _vm.$set(_vm.temp, "type", $$v)
                            },
                            expression: "temp.type"
                          }
                        },
                        _vm._l(_vm.inspectContentTypes, function(type) {
                          return _c("el-option", {
                            key: type.value,
                            attrs: { value: type.value, label: type.label }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "percentage", label: "数据抽检数量" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "80px" },
                        attrs: { min: 1, max: 100, step: 1, controls: false },
                        model: {
                          value: _vm.temp.percentage,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "percentage", $$v)
                          },
                          expression: "temp.percentage"
                        }
                      }),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v("%"),
                        _c("em", { staticClass: "text-primary font-18 mx-2" }, [
                          _vm._v("*")
                        ]),
                        _vm._v("上报数据总量"),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v("【抽检频率单位时间内的上报运单总量】")
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "max_count", label: "抽检数量上限" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "80px" },
                        attrs: { min: 1, step: 1, controls: false },
                        model: {
                          value: _vm.temp.max_count,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "max_count", $$v)
                          },
                          expression: "temp.max_count"
                        }
                      }),
                      _c("span", { staticClass: "text-danger ml-2" }, [
                        _vm._v(
                          "抽检频率单位时间内数据抽检的最大笔数，以运单计 "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "frequency_type", label: "数据抽检频率" }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.temp.frequency_type,
                            callback: function($$v) {
                              _vm.$set(_vm.temp, "frequency_type", $$v)
                            },
                            expression: "temp.frequency_type"
                          }
                        },
                        [_vm._v(" 日 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.temp.frequency_type,
                            callback: function($$v) {
                              _vm.$set(_vm.temp, "frequency_type", $$v)
                            },
                            expression: "temp.frequency_type"
                          }
                        },
                        [_vm._v(" 周 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.temp.frequency_type,
                            callback: function($$v) {
                              _vm.$set(_vm.temp, "frequency_type", $$v)
                            },
                            expression: "temp.frequency_type"
                          }
                        },
                        [_vm._v(" 月 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "status", label: "策略状态" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 2,
                          "active-text": "启用",
                          "inactive-text": "禁用"
                        },
                        model: {
                          value: _vm.temp.status,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "status", $$v)
                          },
                          expression: "temp.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "description", label: "抽检运单范围" } },
                    [
                      _c("el-input", {
                        attrs: { value: _vm.inspectDescription, disabled: "" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "init", label: "是否做默认抽检" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.temp.init,
                            callback: function($$v) {
                              _vm.$set(_vm.temp, "init", $$v)
                            },
                            expression: "temp.init"
                          }
                        },
                        [_vm._v(" 是，应用到所有企业进行抽检 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.temp.init,
                            callback: function($$v) {
                              _vm.$set(_vm.temp, "init", $$v)
                            },
                            expression: "temp.init"
                          }
                        },
                        [_vm._v(" 否，运营人员自行选择配置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.currentActionType === "detail"
                ? _c(
                    "span",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.visible = false
                            }
                          }
                        },
                        [_vm._v("返回")]
                      )
                    ],
                    1
                  )
                : _c(
                    "span",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.visible = false
                            }
                          }
                        },
                        [_vm._v(" 取 消 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.subLoading },
                          on: { click: _vm.submitDetail }
                        },
                        [_vm._v(" 保存 ")]
                      )
                    ],
                    1
                  )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }